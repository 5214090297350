<!--<style scoped>-->
    <!--.home{background-color: rgb(241,239,240);position: absolute;width: calc(100% - 240px);height: calc(100% - 120px);overflow: scroll;}-->
    <!--.screen{width: calc(100% - 80px);display: inline-block;}-->
    <!--.screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}-->
    <!--/*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/-->
    <!--.screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}-->
    <!--.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #00b86c;padding: 0px 16px;}-->
    <!--.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #00b86c;}-->
    <!--.screen :deep(.ivu-tabs-ink-bar){background-color: #00b86c;}-->
    <!--.screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}-->
    <!--.house{width: 72px;height: 72px;margin-top: 29px;}-->
    <!--.number{font-size: 48px;color: #7a7a7a;}-->
    <!--.tipsName{font-size: 16px;color: #8d8d8d;}-->
    <!--.displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}-->
    <!--.administration{width: 100%;background-color: #fff;margin-top: 40px;border-bottom: 1px solid #dddddd;height: 80px;text-align: left;}-->
    <!--.administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}-->
    <!--.label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}-->
    <!--.forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;margin:40px 40px 0 40px;height: 60px;}-->
    <!--.forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 16%;line-height: 60px;}-->
    <!--.forMcontent{width: calc(100% - 80px);height: 60px;color: #666666;font-size: 16px;margin:0px 40px 0 40px;border-bottom: 1px solid #dddddd;text-align: center;}-->
    <!--.forMcontent p {line-height: 60px;display: inline-block;width: 16%;overflow: hidden;white-space: nowrap;}-->
    <!--.smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}-->
    <!--.alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -23px;}-->
    <!--.alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}-->
    <!--.qrCode{width: 100px;height: 100px;}-->
    <!--.addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}-->
    <!--.myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}-->
    <!--.icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}-->
    <!--.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}-->
    <!--.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}-->
    <!--.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}-->

    <!--.publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}-->
    <!--.formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}-->
    <!--.publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}-->
    <!--.publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}-->
<!--</style>-->

<!--<template>-->
    <!--<div class="home">-->
        <!--&lt;!&ndash;<HelloWorld>&ndash;&gt;-->

        <!--&lt;!&ndash;</HelloWorld>&ndash;&gt;-->
        <!--<div style="background-color: rgb(241,239,240);">-->

            <!--<div style="text-align: left;font-size: 16px;width: calc(100% - 80px);display: inline-block;">-->
                <!--<div class="administration">-->
                    <!--<img class="label" src="/img/label.png">-->
                    <!--<p>管理</p>-->
                    <!--<div class="addTo" @click="addTto1(3,n,2)">添加</div>-->
                <!--</div>-->
                <!--<Collapse v-model="value3" accordion>-->
                    <!--<Panel :name="index3.toString()" v-for="(value3,index3,k3) in listData">-->
                        <!--{{index3+1}}、{{value3.AdminName}}-->
                        <!--<div slot="content" class="publicWelfare">-->
                            <!--<table class="formtable">-->
                                <!--<thead>-->
                                <!--<tr>-->
                                    <!--<th>名称</th>-->
                                    <!--<th>登录名</th>-->
                                    <!--<th>密码</th>-->
                                    <!--<th>注册时间</th>-->
                                    <!--&lt;!&ndash;<th>编辑</th>&ndash;&gt;-->
                                    <!--<th >添加律所</th>-->
                                    <!--<th>删除</th>-->
                                <!--</tr>-->
                                <!--</thead>-->
                                <!--<tbody>-->
                                <!--<tr>-->
                                    <!--<td>{{value3.AdminName}}</td>-->
                                    <!--<td>{{value3.AccountLogin}}</td>-->
                                    <!--<td>{{value3.AccountPassword}}</td>-->
                                    <!--<td>{{value3.CreatTime}}</td>-->
                                    <!--&lt;!&ndash;<td><Button icon="md-create" type="primary" @click.stop="modifyData(&ndash;&gt;-->
                                     <!--&lt;!&ndash;value3.AdminId,&ndash;&gt;-->
                           <!--&lt;!&ndash;value3.AccountLogin,value3.AccountPassword,value3.AdminName,value3.OrganizationName,value3.OrganizationLinkman, value3.OrganizationPhone&ndash;&gt;-->
                                    <!--&lt;!&ndash;)">编辑</Button></td>&ndash;&gt;-->
                                    <!--<td><Button  icon="md-add" type="primary" @click.stop="addTto(value3.AreaId,value3.Level,value3.AdminId);modal3 = true">添加</Button></td>-->
                                    <!--<td><Button icon="md-trash" type="primary" @click.stop="deleteLawFirm(value3.AdminId,value3.OrganizationId,value3.Pid)" style="">删除</Button></td>-->
                                <!--</tr>-->
                                <!--</tbody>-->
                            <!--</table>-->
                            <!--&lt;!&ndash;&ndash;&gt;-->
                            <!--<Collapse v-model="value4" accordion>-->
                                <!--<Panel :name="index4.toString()" v-for="(value4,index4,k4) in value3.list">-->
                                    <!--{{index4+1}}、{{value4.AdminName}}-->
                                    <!--<div slot="content" class="publicWelfare">-->
                                        <!--<table class="formtable">-->
                                            <!--<thead>-->
                                            <!--<tr>-->
                                                <!--<th>名称</th>-->
                                                <!--<th>登录名</th>-->
                                                <!--<th>密码</th>-->
                                                <!--<th>注册时间</th>-->
                                                <!--<th>编辑</th>-->
                                                <!--&lt;!&ndash;<th>删除</th>&ndash;&gt;-->
                                            <!--</tr>-->
                                            <!--</thead>-->
                                            <!--<tbody>-->
                                            <!--<tr>-->
                                                <!--<td>{{value4.AdminName}}</td>-->
                                                <!--<td>{{value4.AccountLogin}}</td>-->
                                                <!--<td>{{value4.AccountPassword}}</td>-->
                                                <!--<td>{{value4.CreatTime}}</td>-->
                                                <!--<td><Button icon="md-create" type="primary"-->
                                                            <!--@click.stop="modifyData(-->
                                     <!--value4.AdminId,-->
                           <!--value4.AccountLogin,value4.AccountPassword,value4.AdminName,value4.OrganizationName,value4.OrganizationLinkman, value4.OrganizationPhone-->
                                    <!--)"-->
                                                <!--&gt;编辑</Button></td>-->
                                                <!--&lt;!&ndash;<td><Button icon="md-trash" type="primary" @click.stop="deleteClick(value4.AdminId)" style="">删除</Button></td>&ndash;&gt;-->
                                            <!--</tr>-->
                                            <!--</tbody>-->
                                        <!--</table>-->
                                    <!--</div>-->
                                <!--</Panel>-->
                            <!--</Collapse>-->
                        <!--</div>-->
                    <!--</Panel>-->
                <!--</Collapse>-->
                <!--<div style="text-align: left;margin-left: 40px;font-size: 16px">-->
                    <!--&lt;!&ndash;<Page :total="count" page-size="5"  show-elevator />&ndash;&gt;-->
                    <!--&lt;!&ndash;<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>&ndash;&gt;-->
                    <!--<Page-->
                            <!--:total="count"-->
                            <!--:page-size="pageSize"-->
                            <!--show-elevator-->
                            <!--@on-change="page = $event;lawList()"-->
                    <!--/>-->
                <!--</div>-->
            <!--</div>-->
            <!--&lt;!&ndash;管理律师列表&ndash;&gt;-->
            <!--&lt;!&ndash;<div>&ndash;&gt;-->
                <!--&lt;!&ndash;<div class="font-size" style="width: calc(100% - 80px);display: inline-block;">&ndash;&gt;-->
                    <!--&lt;!&ndash;<div class="administration">&ndash;&gt;-->
                        <!--&lt;!&ndash;<img class="label" src="/img/label.png">&ndash;&gt;-->
                        <!--&lt;!&ndash;<p>管理</p>&ndash;&gt;-->
                        <!--&lt;!&ndash;<div class="addTo" @click="modal2 = true">添加</div>&ndash;&gt;-->
                    <!--&lt;!&ndash;</div>&ndash;&gt;-->
                    <!--&lt;!&ndash;<div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;">&ndash;&gt;-->
                        <!--&lt;!&ndash;<div class="forMtitle" v-if="listData != []">&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>司法所</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>时间</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>电话</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>登录名</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>密码</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>操作</p>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!--&lt;!&ndash;<div class="forMcontent" v-for="(value,index) in listData" :key="index">&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>{{value.AdminName}}</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>{{value.CreatTime}}</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>{{value.OrganizationPhone}}</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>{{value.AccountLogin}}</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p>{{value.AccountPassword}}</p>&ndash;&gt;-->
                            <!--&lt;!&ndash;<p style="color: #00b86c;cursor:pointer" >&ndash;&gt;-->
                                <!--&lt;!&ndash;<span @click="deleteLawFirm(value.AdminId,value.OrganizationId)" style="height: 100%;display: inline-block;width: 50%;">删除</span>&ndash;&gt;-->
                                <!--&lt;!&ndash;<span style="height: 100%;display: inline-block;width: 50%;" >添加</span>&ndash;&gt;-->
                            <!--&lt;!&ndash;</p>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                    <!--&lt;!&ndash;</div>&ndash;&gt;-->
                <!--&lt;!&ndash;</div>&ndash;&gt;-->
                <!--&lt;!&ndash;<div style="text-align: left;margin-left: 40px;font-size: 16px">&ndash;&gt;-->
                    <!--&lt;!&ndash;<Page&ndash;&gt;-->
                            <!--&lt;!&ndash;:total="count"&ndash;&gt;-->
                            <!--&lt;!&ndash;:page-size="pageSize"&ndash;&gt;-->
                            <!--&lt;!&ndash;show-elevator&ndash;&gt;-->
                            <!--&lt;!&ndash;@on-change="page = $event;lawList()"&ndash;&gt;-->
                    <!--&lt;!&ndash;/>&ndash;&gt;-->
                <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--&lt;!&ndash;&ndash;&gt;-->
        <!--</div>-->

        <!--&lt;!&ndash;&ndash;&gt;-->
        <!--<Modal-->
                <!--v-model="modal2"-->
                <!--title="添加"-->
                <!--@on-ok="addMember"-->
                <!--@on-cancel="cancel">-->
            <!--<div class="single">-->
                <!--<p class="contacts">联系人</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入联系人" v-model="organizationLinkman">-->
            <!--</div>-->
            <!--<div class="single">-->
                <!--<p class="contacts">联系电话</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入联系电话" v-model="organizationPhone">-->
            <!--</div>-->
            <!--<div class="single">-->
                <!--<p class="contacts">司法所名称</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入律所名称" v-model="organizationName">-->
            <!--</div>-->
            <!--<div class="single" v-if="cityList.length != 0">-->
                <!--<p class="contacts">地址</p>-->
                <!--<div style="display: inline-block">-->
                    <!--<Select v-model="model8" style="width:200px" @on-change="choice" :label-in-value="labelInValue" :placeholder="tipsWord">-->
                        <!--<Option  v-for="(item,index) in cityList" :value="item.AreaId +','+ index  +','+ '1'" :label="item.AreaName" :key="index">{{ item.AreaName }}</Option>-->
                    <!--</Select>-->
                    <!--<Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue" v-if="areaData" :placeholder="tipsWord">-->
                        <!--<Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName" :key="index">{{ i.AreaName }}</Option>-->
                    <!--</Select>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="single">-->
                <!--<p class="contacts">登录名称</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入登录名" v-model="adminLogin">-->
            <!--</div>-->
            <!--<div class="single">-->
                <!--<p class="contacts">登录密码</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入登录密码" v-model="adminPassword">-->
            <!--</div>-->
        <!--</Modal>-->
        <!--&lt;!&ndash;律所&ndash;&gt;-->
        <!--<Modal-->
                <!--v-model="modal3"-->
                <!--title="添加2"-->
                <!--@on-ok="addMember1"-->
                <!--@on-cancel="cancel1">-->

            <!--<div class="single">-->
                <!--<p class="contacts">律所名称</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入村居律所名称" v-model="organizationName">-->
            <!--</div>-->
            <!--<div class="single">-->
                <!--<p class="contacts">联系人</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入联系人" v-model="organizationLinkman">-->
            <!--</div>-->
            <!--<div class="single">-->
                <!--<p class="contacts">联系电话</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入联系电话" v-model="organizationPhone">-->
            <!--</div>-->
            <!--&lt;!&ndash;<div class="single" v-if="cityList.length != 0">&ndash;&gt;-->
                <!--&lt;!&ndash;<p class="contacts">地址</p>&ndash;&gt;-->
                <!--&lt;!&ndash;<div style="display: inline-block">&ndash;&gt;-->
                    <!--&lt;!&ndash;<Select v-model="model8" style="width:200px" @on-change="choice" :label-in-value="labelInValue" :placeholder="tipsWord">&ndash;&gt;-->
                        <!--&lt;!&ndash;<Option  v-for="(item,index) in cityList" :value="item.AreaId +','+ index  +','+ '1'" :label="item.AreaName" :key="index">{{ item.AreaName }}</Option>&ndash;&gt;-->
                    <!--&lt;!&ndash;</Select>&ndash;&gt;-->
                    <!--&lt;!&ndash;<Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue" v-if="areaData" :placeholder="tipsWord">&ndash;&gt;-->
                        <!--&lt;!&ndash;<Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName" :key="index">{{ i.AreaName }}</Option>&ndash;&gt;-->
                    <!--&lt;!&ndash;</Select>&ndash;&gt;-->
                <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--<div class="single">-->
                <!--<p class="contacts">登录名称</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入登录名" v-model="adminLogin">-->
            <!--</div>-->
            <!--<div class="single">-->
                <!--<p class="contacts">登录密码</p>-->
                <!--<input type="text" class="inputWord" placeholder="请输入登录密码" v-model="adminPassword">-->
            <!--</div>-->
        <!--</Modal>-->
        <!--&lt;!&ndash;添加&ndash;&gt;-->
        <!--<Modal-->
                <!--v-model="modal4"-->
                <!--title="添加"-->
                <!--@on-ok="addMember"-->
                <!--@on-cancel="cancel">-->
            <!--<Form :model="addData" :label-width="60">-->
                <!--<FormItem label="名称">-->
                    <!--<Input v-model="addData.AdminName" placeholder="名称"></Input>-->
                <!--</FormItem>-->
                <!--<FormItem label="登录名">-->
                    <!--<Input v-model="addData.AccountLogin" placeholder="登录名"></Input>-->
                <!--</FormItem>-->
                <!--<FormItem label="密码">-->
                    <!--<Input v-model="addData.AccountPassword" placeholder="密码"></Input>-->
                <!--</FormItem>-->
                <!--&lt;!&ndash;<FormItem label="密码">&ndash;&gt;-->
                    <!--<div class="single" v-if="cityList.length != 0">-->
                        <!--<p class="contacts">地址</p>-->
                        <!--<div style="display: inline-block">-->
                            <!--<Select v-model="model8" style="width:200px" @on-change="choice" :label-in-value="labelInValue" :placeholder="tipsWord">-->
                                <!--<Option  v-for="(item,index) in cityList" :value="item.AreaId +','+ index  +','+ '1'" :label="item.AreaName" :key="index">{{ item.AreaName }}</Option>-->
                            <!--</Select>-->
                            <!--<Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue" v-if="areaData" :placeholder="tipsWord">-->
                                <!--<Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName" :key="index">{{ i.AreaName }}</Option>-->
                            <!--</Select>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--&lt;!&ndash;</FormItem>&ndash;&gt;-->

            <!--</Form>-->
        <!--</Modal>-->
    <!--</div>-->
<!--</template>-->

<!--<script>-->
    <!--// import HelloWorld from '../../components/HelloWorld.vue'-->
    <!--export default {-->
        <!--name: "home",-->
        <!--// components: {HelloWorld,},-->
        <!--data () {-->
            <!--return {-->
                <!--value3: '0',-->
                <!--value4: '0',-->
                <!--value2: ['0', '1', '2'],-->
                <!--addressData : [],-->
                <!--addressID : '',-->
                <!--model8: '',-->
                <!--model9: '',-->
                <!--cityList: [],-->
                <!--ElectronicsList: [],-->
                <!--tipsWord: '请选择地址',-->
                <!--box : 0,-->
                <!--modal1: false,-->
                <!--modal2: false,-->
                <!--modal3: false,-->
                <!--modal4: false,-->
                <!--modifyType : false,-->
                <!--Level : '',-->
                <!--AdminId : '',-->
                <!--listData : [],-->
                <!--arCode : false,//二维码-->
                <!--current : '',-->
                <!--lawyerPhone : '',-->
                <!--lawyerName : '',-->
                <!--information :[],-->
                <!--page : 1,-->
                <!--pageSize : 10,-->
                <!--count : 0,-->
                <!--adminLogin : '',-->
                <!--adminPassword : '',-->
                <!--organizationPhone : '',-->
                <!--organizationLinkman : '',-->
                <!--organizationName : '',-->
                <!--statisticsData : [-->
                    <!--{name : '服务社区（村）数量',value:''},-->
                    <!--{name : '服务用户数量',value:''},-->
                    <!--{name : '律师数量',value:''},-->
                    <!--{name : '咨询消息数量',value:''}-->
                <!--],-->
                <!--timelist : [-->
                    <!--{name:'本月',value:0},-->
                    <!--{name:'本季',value:1},-->
                    <!--{name:'本年',value:2},-->
                    <!--{name:'总计',value:3},-->
                <!--],-->
                <!--addData: {-->
                    <!--typeId : '',-->
                    <!--upAdminId : '',-->
                    <!--AccountLogin : '',//登录名-->
                    <!--AccountPassword  : '',//密码-->
                    <!--AreaId  : '',//区域-->
                    <!--AreaName  : '请选择',//区域名称-->
                    <!--AdminName  : '',//名称-->
                <!--}-->
            <!--}-->
        <!--},-->
        <!--methods:{-->
            <!--deleteLawyer : function(lawyerId){-->
                <!--var _this = this-->
                <!--_this.send({-->
                    <!--url : '/DeleteLawyer',-->
                    <!--confirm:true,-->
                    <!--data: {-->
                        <!--OrganizationId : _this.information.OrganizationId,-->
                        <!--LawyerId : lawyerId,-->
                    <!--},-->
                    <!--success: function (res) {-->
                        <!--console.log(res)-->
                        <!--_this.listData = []-->
                        <!--_this.page = '1'-->
                        <!--_this.lawList()-->
                    <!--}-->
                <!--})-->
            <!--},-->
            <!--lawList :function(){-->
                <!--var _this = this-->
                <!--_this.send({-->
                    <!--url : '/LawyerListById',-->
                    <!--data: {-->
                        <!--OrganizationId : _this.information.OrganizationId,-->
                        <!--page: _this.page,-->
                        <!--pageSize : _this.pageSize,-->
                    <!--},-->
                    <!--success: function (res) {-->
                        <!--_this.listData = res.List-->
                        <!--_this.count = res.count-->

                    <!--}-->
                <!--})-->
            <!--},-->
            <!--addMember :function(){-->
                <!--var _this = this-->
                <!--_this.send({-->
                    <!--url : '/ManageTotalAdmin',-->
                    <!--data: {-->
                        <!--AdminId : _this.information.AdminId,-->
                        <!--TypeId : _this.addData.typeId,-->
                        <!--OwnAdminId :_this.addData.OwnAdminId,-->
                        <!--UpAdminId : _this.addData.upAdminId,-->
                        <!--AccountLogin : _this.addData.AccountLogin,-->
                        <!--AccountPassword  : _this.addData.AccountPassword,-->
                        <!--// AreaId  : _this.addData.AreaId,-->
                        <!--AreaId  : _this.addressID,-->
                        <!--AdminName  : _this.addData.AdminName,-->
                    <!--},-->
                    <!--success: function (res) {-->
                        <!--_this.$Notice.success({-->
                            <!--desc:'操作成功',-->
                        <!--})-->
                        <!--// _this.totalAdminList()-->
                    <!--}-->
                <!--})-->
                <!--// console.log(_this.information.adminLevel)-->
                <!--// if(_this.organizationName != ''&& _this.adminPassword != ''&& _this.adminLogin != ''){-->
                <!--//     if(_this.cityList.length == 0){-->
                <!--//         var AreaId = _this.information.AreaId-->
                <!--//     }-->
                <!--//     _this.send({-->
                <!--//         url : '/AddOrganization',-->
                <!--//         data: {-->
                <!--//             OrganizationPhone : _this.organizationPhone,-->
                <!--//             Level : _this.information.adminLevel,-->
                <!--//             OrganizationLinkman : _this.organizationLinkman,-->
                <!--//             AdminId : _this.information.AdminId,-->
                <!--//             AccountLogin : _this.adminLogin,-->
                <!--//             OrganizationName : _this.organizationName,-->
                <!--//             AccountPassword : _this.adminPassword,-->
                <!--//             AreaId : _this.addressID,-->
                <!--//         },-->
                <!--//         success: function (res) {-->
                <!--//             _this.adminList()-->
                <!--//         }-->
                <!--//     })-->
                <!--// }else{-->
                <!--//     _this.$Notice.warning({-->
                <!--//         title:'系统提示',-->
                <!--//         desc:'输入完成后添加',-->
                <!--//     })-->
                <!--// }-->
            <!--},-->
            <!--addTto : function(AreaId,Level,AdminId){-->
                <!--console.log(AreaId,Level,AdminId)-->
                <!--var _this = this-->
                <!--_this.AreaId = AreaId-->
                <!--_this.Level = Level-->
                <!--_this.AdminId = AdminId-->
            <!--},-->
            <!--addTto1 : function(typeId,adminId,type,adminName,adminLogin,adminPassword,areaId,AreaName) {-->
                <!--var _this = this-->
                <!--if(AreaName){-->
                    <!--_this.modify = false-->
                <!--}else {-->
                    <!--_this.modify = true-->
                <!--}-->
                <!--console.log(typeId,_this.information.AdminId,type,adminName,adminLogin,adminPassword,areaId,AreaName)-->
                <!--//type==1编辑   type==2添加-->
                <!--if(type == 1){-->
                    <!--_this.addData.OwnAdminId = _this.information.AdminId-->
                    <!--_this.addData.upAdminId = ''-->
                    <!--_this.addData.AdminName = adminName-->
                    <!--_this.addData.AccountLogin = adminLogin-->
                    <!--_this.addData.AccountPassword = adminPassword-->
                    <!--_this.addData.AreaId = areaId-->
                    <!--_this.addData.AreaName = AreaName-->
                <!--}-->
                <!--if(type == 2){-->
                    <!--_this.addData.OwnAdminId = ''-->
                    <!--_this.addData.upAdminId = _this.information.AdminId-->
                    <!--console.log(typeId,adminId,type)-->
                <!--}-->
                <!--// if(typeId == 3){-->
                <!--//     _this.addData.OwnAdminId = ''-->
                <!--//     _this.addData.upAdminId = adminId-->
                <!--// }-->
                <!--_this.addData.typeId = typeId,-->
                    <!--_this.modal4 = !_this.modal4-->
                <!--console.log(_this.addData)-->
            <!--},-->
            <!--// 修改数据-->
            <!--modifyData : function (AdminId,AccountLogin, AccountPassword,AdminName,OrganizationName, OrganizationLinkman, OrganizationPhone) {-->
                <!--var _this = this-->
                <!--_this.modal3 = !_this.modal3-->
                <!--_this.adminLogin = AccountLogin-->
                <!--_this.adminPassword = AccountPassword-->
                <!--_this.organizationPhone = OrganizationPhone-->
                <!--_this.organizationLinkman = OrganizationLinkman-->
                <!--_this.organizationName = OrganizationName-->
                <!--_this.AdminName = AdminName-->
                <!--_this.AdminId = AdminId-->
            <!--_this.modifyType = true-->
                <!--console.log(AdminId,AccountLogin, AccountPassword,AdminName,OrganizationName, OrganizationLinkman, OrganizationPhone)-->
            <!--},-->
            <!--addMember1 :function(){-->
                <!--var _this = this-->
                <!--console.log(_this.AreaId,_this.Level,_this.AdminId)-->
                <!--if(_this.modifyType == false){-->
                    <!--if(_this.organizationName != ''&&_this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != ''&& _this.adminLogin != ''){-->
                        <!--// if(_this.cityList.length == 0){-->
                        <!--//     // var AreaId = _this.information.AreaId-->
                        <!--//     _this.AreaId = _this.information.AreaId-->
                        <!--// }-->

                        <!--_this.send({-->
                            <!--url : '/AddMember',-->
                            <!--data: {-->
                                <!--Level : _this.Level,-->
                                <!--AdminId : _this.AdminId,-->
                                <!--AccountLogin : _this.adminLogin,-->
                                <!--AccountPassword : _this.adminPassword,-->
                                <!--OrganizationLinkman : _this.organizationLinkman,-->
                                <!--OrganizationPhone : _this.organizationPhone,-->
                                <!--OrganizationName : _this.organizationName,-->
                                <!--AreaId : _this.AreaId,-->
                            <!--},-->
                            <!--success: function (res) {-->
                                <!--_this.adminList()-->
                                <!--_this.adminLogin=''-->
                                <!--_this.adminPassword=''-->
                                <!--_this.organizationLinkman=''-->
                                <!--_this.organizationPhone=''-->
                                <!--_this.organizationName =''-->
                                <!--_this.AreaId = ''-->
                                <!--_this.model8 = ''-->
                            <!--}-->
                        <!--})-->
                    <!--}else{-->
                        <!--_this.$Notice.warning({-->
                            <!--title:'系统提示',-->
                            <!--desc:'输入完成后添加',-->
                        <!--})-->
                    <!--}-->
                <!--}else {-->
                    <!--if(_this.organizationName != ''&&_this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != ''&& _this.adminLogin != ''){-->
                        <!--// if(_this.cityList.length == 0){-->
                        <!--//     // var AreaId = _this.information.AreaId-->
                        <!--//     _this.AreaId = _this.information.AreaId-->
                        <!--// }-->
                        <!--_this.send({-->
                            <!--url : '/AdminEdit',-->
                            <!--data: {-->
                                <!--AdminId :  _this.AdminId,-->
                                <!--AccountLogin : _this.adminLogin,-->
                                <!--AccountPassword :_this.adminPassword,-->
                                <!--AdminName : _this.AdminName,-->
                                <!--OrganizationName : _this.organizationName,-->
                                <!--OrganizationLinkman :_this.organizationLinkman,-->
                                <!--OrganizationPhone :_this.organizationPhone,-->
                            <!--},-->
                            <!--success: function (res) {-->
                                <!--_this.adminList()-->
                                <!--_this.adminLogin=''-->
                                <!--_this.adminPassword=''-->
                                <!--_this.organizationLinkman=''-->
                                <!--_this.organizationPhone=''-->
                                <!--_this.organizationName =''-->
                                <!--_this.AreaId = ''-->
                                <!--_this.model8 = ''-->
                            <!--}-->
                        <!--})-->
                    <!--}else{-->
                        <!--_this.$Notice.warning({-->
                            <!--title:'系统提示',-->
                            <!--desc:'输入完成后添加',-->
                        <!--})-->
                    <!--}-->
                <!--}-->
            <!--},-->
            <!--ok:function () {-->
                <!--var _this = this-->
                <!--var op = _this.information.OrganizationId+'p'+_this.lawyerPhone +'p'+1-->
                <!--_this.send({-->
                    <!--url : '/getVillageSunCode',-->
                    <!--data: {-->
                        <!--OrganizationId : _this.information.OrganizationId,-->
                        <!--scene: op,-->
                        <!--userType : 'lawyer',-->
                        <!--page: 'pages/login/login',-->
                        <!--width: '430',-->
                        <!--jpg: 'jpg',-->
                        <!--LawyerName : _this.lawyerName,-->
                        <!--LawyerPhone : _this.lawyerPhone-->
                    <!--},-->
                    <!--success: function (res) {-->
                        <!--_this.listData = []-->
                        <!--_this.page = '1'-->
                        <!--_this.lawList()-->
                    <!--}-->
                <!--})-->
            <!--},-->
            <!--cancel () {-->
                <!--var _this = this-->
                <!--_this.AdminId = ''-->
                <!--_this.adminLogin=''-->
                <!--_this.adminPassword=''-->
                <!--_this.organizationLinkman=''-->
                <!--_this.organizationPhone=''-->
                <!--_this.organizationName =''-->
                <!--_this.AreaId = ''-->
                <!--_this.model8 = ''-->
            <!--},-->
            <!--cancel1 () {-->
                <!--var _this = this-->
                <!--_this.AdminId = ''-->
                <!--_this.adminLogin=''-->
                <!--_this.adminPassword=''-->
                <!--_this.organizationLinkman=''-->
                <!--_this.organizationPhone=''-->
                <!--_this.organizationName =''-->
                <!--_this.AreaId = ''-->
                <!--_this.model8 = ''-->
                <!--_this.Level = ''-->
                <!--_this.modifyType = false-->
            <!--},-->
            <!--enter:function(index){-->
                <!--console.log(index)-->
                <!--this.arCode = true-->
                <!--this.current = index-->
            <!--},-->
            <!--leave(){-->
                <!--this.arCode = false-->
                <!--this.current = null-->
            <!--},-->
            <!--//    管理列表-->
            <!--adminList : function () {-->
                <!--var _this = this-->
                <!--_this.send({-->
                    <!--url : '/justiceList',-->
                    <!--data: {-->
                        <!--AdminId : _this.information.AdminId,-->
                        <!--Level : _this.information.adminLevel,-->
                        <!--page : _this.page,-->
                        <!--pageSize : _this.pageSize-->
                    <!--},-->
                    <!--success: function (res) {-->
                        <!--console.log(res)-->
                        <!--_this.listData = res.list-->
                        <!--_this.count = res.count-->
                    <!--}-->
                <!--})-->
                <!--// _this.send({-->
                <!--//     url : '/AllAdminList',-->
                <!--//     data: {-->
                <!--//         AdminId : _this.information.AdminId,-->
                <!--//         Level : _this.information.adminLevel,-->
                <!--//         page : _this.page,-->
                <!--//         pageSize : _this.pageSize-->
                <!--//     },-->
                <!--//     success: function (res) {-->
                <!--//         console.log(res)-->
                <!--//         _this.listData = res.list-->
                <!--//         _this.count = res.count-->
                <!--//     }-->
                <!--// })-->
            <!--},-->
            <!--deleteLawFirm : function (adminid,organizationId,pid) {-->
                <!--console.log(pid)-->
                <!--var _this = this-->
                <!--_this.send({-->
                    <!--url : '/deleteMember',-->
                    <!--confirm:true,-->
                    <!--data: {-->
                        <!--AdminId : adminid,-->
                        <!--Level : _this.information.Level,-->
                        <!--OrganizationId : organizationId,-->
                        <!--Pid : pid-->
                    <!--},-->
                    <!--success: function (res) {-->
                        <!--console.log(res)-->
                        <!--_this.listData = []-->
                        <!--_this.page = '1'-->
                        <!--_this.adminList()-->
                    <!--}-->
                <!--})-->
            <!--},-->
            <!--//    地区-->
            <!--getAreaData : function () {-->
                <!--var _this = this-->
                <!--_this.send({-->
                    <!--url : '/GetAreaData',-->
                    <!--data: {-->
                        <!--AreaId: _this.information.AreaId,-->
                    <!--},-->
                    <!--success: function (res) {-->
                        <!--//-->
                        <!--_this.cityList = res[1]-->
                        <!--console.log(_this.cityList)-->

                        <!--//-->
                        <!--convertTree(res[1])-->
                        <!--function convertTree(tree) {-->
                            <!--const result = []-->
                            <!--tree.forEach((item) => {-->
                                <!--// 解构赋值-->
                                <!--let {-->
                                    <!--AreaId: value,-->
                                    <!--AreaName: label,-->
                                    <!--children: children-->
                                <!--} = item-->
                                <!--if (children) {-->
                                    <!--children = convertTree(children)-->
                                <!--}-->
                                <!--result.push({-->
                                    <!--value,-->
                                    <!--label,-->
                                    <!--children-->
                                <!--})-->
                            <!--})-->
                            <!--_this.addressData = result-->
                            <!--return result-->
                        <!--}-->
                        <!--console.log(_this.addressData)-->
                    <!--}-->
                <!--})-->
            <!--},-->
            <!--choice : function(value){-->
                <!--var _this = this-->
                <!--console.log(value)-->
                <!--var data = value.split(',')-->
                <!--console.log(_this.cityList[data[1]].children)-->
                <!--if(data[2] == '1'){-->
                    <!--_this.areaData = _this.cityList[data[1]].children-->
                    <!--_this.addressID = data[0]-->
                <!--}else {-->

                <!--}-->
                <!--_this.send({-->
                    <!--url : '/GetfileList',-->
                    <!--data: {-->
                        <!--AreaId: data[0],-->
                        <!--AdminId: _this.information.AdminId,-->
                    <!--},-->
                    <!--success: function (res) {-->
                        <!--console.log(res)-->
                        <!--var ElectronicsList = []-->
                        <!--_this.ElectronicsList = res-->
                    <!--}-->

                <!--})-->
            <!--},-->
        <!--},-->
        <!--mounted () {-->
            <!--var _this = this-->
            <!--if(window.sessionStorage.adminLevel == undefined){-->
                <!--// this.$router.push({name:'login'})-->
            <!--}else {-->
                <!--// var data = JSON.parse(window.sessionStorage.information)-->
                <!--// SYSCONFIG.server.information = JSON.parse(window.sessionStorage.information)-->
                <!--// console.log(SYSCONFIG.server.information.AccountLogin)-->
                <!--this.information = window.sessionStorage-->
                <!--if(_this.information.adminLevel == 1 || _this.information.adminLevel == 2){-->
                    <!--_this.adminList()-->
                <!--}-->
                <!--_this.getAreaData()-->
            <!--}-->
        <!--},-->
    <!--}-->
<!--//    1、为睿后台资产列表接口对接-->
<!--</script>-->

<style scoped>
    .home{background-color: rgb(241,239,240);position: absolute;width:100%;height:100%;overflow: auto;}
    .screen{width: calc(100% - 80px);display: inline-block;}
    .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
    /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
    .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #00b86c;padding: 0px 16px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #00b86c;}
    .screen :deep(.ivu-tabs-ink-bar){background-color: #00b86c;}
    .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
    .bbb :deep(.ivu-select-single .ivu-select-selection .ivu-select-placeholder){color: #333}
    .house{width: 72px;height: 72px;margin-top: 29px;}
    .number{font-size: 48px;color: #7a7a7a;}
    .tipsName{font-size: 16px;color: #8d8d8d;}
    .displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
    .administration{width: 100%;background-color: #fff;margin-top: 40px;border-bottom: 1px solid #dddddd;height: 80px;text-align: left;margin-bottom: 0}
    .administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
    .label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
    .forMtitle1{width:calc(100%);background-color: #f9f9f9;height: 60px;display: inline-block;text-align: center;}
    .forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;line-height: 60px;overflow: hidden;white-space: nowrap;width: 20%}
    .forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;margin:40px 40px 0 40px;height: 60px;}
    .forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
    .forMcontent{width: calc(100% - 80px);height: 60px;color: #666666;font-size: 16px;margin:0px 40px 0 40px;border-bottom: 1px solid #dddddd;text-align: center;}
    .forMcontent p {line-height: 60px;display: inline-block;width: 20%;overflow: hidden;white-space: nowrap;}
    .smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}
    .alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -33px;}
    .alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
    .qrCode{width: 100px;height: 100px;}
    .addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
    .myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
    .icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
    .contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
    .single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
    .inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

    .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
    .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
    .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
    .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
    .newlist div{display: inline-block;width: 20%;text-align: center;    white-space: nowrap;}
    .icon :deep(.ivu-icon){position: absolute;margin-top: 18px;top: 0}
    .icon /deep/ .ivu-collapse-header{min-height: 50px;background-color: #FFF;border-left: 1px solid rgb(221, 221, 221);border-right: 1px solid rgb(221, 221, 221);}
    .listTitle div{display: inline-block;width: 20%;text-align: center}
    .listTitle{height: 52px;background: #F9F9F9;border-top: 1px solid #DDDDDD;line-height: 52px;padding-left: 16px;font-weight: bold;border-left: 1px solid #DDDDDD;border-right: 1px solid #DDDDDD;}
</style>

<template>
    <div class="home">
        <!--<HelloWorld>-->

        <!--</HelloWorld>-->
        <div style="background-color: rgb(241,239,240);font-size: 26px">
            <div  class="font-size">
                <div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;font-size: 16px;text-align: left;">
                    <div style="margin: 0 20px">
                      <div style="margin: 10px">
                        <Button type="primary" icon="md-add" @click.stop="addTto(3,information.AdminId,2)">添加</Button>
                        <!--司法所-->
                      </div>

                      <!--information.adminLevel != 1-->
                      <div class="listTitle" v-if="information.adminLevel != 1">
                        <div style="width:10%">序号</div>
                        <div style="width:10%" v-if="information.Hierarchy == 3">乡镇（街道）</div>
                        <div style="width:10%" v-else>区县</div>
                        <div>账号</div>
                        <div>密码</div>
                        <div style="width:40%">操作</div>
                      </div>
                      <Collapse v-if="information.adminLevel != 1" v-model="value1" accordion simple>
                        <Panel :name="index.toString()" v-for="(value,index,k) in electronicsList" class="icon">
                          <div class="newlist" style="display:inline-block;width: 100%;border-bottom: 1px solid #dedede;line-height: 50px">
                            <div style="width:10%">
                              <span v-if="index+1 < 10">0{{index+1}}</span>
                              <span v-else>{{index+1}}</span>
                            </div>
                            <div style="width:10%" v-if="value.Level == 2">{{value.AreaName}}</div>
                            <div style="width:10%" v-else>{{value.AdminName}}</div>
                            <div>{{value.AccountLogin}}</div>
                            <div>{{maskPassword(value.AccountPassword)}}</div>
                            <div @click.stop="onekeyLogin()"  style="width:40%">
                              <router-link style="margin: 0 10px 0 10px" :to="{path:'/login', query:{adminLogin:value.AccountLogin,adminPassword:require('js-base64').Base64.encode(value.AccountPassword)}}" target="_blank">
                                <Button type="primary">
                                  <img src="/img/icon/yijian.png" style="vertical-align: top;margin-top: 1px;"/>
                                  一键登录
                                </Button>
                              </router-link>
                              <Button type="warning" @click.stop="addTto(1,value.AdminId,1,value.AdminName,value.AccountLogin,value.AccountPassword,value.AreaId,value.AreaName,value.AccountId)">
                                <img src="/img/icon/editcopy.png" style="vertical-align: top;margin-top: 1px;"/>
                                修改
                              </Button>
                              <Button type="error"  @click.stop="deleteClick(value.AdminId)" style="margin: 0 10px 0 10px">
                                <img src="/img/icon/shanchu.png" style="vertical-align: top;margin-top: 1px;"/>
                                删除
                              </Button>
                              <Button type="info" @click="getAreaSPC(value,value.AreaId)" style="margin: 0 10px 0 0">
                                小程序码
                              </Button>
                            </div>
                          </div>
                          <div slot="content" class="publicWelfare" v-if="information.Level != 3 && information.adminLevel != 2">
                            <!--司法局列表-->
                            <div class="listTitle">
                              <div style="width:10%">序号</div>
                              <div style="width:10%" v-if="value.list[0].Level == 2">区县</div>
                              <div style="width:10%" v-else>乡镇（街道）</div>
                              <div>账号</div>
                              <div>密码</div>
                              <div style="width:40%">操作</div>
                            </div>
                            <div v-for="(value2,index2,k2) in value.list"class="icon">
                              <div class="newlist" style="padding-left: 16px;display:inline-block;width: 100%;border-bottom: 1px solid #dedede;line-height: 50px;border-left: 1px solid #DDDDDD;border-right: 1px solid #DDDDDD;">
                                <div style="width:10%">
                                  <span v-if="index2+1 < 10">0{{index2+1}}</span>
                                  <span v-else>{{index2+1}}</span>
                                </div>
                                <div style="width:10%" v-if="value2.Level == 2">{{value2.AreaName}}</div>
                                <div style="width:10%" v-else>{{value2.AdminName}}</div>
                                <div>{{value2.AccountLogin}}</div>
                                <div>{{maskPassword(value2.AccountPassword)}}</div>
                                <div @click.stop="onekeyLogin()"  style="width:40%">
                                  <router-link style="margin: 0 10px" :to="{path:'/login', query:{adminLogin:value2.AccountLogin,adminPassword:value2.AccountPassword}}" target="_blank">
                                    <Button type="primary">
                                      <img src="/img/icon/yijian.png" style="vertical-align: top;margin-top: 1px;"/>
                                      一键登录
                                    </Button>
                                  </router-link>
                                  <Button type="warning" @click.stop="addTto(2,value2.AdminId,1,value2.AdminName,value2.AccountLogin,value2.AccountPassword,value2.AreaId,value2.AreaName,value2.AccountId)">
                                    <img src="/img/icon/editcopy.png" style="vertical-align: top;margin-top: 1px;"/>
                                    修改
                                  </Button>
                                  <Button type="error"  @click.stop="deleteClick(value2.AdminId)" style="margin: 0 10px">
                                    <img src="/img/icon/shanchu.png" style="vertical-align: top;margin-top: 1px;"/>
                                    删除
                                  </Button>
                                  <Button type="info" @click="getAreaSPC(value2,value2.AreaId)">
                                    {{buttonCharacters}}
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <!--                                <Collapse v-model="value2" accordion>-->
                            <!--                                    <Panel :name="index2.toString()" v-for="(value2,index2,k2) in value.list" class="icon">-->
                            <!--                                    </Panel>-->
                            <!--                                </Collapse>-->
                          </div>
                        </Panel>
                      </Collapse>
<!--                      -->
                      <div class="listTitle" v-if="information.adminLevel == 1">
                        <div style="width:10%">序号</div>
                        <div>账号</div>
                        <div>密码</div>
                        <div style="width:50%">操作</div>
                      </div>
                      <Collapse v-if="information.adminLevel == 1" @on-change="justiceClick" :accordion="true">
                        <Panel :name="index.toString()" v-for="(value,index) in electronicsList" class="icon" >
                          <div class="newlist" style="display:inline-block;width: 100%;border-bottom: 1px solid #dedede;line-height: 50px">
                            <div style="width:10%">
                              <span v-if="index+1 < 10">0{{index+1}}</span>
                              <span v-else>{{index+1}}</span>
                            </div>
                            <div>{{value.AdminName}}</div>
                            <div>{{maskPassword(value.AccountPassword)}}</div>
                            <div @click.stop="onekeyLogin()"  style="width:50%">
                              <router-link style="margin: 0 10px 0 10px" :to="{path:'/login', query:{adminLogin:value.AccountLogin,adminPassword:require('js-base64').Base64.encode(value.AccountPassword)}}" target="_blank">
                                <Button type="primary">
                                  <img src="/img/icon/yijian.png" style="vertical-align: top;margin-top: 1px;"/>
                                  一键登录
                                </Button>
                              </router-link>
                              <Button type="warning" @click.stop="addTto(1,value.AdminId,1,value.AdminName,value.AccountLogin,value.AccountPassword,value.AreaId,value.AreaName,value.AccountId)">
                                <img src="/img/icon/editcopy.png" style="vertical-align: top;margin-top: 1px;"/>
                                修改
                              </Button>
                              <Button type="error"  @click.stop="deleteClick(value.AdminId)" style="margin: 0 10px 0 10px">
                                <img src="/img/icon/shanchu.png" style="vertical-align: top;margin-top: 1px;"/>
                                删除
                              </Button>
                              <Button type="info" @click="getAreaSPC(value,value.AreaId)" style="margin: 0 10px 0 0">
                                {{buttonCharacters}}
                              </Button>
                            </div>
                          </div>
                          <div slot="content" class="publicWelfare" >
                            <div class="listTitle">
                              <div style="width:10%">序号</div>
                              <div style="width:10%">区县</div>
                              <div>账号</div>
                              <div>密码</div>
                              <div style="width:40%">操作</div>
                            </div>
                            <Collapse v-model="valuen" accordion simple>
                              <Panel :name="i.toString()" v-for="(item,i) in justiceData" class="icon">
                                <div class="newlist" style="display:inline-block;width: 100%;border-bottom: 1px solid #dedede;line-height: 50px">
                                  <div style="width:10%">
                                    <span v-if="i+1 < 10">0{{i+1}}</span>
                                    <span v-else>{{i+1}}</span>
                                  </div>
                                  <div style="width:10%">{{item.AreaName}}</div>
                                  <div>{{item.AdminName}}</div>
                                  <div>{{maskPassword(item.AccountPassword)}}</div>
                                  <div @click.stop="onekeyLogin()"  style="width:40%">
                                    <router-link style="margin: 0 10px 0 10px" :to="{path:'/login', query:{adminLogin:item.AccountLogin,adminPassword:require('js-base64').Base64.encode(item.AccountPassword)}}" target="_blank">
                                      <Button type="primary">
                                        <img src="/img/icon/yijian.png" style="vertical-align: top;margin-top: 1px;"/>
                                        一键登录
                                      </Button>
                                    </router-link>
                                    <Button type="warning" @click.stop="addTto(1,item.AdminId,1,item.AdminName,item.AccountLogin,item.AccountPassword,item.AreaId,item.AreaName,item.AccountId)">
                                      <img src="/img/icon/editcopy.png" style="vertical-align: top;margin-top: 1px;"/>
                                      修改
                                    </Button>
                                    <Button type="error"  @click.stop="deleteClick(item.AdminId)" style="margin: 0 10px 0 10px">
                                      <img src="/img/icon/shanchu.png" style="vertical-align: top;margin-top: 1px;"/>
                                      删除
                                    </Button>
                                    <Button type="info" @click="getAreaSPC(item,item.AreaId)" style="margin: 0 10px 0 0">
                                      {{buttonCharacters}}
                                    </Button>
                                  </div>
                                </div>
                                <div slot="content" class="publicWelfare">
                                  <!--司法局列表-->
                                  <div class="listTitle">
                                    <div style="width:10%">序号</div>
                                    <div style="width:10%">区县</div>
                                    <div>账号</div>
                                    <div>密码</div>
                                    <div style="width:40%">操作</div>
                                  </div>
                                  <div v-for="(item2,i1,k2) in item.list"class="icon">
                                    <div class="newlist" style="padding-left: 16px;display:inline-block;width: 100%;border-bottom: 1px solid #dedede;line-height: 50px;border-left: 1px solid #DDDDDD;border-right: 1px solid #DDDDDD;">
                                      <div style="width:10%">
                                        <span v-if="i1+1 < 10">0{{i1+1}}</span>
                                        <span v-else>{{i1+1}}</span>
                                      </div>
                                      <div style="width:10%">{{item2.AreaName}}</div>
                                      <div>{{item2.AdminName}}</div>
                                      <div>{{maskPassword(item2.AccountPassword)}}</div>
                                      <div @click.stop="onekeyLogin()"  style="width:40%">
                                        <router-link style="margin: 0 10px" :to="{path:'/login', query:{adminLogin:item2.AccountLogin,adminPassword:require('js-base64').Base64.encode(item2.AccountPassword)}}" target="_blank">
                                          <Button type="primary">
                                            <img src="/img/icon/yijian.png" style="vertical-align: top;margin-top: 1px;"/>
                                            一键登录
                                          </Button>
                                        </router-link>
                                        <Button type="warning" @click.stop="addTto(2,item2.AdminId,1,item2.AdminName,item2.AccountLogin,item2.AccountPassword,item2.AreaId,value2.AreaName,value2.AccountId)">
                                          <img src="/img/icon/editcopy.png" style="vertical-align: top;margin-top: 1px;"/>
                                          修改
                                        </Button>
                                        <Button type="error"  @click.stop="deleteClick(item2.AdminId)" style="margin: 0 10px">
                                          <img src="/img/icon/shanchu.png" style="vertical-align: top;margin-top: 1px;"/>
                                          删除
                                        </Button>
                                        <Button type="info" @click="getAreaSPC(item2,item2.AreaId)">
                                          {{buttonCharacters}}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <!--                                <Collapse v-model="value2" accordion>-->
                                  <!--                                    <Panel :name="index2.toString()" v-for="(value2,index2,k2) in value.list" class="icon">-->
                                  <!--                                    </Panel>-->
                                  <!--                                </Collapse>-->
                                </div>
                              </Panel>
                            </Collapse>
                          </div>
                        </Panel>
                      </Collapse>
                      <div style="text-align: left;margin: 20px;font-size: 16px">
                        <!--<Page :total="count" page-size="5"  show-elevator />-->
                        <!--<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>-->
                        <Page
                            :total="count"
                            :page-size="pageSize"
                            show-elevator
                            @on-change="page = $event;totalAdminList()"
                        />
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <!--添加-->
        <Modal
                v-model="modal1"
                title="添加"
                @on-ok="confirm"
                @on-cancel="cancel">
            <Form :model="addData" :label-width="60">
                <FormItem label="名称">
                    <Input v-model="addData.AdminName" placeholder="名称"></Input>
                </FormItem>
                <FormItem label="登录名">
                    <Input v-model="addData.AdminLogin" placeholder="登录名"></Input>
                </FormItem>
                <FormItem label="密码">
                    <Input v-model="addData.AdminPassword" placeholder="密码"></Input>
                </FormItem>
                <FormItem label="地址" :class="[addData.AreaName=='请选择'?'bbb1':'bbb']" v-if="modify == true">
                    <Select v-model="area1" style="width:200px" placeholder="">
                        <Option  v-for="(item,index) in areaListData1" :value="JSON.stringify(item)" :key="'area1'+index">{{ item.AreaName }}</Option>
                    </Select>
                    <Select v-if="areaListData2.length>0" v-model="area2" style="width:200px" placeholder="">
                        <Option  v-for="(item,index) in areaListData2" :value="JSON.stringify(item)" :key="'area2'+index">{{ item.AreaName }}</Option>
                    </Select>
                    <Select v-if="areaListData3.length>1" v-model="area3" style="width:200px" placeholder="">
                        <Option  v-for="(item,index) in areaListData3" :value="JSON.stringify(item)" :key="'area3'+index">{{ item.AreaName }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="地址" v-if="modify == false">
                    <Input v-model="addData.AreaName" placeholder="地址"></Input>
                    <Button type="primary" @click="modify = !modify;area1 == ''">修改地址</Button>
                </FormItem>
            </Form>
        </Modal>
        <!--律所-->
        <Modal
                v-model="modal3"
                title="添加律所"
                @on-ok="addMember1"
                @on-cancel="cancel1">

            <div class="single">
                <p class="contacts">律所名称</p>
                <input type="text" class="inputWord" placeholder="请输入村居律所名称" v-model="organizationName">
            </div>
            <div class="single">
                <p class="contacts">联系人</p>
                <input type="text" class="inputWord" placeholder="请输入联系人" v-model="organizationLinkman">
            </div>
            <div class="single">
                <p class="contacts">联系电话</p>
                <input type="text" class="inputWord" placeholder="请输入联系电话" v-model="organizationPhone">
            </div>
            <div class="single" :class="[addData.AreaName=='请选择'?'bbb1':'bbb']" v-if="modify == true">
                <p class="contacts">地址</p>
                <Select v-model="area1" style="width:200px" placeholder="">
                    <Option  v-for="(item,index) in areaListData1" :value="JSON.stringify(item)" :key="'area1'+index">{{ item.AreaName }}</Option>
                </Select>
                <Select v-if="areaListData2.length>0" v-model="area2" style="width:200px" placeholder="">
                    <Option  v-for="(item,index) in areaListData2" :value="JSON.stringify(item)" :key="'area2'+index">{{ item.AreaName }}</Option>
                </Select>
                <Select v-if="areaListData3.length>1" v-model="area3" style="width:200px" placeholder="">
                    <Option  v-for="(item,index) in areaListData3" :value="JSON.stringify(item)" :key="'area3'+index">{{ item.AreaName }}</Option>
                </Select>
            </div>
            <div class="single">
                <p class="contacts">登录名称</p>
                <input type="text" class="inputWord" placeholder="请输入登录名" v-model="adminLogin">
            </div>
            <div class="single">
                <p class="contacts">登录密码</p>
                <input type="text" class="inputWord" placeholder="请输入登录密码" v-model="adminPassword">
            </div>
        </Modal>

      <Modal
          v-model="areaSPCPanel.state"
          title="区域二维码">
        <img v-if="areaSPCPanel.imgSrc" :src="areaSPCPanel.imgSrc">
        <div style="text-align: center">
          <div id="qrcode" ref="qrcode" style="display: inline-block;"></div>
        </div>
      </Modal>
    </div>
</template>

<script>
import QRCode from 'qrcode2'
    // import HelloWorld from '../../components/HelloWorld.vue'
    export default {
        name: "home",
        // components: {HelloWorld,},
        data () {
            return {
                area1:[],
                area2:[],
                area3:[],
                areaListData1:[],
                areaListData2:[],
                areaListData3:[],
                modify : true,
                information : [],
                electronicsList : [],
                value1: '',
                value2: '0',
                value3: '0',
                value4: '0',
                valuen: '',
                page : 1,
                pageSize : 50,
                modal1: false,
                modal3: false,
                //地址
                areaListData : [],
                justiceData : [],
                areaList1 : '',
                areaList2 : '',
                areaList3 : '',
                labelInValue : true,
                addressId :['','',''],
                cityId : '',//获取市的index
                adminLogin : '',
                adminPassword : '',
                organizationPhone : '',
                organizationLinkman : '',
                organizationName : '',
                count : 0,
                //
                addData: {
                    typeId : '',
                    upAdminId : '',
                    AdminLogin : '',//登录名
                    AdminPassword  : '',//密码
                    AreaId  : '',//区域
                    AreaName  : '请选择',//区域名称
                    AdminName  : '',//名称
                    AccountId  : '',//
                },

              areaSPCPanel:{
                state:false,
                imgSrc:'',
              },
              buttonCharacters : '小程序码'
            }
        },
        watch:{
            'area1':function (item) {
                if(item != ''){
                    item = JSON.parse(item)
                    console.log(item)
                    if(item.AreaId){
                        this.addData.AreaId = item.AreaId
                    }
                    this.areaListData2 = []
                    this.areaListData3 = []
                    if(item.list){
                        this.areaListData2 = item.list
                    }
                }

            },
            'area2':function (item) {
                if(item != ''){
                    item = JSON.parse(item)
                    if(item.AreaId){
                        this.addData.AreaId = item.AreaId
                    }
                    this.areaListData3 = []
                    if(item.list){
                        this.areaListData3 = item.list
                    }
                }
            },
            'area3':function (item) {
                if(item != ''){
                    item = JSON.parse(item)
                    if(item.AreaId){
                        this.addData.AreaId = item.AreaId
                    }
                }
            },
        },
        methods:{
          maskPassword(password) {
            if (password.length <= 3) {
              return password; // Return the password as is if it's too short to mask
            }
            // Calculate the number of asterisks to insert
            const numAsterisks = password.length - 3;
            // Get the first two characters of the password
            const firstTwoChars = password.substring(0, 2);
            // Get the last two characters of the password
            const lastTwoChars = password.substring(password.length - 2);
            // Construct the masked password with asterisks in the middle
            const maskedPassword = firstTwoChars + '*'.repeat(numAsterisks) + lastTwoChars;
            return maskedPassword;
          },
            totalAdminList : function () {
                var _this = this
                _this.addData.typeId = ''
                _this.addData.upAdminId = ''
                _this.addData.AdminLogin = ''
                _this.addData.AdminPassword = ''
                _this.addData.AreaId = ''
                _this.addData.AreaName = '请选择'
                _this.addData.AdminName = '',
                    _this.addressId =  ['','',''],
                    _this.areaList1 = ''
                _this.areaList2 = ''
                _this.areaList3 = ''
                _this.area1 = []
                _this.area2 = []
                _this.area3 = []
                _this.areaListData2 = []
                _this.areaListData3 = []
                if(_this.information.adminLevel == 2){
                    _this.send({
                        url : '/justiceList',
                        data: {
                            AdminId : window.sessionStorage.AdminId,
                            Level : _this.information.adminLevel,
                            page: _this.page,
                            pageSize : _this.pageSize,
                        },
                        success: function (res) {
                            _this.electronicsList = res.list
                            _this.count = res.count
                            console.log(res.list)
                        }
                    })
                }
                if(_this.information.adminLevel == 1){
                    _this.send({
                        url : '/AllAdminList',
                        data: {
                            AdminId : window.sessionStorage.AdminId,
                            Level : _this.information.adminLevel,
                            page: _this.page,
                            pageSize : _this.pageSize,
                        },
                        success: function (res) {
                            _this.electronicsList = res.list
                            _this.count = res.count
                            console.log(res.list)
                        }
                    })
                }

            },
            justiceClick : function (e){
                var _this = this
              _this.justiceData = []
              console.log(e)
              if(e){
                _this.send({
                  url : '/justiceList',
                  data: {
                    AdminId : _this.electronicsList[e].AdminId,
                    Level : _this.electronicsList[e].Level,
                    page: _this.page,
                    pageSize : _this.pageSize,
                  },
                  success: function (res) {
                    _this.justiceData = res.list
                    // _this.count = res.count
                    console.log(res.list)
                  }
                })
              }

            },
            addTto : function (typeId,adminId,type,adminName,adminLogin,adminPassword,areaId,AreaName,AccountId) {
                var _this = this
                if(AreaName){
                    _this.modify = false
                }else {
                    _this.modify = true
                }
                console.log(typeId,adminId,type,adminName,adminLogin,adminPassword,areaId,AreaName)
                //type==1编辑   type==2添加
                if(type == 1){
                    _this.addData.OwnAdminId = adminId
                    _this.addData.upAdminId = ''
                    _this.addData.AdminName = adminName
                    _this.addData.AdminLogin = adminLogin
                    _this.addData.AdminPassword = adminPassword
                    _this.addData.AreaId = areaId
                    _this.addData.AreaName = AreaName
                    _this.addData.AccountId = AccountId
                }
                if(type == 2){
                    _this.addData.OwnAdminId = ''
                    _this.addData.AccountId = ''
                    _this.addData.upAdminId = adminId
                    console.log(typeId,adminId,type)
                }
                // if(typeId == 3){
                //     _this.addData.OwnAdminId = ''
                //     _this.addData.upAdminId = adminId
                // }
                _this.addData.typeId = typeId,
                    _this.modal1 = !_this.modal1
                console.log(_this.addData)
            },
            //删除
            deleteClick : function(ownAdminId){
                var _this = this
                _this.send({
                    confirm:true,
                    url : '/DeleteTotalAdmin',
                    data: {
                        AdminId : _this.information.AdminId,
                        OwnAdminId : ownAdminId,
                    },
                    success: function (res) {
                        _this.totalAdminList()
                    }
                })
            },
            confirm : function () {
                var _this = this
                console.log(_this.addData)
                _this.send({
                    url : '/ManageTotalAdmin',
                    data: {
                        AdminId : _this.information.AdminId,
                        TypeId : _this.addData.typeId,
                        OwnAdminId :_this.addData.OwnAdminId,
                        UpAdminId : _this.addData.upAdminId,
                        AdminLogin : _this.addData.AdminLogin,
                        AdminPassword  : _this.addData.AdminPassword,
                        AreaId  : _this.addData.AreaId,
                        AdminName  : _this.addData.AdminName,
                        RoleType  : window.sessionStorage.AccountTypeId,
                        AccountId  : _this.addData.AccountId,
                    },
                    success: function (res) {
                        _this.$Notice.success({
                            desc:'操作成功',
                        })
                        _this.totalAdminList()
                    }
                })
            },
            //弹窗取消
            cancel () {
                var _this = this
                _this.addData.typeId = ''
                _this.addData.upAdminId = '',
                    _this.addData.AdminLogin = '',//登录名
                    _this.addData.AdminPassword  = '',//密码
                    _this.addData.AreaId  = '',//区域
                    _this.addData.AdminName  = ''//名称
                _this.addData.AreaName = '请选择'
                _this.areaList1 = ''
                _this.areaList2 = ''
                _this.areaList3 = ''
                _this.area1 = []
                _this.area2 = []
                _this.area3 = []
                _this.areaListData2 = []
                _this.areaListData3 = []
            },
            //    地区
            areaList : function () {
                var _this = this
                _this.send({
                    url : '/AreaAdminList',
                    data: {},
                    success: function (res) {
                        _this.areaListData1 = res
                        _this.areaListData = res
                        console.log(res)
                    }
                })
            },
            //    选择
            choice : function(value){
                var _this = this
                var data = value.value.split(',')
                if(data[1] == 0){
                    _this.addressId = ['','','']
                }if(data[1] == 1){
                    _this.addressId[2] = ''
                    _this.addressId[1] = ''
                    _this.cityId = data[2]
                }
                _this.addData.AreaId = data[0]
                _this.addressId[data[1]] = data[0]
                console.log(_this.addressId)
                // console.log(data)
                // console.log(_this.addressId)
            },
            addTto1 : function(AreaId,Level,AdminId){
                console.log(AreaId,Level,AdminId)
                var _this = this
                _this.AreaId = AreaId
                _this.Level = Level
                _this.AdminId = AdminId
                _this.modifyType = false
            },
            addMember1 :function(){
                var _this = this
                console.log(_this.AreaId,_this.Level,_this.AdminId, _this.modifyType)
                if(_this.modifyType == false){
                    if(_this.organizationName != ''&&_this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != ''&& _this.adminLogin != ''){
                        // if(_this.cityList.length == 0){
                        //     // var AreaId = _this.information.AreaId
                        //     _this.AreaId = _this.information.AreaId
                        // }

                        _this.send({
                            url : '/AddMember',
                            data: {
                                Level : _this.Level,
                                AdminId : _this.AdminId,
                                AdminLogin : _this.adminLogin,
                                AdminPassword : _this.adminPassword,
                                OrganizationLinkman : _this.organizationLinkman,
                                OrganizationPhone : _this.organizationPhone,
                                OrganizationName : _this.organizationName,
                                AreaId : _this.addData.AreaId,
                            },
                            success: function (res) {
                                _this.totalAdminList()
                                _this.adminLogin=''
                                _this.adminPassword=''
                                _this.organizationLinkman=''
                                _this.organizationPhone=''
                                _this.organizationName =''
                                _this.addData.AreaId= ''
                                _this.model8 = ''
                            }
                        })
                    }else{
                        _this.$Notice.warning({
                            title:'系统提示',
                            desc:'输入完成后添加',
                        })
                    }
                }else {
                    if(_this.organizationName != ''&&_this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != ''&& _this.adminLogin != ''){
                        // if(_this.cityList.length == 0){
                        //     // var AreaId = _this.information.AreaId
                        //     _this.AreaId = _this.information.AreaId
                        // }
                        _this.send({
                            url : '/AdminEdit',
                            data: {
                                AdminId :  _this.AdminId,
                                AdminLogin : _this.adminLogin,
                                AdminPassword :_this.adminPassword,
                                AdminName : _this.AdminName,
                                OrganizationName : _this.organizationName,
                                OrganizationLinkman :_this.organizationLinkman,
                                OrganizationPhone :_this.organizationPhone,
                                AreaId : _this.addData.AreaId,
                            },
                            success: function (res) {
                                _this.totalAdminList()
                                _this.adminLogin=''
                                _this.adminPassword=''
                                _this.organizationLinkman=''
                                _this.organizationPhone=''
                                _this.organizationName =''
                                _this.addData.AreaId = ''
                                _this.model8 = ''
                            }
                        })
                    }else{
                        _this.$Notice.warning({
                            title:'系统提示',
                            desc:'输入完成后添加',
                        })
                    }
                }
            },
            cancel1 () {
                var _this = this
                _this.AdminId = ''
                _this.adminLogin=''
                _this.adminPassword=''
                _this.organizationLinkman=''
                _this.organizationPhone=''
                _this.organizationName =''
                _this.addData.AreaId = ''
                _this.model8 = ''
                _this.Level = ''
                _this.modifyType = false
            },
            onekeyLogin : function (AdminLogin,AdminPassword) {
                // this.$router.push({path: '/login',query:{ adminLogin:AdminLogin,adminPassword:AdminPasswo}});
            },

          qRCode : function (url){
            this.areaSPCPanel.state = true
            console.log(url)
            if(this.areaSPCPanel.state){
              document.getElementById('qrcode').innerHTML = ''
              var qrcode = new QRCode('qrcode', { // 第一个入参是组件id
                text: url, // 生成二维码的文本
                width: '360',
                height: '360',
                colorDark: '#0067ff', // 二维码色
                colorLight: '#ffffff', // 背景色
                correctLevel: QRCode.CorrectLevel.H // 容错等级，H是heigh，最高，所以二维码看起来很密
              })
              let logo = new Image();
              logo.crossOrigin = 'Anonymous';
              logo.src = "/img/HYB.png"
              // logo.fillText = ('海南村居法律服务平台')
              let that = this;
              console.log(this.$refs.qrcode)
              let con = this.$refs.qrcode;
              logo.onload = function() {
                let container = con;
                let qrImg = qrcode._el.getElementsByTagName('img')[0];
                let canvas = qrcode._el.getElementsByTagName('canvas')[0];
                var ctx = canvas.getContext("2d");
                ctx.drawImage(logo, 360 * 0.5 - 44, 360 * 0.5 - 44, 88, 88);
                qrImg.src = canvas.toDataURL();
                container.appendChild(qrcode._el);
                // 销毁临时dom
                tmp = null;
                qrcode = null;
                logo = null;
              }
              console.log(qrcode)
            }
          },
          getAreaSPC:function(item,AreaId){
            var _this = this
            if(SYSCONFIG.location == 'chong-qing'){
              var scene ="cqcj-"+item.AdminId
              var AreaId = 2236
              var url = '/getVillageSunCode'
            }else{
              if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){
                var url = 'https://onecode-h5.digitalhainan.com.cn/online/qlink?linkCode=mZcfTkVp&type=qrCode_2&AdminId='+item.AdminId
                _this.qRCode(url)
                return
              }else {
                var scene =  "chooseArea-"+item.AdminId
                var url = '/CD_getVillageSunCode'
              }

            }
            _this.areaSPCPanel.state = false
            _this.areaSPCPanel.imgSrc = ''
            _this.send({
              url:url,
              consulting1:true,
              responseType:"arraybuffer",
              data: {
                scene:scene,
                width: '430',
                jpg: 'jpg',
                page:'public/scanIn/scanIn',
                AreaId: AreaId
              },
              success:function(res){
                _this.areaSPCPanel.state = true
                _this.areaSPCPanel.imgSrc = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
              }
            })
          },
        },
        mounted () {
            var _this = this
            if(window.sessionStorage.adminLevel == undefined){
                // this.$router.push({name:'login'})
            }else {
                this.information = window.sessionStorage
                // _this.information.AdminId = 0
                _this.totalAdminList()
                _this.areaList()
            }
            if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){
              _this.buttonCharacters = '二维码'
            }
        },
    }
</script>

