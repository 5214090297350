<template xmlns="http://www.w3.org/1999/html">
  <!--评价-->
  <div class="screen">


    <div style="background-color: white;">
      <div style="padding:15px"></div>
      <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 数据推送</div>
      <div style="padding:15px"></div>
      <div style="margin-left: 30px;line-height: 30px">
        1、说明：数据推送为平台服务数据每周一早上８点左右自动短信推送，内容如下：
        <br>
        <div style="">
          【律蛙服务】亲爱的XXX,上周新增XXX个用户,XXX次咨询,本月有XXX名律师打卡共XXX次,服务XXX小时XXX分钟,上传XXX个档案,请登陆 https://xxx.com 查看
        </div>
        <div>2、可设置您需要接收此服务数据推送的手机号。</div>
      </div>
      <Button type="primary" @click="modal6 = true" style="margin: 0 0 30px 30px">我要增加</Button>
      <div class="publicWelfare" >
        <table class="formtable">
          <thead>
          <tr>
            <th>姓名</th>
            <th>接收推送数据的手机号码</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in Principal">
            <td>{{value.name}}</td>
            <td>{{value.mobile}}</td>
            <td><Button type="primary" @click="remindDelete(index)">删除</Button>
              <Button type="primary" @click="remindModify(index)" style="margin-left: 20px">修改</Button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Modal
        v-model="modal6"
        title=""
        @on-ok="manageLinkman"
        @on-cancel="">
      <div class="single">
        <p class="contacts">名称</p>
        <input type="text" class="inputWord" placeholder="请输入名称" v-model="name">
      </div>
      <div class="single">
        <p class="contacts">手机号</p>
        <input type="text" class="inputWord" placeholder="请输入手机号" v-model="mobile">
      </div>
    </Modal>
    <Modal
        v-model="modal1"
        title="法律援助"
        ok-text="关闭"
        cancel-text=""
    >
      <div class="details">
        <div style="font-size: 16px">1、法律援助事项类别</div>
        <p><span>申请类别：</span>{{detailsData.zxsxlxzx}}</p>
        <div style="font-size: 16px">2、基本信息</div>
        <p><span>申请人：</span>{{detailsData.zxr}}</p>
        <p><span>性别：</span>{{detailsData.xb}}</p>
        <p><span>身份证号码：</span>{{detailsData.zjhm}}</p>
        <p><span>民族：</span>{{detailsData.mz}}</p>
        <p><span>出生日期：</span>{{detailsData.csrq}}</p>
        <p><span>年龄：</span>{{detailsData.nl}}</p>
        <p><span>联系电话：</span>{{detailsData.lxdh}}</p>
        <p><span>身份证：</span><Button type="primary" @click="sfclmcImg(detailsData.sfclmc);modal4 = true">查看身份证</Button></p>
        <div style="font-size: 16px">3、被申请人信息</div>
        <p><span>姓名：</span>{{detailsData.bsqr}}</p>
        <p><span>身份证号码：</span>{{detailsData.bsqrzjhm}}</p>
        <p><span>请填写住所地址：</span>{{detailsData.bsqrdz}}</p>
        <p><span>案情及申请 理由概述：</span>{{detailsData.zxnr}}</p>
        <p><span>机构所在：</span>{{detailsData.orgCity}}{{detailsData.orgArea}}</p>
      </div>
      <Button type="primary" @click="result();modal3 = true">结果查询</Button>
    </Modal>
    <Modal
        v-model="modal2"
        title="调解申请"
        ok-text="关闭"
        cancel-text=""
    >
      <div class="details">
        <div style="font-size: 16px">1、基本信息</div>
        <p><span>姓   名：</span>{{detailsData.applyName}}</p>
        <p><span>性别：</span>{{detailsData.sex}}</p>
        <p><span>身份证号码：</span>{{detailsData.idCard}}</p>
        <p><span>民族：</span>{{detailsData.nation}}</p>
        <p><span>出生日期：</span>{{detailsData.birthday}}</p>
        <p><span>年龄：</span>{{detailsData.age}}</p>
        <p><span>住所地址：</span>{{detailsData.address}}</p>
        <p><span>联系电话：</span>{{detailsData.phone}}</p>
        <div style="font-size: 16px">2、被申请人信息</div>
        <p><span>姓名：</span>{{detailsData.respondent}}</p>
        <p><span>身份证号码：</span>{{detailsData.respondentIdCard}}</p>
        <p><span>住所地址：</span>{{detailsData.respondentAddress}}</p>
        <p><span>联系电话：</span>{{detailsData.respondentPhone}}</p>
        <div style="font-size: 16px">3、申请人民调解的纠纷概述</div>
        <p><span>纠纷类型：</span>{{detailsData.applyType}}</p>
        <p><span>纠纷概述：</span>{{detailsData.proposerExcuse}}</p>
        <p><span>机构所在：</span>{{detailsData.orgCity}}{{detailsData.orgArea}}{{detailsData.orgZhen}}{{detailsData.hui}}{{detailsData.ren}}</p>
      </div>
    </Modal>
    <!---->
    <Modal
        v-model="modal4"
        title="身份证"
        ok-text="关闭"
        cancel-text="">
      <div v-model="sfclmc" >
        <img :src="sfclmc" style="width: 100%;">
      </div>
    </Modal>
    <!---->
    <Modal
        v-model="modal3"
        title="结果查询"
        ok-text="关闭"
        cancel-text=""
    >
      <div class="details">
        <div class="consultation">
          <div style="padding: 20px;">
            <div v-if="resultReply.zt1">
              <div>状态：{{resultReply.zt1}}</div>
              <div>答复意见：{{resultReply.dfyj}}</div>
              <div>答复日期：{{resultReply.jdrq}}</div>
            </div>
            <div v-if="resultReply.zt2">
              <div>受理审查日期：{{resultReply.slscrq}}</div>
              <div>受理审查单位：{{resultReply.slscdw}}</div>
            </div>
            <div v-if="resultReply.zt3">
              <div>立案日期：{{resultReply.larq}}</div>
              <div>案件编号：{{resultReply.ah}}</div>
            </div>
            <div v-if="resultReply.zt4">
              <div>案件指派：{{resultReply.zprq}}</div>
              <div>办理单位：{{resultReply.cbdw}}</div>
              <div>承办律师{{resultReply.cbr}}</div>
            </div>
            <div v-if="resultReply.zt5">结案日期：{{resultReply.jarq}}</div>
          </div>
        </div>
      </div>
    </Modal>
    <!--回复-->
    <Modal
        v-model="modal5"
        title="回复"
        @on-ok="submit"
        ok-text="发送" style="height: 300px">
      <Input v-model="content" maxlength="300" show-word-limit type="textarea" placeholder="回复内容"  />
    </Modal>
    <!--回复内容-->
    <Modal
        v-model="replyShow"
        title="回复内容"
        ok-text="关闭"
        cancel-text=""
    >
      <div class="details">
        {{replydata}}
      </div>
    </Modal>
  </div>
  <!---->
</template>

<script>
export default {
  name: "dataPush",
  data(){
    return {
      total : 0,
      opinionpage : 1,
      opinionpageSize : 10,
      opinioncount : 0,
      opinionData : [],
      information : [],
      legalAidData : [],
      count : 0,
      pageSize : 10,
      page : 1,
      modal1 : false,
      modal3 : false,
      modal2 : false,
      modal4 : false,
      modal5 : false,
      modal6 : false,
      content : '',
      id : '',
      detailsData : [],
      sfclmc : '',
      FlyzApplyId : '',
      UserId : '',
      resultReply : [],
      messageCount : [],
      Principal : [],
      name : '',
      mobile : '',
      smsReminder : '添加',
      replydata : '',
      replyShow : false,
      e : '',
    }
  },
  mounted () {
    this.information = window.sessionStorage
    this.opinion(5)
    this.message_count()


    var _this = this
    // this.adminflyzApplyList()
  },
  methods : {
    onreply : function(reply){
      this.replydata = reply
      this.replyShow = !this.replyShow
    },
    remindModify : function(index){
      var _this = this
      _this.modal6 = true
      _this.mobile = _this.Principal[index].mobile
      _this.name = _this.Principal[index].name
      _this.smsReminder = index
      console.log(_this.Principal)
    },
    remindDelete : function(index){
      var _this = this
      _this.$Modal.confirm({
        title:'系统消息',
        content:'是否确认当前操作？',
        onOk:function () {
          _this.Principal.splice(index,1)
          _this.send({
            url : '/ManageLinkman',
            data: {
              AdminId : _this.information.AdminId,
              Principal : _this.Principal
            },
            success: function (res) {
              console.log(res)
              _this.showLinkman()
            }
          })
        },
        onCancel:function () {

        }
      })
    },
    manageLinkman : function(){
      var _this = this
      var Principal = _this.Principal
      console.log(_this.smsReminder)
      if(_this.smsReminder == '添加'){
        _this.Principal.push({name:_this.name,mobile:_this.mobile})
      }
      else{
        _this.Principal[_this.smsReminder].name = _this.name
        _this.Principal[_this.smsReminder].mobile = _this.mobile
      }

      _this.modal6 = false
      console.log(_this.Principal)
      _this.send({
        url : '/ManageLinkman',
        data: {
          AdminId : _this.information.AdminId,
          Principal : _this.Principal
        },
        success: function (res) {
          console.log(res)
          _this.smsReminder = '添加'
          _this.name = ''
          _this.mobile = ''
          _this.showLinkman()
        }
      })
    },
    showLinkman : function(){
      var _this = this
      _this.send({
        url : '/ShowLinkman',
        data: {
          AdminId : _this.information.AdminId,
        },
        success: function (res) {
          console.log(res)
          if(res.Principal){
            _this.Principal = res.Principal
          }
        }
      })
    },
    reply : function(id,e,reply){
      console.log(id)
      var _this = this
      _this.modal5 = !_this.modal5
      _this.id = id
      _this.e = e
      _this.content = reply
    },
    submit : function(){
      var _this = this
      _this.send({
        url : '/FeedbackReply',
        data: {
          AdminId : _this.information.AdminId,
          content : _this.content,
          id : _this.id,
        },
        success: function (res) {
          console.log(res)
          _this.$Notice.success({
            title:'系统提示',
            desc:'发送成功',
          })
          _this.opinion(_this.e)
        }
      })
    },
    result : function(){
      var _this = this
      _this.send({
        url : '/flyzApply_resAdmin',
        data: {
          FlyzApplyId : _this.FlyzApplyId,
          UserId : _this.UserId,
        },
        success: function (res) {
          if(res.code != 500){
            _this.resultReply = res.data
          }else{
            _this.resultReply = []
          }

        }
      })
    },
    opinion1 : function(e){
      this.opinionpage = 1
      this.count = 0

      this.opinionData = []
      this.legalAidData = []
      this.opinion(e)
    },
    opinion : function (e) {
      var _this = this

      if(e == '2' || e == '1'){
        _this.send({
          url : '/FeedbackList',
          data: {
            AdminId : _this.information.AdminId,
            type : e,
            page : _this.opinionpage,
            pageSize : _this.opinionpageSize
          },
          success: function (res) {
            console.log(res)
            _this.opinioncount = res.Count
            _this.opinionData = res.List
          }
        })
      }
      if(e == '3'){
        _this.send({
          url:'/AdminflyzApplyList',
          data:{
            AdminId:window.sessionStorage.AdminId,
            page : _this.opinionpage,
            pageSize : _this.opinionpageSize
          },
          success:function (res) {
            _this.legalAidData = res.List
            _this.count = res.Count
          }
        })
      }
      if(e == '4'){
        var _this = this
        _this.send({
          url:'/AdminMediateList',
          data:{
            AdminId:window.sessionStorage.AdminId
          },
          success:function (res) {
            _this.legalAidData = res.List
            _this.count = res.Count
          }
        })
      }
      if(e == '5'){
        this.showLinkman()
      }
    },
    adminflyzApplyList : function (e) {
      console.log(e)
      var _this = this

    },
    see : function (e,UserId,FlyzApplyId) {
      console.log(e)
      this.detailsData = e
      this.UserId = UserId
      this.FlyzApplyId = FlyzApplyId
    },
    sfclmcImg : function (e) {
      this.sfclmc = e
    },
    message_count : function () {
      var _this = this
      _this.send({
        url:'/message_count',
        data:{
          AdminId:window.sessionStorage.AdminId
        },
        success:function (res) {
          _this.messageCount = res
        }
      })
    }
  }
}
</script>

<style scoped>
.screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
/*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
.screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
.screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
.screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
.publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
.formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
.publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
.publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
.details p{color: #000000}
.details span{color: #5c6b77}
.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

</style>
