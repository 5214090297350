<style scoped>
    .touxiang{width: 1.26rem;height: 1.4rem;display: inline-block;background: url("/img/zhqy/touxiang.png");background-size: 100% 100%;margin-top: 0.2rem}
    .iconset{width: 0.44rem;height: 0.43rem;margin-top: 0.10rem;vertical-align: top;}
    .inputFrame{width: 6.9rem;height: 1rem;border-bottom: 0.01rem solid #DDD;
        font-size: 0.28rem;font-family: PingFang SC;
        font-weight: 500;line-height: 1rem;position: relative;display: inline-block;text-align: left}
    .textareaFrame{width: 6.9rem;height: 3rem;border-bottom: 0.01rem solid #DDD;
        font-size: 0.28rem;font-family: PingFang SC;
        font-weight: 500;position: relative;display: inline-block;text-align: left;margin-top: 0.01rem;}
    .clickUpload{width: 1.46rem;
        height: 0.54rem;
        background: #1186E1;
        border-radius: 0.27rem;
        font-size: 0.24rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFF;line-height: 0.54rem;text-align: center;float: right;margin-top: 0.22rem;}
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {color: #CCC;}
    input::-moz-placeholder,
    textarea::-moz-placeholder {color: #CCC;}
    input:-moz-placeholder,
    textarea:-moz-placeholder {color: #CCC;}
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {color: #CCC;}
    .addCase{width: 6.9rem;height: 0.86rem;border: 0.01rem dashed #1186E1;display: inline-block;font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #1186E1;
        line-height: 0.86rem;text-align: center;}
    .preservation{width: 7.5rem;
        height: 0.92rem;
        background: #1186E1;
        font-size: 0.3rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFF;
        line-height: 0.92rem;position: fixed;text-align: center;bottom: 1rem;}
    .shanchu{width: 0.23rem;height: 0.24rem;vertical-align: top;margin: 0.2rem 0.11rem 0 0}
    .inputFrame :deep(.ivu-cascader){position: absolute;color: #222;right: 0;text-align: right;width: 100%;top: 0;height: 100%}
    .inputFrame :deep(.ivu-input){border:0;height: 100%;text-align: right;font-size: 0.28rem;}
    .inputFrame :deep(.ivu-input-wrapper){height: 100%;}
    .inputFrame :deep(.ivu-cascader-rel){height: 100%;font-size: 0.28rem}
    .head-title{font-size: .36rem;color: #333;line-height: .88rem;display: inline-block;vertical-align: top;}
    .return{width: .16rem;height: .3rem;display: inline-block;vertical-align: top;float: left;margin-top: .29rem;margin-left: .3rem;}
</style>

<template>
    <div>
      <div style="background-color: #F5F5F5; height: 0.88rem; text-align: center;position: fixed;top: 0;width: 100%;right: 0;z-index: 9;">
        <img src="/img/zhqy/return.png" class="return" @click="$router.go(-1)">
        <p class="head-title">编辑信息</p>
      </div>
      <div style="padding: 0.44rem"></div>
<!--        <div style="font-size: 0.36rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.32rem;margin: 0.3rem 0 0.37rem 0.55rem">您好，请完善个人信息</div>-->
        <div style="text-align: center">
            <Upload action="https://vn-server.lvwa18.com/api/upload_file_H5" :show-upload-list="false"
                    　　:on-success="headPortrait">
                <img v-if="information.lawPhoto"  :src="information.lawPhoto" style="width: 1.6rem;height: 1.6rem;background: #FFFFFF;border: 0.02rem solid #E5E5E5;border-radius: 50%;text-align: center;display: inline-block">
                <div v-else style="width: 1.6rem;height: 1.6rem;background: #FFFFFF;border: 0.02rem solid #E5E5E5;border-radius: 50%;text-align: center;display: inline-block">
                    <div class="touxiang">
                        <div style="width: 0.68rem;height: 0.68rem;background: #0A77FA;opacity: 0.5;border-radius: 50%;vertical-align: top;margin: 0.26rem 0 0 0.29rem">
                            <img class="iconset" src="/img/zhqy/iconset.png">
                        </div>
                    </div>
                </div>
            </Upload>
        </div>
        <div style="text-align: center">
            <div style="border-top: 0.01rem solid #DDD;width: 6.9rem;display: inline-block"></div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;">律所名称</div>
                <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="填写您的律所名称" v-model="information.lawName"/>
            </div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;">律所电话</div>
                <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="填写您的律所电话" v-model="information.lawPhone"/>
            </div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;position: absolute;z-index: 99;">律所地址</div>
                <Cascader :data="addressData" v-model="value1" :placeholder="value2==''?'选择您的地址':''" @on-change="addressSelect">
                    <div style="line-height: 1rem;">{{value2}}</div>
                </Cascader>
                <!--<div style="color: #666;display: inline-block;position: absolute;z-index: 99;right: 0.4rem;">{{value2}}</div>-->
                <!--<input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="填写您的律所地址" v-model="information.lawAddress"/>-->
            </div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;">律所详细地址</div>
                <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="填写您的律所详细地址" v-model="information.lawAddress"/>
            </div>
            <div class="inputFrame" style="height:auto;">
                <div style="color: #666;display: inline-block;">上传图片</div>
                <Upload action="https://vn-server.lvwa18.com/api/upload_file_H5" :show-upload-list="false" class="clickUpload"
                        　　:on-success="lawyerPicture">
                    <div >点击上传</div>
                </Upload>
                <div style="width: 6.3rem;height: 0.54rem;background: #F7F7F7;border-radius: 0.04rem;display: inline-block;margin-left: 0.2rem" v-for="(value,index) in information.lawImage">
                    <div style="font-size: 0.24rem;line-height: 0.54rem;vertical-align: top;font-family: PingFang SC;margin-left: 0.2rem;font-weight: 500;color: #1186E1;display: inline-block;width: 5.66rem;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{value.name}}</div>
                    <img src="/img/zhqy/lconDelete.png" style="width: 0.24rem;height: 0.24rem;vertical-align: top;margin-top: 0.15rem" @click="deleteLawyerPicture(index)">
                </div>
            </div>
            <!---->
            <div class="textareaFrame">
                <div style="color: #666;display: inline-block;line-height: 1rem">律所简介</div>
                <textarea style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;height: calc(100% - 0.37rem);resize: none;margin-top: 0.37rem;" placeholder="填写您律所简介，字数不超过300字" v-model="information.lawIntro">

                </textarea>
            </div>
            <!---->
            <div style="padding: 0.6rem"></div>
            <div class="preservation" @click="preservation">保存</div>
            <!---->
        </div>

        <div style="width: 100%;height: 1rem;background-color: #FFF;position: fixed;bottom: 0;">
            <div @click="home" style="width: 49%;height: 1rem;font-size: 0.26rem;text-align: center;display: inline-block">
                <img src="/img/zhqy/sy1.png" style="width: 0.36rem;height: 0.36rem;margin-top: 0.1rem">
                <div>首页</div>
            </div>
            <div style="width: 49%;height: 1rem;font-size: 0.26rem;text-align: center;display: inline-block">
                <img src="/img/zhqy/geren.png" style="width: 0.36rem;height: 0.36rem;margin-top: 0.1rem">
                <div>个人信息</div>
            </div>
        </div>
        <div style="padding: 0.5rem"></div>
    </div>
</template>

<script>
    export default {
        name: "lawFirmEditInformation",
        data() {
            return {
                value1 : [],
                value2 : '',
                addressData :[],
                information : {
                    lawOfficeId : '',//律所id
                    lawPhoto : '',//*律所头像
                    lawName : '',//律所名称
                    lawPhone : '',//律所电话
                    lawProvinceId : '',//律所省
                    lawCityId : '',//律所城市
                    lawAreaId : '',//律所区域
                    lawAddress : '',//律所地址
                    lawIntro : '',//律所简介
                    lawImage : [],//律所图片
                    lawDetailAddress : '',//律所详细地址
                },
                name : '个人信息',
            }
        },
        methods :{
            home : function(){
                this.$router.push({name: 'zhqyHome', query: {}})//律师
            },
            //保存
            preservation : function(){
                var _this = this
                _this.information.lawDetailAddress = _this.information.provinceName+_this.information.cityName+_this.information.areaName+_this.information.lawAddress
                _this.send({
                    url: '/LawOfficeIdUpdate',
                    data: _this.information,
                    success: function (res) {
                        console.log(res)
                        _this.$Message.success('保存成功');
                        _this.login()
                    }
                })
            },
            addressSelect : function(value, selectedData){
                console.log(value, selectedData)
                var _this = this
                _this.information.lawProvinceId = value[0]
                _this.information.lawCityId = value[1]
                _this.information.lawAreaId = value[2]
                _this.value2 = selectedData[0].label+'/'+selectedData[1].label+'/'+selectedData[2].label
            },
            //    地区
            areaList: function() {
                var _this = this
                var data = []
                _this.send({
                    url: '/AreaAdminList',
                    data: {},
                    success: function(res) {
                        //
                        for(var i in res){
                            data.push(res[i])
                        }
                        convertTree(data)
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let {
                                    AreaId: value,
                                    AreaName: label,
                                    list: children
                                } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    value,
                                    label,
                                    children
                                })
                            })
                            _this.addressData = result
                            return result
                        }
                        console.log(_this.addressData)
                    }
                })
            },
            //删除律师图片
            deleteLawyerPicture :function(index){
                var _this = this
                delete _this.information.lawImage.splice(index, 1)
                console.log(_this.information.lawImage)
            },
            //律师图片
            lawyerPicture : function(response){
                var _this = this
                _this.information.lawImage.push(window.sessionStorage.url+response.Data)
                console.log(_this.information.lawImage)
            },
            // 头像
            headPortrait : function(response){
                var _this = this
                _this.information.lawPhoto = window.sessionStorage.url+response.Data.path
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
            login : function () {
                var _this = this
                _this.send({
                    url : '/LawOfficeLoginOrById',
                    data: {lawOfficeId :window.sessionStorage.lawOfficeId},
                    success: function (res) {

                        window.sessionStorage.areaName = res.areaName || ''
                        window.sessionStorage.cityName = res.cityName || ''
                        window.sessionStorage.creatTime = res.creatTime || ''
                        window.sessionStorage.lawAddress = res.lawAddress || ''
                        window.sessionStorage.lawIntro = res.lawIntro || ''
                        window.sessionStorage.lawName = res.lawName || ''
                        window.sessionStorage.lawOfficeId = res.lawOfficeId || ''
                        window.sessionStorage.lawPhone = res.lawPhone || ''
                        window.sessionStorage.provinceName = res.provinceName || ''
                        window.sessionStorage.lawProvinceId = res.lawProvinceId || ''
                        window.sessionStorage.lawCityId = res.lawCityId || ''
                        window.sessionStorage.lawAreaId = res.lawAreaId || ''
                        window.sessionStorage.lawPhoto = res.lawPhoto || ''
                        _this.$router.push({name:'zhqyHome'});
                    }
                })
            },
        },
        mounted (){
            var _this = this
            _this.setPageSize()
            _this.areaList()
            if(window.sessionStorage.lawPhone){
                if(window.sessionStorage.lawyerId){
                    _this.law = '律师'
                }
                else{
                    _this.law = '律所'
                }
            }else{
                _this.name = '登录'
            }
            if(window.sessionStorage.lawImage){
                _this.information.lawImage = JSON.parse(window.sessionStorage.lawImage) || []
            }
            _this.information.areaName = window.sessionStorage.areaName || ''
            _this.information.cityName = window.sessionStorage.cityName || ''
            _this.information.provinceName = window.sessionStorage.provinceName || ''
            _this.information.creatTime = window.sessionStorage.creatTime || ''
            _this.information.lawAddress = window.sessionStorage.lawAddress || ''
            _this.information.lawIntro = window.sessionStorage.lawIntro || ''
            _this.information.lawName = window.sessionStorage.lawName || ''
            _this.information.lawOfficeId = window.sessionStorage.lawOfficeId || ''
            _this.information.lawPhone = window.sessionStorage.lawPhone || ''
            _this.information.lawProvinceId = window.sessionStorage.lawProvinceId || ''
            _this.information.lawCityId = window.sessionStorage.lawCityId || ''
            _this.information.lawAreaId = window.sessionStorage.lawAreaId || ''
            _this.information.lawPhoto = window.sessionStorage.lawPhoto || ''
            _this.value2 = window.sessionStorage.provinceName+'/'+window.sessionStorage.cityName+'/'+window.sessionStorage.areaName || ''
        }
    }
</script>


