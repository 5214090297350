<template>
    <div style="font-family: PingFang SC;">
        <div style="background: url(/img/caseInput/logintp.png);background-size: 100% 100%;width: 7.5rem;height: 3.6rem;text-align: center;font-family: PingFang SC;position: relative">
            <div>
                <img style="width: 0.8rem;height: 0.8rem;vertical-align: top;margin: 0.72rem 0 0.15rem;" src="/img/caseInput/logo.png">
            </div>
            <div style="font-size: 0.4rem;font-weight: bold;color: #FFF;line-height: 0.54rem;">
                <div>成都市武侯区司法局</div>
                <div>律师&律所评价系统</div>
            </div>
        </div>
        <div style="margin-left: 0.57rem;font-size: 0">
            <div style="font-size: 0.48rem;font-weight: bold;color: #000000;margin-top: 0.8rem;">用户登录</div>
            <!--<div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.59rem 0 0.2rem 0">姓名</div>-->
            <!--<input v-model="userName" style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;color: #CCCCCC;" placeholder="请输入姓名" />-->
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.4rem 0 0.2rem 0">电话号码</div>
            <input v-model="userPhone" style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;color: #CCCCCC;" placeholder="请输入电话号码" />
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.59rem 0 0.2rem 0">验证码</div>
            <div style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;position: relative">
                <input v-model="Code" style="height: 0.86rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;color: #CCCCCC;" placeholder="请输入验证码" />
                <div @click="getCode" style="position: absolute;border: 0.01rem solid #1186E1;right: 0.2rem;top: 0.11rem;font-size: 0.28rem;color: #1186E1;padding: 0.1rem 0.2rem"v-if="messageTimer.fun==null">获取验证码</div>
                <div style="position: absolute;border: 0.01rem solid #1186E1;right: 0.2rem;top: 0.11rem;font-size: 0.28rem;color: #1186E1;padding: 0.1rem 0.2rem" v-else>{{messageTimer.count+"s后重新获取"}}</div>
            </div>
            <div @click="login" style="width: 6.4rem;height: 0.86rem;background: #1186E1;border-radius: 0.04rem;font-size: 0.28rem;font-weight: bold;color: #FFFFFF;text-align: center;
            line-height: 0.86rem;margin-top: 0.4rem;margin-bottom: 0.3rem">登录</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "caseLogin",
        data() {
            return {
                userName : '',
                userPhone : '',
                messageTimer: {
                    fun: null,
                    count: '',
                },
                Code : '',
            }
        },
        methods :{
            getCode : function(){
                var _this = this
                _this.send({
                    url : '/GetCode',
                    data: {userPhone : _this.userPhone},
                    success: function (res) {
                        _this.setReciprocal()
                    }
                })
            },
            setReciprocal: function () {
                var _this = this
                var TIME_COUNT = 60
                if (!_this.messageTimer.fun) {
                    _this.messageTimer.count = TIME_COUNT
                    _this.messageTimer.fun = setInterval(function () {
                        if (_this.messageTimer.count > 0 && _this.messageTimer.count <= TIME_COUNT) {
                            _this.messageTimer.count--
                        } else {
                            clearInterval(_this.messageTimer.fun)
                            _this.messageTimer.fun = null;
                        }
                    }, 1000)
                }
            },
            login : function () {
                var _this = this
                _this.send({
                    url : '/LawUserLogin',
                    data: {userPhone :_this.userPhone,Code : _this.Code },
                    success: function (res) {
                        if(res.lawImage){
                            window.sessionStorage.lawImage = JSON.stringify(res.lawImage) || []
                        }
                        window.sessionStorage.userId = res.userId || ''
                        window.sessionStorage.userImage = res.userImage || ''
                        window.sessionStorage.userName = res.userName || ''
                        window.sessionStorage.userPhone = res.userPhone || ''
                        _this.setCookie(_this.userName,_this.userPhone,res.userId)
                        _this.delCookie ('lawOfficeId')
                        if(window.sessionStorage.qr == 1){
                            _this.$router.go(-1);
                        }else {
                            _this.$router.push({name:'lawfirmList'});
                        }
                    }
                })
            },
            setCookie(userName,userPhone,userId) {
                var exdate = new Date(); //获取时间
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 30); //保存的天数
                //字符串拼接cookie
                window.document.cookie = "userName" + "=" + userName + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "userPhone " + "=" + userPhone  + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "userId " + "=" + userId  + ";path=/;expires=" + exdate.toGMTString();
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
            //    删除cookie
            delCookie (name) {
                var exp = new Date();
                exp.setTime(exp.getTime() - 1);
                var cval = this.getCookie(name);
                if (cval != null)
                    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
            },
            getCookie(name) {
                var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
                if (arr = document.cookie.match(reg)) {
                    return true;
                    // return (arr[2]);
                } else {
                    return false
                }
            }
        },
        mounted () {
            var _this = this
            _this.setPageSize()
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'userName') {
                        window.sessionStorage.userName = arr2[1];
                        _this.userName = window.sessionStorage.userName
                    }
                    if (arr2[0] == 'userPhone') {
                        window.sessionStorage.userPhone = arr2[1];
                        _this.userPhone = window.sessionStorage.userPhone
                    }
                    if (arr2[0] == 'userId') {
                        _this.$router.push({name:'lawfirmList'});
                    }
                }
            }
            console.log(window.location.href)
        }
    }
</script>

<style scoped>

</style>
