<template>
    <div style="background-color:#f8f9fb;" class="mediateapply">
        <div class="header"></div>
        <!--<div style="font-size: 0.3rem">-->
            <!--<Steps :current="current">-->
                <!--<Step title="选择地址">-->

                <!--</Step>-->
                <!--<Step title="基本信息"></Step>-->
                <!--<Step title="是想描述"></Step>-->
            <!--</Steps>-->
        <!--</div>-->
        <div style="padding:0.3rem;">
            <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
                <template >
                    <FormItem label="" prop="name">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>姓名：</div>
                        <Input v-model="formValidate.name" placeholder="姓名"></Input>
                    </FormItem>
                    <FormItem label="" prop="phone">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>电话：</div>
                        <Input v-model="formValidate.phone" placeholder="电话"></Input>
                    </FormItem>
                    <FormItem label="" prop="sex">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>性别：</div>
                        <div>
                            <RadioGroup v-model="formValidate.sex" @on-change="onSex">
                                <Radio label="1">男</Radio>
                                <Radio label="2">女</Radio>
                            </RadioGroup>
                        </div>
                    </FormItem>
                    <FormItem label="" prop="identitycardnumber">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证号：</div>
                        <Input v-model="formValidate.identitycardnumber" placeholder="身份证号"></Input>
                    </FormItem>
                    <FormItem label prop="familyaddress">
                        <div class="inline-block">
                            <span style="color: red;padding: 0 0.1rem;">*</span>申请人住所地地址：
                        </div>
                        <Cascader :data="addressData" v-model="formValidate.familyaddress" @on-change="addressSelect"></Cascader>
                    </FormItem>
                    <FormItem label="" prop="webcrowdtype">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人身份类别：</div>
                        <Select v-model="formValidate.webcrowdtype" placeholder="申请人身份类别" @on-change="onwebcrowdtype">
                            <Option :value="index" v-for="(value,index) in crowd">{{value.humanType}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="" prop="humantype">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>人群分类：</div>
                        <Select v-model="formValidate.humantype" placeholder="人群分类" @on-change="onhumantype">
                            <Option :value="index" v-for="(value,index) in crowd" v-if="index < 8">{{value.humanType}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="" prop="monthlyincome">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>月收入：</div>
                        <Select v-model="formValidate.monthlyincome" placeholder="月收入" @on-change="onmonthlyincome">
                            <Option :value="index" v-for="(value,index) in monthly">{{value.income}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="" prop="opposingname">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>对方当事人姓名：</div>
                        <Input v-model="formValidate.opposingname" placeholder="对方当事人姓名"></Input>
                    </FormItem>
                    <!--<FormItem label="" prop="orginregincode">-->
                        <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人户籍地区划编码：</div>-->
                        <!--<Input v-model="formValidate.orginregincode" placeholder="申请人户籍地区划编码"></Input>-->
                    <!--</FormItem>-->
                    <FormItem label="" prop="legalhelpreason">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>法律援助事由：</div>
                        <Select v-model="formValidate.legalhelpreason" placeholder="法律援助事由" @on-change="onlegalhelpreason">
                            <Option :value="index" v-for="(value,index) in legalAid">{{value.reasonContent}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="" prop="caseremark">
                        <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请事项明细：</div>
                        <Input v-model="formValidate.caseremark" placeholder="申请事项明细"></Input>
                    </FormItem>
                    <!--<FormItem label="" prop="sfclmc">-->
                        <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>申请证据：</div>-->
                        <!--<div style="text-align: center;border: solid 0.01rem #dcdee2;background-color: #FFF">-->
                            <!--<Upload-->
                                    <!--action="https://vn-server.lvwa18.com/api/upload_file_H5"-->
                                    <!--　　:on-success="Picture"-->
                                    <!--:default-file-list="uploadPicture"-->
                                    <!--:on-remove="deleteValuePicture"-->
                            <!--&gt;-->
                                <!--<div style="line-height: 58px;width: 6.9rem">-->
                                    <!--<Icon type="ios-camera" size="20"></Icon>-->
                                <!--</div>-->
                            <!--</Upload>-->
                        <!--</div>-->
                    <!--</FormItem>-->
                </template>
            </Form>
        </div>
        <div style="text-align: center;padding-bottom: 0.2rem">
            <Button type="primary" style="width:3rem;" @click="submit('formValidate')">提交</Button>
        </div>
        <Modal
                v-model="modal1"
                title=""
                @on-cancel="fanhui"
                ok-text="关闭"
                cancel-text="返回上一页">
            <p>申请成功</p>
        </Modal>
    </div>
</template>

<script>
    export default {
        name: "legalAidapply",
        data (){
            return{
                modal1 : false,
                crowd : [],
                monthly : [],
                addressData : [],
                legalAid : [],
                uploadPicture : [],
                ruleValidate : {
                    name : [{ required: true,trigger: 'blur' ,message:' '}],
                    phone : [{ required: true,trigger: 'blur' ,message:' '}],
                    sex : [{ required: true,trigger: 'blur' ,message:'请选择性别'}],
                    identitycardnumber : [{ required: true,trigger: 'blur' ,message:' '}],
                    familyaddress : [{ required: true,trigger: 'blur' ,message:' '}],
                    webcrowdtype : [{ required: true,trigger: 'change' ,message:' ',type:'number'}],
                    humantype : [{ required: true,trigger: 'change' ,message:' ',type:'number'}],
                    monthlyincome : [{ required: true,trigger: 'change' ,message:' ',type:'number'}],
                    opposingname : [{ required: true,trigger: 'blur' ,message:' '}],
                    legalhelpreason : [{ required: true,trigger: 'change' ,message:' ',type:'number'}],
                    caseremark : [{ required: true,trigger: 'blur' ,message:' '}],
                },
                //传递参数
                UserId:'',
                AreaId:'',
                // type:"2",
                formValidate :{
                    // username: '',
                    // userpwd: '',
                    // id:'',
                    // adder:'',
                    name:'',
                    phone:'',
                    sex:'',
                    identitycardnumber : '',
                    familyregincode : '',
                    humantype : '',
                    webcrowdtype : '',
                    monthlyincome: '',
                    opposingname : '',
                    opposingpartyaddressregincode : '',//对方当事人居住地区划编码
                    orginregincode : '',
                    familyaddress :'',
                    legalhelpreason : '',
                    caseremark : '',


                    // photoids:[
                    //     {
                    //         filename: "",
                    //         filecontent: ""
                    //     }
                    // ],
                   // type:'2',
                },
                SupplementContent:{
                    sex : '',
                    webcrowdtypename: "",
                    humantypename: "",
                    monthlyincomename: "",
                    legalhelpreasonname: "",
                    // typename:"移动端",
                    // photoids:[ {
                    //     filename: "",
                    //     filecontent: ""
                    // }
                    // ]
                }
            }
        },
        methods :{
            fanhui: function(){window.history.go(-1)},
            onSex : function(e){
                console.log(e)
                var _this = this
                if(e == 1){
                    _this.SupplementContent.sex = '男'
                }else {
                    _this.SupplementContent.sex = '女'
                }

            },
            deleteValuePicture : function(file, fileList){
                var _this = this
                _this.SupplementContent.photoids = []
                _this.formValidate.photoids = []
                for(var i in fileList){
                    _this.SupplementContent.photoids.push({filename:fileList[i].response.Data.name,filecontent:fileList[i].response.Data.path})
                    _this.formValidate.photoids.push({filename:fileList[i].response.Data.name,filecontent:fileList[i].response.Data.path})
                }
            },
            Picture : function(response, file, fileList){
                var _this = this
                _this.SupplementContent.photoids = []
                _this.formValidate.photoids = []
                for(var i in fileList){
                    _this.SupplementContent.photoids.push({filename:fileList[i].response.Data.name,filecontent:fileList[i].response.Data.path})
                    _this.formValidate.photoids.push({filename:fileList[i].response.Data.name,filecontent:fileList[i].response.Data.path})
                }
            },
            submit : function(name){
                var _this = this
                _this.$refs[name].validate((valid) => {
                  if (valid) {
                      _this.send({
                          url:'/CqFlyzApply',
                          data :{
                              content:_this.formValidate,
                              UserId:window.sessionStorage.userId,
                              AreaId:window.sessionStorage.AreaId,
                              type:_this.type,
                              SupplementContent : _this.SupplementContent
                          },
                          success:function(res){
                              console.log(res)
                              _this.modal1 = true
                          }
                      })
                  }
                })
            },
            onlegalhelpreason : function(e){
                this.SupplementContent.legalhelpreasonname = this.legalAid[e].reasonContent
                // this.SupplementContent.reasonId = this.legalAid[e].reasonId
            },
            onmonthlyincome : function(e){
                this.SupplementContent.monthlyincomename = this.monthly[e].income
                // this.SupplementContent.monthlyIncomeId = this.monthly[e].monthlyIncomeId
            },
            onhumantype : function(e){
                this.SupplementContent.humantypename = this.crowd[e].humanType
                // this.SupplementContent.humantype = this.crowd[e].humanTypeId
            },
            onwebcrowdtype : function(e){
                this.SupplementContent.webcrowdtypename = this.crowd[e].humanType
                // this.SupplementContent.webcrowdtype = this.crowd[e].humanTypeId
            },
            addressSelect : function (value, selectedData){
                console.log(value, selectedData)
                this.formValidate.familyaddress = selectedData[0].label+selectedData[1].label
                this.formValidate.familyregincode = selectedData[1].AreaCode
                this.formValidate.orginregincode = selectedData[1].AreaCode//申请人户籍地区划编码
                this.formValidate.opposingpartyaddressregincode = selectedData[1].AreaCode//对方当事人居住地区划编码
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
            monthlyIncome : function(){
                var _this = this
                //月收入
                _this.send({
                    url : '/MonthlyIncome',
                    success: function (res) {
                        _this.monthly = res
                    }
                })
                //法律援助
                _this.send({
                    url : '/LegalReason',
                    success: function (res) {
                        _this.legalAid = res
                    }
                })
                //人群类型
                _this.send({
                    url : '/HumanType',
                    success: function (res) {
                        _this.crowd = res
                    }
                })
            },
            cqAreaList:function(){
                var _this = this
                var data = []
                _this.send({
                    url:'/CqAreaList',
                    success:function(res){
                        for(var i in res){
                            data.push(res[i])
                        }
                        convertTree(data)
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let {
                                    AreaId: value,
                                    AreaName: label,
                                    AreaCode : AreaCode,
                                    list: children
                                } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    value,
                                    label,
                                    AreaCode,
                                    children
                                })
                            })
                            _this.addressData = result
                            console.log(_this.addressData)
                            return result
                        }
                    }
                })
            },
        },
        mounted(){
            var _this = this
          SYSCONFIG.server.default.host = 'cq.lvwa18.com'
          SYSCONFIG.server.default.port = '8001'
            _this.monthlyIncome()
            _this.cqAreaList()
            _this.setPageSize()
        }
    }
</script>

<style scoped>
    .header{background-image: url(/img/legalAid/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
    .mediateapply :deep(.ivu-date-picker){display: inline}
    .mediateapply :deep(.ivu-radio-group){display: inline}
</style>
