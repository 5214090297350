<template>
  <div>
    <img src="https://ai-cdn.lvwa18.com/img/rusticate/caseBanner.png" style="width:100%;height:2rem;" />
    <div style="text-align: center;width: 7.50rem;">
      <div style="display: inline-block;text-align: center;width: 6.9rem;margin: 0.2rem 0 0 0.3rem">
        <Input search placeholder="输入关键词搜索" style="display: inline-block" v-model="Key" @on-change="onkey" />
      </div>
    </div>
    <div>
      <div style="color: #009EDB;font-size: 0.26rem;line-height: 0.73rem;margin-left:0.30rem;">
        <span style="color: #000000;padding-right:0.30rem;" @click="$router.back(-1)">返回首页</span>
        <span style="color: #000000;">热门：</span>
        <span @click="hot('调解')" style="padding-right:0.30rem;">调解 </span>
        <span @click="hot('援助')" style="padding-right:0.30rem;">援助 </span>
        <span @click="hot('公证')" style="padding-right:0.30rem;">公证 </span>
        <span @click="hot('鉴定')" style="padding-right:0.30rem;">鉴定 </span>
      </div>
    </div>
    <div style="text-align: center;">
      <div class="classification" @click="onClassification">
        <div style="margin-left: 0.20rem;display:inline-block;" v-if="Type">{{Type}} <span
            style="color: #F6EE08;">{{Count}}例</span></div>
        <div style="margin-left: 0.20rem;display:inline-block;" v-else>司法鉴定业务案例 <span
            style="color: #F6EE08;">{{Count}}例</span></div>
        <img class="xia" src="https://ai-cdn.lvwa18.com/img/rusticate/xia.png" />
      </div>
    </div>
    <div style="span-align: center;font-size: 0;text-align: center;">
      <div class="list" v-for="(value,index) in caseList" @click="caseDetails(value.ExternalArticleId)">
        <div class="lists">{{index+1}}.<span v-html="value.ExternalArticleTitle"></span></div>
      </div>
    </div>

    <Modal v-model="currentShow" title="司法鉴定业务案例" @on-cancel="cancel">
      <div slot="footer">
        <Button @click="cancel">返回</Button>
        <!--<Button type="warning" class="btn-warning">保存</Button>-->
      </div>
      <div v-for="(value,index) in choiceClassification"
        style="text-align:center;line-height: 0.7rem;height: 0.7rem;font-size:0.26rem;"
        @click="onChoiceClassification(value.ExternalArticleTypeId,value.ExternalArticleTypeTitle)">
        {{value.ExternalArticleTypeTitle}} <span style="color:red;">{{value.count}}例</span>
      </div>
    </Modal>
  </div>
</template>

<script>
  export default {
    name: "sfalk",
    data() {
      return {
        currentShow: false,
        currentShow1: false,
        choiceClassification: [],
        choiceClassificationList: [],
        Type: '',
        caseList: [],
        Key: '',
        pageSize: 20,
        page: 1,
        typei: false,
        Count: '',
      }
    },
    mounted() {
      this.caseArticleList()
      this.caseArticleType()
      document.title = window.sessionStorage.Area_name||'案例库' + '-公共法律服务平台'
    },
    created() {
      let that = this;
      window.onscroll = function () {
        // scrollTop 滚动条滚动时，距离顶部的距离
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // windowHeight 可视区的高度
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        // scrollHeight 滚动条的总高度
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        // 滚动条到底部的条件
        if (scrollTop + windowHeight + 5 >= scrollHeight) {
          // 加载数据
          that.page++
          that.caseArticleList();

        }
      }
    },
    methods: {
      caseArticleList: function () {
        var _this = this
        if(SYSCONFIG.location == 'chong-qing'){
          var url = '/CQ_CaseArticleList'
        }else{
          var url = '/CD_CaseArticleList'
        }
        _this.send({
          url: url,
          data: { Key: _this.Key, Type: _this.Type, page: _this.page, pageSize: _this.pageSize },
          success: function (res) {
            var caseList = _this.caseList
            var keyword = new RegExp("(" + _this.Key + ")", "g")
            console.log(res)
            for (var i in res.List) {
              if (_this.Key) {
                res.List[i].ExternalArticleTitle = res.List[i].ExternalArticleTitle.replace(keyword, '<span style="color:red;">' + _this.Key + '</span>')
              }
              new Array(caseList.push(res.List[i]))
            }
            _this.caseList = caseList,
              _this.Count = res.Count
          }
        })
      },

      caseArticleType: function () {
        var _this = this
        if(SYSCONFIG.location == 'chong-qing'){
          var url = '/CQ_CaseArticleType'
        }else{
          var url = '/CD_CaseArticleType'
        }
        _this.send({
          url: url,
          success: function (res) {

            _this.choiceClassification = res,
              _this.choiceClassificationList = res
          }
        })
      },
      onChoiceClassification: function (index, name) {
        var _this = this
        if (_this.typei == false) {
          _this.choiceClassification = [],
            _this.currentShow = true
          _this.choiceClassification = _this.choiceClassificationList[index].list,
            _this.typei = true
        } else {
          _this.typei = false,
            _this.currentShow = false,
            _this.Type = name,
            _this.caseList = [],
            _this.choiceClassification = [],
            _this.choiceClassificationList = []
          _this.caseArticleList()
        }
      },
      caseDetails: function (id) {
        this.$router.push({ name: 'sfalkDetails', query: { id: id } })
      },
      onkey: function () {
        this.caseList = []
        this.page = 1
        this.caseArticleList()
      },
      cancel: function () {
        this.Type = '',
          this.caseList = [],
          this.page = 1,
          this.currentShow = false
        this.caseArticleList()
      },
      onClassification: function () {
        var _this = this
        _this.caseArticleType()
        _this.currentShow = !_this.currentShow
      },
      hot: function (name) {
        this.Key = name,
          this.value = name,
          this.caseList = []
        this.caseArticleList()
      },
    },
  }
</script>

<style scoped>
  page{background-color: #f7f8fa;}
  .search{background-color: #FFF;padding-left: var(--padding-xs,0.20rem);margin-left: -0.20rem;}
  .inputSearch{padding-left: 0.20rem;}
  .classification{width: 6.90rem;height: 0.70rem;display: inline-block;background: #009EDB;color: #FFFFFF;line-height: 0.70rem;font-size: 0.26rem;text-align: left;margin-top: 0.10rem;}
  .xia{float: right;margin: 0.29rem 0.20rem 0 0;width: 0.22rem;height: 0.12rem;}
  .list{width: 6.90rem;border-bottom:0.01rem solid #DDDDDD;height: 0.92rem;line-height: 0.92rem;display: inline-block;text-align: left;background-color: #FFF;font-size: 0.26rem;}
  .lists{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin: 0 0.23rem;color: #333;font-size: 0.26rem;}
  .list view{display: inline-block;}
</style>
