<template>
  <div>
    <Modal :draggable="true" v-model="modal3" title="服务次数" :z-index="1" @on-ok="" @on-cancel="cancel1">
      <div
          class="publicWelfare"
          style="
                    height: 600px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow-y: scroll;
                "
      >
        <table class="formtable">
          <thead>
          <tr>
            <th>服务类型</th>
            <th>服务次数</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in orderTypeCountData">
            <td v-if="value.OrderTypeId != '12'">{{ value.OrderTypeTitle }}</td>
            <td v-if="value.OrderTypeTitle == '人工咨询' || value.OrderTypeTitle == '留言咨询'">
              <div style="display: inline-block; margin: 7px auto">{{ value.count }}</div>
              <Button
                  style="background-color: rgb(243, 133, 4); color: #fff; float: right"
                  @click="
                                        modal12 = true
                                        orderTypeCount(5, undefined, 1)
                                        lawyerList()
                                    "
              >详情</Button
              >
              <Button
                  style="background-color: rgb(243, 133, 4); color: #fff; float: right"
                  @click="
                                        modal12 = true
                                        orderTypeCount(5, undefined, 2)
                                        lawyerList()
                                    "
                  v-if="orderTypeCountData[11].OrderTypeId == '12'"
              >{{ orderTypeCountData[11].OrderTypeTitle
                }}{{ orderTypeCountData[11].count }}条</Button
              >
            </td>
            <td v-else-if="value.OrderTypeTitle == '自助咨询'">
              <div style="display: inline-block; margin: 7px auto">{{ value.count }}</div>
              <Button
                  style="background-color: rgb(243, 133, 4); color: #fff; float: right"
                  type="warning"
                  @click="robot()"
              >案由分析</Button
              >
            </td>
            <td v-else-if="value.OrderTypeTitle == '拨打律师电话'">
              <div style="display: inline-block; margin: 7px auto">{{ value.count }}</div>
              <Button
                  style="background-color: rgb(243, 133, 4); color: #fff; float: right"
                  type="warning"
                  @click="
                                        dialTelephone(value.OrderTypeId, value.OrderTypeTitle, 1)
                                        modal18 = true
                                    "
              >详情</Button
              >
            </td>
            <td v-else-if="value.OrderTypeTitle == '拨打12348热线'">
              <div style="display: inline-block; margin: 7px auto">{{ value.count }}</div>
              <Button
                  style="background-color: rgb(243, 133, 4); color: #fff; float: right"
                  type="warning"
                  @click="
                                        dialTelephone(value.OrderTypeId, value.OrderTypeTitle, 2)
                                        modal18 = true
                                    "
              >详情</Button
              >
            </td>
            <td v-else-if="value.OrderTypeTitle == '其他咨询'">
              <div style="display: inline-block; margin: 7px auto">{{ value.count }}</div>
            </td>
            <td v-else-if="value.OrderTypeId == '9'">
              <div style="display: inline-block; margin: 7px auto" v-if="chengdu">
                {{ value.count }}
              </div>
              <div style="display: inline-block; margin: 7px auto" v-else>{{ value.count }}</div>
              <!--<Button style="background-color:  rgb(243, 133, 4);color: #fff;float: right" @click="detailsSee(value.OrderTypeId,value.OrderTypeTitle);modal17 = true">详情</Button>-->
            </td>
            <td v-else-if="value.OrderTypeId == '13'">
              <div style="display: inline-block; margin: 7px auto">{{ value.count }}</div>
              <!--<Button style="background-color:  rgb(243, 133, 4);color: #fff;float: right" @click="detailsSee(value.OrderTypeId,value.OrderTypeTitle);modal17 = true">详情</Button>-->
            </td>
            <td v-else-if="value.OrderTypeId != '12'">
              <div style="display: inline-block; margin: 7px auto">{{ value.count }}</div>
              <Button
                  style="background-color: rgb(243, 133, 4); color: #fff; float: right"
                  @click="
                                        detailsSee(value.OrderTypeId, value.OrderTypeTitle)
                                        modal17 = true
                                        lawyerList()
                                    "
              >详情</Button
              >
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
    <!--人工咨询详情-->
    <Modal :draggable="true" v-model="modal12" title="人工咨询详情" @on-ok="" @on-cancel="cancel" width="900">
      <p slot="header" style="color: #17233d; text-align: left">
        <span v-if="effect == 1">人工咨询详情</span>
        <span v-if="effect == 2">有效咨询</span>
        <span style="margin-left: 30px">共{{ count }}条</span>
      </p>
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">处理状态</span>
        已处理：<span style="color: red">{{ processingStatusData.ReplyCount }}</span
      >条 未处理：<span style="color: red">{{ processingStatusData.NoCount }}</span
      >条
      </div>
      <div class="publicWelfare">
        <DatePicker
            :value="timeData"
            v-model="timeData"
            type="daterange"
            split-panels
            placeholder="请选择时间"
            style="width: 150px"
            @on-change="totalTime0"
        ></DatePicker>
        <Select v-model="model1" style="width: 150px" @on-change="lawyerClick" filterable clearable>
          <Option v-for="(value, index) in lawyerData" :value="value.LawyerId" :key="value.LawyerId">
            {{ value.LawyerName }}</Option
          >
        </Select>
        <Select
            style="width: 150px"
            v-model="model141"
            filterable
            remote
            clearable
            :remote-method="remoteMethod2"
            @on-change="lawyerClick2"
            :loading="loading2"
            placeholder="请输入社区"
        >
          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesId">
            {{ value.VillagesName }}</Option
          >
        </Select>
        <Select
            v-model="model10"
            clearable
            style="width: 150px"
            @on-change="stateChoice"
            placeholder="请选择处理状态"
        >
          <Option v-for="item in IsReplyList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        <Select v-model="isArchive" style="width: 150px" placeholder="请选择已归档" clearable filterable @on-change="fileChoice">
          <Option value="1" label="已归档"></Option>
        </Select>
        <table class="formtable">
          <thead>
          <tr>
            <th>用户</th>
            <th>法律顾问</th>
            <th>村（社区）</th>
            <!--<th>用户号码</th>-->
            <th>咨询时间</th>
            <th>服务状态</th>
            <th>处理状态</th>
            <th>处理时间</th>
            <th v-if="chongqing == '重庆'">咨询摘要</th>
            <th v-else>律师小结</th>
            <th v-if="chongqing == '重庆'">是否归档</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in UserOrderLIst">
            <td>
              <div v-if="value.user"><Avatar :src="value.user.UserImg" /> {{ value.user.UserName }}</div>
              <div v-else>--</div>
            </td>
            <td>
              <div v-if="value.lawyer">{{ value.lawyer.LawyerName }}</div>
              <div v-else>--</div>
            </td>
            <td>
              <div v-if="value.Villages">{{ value.Villages.CityName }}{{ value.Villages.street }}{{ value.Villages.VillagesName }}</div>
              <div v-else>--</div>
            </td>
            <!--<td>{{value.UserPhone}}</td>-->
            <td>{{ value.OrderCreatedTime }}</td>
            <td>{{ value.state }}</td>
            <td>{{ value.isReply }}</td>
            <td>{{ value.isReplyTime }}</td>
            <td v-if="chongqing == '重庆'">{{ value.FileExplain }}</td>
            <td v-else>{{ value.OrderSummary ? value.OrderSummary : '未填写' }}</td>
            <td v-if="chongqing == '重庆'">
              <span style="cursor: pointer;color: #2d8cf0" v-if="value.archiveId">已归档</span>
              <span v-else>{{value.IsReply=='浏览过'?'--':'未归档'}}</span>
            </td>
          </tr>
          </tbody>
        </table>

        <Page
            :total="count"
            show-total
            :page-size="pageSize"
            show-elevator
            @on-change="
                        page = $event
                        orderTypeCount(5, undefined, effect)
                    "
        />
      </div>
    </Modal>
    <!--拨打电话-->
    <Modal :draggable="true" v-model="modal18" @on-ok="" @on-cancel="cancel" width="650">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>{{ serviceName }}</span>
        <span style="margin-left: 30px">共{{ count }}条</span>
      </p>
      <div class="publicWelfare">
        <DatePicker
            :value="timeData"
            v-model="timeData"
            type="daterange"
            split-panels
            placeholder="请选择时间"
            style="width: 200px"
            @on-change="totalTime2"
        ></DatePicker>
        <Select v-model="model1" style="width: 200px" @on-change="lawyerChoiceA" filterable clearable>
          <Option v-for="(value, index) in lawyerData" :value="value.LawyerId" :key="value.LawyerId">
            {{ value.LawyerName }}</Option
          >
        </Select>
        <Select
            style="width: 200px"
            v-model="model142"
            filterable
            remote
            clearable
            :remote-method="remoteMethod2"
            @on-change="lawyerChoiceA2"
            :loading="loading2"
            placeholder="请输入社区"
        >
          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesId">
            {{ value.VillagesName }}</Option
          >
        </Select>
        <table class="formtable">
          <thead>
          <tr>
            <th>用户</th>
            <th>法律顾问</th>
            <th>村（社区）</th>
            <th>拨打时间</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in UserCallList">
            <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
            <td>{{ value.LawyerName }}</td>
            <td>{{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}</td>
            <td>{{ value.Time }}</td>
          </tr>
          </tbody>
        </table>

        <Page
            :total="count"
            show-total
            :page-size="pageSize"
            show-elevator
            @on-change="
                        page = $event
                        dialTelephone(serviceType, serviceName, typeData)
                    "
        />
      </div>
    </Modal>

    <!--服务次数剩下的详情-->
    <Modal :draggable="true" v-model="modal17" @on-ok="" @on-cancel="cancel" width="750">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>{{ serviceName }}</span>
        <span style="margin-left: 30px">共{{ count }}条</span>
      </p>
      <div class="publicWelfare">
        <DatePicker
            :value="timeData"
            v-model="timeData"
            type="daterange"
            split-panels
            placeholder="请选择时间"
            style="width: 200px"
            @on-change="totalTime1"
        ></DatePicker>
        <Select v-model="model1" style="width: 200px" @on-change="lawyerChoice" filterable clearable>
          <Option v-for="(value, index) in lawyerData" :value="value.LawyerId" :key="value.LawyerId">
            {{ value.LawyerName }}</Option
          >
        </Select>
        <Select
            style="width: 200px"
            v-model="model14"
            filterable
            remote
            clearable
            :remote-method="remoteMethod2"
            @on-change="lawyerChoice2"
            :loading="loading2"
            placeholder="请输入社区"
        >
          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesId">
            {{ value.VillagesName }}</Option
          >
        </Select>
        <table class="formtable">
          <thead>
          <tr>
            <th>用户</th>
            <th>法律顾问</th>
            <th>村（社区）</th>
            <th v-if="serviceName == '法律援助' || serviceName == '调解申请'">申请状态</th>
            <th>咨询时间</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in detailsSeeLIst">
            <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
            <td>{{ value.LawyerName }}</td>
            <td>{{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}</td>
            <td v-if="serviceName == '法律援助' || serviceName == '调解申请'">{{ value.IsApply }}</td>
            <td>{{ value.OrderCreatedTime || value.ClickTime }}</td>
          </tr>
          </tbody>
        </table>

        <Page
            :total="count"
            show-total
            :page-size="pageSize"
            show-elevator
            @on-change="
                        page = $event
                        detailsSee(serviceType, serviceName)
                    "
        />
      </div>
    </Modal>
    <!--案由排名-->
    <Modal :draggable="true" v-model="modal13" title="关键词分析" @on-ok="" @on-cancel="cancel" width="600">
      <div class="publicWelfare">
        <div id="robot" style="height: 600px; width: 100%"></div>
      </div>
    </Modal>

    <!--案由-->
    <Modal :draggable="true" v-model="modal8"  @on-ok="" @on-cancel="cancel" width="530">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>案由分析</span>
        <span style="margin: 0 10px">-</span>
        <span style="color: red">{{keywordData.keyword}}</span>
      </p>
      <Tabs v-model="keywordData.subscript" @on-click="keywordTabs">
        <TabPane label="区县" name="1" v-if="information.Hierarchy <= 2"></TabPane>
        <TabPane label="乡镇（街道）" name="2" v-if="information.Hierarchy <= 3"></TabPane>
        <TabPane label="村（社区）" name="3" v-if="information.Hierarchy <= 4"></TabPane>
      </Tabs>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <!--<th>案由</th>-->
            <th>序号</th>
            <th>
              <div v-if="keywordData.subscript == 1">区县</div>
              <div v-if="keywordData.subscript == 2">乡镇（街道）</div>
              <div v-if="keywordData.subscript == 3">村（社区）</div>
            </th>
            <th>关注度（次）</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in listData">
            <!--<td>{{value.keyword}}</td>-->
            <td>{{index+1}}</td>
            <td>{{ value.address }}</td>
            <td>{{ value.nums }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  </div>
</template>

<script>
import echarts from "echarts";

export default {
  props: ['modal3','typeId','startTime','endTime',],
  name: "serviceFrequency",
  data (){
    return{
      model142: '',
      typeData: '',
      timeData: '',
      model14: '',
      model1: '',
      loading2: false,
      modal12: false,
      modal18: false,
      modal17: false,
      modal8: false,
      modal13: false,
      effect: '',
      model141: '',
      options2: [],
      model10: '',
      count: 0,
      page: 1,
      pageSize: 15,
      serviceType: '',
      lawyerData: [],
      serviceName: '',
      detailsSeeLIst: [],
      UserCallList: [],
      processingStatusData: [],
      UserOrderLIst: [],
      chengdu: [],
      listData: [],
      information: [],
      orderTypeCountData: [
        { OrderTypeId: 1, OrderTypeTitle: '', count: '' },
        { OrderTypeId: 2, OrderTypeTitle: '自助咨询', count: '' },
        { OrderTypeId: 3, OrderTypeTitle: '视频咨询', count: '' },
        { OrderTypeId: 4, OrderTypeTitle: '模拟判决', count: '' },
        { OrderTypeId: 5, OrderTypeTitle: '代拟法律文书', count: '' },
        { OrderTypeId: 6, OrderTypeTitle: '法律援助', count: '' },
        { OrderTypeId: 7, OrderTypeTitle: '办理公证', count: '' },
        { OrderTypeId: 8, OrderTypeTitle: '调解申请', count: '' },
        { OrderTypeId: 9, OrderTypeTitle: '民法典', count: '' },
        { OrderTypeId: 10, OrderTypeTitle: '拨打律师电话', count: '' },
        { OrderTypeId: 11, OrderTypeTitle: '拨打12348热线', count: '' },
        { OrderTypeId: 12, OrderTypeTitle: '有效咨询', count: '' },
        { OrderTypeId: 14, OrderTypeTitle: '其他咨询', count: '' }
        // {OrderTypeId: 13, OrderTypeTitle: "语音咨询", count: '',},
      ],
      IsReplyList: [
        {
          value: '1',
          label: '已处理'
        },
        {
          value: '2',
          label: '未处理'
        }
      ],
      isArchive : '',
      chongqing: '',
      keywordData:{
        keyword:'',
        subscript:'1',
      }
    }
  },
  mounted() {
    var _this = this
    this.information = window.sessionStorage
    if (SYSCONFIG.location == 'chong-qing') {
      _this.chongqing = '重庆'
      _this.orderTypeCountData.push(
          { OrderTypeId: 15, OrderTypeTitle: '企业征信', count: '' },
          { OrderTypeId: 16, OrderTypeTitle: '法律法规', count: '' },
          { OrderTypeId: 17, OrderTypeTitle: '司法案例库', count: '' },
          { OrderTypeId: 18, OrderTypeTitle: '网上立案', count: '' },
          { OrderTypeId: 19, OrderTypeTitle: '学法用法', count: '' },
          { OrderTypeId: 20, OrderTypeTitle: '法律服务地图', count: '' }
      )
    } else {
      _this.findDazhou()
    }
    this.serviceTimes()
  },
  methods:{
    findDazhou: function () {
      var _this = this
      _this.send({
        url: '/CD_FindDazhou',
        data: {
          type: 'chengdu'
        },
        success: function (res) {
          for (var i in res) {
            if (res[i] == window.sessionStorage.AdminId) {
              _this.chengdu = true
              return
            } else {
              _this.chengdu = false
            }
          }
          console.log(res)
        }
      })
    },
    fileChoice: function (e) {
      this.isArchive = e
      this.orderTypeCount(5, undefined, this.effect)
    },
    stateChoice: function (e) {
      this.IsReply = e
      this.orderTypeCount(5, undefined, this.effect)
    },
    totalTime0: function (e) {
      var _this = this
      ;(_this.startTime = e[0]), //开始时间
          (_this.endTime = e[1]) //结束时间
      _this.page = 1
      _this.orderTypeCount(5, undefined, _this.effect)
    },
    orderTypeCount: function (type, adminId, index) {
      var _this = this
      console.log(type)
      if (type == 1) {
        _this.modal10 = true
        _this.adminVillagesData = []
        console.log(adminId)
        if (adminId == undefined) {
          _this.administration = window.sessionStorage.AdminId
        } else {
          _this.administration = adminId
        }
        _this.send({
          url: '/VillageListByPage',
          data: {
            AdminId: _this.administration,
            typeId: _this.typeId,
            page: _this.page,
            pageSize: _this.pageSize,
            key: _this.search,
            LawyerName: _this.LawyerName,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            sort: _this.sort
          },
          success: function (res) {
            _this.adminVillagesData = res.data
            _this.count = res.total
          }
        })
      }
      if (type == 2) {
        _this.modal11 = true
        _this.send({
          url: '/AdminUserList',
          loading: true,
          data: {
            AdminId: window.sessionStorage.AdminId,
            typeId: _this.typeId,
            page: _this.page,
            pageSize: _this.pageSize,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            VillagesId: _this.VillagesId,
            key: _this.userKey
          },
          success: function (res) {
            for (var i in res.data) {
              if(res.data[i].UserPhone){
                var mobile = res.data[i].UserPhone
                var tempStr = ''
                for (var j = 0; j < mobile.length; j++) {
                  if (j < 3 || j > mobile.length - 3) {
                    tempStr += mobile[j]
                  } else {
                    tempStr += '*'
                  }
                }
                res.data[i].UserPhone = tempStr
              }
            }
            _this.adminUserData = res.data
            _this.count = res.total
          }
        })
      }
      if (type == 3) {
        _this.modal15 = true
        _this.checkAllGroup = []
        _this.send({
          url: '/AdminLawyerList',
          loading: true,
          data: {
            AdminId: window.sessionStorage.AdminId,
            page: _this.page,
            pageSize: _this.pageSize,
            key: _this.searchLaw,
            isBinding:_this.isBinding
          },
          success: function (res) {
            _this.adminLawyerList = res.List
            for (var i in res.List) {
              var num = res.List[i].avgs
              console.log(Math.floor(num * 100) / 100)
            }
            _this.count = res.Count||0
            _this.BingCount = res.BingCount || 0
            _this.avgs = res.List.legend?res.List[0].avgs:0
            _this.sum = res.Sum || 0
          }
        })
        _this.send({
          url: '/AdminLawyerAvg',
          data: {
            AdminId: window.sessionStorage.AdminId,
            page: _this.page,
            pageSize: _this.pageSize,
            key: _this.searchLaw
          },
          success: function (res) {
            _this.lawyerAvg = res.avg
          }
        })
      }
      if (type == 4) {
        _this.modal3 = true
        // _this.send({
        //   url: '/OrderTypeCount',
        //   data: {
        //     AdminId: window.sessionStorage.AdminId,
        //     typeId: _this.typeId,
        //     start_time: _this.startTime,//开始时间
        //     end_time: _this.endTime
        //   },
        //   success: function (res) {
        //     _this.orderTypeCountData = res
        //   }
        // })
      }
      if (type == 6) {
        this.$router.push({ name: 'MAL' })
      }
      if (type == 'kq') {
        this.$router.push({ path: '/punchIn' })
      }
      if (type == 5) {
        _this.effect = index
        if (_this.effect == 1) {
          _this.processingStatus()
          _this.send({
            url: '/UserOrderLIst',
            loading: true,
            data: {
              AdminId: window.sessionStorage.AdminId,
              start_time: _this.startTime, //开始时间
              end_time: _this.endTime, //结束时间
              nameKeyId: _this.nameKeyId, //关键字
              page: _this.page,
              pageSize: _this.pageSize,
              VillagesId: _this.VillagesId,
              IsReply: _this.IsReply,
              isArchive : _this.isArchive,
            },
            success: function (res) {
              for (var i in res.list) {
                if (res.list[i].jsonContent) {
                  for (var k in res.list[i].jsonContent) {
                    if (JSON.parse(res.list[i].jsonContent[k]).type == 1) {
                      console.log(JSON.parse(res.list[i].jsonContent[k]))
                      var time = JSON.parse(res.list[i].jsonContent[k])
                          .sendTime.replace(/年|月/g, '-')
                          .replace('日', '')
                      res.list[i].handleTime = time.substr(0, time.length - 3)
                      break
                    } else {
                      res.list[i].handleTime = '--'
                    }
                  }
                } else {
                  res.list[i].handleTime = '--'
                }
              }
              _this.UserOrderLIst = res.list
              _this.count = res.count
            }
          })
        }
        if (index == 2) {
          _this.processingStatus()
          _this.send({
            url: '/UserOrderLIst',
            loading: true,
            data: {
              AdminId: window.sessionStorage.AdminId,
              start_time: _this.startTime, //开始时间
              end_time: _this.endTime, //结束时间
              nameKeyId: _this.nameKeyId, //关键字
              page: _this.page,
              pageSize: _this.pageSize,
              VillagesId: _this.VillagesId,
              IsReply: _this.IsReply,
              isValid : '1',//随便传
              isArchive : _this.isArchive,
            },
            success: function (res) {
              for (var i in res.list) {
                for (var k in res.list[i].jsonContent) {
                  if (JSON.parse(res.list[i].jsonContent[k]).type == 1) {
                    console.log(JSON.parse(res.list[i].jsonContent[k]))
                    var time = JSON.parse(res.list[i].jsonContent[k])
                        .sendTime.replace(/年|月/g, '-')
                        .replace('日', '')
                    res.list[i].handleTime = time.substr(0, time.length - 3)
                    break
                  } else {
                    res.list[i].handleTime = '--'
                  }
                }
              }
              _this.UserOrderLIst = res.list
              _this.count = res.count
            }
          })
        }

        // 详情
      }
    },
    robotImplement: function () {
      var _this = this
      let myChart1 = echarts.init(document.getElementById('robot'), 'light')
      _this.send({
        url: '/keyword_click',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          var click_count = [] //数量
          var keyword = [] //名称
          var gid = [] //名称

          var compare = function (prop) {
            return function (obj1, obj2) {
              var val1 = obj1[prop]
              var val2 = obj2[prop]
              if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
                val1 = Number(val1)
                val2 = Number(val2)
              }
              if (val1 < val2) {
                return -1
              } else if (val1 > val2) {
                return 1
              } else {
                return 0
              }
            }
          }
          //调用方式
          res.sort(compare('click'))
          console.log(res.sort(compare('click')))
          // for(var i=res.length-1;i>=0;i--){
          //     click_count.push(res[i].click)
          //     keyword.push(res[i].keyword)
          // }
          for (var i in res) {
            click_count.push(res[i].click)
            keyword.push(res[i].keyword)
            gid.push(res[i].gid)
          }
          console.log(keyword)
          // myChart1.on('click', params => {
          //     console.log(params)
          // })
          myChart1.getZr().on('click', (params) => {
            console.log(gid[clickname.dataIndex])
            // console.log(gid[clickname.dataIndex])
                _this.keywordData.keyword = clickname.name //开始时间
            if(_this.information.Hierarchy == 3) {_this.keywordData.subscript = '2'}
            if(_this.information.Hierarchy == 4) {_this.keywordData.subscript = '3'}
            _this.keywordTabs(_this.keywordData.subscript)
            _this.modal8 = !_this.modal8
          })
          var clickname
          var clickid
          myChart1.setOption({
            title: {
              text: '关键词点击量TOP30',
              subtext: '案由TOP30'
            },
            tooltip: {
              trigger: 'axis',
              formatter: function (params) {
                for (var i in params) {
                  clickname = params[i]

                  // return params[i].name+','+ gid[i]
                }
                for (var i in gid) {
                  clickid = gid[i]
                  // console.log(gid[i])
                }
              },
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              data: ['关键词']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.01]
            },
            yAxis: {
              type: 'category',
              data: keyword
            },
            series: [
              {
                label: {
                  show: true,
                  position: 'right'
                },
                name: gid,
                type: 'bar',
                data: click_count
              }
            ]
          })
        }
      })
    },
    keyword_details:function (){
      var _this = this
      _this.send({
        url: '/keyword_details',
        loading:true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          keyword: _this.keywordData.keyword //开始时间
        },
        success: function (res) {
          var data = []
          for(var i in res){
            data.push({
              address : res[i].CityName+ res[i].AdminName + res[i].VillagesName,
              nums : res[i].nums
            })
          }
          _this.listData = data
        }
      })
    },
    keywordTabs: function (type){
      console.log(type)
      if(type == 1){
        this.country_keyword_details()
      }
      if(type == 2){
        this.street_keyword_details()
      }
      if(type == 3){
        this.keyword_details()
      }
    },
    country_keyword_details:function (){
      var _this = this
      _this.send({
        url: '/country_keyword_details',
        loading:true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          keyword: _this.keywordData.keyword //开始时间
        },
        success: function (res) {
          var data = []
          for(var i in res){
            data.push({
              address : res[i].AdminName.replace('司法局',''),
              nums : res[i].click_count
            })
          }
          _this.listData = data
        }
      })
    },
    street_keyword_details:function (){
      var _this = this
      _this.send({
        url: '/street_keyword_details',
        loading:true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          keyword: _this.keywordData.keyword //开始时间
        },
        success: function (res) {
          var data = []
          for(var i in res){
            data.push({
              address : res[i].pname[0].AreaShortName + res[i].AdminName,
              nums : res[i].click_count
            })
          }
          _this.listData = data
        }
      })
    },
    lawyerChoiceA2: function (e) {
      var _this = this
      if (e == undefined) {
        _this.VillagesId = ''
        _this.options2 = []
      } else {
        _this.VillagesId = e
      }
      _this.page = 1
      _this.dialTelephone(_this.serviceType, _this.serviceName, _this.typeData)
      console.log(e)
    },
    //    机器人咨询TOP30
    robot: function () {
      var _this = this
      _this.modal13 = true
      this.$nextTick(() => {
        this.robotImplement()
      })
    },
    remoteMethod2(query) {
      console.log(query)
      var _this = this
      _this.send({
        url: '/villagesList',
        data: {
          AdminId: window.sessionStorage.AdminId,
          Key: query
        },
        success: function (res) {
          console.log(res)
          _this.options2 = res.list
        }
      })
    },
    lawyerClick2: function (e) {
      var _this = this
      if (e == undefined) {
        _this.VillagesId = ''
        _this.options2 = []
      } else {
        _this.VillagesId = e
      }
      _this.page = 1
      _this.orderTypeCount(5, undefined, _this.effect)
      console.log(e)
    },
    lawyerClick: function (e) {
      var _this = this
      if (e == undefined) {
        _this.nameKeyId = ''
      } else {
        _this.nameKeyId = e
      }
      _this.page = 1
      _this.orderTypeCount(5, undefined, _this.effect)
      console.log(e)
    },
    lawyerList: function () {
      var _this = this
      _this.send({
        url: '/LawyerList',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          _this.lawyerData = res.list
          console.log(res)
        }
      })
    },
    detailsSee: function (type, name) {
      var _this = this
      _this.serviceType = type
      _this.serviceName = name
      // _this.lawyerList()
      console.log(type)
      if (type == 3) {
        var _this = this
        _this.send({
          url: '/UserVideoList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.list
            console.log(_this.detailsSeeLIst)
            _this.count = res.count
          }
        })
      }
      if (type == 4) {
        var _this = this
        _this.send({
          url: '/UserDecisionList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.list
            console.log(_this.detailsSeeLIst)
            _this.count = res.count
          }
        })
      }
      if (type == 5) {
        var _this = this
        _this.send({
          url: '/UserWritList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.list
            console.log(_this.detailsSeeLIst)
            _this.count = res.count
          }
        })
      }
      if (type == 6) {
        var _this = this
        _this.send({
          url: '/UserLegalList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.list
            console.log(_this.detailsSeeLIst)
            _this.count = res.count
          }
        })
      }
      if (type == 7) {
        var _this = this
        _this.send({
          url: '/UserHandleList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.list
            console.log(_this.detailsSeeLIst)
            _this.count = res.count
          }
        })
      }
      if (type == 8) {
        var _this = this
        _this.send({
          url: '/UserMediateList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.list
            console.log(_this.detailsSeeLIst)
            _this.count = res.count
          }
        })
      }
      if (type == 9) {
        var _this = this
        _this.send({
          url: '/UserMediateList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.list
            console.log(_this.detailsSeeLIst)
            _this.count = res.count
          }
        })
      }

      if (type == 15) {
        var _this = this
        _this.send({
          url: '/InvestigationList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.List
            _this.count = res.Count
          }
        })
      }
      if (type == 16) {
        var _this = this
        _this.send({
          url: '/RegulationsList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.List
            _this.count = res.Count
          }
        })
      }
      if (type == 17) {
        var _this = this
        _this.send({
          url: '/JudicialCaseList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.List
            _this.count = res.Count
          }
        })
      }
      if (type == 18) {
        var _this = this
        _this.send({
          url: '/OnRegistrationList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.List
            _this.count = res.Count
          }
        })
      }
      if (type == 19) {
        var _this = this
        _this.send({
          url: '/MethodUseList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.List
            _this.count = res.Count
          }
        })
      }
      if (type == 20) {
        var _this = this
        _this.send({
          url: '/ServiceMapList',
          data: {
            AdminId: window.sessionStorage.AdminId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            page: _this.page,
            pageSize: _this.pageSize,
            VillagesId: _this.VillagesId,
            nameKeyId: _this.nameKeyId //关键字
          },
          success: function (res) {
            _this.detailsSeeLIst = res.List
            _this.count = res.Count
          }
        })
      }
    },
    lawyerChoice2: function (e) {
      var _this = this
      if (e == undefined) {
        _this.VillagesId = ''
        _this.options2 = []
      } else {
        _this.VillagesId = e
      }
      _this.page = 1
      _this.detailsSee(_this.serviceType, _this.serviceName)
      console.log(e)
    },
    lawyerChoice: function (e) {
      var _this = this
      if (e == undefined) {
        _this.nameKeyId = ''
      } else {
        _this.nameKeyId = e
      }
      _this.page = 1
      _this.detailsSee(_this.serviceType, _this.serviceName)
      console.log(e)
    },
    totalTime1: function (e) {
      var _this = this
      ;(_this.startTime = e[0]), //开始时间
          (_this.endTime = e[1]) //结束时间
      _this.page = 1
      _this.detailsSee(_this.serviceType, _this.serviceName)
    },
    dialTelephone: function (type, name, typeData) {
      var _this = this
      _this.serviceType = type
      _this.serviceName = name
      _this.typeData = typeData
      _this.lawyerList()
      _this.send({
        url: '/UserCallList',
        data: {
          type: typeData,
          AdminId: window.sessionStorage.AdminId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime, //结束时间
          page: _this.page,
          pageSize: _this.pageSize,
          VillagesId: _this.VillagesId,
          nameKeyId: _this.nameKeyId //关键字
        },
        success: function (res) {
          console.log(res.List)
          _this.UserCallList = res.List
          _this.count = res.Count
        }
      })
    },
    lawyerChoiceA: function (e) {
      var _this = this
      if (e == undefined) {
        _this.nameKeyId = ''
      } else {
        _this.nameKeyId = e
      }
      _this.page = 1
      _this.dialTelephone(_this.serviceType, _this.serviceName, _this.typeData)
      console.log(e)
    },
    totalTime2: function (e) {
      var _this = this
      ;(_this.startTime = e[0]), //开始时间
          (_this.endTime = e[1]) //结束时间
      _this.page = 1
      _this.dialTelephone(_this.serviceType, _this.serviceName, _this.typeData)
    },
    processingStatus: function () {
      var _this = this
      _this.send({
        url: '/IsReplyCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime, //结束时间
          nameKeyId: _this.nameKeyId, //关键字
          page: _this.page,
          pageSize: _this.pageSize,
          VillagesId: _this.VillagesId
        },
        success: function (res) {
          _this.processingStatusData = res
        }
      })
    },
    serviceTimes: function () {
      var _this = this
      if (SYSCONFIG.location == 'chong-qing') {
        _this.orderTypeCountData[0].OrderTypeTitle = '留言咨询'
      } else {
        _this.orderTypeCountData[0].OrderTypeTitle = '人工咨询'
      }
      _this.send({
        url: '/ValidUserCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[11].count = res.count
        }
      })
      _this.send({
        url: '/UserOrderCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[0].count = res.count
        }
      })
      _this.send({
        url: '/zizhuUserCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[1].count = res.count
        }
      })
      _this.send({
        url: '/UserVideoCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[2].count = res.count
        }
      })
      _this.send({
        url: '/UserDecisionCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[3].count = res.count
        }
      })
      _this.send({
        url: '/UserWritCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[4].count = res.count
        }
      })
      _this.send({
        url: '/UserLegalCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[5].count = res.count
        }
      })
      _this.send({
        url: '/UserHandleCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[6].count = res.count
        }
      })
      _this.send({
        url: '/UserMediateCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[7].count = res.count
        }
      })
      _this.send({
        url: '/MingCodeUserCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.orderTypeCountData[8].count = res.count
        }
      })
      _this.send({
        url: '/UserCallCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime,
          type: 1
        },
        success: function (res) {
          _this.orderTypeCountData[9].count = res.Count
        }
      })
      _this.send({
        url: '/UserCallCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime,
          type: 2
        },
        success: function (res) {
          _this.orderTypeCountData[10].count = res.Count
        }
      })
      //其他咨询
      _this.send({
        url: '/OtherCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime,
          type: 2
        },
        success: function (res) {
          for (var i in _this.orderTypeCountData) {
            if (_this.orderTypeCountData[i].OrderTypeId == 14) {
              _this.orderTypeCountData[i].count = res.count
            }
          }
        }
      })
      if (SYSCONFIG.location == 'chong-qing') {
        _this.send({
          url: '/InvestigationCount',
          data: {
            AdminId: window.sessionStorage.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.orderTypeCountData) {
              if (_this.orderTypeCountData[i].OrderTypeId == 15) {
                _this.orderTypeCountData[i].count = res.Count
              }
            }
          }
        })
        _this.send({
          url: '/RegulationsCount',
          data: {
            AdminId: window.sessionStorage.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.orderTypeCountData) {
              if (_this.orderTypeCountData[i].OrderTypeId == 16) {
                _this.orderTypeCountData[i].count = res.Count
              }
            }
          }
        })
        _this.send({
          url: '/JudicialCaseCount',
          data: {
            AdminId: window.sessionStorage.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.orderTypeCountData) {
              if (_this.orderTypeCountData[i].OrderTypeId == 17) {
                _this.orderTypeCountData[i].count = res.Count
              }
            }
          }
        })
        _this.send({
          url: '/OnRegistrationCount',
          data: {
            AdminId: window.sessionStorage.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.orderTypeCountData) {
              if (_this.orderTypeCountData[i].OrderTypeId == 18) {
                _this.orderTypeCountData[i].count = res.Count
              }
            }
          }
        })
        _this.send({
          url: '/MethodUseCount',
          data: {
            AdminId: window.sessionStorage.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.orderTypeCountData) {
              if (_this.orderTypeCountData[i].OrderTypeId == 19) {
                _this.orderTypeCountData[i].count = res.Count
              }
            }
          }
        })
        _this.send({
          url: '/ServiceMapCount',
          data: {
            AdminId: window.sessionStorage.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.orderTypeCountData) {
              if (_this.orderTypeCountData[i].OrderTypeId == 20) {
                _this.orderTypeCountData[i].count = res.Count
              }
            }
          }
        })
      }
    },
    cancel1() {
      const newValue = false
      this.$emit('message-updated', newValue);
    },
    cancel() {
      var _this = this
      _this.page = 1
      _this.count = 0
      _this.detailsSeeLIst = []
      _this.UserOrderLIst = []
      _this.model1 = ''
      _this.model14 = ''
      console.log(_this.endTime, _this.startTime)
    },
  }
}
</script>

<style scoped>
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>
