import { render, staticRenderFns } from "./legalVideo.vue?vue&type=template&id=c7902828&scoped=true"
import script from "./legalVideo.vue?vue&type=script&lang=js"
export * from "./legalVideo.vue?vue&type=script&lang=js"
import style0 from "./legalVideo.vue?vue&type=style&index=0&id=c7902828&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7902828",
  null
  
)

export default component.exports