//手机公共端路由模块加载器 Zima -- 2020-10-20
import nullPage from '@/views/nullPage'

var _public = {
  videoCall:require('@/views/mobile/public/video-call').default,
  videoChat:{
    single:require('@/views/mobile/public/video-chat/single').default,
  },
}

var routes = [
  {
    path:'public',
    component:nullPage,
    children:[
      {path: 'video-call',component:_public.videoCall,name:'MOPV',meta:{title:'视频通话'}},
      {path: 'video-chat',component:nullPage,children:[
        {path: 'single',component:_public.videoChat.single,meta:{title:'视频通话'}},
      ]},
    ]
  },
]

export default {
  routes:routes
}