export default {
  menuList:[
    { name: '首页', routeName: 'managerIndex',iconType:'svg',iconSrc:'/img/icons-svg/menu/home.svg',},
    { name: '区域管理',routeName: 'judicialoffice',iconType:'svg',iconSrc:'/img/icons-svg/menu/area.svg',},
    // { name: '区域管理',routeName: 'communityRevise',iconType:'svg',iconSrc:'/img/icons-svg/menu/area.svg',},
    { name: '区域管理',routeName: 'MAAL',iconType:'svg',iconSrc:'/img/icons-svg/menu/area.svg',},
    { name: '顾问管理',routeName: 'lawyerManagement',iconType:'svg',iconSrc:'/img/icons-svg/menu/lawyer.svg',},
    { name: '村(居)服务管理',routeName: 'community',iconType:'svg',iconSrc:'/img/icons-svg/menu/lawfirm.svg',},
    // { name: '村（社区）管理',routeName: 'villageAdmin',iconType:'svg',iconSrc:'/img/icons-svg/menu/lawyer.svg',},
    { name: '用户管理',routeName: 'userAdministration',iconType:'svg',iconSrc:'/img/icons-svg/menu/user.svg',},
    // {
    //   name: '服务管理',
    //   iconType:'svg',
    //   iconSrc:'/img/icons-svg/menu/service.svg',
    //   children:[
        { name: '咨询服务',routeName: 'consultingService',iconType:'svg',iconSrc:'/img/icons-svg/menu/consulting.svg',},
        { name: '申办服务',routeName: 'handlingServices',iconType:'svg',iconSrc:'/img/icons-svg/menu/handling.svg',},
        { name: '查询服务',routeName: 'queryService',iconType:'svg',iconSrc:'/img/icons-svg/menu/query.svg',},
        { name: '事件报送',routeName: 'earlyWarning',iconType:'svg',iconSrc:'/img/icons-svg/menu/submitted.svg',},
        // { name: '法律援助',routeName: 'legalAidList',},
        // { name: '人民调解',routeName: 'MML',},
    //   ]
    // },
    { name: '律所管理',routeName: 'lawFirm',iconType:'svg',iconSrc:'/img/icons-svg/menu/lawfirm.svg',},
    { name: '律师管理',routeName: 'lawyer',iconSrc:'/img/icons-svg/menu/lawyer.svg',},
    { name: '档案管理',routeName: 'MAL',iconType:'svg',iconSrc:'/img/icons-svg/menu/archives.svg',},
    { name: '报表管理',routeName:'report',iconType:'svg',iconSrc:'/img/icons-svg/menu/report.svg',},
    { name: '考勤打卡',routeName: 'punchIn',iconType:'svg',iconSrc:'/img/icons-svg/menu/punchIn.svg',},
    { name: '律师积分',routeName: 'pointsMenu',iconType:'svg',iconSrc:'/img/icons-svg/menu/integral.svg',},
    // { name: '大数据中心',routeName: 'bigData',iconType:'svg',iconSrc:'/img/icons-svg/menu/bigdata.svg',}, // 2024/1/6暂时隐藏
    // {
    //   name: '明白人管理',
    //   iconType:'svg',
    //   iconSrc:'/img/icons-svg/menu/service.svg',
    //   children:[
    //     { name: '问卷管理',routeName:'questionnaireList',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    //     { name: '明白人列表',routeName:'understandingList',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    //     // { name: '日程列表',routeName:'aScheduleList',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    //     { name: '工作日志',routeName:'workLog',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    //   ]
    // },
    { name: '服务时间设置',routeName:'timeSet',iconType:'svg',iconSrc:'/img/icons-svg/menu/sts.svg',},
    { name: '内容推送',routeName: 'article',iconType:'svg',iconSrc:'/img/icons-svg/menu/pushArticle.svg',},
    { name: '普法宣传',routeName: 'publicityofSpd',iconType:'svg',iconSrc:'/img/icons-svg/menu/pfxc.svg',},
    { name: '数据推送 ',routeName: 'dataPush',iconType:'svg',iconSrc:'/img/icons-svg/menu/pushmsg.svg',},
    { name: '热点法律 ',routeName: 'hotspotlaw',iconType:'svg',iconSrc:'/img/icons-svg/menu/pushmsg.svg',},
    { name: '公益律师审核',routeName: 'publicInterestLawyer',iconType:'svg',iconSrc:'/img/icons-svg/menu/toExamine.svg',},
    { name: '意见反馈',routeName: 'feedback',iconType:'svg',iconSrc:'/img/icons-svg/menu/feedback.svg',},
    { name: '运行报告',routeName: 'runReport',iconType:'svg',iconSrc:'/img/icons-svg/menu/runReport.svg',},
    { name: '小程序数据管理',routeName: 'backstageAdmin',iconType:'svg',iconSrc:'/img/icons-svg/menu/data-statistics.svg',},
    { name: '补贴发放记录',routeName: 'subsidyRecord',iconType:'svg',iconSrc:'/img/icons-svg/menu/subsidy.svg',},
  ],
  displayRules:{
    /*
      *level-按Admin账号等级显示;
      *specific-指定AdminID显示;
      *specificAll-指定AdminID及下属全部显示;
      *specificHidden-指定AdminID隐藏;
      *specificHideAll-指定AdminID及下属全部隐藏;
      *默认全部展示
      *需要建立与routeName相同名称的对象
      *填入ID，对应规则生效
      *按层级判断，有一个判断结果为真则显示
    */
    'lawyer':{level:[4],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'MAAL':{level:[0],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'judicialoffice':{level:[1,2],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    // 'communityRevise':{level:[3],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    // 'villageAdmin':{level:[1,2],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],},
    'hotspotlaw':{level:[],specific:[0],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'earlyWarning':{level:[],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[1]},
    'subsidyRecord':{level:[1,2],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
  }
}
