<template>
    <div class="informationFillin">
<!--        <div style="padding: 0.2rem">-->
<!--            <Steps :current="active">-->
<!--                <Step title="申请人信息"></Step>-->
<!--                <Step title="被申请人信息"></Step>-->
<!--                <Step title="纠纷信息"></Step>-->
<!--            </Steps>-->
<!--        </div>-->
<!--        <div style="padding: 0.08rem;background-color: rgb(244 244 244)"></div>-->
      <van-form @submit="submit">
<!--   纠纷信息     -->
<!--        <van-field-->
<!--            readonly-->
<!--            clickable-->
<!--            name="bsqrcsrq"-->
<!--            :value="formValidate.yysjq"-->
<!--            placeholder="预约时间起" label-width="12em"-->
<!--            @click="aboutTime = true;riseStop = 1"  :rules="[{ required: true, message: '请选择预约时间' }]"-->
<!--        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>预约时间起：</div></div></van-field>-->
<!--        <van-field-->
<!--            readonly-->
<!--            clickable-->
<!--            name="bsqrcsrq"-->
<!--            :value="formValidate.yysjz"-->
<!--            placeholder="预约时间止" label-width="12em"-->
<!--            @click="aboutTime = true;riseStop = 2"  :rules="[{ required: true, message: '请选择预约时间' }]"-->
<!--        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>预约时间止：</div></div></van-field>-->
        <van-field
            readonly
            clickable
            name="jflx"
            :value="jflx"
            placeholder="选择纠纷类型"label-width="12em"
            @click="dropDownChoice('jf')" :rules="[{ required: true, message: '请选择纠纷类型' ,trigger :'onChange'}]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>调解类型：</div></div></van-field>
        <van-field
            v-model="formValidate.jfjj"
            name="jfjj" type="textarea"
            placeholder="请填写纠纷概述"label-width="12em"
            :rules="[{ required: true, message: '请填写纠纷简介' }]"
        >
          <div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>调解内容说明：</div></div>
        </van-field>
<!--        -->
        <div style="margin: 0.3rem 0 0.1rem 0.3rem;font-weight: bold"> 申请人信息</div>
        <div style="margin: 0.15rem;">
          <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证：</div>
          <div style="text-align: center;">
            <van-uploader :after-read="afterRead" >
              <img v-if="SupplementContent.sfcl" :src="SupplementContent.sfcl" style="width:3.6rem;height:2.5rem;"/>
              <img v-else src="/img/legalAid/IDcard1.png" style="width:3.6rem;height:2.5rem;"/>
              <div>点击拍摄/更换身份证照片</div>
              <div>1.上传申请人身份证，自动录入信息；</div>
              <div>2.身份证上的信息不能被遮挡，且清晰可见；</div>
              <div>3.支持jpg/bmp格式，最大不超过2MB。</div>
            </van-uploader>
          </div>
        </div>
        <div style="padding: 0 0.2rem;" class="genderDateNation">
          <div style="display:inline-block;width: 50%;border-bottom: 0.01rem solid #e8e8e8;">
            <van-field
                v-model="formValidate.sqrxm"
                name="sqrxm"
                placeholder="请输入"
                :rules="[{ required: true, message: '请填写姓名' }]"
            >
              <div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>姓名：</div></div>
            </van-field>
          </div>
          <div style="display:inline-block;width: 50%;border-bottom: 0.01rem solid #e8e8e8;">
            <van-field
                readonly
                clickable
                name="sqrxb"
                :value="sqrxb"
                placeholder="性别"
                @click="dropDownChoice('xb')" :rules="[{ required: true, message: '请选择性别' ,trigger :'onChange'}]"
            ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>性别：</div></div></van-field>
          </div>
        </div>
        <!--        性别、民族、日期-->
        <div style="padding: 0 0.2rem;" class="genderDateNation">

          <div style="display:inline-block;width: 50%;border-bottom: 0.01rem solid #e8e8e8;">
            <van-field
                readonly
                clickable
                name="sqrmz"
                :value="sqrmz"
                placeholder="民族"
                @click="dropDownChoice('mz')" :rules="[{ required: true, message: '请选择民族',trigger :'onChange' }]"
            ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>民族：</div></div></van-field>
          </div>
          <div style="display:inline-block;width: 50%;border-bottom: 0.01rem solid #e8e8e8;">
            <van-field
                readonly
                clickable
                name="sqrcsrq"
                :value="formValidate.sqrcsrq"
                placeholder="出生日期"
                @click="birthday = true;active=0" :rules="[{ required: true, message: '出生日期' ,trigger :'onChange'}]"
            ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>出生日期：</div></div></van-field>
          </div>
        </div>
        <van-field
            v-model="formValidate.sqrsfzh"
            name="sqrsfzh"
            placeholder="请输入"label-width="8em"
            :rules="[{ required: true, message: '请填写身份证号码' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证号码：</div></div></van-field>
        <van-field
            v-model="formValidate.sqrdz"
            name="sqrdz"
            placeholder="请输入"label-width="8em"
            :rules="[{ required: true, message: '请填写居住地址' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>居住地址：</div></div></van-field>
        <van-field
            v-model="formValidate.sqrsjhm"
            name="sqrsjhm"
            placeholder="请输入"label-width="8em"
            :rules="[{ required: true, message: '请填写手机号' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>手机号：</div></div></van-field>
        <div style="margin: 0.3rem 0 0.1rem 0.3rem;font-weight: bold"> 被申请人信息</div>
        <van-field
            v-model="formValidate.bsqrxm"
            name="bsqrxm"
            placeholder="请输入"label-width="12em"
            :rules="[{ required: true, message: '姓名' }]"
        >
          <div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>姓名：</div></div>
        </van-field>
        <van-field
            v-model="formValidate.bsqrsjhm"
            name="bsqrsjhm"
            placeholder="请输入"label-width="12em"
            :rules="[{ required: true, message: '请填写联系电话' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>联系电话：</div></div></van-field>
        <van-field
            v-model="formValidate.bsqrsfzh"
            name="bsqrsfzh"
            placeholder="请输入"label-width="12em"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>身份证号：</div></div></van-field>
        <van-field
            v-model="formValidate.bsqrdz"
            name="bsqrdz"
            placeholder="请输入"label-width="12em"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>地址：</div></div></van-field>
        <van-field
            readonly
            clickable
            name="bsqrxb"
            :value="bsqrxb"
            placeholder="选择性别"label-width="12em"
            @click="dropDownChoice('bxb')"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>性别：</div></div></van-field>
        <van-field
            readonly
            clickable
            name="bsqrmz"
            :value="bsqrmz"
            placeholder="选择民族"label-width="12em"
            @click="dropDownChoice('bmz')"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>民族：</div></div></van-field>
        <van-field
            readonly
            clickable
            name="bsqrcsrq"
            :value="formValidate.bsqrcsrq"
            placeholder="选择出生日期" label-width="12em"
            @click="birthday = true;active=1":rules="[{ required: true, message: '出生日期' ,trigger :'onChange'}]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>出生日期：</div></div></van-field>
<!--        :rules="[{ required: true, message: '出生日期' }]"-->
<!--        <van-field-->
<!--            v-model="formValidate.ly"-->
<!--            name="bsqrsfzh"-->
<!--            placeholder="请输入"label-width="12em"-->
<!--        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>来源：</div></div></van-field>-->
        <!--        多文件上传-->
        <div style="margin: 0.3rem 0 0.1rem 0.3rem;font-weight: bold"> 材料上传</div>
        <div style="margin-left: 0.3rem">

          <van-uploader v-model="fileList" multiple  :after-read="multipleFiles" :before-delete="deleteImg"/>
        </div>
        <div style="padding: 0.6rem"></div>
        <van-button round block type="info" native-type="submit" style="width: 100%;background-color: rgb(45 140 240);height: 0.8rem;line-height: 0.8rem;color: #FFF;font-size: 0.32rem;font-weight: bold;text-align: center;position: fixed;bottom: 0;border-radius: 0;right: 0">
          提交
        </van-button>
      </van-form>
      <!--下拉-->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="dropDownData"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
            ></van-picker>
        </van-popup>
        <!--出生日期-->
        <van-popup v-model="birthday" position="bottom">
            <van-datetime-picker v-model="currentDate"
                    type="date"
                    @confirm="onBirthday"
                    @cancel="birthday = false"
                    :min-date="minDate" :max-date="maxDate"
            ></van-datetime-picker>
        </van-popup>
        <!--预约时间-->
        <van-popup v-model="aboutTime" position="bottom">
            <van-datetime-picker v-model="currentDate"
                    type="datetime"
                    @confirm="onAboutTime"
                    @cancel="aboutTime = false"
                    :min-date="minDate" :max-date="maxDate"
            ></van-datetime-picker>
        </van-popup>
    </div>
</template>

<script>
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Toast } from 'vant';
    import axios from "axios";
    export default {
        name: "informationFillin",
        data (){
            return {
                //上传身份证
                uploadPicture: [],

                currentDate: new Date(),
                birthday: false,
                aboutTime: false,
                riseStop : '',
                dropDownData : [],
                dispute : [],
                nation : [],
                minDate: new Date(1900, 0, 1),
                maxDate : new Date(),
                sqrxb : '',
                bsqrxb : '',
                sqrmz : '',
                bsqrmz : '',
                jflx : '',
                totalDropDownData : {
                    xb : ['男','女'],
                    mz : [],
                    jf : [],
                },
                type : '',
                showPicker: false,
                active : 0,
                formValidate :{
                    ywlsh:'',
                    ly:'来源',
                    sqrxm:'',
                    sqrxb:'',
                    sqrsfzh:'',
                    sqrmz:'',
                    sqrcsrq:'',
                    sqrdz:'',
                    sqrsjhm:'',
                    bsqrxm:'',
                    bsqrxb:'',
                    bsqrsfzh:'',
                    bsqrmz:'',
                    bsqrcsrq:'',
                    bsqrdz:'',
                    bsqrsjhm:'',
                    jfjj:'',
                    jflx:'',
                    yysjq:'2022-03-29 16:34:00',
                    yysjz:'2022-03-29 16:34:00',
                    twhid:'',
                    twhmc:'',
                },
                SupplementContent:{
                    twhmc: '',
                    fzr: '',
                    jflx: '',
                    lxdz :'',
                    photoids : [],
                    ocr : [],
                    sfcl : '',
                },
                content : {
                    thirdFlow:'',
                    baseCode:'fwaj',
                    data : []
                },
              //  多文件上传
              fileList : [],
              fileData : [],
            }
        },
        methods:{
          afterRead(file) {
            // 此时可以自行将文件上传至服务器file: (binary)Identification
            console.log(file);
            var _this = this;
            var param = new FormData(); //创建form对象
            param.append('file', file.file);//通过append向form对象添加数据
            // param.append('MenuId', _this.menuId);
            var config = {}
            axios.post('https://cq.lvwa18.com:8001/api/upload_file_H5', param, config)
                .then(response => {
                  console.log(response)
                  _this.SupplementContent.sfcl = 'https://cq.lvwa18.com:8001/'+response.data.Data.path
                  _this.Picture(response.data)
                })
          },
            deleteValuePicture: function(file, fileList) {
                console.log(file, fileList)
            },
            Picture: function(response, file, fileList) {
                console.log(response, file, fileList)
                var _this = this;
                _this.send({
                    url:'/Identification',
                    data:{
                        ImgPath:'https://cq.lvwa18.com:8001/'+response.Data.path
                    },
                    success:function(res){
                        _this.SupplementContent.photoids = response.Data
                        _this.SupplementContent.sfcl = 'https://cq.lvwa18.com:8001/'+response.Data.path;
                        var birthday = res.Birth.split('/')
                        if(birthday[1].length<2){
                            birthday[1] = '0'+birthday[1]
                        }
                        if(birthday[2].length<2){
                            birthday[2] = '0'+birthday[2]
                        }
                        if(res.Sex == '男'){_this.formValidate.sqrxb = 1}else{_this.formValidate.sqrxb = 2}
                        _this.sqrxb = res.Sex
                        _this.formValidate.sqrsfzh = res.IdNum
                        _this.formValidate.sqrxm = res.Name
                        _this.formValidate.sqrdz = res.Address
                        _this.formValidate.sqrcsrq = res.Birth
                        if(res.Nation == '汉'){
                          _this.formValidate.sqrmz = 1,
                          _this.sqrmz = res.Nation +'族'
                          _this.SupplementContent.sqrmz = res.Nation +'族'
                        }
                        _this.formValidate.sqrcsrq = `${birthday[0]}-${birthday[1]}-${birthday[2]}`
                        _this.SupplementContent.ocr = res
                        console.log(_this.SupplementContent)
                    }
                })
            },
            submit : function(){
                var _this = this
                _this.content.data = _this.formValidate
              console.log(_this.content)
              console.log(_this.SupplementContent)
              console.log(_this.fileData.join(","))
              // 轨道交通
              if(window.sessionStorage.sourceFrom == 'railTraffic'){
                axios({
                  method: 'post',
                  url: 'https://rail.lvwa18.com:8001/api/SocialMediate',
                  data: {
                    content:_this.content,
                    UserId:window.sessionStorage.userId,
                    user_id:window.sessionStorage.userId,
                    Admin_id:window.sessionStorage.Admin_id,
                    AreaId:window.sessionStorage.AreaId,
                    type:_this.type,
                    SupplementContent : _this.SupplementContent,
                    SupplementFile : _this.fileData.join(","),
                    sourceFrom: window.sessionStorage.sourceFrom,
                    VillagesId:window.sessionStorage.villagesid
                  },
                }).then(function (response) {
                  // Handle success
                  console.log(response);
                  window.history.go(-1);
                  Toast('提交成功');
                })
              }else {
                _this.send({
                  url:'/CqMediate',
                  loading: true,
                  data :{
                    content:_this.content,
                    UserId:window.sessionStorage.userId,
                    AreaId:window.sessionStorage.AreaId,
                    SupplementContent : _this.SupplementContent,
                    SupplementFile : _this.fileData.join(",")
                  },
                  success:function(res){
                    console.log(res)
                    window.history.go(-1)
                    Toast('提交成功');
                  }
                })
              }
            },
            nexStep : function(values){
                this.active++
                console.log('submit', values);
                console.log(this.formValidate)
            },
            dropDownChoice : function(type){
                var _this = this
                _this.showPicker = true
                _this.type = type
                if(type == 'xb'){_this.dropDownData = _this.totalDropDownData.xb}
                if(type == 'bxb'){_this.dropDownData = _this.totalDropDownData.xb}
                if(type == 'mz'){_this.dropDownData = _this.totalDropDownData.mz}
                if(type == 'bmz'){_this.dropDownData = _this.totalDropDownData.mz}
                if(type == 'jf'){_this.dropDownData = _this.totalDropDownData.jf}
            },
            initialization : function(){
                var _this = this
                _this.send({
                    url : '/EthnicityList',
                    success: function (res) {
                        _this.nation = res
                        for(var i in res){
                            _this.totalDropDownData.mz.push(res[i].EthnicityName)
                        }
                    }
                })
                _this.send({
                    url:'/DisputeType',
                    success:function(res){
                        _this.dispute = res
                        for(var i in res){
                            _this.totalDropDownData.jf.push(res[i].DisputeType)
                        }
                    }
                })
            },
            onBirthday : function(refName){
            console.log(refName)
                var date = new Date(refName);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
                var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
                if(this.active == 0){this.formValidate.sqrcsrq = Y + M + D}
                if(this.active == 1){this.formValidate.bsqrcsrq = Y + M + D}
                this.birthday = false
            },
            onAboutTime : function(refName){
                var date = new Date(refName);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
                var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
                var h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
                var m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';
                var s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
                if(this.riseStop == 1){this.formValidate.yysjq = Y + M + D + h +  m + s}
                if(this.riseStop == 2){this.formValidate.yysjz = Y + M + D + h +  m + s}

                this.aboutTime = false
            },
            onConfirm(value,index) {
                console.log(value)
                if(this.type == 'xb'){
                    // this.SupplementContent.sqrxb = value
                    if(index == '0'){this.formValidate.sqrxb = '1',this.sqrxb = '男'}
                    if(index == '1'){this.formValidate.sqrxb = '2',this.sqrxb = '女'}
                }
                if(this.type == 'bxb'){
                    // this.SupplementContent.bsqrxb = value
                    if(index == '0'){this.formValidate.bsqrxb = '1',this.bsqrxb = '男'}
                    if(index == '1'){this.formValidate.bsqrxb = '2',this.bsqrxb = '女'}
                }
                if(this.type == 'mz'){this.formValidate.sqrmz = this.nation[index].EthnicityId,this.sqrmz = value,this.SupplementContent.sqrmz = value}
                if(this.type == 'bmz'){this.formValidate.bsqrmz = this.nation[index].EthnicityId,this.bsqrmz = value,this.SupplementContent.bsqrmz = value}
                if(this.type == 'jf'){
                    this.formValidate.jflx = this.dispute[index].DisputeTypeId
                    this.SupplementContent.jflx = value
                    this.jflx = value
                }
                this.showPicker = false;
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
          //多文件上传
          multipleFiles : function (file,index){
            var _this = this
            // 此时可以自行将文件上传至服务器file: (binary)Identification
            console.log(file);
            var _this = this;
            var param = new FormData(); //创建form对象
            param.append('file', file.file);//通过append向form对象添加数据
            // param.append('MenuId', _this.menuId);
            var config = {}
            axios.post('https://cq.lvwa18.com:8001/api/upload_file_H5', param, config)
                .then(response => {
                  _this.fileData.push('https://cq.lvwa18.com:8001/'+response.data.Data.path)
                })
          },
          deleteImg : function (file,value){
            var _this = this
            _this.fileList.splice(value,1)
            _this.fileData.splice(value,1)
          },
        },
        mounted(){
          SYSCONFIG.server.default.host = 'cq.lvwa18.com'
          SYSCONFIG.server.default.port = '8001'
            this.initialization()
            this.setPageSize()
            this.SupplementContent.twhmc = window.sessionStorage.twhmc
            this.formValidate.twhmc = window.sessionStorage.twhmc
            this.SupplementContent.fzr = window.sessionStorage.fzr
            this.SupplementContent.jflx = window.sessionStorage.jflx
            this.SupplementContent.lxdz = window.sessionStorage.lxdz
            this.formValidate.twhid = window.sessionStorage.twhid
            this.formValidate.sqrsjhm = window.sessionStorage.userphone
            //默认选择
            // this.formValidate.sqrxb = '1'
            // this.formValidate.bsqrxb = '1'
            // this.sqrxb = '男'
            // this.bsqrxb = '男'
            // this.formValidate.sqrmz = '汉族'
            // this.formValidate.bsqrmz = '汉族'
            var date = new Date();//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
            // this.formValidate.sqrcsrq = Y + M + D
            this.formValidate.jflx = 1
            this.SupplementContent.jflx = '婚姻家庭纠纷'
            this.jflx = '婚姻家庭纠纷'
            // this.formValidate.bsqrcsrq = Y + M + D
          window.onscroll = null;
        },
    }
</script>

<style scoped>
    .informationFillin :deep(.van-cell){line-height: 0.5rem;}
        /*height: 0.6rem;padding: 0 0.1rem;margin: 0.01rem 0;*/
    .informationFillin :deep(.van-field__control div){height: 0.5rem;}
    .informationFillin :deep(.van-field__label){height: 0.5rem;}
    .informationFillin :deep(.ivu-steps .ivu-steps-main){width: 60%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;}
    .genderDateNation :deep(.van-cell){padding: 10px 0;}
    .genderDateNation :deep(.van-field__label){margin-right: 0;width: auto;}
</style>
