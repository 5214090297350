<style scoped>
    .home{background-color: rgb(241,239,240);position: absolute;width: calc(100% - 240px);height: calc(100% - 120px);overflow: auto;}
    .screen{width: calc(100% - 80px);display: inline-block;}
    .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
    /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
    .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #00b86c;padding: 0px 16px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #00b86c;}
    .screen :deep(.ivu-tabs-ink-bar){background-color: #00b86c;}
    .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
    .house{width: 72px;height: 72px;margin-top: 29px;}
    .number{font-size: 48px;color: #7a7a7a;}
    .tipsName{font-size: 16px;color: #8d8d8d;}
    .displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
    .administration{width: 100%;background-color: #fff;margin-top: 40px;border-bottom: 1px solid #dddddd;height: 80px;text-align: left;}
    .administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
    .label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
    .forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;margin:40px 40px 0 40px;height: 60px;text-align: center}
    .forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;}
    .forMcontent{width: calc(100% - 80px);height: 60px;color: #666666;font-size: 16px;margin:0px 40px 0 40px;border-bottom: 1px solid #dddddd;text-align: center;}
    .forMcontent p {line-height: 60px;display: inline-block;width: 20%}
    .smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}
    .alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -23px;}
    .alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
    .qrCode{width: 100px;height: 100px;}
    .addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
    .myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
    .icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
    .contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
    .single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
    .inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}
</style>

<template>
    <div class="home">
        <!--<HelloWorld>-->

        <!--</HelloWorld>-->
        <div style="background-color: rgb(241,239,240);">


            <!--管理律师列表-->
            <div v-if="information.adminLevel == 3">
                <div class="font-size" style="width: calc(100% - 80px);display: inline-block;">
                    <div class="administration">
                        <img class="label" src="/img/label.png">
                        <p>管理</p>
                        <div class="addTo" @click="modal2 = true">添加</div>
                    </div>
                    <div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;">
                        <div class="forMtitle" v-if="listData != []">
                            <p>机构名称</p>
                            <p>电话</p>
                            <p>登录名</p>
                            <p>密码</p>
                            <p>操作</p>
                        </div>
                        <!--for-->
                        <div class="forMcontent" v-for="(value,index) in listData" :key="index">
                            <p>{{value.OrganizationName}}</p>
                            <p>{{value.OrganizationPhone}}</p>
                            <p>{{value.AdminLogin}}</p>
                            <div style="position: relative;line-height: 60px;display: inline-block;width: 20%">
                                <p>{{value.AdminPassword}}</p>
                                <!--弹出-->
                                <div class="alertCode" v-if="arCode&&index==current">
                                    <!---->
                                    <p>{{value.LawyerName}}</p>
                                    <div style="width: 100%;position: absolute;top: 36px;">
                                        <img class="qrCode" :src="value.LawyerQRcode">
                                    </div>
                                </div>
                            </div>
                            <p style="color: #00b86c;cursor:pointer" >
                                <span @click="deleteLawFirm(value.AdminId,value.OrganizationId,value.Pid)" style="height: 100%;display: inline-block;width: 50%;">删除</span>
                                <span style="height: 100%;display: inline-block;width: 50%;" @click="modifyData(
                                value.AdminId,
                           value.AdminLogin,value.AccountPassword,value.AdminName,value.OrganizationName,value.OrganizationLinkman, value.OrganizationPhone
                                )">修改</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div style="text-align: left;margin-left: 40px;font-size: 16px">
                    <!--<Page :total="count" page-size="5"  show-elevator />-->
                    <!--<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>-->
                    <Page
                            :total="count"
                            :page-size="pageSize"
                            show-elevator
                            @on-change="page = $event;lawList()"
                    />
                </div>
            </div>
            <!---->
        </div>
        <!--弹出-->
        <Modal
                v-model="modal1"
                title="添加"
                @on-ok="ok"
                @on-cancel="cancel">
            <div class="single">
                <p class="contacts">律师名称</p>
                <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName">
            </div>
            <div class="single">
                <p class="contacts">律师电话</p>
                <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone">
            </div>
        </Modal>
        <!---->
        <Modal
                v-model="modal2"
                title="添加"
                @on-ok="addMember"
                @on-cancel="cancel">

            <div class="single">
                <p class="contacts">律所名称</p>
                <input type="text" class="inputWord" placeholder="请输入村居律所名称" v-model="organizationName">
            </div>
            <div class="single">
                <p class="contacts">联系人</p>
                <input type="text" class="inputWord" placeholder="请输入联系人" v-model="organizationLinkman">
            </div>
            <div class="single">
                <p class="contacts">联系电话</p>
                <input type="text" class="inputWord" placeholder="请输入联系电话" v-model="organizationPhone">
            </div>
            <div class="single" v-if="cityList.length != 0">
                <p class="contacts">地址</p>
                <div style="display: inline-block">
                    <Select v-model="model8" style="width:200px" @on-change="choice" :label-in-value="labelInValue" :placeholder="tipsWord">
                        <Option  v-for="(item,index) in cityList" :value="item.AreaId +','+ index  +','+ '1'" :label="item.AreaName" :key="index">{{ item.AreaName }}</Option>
                    </Select>
                    <Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue" v-if="areaData" :placeholder="tipsWord">
                        <Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName" :key="index">{{ i.AreaName }}</Option>
                    </Select>
                </div>
            </div>
            <div class="single">
                <p class="contacts">登录名称</p>
                <input type="text" class="inputWord" placeholder="请输入登录名" v-model="adminLogin">
            </div>
            <div class="single">
                <p class="contacts">登录密码</p>
                <input type="text" class="inputWord" placeholder="请输入登录密码" v-model="adminPassword">
            </div>
        </Modal>
    </div>
</template>

<script>
    // import HelloWorld from '../../components/HelloWorld.vue'
    export default {
        name: "home",
        // components: {HelloWorld,},
        data () {
            return {
                value2: ['0', '1', '2'],
                addressData : [],
                model8: '',
                model9: '',
                cityList: [],
                ElectronicsList: [],
                tipsWord: '请选择地址',
                box : 0,
                modal1: false,
                modal2: false,
                listData : [],
                arCode : false,//二维码
                current : '',
                lawyerPhone : '',
                lawyerName : '',
                information :[],
                page : 1,
                pageSize : 5,
                count : 0,
                adminLogin : '',
                adminPassword : '',
                organizationPhone : '',
                organizationLinkman : '',
                organizationName : '',
                AdminId : '',
                AreaId : '',
                statisticsData : [
                    {name : '服务社区（村）数量',value:''},
                    {name : '服务用户数量',value:''},
                    {name : '律师数量',value:''},
                    {name : '咨询消息数量',value:''}
                ],
                timelist : [
                    {name:'本月',value:0},
                    {name:'本季',value:1},
                    {name:'本年',value:2},
                    {name:'总计',value:3},
                ]
            }
        },
        methods:{
            deleteLawyer : function(lawyerId){
                var _this = this
                _this.send({
                    url : '/DeleteLawyer',
                    confirm:true,
                    data: {
                        OrganizationId : _this.information.OrganizationId,
                        LawyerId : lawyerId,
                    },
                    success: function (res) {
                        console.log(res)
                        _this.listData = []
                        _this.page = '1'
                        _this.lawList()
                    }
                })
            },
            lawList :function(){
                var _this = this
                _this.send({
                    url : '/LawyerListById',
                    data: {
                        OrganizationId : _this.information.OrganizationId,
                        page: _this.page,
                        pageSize : _this.pageSize,
                    },
                    success: function (res) {
                        _this.listData = res.List
                        _this.count = res.count

                    }
                })
            },
            addMember :function(){
                var _this = this
                if(_this.AdminId == ''){
                    if(_this.organizationName != ''&&_this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != ''&& _this.adminLogin != ''){
                        if(_this.cityList.length == 0){
                            // var AreaId = _this.information.AreaId
                            _this.AreaId = _this.information.AreaId
                        }
                        _this.send({
                            url : '/AddMember',
                            data: {
                                Level : _this.information.adminLevel,
                                AdminId : _this.information.AdminId,
                                AdminLogin : _this.adminLogin,
                                AdminPassword : _this.adminPassword,
                                OrganizationLinkman : _this.organizationLinkman,
                                OrganizationPhone : _this.organizationPhone,
                                OrganizationName : _this.organizationName,
                                AreaId : _this.AreaId,
                            },
                            success: function (res) {
                                _this.adminList()
                                _this.adminLogin=''
                                _this.adminPassword=''
                                _this.organizationLinkman=''
                                _this.organizationPhone=''
                                _this.organizationName =''
                                _this.AreaId = ''
                                _this.model8 = ''
                            }
                        })
                    }else{
                        _this.$Notice.warning({
                            title:'系统提示',
                            desc:'输入完成后添加',
                        })
                    }
                }else {
                    if(_this.organizationName != ''&&_this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != ''&& _this.adminLogin != ''){
                        if(_this.cityList.length == 0){
                            // var AreaId = _this.information.AreaId
                            _this.AreaId = _this.information.AreaId
                        }
                        _this.send({
                            url : '/AdminEdit',
                            data: {
                                AdminId :  _this.AdminId,
                                AdminLogin : _this.adminLogin,
                                AdminPassword :_this.adminPassword,
                                AdminName : _this.AdminName,
                                OrganizationName : _this.organizationName,
                                OrganizationLinkman :_this.organizationLinkman,
                                OrganizationPhone :_this.organizationPhone,
                            },
                            success: function (res) {
                                _this.adminList()
                                _this.adminLogin=''
                                _this.adminPassword=''
                                _this.organizationLinkman=''
                                _this.organizationPhone=''
                                _this.organizationName =''
                                _this.AreaId = ''
                                _this.model8 = ''
                            }
                        })
                    }else{
                        _this.$Notice.warning({
                            title:'系统提示',
                            desc:'输入完成后添加',
                        })
                    }
                }
            },
            ok:function () {
                var _this = this
                var op = _this.information.OrganizationId+'p'+_this.lawyerPhone +'p'+1
                _this.send({
                    url : '/getVillageSunCode',
                  consulting1:true,
                  responseType:"arraybuffer",
                    data: {
                        OrganizationId : _this.information.OrganizationId,
                        scene: op,
                        userType : 'lawyer',
                        page: 'pages/login/login',
                        width: '430',
                        jpg: 'jpg',
                        LawyerName : _this.lawyerName,
                        LawyerPhone : _this.lawyerPhone
                    },
                    success: function (res) {
                        _this.listData = []
                        _this.page = '1'
                        _this.lawList()
                    }
                })
            },
            cancel () {
                var _this = this
                _this.AdminId = ''
                _this.adminLogin=''
                _this.adminPassword=''
                _this.organizationLinkman=''
                _this.organizationPhone=''
                _this.organizationName =''
                _this.AreaId = ''
                _this.model8 = ''
            },
            enter:function(index){
                console.log(index)
                this.arCode = true
                this.current = index
            },
            leave(){
                this.arCode = false
                this.current = null
            },
            //    管理列表
            adminList : function () {
                var _this = this
                _this.send({
                    url : '/AdminList',
                    data: {
                        AdminId : _this.information.AdminId,
                    },
                    success: function (res) {
                        console.log(res)
                        _this.listData = res
                        _this.count = res.count
                    }
                })
            },
            deleteLawFirm : function (adminid,organizationId,pid) {
                console.log(pid)
                var _this = this
                _this.send({
                    url : '/deleteMember',
                    confirm:true,
                    data: {
                        AdminId : adminid,
                        Level : _this.information.Level,
                        OrganizationId : organizationId,
                        Pid : pid
                    },
                    success: function (res) {
                        console.log(res)
                        _this.listData = []
                        _this.page = '1'
                        _this.adminList()
                    }
                })
            },
            //    地区
            getAreaData : function () {
                var _this = this
                _this.send({
                    url : '/GetAreaData',
                    data: {
                        AreaId: _this.information.AreaId,
                    },
                    success: function (res) {
                        //
                        _this.cityList = res[1]
                        console.log(_this.cityList)

                        //
                        convertTree(res[1])
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let {
                                    AreaId: value,
                                    AreaName: label,
                                    children: children
                                } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    value,
                                    label,
                                    children
                                })
                            })
                            _this.addressData = result
                            return result
                        }
                        console.log(_this.addressData)
                    }
                })
            },
            choice : function(value){
                var _this = this
                var data = value.value.split(',')
                console.log(data)
                _this.AreaId = data[0]
                if(data[2] == '1'){
                    _this.areaData = _this.cityList[data[1]].children
                }else {

                }
                _this.send({
                    url : '/GetfileList',
                    data: {
                        AreaId: data[0],
                        AdminId: _this.information.AdminId,
                    },
                    success: function (res) {
                        console.log(res)
                        var ElectronicsList = []
                        _this.ElectronicsList = res
                    }
                })
            },
            // 修改数据
            modifyData : function (AdminId,AdminLogin, AdminPassword,AdminName,OrganizationName, OrganizationLinkman, OrganizationPhone) {
                var _this = this
                _this.modal2 = !_this.modal2
                _this.adminLogin = AdminLogin
                _this.adminPassword = AdminPassword
                _this.organizationPhone = OrganizationPhone
                _this.organizationLinkman = OrganizationLinkman
                _this.organizationName = OrganizationName
                _this.AdminName = AdminName
                _this.AdminId = AdminId
                // _this.send({
                //     url : '/GetfileList',
                //     data: {
                //         AdminId: AdminId,
                //     },
                //     success: function (res) {
                //         console.log(res)
                //         var ElectronicsList = []
                //         _this.ElectronicsList = res
                //     }
                // })
                console.log(AdminId,AdminLogin, AdminPassword,AdminName,OrganizationName, OrganizationLinkman, OrganizationPhone)
            }
        },
        mounted () {
            var _this = this
            if(window.sessionStorage.adminLevel == undefined){
                // this.$router.push({name:'login'})
            }else {
                // var data = JSON.parse(window.sessionStorage.information)
                // SYSCONFIG.server.information = JSON.parse(window.sessionStorage.information)
                // console.log(SYSCONFIG.server.information.AdminLogin)
                this.information = window.sessionStorage
                if(_this.information.adminLevel == 4){
                    _this.lawList()
                    console.log(this.information)
                }
                if(_this.information.adminLevel == 3){
                    _this.adminList()
                    console.log(this.information)
                }
                if(_this.information.adminLevel == 1 || _this.information.adminLevel == 2){
                    _this.adminList()
                }
                _this.getAreaData()
            }
        },
    }
</script>

