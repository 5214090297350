<template>
  <div style="padding:20px;">
    <div style="background-color: white;">
      <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px">
        总统计数
      </div>
      <div
          class="displayData"
          v-for="(value, index) in statisticsData"
          :key="index"
          @click="orderTypeCount(value.type,value.name)" :style="{cursor:value.active?'pointer':'not-allowed'}"
      >
        <!--<img class="house" :src="/img/house.png">-->
        <img class="house" :src="'/img/house' + '' + value.img + '.png'" />
        <p class="number">{{ value.value }}<span style="font-size: 16px;margin-left: 3px">{{value.unit}}</span></p>
        <p class="tipsName">{{ value.name }}</p>
      </div>
      <div style="background-color: #f1eff0;padding: 10px"></div>

      <!--      <div style="margin: 15px 0">-->
      <!--        <DatePicker :value="timeData" v-model="timeData" type="daterange" split-panels placeholder="请选择时间"-->
      <!--                    style="width: 200px" @on-change="totalTime3"></DatePicker>-->

      <!--        <Select style="width:200px" v-model="model140" filterable remote clearable :remote-method="remoteMethod2"-->
      <!--                @on-change="lawyerClick3" :loading="loading2" placeholder="请输入社区">-->
      <!--          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">-->
      <!--            {{value.VillagesName}}</Option>-->
      <!--        </Select>-->
      <!--        <div style="width: 300px;display: inline-block;vertical-align: top;">-->
      <!--          <Input v-model="userKey" search enter-button placeholder="搜索姓名" @on-search="userSearch" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div style="background-color: white; margin: 20px 0">
        <div style=""></div>
        <div
            style="
                        font-size: 16px;
                        text-align: left;
                        border-left: solid #de4f0b 3px;
                        padding-left: 5px;
                    "
        >
          十日新增数据
        </div>
        <div id="myChart1" style="height: 400px"></div>
        <div style="padding: 15px"></div>
      </div>
      <div style="background-color: #f1eff0;padding: 10px"></div>

<!--      <div style="background-color: white;" >-->
<!--        <div style="padding: 15px"></div>-->
<!--        <div-->
<!--            style="-->
<!--                        font-size: 16px;-->
<!--                        text-align: left;-->
<!--                        border-left: solid #de4f0b 3px;-->
<!--                        padding-left: 5px;-->
<!--                    "-->
<!--        >-->
<!--          今日数据-->
<!--        </div>-->
<!--        <Row style="font-size: 14px; height: 180px;text-align: center;" type="flex" justify="center" align="middle">-->
<!--          <Col span="6">-->
<!--            <div>-->
<!--              <Col style="font-size: 24px">服务村（社区）</Col>-->
<!--              <Col style="color: red; font-size: 24px; margin-top: 10px">{{ yesterdayData.village }}<span style="font-size: 16px;margin-left: 3px">个</span></Col>-->
<!--            </div>-->
<!--          </Col>-->
<!--          <Col span="6">-->
<!--            <div>-->
<!--              <Col style="font-size: 24px">服务明白人</Col>-->
<!--              <Col style="color: red; font-size: 24px; margin-top: 10px">{{ yesterdayData.Insider }}<span style="font-size: 16px;margin-left: 3px">人</span></Col>-->
<!--            </div>-->
<!--          </Col>-->
<!--          <Col span="6">-->
<!--            <div>-->
<!--              <Col style="font-size: 24px">服务用户</Col>-->
<!--              <Col style="color: red; font-size: 24px; margin-top: 10px">{{ yesterdayData.user }}<span style="font-size: 16px;margin-left: 3px">人</span></Col>-->
<!--            </div>-->
<!--          </Col>-->
<!--          <Col span="6">-->
<!--            <div>-->
<!--              <Col style="font-size: 24px">咨询次数</Col>-->
<!--              <Col style="color: red; font-size: 24px; margin-top: 10px">{{ yesterdayData.order }}<span style="font-size: 16px;margin-left: 3px">次</span></Col>-->
<!--            </div>-->
<!--          </Col>-->
<!--        </Row>-->
<!--      </div>-->
    </div>

    <Modal :draggable="true" v-model="modal6" :title="lawName+'的太阳码'" @on-cancel="" ok-text="确定"
           cancel-text="关闭" width="350">
      <div style="text-align: center">
<!--        <p style="font-size: 16px">是否发送明白人小程序码至手机</p>-->
        <!--<img v-if="modal6" :src="urlImg" style="width: 300px;height: 300px">-->
        <div style="width: 300px;height: 300px">
          <img v-if="modal6 && lawImg" :src="lawImg" style="width: 300px;height: 300px">
        </div>
      </div>
    </Modal>
<!--    添加明白人-->
    <Modal
        v-model="modal"
        title="添加"
        @on-ok="ok"
        @on-cancel="cancel">
      <Input v-model="InsiderName" placeholder="明白人姓名" style="margin-bottom: 30px" />
      <Input v-model="InsiderPhone" placeholder="明白人电话" style="margin-bottom: 30px" />
<!--      <Select  style="margin-bottom: 30px" v-model="OrganizationId" filterable clearable @on-query-change="lawFirmsearch" @on-open-change="lawFirmdropDown" class="choiceLawyer" placeholder="服务机构">-->
<!--        <Option v-for="item in FirmPageData" :value="item.OrganizationId" :key="item.OrganizationId">{{item.AdminName}}</Option>-->
<!--        <Page-->
<!--            :total="FirmPageCount" show-total-->
<!--            :page-size="pageSize"-->
<!--            :current="lawFirmPage"-->
<!--            @on-change="lawFirmTurnPages"-->
<!--        ></Page>-->
<!--      </Select>-->
      <Cascader v-if="adminLevel != 3" style="margin-bottom: 30px" :data="serachData" v-model="serachValue" placeholder="区域" @on-change="serachSelect"  ></Cascader>
      <Select v-if="VillageByData != ''" style="margin-bottom: 30px" v-model="VillagesId" filterable clearable multiple @on-query-change="villageByQuery" @on-open-change="villageByOpen" class="choiceLawyer" placeholder="请选择村（社区），可多选">
        <Option v-for="item in VillageByData" :value="item.VillagesId" :key="item.VillagesId">{{item.VillagesName}}</Option>
        <Page
            :total="villageByCount" show-total
            :page-size="pageSize"
            :current="villageByPage"
            @on-change="serachSelectPage"
        ></Page>
      </Select>
    </Modal>
<!--    明白人数 -->
    <Modal
        v-model="Mbrmodal"
        width="1000">
      <div >

        <p slot="header" style="color: #17233d; text-align: left;margin: 0 0 10px 0">
          <span>法律明白人数</span>
          <span style="margin-left: 30px">共{{count}}条</span>
          <span style="margin-left: 30px">已绑定 {{ Bound }} 人</span>
          <span style="margin-left: 30px">未已绑 {{ UnBound }} 人</span>
        </p>
        <div>
          <div class="publicWelfare">
            <Row>
              <Col span="12">
                <Input v-model="searchLaw" search enter-button placeholder="搜索明白人" @on-search="aQuestionnaireList" />
              </Col>
              <!--<Col span="12" style="text-align: center">-->
              <!--<Button type="primary" @click="sendOutinformation()">一键发送</Button>-->
              <!--</Col>-->
              <Col span="12">
                <Button type="primary"  @click="cqCommunity();modal = true" style="margin-left: 30px">添加</Button>
              </Col>
            </Row>

            <table class="formtable">
              <thead>
              <tr>
                <th>序号</th>
                <th>明白人姓名</th>
                <th>移动电话</th>
                <!--            <th>服务数据</th>-->
                <th>小程序码
                  <!--<Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>-->
                </th>
                <th>是否绑定</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(value,index) in adminLawyerList">
                <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
                <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
                <td>
                  <Avatar :src="value.InsiderImg||'https://ai-cdn.lvwa18.com/img/rusticate/text3.jpg'" /> {{value.InsiderName}}</td>
                <td>{{value.InsiderPhone}}</td>
                <!--            <td><Button style="background-color:  rgb(243, 133, 4);color: #fff"-->
                <!--                        @click="serviceClick(value.InsiderId);modal19 = true">详情</Button></td>-->
                <td style="width: 190px;">
                  <img class="smallProgram"
                       @click="alertbox(value.LawyerQRcode,value.InsiderName,value.InsiderPhone,value.InsiderId,value.OrganizationId)"
                       src="/img/smallProgram1.png">
                  <!--              <div style="display: inline-block;margin: 0 8%"></div>-->
                  <!--              <Button type="success"-->
                  <!--                      @click="alertbox1(value.LawyerQRcode,value.InsiderName,value.InsiderId,value.InsiderPhone,value.OrganizationId)">发送</Button>-->
                </td>
                <td v-if="value.isBinding == 1" style="color: red">已绑定</td>
                <td v-else>未绑定</td>
              </tr>
              </tbody>
            </table>
            <Page :total="count" :page-size="pageSize" show-elevator show-total
                  @on-change="page = $event;aQuestionnaireList()" />
          </div>
        </div>
      </div>
    </Modal>
<!--    线上解答 -->
    <Modal
        v-model="serviceShow"
        title="线上解答">
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>服务类型</th>
            <th>线上解答数</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>订单发起人次</td>
            <td>{{orderData.UserCount}}</td>
          </tr>
          <tr>
            <td>发起订单数</td>
            <td>{{orderData.OrderCount}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
    <Modal
        :draggable="true"
        v-model="numberofStatesshow"
        :z-index="1"
        width="900"
    >
      <p slot="header" style="color: #17233d; text-align: left">
        <span>区域分布</span>
        <span style="margin-left: 30px">共{{ difficultCount.length }}条</span>
      </p>
      <div class="publicWelfare">
        <div>
          <Button type="primary" v-if="sort == 2" @click="sortClick('')">明白人数(升序)</Button>
          <Button type="primary" v-else="" @click="sortClick(2)">明白人数(降序)</Button>
        </div>
        <table class="formtable">
          <thead>
          <tr>
            <!--<th v-if="information.adminLevel == 0">省数</th>-->
            <th>名称</th>
            <th v-if="information.adminLevel == 0">市州数</th>
            <th v-if="information.adminLevel == 1 || information.adminLevel == 0">
              <span v-if="information.AdminId == '275'">乡镇数</span>
              <span v-else>区县数</span>
            </th>
            <th v-if="information.Hierarchy < 3">街道（司法所）数</th>
            <th>服务村（社区）</th>
            <th>明白人数</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in difficultCount">
            <td>{{ value.AdminName }}</td>
            <td v-if="information.adminLevel == 0">{{ value.shiju_count }}</td>
            <td v-if="information.adminLevel == 1 || information.adminLevel == 0">
              {{ value.quxian_count }}
            </td>
            <td v-if="information.Hierarchy < 3">
              {{ value.AdminCount }}
            </td>
            <td  @click="orderTypeCount(1)">
              {{ value.VillagesCount }}
            </td>
            <td>{{ value.insderCount }}</td>
            <td>
              <router-link style="margin: 0 10px 0 10px" :to="{path:'/manager/loginMbr', query:{adminLogin:value.AccountLogin,adminPassword:require('js-base64').Base64.encode(value.AccountPassword)}}" target="_blank">
                <Button type="primary">
                  <img src="/img/icon/yijian.png" style="vertical-align: top;margin-top: 1px;"/>
                  一键登录
                </Button>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
        <!--<Page-->
        <!--:total="count"-->
        <!--show-total-->
        <!--:page-size="pageSize"-->
        <!--show-elevator-->
        <!--@on-change="page = $event;jurisdiction()"-->
        <!--/>-->
      </div>
    </Modal>

    <!--订单数-->
    <Modal :draggable="true" v-model="insderOrderData.modal" title="订单数" @on-ok="" @on-cancel="cancel" width="900">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>{{insderOrderData.title}}</span>
        <span style="margin-left: 30px">共{{ insderOrderData.count }}条</span>
      </p>
      <div v-if="insderOrderData.type == 3">
        <DatePicker
            :value="timeData"
            v-model="timeData"
            type="daterange"
            split-panels
            placeholder="请选择时间"
            style="width: 200px"
            @on-change="totalTime0"
        ></DatePicker>
        <Select v-model="model1" style="width: 200px" :remote-method="aQuestionnaireList" @on-change="InsiderClick" filterable clearable>
          <Option v-for="(value, index) in adminLawyerList" :value="value.InsiderId" :key="value.InsiderId">
            {{ value.InsiderName }}</Option
          >
        </Select>
        <Select
            v-model="model10"
            clearable
            style="width: 200px"
            @on-change="stateChoice"
            placeholder="请选择处理状态"
        >
          <Option v-for="item in IsReplyList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>用户</th>
            <th>法律明白人</th>
            <th>村（社区）</th>
            <!--<th>用户号码</th>-->
            <th>咨询时间</th>
            <th v-if="insderOrderData.type == 3">服务状态</th>
            <th v-if="insderOrderData.type == 3">处理状态</th>
            <th v-if="insderOrderData.type == 3">处理时间</th>
<!--            <th>咨询摘要</th>-->
            <th v-if="insderOrderData.type == 3">是否归档</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in insderOrderData.list">
            <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
            <td>{{ value.InsiderName }}</td>
            <td>
              {{ value.CityName.replace('重庆市', '') }}{{ value.AdminName }}{{ value.VillagesName }}
            </td>
            <td>{{ value.CreateTime }}</td>
            <td v-if="insderOrderData.type == 3">{{ value.state }}</td>
            <td v-if="insderOrderData.type == 3">{{ value.IsReply }}</td>
            <td v-if="insderOrderData.type == 3">{{ value.isReplyTime || '--' }}</td>
<!--            <td >{{ value.FileExplain || '&#45;&#45;' }}</td>-->
            <td v-if="insderOrderData.type == 3">
              <span v-if="value.archiveId">已归档</span>
              <span v-else>未归档</span>
            </td>
          </tr>
          </tbody>
        </table>
        <Page
            :total="insderOrderData.count"
            :page-size="insderOrderData.pageSize"
            show-elevator
            show-total
            :current="insderOrderData.page"
            @on-change="insderOrderData.page = $event;orderTypeCount(insderOrderData.type,insderOrderData.title)"
        />
      </div>
    </Modal>
<!--    拨打电话数-->
    <Modal :draggable="true" v-model="phoneData.modal" title="订单数" @on-ok="" @on-cancel="cancel" width="900">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>{{phoneData.title}}</span>
        <span style="margin-left: 30px">共{{ phoneData.count }}条</span>
      </p>
      <div v-if="phoneData.type == 3">
        <DatePicker
            :value="timeData"
            v-model="timeData"
            type="daterange"
            split-panels
            placeholder="请选择时间"
            style="width: 200px"
            @on-change="totalTime1"
        ></DatePicker>
        <Select v-model="model1" style="width: 200px" :remote-method="aQuestionnaireList" @on-change="InsiderClick" filterable clearable placeholder="请输入明白人">
          <Option v-for="(value, index) in adminLawyerList" :value="value.InsiderId" :key="value.InsiderId">
            {{ value.InsiderName }}</Option
          >
        </Select>
      </div>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>用户</th>
            <th>法律明白人</th>
            <th>村（社区）</th>
            <!--<th>用户号码</th>-->
            <th>拨打时间</th>
<!--            <th v-if="phoneData.type == 3">服务状态</th>-->
<!--            <th v-if="phoneData.type == 3">处理状态</th>-->
<!--            <th v-if="phoneData.type == 3">处理时间</th>-->
<!--&lt;!&ndash;            <th>咨询摘要</th>&ndash;&gt;-->
<!--            <th v-if="phoneData.type == 3">是否归档</th>-->
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in phoneData.list">
            <td><Avatar :src="value.user.UserImg" /> {{ value.user.UserName }}</td>
            <td>{{ value.insider.InsiderName }}</td>
            <td>
              {{ value.village.CityName.replace('重庆市', '') }}{{ value.village.admin[0].AdminName }}{{ value.village.VillagesName }}
            </td>
            <td>{{ value.Time }}</td>
<!--            <td v-if="phoneData.type == 3">{{ value.state }}</td>-->
<!--            <td v-if="phoneData.type == 3">{{ value.IsReply }}</td>-->
<!--            <td v-if="phoneData.type == 3">{{ value.isReplyTime || '&#45;&#45;' }}</td>-->
<!--&lt;!&ndash;            <td >{{ value.FileExplain || '&#45;&#45;' }}</td>&ndash;&gt;-->
<!--            <td v-if="phoneData.type == 3">-->
<!--              <span v-if="value.archiveId">已归档</span>-->
<!--              <span v-else>未归档</span>-->
<!--            </td>-->
          </tr>
          </tbody>
        </table>
        <Page
            :total="phoneData.count"
            :page-size="phoneData.pageSize"
            show-elevator
            show-total
            :current="phoneData.page"
            @on-change="phoneData.page = $event;callList()"
        />
      </div>
    </Modal>
    <!--用户人数-->
    <Modal :draggable="true" v-model="modal11" title="用户管理"  width="800">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>用户管理</span>
        <span style="margin-left: 30px">共{{ count }}条</span>
      </p>
      <div class="publicWelfare">
        <DatePicker
            :value="timeData"
            v-model="timeData"
            type="daterange"
            split-panels
            placeholder="请选择时间"
            style="width: 200px"
            @on-change="totalTime3"
        ></DatePicker>

        <Select
            style="width: 200px"
            v-model="model140"
            filterable
            remote
            clearable
            :remote-method="remoteMethod2"
            @on-change="lawyerClick4"
            :loading="loading2"
            placeholder="请输入社区"
        >
          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesId">
            {{ value.VillagesName }}</Option
          >
        </Select>
        <div style="width: 200px; display: inline-block; vertical-align: top">
          <Input v-model="userKey" search enter-button placeholder="搜索姓名" @on-search="userSearch" />
        </div>
        <table class="formtable">
          <thead>
          <tr>
            <th>姓名</th>
            <th>手机号码</th>
            <th>所在村（社区）</th>
            <th>关注时间</th>
            <th>咨询次数</th>
            <th>申办次数</th>
            <th>查询次数</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in adminUserData">
            <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
            <td>{{ value.UserPhone }}</td>
            <td>
              {{ value.village.CityName.replace('重庆市', '') }}{{ value.village._AdminName }}{{ value.village.VillagesName }}
            </td>
            <td>{{ value.CreatTime }}</td>
            <td>{{ value.OrderCount }}</td>
            <td>{{ value.ApplyCount }}</td>
            <td>{{ value.QueryCount }}</td>
          </tr>
          </tbody>
        </table>
        <Page
            :total="count"
            :page-size="pageSize"
            show-elevator
            show-total
            @on-change="
                        page = $event
                        orderTypeCount(2)
                    "
        />
      </div>
    </Modal>
    <!--用户人数-->
    <Modal :draggable="true" v-model="modal12" title="求助律师"  width="800">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>求助律师</span>
        <span style="margin-left: 30px">共{{ count }}条</span>
      </p>
      <div class="publicWelfare">
        <DatePicker
            :value="timeData"
            v-model="timeData"
            type="daterange"
            split-panels
            placeholder="请选择时间"
            style="width: 200px"
            @on-change="totalTime4"
        ></DatePicker>

        <Select
            style="width: 200px"
            v-model="model140"
            filterable
            remote
            clearable
            :remote-method="remoteMethod2"
            @on-change="lawyerClick4"
            :loading="loading2"
            placeholder="请输入社区"
        >
          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesId">
            {{ value.VillagesName }}</Option
          >
        </Select>
<!--        <div style="width: 200px; display: inline-block; vertical-align: top">-->
<!--          <Input v-model="userKey" search enter-button placeholder="搜索姓名" @on-search="userSearch1" />-->
<!--        </div>-->
        <table class="formtable">
          <thead>
          <tr>
            <th>法律明白人</th>
            <th>手机号码</th>
            <th>村（社区）</th>
            <th>求助律师</th>
            <th>求助时间</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in seekingHelpData">
            <td><Avatar :src="value.user.UserImg" /> {{ value.user.UserName }}</td>
            <td>{{ value.user.UserPhone }}</td>
            <td> {{ value.villages.CityName.replace('重庆市', '') }}{{ value.villages.admin[0].AdminName }}{{ value.villages.VillagesName }}</td>
            <td>{{ value.lawyer.LawyerName }}</td>
            <td>{{ timestampToDate(value.OrderCreatedTime,'datetime') }}</td>
          </tr>
          </tbody>
        </table>
        <Page
            :total="count"
            :page-size="pageSize"
            show-elevator
            show-total
            @on-change="
                        page = $event
                        orderTypeCount(8)
                    "
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import echarts from "echarts";

export default {
  name: "userAdministration",
  data() {
    return {
      adminLawyerList:[],
      adminUserData:[],
      seekingHelpData:[],
      information:[],
      VillagesId : '',
      page : 1,
      pageSize : 15,
      startTime : '',
      endTime : '',
      timeData: '',
      model140: false,
      model1: '',
      model10: '',
      loading2: false,
      modal6: false,
      modal11: false,
      modal12: false,
      options2: [],
      userKey : '',
      urlImg: '',
      lawImg: '',
      lawName: '',
      pageUrl : 'chong-qing/login/login',
      searchLaw : '',
      modal : false,
      InsiderName : '',
      InsiderPhone : '',
      adminLevel : '',
      FirmPageData : [],
      FirmPageCount : 0,
      lawFirmPage : 1,
      serachData : [],
      VillageByData : [],
      serachValue : [],
      villageByCount : 0,
      villageByPage : 1,
      count : 0,
      UnBound : 0,
      Bound : 0,
      orderData : [],
      statisticsData: [
        { name: '区域分布', value: '', type: 1, img: '10' ,active:true,unit:'个',},
        { name: '覆盖村（社区）数', value: '', type: 4, img: '0' ,active:true,unit:'个',},
        { name: '法律明白人数', value: '', type: 2, img: '1' ,active:true,unit:'人',},
        { name: '累计用户数', value: '', type: 7, img: '2' ,active:true,unit:'人',},
        { name: '线上解答', value: '', type: 3, img: '3' ,active:true,unit:'次',},
        { name: '服务档案数', value: '', type: 5, img: '5' ,active:true,unit:'份',},
        { name: '求助律师', value: '', type: 8, img: '11' ,active:true,unit:'次',},
        // { name: '服务用户数', value: '', type: 6, img: '4' ,active:true,unit:'人',},
      ],
      Mbrmodal : false,
      serviceShow : false,
      numberofStatesshow : false,
      difficultCount : [],
      insderOrderData : {
        list : [],
        page : 1,
        pageSize : 15,
        count : 0,
        key : '',
        type : [],
        title : '',
        modal : false,
      },
      phoneData : {
        list : [],
        page : 1,
        pageSize : 15,
        count : 0,
        key : '',
        type : [],
        title : '',
        modal : false,
      },
      yesterdayData: {
        village: '',
        Insider: '',
        user: '',
        order: ''
      },
      nameKeyId : '',
      IsReply : '',
      IsReplyList: [
        {
          value: '1',
          label: '已处理'
        },
        {
          value: '2',
          label: '未处理'
        }
      ],
      insiderVillagesData : {
        data : [],
        search: '',
        page: ''
      },
      sort: 2,
    }
  },
  methods:{
    newlyDataView: function () {
      var _this = this
      // 新增
      let myChart1 = echarts.init(document.getElementById('myChart1'), 'light')
      _this.send({
        url: '/tenData',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          var time = [] //时间
          // var order_count = [] //咨询数量
          var phone_count = [] //拨打电话咨询
          var login_count = [] //登录数量
          var user_count = [] //用户数量
          var file_count = [] //档案次数
          var resort_count = [] //求助律师订单数
          for(var i=res.list.day.length-1;i>=0;i--){
            time.push(res.list.day[i].time)
            // order_count.push(res.list.day[i].order_count)
            login_count.push(res.list.day[i].login_count)
            phone_count.push(res.list.day[i].phone_count)
            user_count.push(res.list.day[i].user_count)
            file_count.push(res.list.day[i].file_count)
            resort_count.push(res.list.day[i].resort_count)
          }
          myChart1.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              }
            },
            // title: { text: "十日新增数据", left: "center" },
            xAxis: {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              data: time
            },
            legend: {
              data: ['用户人数', '线上解答','登录数', '服务档案数','求助律师订单数'],
              left: 'center'
              // top: 50
            },
            grid: {
              // top: 100
            },
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [

              {
                name: '用户人数',
                type: 'bar',
                data: user_count,
                itemStyle:{
                  color:"#E6C240",barBorderRadius:4,
                }
              },
              {
                name: '线上解答',
                type: 'bar',//line
                data: phone_count,
                itemStyle:{
                  color:"#5BD092",barBorderRadius:4,
                }
              },
              {
                name: '登录数',
                type: 'bar',//line
                data: login_count,
                itemStyle:{
                  color:"#FF9715",barBorderRadius:4,
                }
              },
              {
                name: '服务档案数',
                type: 'bar',
                data: file_count,
                itemStyle:{
                  color:"#617BE9",barBorderRadius:4,
                }
              },
              {
                name: '求助律师订单数',
                type: 'bar',
                data: resort_count,
                itemStyle:{
                  color:"#EB9BFB",barBorderRadius:4,
                }
              }
            ]
          })
        }
      })
    },
    userSearch: function (e) {
      this.userKey = e
      this.orderTypeCount(7)
    },
    userSearch1: function (e) {
      this.userKey = e
      this.orderTypeCount(8)
    },
    totalTime3: function (e) {
      var _this = this
      ;(_this.startTime = e[0]), //开始时间
          (_this.endTime = e[1]) //结束时间
      _this.page = 1
      _this.orderTypeCount(7)
    },
    totalTime4: function (e) {
      var _this = this
      ;(_this.startTime = e[0]), //开始时间
          (_this.endTime = e[1]) //结束时间
      _this.page = 1
      _this.orderTypeCount(8)
    },
    remoteMethod2(query) {
      console.log(query)
      var _this = this
      _this.send({
        url: '/villagesList',
        data: {
          AdminId: _this.information.AdminId,
          Key: query
        },
        success: function (res) {
          console.log(res)
          _this.options2 = res.list
        }
      })
    },
    lawyerClick3: function (e) {
      var _this = this
      if (e == undefined) {
        _this.VillagesId = ''
        _this.options2 = []
      } else {
        _this.VillagesId = e
      }
      _this.page = 1
      _this.orderTypeCount(7)
      console.log(e)
    },
    lawyerClick4: function (e) {
      var _this = this
      if (e == undefined) {
        _this.VillagesId = ''
        _this.options2 = []
      } else {
        _this.VillagesId = e
      }
      _this.page = 1
      _this.orderTypeCount(8)
      console.log(e)
    },

    //时间戳转换成日期
    timestampToDate: function (timestamp,type) {
      var date = new Date(1000 * timestamp); //获取一个时间对象
      var Y = date.getFullYear();
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      var H = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours());
      var B = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
      if (type == 'datetime') { return `${Y}年${M}月${D}日 ${H}:${B}`}
      if (type == 'date') { return `${Y}年${M}月${D}日`}
    },
    orderTypeCount : function (type,name){
      var _this = this
      if(type == 1){
        _this.numberofStatesshow = true
      }
      if(type == 2){
        _this.$router.push({ path: '/UnderstanderAdmin' })
        // _this.aQuestionnaireList()
        // _this.Mbrmodal = true
      }
      if(type == 3){
        _this.phoneData.modal = true
        _this.phoneData.type = type
        _this.phoneData.title = name
        return
        _this.insderOrderData.modal = true
        _this.insderOrderData.type = type
        _this.insderOrderData.title = name
        _this.send({
          url: '/InsderOrderList',
          loading: true,
          data: {
            AdminId: _this.information.AdminId,
            page: _this.insderOrderData.page,
            pageSize: _this.insderOrderData.pageSize,
            key: _this.insderOrderData.key,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime, //结束时间
            nameKeyId: _this.nameKeyId, //明白人搜索
            VillagesId: _this.VillagesId,
            IsReply: _this.IsReply,
          },
          success: function (res) {
            _this.insderOrderData.list = res.List
            _this.insderOrderData.count = res.Count
          }
        })
      }
      if(type == 5){
        this.$router.push({ path: '/workLog' })
      }
      if(type == 4){this.$router.push({ path: '/toconfigureAdmin' })}
      if(type == 6){
        _this.insderOrderData.modal = true
        _this.insderOrderData.type = type
        _this.insderOrderData.title = name
        _this.send({
          url: '/InsderOrderByUserList',
          loading: true,
          data: {
            AdminId: _this.information.AdminId,
            page: _this.insderOrderData.page,
            pageSize: _this.insderOrderData.pageSize,
            key: _this.insderOrderData.key
          },
          success: function (res) {
            _this.insderOrderData.list = res.List
            _this.insderOrderData.count = res.Count
          }
        })
      }
      if(type == 7){
        _this.modal11 = true
        _this.send({
          url: '/AdminUserList',
          loading: true,
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            page: _this.page,
            pageSize: _this.pageSize,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            VillagesId: _this.VillagesId,
            key: _this.userKey
          },
          success: function (res) {
            for (var i in res.data) {
              if(res.data[i].UserPhone){
                var mobile = res.data[i].UserPhone
                var tempStr = ''
                for (var j = 0; j < mobile.length; j++) {
                  if (j < 3 || j > mobile.length - 3) {
                    tempStr += mobile[j]
                  } else {
                    tempStr += '*'
                  }
                }
                res.data[i].UserPhone = tempStr
              }
            }
            _this.adminUserData = res.data
            _this.count = res.total
          }
        })
      }
      if(type == 8){
        _this.modal12 = true
        _this.send({
          url: '/resortList',
          loading: true,
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            page: _this.page,
            pageSize: _this.pageSize,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            VillagesId: _this.VillagesId,
            key: _this.userKey
          },
          success: function (res) {
            _this.seekingHelpData = res.data
            _this.count = res.total
          }
        })
      }
    },
    aQuestionnaireList:function (e){
      console.log(e)
      var _this = this
      _this.send({
        url: '/insiderList',
        data: {
          AdminId: _this.information.AdminId,
          page: _this.page,
          pageSize: _this.pageSize,
          // InsiderName: _this.searchLaw || e
          InsiderName: ''
        },
        success: function (res) {
          _this.adminLawyerList = res.data.data
          _this.count = res.data.total
          for(var i in _this.statisticsData){
            if(_this.statisticsData[i].type == 2){if(_this.statisticsData[i].value == ''){_this.statisticsData[i].value = res.data.total}}
          }
        }
      })
      _this.send({
        url: '/insiderAdminFileList',
        data: {
          AdminId: _this.information.AdminId,
          typeId: 4,
          page: _this.page,
          pageSize: _this.pageSize,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime,
          VillagesId: _this.VillagesId,
          key : _this.userKey,
        },
        success: function (res) {
          for(var i in _this.statisticsData){
            if(_this.statisticsData[i].type == 5){_this.statisticsData[i].value = res.Count}
          }
        }
      })
      _this.send({
        url: '/OrganizationCountUser',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          for(var i in _this.statisticsData){
            if(_this.statisticsData[i].type == 7){_this.statisticsData[i].value = res.count}
          }
        }
      })
      _this.send({
        url: '/resortCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          for(var i in _this.statisticsData){
            if(_this.statisticsData[i].type == 8){_this.statisticsData[i].value = res}
          }
        }
      })
    },
    alertbox: function (url, name, InsiderPhone, InsiderId, OrganizationId) {
      var _this = this
      var _this = this
      var op = 0 + 'p' + InsiderPhone + 'p' + 4 + 'p' + InsiderId
      _this.send({
        url: '/AgetInsiderQRcode',
        data: {
          // OrganizationId: OrganizationId,
          scene: 0 + 'p' + InsiderPhone + 'p' + 4 + 'p' + InsiderId,
          userType: '4',
          page: _this.pageUrl,
          width: '430',
          jpg: 'jpg',
          InsiderId: InsiderId
        },
        success: function (res) {
          _this.lawImg = 'https://cq.lvwa18.com:8001/' + res
        }
      })
      console.log(op)
      _this.modal6 = true
      // _this.urlImg = url
      _this.lawName = name
    },
    alertbox1: function (url, name, lawyerId1, lInsiderPhone, OrganizationId) {
      var _this = this
      var op = OrganizationId + 'p' + lInsiderPhone + 'p' + 1 + 'p' + lawyerId1 + ''
      if(SYSCONFIG.location == 'chong-qing'){
        var url1 = '/getLawyerSunCode'
      }else {
        var url1 = '/CD_getLawyerSunCode'
      }
      _this.send({
        url: url1,
        consulting1:true,
        responseType:"arraybuffer",
        data: {
          OrganizationId: OrganizationId,
          scene: op,
          userType: '2',
          page: _this.pageUrl,
          width: '430',
          jpg: 'jpg',
          InsiderId: lawyerId1
        },
        success: function (res) {
          _this.lawImg = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
        }
      })
      console.log(op)
      _this.modal6 = true
      // _this.urlImg = url
      _this.lawName = name
      _this.lawyerId1 = lawyerId1
      _this.lInsiderPhone = lInsiderPhone
    },

    serachSelectPage : function (value){
      var _this = this
      _this.villageByPage = value
      _this.VillageByAdmin()
    },
    serachSelect : function (value, selectedData){
      var _this = this
      console.log(value, selectedData)
      if(value != ''){
        _this.serachAdminId = value[value.length-1]
        _this.VillageByAdmin()
      }else {
        _this.VillageByData = []
      }
    },

    VillageByAdmin : function (){
      var _this = this
      _this.send({
        url: '/VillageByAdmin',
        data: {
          AdminId: _this.serachAdminId,
          key : _this.serachkey,
          page : _this.villageByPage,pageSize : _this.pageSize,
        },
        success: function(res) {
          _this.VillageByData = res.list
          _this.villageByCount = res.count
        }
      })
    },
    CqAdminSerach : function (){
      var _this = this
      var data = []
      _this.send({
        url: '/CqAdminSerach',
        data: {
          AdminId: window.sessionStorage.AdminId,
          Level: window.sessionStorage.adminLevel,
          page : 1,
          pageSize : '999',
        },
        success: function(res) {
          for(var i in res.list){
            data.push(res.list[i])
          }
          convertTree(data)
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let {
                AdminId: value,
                AdminName: label,
                Level : Level,
                Pid : Pid,
                list: children
              } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                Level,
                Pid,
                children
              })
            })
            _this.serachData = result
            return result
          }
        }
      })
    },
    lawFirmdropDown : function (e){
      if(e){
        this.searchFirm = ''
        this.OrganizationId = ''
        this.serachOrganization()
      }
      console.log(this.OrganizationId)
    },
    replaceLawyer : function (e){
      this.replaceShow = true
      this.FirmPageData = [e]
    },
    serachOrganization : function (){
      var _this = this
      _this.send({
        url: '/AdminOrganizationList',
        data: {
          AdminId : window.sessionStorage.AdminId,
          key: _this.searchFirm,
          page : _this.lawFirmPage,
          pageSize : '15',
        },
        success: function (res) {
          _this.FirmPageData = res.list
          _this.FirmPageCount = res.count
        }
      })
    },

    cqCommunity : function (){
      var _this = this
      if(window.sessionStorage.adminLevel == 3){
        _this.serachAdminId = window.sessionStorage.AdminId
        _this.VillageByAdmin()
      }else{
        _this.CqAdminSerach()
      }
    },
    lawFirmTurnPages : function (e){
      console.log(e)
      this.lawFirmPage = e
      this.serachOrganization()
    },
    lawFirmsearch : function (e){
      this.searchFirm = e
      this.lawFirmPage = 1
      this.FirmPageCount = 0
      // this.FirmPageData = []
      this.serachOrganization()
    },

    villageByQuery : function (e){
      console.log(e,this.VillagesId)
      this.serachkey = e
      this.villageByPage = 1
      this.villageByCount = 0
      this.VillageByAdmin()
    },
    villageByOpen : function (e){
      console.log(e)
    },
    cancel : function (){
      var _this = this
      _this.OrganizationId = ''
      _this.lawyerName = ''
      _this.lawyerPhone = ''
      _this.insderOrderData.page = 1
      _this.insderOrderData.count = 0
      _this.insderOrderData.title = ''
      _this.insderOrderData.list = []
      _this.startTime = '' //开始时间
      _this.endTime= '' //结束时间
      _this.nameKeyId= ''
    },
    ok: function() {
      var _this = this
      if (_this.InsiderName != '' && _this.InsiderPhone != '') {
        _this.send({
          url: '/AAddInsiderInfo',
          data: {

            AdminId: _this.information.AdminId,
            InsiderName : _this.InsiderName,
            InsiderPhone : _this.InsiderPhone,
            VillagesArr : _this.VillagesId.toString(),
          },
          success: function(res) {

            _this.VillageByData = []
            _this.serachValue = []
            _this.page = 1
            _this.aQuestionnaireList()
          }
        })
      } else {
        _this.$Notice.warning({
          title: '系统提示',
          desc: '名称或电话不能为空',
        })
      }
    },
    boundInsder : function (){
      var _this = this
      _this.send({
        url: '/BoundInsder',
        data: {
          AdminId: _this.information.AdminId,
        },
        success: function(res) {
          _this.UnBound = res.UnBound
          _this.Bound = res.Bound
        }
      })
    },
    InsderOrderByUser : function (){
      var _this = this
      _this.send({
        url: '/InsderOrderByUser',
        data: {
          AdminId: _this.information.AdminId,
        },
        success: function(res) {
          for(var i in _this.statisticsData){
            // if(_this.statisticsData[i].type == 3){_this.statisticsData[i].value = res.OrderCount}//线上解答数据更换
            if(_this.statisticsData[i].type == 6){_this.statisticsData[i].value = res.UserCount}
            if(_this.statisticsData[i].type == 4){_this.statisticsData[i].value = res.CoverVillageCount}
          }
          _this.orderData = res
        }
      })
    },
    callList : function (){
      var _this = this
      _this.send({
        url: '/callList',
        data: {
          AdminId: _this.information.AdminId,
          page: _this.phoneData.page,
          pageSize: _this.phoneData.pageSize,
          key: _this.phoneData.key,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime, //结束时间
          nameKeyId: _this.nameKeyId, //明白人搜索
          VillagesId: _this.VillagesId,
          IsReply: _this.IsReply,
        },
        success: function(res) {
          for(var i in _this.statisticsData){
            if(_this.statisticsData[i].type == 3){_this.statisticsData[i].value = res.Count}//拨打电话次数
          }
          _this.phoneData.list = res.List
          _this.phoneData.count = res.Count
        }
      })
    },
    insderCover : function (){
      var _this = this
      _this.send({
        url: '/insderCover',
        data: {
          AdminId: _this.information.AdminId,
          sort: _this.sort
        },
        success: function(res) {
          for(var i in _this.statisticsData){
            if(_this.statisticsData[i].type == 1){_this.statisticsData[i].value = res.length}
          }
          _this.difficultCount = res
        }
      })
    },
    todayGetCount : function (){
      var _this = this
      _this.send({
        url: '/TodayGetCount',
        data: {
          AdminId: _this.information.AdminId,
        },
        success: function(res) {
          _this.yesterdayData = {
            village: res.villages_sql,
            Insider: res.Insider_count,
            user: res.user_count,
            order: res.order_count
          }
        }
      })
    },
    //线上解答
    totalTime0: function (e) {
      var _this = this
      ;(_this.startTime = e[0]), //开始时间
          (_this.endTime = e[1]) //结束时间
      _this.insiderVillagesData.page = 1
      _this.orderTypeCount(_this.insderOrderData.type, _this.insderOrderData.title)
    },
    totalTime1: function (e) {
      var _this = this
      ;(_this.startTime = e[0]), //开始时间
          (_this.endTime = e[1]) //结束时间
      _this.phoneData.page = 1
      _this.callList()
    },
    InsiderClick: function (e) {
      var _this = this
      if (e == undefined) {
        _this.nameKeyId = ''
      } else {
        _this.nameKeyId = e
      }
      // _this.insiderVillagesData.page = 1
      _this.phoneData.page = 1
      // _this.orderTypeCount(_this.insderOrderData.type, _this.insderOrderData.title)
      // 2023/7/31更换为拨打电话
      _this.callList(_this.insderOrderData.type, _this.insderOrderData.title)
    },
    lawyerClick2: function (e) {
      var _this = this
      if (e == undefined) {
        _this.VillagesId = ''
        _this.options2 = []
      } else {
        _this.VillagesId = e
      }
      _this.insiderVillagesData.page = 1
      _this.orderTypeCount(_this.insderOrderData.type, _this.insderOrderData.title)
      console.log(e)
    },
    stateChoice: function (e) {
      var _this = this
      this.IsReply = e
      this.orderTypeCount(_this.insderOrderData.type, _this.insderOrderData.title)
    },
    sortClick: function (e) {
      var _this = this
      _this.sort = e
      _this.insderCover()
    },
  },
  mounted() {
    var _this = this
    this.information = window.sessionStorage
    _this.adminLevel = window.sessionStorage.adminLevel
    this.aQuestionnaireList()
    this.serachOrganization()
    this.boundInsder()
    this.InsderOrderByUser()
    this.insderCover()
    this.todayGetCount()
    this.newlyDataView()
    this.callList()
    if(this.information.adminLevel == 3){
      const entries = Object.entries(this.statisticsData);
      entries.shift();
      this.statisticsData = Object.fromEntries(entries);
    }
  },
}
</script>

<style scoped>
.tipsName {
  font-size: 16px;
  color: #8d8d8d;
}
.number {
  font-size: 36px;
  color: #7a7a7a;
}
.house {
  width: 72px;
  height: 72px;
  margin-top: 29px;
}
.displayData {
  width: 14.2%;
  height: 220px;
  background-color: #fff;
  font-size: 0;
  display: inline-block;
  text-align: center;
  /*margin-left: 2%;*/
}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
.smallProgram {
  width: 32px;
  height: 32px;
  vertical-align: top;
}
.addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}

.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

</style>
