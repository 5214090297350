<style scoped>
.frame{
  background: linear-gradient(80deg, #006FD6, #4C97FF);
  box-shadow: 0rem 2rem 4rem 0rem rgba(255, 255, 255, 0.1);opacity: 0.8;border-radius: 0.04rem;width: 6.90rem;height: 4.80rem;
  display: inline-block;}
.sifa{width: 1.02rem;height: 1.10rem;margin: 0.79rem 0 0.10rem 0;}
.title{font-size: 0.45rem;font-family: SingYiEG-Ultra-GB;font-weight: 900;color: #FFFFFF;margin-bottom: 0.60rem;}
.frameTwo{
  border-radius: 4px;margin: 0.30rem;width:5.98rem;height:3.90rem;
  border: 0.16rem solid rgba(255, 255, 255, 0.5);}



.tick{width: 0.5rem;height: 0.50rem;position: absolute;right: 0;bottom: 0;}
.regionTrue{height: 0.90rem;display: inline-block;line-height: 0.90rem;font-size: 0.26rem;
  border: 0.01rem solid #EBF8FF;position: relative;margin: 0.30rem 0rem 0 0.30rem;width: 2.1rem;background: #EBF8FF;text-align: center;

  border-radius: 0.15rem;
  background-color: rgba(255,255,255,0.85);
  color: #333;
  padding-left: 0;

  box-shadow: 0px 0px 0.1rem -0.04rem rgba(0,0,0,0.5);
  overflow: hidden;
  white-space: nowrap;}
.regionFalse{height: 0.90rem;display: inline-block;line-height: 0.90rem;font-size: 0.26rem;
  border: 0.01rem solid #ffffff;position: relative;margin: 0.30rem 0rem 0 0.30rem;width: 2.1rem;background: #ffffff;text-align: center;

  border-radius: 0.15rem;
  background-color: rgba(255,255,255,0.85);
  color: #333;
  padding-left: 0;

  box-shadow: 0px 0px 0.1rem -0.04rem rgba(0,0,0,0.5);
  overflow: hidden;
  white-space: nowrap;}


/*模态框*/
.modals{position:fixed; z-index: 999; top:0; left: 0; right:0; bottom: 0;}
.modals-cancel{position:absolute; z-index:1000; top:0; left: 0; right:0; bottom: 0; background-color: rgba(0,0,0,.5);}
.bottom-dialog-body{position:absolute; z-index:10001; bottom:0; left:0; right:0;max-height:70%; background-color: #fff;overflow:auto;}
/*动画前初始位置*/
.bottom-pos{-webkit-transform:translateY(100%);transform:translateY(100%);}
.street{width: 100%;height: 0.80rem;line-height: 0.80rem;text-align: center;border-bottom: 0.01rem solid #F6F5F8;}
.close{position: absolute;right: 0.20rem;top: -0.12rem;}
.scrollBar{overflow: hidden;position: fixed;width: 100%;}
.headTransparent{width: 100%;height: 0.7rem;background-image: linear-gradient(rgba(0,111,214,0.8), rgba(76,151,255,0.8));}
.framePortrait{width: 0.47rem;height: 0.47rem;border-radius: 0.47rem;background-color: rgba(255,255,255,0.6);display: inline-block;text-align: center;margin: 0.14rem 0.15rem 0 0.3rem;vertical-align:top;}
.hi{color:#fff;font-size: 0.24rem;display: inline-block;line-height: 0.70rem;vertical-align:top;}
.userImg{width: 0.45rem;height: 0.45rem;border-radius: 0.45rem;margin-top: 0.01rem;}
/*.position-panel{font-size: 0.26rem;font-family: PingFang SC;color:#fff;padding:0.2rem;padding-bottom:0.6rem;}*/

</style>

<template>
  <div>
    <div class="headTransparent">
      <div class="framePortrait" bindtap="myClikc">
        <img src="/img/logoGov.png" class="userImg" />
      </div>
      <div class="hi">Hi，<template v-if="nickName">{{nickName}}</template><span>你好！</span></div>
      <div class="verticalLine"></div>
      <!--<img src="https://ai-cdn.lvwa18.com/img/rusticate/tongzhi.png" class="tongzhi" />-->
    </div>
    <div style="background:url(https://ai-cdn.lvwa18.com/img/rusticate/newbg.png) no-repeat;background-size:100% auto;background-position: top;height:7.21rem;text-align: center;">
      <img src="https://ai-cdn.lvwa18.com/img/rusticate/sifa.png" class="sifa" />
      <div class="title">海南省智慧村居法律服务平台</div>
      <!---->
      <div style="text-align: center;"  class="position-panel">
        <div style="text-align: left;">
          <div v-for="(item,index) in regionList" style="display: inline-block;">
            <div class="regionTrue" v-if="item.AdminId == AdminId && item.nothing != false" @click="regionClick(item.AdminId)">
              {{item.AreaName}}
              <img class="tick" src="https://ai-cdn.lvwa18.com/img/rusticate/tick.png" />
            </div>
            <div class="regionFalse" v-if="item.AdminId != AdminId && item.nothing != false" @click="regionClick(item.AdminId)">
              {{item.AreaName}}
            </div>
            <div class="regionFalse" v-if="item.nothing == false" style="background: #EBEBEB;">
              {{item.AreaName}}
            </div>
          </div>
          <div style="padding:0.10rem;"></div>
        </div>
      </div>
    </div>
    <!---->
    <div class="cdsftPls">
      <Modal v-model="hideModal" @on-cancel="cancel">
        <div>
          <div class="street">选择所属区域</div>
          <div class="street" v-for="(item,index) in streetList" @click="street(item.AdminId)">{{item.AdminName}}</div>
          <div class="street" v-for="(item,index) in villagesList" @click="jump(item.VillagesId)">{{item.VillagesName}}
          </div>
          <div v-if="streetList == '' && villagesList == ''" style="text-align: center">暂无数据</div>
        </div>
        <div slot="footer"></div>
      </Modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "HNHYBSD",
  data(){
    return{
      AdminId : '',
      regionList : [
      ],
      streetList : [],
      villagesList : [],
      //
      hideModal:false, //模态框的状态  true-隐藏  false-显示
      animationData:{},//
    }
  },
  mounted(){
    this.initArea(67)
    this.getUserInfo()
  },
  methods : {
    getUserInfo:function(){
      if(Object.keys(this.$route.query).length>0){
        this.$store.state.userInfoTF.nickName = this.$route.query.nickname
        this.$store.state.userInfoTF.userId = this.$route.query.userid
        this.$store.state.userInfoTF.name = this.$route.query.name
        this.$store.state.userInfoTF.img = this.$route.query.headpic
        window.sessionStorage.nickName = this.$route.query.nickname
        window.sessionStorage.userId = this.$route.query.userid
        window.sessionStorage.name = this.$route.query.name
        window.sessionStorage.img = this.$route.query.img
      }
      if(!this.$store.state.userInfoTF.userId&&this.$route.query.test==1){
        window.location.href = "https://cdpre.tfsmy.com/api/oauth/authorize?client_id=alBcTOONxF&response_type=code&redirect_uri=https://vn-server.lvwa18.com/plsHome&scope=read"
      }
    },
    jump:function (res) {
      var _this = this
      _this.hideModal = false
      setTimeout(function () {
        _this.$router.push({name:'HNHYBH',query:{AreaId:res}})
      },50)
    },
    cancel : function(){
      this.streetList = []
      this.villagesList = []
    },
    showModal : function(){
      this.hideModal = !this.hideModal
    },
    initArea : function (adminId) {
      var _this = this
      _this.AdminId = adminId
      _this.send({
        url:'/FindBottomVillagesAndAdmin_',
        data:{
          AdminId:adminId,
        },
        success:function(res){
          _this.regionList = []
          for(var index in res.Admin){
            _this.regionList.push({AdminId:res.Admin[index].AdminId,AdminName:res.Admin[index].AdminName,AreaName:res.Admin[index].AdminName})
          }
        }
      })
    },
    regionClick : function (adminId) {
      var _this = this
      _this.AdminId = adminId
      _this.send({
        url:'/FindBottomVillagesAndAdmin_',
        data:{
          AdminId:adminId,
        },
        success:function(res){
          _this.streetList = res.Admin
          _this.villagesList = res.Villages
          console.log(_this.streetList)
          _this.showModal()
        }
      })
    },
    street : function (adminId) {
      var _this =this
      _this.send({
        url:'/FindBottomVillagesAndAdmin_',
        data:{
          AdminId:adminId,
        },
        success:function(res){
          _this.villagesList = res.Villages
          _this.streetList = []
          console.log(_this.streetList)
        }
      })
    },
  },
  computed:{
    nickName:function(){
      if(this.$store.state.userInfoTF.nickName){
        return this.$store.state.userInfoTF.nickName
      }else{
        return false
      }
    }
  },
}
</script>


