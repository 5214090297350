<template>
  <div>
    <div style="background:url(https://ai.lvwa18.com/img/rusticate/banner-08.png) no-repeat;background-size:100% auto;background-position: top;height:1.62rem;width:7.50rem;position: relative;">
      <div style="text-align: center;line-height: 1.62rem;font-size: 0.42rem;color: #FFF;font-weight: bold;">
        预约公证
      </div>
    </div>

    <div class="center" v-for="(item,i) in firmData" style="margin-bottom:0.20rem;box-shadow: 0px 0.04rem 0.18rem 0.01rem rgba(0,0,0,0.15);border-top: 0.02rem solid #dddddd;">
      <div class="statisticsFrame" v-for="(value,index) in item" v-if="!value.type || value.type == type">
        <div class="statisticsName">{{value.name}}</div>
        <div class="statisticsNumber">{{value.content}}
          <div class="backgroundImg" @click="dialClick(value.content)" v-if="value.fun == 'calling'">
            <van-icon name="phone" color="#009edb" size="40rpx" />
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 0.65rem;"></div>
    <div class="bottom">
      <van-button type="info" @click="handle" block style="height:1rem;font-size:0.32rem;">点击办理</van-button>
    </div>
  </div>
</template>

<script>
import md5 from "md5";

export default {
  name: "notarization",
  data:function(){
    return{
      type: '',
      firmData: [
        [{name: '单位', content: '西平县司法局'},{name: '公证处', content: '0396-6223780',fun:'calling',type:7},{name: '法律援助中心', content: '0396-6196778',fun:'calling',type:6},{name: '法律援助中心地址', content: '西平县司法局院内一楼',type:6},],
        [{name: '单位', content: '驿城区司法局'},{name: '公证处', content: '0396-3507080',fun:'calling',type:7},{name: '法律援助中心', content: '0396-2828148',fun:'calling',type:6},{name: '法律援助中心地址', content: '驿城区解放路和薄山路交叉口东北角',type:6},],
        [{name: '单位', content: '遂平县司法局'},{name: '公证处', content: '0396-2316189',fun:'calling',type:7},{name: '法律援助中心', content: '0396-4656582',fun:'calling',type:6},{name: '法律援助中心地址', content: '遂平县勤政路一号楼一楼',type:6},],
        [{name: '单位', content: '泌阳县司法局'},{name: '公证处', content: '0396-7927567',fun:'calling',type:7},{name: '法律援助中心', content: '0396-7905678',fun:'calling',type:6},{name: '法律援助中心地址', content: '泌阳县文明路中段司法局院内',type:6},],
        [{name: '单位', content: '新蔡县司法局'},{name: '公证处', content: '0396-5922793',fun:'calling',type:7},{name: '法律援助中心', content: '0396-2731858',fun:'calling',type:6},{name: '法律援助中心地址', content: '新蔡县市民之家A座1楼南大厅',type:6},],
        [{name: '单位', content: '上蔡县司法局'},{name: '公证处', content: '0396-6906068',fun:'calling',type:7},{name: '法律援助中心', content: '0396-6916936',fun:'calling',type:6},{name: '法律援助中心地址', content: ' 上蔡县拥翠路南段',type:6},],
        [{name: '单位', content: '平舆县司法局'},{name: '公证处', content: '0396-3732691',fun:'calling',type:7},{name: '法律援助中心', content: '0396-2300697',fun:'calling',type:6},{name: '法律援助中心地址', content: '平舆县清河大道北段县行政副中心司法局一楼',type:6},],
        [{name: '单位', content: '汝南县司法局'},{name: '公证处', content: '0396-2718508',fun:'calling',type:7},{name: '法律援助中心', content: '0396-2718509',fun:'calling',type:6},{name: '法律援助中心地址', content: '汝南县汝宁街道办事处古城大街7号（老县政府院内）',type:6},],
        [{name: '单位', content: '确山县司法局'},{name: '公证处', content: '0396-7024259',fun:'calling',type:7},{name: '法律援助中心', content: '0396-7018301',fun:'calling',type:6},{name: '法律援助中心地址', content: '确山县未来大道589号 ',type:6},],
        [{name: '单位', content: '正阳县司法局'},{name: '公证处', content: '0396-8915200',fun:'calling',type:7},{name: '法律援助中心', content: '0396-8912438',fun:'calling',type:6},{name: '法律援助中心地址', content: '正阳县司法局办公楼一楼',type:6},],
      ]
    }
  },
  mounted() {
    if(this.$route.query.type){
      this.type = this.$route.query.type
    }
  },
  methods:{
    dialClick: function(content){
      window.location.href = 'tel:' + content
    },
    handle: function (){
      if(this.type == 7){
        var md5 = require('md5');
        var date = new Date()
        var timestamp = date.valueOf().toString().substring(0, 10)
        var dateNum = date.getFullYear() + '-'
        dateNum += date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        dateNum += '-'
        dateNum += date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        var url = 'https://notarization.lvwa18.com/#/?userid='+this.$store.state.userInfo.UserId +'&key=' + md5('cdssfjcdjyjdzxxjsyxgs' + timestamp + dateNum) + '&timestamp=' + timestamp
        window.location = url
      }
    },
  },
}
</script>

<style scoped>
.statisticsFrame{width: 6.30rem;border-bottom: 0.01rem solid #dddddd;line-height: 1rem;display: inline-block;text-align: left;}
.statisticsName{font-size: 0.24rem;color: #999999;display: inline-block;margin-left: 0.20rem;}
.statisticsNumber{font-size: 0.24rem;color: #333333;display: inline-block;float: right;margin-right: 0.20rem;font-weight: 600}
.consultation{width: 6.90rem;background-color: #fff;border-radius: 0.06rem;margin-left: 0.30rem;box-shadow: 0px 0.04rem 0.18rem 0.01rem rgba(0,0,0,0.15);}
.backgroundImg{width: 0.60rem;height: 0.60rem;background-color: #f8f9fb;border-radius: 0.52rem;float: right;margin-top: 0.20rem; display: flex;justify-content: center;align-items: center;margin-left: 0.20rem;}

.bottom{text-align: center;background-color: #fff;height: 1rem;position: fixed;bottom: 0;width:100%;}
</style>
