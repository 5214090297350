<template>
    <div>
        <div style="width: 300px;height: 150px;background-color: rgb(81 153 238);font-size: 50px;color: #FFF;text-align: center;line-height: 150px;margin-top: 20px"
             @click="addLawyerList">
            添加
        </div>
        <div>
          <div style="width: 300px;height: 75px;background-color: rgb(81 153 238);font-size: 26px;color: #FFF;text-align: center;line-height: 75px;margin-top: 20px;display: inline-block" @click="AdminVillagesList">1、canvas加载社区</div>
          <div style="width: 300px;height: 75px;background-color: rgb(81 153 238);font-size: 26px;color: #FFF;text-align: center;line-height: 75px;margin-top: 20px;display: inline-block;margin-left: 30px"
               @click="downloadCanvas">
            2、canvas社区村下载
          </div>
        </div>

        <div>
          <div style="width: 300px;height: 75px;background-color: rgb(81 153 238);font-size: 26px;color: #FFF;text-align: center;line-height: 75px;margin-top: 20px;display: inline-block" @click="justiceList">1、canvas加载街道镇</div>
          <div style="width: 300px;height: 75px;background-color: rgb(81 153 238);font-size: 26px;color: #FFF;text-align: center;line-height: 75px;margin-top: 20px;display: inline-block;margin-left: 30px"
               @click="downloadCanvas1">
            2、canvas街道镇下载
          </div>
        </div>
        <div style="width: 300px;height: 150px;background-color: rgb(81 153 238);font-size: 50px;color: #FFF;text-align: center;line-height: 150px;margin-top: 20px"
             @click="see">
           查看数据
        </div>
        <div style="width: 300px;height: 150px;background-color: rgb(81 153 238);font-size: 50px;color: #FFF;text-align: center;line-height: 150px;margin-top: 20px"
             @click="see1">
           接口
        </div>
        <div style="width: 300px;height: 150px;background-color: rgb(81 153 238);font-size: 50px;color: #FFF;text-align: center;line-height: 150px;margin-top: 20px"
             @click="addLawFirm">
            添加律所
        </div>
        <div style="width: 300px;height: 150px;background-color: rgb(81 153 238);font-size: 50px;color: #FFF;text-align: center;line-height: 150px;margin-top: 20px"
             @click="onShequ">
            添加社区
        </div>
        <div style="width: 300px;height: 150px;background-color: rgb(81 153 238);font-size: 50px;color: #FFF;text-align: center;line-height: 150px;margin-top: 20px"
             @click="addLawyerList">
            添加律师
        </div>
        <div style="width: 300px;height: 150px;background-color: rgb(81 153 238);font-size: 50px;color: #FFF;text-align: center;line-height: 150px;margin-top: 20px"
             @click="attendanceClock">
            考勤打卡社区导出
        </div>
      <div v-for="(item,index) in adminVillagesData" :id="'qrcode'+index" :ref="'qrcode'+index" style="display: none;"></div>
      <div v-for="(item,index) in electronicsList" :id="'qrcode'+index" :ref="'qrcode'+index" style="display: none;"></div>
<!--      width="488" height="640"-->
        <canvas width="1063" height="638" v-for="(item,index) in adminVillagesData" :id="item.VillagesId"></canvas>
        <canvas width="1063" height="638" v-for="(item,index) in electronicsList" :id="item.AdminId"></canvas>

    </div>
</template>

<script>
    import JSZip from 'jszip'
    import FileSaver from 'file-saver'
    import QRCode from "qrcode2";
    export default {
        name: "Atest",
        data () {
            return {
                listData : [],
                listData2 : [],
                listData3 : [],
                listData4 : [],
                adminVillagesData : [],
              electronicsList : [],
                url : [],
                LawFirmList : [

                ],
                lawyerList : [

                ],
                shequ : [

                ],
                ManageTotalAdminData : [


                ],
                packZip : [],
            }
        },
        methods:{
            filesToRar : function(arrImages, filename){
                let _this = this;
                let zip = new JSZip();
                let cache = {};
                let promises = [];
                _this.title = '正在加载压缩文件';

                for (let item of arrImages) {
                    const promise= _this.getImgArrayBuffer(item.fileUrl).then(data => {
                        // 下载文件, 并存成ArrayBuffer对象(blob)
                        zip.file(item.renameFileName, data, { binary: true }); // 逐个添加文件
                        cache[item.renameFileName] = data;
                    });
                    promises.push(promise);
                }

                Promise.all(promises).then(() => {
                    zip.generateAsync({ type: "blob" }).then(content => {
                        _this.title = '正在压缩';
                        // 生成二进制流
                        FileSaver.saveAs(content, filename); // 利用file-saver保存文件  自定义文件名
                        _this.title = '压缩完成';
                    });
                }).catch(res=>{
                    _this.$message.error('文件压缩失败');
                });
            },
            getImgArrayBuffer(url){
                let _this=this;
                return new Promise((resolve, reject) => {
                    //通过请求获取文件blob格式
                    let xmlhttp = new XMLHttpRequest();
                    xmlhttp.open("GET", url, true);
                    xmlhttp.responseType = "blob";
                    console.log(xmlhttp)
                    xmlhttp.onload = function () {
                        if (this.status == 200) {
                            resolve(this.response);
                        }else{
                            reject(this.status);
                        }
                    }
                    xmlhttp.send();
                });

            },
            downloadCanvas : function(){
                var _this = this
                for(var i in _this.adminVillagesData){
                    var canvas=document.getElementById(_this.adminVillagesData[i].VillagesId)//获取canvas
                    var base64 = canvas.toDataURL("image/png")
                    if(_this.adminVillagesData[i].LawyerName){var LawyerName = _this.adminVillagesData[i].LawyerName[0][0]}else{var LawyerName = ''}
                    if(_this.adminVillagesData[i].LawyerName){var LawyerPhone = _this.adminVillagesData[i].LawyerName[0][3]}else{var LawyerPhone = ''}
                    _this.packZip.push({fileUrl:base64,
                        renameFileName:_this.adminVillagesData[i].AdminName+'-'+_this.adminVillagesData[i].VillagesName+'-'+LawyerName+'-'+LawyerPhone+'.png',})
                }
                console.log(_this.packZip)
                _this.filesToRar(_this.packZip,window.sessionStorage.AdminName)
            },
          qRCode : function (url,index,item){
            console.log(url, index);
            document.getElementById('qrcode' + index).innerHTML = '';
            let tmp = document.getElementById('qrcode' + index);
            var qrcode = new QRCode('qrcode' + index, {
              text: url,
              width: '360',
              height: '360',
              colorDark: '#0067ff',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.H
            });
            let logo = new Image();
            logo.crossOrigin = 'Anonymous';
            logo.src = "/img/HYB.png";
            let that = this;
            console.log(this.$refs.qrcode);
            let con = this.$refs.qrcode;
            logo.onload = function() {
              let container = con;
              let qrImg = qrcode._el.getElementsByTagName('img')[0];
              let canvas = qrcode._el.getElementsByTagName('canvas')[0];
              var ctx = canvas.getContext("2d");
              ctx.drawImage(logo, 360 * 0.5 - 44, 360 * 0.5 - 44, 88, 88);
              qrImg.src = canvas.toDataURL();
              // container.appendChild(qrcode._el);
              // 销毁临时dom
              tmp = null;
              qrcode = null;
              logo = null;

              qrImg.onload = function() {
                console.log(document.getElementById('qrcode' + index).getElementsByTagName("img")[0].src);
                var canvas = document.getElementById(item.VillagesId);
                var ctx = canvas.getContext("2d");
                var img = new Image();
                img.src = "https://ai-cdn.lvwa18.com/img/rusticate/card1.jpg";
                img.setAttribute("crossOrigin", 'Anonymous');
                img.onload = function() {
                  ctx.drawImage(img, 0, 0);
                  ctx.font = 'normal bold 55px Arial, sans-serif';
                  ctx.fillStyle = 'rgba(69, 69, 69, 1)';
                  ctx.fillText(item.VillagesName + '法律服务', 215, 133);
                  ctx.font = 'normal bold 22px Arial, sans-serif';
                  ctx.fillStyle = 'rgba(69, 69, 69, 1)';
                  ctx.fillText(item.LawyerName[0][0] + '：' + item.LawyerName[0][3], 750, 530);
                  ctx.font = 'normal bold 24px Arial, sans-serif';
                  ctx.fillStyle = 'rgba(23, 156, 209, 1)';
                  ctx.fillText(item.AdminName, 600, 590);
                  var img1 = new Image();
                  img1.src = document.getElementById('qrcode' + index).getElementsByTagName("img")[0].src;
                  img1.setAttribute("crossOrigin", 'Anonymous');
                  img1.onload = function() {
                    ctx.drawImage(img1, 685, 154, 311, 311);
                  };
                };
              };
            };

          },
            draw : function(){
                var _this = this
                _this.adminVillagesData.forEach((item,index,array)=>{
                  console.log(item.LawyerName[0])
                  if(SYSCONFIG.location == 'chong-qing'){
                    var url = '/getVillageSunCode'
                    var page = 'chong-qing/login/login'
                  }else {
                    var url = '/CD_getVillageSunCode'
                    var page = 'pages/login/login'
                  }
                    // console.log(item._AdminName+'-'+item.VillagesName+'-'+item.LawyerName+'-'+item.LawyerPhone+'-'+item.LawyerId+'p'+item.VillagesId+'p'+2)

                        _this.send({
                          url:url,
                          consulting1:true,
                          responseType:"arraybuffer",
                          data: {
                            scene: item.LawyerName[0][2]+'p'+item.VillagesId+'p'+2,
                            LawyerId : item.LawyerId,
                            width: '430',
                            jpg: 'jpg',
                            page: page,
                            userType : '1',
                            VillagesId : item.VillagesId
                          },
                          success:function(res){
                            var canvas = document.getElementById(item.VillagesId);
                            var ctx = canvas.getContext("2d");
                            var img = new Image();
                            img.src = "https://ai-cdn.lvwa18.com/img/rusticate/card1.jpg";
                            img.setAttribute("crossOrigin",'Anonymous')
                            //浏览器加载图片完毕后再绘制图片
                            img.onload = function(){
                              //以Canvas画布上的坐标(10,10)为起始点，绘制图像
                              ctx.drawImage(img, 0, 0);
                              ctx.font = 'normal bold 55px Arial, sans-serif';
                              ctx.fillStyle = 'rgba(69, 69, 69, 1)';//填充颜色
                              ctx.fillText(item.VillagesName+'法律服务', 215, 133,);
                              ctx.font = 'normal bold 22px Arial, sans-serif';
                              ctx.fillStyle = 'rgba(69, 69, 69, 1)';//填充颜色
                              ctx.fillText(item.LawyerName[0][0]+'：'+item.LawyerName[0][3], 750, 530,);
                              ctx.font = 'normal bold 24px Arial, sans-serif';
                              ctx.fillStyle = 'rgba(23, 156, 209, 1)';//填充颜色
                              ctx.fillText(item.AdminName, 600, 590,);


                              var img1 = new Image();
                              img1.src = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
                              img1.setAttribute("crossOrigin",'Anonymous')
                              img1.onload = function(){
                                ctx.drawImage(img1, 685, 154,311,311);
                              }
                            };
                            return
                            var canvas1 = document.getElementById(item.VillagesId);//获取绘图区域
                            var ctx1 = canvas1.getContext('2d');//让我们拿到一个CanvasRenderingContext2D对象
                            //var gl = canvas.getContext("webgl");//3d
                            ctx1.clearRect(0, 0, canvas1.width, canvas1.height);//擦除(0,0)位置大小为canvas1.width，canvas1.height的矩形，擦除的意思是把该区域变为透明
                            ctx1.beginPath();//开始
                            ctx1.fillStyle = '#FFF';
                            ctx1.fillRect(0,0,488,640)
                            ctx1.font = '36px Arial';
                            ctx1.fillStyle = '#333333';//填充颜色
                            ctx1.textAlign='center';
                            ctx1.fillText(item.AdminName +'-'+item.VillagesName, 244, 60);
                            //创建新的图片对象
                            var img = new Image();
                            //指定图片的URL
                            img.src = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
                            img.setAttribute("crossOrigin",'Anonymous')
                            //浏览器加载图片完毕后再绘制图片
                            img.onload = function(){
                              //以Canvas画布上的坐标(10,10)为起始点，绘制图像
                              ctx1.textAlign='center';
                              ctx1.drawImage(img, 30, 100);
                            };
                            ctx1.font = '30px Arial';
                            ctx1.textAlign='center';
                            ctx1.fillText('村（社区）法律顾问：'+item.LawyerName[0][0], 244, 570);
                            ctx1.textAlign='center';
                            ctx1.fillText(item.LawyerName[0][3], 244, 620);
                          }
                        })
                        //执行代码

                })
            },
            onShequ : function(){
                var _this = this
                for(var i in _this.shequ){
                    console.log(_this.shequ[i])
                    _this.send({
                        url : '/AddVillages',
                        data: {
                            VillagesName:_this.shequ[i].VillagesName,
                            LawyerId:_this.shequ[i].LawyerId,
                            AdminId:_this.shequ[i].AdminId,
                            ProvinceId:'2236',
                            CityId:'2237',
                            AreaId:'2251',
                            CityName:'重庆市黔江区',
                        },
                        success: function (res) {

                        }
                    })
                }
            },
        //
            addLawFirm : function () {
                var _this = this
                for(var i in _this.LawFirmList){
                    console.log(_this.LawFirmList[i])
                    _this.send({
                        url : '/AddMember',
                        data: {
                            Level : 3,
                            AdminId : _this.LawFirmList[i].AdminId,
                            AdminLogin : _this.LawFirmList[i].AdminLogin,
                            AdminPassword : _this.LawFirmList[i].AdminPassword,
                            OrganizationLinkman : '',
                            OrganizationPhone : _this.LawFirmList[i].OrganizationPhone,
                            OrganizationName : _this.LawFirmList[i].OrganizationName,
                            AreaId : '',
                        },
                        success: function (res) {

                        }
                    })
                }
            },
            //添加律师
            addLawyerList : function () {
                var _this = this
                for(var i in _this.lawyerList){
                    console.log(_this.lawyerList[i],_this.lawyerList[i].OrganizationId+'p'+_this.lawyerList[i].LawyerPhone +'p'+1)
                  if(SYSCONFIG.location == 'chong-qing'){
                    var url = '/getVillageSunCode'
                  }else {
                    var url = '/CD_getVillageSunCode'
                  }
                    _this.send({
                        url : url,
                      consulting1:true,
                      responseType:"arraybuffer",
                        data: {
                            OrganizationId : _this.lawyerList[i].OrganizationId,
                            scene: _this.lawyerList[i].OrganizationId+'p'+_this.lawyerList[i].LawyerPhone +'p'+1,
                            userType : '2',
                            page: 'pages/login/login',
                            width: '430',
                            jpg: 'jpg',
                            LawyerName : _this.lawyerList[i].LawyerName,
                            LawyerPhone : _this.lawyerList[i].LawyerPhone
                        },
                        success: function (res) {
                        }
                    })
                }
            },
            getCaption : function (obj,i) {
                var index=obj.lastIndexOf("\镇");
                obj=obj.substring(index+1,obj.length);
                this.getCaption1(obj,i)
                return obj;
            },
            getCaption1 : function (obj,i) {
                var _this = this
                var index=obj.indexOf("村");
                obj=obj.substring(0,index);
                console.log(i)
                if(obj.length > 4){
                    console.log(obj.substr(obj.length - 2,4))
                    _this.listData.push({village :obj.substr(obj.length - 2,4)})
                }else {
                    _this.listData.push({village :obj})
                }
                return obj;
            },

        //
        getCaption3 : function (obj,i) {
            var index=obj.lastIndexOf("\省");
            obj=obj.substring(index+1,obj.length);
            this.getCaption4(obj,i)
            return obj;
        },
            getCaption4 : function (obj,i) {
                var _this = this
                var index=obj.indexOf("市");
                obj=obj.substring(0,index);
                _this.listData2.push({city :obj+"市"})
                return obj;
            },
            see  : function () {
                var _this = this
                for(var i in _this.listData3){
                    _this.listData4.push({village :_this.listData[i].village,city:_this.listData2[i].city,id:_this.listData3[i].id})
                }
console.log(_this.listData4)
            },
            see1 : function () {
                var _this = this
                _this.send({
                    url : '/back_address',
                    data: {address: _this.listData4},
                    success: function (res) {
                        for(var i in res){
                            _this.getCaption(res[i].address,i)
                            _this.getCaption3(res[i].address,i)
                            _this.listData3.push({id :res[i].id})
                        }
                        _this.$Loading.finish();
                    }
                })
            },
          AdminVillagesList : function (){
            var _this = this
            _this.send({
              url : '/AdminVillagesList',
              data: {
                AdminId : window.sessionStorage.AdminId,
                typeId : '4',
                page : '1',
                pageSize : '999',
                key : '',
                start_time : '' ,//开始时间
                end_time : '',
                sort : ''
              },
              success: function (res) {
                for(var i in res.List){
                  if(res.List[i].LawyerName.indexOf(",")==-1){
                    var LawyerData = []
                    LawyerData.push(res.List[i].LawyerName.split('+'))
                    res.List[i].LawyerName = LawyerData
                  }else {
                    var LawyerList = res.List[i].LawyerName.split(',')
                    var LawyerData = []
                    for(var j in LawyerList){
                      LawyerData.push(LawyerList[j].split('+'))
                    }
                    res.List[i].LawyerName = LawyerData
                  }
                  console.log(res.List[i].LawyerName,i)
                }
                _this.adminVillagesData = res.List
                if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){
                  setTimeout(() => {
                    for (var i in _this.adminVillagesData) {
                      (function (index) {
                        var url = 'https://onecode-h5.digitalhainan.com.cn/online/qlink?linkCode=mZcfTkVp&type=qrCode_2&VillagesId=' + _this.adminVillagesData[index].VillagesId;
                        _this.qRCode(url, index, _this.adminVillagesData[index]);
                      })(i);
                    }
                  }, 500);

                }else {
                  _this.draw()
                }
              }
            })
          },
          //街道镇
          justiceList : function (){
            var _this = this
            if(window.sessionStorage.adminLevel == 2){var url = '/justiceList'}
            if(window.sessionStorage.adminLevel == 1){var url = '/AllAdminList'}
            _this.send({
              url : url,
              data: {
                AdminId : window.sessionStorage.AdminId,
                Level : window.sessionStorage.adminLevel,
                page: 1,
                pageSize : 999,
              },
              success: function (res) {
                _this.electronicsList = res.list
                _this.draw1()
              }
            })
          },
          draw1 : function(){
            var _this = this
            _this.electronicsList.forEach((item,index,array)=>{
              // console.log(item._AdminName+'-'+item.VillagesName+'-'+item.LawyerName+'-'+item.LawyerPhone+'-'+item.LawyerId+'p'+item.VillagesId+'p'+2)
              if(window.sessionStorage.ParentId == '2237' || window.sessionStorage.ParentId == '2262'){
                var scene ="cqcj-"+item.AdminId
                var AreaId = '2236'
              }else{
                if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){
                  setTimeout(() => {
                    var url = 'https://onecode-h5.digitalhainan.com.cn/online/qlink?linkCode=mZcfTkVp&type=qrCode_2&AdminId='+item.AdminId
                    console.log(item,index)
                    _this.qRCode(url,index,_this.electronicsList[index])
                  },500)
                  return
                }else{
                  var scene =  "chooseArea-"+item.AdminId
                  var AreaId = item.AreaId
                }
              }
              if(SYSCONFIG.location == 'chong-qing'){
                var url = '/getVillageSunCode'
              }else {
                var url = '/CD_getVillageSunCode'
              }
              _this.send({
                url:url,
                consulting1:true,
                responseType:"arraybuffer",
                data: {
                  scene:scene,
                  width: '430',
                  jpg: 'jpg',
                  page:'public/scanIn/scanIn',
                  AreaId: AreaId
                },
                success:function(res){
                  var canvas = document.getElementById(item.AdminId);
                  var ctx = canvas.getContext("2d");
                  var img = new Image();
                  img.src = "https://ai-cdn.lvwa18.com/img/rusticate/card1.jpg";
                  img.setAttribute("crossOrigin",'Anonymous')
                  //浏览器加载图片完毕后再绘制图片
                  img.onload = function(){
                    //以Canvas画布上的坐标(10,10)为起始点，绘制图像
                    ctx.drawImage(img, 0, 0);
                    ctx.font = 'normal bold 55px Arial, sans-serif';
                    ctx.fillStyle = 'rgba(69, 69, 69, 1)';//填充颜色
                    ctx.fillText(window.sessionStorage.AreaName+item.AdminName+'法律服务', 215, 133,);
                    ctx.font = 'normal bold 22px Arial, sans-serif';
                    ctx.fillStyle = 'rgba(69, 69, 69, 1)';//填充颜色
                    ctx.fillText('', 750, 530,);
                    ctx.font = 'normal bold 24px Arial, sans-serif';
                    ctx.fillStyle = 'rgba(23, 156, 209, 1)';//填充颜色
                    ctx.fillText(window.sessionStorage.AreaName+item.AdminName, 600, 590,);
                  };
                  var img1 = new Image();
                  img1.src = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
                  img1.setAttribute("crossOrigin",'Anonymous')
                  img1.onload = function(){
                    ctx.drawImage(img1, 685, 154,311,311);
                  }
                  return
                  var canvas1 = document.getElementById(item.AdminId);//获取绘图区域
                  var ctx1 = canvas1.getContext('2d');//让我们拿到一个CanvasRenderingContext2D对象
                  ctx1.clearRect(0, 0, canvas1.width, canvas1.height);//擦除(0,0)位置大小为canvas1.width，canvas1.height的矩形，擦除的意思是把该区域变为透明
                  ctx1.beginPath();//开始
                  ctx1.fillStyle = '#FFF';
                  ctx1.fillRect(0,0,488,540)
                  ctx1.font = '36px Arial';
                  ctx1.fillStyle = '#333333';//填充颜色
                  ctx1.textAlign='center';
                  ctx1.fillText(window.sessionStorage.AreaName+item.AdminName, 244, 60); //创建新的图片对象
                  var img = new Image();
                  //指定图片的URL
                  img.src = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
                  img.setAttribute("crossOrigin",'Anonymous')
                  //浏览器加载图片完毕后再绘制图片
                  img.onload = function(){
                    //以Canvas画布上的坐标(10,10)为起始点，绘制图像
                    ctx1.textAlign='center';
                    ctx1.drawImage(img, 30, 100);
                  };
                }
              })
              //执行代码
            })
          },
          downloadCanvas1 : function(){
            var _this = this
            for(var i in _this.electronicsList){
              var canvas=document.getElementById(_this.electronicsList[i].AdminId)//获取canvas
              var base64 = canvas.toDataURL("image/png")
              _this.packZip.push({fileUrl:base64,
                renameFileName:window.sessionStorage.AreaName+'-'+_this.electronicsList[i].AdminName+'.png',})
            }
            console.log(_this.packZip)
            _this.filesToRar(_this.packZip,window.sessionStorage.AdminName)
          },
        //   考勤打卡社区导出
          attendanceClock: function (){
            var _this = this;
            _this.send({
              url: '/VillageListByPage',
              data: {
                AdminId: window.sessionStorage.AdminId,
                typeId: '',
                page: 1,
                pageSize: 9999999999,
                key: '',
                LawyerName: '',
                start_time: '', //开始时间
                end_time: '',
                sort: ''
              },
              success: function (res) {
                for (let i in res.data) {
                  _this.send({
                    url: '/ClockWord',
                    loading: true,
                    data: {
                      AdminId: window.sessionStorage.AdminId,
                      start_time: '2022-10-1',
                      end_time: '2023-9-30',
                      key: '',
                      VillagesId: res.data[i].VillagesId,
                      FileName: res.data[i].VillagesName,
                      clockArr: ''
                    },
                    success: function (data) {
                      // link.href = window.sessionStorage.url + data;
                      // link.setAttribute('download', res.data[i].VillagesName + '.docx'); // 自定义文件名
                      window.open(window.sessionStorage.url + data)
                    }
                  });
                }
              }
            });

          },
          testAPI: function (){
            var _this = this
            this.send({
                url : '/testAPI',
                data: {pageSize:50000},
                success: function (res) {
                  _this.testAPI()
                },
                error:function (){
                  _this.testAPI()
                },
            })
          }
        },
        mounted () {
            var _this = this
          // _this.testAPI()
            // _this.$Loading.start();
            // _this.send({
            //     url : '/p_address',
            //     data: {},
            //     success: function (res) {
            //         for(var i in res){
            //             _this.getCaption(res[i].address,i)
            //             _this.getCaption3(res[i].address,i)
            //             _this.listData3.push({id :res[i].id})
            //         }
            //         _this.$Loading.finish();
            //     }
            // })
        },
    }
</script>

<style scoped>

</style>
