<template>
  <div style="padding: 20px">
    <div style="background-color: white">
      <div style="font-size: 16px; text-align: left; border-left: solid #de4f0b 3px; padding-left: 5px;margin-bottom: 20px">
        企业法治体检
        <div style="display: inline-block; vertical-align: middle; margin-left: 10px">
          <span style="margin-left: 30px">共{{ ruleOfLawData.count }}条</span>
        </div>
      </div>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>企业名称</th>
            <th>企业地址</th>
            <th>联系人</th>
            <th>手机号</th>
            <th>律所</th>
<!--            <th>体检类别</th>-->
            <th>情况说明（选填）</th>
            <th>申请时间</th>
            <th>查看时间</th>
            <th>处理完成时间</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in ruleOfLawData.Data">
            <td>{{ value.CompanyName }}</td>
            <td>{{ value.Address }}</td>
            <td>{{ value.UserName }}</td>
            <td>{{ value.UserTel }}</td>
            <td>{{ value.LawyerName }}</td>
<!--            <td>{{ value.typeId }}</td>-->
            <td>{{ value.context|| '--' }}</td>
            <td>{{ value.RegisterTime }}</td>
            <td>{{ value.look_time }}</td>
            <td>{{ value.operate_time || '--' }}</td>
            <td>
              <span v-if="value.operate_time == null" @click="handleclick(value.UserId)" style="cursor: pointer;color:#fa8c16;">处理</span>
              <span v-else style="color:#1890ff;">处理完成</span>
            </td>
          </tr>
          </tbody>
        </table>
        <Page
            :total="ruleOfLawData.count"
            :page-size="ruleOfLawData.pageSize"
            show-elevator
            show-total
            @on-change="ruleOfLawData.page = $event;adminAllUser()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "enterpriseRuleOfLaw",
  data () {
    return {
      ruleOfLawData : {
        page: 1,
        pageSize: 15,
        count: 0,
        data: []
      }
    }
  },
  mounted() {
    this.adminAllUser()
  },
  methods: {
    adminAllUser: function (){
      var _this = this
      axios({
        method: 'post',
        url: 'https://manager.lvwa18.com:8002/AdminAllUser',
        data: {
          page : _this.ruleOfLawData.page,
          pageSize : _this.ruleOfLawData.pageSize,
        },
      })
      .then(function (res) {
        _this.$set(_this.ruleOfLawData, 'Data', res.data.Data.data);
        _this.$set(_this.ruleOfLawData, 'count', res.data.Data.count)
      })
    },
    handleclick: function (id){
      var _this = this
      axios({
        method: 'post',
        url: 'https://manager.lvwa18.com:8002/AdminOperate',
        data: {
          UserId: id
        },
      })
      .then(function (res) {
        _this.adminAllUser()
      })
    },
  }
}
</script>

<style scoped>
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>
