<style scoped>
.bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
.introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
.introduce-video-player{width:100%;}
.mediator-list{color:#FFF;}
.mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
.mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
.introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
.introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
.introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div class="introduce-text">
      <div class="introduce-text-title">办理售房委托公证需提交的材料</div>
      <div class="introduce-text-content">
        1、身份证；</br>
        2、户口簿；</br>
        3、结婚证；</br>
        4、房产证、土地使用证或不动产证；</br>
        5、房产信息（无抵押、扣押的）；</br>
        6、委托人的银行卡存取钱回单（监管银行：农行、建行、邮政）；</br>
        7、受托人的身份证复印件；</br>
        8、产权为夫妻的，夫妻2个到公证处办理公证；</br>
        9、不动产证登记为单独所有的，应到不动产登记部门复印配偶放弃的材料。不动产部门无配偶放弃材料的，配偶应到公证处确认产权归属。
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <Row style="text-align: center;">
      <Col span="24"><a href="https://scjys.egongzheng.com/H5/templates/index.html"><Button type="warning" style="width:4rem;">去 办 理</Button></a></Col>
    </Row>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'guild6',
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {

  },
}
</script>
