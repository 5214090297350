import { render, staticRenderFns } from "./adviser-government-table.vue?vue&type=template&id=5efaa42b&scoped=true"
import script from "./adviser-government-table.vue?vue&type=script&lang=js"
export * from "./adviser-government-table.vue?vue&type=script&lang=js"
import style0 from "./adviser-government-table.vue?vue&type=style&index=0&id=5efaa42b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5efaa42b",
  null
  
)

export default component.exports