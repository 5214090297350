<style scoped>
.bigDataBackground {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    background-repeat: no-repeat;
}
.title {
    font-size: 46px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0px 4px 8px rgba(2, 17, 58, 0.81);
    line-height: 46px;
    padding-top: 23px;
}
.titleBottom {
    display: inline-block;
    width: 100%;
    height: 98px;
    margin-top: -38px;
}
.total {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 16px;
    margin-bottom: 16px;
    text-align: left;
}
.numberStyle {
    overflow: hidden;
    vertical-align: top;
    width: 50px;
    height: 60px;
    font-size: 46px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    background: #0a54ea;
    border-radius: 4px;
    line-height: 60px;
    display: inline-block;
    margin-right: 9px;
    text-align: center;
}
.comma {
    height: 60px;
    font-size: 46px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 60px;
    display: inline-block;
    margin: 0 10px 0 1px;
}
.category {
    width: 500px;
    height: 366px;
    background: linear-gradient(-45deg, rgba(10, 84, 234, 0.26), rgba(10, 84, 234, 0));
    border: 2px solid #2c3381;
    border-radius: 4px;
    position: relative;
    margin: 0 0 25px 0px;
}
.rankingName {
    display: inline-block;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 60px;
}
.giant {
    margin: 22px 9px 22px 22px;
    vertical-align: top;
}
.archivesImg {
    display: inline-block;
    position: relative;
    background-image: url(/img/bigData/archivesImg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 370px;
    height: 327px;
}
.punctuation {
    width: 53px;
    height: 53px;
    position: absolute;
}
.archivesName {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    height: 28px;
    line-height: 28px;
    position: absolute;
    white-space: nowrap;
}
.archivesName span {
    font-size: 36px;
    color: rgb(236, 192, 37);
    vertical-align: top;
}
.rankingTitle {
    margin-bottom: 10px;
    background-color: rgba(13, 37, 72, 0.5);
    width: 496px;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
}
.rankingTitle div {
    width: 20%;
    display: inline-block;
}
.rankingList {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 29px;
}
.rankingList div {
    width: 20%;
    display: inline-block;
}
.archivesNumber {
    top: 161px;
    font-size: 36px;
    color: rgb(236, 192, 37);
    display: inline-block;
}
.roll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.roll::-webkit-scrollbar-track {
    background: #f6f6f6;
    border-radius: 2px;
}
.roll::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 2px;
}
.roll::-webkit-scrollbar-thumb:hover {
    background: #747474;
}
.roll::-webkit-scrollbar-corner {
    background: #f6f6f6;
}
</style>

<template>
    <div class="bigDataBackground">
        <div
            class="center"
            style="
                height: 100%;
                background-image: url(/img/bigData/bg.png);
                background-repeat: no-repeat;
                min-width: 1920px;
                background-size: 100% 100%;
                min-height: 1080px;
            "
        >
            <div class="title">
                <!--律蛙科技有限公司 - 律蛙数字法治-智慧司法平台-->
                <span v-if="information.OrganizationId == '86'">大数据中心</span>
                <span v-else-if="information.ParentId == '2205' || information.AreaId == '2205'">
                    智慧村居法律服务平台 - 大数据中心
                </span>
                <span v-else>{{ sysName }} - 大数据中心</span>
            </div>
            <div style="font-size: 18px; color: white; line-height: 18px; margin-top: 6px">
                <div class="inline-block">
                    您好，<span v-if="information.AreaId == '3269'">{{ information.AdminName }}</span>
                  <span v-else>{{ information.AdminLogin }}</span>
                </div>
                {{ dateNow }}
            </div>
            <img class="titleBottom" src="/img/bigData/titleBottom.png" />
            <!---->
            <div class="center">
                <div
                    class="inline-block"
                    v-for="(items, index) in statisticsData"
                    :style="{ margin: statisticsData.length == index + 1 ? '0 0 40px 0' : '0 80px 40px 0' }"
                    style="vertical-align: top"
                >
                    <div class="total">{{ items.name }}</div>
                    <div
                        v-for="(iteml, index) in items.value"
                        :class="iteml == ',' ? 'comma' : 'numberStyle'"
                        :style="{ 'margin-right': items.value.length == index + 1 ? '0' : '9px' }"
                    >
                        <div style="display: inline-block" v-if="iteml == ','">
                            {{ iteml }}
                        </div>
                        <div
                            v-else
                            style="display: inline-block; transition: transform 0.5s ease-in-out"
                            v-for="(value, i) in 10"
                            :style="{ transform: 'translate(0,' + -60 * iteml + 'px)' }"
                        >
                            {{ i }}
                        </div>
                    </div>
                </div>
            </div>
            <!---->
            <div class="inline-block">
                <div class="category">
                    <img src="/img/bigData/top.png" style="position: absolute; top: -2px; left: -2px" />
                    <img src="/img/bigData/right.png" style="position: absolute; top: -2px; right: -2px" />
                    <img src="/img/bigData/bottom.png" style="position: absolute; bottom: -2px; right: -2px" />
                    <img src="/img/bigData/left.png" style="position: absolute; bottom: -2px; left: -2px" />
                    <div style="text-align: left">
                        <img src="/img/bigData/giant.png" class="giant" />
                        <div class="rankingName">服务类别 TOP10</div>
                    </div>
                    <div id="consulting" style="width: 456px; height: 281px"></div>
                </div>
                <!---->
                <div class="category" style="margin-bottom: 0">
                    <img src="/img/bigData/top.png" style="position: absolute; top: -2px; left: -2px" />
                    <img src="/img/bigData/right.png" style="position: absolute; top: -2px; right: -2px" />
                    <img src="/img/bigData/bottom.png" style="position: absolute; bottom: -2px; right: -2px" />
                    <img src="/img/bigData/left.png" style="position: absolute; bottom: -2px; left: -2px" />
                    <div style="text-align: left">
                        <img src="/img/bigData/giant.png" class="giant" />
                        <div class="rankingName">服务数据 7日</div>
                    </div>
                    <div id="serviceData" style="width: 456px; height: 261px"></div>
                </div>
            </div>
            <!--地图-->
            <div class="inline-block" style="vertical-align: top">
                <div style="width: 860px; height: 440px">
                    <div style="position: relative">
<!--                        <div id="mapChart" :style="{ width: '100%', height: '440px' }"></div>-->
                      <div ref="echarts-map" :style="{ width: '100%', height: '440px' }"></div>
                        <div
                            v-if="map.mapHistory.length > 1 && map.mapLodingState == false"
                            style="
                                cursor: pointer;
                                padding: 5px 15px;
                                position: absolute;
                                top: 80px;
                                left: 40px;
                                z-index: 999;
                                background-color: #29c8ff;
                                font-size: 14px;
                                color: #fff;
                                border-radius: 5px;
                            "
                            @click="backMap"
                        >
                            返回
                        </div>
                        <div
                            ref="childPanel"
                            style="
                                color: #fff;
                                font-size: 14px;
                                padding: 15px;
                                border-radius: 5px;
                                background-color: rgba(0, 0, 0, 0.5);
                                position: absolute;
                                display: none;
                            "
                        >
                            <div v-for="(item, index) in map.childData">{{ item.name }}:{{ item.value }}</div>
                        </div>
                    </div>
                </div>
                <!---->
                <div style="display: inline-block">
                    <div class="archivesImg center">
                        <div v-if="archivesData != ''">
                            <div class="archivesName" style="left: 136px; top: 125px; color: #ffffff; font-size: 16px">
                                电子档案
                            </div>
                            <div
                                class="archivesName archivesNumber"
                                :style="{ 'margin-left': -(archivesTotal.toString().length * 10 + 18) + 'px' }"
                            >
                                {{ archivesTotal }}
                            </div>
                            <!---->
                            <img
                                src="/img/bigData/punctuation.png"
                                class="punctuation"
                                style="top: 16px; right: 74px"
                            />
                            <div class="archivesName" style="left: 296px; top: 29px">
                                {{ archivesData[0].EvaluationTitle
                                }}<span style="margin-left: 12px">{{ archivesData[0].count }}</span>
                            </div>
                            <img
                                src="/img/bigData/punctuation.png"
                                class="punctuation"
                                style="top: 102px; right: 24px"
                            />
                            <div class="archivesName" style="left: 345px; top: 115px">
                                {{ archivesData[1].EvaluationTitle
                                }}<span style="margin-left: 12px">{{ archivesData[1].count }}</span>
                            </div>
                            <img
                                src="/img/bigData/punctuation.png"
                                class="punctuation"
                                style="top: 189px; right: 33px"
                            />
                            <div class="archivesName" style="left: 336px; top: 202px">
                                {{ archivesData[2].EvaluationTitle
                                }}<span style="margin-left: 12px">{{ archivesData[2].count }}</span>
                            </div>
                            <img
                                v-if="archivesData[7]"
                                src="/img/bigData/punctuation.png"
                                class="punctuation"
                                style="top: 236px; right: 60px"
                            />
                            <div v-if="archivesData[7]" class="archivesName" style="left: 316px; top: 251px">
                                {{ archivesData[7].EvaluationTitle
                                }}<span style="margin-left: 12px">{{ archivesData[7].count }}</span>
                            </div>
                            <div class="archivesName" style="left: 336px; top: 202px">
                                {{ archivesData[2].EvaluationTitle
                                }}<span style="margin-left: 12px">{{ archivesData[2].count }}</span>
                            </div>
                            <img
                                v-if="archivesData[8]"
                                src="/img/bigData/punctuation.png"
                                class="punctuation"
                                style="top: 277px; right: 151px"
                            />
                            <div v-if="archivesData[8]" class="archivesName" style="left: 224px; top: 288px">
                                {{ archivesData[8].EvaluationTitle
                                }}<span style="margin-left: 12px">{{ archivesData[8].count }}</span>
                            </div>
                            <!--<img src="/img/bigData/punctuation.png" class="punctuation" style="bottom: -2px;right: 124px">-->
                            <!--<div class="archivesName" style="left: 245px;bottom: 10px;">{{archivesData[5].EvaluationTitle}}<span style="margin-left: 12px;">{{archivesData[5].count}}</span></div>-->
                            <img
                                src="/img/bigData/punctuation.png"
                                class="punctuation"
                                style="bottom: 46px; left: 25px"
                            />
                            <div class="archivesName" style="right: 340px; bottom: 59px">
                                <span style="margin-right: 12px">{{ archivesData[5].count }}</span
                                >{{ archivesData[5].EvaluationTitle }}
                            </div>
                            <img
                                src="/img/bigData/punctuation.png"
                                class="punctuation"
                                style="bottom: 137px; left: -11px"
                            />
                            <div class="archivesName" style="right: 376px; bottom: 156px">
                                <span style="margin-right: 12px">{{ archivesData[4].count }}</span
                                >{{ archivesData[4].EvaluationTitle }}
                            </div>
                            <img src="/img/bigData/punctuation.png" class="punctuation" style="top: 37px; left: 19px" />
                            <div class="archivesName" style="right: 343px; top: 55px">
                                <span style="margin-right: 12px">{{ archivesData[3].count }}</span
                                >{{ archivesData[3].EvaluationTitle }}
                            </div>
                            <img
                                v-if="archivesData[6] && archivesData[6].count"
                                src="/img/bigData/punctuation.png"
                                class="punctuation"
                                style="top: -9px; left: 75px"
                            />
                            <div
                                v-if="archivesData[6] && archivesData[6].count"
                                class="archivesName"
                                style="right: 304px; top: 0"
                            >
                                <span style="margin-right: 12px">{{ archivesData[6].count }}</span
                                >{{ archivesData[6].EvaluationTitle }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!---->
            <div
                class="category inline-block roll"
                style="height: 750px; vertical-align: top; margin: 0; overflow-x: hidden"
            >
                <img src="/img/bigData/top.png" style="position: absolute; top: -2px; left: -2px" />
                <img src="/img/bigData/right.png" style="position: absolute; top: -2px; right: -2px" />
                <img src="/img/bigData/bottom.png" style="position: absolute; bottom: -2px; right: -2px" />
                <img src="/img/bigData/left.png" style="position: absolute; bottom: -2px; left: -2px" />
                <div style="text-align: left">
                    <img src="/img/bigData/giant.png" class="giant" />
                    <div class="rankingName">使用单位排名</div>
                </div>
                <div class="rankingTitle">
                    <div>序号</div>
                    <div>单位名称</div>
                    <div>服务次数</div>
                    <div>服务档案</div>
                    <div>累计用户数</div>
                </div>
                <div class="rankingList" v-for="(value, index) in difficultData">
                    <div><span v-if="index < 9">0</span>{{ index + 1 }}</div>
                    <div>{{ value.AdminName }}</div>
                    <div>{{ value.orderCount }}</div>
                    <div>{{ value.fileCount }}</div>
                    <div>{{ value.userCount }}</div>
                </div>
                <div style="padding: 10px"></div>
            </div>
            <!---->
        </div>

      <div v-if="cqs">
        <div style="position: fixed;width: 100%;height: 100%;z-index: 99999;background-color: rgba(227, 227, 227 , 0.8);top: 0;left: 0;" v-if="information.IsSign != 1">
          <div style="text-align: center;color: #000;font-size: 20px;margin-top: 20%">
            <div>感谢您一直以来对“重庆村居法务平台”的支持和关注</div>
            <div>本功能为增值服务项目，请购买后使用！</div>
            <div style="margin-top: 30px">
              <div>平台服务商： 重庆律蛙信息科技有限公司</div>
              <div>地   址：重庆市江北区五里店街道创富路5号1幢4楼</div>
              <div>联系人：唐应坤  18980633318</div>
            </div>
            <div style="display:inline-block;margin-top: 20px">
              <Button @click="introduction()">“重庆村居法务平台”的定制增值服务项目介绍.pdf</Button>
            </div>
            <div style="margin-top: 20px">
              <Button @click="introduction1('https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/pdf/%E9%87%8D%E5%BA%86%E6%9D%91%E5%B1%85%E6%B3%95%E5%8A%A1%E5%B9%B3%E5%8F%B0-%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.docx')">重庆村居法务平台-服务协议.docx</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import echarts from 'echarts'
import axios from 'axios'
import mapName from "../manager/data-center/big-data/map-name";
export default {
    name: 'bigData',
    data() {
        return {
            cqs: false,
            information: [],
            difficultData: [],
            archivesTotal: '',
            archivesData: [],
            statisticsData: [
                { name: '用户人数', value: [], type: '2' },
                { name: '咨询次数', value: [], type: '4' },
                { name: '服务村（社区）', value: [], type: '1' },
                { name: '律师人数', value: [], type: '3' }
                // { name: '省数', value: [], type: '5' },
            ],
            map: {
                mapChart: false,
                mapLodingState: false,
                mapName: '中国',
                mapHistory: [],
                mapData: [
                    {
                        name: '四川省',
                        value: 395,
                        type: [
                            { name: '服务村（社区）数', value: 152 },
                            { name: '律师数量', value: 30 },
                            { name: '咨询数量', value: 80 },
                            { name: '用户数量', value: 80 }
                        ]
                    },
                    {
                        name: '贵州省',
                        value: 98,
                        type: [
                            { name: '服务村（社区）数', value: 12 },
                            { name: '律师数量', value: 50 },
                            { name: '咨询数量', value: 30 },
                            { name: '用户数量', value: 30 }
                        ]
                    },
                    {
                        name: '云南省',
                        value: 15,
                        type: [
                            { name: '服务村（社区）数', value: 12 },
                            { name: '律师数量', value: 70 },
                            { name: '咨询数量', value: 20 },
                            { name: '用户数量', value: 20 }
                        ]
                    }
                ],
                childData: [
                    { name: '服务村（社区）', value: 152 },
                    { name: '律师人数', value: 30 },
                    { name: '用户人数', value: 30 },
                    { name: '咨询次数', value: 80 }
                ]
            },
            // user_count : 355684
            sysName: '',

          echartsMap:{
            tooltip: {
              trigger: 'item',
              // formatter: '{b}:{c}',
              formatter: function (params) {
                if(params.data){
                  return params.name +'<br>'+
                      // '服务村（社区）数：' + params.data.type.villages_count + '个'+'<br>'
                      // +'顾问人数：' + params.data.type.lawyer_count +'人'+'<br>'+
                      '用户人数：'+ params.data.type.user_count +'人'+'<br>'+
                      '服务档案数：'+ params.data.type.file_count +'条'+'<br>'+
                      '服务次数：'+ params.data.type.order_count+'次'
                }else {
                  return params.name
                }
              },
            },
            grid:{//直角坐标系
              top:'0',
              left:'0',
              right:'0',
              bottom:'0',
              containLabel:true,//设置直角坐标系区域包含坐标轴的刻度标签
            },
            visualMap: {
              min:0,
              max:0,
              show:false,//取消左侧visualMap-continuous组件保留数据映射功能
              inRange: {//设置最低最高颜色
                color: ['#063B89','#6099EC']
              }
            },
            series: [{
              type: 'map',
              zoom:1.1,//地图缩放
              map: '地图',
              nameMap:mapName,//自定义地图名称映射geo-json数据能够对应，则忽略
              emphasis:{//地图各版块高亮样式设置
                label:{
                  color:'#ffffff',//地图板块高亮区域文字颜色
                },
              },
              itemStyle:{
                borderColor:'#ffffff',//地图各板块描边颜色
                borderWidth:0.3,
              },
              label: {
                show: true,//区域名称上屏
                color:'#4ADEFE',//地图板块区域名称颜色
                textBorderColor:'#000000',//地图板块区域名称文字描边颜色
                textBorderWidth:2,//地图板块区域名称文字描边宽度
              },
              // formatter:[

              // ],
              data: [
                //{ name: '巫溪县', value: 20057.34},
              ],
            }]
          },
        }
    },
    computed: {
        dateNow: function () {
            return `${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日`
        }
    },
    mounted() {
        var _this = this
        if (window.sessionStorage.adminLevel == undefined) {
            this.$router.push({ name: 'managerLogin' })
        } else {
            this.information = window.sessionStorage
            _this.map.mapName = window.sessionStorage.AreaName
            // || window.sessionStorage.AreaId == '2236'
            if (window.sessionStorage.ParentId == '2237' || window.sessionStorage.ParentId == '2262') {
                this.cqs = true
                _this.switchData()
                _this.consulting()
                _this.serviceData()
                // _this.getMapGeo()
                this.makeMap(window.sessionStorage.areaCode)
                _this.difficultCount()
                _this.fileCountData()
            } else {
                _this.switchData()
                _this.consulting()
                _this.serviceData()
                // _this.getMapGeo()
                this.makeMap(window.sessionStorage.areaCode)
                _this.difficultCount()
                _this.fileCountData()
            }
        }
        // window.setInterval(() => {
        //     setTimeout(()=>{
        //         _this.switchData();
        //         // _this.statisticsData[0].value = String((_this.user_count++ || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')).split('')
        //     },0)
        // }, 30000)
        _this.sysName = window.sessionStorage.sysName
      var meta = document.getElementsByTagName('meta');
      meta[2].content = ''
    },
    methods: {
      introduction : function (){
        window.open('https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/pdf/%E2%80%9C%E9%87%8D%E5%BA%86%E6%9D%91%E5%B1%85%E6%B3%95%E5%8A%A1%E2%80%9D%E5%B9%B3%E5%8F%B0%E7%9A%84%E5%AE%9A%E5%88%B6%E5%A2%9E%E5%80%BC%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE%E4%BB%8B%E7%BB%8DV2.pdf');
      },
      introduction1 : function (url){
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
      },
        fileCountData: function () {
            var _this = this
            if (SYSCONFIG.location == 'chong-qing') {
                var url = '/FileCountData'
            } else {
                var url = '/CD_FileCountData'
            }
            _this.send({
                url: url,
                data: {
                    AdminId: _this.information.AdminId
                },
                success: function (res) {
                    _this.archivesData = res.List
                    _this.archivesTotal = res.Total
                }
            })
        },
        //
        difficultCount: function () {
            var _this = this
            if (_this.information.AdminId == 0) {
                var AdminId = 80
            } else {
                var AdminId = _this.information.AdminId
            }
            _this.send({
                url: '/areaRank',
                data: {
                    AdminId: AdminId
                },
                success: function (res) {
                    res.sort((item, item1) => {
                        return item1.userCount - item.userCount
                    })
                    _this.difficultData = res
                }
            })
        },
        //7日服务数据
        serviceData: function () {
            var _this = this
            let serviceData = echarts.init(document.getElementById('serviceData'))
            _this.send({
                url: '/GetTenData',
                data: {
                    AdminId: _this.information.AdminId
                },
                success: function (res) {
                    var time = []
                    var service = []
                    for (var i in res.list.day) {
                        if (i < 7) {
                            time.push(res.list.day[i].time.substring(5))
                            service.push(
                                res.list.day[i].Aid_count +
                                    res.list.day[i].Banli_count +
                                    res.list.day[i].Daixie_count +
                                    res.list.day[i].Legal_count +
                                    res.list.day[i].Moni_count +
                                    res.list.day[i].Phone_count +
                                    res.list.day[i].Rexian_count +
                                    res.list.day[i].Self_count +
                                    res.list.day[i].Tiaojie_count +
                                    res.list.day[i].video_count
                            )
                        }
                    }
                    console.log(time)
                    console.log(service)
                    serviceData.setOption({
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '0%',
                            top: '5%',
                            containLabel: true
                        },
                        axisLabel: {
                            // 坐标轴标签
                            show: true, // 是否显示
                            inside: false, // 是否朝内
                            rotate: 0, // 旋转角度
                            margin: 5, // 刻度标签与轴线之间的距离
                            color: '#FFF' // 默认取轴线的颜色
                        },
                        xAxis: [
                            {
                                type: 'category',
                                boundaryGap: false,
                                data: time,
                                axisLine: {
                                    //这是y轴文字颜色
                                    lineStyle: {
                                        color: '#0A54EA'
                                    }
                                },
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    lineStyle: {
                                        color: '#0A54EA'
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                splitLine: {
                                    lineStyle: {
                                        opacity: 0.3,
                                        color: '#0A54EA'
                                    }
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    lineStyle: {
                                        color: '#0A54EA'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                type: 'line',
                                smooth: true,
                                lineStyle: {
                                    color: new echarts.graphic.LinearGradient(1, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: '#F950FF'
                                        },
                                        {
                                            offset: 1,
                                            color: '#008AFF'
                                        }
                                    ]),
                                    width: 3
                                },
                                // background: linear-gradient(-45deg, rgba(10, 84, 234, 0.26), rgba(10, 84, 234, 0));
                                showSymbol: false,
                                areaStyle: {
                                    opacity: 0.6,
                                    color: new echarts.graphic.LinearGradient(1, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: '#F950FF'
                                        },
                                        {
                                            offset: 1,
                                            color: '#008AFF'
                                        }
                                    ])
                                },
                                itemStyle: {
                                    normal: {
                                        color: '#008AFF'
                                    }
                                },
                                data: service
                            }
                        ]
                    })
                }
            })
        },
        //咨询类别排名
        consulting: function () {
            var _this = this
            let consulting = echarts.init(document.getElementById('consulting'))
            _this.send({
                url: '/OrderTypeCount',
                data: {
                    AdminId: _this.information.AdminId,
                    typeId: '4',
                    start_time: '', //开始时间
                    end_time: ''
                },
                success: function (res) {
                    var OrderTypeTitle = []
                    var count = []
                    var compare = function (prop) {
                        return function (obj1, obj2) {
                            var val1 = obj1[prop]
                            var val2 = obj2[prop]
                            if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
                                val1 = Number(val1)
                                val2 = Number(val2)
                            }
                            if (val1 < val2) {
                                return -1
                            } else if (val1 > val2) {
                                return 1
                            } else {
                                return 0
                            }
                        }
                    }
                    //调用方式
                    res.sort(compare('count'))
                    console.log(res.sort(compare('count')))
                    for (var i in res) {
                        if (i > 1) {
                            if (res[i].OrderTypeTitle != '有效咨询') {
                                OrderTypeTitle.push(res[i].OrderTypeTitle)
                                count.push(res[i].count)
                            }
                        }
                    }
                    consulting.setOption({
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '5%',
                            bottom: '0%',
                            top: '5%',
                            containLabel: true
                        },
                        axisLabel: {
                            // 坐标轴标签
                            show: true, // 是否显示
                            inside: false, // 是否朝内
                            rotate: 0, // 旋转角度
                            margin: 5, // 刻度标签与轴线之间的距离
                            color: '#FFF' // 默认取轴线的颜色
                        },
                        xAxis: {
                            type: 'value',
                            axisLine: {
                                //这是y轴文字颜色
                                lineStyle: {
                                    color: '#0A54EA'
                                }
                            },
                            splitLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            }
                        },
                        yAxis: {
                            // inverse: true,
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    opacity: 0.3,
                                    color: '#0A54EA'
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            type: 'category',
                            data: OrderTypeTitle,
                            axisLine: {
                                lineStyle: {
                                    color: '#0A54EA'
                                }
                            }
                        },
                        series: [
                            {
                                name: '',
                                type: 'bar',
                                barWidth: 10,
                                itemStyle: {
                                    normal: {
                                        //柱体的颜色
                                        //右，下，左，上（1，0，0，0）表示从正右开始向左渐变
                                        barBorderRadius: [5, 5, 5, 5],
                                        color: new echarts.graphic.LinearGradient(
                                            1,
                                            0,
                                            0,
                                            0,
                                            [
                                                {
                                                    offset: 0,
                                                    color: '#47CD93'
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#1D3EC3'
                                                }
                                            ],
                                            false
                                        )
                                    }
                                },
                                data: count
                            }
                        ]
                    })
                    // var option = {
                    //
                    // };
                    // consulting.setOption(option, true);
                }
            })
        },
        //数字统计
        switchData: function () {
            var _this = this
            _this.send({
                url: '/OrganizationCountUser',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    typeId: _this.typeId,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime
                },
                success: function (res) {
                    _this.statisticsData[0].value = String(
                        (res.count || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                    ).split('')
                }
            })
            _this.send({
                url: '/OrganizationCountOrder',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    typeId: _this.typeId,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime
                },
                success: function (res) {
                    _this.statisticsData[1].value = String(
                        (res.count || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                    ).split('')
                }
            })
            _this.send({
                url: '/OrganizationCountVillage',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    typeId: _this.typeId,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime
                },
                success: function (res) {
                    _this.statisticsData[2].value = String(
                        (res.count || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                    ).split('')
                }
            })
            _this.send({
                url: '/OrganizationCountLawyer',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    typeId: _this.typeId,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime
                },
                success: function (res) {
                    _this.statisticsData[3].value = String(
                        (res.count || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                    ).split('')
                }
            })
        },
        //
        backMap: function () {
            this.map.mapName = this.map.mapHistory[this.map.mapHistory.length - 2]
            this.map.mapHistory.length = this.map.mapHistory.length - 1
            this.getMapGeo()
        },
        getMapGeo: function (areaCode) {
          let _this = this
          return new Promise((resolve,reject)=>{
            axios.get(`/geo-json/${areaCode}.json`)
                .then(res=>{
                  console.log(res.data)
                  echarts.registerMap('地图',res.data)
                  echarts.init(_this.$refs['echarts-map']).setOption(_this.echartsMap)
                  resolve(areaCode)
                })
          })
        },
        loadMap: function (mapGeo) {
            var _this = this
            var myChart = _this.map.mapChart
            echarts.registerMap(_this.map.mapName, mapGeo)
            var mapOption = {
                geo: {
                    show: true,
                    map: 'china',
                    label: {
                        normal: {
                            show: false
                        },
                        emphasis: {
                            show: false
                        }
                    },
                    roam: false,
                    itemStyle: {
                        normal: {
                            areaColor: '#01215c',
                            borderWidth: 5, //设置外层边框
                            borderColor: '#9ffcff',
                            shadowColor: 'rgba(0,54,255, 1)',
                            shadowBlur: 150
                        }
                    }
                },
                title: {
                    text: '数据分布 - ' + _this.map.mapName,
                    subtext: '鼠标点击可查看数据',
                    left: 'center',
                    textStyle: {
                        color: '#FFF'
                    }
                },
                visualMap: {
                    inRange: {
                        color: ['#060457', '#d1a814'],
                        // color: ['#060457', '#0201ea'],
                        symbolSize: [30, 100]
                    },
                    left: '-10%'
                },
                series: [
                    {
                        type: 'map',
                        map: _this.map.mapName,
                        name: '服务村（社区）数',
                        data: _this.map.mapData,
                        nameMap: {
                            鱼嘴镇人民政府: '鱼嘴镇',
                            复盛镇人民政府: '复盛镇',
                            五宝镇人民政府: '五宝镇',
                            新津县: '新津区'
                        },
                        label: {
                            normal: {
                                show: false
                            },
                            emphasis: {
                                show: true,
                                textStyle: {
                                    color: '#fff'
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                areaColor: '#060457',
                                borderColor: '#1950CC',
                                borderWidth: 1
                            },
                            emphasis: {
                                areaColor: '#01215c'
                            }
                        }
                    }
                ]
            }
            myChart.setOption(mapOption, true)
            myChart.on('mousemove', function (params) {
                if (!params.data.type || {}) {
                    return
                }
                _this.map.childData = params.data.type || {}
                _this.$refs.childPanel.style.display = 'block'
                _this.$refs.childPanel.style.left = params.event.offsetX + 20 + 'px'
                _this.$refs.childPanel.style.top = params.event.offsetY + 20 + 'px'
            })
            myChart.on('mouseout', function (params) {
                _this.$refs.childPanel.style.display = 'none'
            })

            if (myChart._$handlers.click) {
                myChart._$handlers.click.length = 0
            }
            myChart.on('click', function (params) {
                _this.map.mapName = params.name
                _this.getMapGeo()
            })
            myChart.hideLoading()
            _this.map.mapLodingState = false
        },
        getMapData: function (adcode) {
            var _this = this
            return new Promise((resolve, reject) => {
                _this.send({
                    url: '/_GetMapCount',
                    data: {
                        AreaCode: adcode
                    },
                    success: function (res) {
                        // _this.map.mapData = []
                        for (var index in res) {
                            _this.map.mapData.push({
                                name: res[index].AreaName,
                                value:
                                    res[index].villages_count +
                                    res[index].lawyer_count +
                                    res[index].order_count +
                                    res[index].user_count,
                                type: [
                                    { name: '服务村（社区）数', value: res[index].villages_count },
                                    { name: '律师人数', value: res[index].lawyer_count },
                                    { name: '用户人数', value: res[index].user_count },
                                    { name: '咨询次数', value: res[index].order_count }
                                ]
                            })
                        }
                        resolve(res)
                    }
                })
            })
        },
    //   地图

      makeMap:function(areaCode){
        let _this = this
        // echarts.init(_this.$refs['echarts-map']).showLoading({
        //     text: '数据正在加载中...',
        //     textStyle: { fontSize: 30, color: '#444' },
        //     effectOption: { backgroundColor: 'rgba(0, 0, 0, 0)' }
        //   });
        _this.getMapGeo(areaCode)//获取地图渲染GEOJSON
            .then(_this.getMapData)//获取地图的数据
            .then(_this.checkSquireArea)//检查特殊地区数据
            .then(res=>{
              _this.echartsMap.series[0].data = []
              for(let index in res){
                if(res[index].user_count>_this.echartsMap.visualMap.max){
                  _this.echartsMap.visualMap.max = res[index].user_count
                }
                _this.echartsMap.series[0].data.push({
                  name:res[index].AreaName,
                  value:res[index].user_count,
                  type : res[index]
                })

              }
              const myChart = echarts.init(_this.$refs['echarts-map'])
              myChart.setOption(_this.echartsMap)
              myChart.on('click', (e) => {
                for(var i in _this.electronicsList){
                  if(_this.electronicsList[i].name == e.data.name){
                    let adminLogin = _this.electronicsList[i].adminLogin;
                    let adminPassword = _this.electronicsList[i].adminPassword;
                    let routeData = _this.$router.resolve({name: 'managerLogin', params: { adminLogin, adminPassword },});
                    let urlWithParams = `${routeData.href}?adminLogin=${adminLogin}&adminPassword=${adminPassword}`;
                    window.open(urlWithParams, '_blank');
                  }
                }

              })
              echarts.init(_this.$refs['echarts-map']).setOption(_this.echartsMap)
              // echarts.init(_this.$refs['echarts-map']).hideLoading()
            })
      },

      //检查特殊区域
      checkSquireArea:function(areaListIn){
        let _this = this
        let areaList = []
        return new Promise((resolve,reject)=>{
          if(areaListIn[0].AreaCode==500100){
            //areaListIn.splice(0,1)//是否删除最大值
            let getMapFunList = []
            for(let index in areaListIn){
              getMapFunList.push(_this.getMapData(areaListIn[index].AreaCode))
            }
            Promise.all(getMapFunList)
                .then(res=>{
                  res.map((item,index)=>{
                    // item.splice(0,1)
                    areaList = [...areaList,...item]
                  })
                  resolve(areaList)
                })
          }else{
            resolve(areaListIn)
          }
        })
      },
    }
}
</script>
