<style scoped>
    .header{background-image: url(/img/legalAid/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
    .menu{font-size:0.24rem;padding:0.3rem 0.3rem;}
    .list{padding:0.2rem 0;}
</style>

<template>
    <div style="background-color:#f8f9fb;">
        <Modal
                v-model="detialPanel.state"
                title="申请详情"
        >
            <template v-if="detialPanel.data.Content">
                <div>姓名：<span class="colour">{{detialPanel.Content["biz_webcase.name"]}}</span></div>
                <div>性别：<span class="colour">{{detialPanel.data.SupplementContent.sex}}</span></div>
                <div>电话：<span class="colour">{{detialPanel.Content["biz_webcase.phone"]}}</span></div>
                <div>身份证号码：<span class="colour">{{detialPanel.Content["biz_webcase.identitycardnumber"]}}</span></div>
                <div>人群类型：<span class="colour">{{detialPanel.data.SupplementContent.webcrowdtypename}}</span></div>
                <div>对方当事人姓名：<span class="colour">{{detialPanel.data.Content.opposingname}}</span></div>
                <div>类型：<span class="colour">{{detialPanel.data.SupplementContent.humantypename}}</span></div>
                <div>月收入：<span class="colour">{{detialPanel.data.SupplementContent.monthlyincomename}}</span></div>
                <div>法律援助事由：<span class="colour">{{detialPanel.data.SupplementContent.legalhelpreasonname}}</span></div>
                <div>申请事项明细：<span class="colour">{{detialPanel.Content["biz_webcase.caseremark"]}}</span></div>
                <!--<Button type="primary" @click="result();modal3 = true">结果查询</Button>-->
            </template>
            <template >
                <Divider dashed style="margin:0.3rem 0;"/>
                <div>平台回复：
                    <span v-if="detialPanel.data.PlatformApply">{{detialPanel.data.PlatformApply}}</span>
                    <span v-else>未回复</span>
                </div>
            </template>
        </Modal>
        <!---->
        <Modal
                v-model="modal3"
                title="结果查询"
                ok-text="关闭"
                cancel-text=""
        >
            <div class="details">
                <div class="consultation">
                    <div style="padding: 20px;">
                        <div v-if="resultReply.zt1">
                            <div>状态：{{resultReply.zt1}}</div>
                            <div>答复意见：{{resultReply.dfyj}}</div>
                            <div>答复日期：{{resultReply.jdrq}}</div>
                        </div>
                        <div v-if="resultReply.zt2">
                            <div>受理审查日期：{{resultReply.slscrq}}</div>
                            <div>受理审查单位：{{resultReply.slscdw}}</div>
                        </div>
                        <div v-if="resultReply.zt3">
                            <div>立案日期：{{resultReply.larq}}</div>
                            <div>案件编号：{{resultReply.ah}}</div>
                        </div>
                        <div v-if="resultReply.zt4">
                            <div>案件指派：{{resultReply.zprq}}</div>
                            <div>办理单位：{{resultReply.cbdw}}</div>
                            <div>承办律师{{resultReply.cbr}}</div>
                        </div>
                        <div v-if="resultReply.zt5">结案日期：{{resultReply.jarq}}</div>
                    </div>
                </div>
            </div>
        </Modal>
        <!---->
        <div class='header'></div>
        <div class='body'>
<!--            <div class="menu">-->
<!--                <Row>-->
<!--                    <Col span="12" style="font-size:0.3rem;font-weight: bold;"><Icon type="ios-list" style="font-size:0.5rem;"/>申请列表</Col>-->
<!--                    <Col span="12" style="text-align: right;">-->
<!--                        <router-link :to="{name:'cqslegalAidInput'}"><span style="background-color:#008fd6;padding:0.17rem 0.45rem;border-radius:0.1rem;color: #FFF;">去申请</span></router-link>-->
<!--                    </Col>-->
<!--                </Row>-->
<!--            </div>-->
<!--            <div style="text-align: center">-->
<!--              <div @click="modal = true" style="margin-top:0.3rem;line-height: 1rem;display:inline-block;width: 6.6rem;height: 1rem;background: #08C47B;border-radius: 0.04rem;color: #FFFFFF;font-size: 0.3rem;font-weight: bold;">-->
<!--                <img src="/img/legalAid/shenqing.png" style="width: 0.32rem;height: 0.36rem;vertical-align: top;margin: 0.3rem 0.1rem 0 0">-->
<!--                <div style="display: inline-block">去申请</div>-->
<!--              </div>-->
<!--            </div>-->
            <div v-if="list.length>0" style="text-align: center">
              <div style="margin-top:0.3rem;line-height: 1rem;display:inline-block;width: 6.6rem;height: 1rem;background: #008FD6;border-radius: 0.04rem 0.04rem 0 0;color: #FFFFFF;font-size: 0.3rem;font-weight: bold;text-align: left">
                <img src="/img/legalAid/shenqingjilu.png" style="width: 0.32rem;height: 0.36rem;vertical-align: top;margin: 0.32rem 0.1rem 0 0.3rem">
                <div style="display: inline-block">申请记录</div>
              </div>
              <div style="text-align: center;margin-bottom: 0.3rem" v-for="(item,index) in list">
                <div style="width: 6.6rem;height: 1rem;background: #008FD6;border-radius: 0.04rem 0.04rem 0rem 0rem;line-height: 1rem;
color: #FFFFFF;font-size: 0.28rem;display: inline-block;text-align: left;">
                  <div style="width: 0.6rem;height: 0.6rem;background: #FFFFFF;border-radius: 50%;display: inline-block;color: #008FD6;text-align: center;line-height: 0.6rem;margin-left: 0.3rem">
                    <span v-if="index+1<=9">0{{index+1}}</span>
                    <span v-else>{{index+1}}</span>
                  </div>
                  <div style="float: right" @click="getDetail(item)">
                    <div style="display: inline-block">查看详情</div>
                    <img src="/img/legalAid/youbian.png" style="width: 0.2rem;height: 0.2rem;margin: 0 0.2rem 0 0.1rem">
                  </div>
                </div>
                <div style="text-align: center;background-color: #FFF;width: 6.6rem;display: inline-block;box-shadow: 0px 5px 8px 0px rgba(161, 159, 158, 0.29);border-radius: 0.06rem;">
                  <div style="border-bottom: 0.01rem #DDDDDD solid;font-size: 0.28rem;color: #999999;height: 0.86rem;line-height: 0.86rem;text-align: left;display: inline-block;width: 6.60rem">
                    <div style="display: inline-block;margin-left: 0.3rem">申请人：</div>
                    <div style="display: inline-block;color: #333333">{{item.Content["biz_webcase.name"]}}</div>
                  </div>
                  <div style="border-bottom: 0.01rem #DDDDDD solid;font-size: 0.28rem;color: #999999;height: 0.86rem;line-height: 0.86rem;text-align: left;display: inline-block;width: 6.60rem">
                    <div style="display: inline-block;margin-left: 0.3rem">申请时间：</div>
                    <div style="display: inline-block;color: #333333">{{item.CreateTime}}</div>
                  </div>
                  <div style="border-bottom: 0.01rem #DDDDDD solid;font-size: 0.28rem;color: #999999;height: 0.86rem;line-height: 0.86rem;text-align: left;display: inline-block;width: 6.60rem">
                    <div style="display: inline-block;margin-left: 0.3rem">申请事由：</div>
                    <div style="display: inline-block;color: #333333">{{item.SupplementContent.legalhelpreasonname}}</div>
                  </div>
                  <div style="border-bottom: 0.01rem #DDDDDD solid;font-size: 0.28rem;color: #999999;height: 0.86rem;line-height: 0.86rem;text-align: left;display: inline-block;width: 6.60rem">
                    <div style="display: inline-block;margin-left: 0.3rem">回复内容：</div>
                    <div style="display: inline-block;color: #333333">
                      <span v-if="item.PlatformApply">{{item.PlatformApply}}</span>
                      <span v-else>未回复</span>
                    </div>
                  </div>
                  <div v-if="item.PlatformApplyTime" style="border-bottom: 0.01rem #DDDDDD solid;font-size: 0.28rem;color: #999999;height: 0.86rem;line-height: 0.86rem;text-align: left;display: inline-block;width: 6.60rem">
                    <div style="display: inline-block;margin-left: 0.3rem">回复时间：</div>
                    <div style="display: inline-block;color: #333333">
                      <span >{{item.PlatformApplyTime}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                      <div v-else style="background-image:url(/img/legalAid/empty1.png);height:3.92rem;width:6.9rem;background-size:100% 100%;margin:1.1rem auto 0;">
<!--                          <p style="color: #ababab;text-align: center;padding-top: 3.7rem;font-size: 0.30rem;">未申请</p>-->
                      </div>
          <div style="padding: 0.2rem"></div>
<!--            <ul v-if="list.length>0">-->
<!--                <li v-for="(item,index) in list" :style="index%2==0?'background-color: #fff;':''" class='list'>-->
<!--                    <Row type="flex" align="middle" style="font-size:0.26rem;padding:0.1rem 0.3rem;">-->
<!--                        <Col span="20">-->
<!--                            <Row>-->
<!--                                <Col span="24" style="color:#666666">-->
<!--                                    申请人：<span v-if="item.Content">{{item.Content["biz_webcase.name"]}}</span> | 申请时间：{{item.CreateTime}}-->
<!--                                </Col>-->
<!--                            </Row>-->
<!--                        </Col>-->
<!--                        <Col span="4" style="font-size:0.23rem;color:#008fd6"><span @click="getDetail(item)">查看详情 ></span></Col>-->
<!--                    </Row>-->
<!--                </li>-->
<!--            </ul>-->
<!--            <div v-else style="background-image:url(/img/legalAid/empty.png);height:4.95rem;width:6.9rem;background-size:100% 100%;margin:1.1rem auto 0;">-->
<!--                <p style="color: #ababab;text-align: center;padding-top: 3.7rem;font-size: 0.30rem;">未申请</p>-->
<!--            </div>-->
            <div style="padding: 30px;font-size: 12px;line-height: 20px;" v-if="pid == 2295">
                <div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">都江堰市法律援助办事指南</div>
                <div style="text-indent: 2em;">法律援助是指由政府设立的法律援助机构组织法律援助人员，为经济困难或特殊案件的人给予无偿提供法律服务的一项法律保障制度。</div>
                <div style="text-indent: 2em;">都江堰市法律援助中心以扶助困难群众为己任，以提供良好的法律服务为目的，认真体现党和政府对困难群众的深切关怀。组织律师、公证员、基层法律服务工作者积极为残疾人、老人、妇女、儿童和农民工等各类困难群体提供法律援助。 同时，为方便群众办理法律援助申请， 我局还在市公安、检察院、法院、劳动仲裁院等部门以及11个乡镇(街道办）依托司法所设立了法律援助工作站。</div>
                <div style="text-indent: 2em;">都江堰市法律援助咨询热线 ：028-87200148</div>
                <div style="text-indent: 2em;">成都市法律援助24小时咨询热线：12348</div>
                <div>一、法律援助对象</div>
                <div>向法律援助机构申请法律援助，应当符合以下条件：</div>
                <div>（一）符合规定的经济困难标准；</div>
                <div>（二）申请事项依法在本行政区域审理或者处理；</div>
                <div>（三）因维护自身合法权益需要无偿法律服务。</div>
                <div>经济困难标准：按照申请人家庭成员人均收入不足住所地或者受理法律援助申请地最低工资标准确定。</div>
                <div>二、申请人有下列情形之一的，免于经济困难审查：</div>
                <div>（一）重点优抚对象（享受抚恤和生活补助的人员）；</div>
                <div>（二）纳入享受最低生活保障待遇范围的城乡低保对象、城乡特困人员、孤儿；</div>
                <div>（三）在社会福利机构由政府供养的或者在救助管理机构接受救助的；</div>
                <div>（四）无固定生活来源的残疾人、老年人、患有严重疾病的人；</div>
                <div>（五）在本市行政区域内务工的农民因请求支付劳动报酬、经济补偿或者工伤赔偿的；</div>
                <div>（六）因遭受自然灾害或者其他不可抗力正在接受社会救济的；</div>
                <div>（七）申请的法律援助事项已经获得公安机关、人民检察院、人民法院司法救助的；</div>
                <div>（八）因见义勇为行为、实施志愿服务行为或者为保护社会公共利益致使其合法权益受到损害请求赔偿或者补偿的；</div>
                <div>（九）义务兵、供给制学员以及军属，执行作战、重大非战争军事行动任务的军人以及军属，烈士、因公牺牲或者病故的军人、人民警察、消防救援人员的遗属；军队中的文职人员、非现役公勤人员、在编职工，由军队管理的离退休人员，以及执行军事任务的预备役人员和其他人员，参照军人条件执行；</div>
                <div>（十）法律法规以及国家政策规定的其他情形。</div>
                <div>三、法律援助的形式</div>
                <div>(一)法律咨询、代拟法律文书;</div>
                <div>(二)刑事辩护、刑事代理、刑事法律帮助;</div>
                <div>(三)民事、行政诉讼代理;</div>
                <div>(四)非诉讼法律事务代理;</div>
                <div>(五) 已经受理的法律援助案件需要进行的公证、司法鉴定；</div>
                <div>(六) 法律法规规定的其他形式。</div>
                <div>四、申请法律援助应当提交下列材料：</div>
                <div>（一）身份证或者其他有效身份证明，如委托他人代理的，代理人还应当提交有代理权的证明；</div>
                <div>（二）经济困难证明或者能够证明其经济困难的其他材料；</div>
                <div>（公民申请法律援助需要提交的经济困难证明，由其户籍所在地或者经常居住地的街道办事处、镇人民政府出具，或者由申请人采取书面形式作出承诺。经济困难证明应当如实载明申请人家庭成员、就业状况、家庭人均收入等情况。）</div>
                <div>(三)与申请事项相关的案件材料;</div>
            </div>
            <div  v-if="pid == 1731" style="padding: 30px;font-size: 12px;line-height: 20px;">
                <div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">法律援助</div>
                <div style="text-indent: 2em;">法律援助是政府的职责，是政府设立的为经济困难公民或特殊案件的当事人提供法律援助，依法保障确有经济困难的弱势群体获得必要法律服务，维护公民的合法权益的法律保障制度。</div>
                <div style="text-indent: 2em;">公民只有符合法定的条件，才能获得法律援助。提出法律援助申请必须符合两个基本条件：一是属于可以申请的法律援助事项范围，即案件必须符合规定的条件；二是因经济困难无力支付法律服务费用，即申请人必须符合经济困难的条件。另外，一些特殊案件的当事人，主要是刑事案件的犯罪嫌疑人和被告人，可以不经申请，由人民法院直接指定获得法律援助。 </div>
                 <div style="text-indent: 2em;">谷城县法律援助中心主要负责接待、解答来电、来访法律咨询，办理民事、行政诉讼代理，刑事辩护，非诉讼法律事务。负责受理公民申请的法律援助案件和人民法院指定的刑事法律援助案件，组织、协调、指派律师和其它法律工作者承办法律援助案件。掌握法律援助工作中涉及的社情民意，为党委、政府决策提供依据。办理或协调处理其他法律援助事务。</div>
                <div>法律援助咨询热线：0710-7335148</div>
            </div>
        </div>
      <Modal
          v-model="modal"
          title="温馨提示"
          ok-text="继续" cancel-text="" :mask-closable="false"
          @on-cancel="cancelButton" @on-ok="goApply">


        <p>1.法律援助是国家为经济困难公民和符合条件的特殊案件当事人免费提供的法律咨询、涉法事务代理、刑事辨护、法律帮助等服务。</p>
        <p>2.如要获得涉法事务代理、刑事辩护等法律援助，必须先提出申请，经法律援助中心审查符合法律规定的事项和经济困难条件。</p>
        <p>3.法律援助申请，可以到法律援助中心现场提交，也可通过12348重庆法网（网站、公众号和APP）、重庆村居法律顾问小程序等网络渠道提交。</p>
        <p>4.由以上网络渠道提交法律援助申请，填写的信息务必真实、准确、完整，否则可能导致信息传递失败或者延迟，影响法律援助中心审查进度和结果。</p>


      </Modal>
      <!--     电话 -->
      <div style="padding: 0 0.3rem;font-size: 0.22rem;line-height: 0.36rem;" v-if="hotlineData != ''">
        <div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">法律援助热线电话咨询</div>
        <div>
          <div style="display: inline-block">
            <div>地址：{{hotlineData.address}}</div>
            <div>联系电话：{{hotlineData.phone}}</div>
          </div>
          <div style="float: right;display:inline-block;width: 1.80rem;height: 0.64rem;background: linear-gradient(90deg, #64C3FF, #3391FE);box-shadow: 0px 0.04rem 0.06rem 0px rgba(53,148,255,0.26);border-radius: 0.32rem;">
            <img style="width:0.32rem;height:0.32rem;margin:0.16rem 0.10rem 0.16rem 0.18rem;vertical-align: top;" src="https://ai-cdn.lvwa18.com/img/rusticate/notarizationDH.png"/>
            <span style="font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.64rem;" @click="dialTelephone(hotlineData.phone)" >一键拨打</span>
          </div>
        </div>
      </div>
      <!--      去申请-->
      <div style="height: 0.8rem"></div>
      <Row>
        <div @click="modal = true" ><Col span="24" style="text-align: center;background-color:#008fd6;height: 0.8rem;font-size: 0.26rem;line-height: 0.8rem;position: fixed;bottom: 0;width: 100%;">
          <span style="color: #FFF;">线上办理</span>
        </Col></div>
      </Row>
    </div>
</template>

<script>
    export default{
        name:'legalAidList',
        data(){
            return{
                list:[],
                FlyzApplyId : '',
                modal3 : false,
                resultReply : [],
                detialPanel:{
                    state:false,
                    data:{},
                    Content:[],
                    result:{},
                },
                pid : 0,
              modal : false,
              hotlineData: [],

            }
        },
        mounted(){
            var _this = this
          SYSCONFIG.server.default.host = 'cq.lvwa18.com'
          SYSCONFIG.server.default.port = '8001'
          window.sessionStorage.url = 'https://cq.lvwa18.com:8001/'
            _this.setPageSize()
            if(window.sessionStorage.userId == undefined){
                if( _this.$route.query.id){
                    window.sessionStorage.userId = _this.$route.query.id
                    _this.getList()
                    window.sessionStorage.AreaId = _this.$route.query.AreaId
                    window.sessionStorage.pid = _this.$route.query.pid
                    window.sessionStorage.userphone = _this.$route.query.userphone
                    _this.pid =window.sessionStorage.pid
                    console.log(window.sessionStorage)
                }else{
                    _this.$Modal.error({
                        title:'系统通知',
                        content:'未获取到用户信息！请重新进入！',
                    })
                }
            }else{
                _this.getList()
                if( _this.$route.query.id){
                    window.sessionStorage.userId = _this.$route.query.id
                    window.sessionStorage.AreaId = _this.$route.query.AreaId
                    window.sessionStorage.pid = _this.$route.query.pid
                  window.sessionStorage.userphone = _this.$route.query.userphone
                    _this.pid =window.sessionStorage.pid
                }
            }
          this.aidInfo()
        },
        methods:{
          aidInfo: function (){
            var _this = this
            _this.send({
              url : '/aidInfo',
              data: {
                province : '重庆市',
                city : '重庆城区',
                area : '',
              },
              success: function (res) {
                if(res){
                  _this.hotlineData = res
                }
              }
            })
          },
          cancelButton : function (){
            this.$router.go(-1)
          },
            result : function(){
                var _this = this
                _this.send({
                    url : '/flyzApply_resAdmin',
                    data: {
                        FlyzApplyId : _this.FlyzApplyId,
                        UserId : window.sessionStorage.userId,
                    },
                    success: function (res) {
                        if(res.code != 500){
                            _this.resultReply = res.data
                        }else{
                            _this.resultReply = []
                        }

                    }
                })
            },
            getList:function(){
                var _this = this
                _this.send({
                    consulting: true,
                    url:'/flyzApplyList',
                    data:{
                        UserId:window.sessionStorage.userId,
                    },
                    success:function(res){
                      if(res){
                        _this.list = res.List
                      }
                    }
                })
            },
            getDetail:function(item){
                var _this = this
                console.log(item.Content)
                _this.detialPanel.state = true
                _this.detialPanel.data = item
                _this.detialPanel.Content = item.Content
                return
                _this.FlyzApplyId = item.FlyzApplyId
                _this.send({
                    url:'/flyzApply_resAdmin',
                    data:{
                        UserId :window.sessionStorage.userId,
                        FlyzApplyId :item.FlyzApplyId,
                    },
                    success:function(res){
                        _this.detialPanel.state = true
                        _this.detialPanel.data = item
                        _this.detialPanel.result = res.data
                    }
                })
            },
            goApply : function (){
              var _this = this
              _this.modal = false
              setTimeout(function () {
                _this.$router.push({name:'cqslegalAidInput'})
              },50)

            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
    }
</script>
