<template>
  <div>
    <div class="publicWelfare" >
      <Row style="margin: 20px">
        <Col span="12">
          <Input v-model="greffierVillagesData.searchData" placeholder="搜索" style="width: 200px" />
          <Button type="primary" @click="GzyDara(1)">搜索社区</Button>
          <Button type="primary" @click="GzyDara(2)" style="margin-left: 30px">搜索社区公证员</Button>
        </Col>
        <Col span="12">
          <!--              <Button type="primary" @click="communityShow = true" style="margin-left: 30px">添加社区</Button>-->
        </Col>
      </Row>
      <table class="formtable">
        <thead>
        <tr>
          <th>序号</th>
          <th>村（社区）</th>
          <th>姓名</th>
          <!--          <th>服务机构</th>-->
          <th>创建时间</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, index) in greffierVillagesData.data">
          <td v-if="(greffierVillagesData.page - 1) * pageSize + index + 1 < 10">
            0{{ (greffierVillagesData.page - 1) * pageSize + index + 1 }}
          </td>
          <td v-else>{{ (greffierVillagesData.page - 1) * pageSize + index + 1 }}</td>
          <td>
            {{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}
            <!--            <samp style="padding: 10px;"></samp>用户数量：（{{value.UserCount}}）-->
          </td>
          <td nowrap="nowrap">
            <div
                v-for="(item, i) in value.GreffierArr"
                style="text-align: center; display: inline-block; margin: 0 5px"
            >
              <div  class="del-hover" @click="value.GreffierArr.length>1?changeLawyer(value.GreffierArr,item,value.VillagesId):addGzy(value.VillagesId, '更换社区公证员', 'change')">
                <div class="del-btn">{{value.GreffierArr.length>1?'删除':'更换'}}</div>
                <Avatar :src="item.GreffierImg" />
              </div>
              <div style="margin-top: 4px">{{ item.GreffierName }}</div>
            </div>
          </td>
          <!--          <td>-->
          <!--            <div v-for="(item,i) in value.lawyerArr">-->
          <!--              {{item.GreffierName}}：{{item.OrganizationName}}-->
          <!--            </div>-->
          <!--          </td>-->
          <td>{{ value.CreatTime }}</td>
          <td style="text-align: center">
            <Button type="primary" @click="addGzy(value.VillagesId, '新增社区公证员', '')"
            >新增社区公证员</Button
            >
            <Button
                type="primary"
                @click="addGzy(value.VillagesId, '更换社区公证员', 'change')"
                style="margin-left: 30px"
            >更换社区公证员</Button
            >
            <!--                <Button type="error" @click="addLawyer(value.VillagesId,'删除社区','shanchu')" style="margin-left: 30px">删除社区</Button>-->
            <!--                <Button type="primary" @click="modifyCommunity(value)" style="margin-left: 30px">修改社区</Button>-->
          </td>
        </tr>
        </tbody>
      </table>
      <Page
          :current="greffierVillagesData.page"
          :total="greffierVillagesData.count"
          show-total
          :page-size="greffierVillagesData.pageSize"
          show-elevator
          @on-change="
                    greffierVillagesData.page = $event
                    greffierVillagesList()
                "
      />
    </div>
    <!--      更换顾问-->

    <!--     公证员更换公证员 - 新增公证员 -->
    <Modal
        :draggable="true"
        v-model="greffierVillagesData.modal"
        :title="title"
        @on-ok="determineGzy"
        @on-cancel="cancel"
        width="530"
    >
      <Select
          v-model="greffierVillagesData.model"
          filterable
          multiple
          @on-query-change="searchGzy"
          class="choiceLawyer"
          placeholder="请输入社区公证员名称或电话"
      >
        <Option v-for="item in greffierVillagesData.GzyList" :value="item.GreffierId" :key="item.GreffierId"
        >{{ item.GreffierName }}{{ item.GreffierPhone }}</Option
        >
        <Page
            :total="greffierVillagesData.totalName"
            show-total
            :page-size="pageSize"
            :current="greffierVillagesData.currentPageName"
            @on-change="handlePageNameGzy"
        ></Page>
      </Select>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'lawyerManagement',
  data() {
    return {
      key: '',
      currentPageName: 1,
      pageSize: 15,
      page: 1,
      count: 0,
      totalName: 0,
      modal: false,
      replaceShow: false,
      replaceData: [],
      FirmPageData: [],
      FirmPageCount: 0,
      lawFirmPage: 1,
      searchFirm: '',
      lawyerPhone: '',
      lawyerName: '',
      OrganizationId: '',
      //  村社区管理
      modifycommunityShow: false,
      // Modal标题
      title: '',
      communitySingleData: {},
      administrationData: [],
      searchData: '',
      CommunityData: [],
      modal2: false,
      addressData: [],
      communityShow: false,

      model1: [],
      community: {
        VillagesName: '',
        LawyerId: '',
        AdminId: [],
        ProvinceId: '',
        CityId: '',
        AreaId: '',
        CityName: ''
      },
      familyaddress: [],
      modal1: false,
      //  添加律所
      organizationSearch: '',
      lawFirmData: [],
      lawFirmCount: 0,
      lawFirmShow: false,
      value1: '',
      addData: {},
      listData: [],
      LawyerLpage1: 1,
      addlawFirmData: [],
      modal4: false,
      cq: '',
      greffierVillagesData: {
        data: [],
        page: 1,
        count: 0,
        pageSize: 15,
        Key: '',
        modal: false,
        searchKey: '',
        currentPageName: 1,
        totalName: 0,
        GzyList: [],
        searchData: '',
        model: [],
        GreffierName: '',
        search: ''
      },
    }
  },
  methods: {
    changeLawyer(GreffierArr,item,villageId){
      var _this = this
      let arr = []
      GreffierArr.map(GreffierArritem=>{
        if(GreffierArritem.GreffierId==item.GreffierId){
          return
        }else{
          arr.push(GreffierArritem.GreffierId)
        }
      })
      console.log(arr)
      _this.send({
        url: '/ChangeGreffierSer',
        confirm:true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          GreffierArrId: arr.toString(),
          VillagesId:villageId,
          type: 'change'
        },
        success: function (res) {
          _this.$Message.success('操作成功')
          _this.greffierVillagesList()
        }
      })
    },
    replaceLawyer: function (e) {
      this.replaceShow = true
      this.lawyerName = e.LawyerName
      this.replaceData = e
      this.lawyerPhone = e.LawyerPhone
      this.FirmPageData = [e]
      this.OrganizationId = e.OrganizationId
    },
    lawFirmsearch: function (e) {
      this.searchFirm = e
      this.lawFirmPage = 1
      this.FirmPageCount = 0
      // this.FirmPageData = []
      this.serachOrganization()
    },
    serachOrganization: function () {
      var _this = this
      _this.send({
        url: '/AdminOrganizationList',
        data: {
          AdminId: window.sessionStorage.AdminId,
          key: _this.searchFirm,
          page: _this.lawFirmPage,
          pageSize: _this.pageSize
        },
        success: function (res) {
          _this.FirmPageData = res.list
          _this.FirmPageCount = res.count
        }
      })
    },
    lawFirmdropDown: function (e) {
      if (e) {
        this.searchFirm = ''
        this.OrganizationId = ''
        this.serachOrganization()
      }
      // console.log(this.OrganizationId)
    },
    lawFirmTurnPages: function (e) {
      // console.log(e)
      this.lawFirmPage = e
      this.serachOrganization()
    },
    //关闭弹出
    cancel: function () {
      var _this = this
      _this.OrganizationId = ''
      _this.lawyerName = ''
      _this.lawyerPhone = ''
      // console.log(_this.OrganizationId)
    },
    //  村社区管理
    addLawyer: function (e, title, type) {
      var _this = this
      if (type == 'shanchu') {
        _this.send({
          url: '/RemoveVillages',
          confirm: true,
          data: {
            VillagesId: e
          },
          success: function (res) {
            // console.log(res)
            _this.$Message.success('操作成功')
            _this.CommunityData = []
            _this.adminVillagesList()
          }
        })
      } else {
        _this.VillagesId = e
        _this.title = title
        _this.modal1 = true
        _this.type = type
      }
    },
    onmodifyCommunity: function () {
      var _this = this
      if (this.communitySingleData.AdminId[this.communitySingleData.AdminId.length - 1] == undefined) {
        var AdminId = this.communityAdminId
      } else {
        var AdminId = this.communitySingleData.AdminId[this.communitySingleData.AdminId.length - 1]
      }
      _this.send({
        url: '/EditVillages',
        data: {
          AdminId: AdminId,
          VillagesId: _this.communitySingleData.VillagesId,
          VillagesName: _this.communitySingleData.VillagesName,
          CityName: _this.community.CityName,
          ProvinceId: _this.communitySingleData.familyaddress[0],
          CityId: _this.communitySingleData.familyaddress[1],
          AreaId: _this.communitySingleData.familyaddress[2]
        },
        success: function (res) {
          _this.$Message.success('操作成功')
          _this.model1 = []
          _this.adminVillagesList()
        }
      })
      // console.log(AdminId)
    },
    searchDara: function (type) {
      var _this = this
      if (type == 1) {
        _this.search = _this.searchData
        _this.LawyerName = ''
      } else {
        _this.search = ''
        _this.LawyerName = _this.searchData
      }
      _this.adminVillagesData = []
      _this.CommunityData = []
      _this.count = 0
      _this.page = 1
      _this.adminVillagesList()
    },
    adminTreeList: function () {
      var _this = this
      var data = []
      _this.send({
        url: '/AdminTreeList',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          for (var i in res.list) {
            data.push(res.list[i])
          }
          convertTree(data)
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let { AdminId: value, AdminName: label, list: children } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                children
              })
            })
            _this.administrationData = result
            return result
          }
        }
      })
    },
    adminVillagesList: function () {
      //   更换VillageListByPage
    },
    modifyCommunity: function (value) {
      var _this = this
      // console.log(value)
      _this.communitySingleData = value
      _this.communitySingleData.familyaddress = [value.ProvinceId, value.CityId, value.AreaId]
      _this.communityAdminId = value.AdminId
      _this.communitySingleData.AdminId = value.AdminId
      _this.modifycommunityShow = true
    },
    onCapture(value) {
      console.log(value)
    },
    onSelect(value) {
      console.log(value)
    },
    AreaList: function () {
      var _this = this
      var data = []
      _this.send({
        url: '/AreaList',
        success: function (res) {
          for (var i in res) {
            data.push(res[i])
          }
          convertTree(data)
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let { AreaId: value, AreaName: label, AreaCode: AreaCode, list: children } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                AreaCode,
                children
              })
            })
            _this.addressData = result
            return result
          }
        }
      })
    },
    onCommunity: function () {
      var _this = this
      this.community.LawyerId = _this.model1.toString()
      this.community.AdminId = this.community.AdminId[this.community.AdminId.length - 1]
      // this.community.AdminId = window.sessionStorage.AdminId
      // console.log(this.community)
      _this.send({
        url: '/AddVillages',
        data: _this.community,
        success: function (res) {
          _this.$Message.success('操作成功')
          _this.model1 = []
          _this.VillagesName = ''
          _this.community.familyaddress = ''
          _this.page = 1
          _this.adminVillagesList()
        }
      })
    },
    handlePageName: function (e) {
      // console.log(e)
      this.currentPageName = e
      this.serachLawyer()
    },
    addressSelect: function (value, selectedData) {
      console.log(value, selectedData)
      if (value[1] == 2262 || value[1] == 2237) {
        this.community.ProvinceId = value[0]
        this.community.CityId = value[1]
        this.community.AreaId = value[2] || ''
        this.community.CityName = selectedData[0].label + selectedData[2].label
      } else {
        this.community.ProvinceId = value[0]
        this.community.CityId = value[1]
        this.community.AreaId = value[2] || ''
        if (selectedData.length == 3) {
          this.community.CityName = selectedData[0].label + selectedData[1].label + selectedData[2].label
        } else {
          this.community.CityName = selectedData[0].label + selectedData[1].label
        }
      }
      // console.log(this.community)
    },
    onAdministration: function (value, selectedData) {
      console.log(value[value.length - 1])
    },
    // 添加律所
    adminOrganizationList: function (value) {
      var _this = this
      if (value != undefined) {
        _this.organizationPage = 1
        _this.lawFirmCount = 0
      }
      _this.send({
        url: '/AdminOrganizationList',
        data: {
          AdminId: window.sessionStorage.AdminId,
          key: _this.organizationSearch,
          page: _this.organizationPage,
          pageSize: _this.pageSize
        },
        success: function (res) {
          _this.lawFirmData = res.list
          _this.lawFirmCount = res.count
        }
      })
    },
    lawList: function () {
      console.log(this.lawFirmData[this.value1[0]].OrganizationId)
      var _this = this
      _this.send({
        consulting: true,
        url: '/LawyerListById',
        data: {
          OrganizationId: _this.lawFirmData[this.value1[0]].OrganizationId,
          page: _this.LawyerLpage1,
          pageSize: 5
        },
        success: function (res) {
          if (res) {
            _this.listData = res.List
            _this.count = res.count
          } else {
            _this.listData = []
            _this.count = 0
          }
        }
      })
    },
    addlawFirm: function () {
      var _this = this
      // console.log(_this.addlawFirmData.AdminId)
      _this.send({
        url: '/AddMember',
        data: {
          AdminId: _this.addlawFirmData.AdminId[_this.addlawFirmData.AdminId.length - 1],
          AdminLogin: _this.addlawFirmData.AdminLogin,
          AdminPassword: _this.addlawFirmData.AdminPassword,
          AreaId: _this.addlawFirmData.AreaId[_this.addlawFirmData.AreaId.length - 1], //（区域id）,
          Level: 3, // （固定）,
          OrganizationLinkman: _this.addlawFirmData.OrganizationLinkman,
          OrganizationName: _this.addlawFirmData.OrganizationName,
          OrganizationPhone: _this.addlawFirmData.OrganizationPhone
        },
        success: function (res) {
          _this.addlawFirmData = []
          _this.$Message.success('操作成功')
          _this.adminOrganizationList()
        }
      })
    },
    //公证员
    greffierVillagesList: function () {
      var _this = this
      _this.send({
        url: '/GreffierVillagesList',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: '4',
          page: _this.greffierVillagesData.page,
          pageSize: _this.greffierVillagesData.pageSize,
          key: _this.greffierVillagesData.search,
          GreffierName: _this.greffierVillagesData.GreffierName,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime,
          sort: _this.sort
        },
        success: function (res) {
          _this.greffierVillagesData.data = res.List
          _this.greffierVillagesData.count = res.Count
        }
      })
    },
    addGzy: function (e, title, type) {
      var _this = this
      if (type == 'shanchu') {
        _this.send({
          url: '/RemoveVillages',
          confirm: true,
          data: {
            VillagesId: e
          },
          success: function (res) {
            // console.log(res)
            _this.$Message.success('操作成功')
            _this.CommunityData = []
            _this.adminVillagesList()
          }
        })
      } else {
        _this.VillagesId = e
        _this.title = title
        _this.greffierVillagesData.modal = true
        _this.type = type
      }
    },
    serachGzy: function (value) {
      var _this = this
      // console.log(value)
      if (value != undefined) {
        _this.currentPageName = 1
        _this.totalName = 0
      }
      _this.send({
        url: '/SerachGreffierByKey',
        data: {
          AdminId: window.sessionStorage.AdminId,
          key: _this.greffierVillagesData.searchKey,
          page: _this.greffierVillagesData.currentPageName,
          pageSize: _this.pageSize
        },
        success: function (res) {
          _this.greffierVillagesData.GzyList = res.list
          _this.greffierVillagesData.totalName = res.count
        }
      })
    },
    searchGzy: function (e) {
      this.greffierVillagesData.searchKey = e
      this.greffierVillagesData.currentPageName = 1
      this.greffierVillagesData.totalName = 0
      this.greffierVillagesData.GzyList = []
      this.serachGzy()
    },

    handlePageNameGzy: function (e) {
      // console.log(e)
      this.greffierVillagesData.currentPageName = e
      this.serachGzy()
    },

    determineGzy: function () {
      var _this = this
      if (_this.type == 'genghuan') {
        _this.send({
          url: '/EditGreffierSer',
          data: {
            GreffierArrId: _this.greffierVillagesData.model.toString(),
            VillagesId: _this.VillagesId
          },
          success: function (res) {
            _this.$Message.success('操作成功')
            _this.greffierVillagesData.model = []
            _this.greffierVillagesList()
          }
        })
      } else {
        _this.send({
          url: '/ChangeGreffierSer',
          data: {
            AdminId: window.sessionStorage.AdminId,
            GreffierArrId: _this.greffierVillagesData.model.toString(),
            VillagesId: _this.VillagesId,
            type: _this.type
          },
          success: function (res) {
            _this.$Message.success('操作成功')
            _this.greffierVillagesData.model = []
            _this.greffierVillagesList()
          }
        })
      }
    },
    GzyDara: function (type) {
      var _this = this
      if (type == 1) {
        _this.greffierVillagesData.search = _this.greffierVillagesData.searchData
        _this.greffierVillagesData.GreffierName = ''
      } else {
        _this.greffierVillagesData.search = ''
        _this.greffierVillagesData.GreffierName = _this.greffierVillagesData.searchData
      }
      _this.greffierVillagesData.CommunityData = []
      _this.greffierVillagesData.count = 0
      _this.greffierVillagesData.page = 1
      _this.greffierVillagesList()
    }
  },
  mounted() {
    var _this = this
    //  村社区管理
    _this.greffierVillagesList()
    _this.serachGzy()
    _this.adminTreeList()
    _this.AreaList()
    //
    _this.serachOrganization()
    if (SYSCONFIG.location == 'chong-qing') {
      _this.cq = 'cq'
    }
  }
}
</script>

<style scoped>
.forMcontent {
  width: calc(100% - 80px);
  height: 60px;
  color: #666666;
  font-size: 16px;
  margin: 0px 40px 0 40px;
  border-bottom: 1px solid #dddddd;
  text-align: center;
}
.forMcontent p {
  line-height: 60px;
  display: inline-block;
  width: 50%;
}
.forMtitle1 p {
  display: inline-block;
  color: #333333;
  font-size: 16px;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
  width: 20%;
}
.forMtitle {
  width: calc(100% - 80px);
  background-color: #f9f9f9;
  margin: 40px 40px 0 40px;
  height: 60px;
}
.forMtitle p {
  display: inline-block;
  color: #333333;
  font-size: 16px;
  width: 50%;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
}
.administration {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #dddddd;
  height: 80px;
  text-align: left;
  margin-bottom: 0;
}
.administration p {
  font-size: 20px;
  color: #333333;
  display: inline-block;
  line-height: 80px;
}
.label {
  width: 6px;
  height: 24px;
  vertical-align: top;
  margin: 28px 20px 0 20px;
}
.addTo {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: #00b86c;
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  float: right;
  line-height: 40px;
  margin: 20px 40px 0 0;
  cursor: pointer;
}
.inputWord {
  color: #333333;
  font-size: 16px;
  border: 0;
  text-align: left;
  height: 66px;
  margin-left: 20px;
  width: 350px;
}
.contacts {
  font-size: 16px;
  color: #999999;
  display: inline-block;
  height: 66px;
  line-height: 66px;
}
.single {
  width: 100%;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  display: inline-block;
}

.choiceLawyer :deep(.ivu-select-dropdown ){
  max-height: none;
}
.choiceLawyer :deep(.ivu-page ){
  margin-left: 30px;
}
.choiceLawyer {
  width: 380px;
}
.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table tbody th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: center;
}
.publicWelfare table thead th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: center;
}
/*.publicWelfare{margin: 30px}*/
.placeholderColor /deep/ input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #515a6e;
}
.placeholderColor /deep/ input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #515a6e;
}
.placeholderColor /deep/ input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #515a6e;
}
.placeholderColor /deep/ input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #515a6e;
}
.del-hover{position:relative;border-radius:48px;overflow: hidden;display:inline-block;color:#fff}
.del-hover:hover .del-btn{display:block}
.del-btn{position:absolute;top:0;bottom:0;left:0;right:0;background-color:rgba(0,0,0,0.5);line-height:30px;z-index:2;display:none;cursor:pointer}
</style>
