<style scoped>
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
  .details p{color: #000000}
  .details span{color: #5c6b77}
</style>

<template>
  <div>
    <div style="padding:15px"></div>
    <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px">
      社区矫正 - 查看试卷
    </div>
    <div style="padding:15px"></div>
              <div class="publicWelfare" >
                  <component
                    style="background-color: #FFF;margin-bottom:20px;padding:15px;font-size:16px"
                    v-for="(item,index) in examContent"
                    :key="index"
                    :is="item.name"
                    :indexQ="index"
                    :contentIn="item.content"
                    @change="item.content=$event"
                    state="grade">
                  </component>
              </div>
              <div><Button type="success" @click="saveExam">保存分数</Button></div>
              <div style="text-align: left;margin-left: 40px;font-size: 16px;padding-bottom: 20px">
                  <!-- <Page
                    :total="count"
                    show-total
                    :page-size="pageSize"
                    show-elevator
                    @on-change="page = $event;adminflyzApplyList()"
                  /> -->
              </div>
  </div>
</template>

<script>
  import examRadio from '@/components/exam/examRadio'
  import examInput from '@/components/exam/examInput'
  import examTof from '@/components/exam/examTof'
  import examTextarea from '@/components/exam/examTextarea'
  import examCheckbox from '@/components/exam/examCheckbox'
  export default {
      name: "mediateList",
      data () {
          return {
              information : [],
              legalAidData : [],
              count : '0',
              pageSize : 10,
              page : 1,
              modal1 : false,
              detailsData : [],
              sfclmc : '',
              examContent:[],
          }
      },
      methods : {
        saveExam:function(){
          var _this = this
          _this.send({
            url:'/DealUserExam',
            data:{
              UserExamId:_this.$route.query.examId,
              ExamContent:_this.examContent
            },
            success:function(res){
              _this.$Notice.success({
                title:'系统提示',
                desc:'数据更新成功！'
              })
            },
          })
        },
        getExamCon:function(){
          var _this = this
          _this.send({
            url:'/UserExamContentAdmin',
            data:{
              UserExamId:_this.$route.query.examId,
            },
            success:function(res){
              _this.examId = res.ExamId
              _this.examContent = res.ExamContent
            },
          })
        },

          see : function (e) {
              console.log(e)
              this.detailsData = e
          },
          sfclmcImg : function (e) {
              this.sfclmc = e
          }
      },
      mounted (){
          var _this = this
          if(window.sessionStorage.adminLevel == undefined){
              // this.$router.push({name:'login'})
          }else {
              this.information = window.sessionStorage
              _this.getExamCon()
          }
      },
      components:{
        examRadio:examRadio,
        examInput:examInput,
        examTof:examTof,
        examTextarea:examTextarea,
        examCheckbox:examCheckbox,
      },
  }
</script>


