<style scoped>
.head-title{font-size: 0.36rem;color: #333333;line-height: 0.88rem;display: inline-block; vertical-align: top;}
.return{width: 0.16rem;height: 0.3rem;display: inline-block;vertical-align: top;float: left;margin-top: 0.29rem;margin-left: 0.3rem}
.switch{font-size: 0.3rem;border-bottom: solid 0.01rem #d9d9d9;background-color: #f8f8f8;height: 1rem;width: 7.24rem;line-height: 1rem;padding: 0 0.13rem;}
.label{color: #666666;width: 1.6rem;text-align: center;margin: 0 0.1rem;}
.selectedc1{border-bottom: 0.02rem solid #438bff;color: #333333;}
.name{font-size: 16px;vertical-align: top;width: 5.77rem;white-space: nowrap;overflow: hidden;}
.option{background-color: #ffffff;width: 7.5rem;height: 1.1rem;font-size: 0.28rem;line-height: 1.1rem;margin-top: 0.3rem;}
.document-img{width: 0.36rem;height: 0.36rem;margin: 0.39rem 0.18rem 0.35rem 0.30rem;}
.xiazai{width: 0.8rem;height: 0.8rem;float: right;margin-top: 0.3rem;}
.filescontent{color: #000000;font-size: 0.2rem;font-weight:bold;    margin: 0rem 0.6rem 1rem;line-height: 0.6rem;    overflow: hidden;}
.filescontent h1{font-size: 0.36rem;color: #333333;    font-weight: 400;}
.download{width: 0.37rem;height: 0.36rem;vertical-align: top;margin: 0.12rem 0rem 0.12rem 0.18rem;}
.gray{background-color: #dfdfdf;width: 1.4rem;height: 0.60rem;font-size: 0.3rem;line-height: 0.6rem;}
.sendout{width: 3.6rem;height: 1rem;background-color: #ff9c00;font-size: 0.3rem;line-height: 1rem;border-radius:0.5rem;color: white}
.fasong{width: 0.32rem;height: 0.36rem;vertical-align: top;margin: 0.32rem 0.05rem 0.32rem 0.81rem; }
.mailbox{width: 6rem;height: 3.78rem;background-color: white;position: fixed;top: 1.5rem;font-size: 0.3rem;text-align: center;left: 50%; margin-left: -3rem;z-index: 100}
.cancel{width: 2.3rem;height: 0.72rem;line-height: 0.72rem;}
.inputmailbox{width: 4.8rem;height: 0.8rem;border: 0.01rem solid rgb(221,221,221);margin-top: 0.4rem;margin-bottom: 0.31rem;font-size: 0.3rem;}
.youxiang{width: 0.45rem;height: 0.36rem;vertical-align: top;margin-top: 0.27rem;}
.sign{border-radius:0.45rem;width: 0.9rem;height: 0.9rem;border: 0.01rem solid rgb(221,221,221);position: absolute;top: -0.45rem;background-color: white;left: 50%;margin-left: -0.45rem;}
.transparent{top: 0;background-color: #000000;opacity: 0.5;width: 7.5rem;height: 100%;position: fixed;z-index: 99;}
.prohibit {overflow-x: scroll;overflow-y: hidden;height: 100%;position: fixed;}

.preservationButtom{width: 50%;height: 1rem;background-color: #ffffff;font-size: 0.3rem;color: #989898;text-align: center;line-height: 1rem;position: absolute;bottom: 0;}
.generateButtom{width: 50%;height: 1rem;background-color: #4195ff;font-size: 0.3rem;color: #fff;text-align: center;line-height: 1rem;position: absolute;bottom: 0;right: 0;}
</style>
<template>
  <div>
    <div id="demo"></div>
    <div style="position: fixed;bottom: 0;width: 100%;" >
      <div class="preservationButtom" @click="download('down')">
        <p class="inline-block">预览</p>
      </div>
      <!--<router-link :to="{name:'documentDownload',query:{type:3}}">-->
      <div class="generateButtom" @click="sendEmail()">
        <p class="inline-block">发送邮箱</p>
      </div>
      <!--</router-link>-->
    </div>
<!--    弹出-->
    <div class="transparent" v-if="show" @click="show = !show"></div>
    <div class="mailbox" v-if="show">
      <div class="sign"><img src="/img/pc/youxiang.png" class="youxiang"></div>
      <p style="color: rgb(0, 0, 0); margin-top: 0.7rem;">— 填写邮箱地址 —</p>
      <input type="text" id="aa" class="inputmailbox" v-model="inputmailbox">
      <div class="inline-block cancel" style="border: 0.01rem solid rgb(221, 221, 221); color: rgb(0, 0, 0);"
           @click="show = !show">
        取消
      </div>
      <div class="inline-block cancel"
           style="background-color: rgb(66, 129, 245); color: rgb(255, 255, 255); margin-left: 0.1rem;"
           @click="download('send')">
        确定
      </div>
    </div>
  </div>
</template>

<script>
import Pdfh5 from "pdfh5";
import wx from 'weixin-js-sdk'
export default {
  name: "templateStyle",
  data (){
    return {
      inputmailbox : '',
      show: false,
      isShowTool : true
    }
  },
  methods:{
    doc_template : function (){
      var _this = this;
      _this.send({
        url:'/doc_template',
        data:{
          kind: "2",
          type: _this.$route.query.id,
        },
        success:function(res){
          _this.showPdf(res)
        }
      })
    },
    showPdf : function (e){
      var _this = this
      if(SYSCONFIG.location == 'chong-qing'){
        var url = '/ShowPdf'
      }else{
        var url = '/CD_ShowPdf'
      }
      _this.pdfh5 = new Pdfh5("#demo", {
        pdfurl: window.sessionStorage.url + 'api' + url + '?FilePath='+ e
      });
    },
    setPageSize:function() {
      var wsw = document.body.clientWidth
      document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
    },
    sendEmail: function () {
      if(this.isShowTool == false) {
        this.$Message.warning('请先升级为VIP会员才能享受此功能。');
        return
      }
      this.show = !this.show
    },
    download: function (type) {
      var _this = this
      if(this.isShowTool == false) {
        this.$Message.warning('请先升级为VIP会员才能享受此功能。');
        return
      }
      _this.send({
        consulting1 : true,
        url: '/send_down',
        data: {
          kind: type,//下载
          id: _this.$route.query.id,//第几个数据页
          emails: _this.inputmailbox,//邮箱内容element
          UserId : _this.$route.query.userid,
          LawyerId : _this.$route.query.lawyerid,
        },
        success: function (res) {
          console.log(res)
          if (type == 'down') {
            console.log(`/pages/userSide/download/download?url=`+window.sessionStorage.url+`${res}`+'&id='+_this.$route.query.id+'')
            wx.miniProgram.redirectTo({
              url: `/pages/userSide/download/download?url=`+window.sessionStorage.url+`${res}`+'&id='+_this.$route.query.id+''
            })
          }
          if(res.code == 200){
            _this.$toast('发送成功，请注意查阅');
            _this.show = !_this.show
          }
          if(res.code == 100){
            _this.$toast.fail('请输入正确的邮箱！');
          }
          if(res.code == 101){
            _this.$toast.fail('邮箱错误，请重新输入')
          }
        },
        error:function (err) {
          _this.$toast.fail('网络繁忙！');
        }
      })
    },
  },
  mounted(){
    this.doc_template()
    this.setPageSize()
    if(SYSCONFIG.location == 'chong-qing'){
      window.sessionStorage.url = 'https://cq.lvwa18.com:8001/'
    }else{
      window.sessionStorage.url = 'https://vn-server.lvwa18.com/'
    }
    if(this.$route.query.isShowTool){
      this.isShowTool = false
    }
    // window.sessionStorage.url = 'https://cq.lvwa18.com:8001/'
    // SYSCONFIG.server.default.host = 'cq.lvwa18.com'
    // SYSCONFIG.server.default.port = '8001'
  },
}
</script>

