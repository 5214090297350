<style scoped>
	.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
  .detial-content{border-radius:0.5rem;margin-top:-0.5rem;background-color:#FFF;padding:0.3rem;}
  .content-title{text-align: center;color:#333333;font-size:0.28rem;font-weight:bold;}
  .content-text{color:#888888;font-size:0.24rem;}
  .content-sec-title{color:#008fd6;padding:0 0.3rem 0.3rem 0.3rem;margin:0 -0.3rem;font-size:0.28rem;background-color:#FFF;}
  .content-important-text{font-size:0.26rem;color:#888888}
</style>

<template>
  <div>
    <div class='header'></div>
    <div class="detial-content">
      <div class="content-title">
       {{detial.MediationType}}
      </div>
      <Divider style="margin-bottom:0.3rem;"/>
        <div class="content-sec-title">申请人</div>
        <Row class="content-important-text">
          <Col span="10">
            姓名：<Icon type="md-contact" style="color:#57c5f7;font-size:0.32rem;"/> {{detial.ProposerName}}
          </Col>
          <Col span="14">
            电话：<Icon type="md-phone-portrait" style="color:#57c5f7;font-size:0.32rem;"/>
            {{detial.ProposerPhone}} <Button size="small" type="info" style="padding:0 0.1rem">拨打</Button>
          </Col>
        </Row>
        <Divider style="margin:0.3rem 0;"/>
        <div class="content-sec-title"><Icon type="ios-paper-outline" style="font-size:0.3rem;"/>详细信息</div>
          <Row class="content-text">
            <Col span="8">纠纷描述：</Col>
            <Col span="16" style="text-align: right;">{{detial.UserSummarize}}</Col>
          </Row>
          <Divider style="margin:0.3rem 0;"/>
          <Row class="content-text">
            <Col span="8">民族：</Col>
            <Col span="16" style="text-align: right;">{{detial.ProposerNation}}</Col>
          </Row>
          <Divider style="margin:0.3rem 0;"/>
          <Row class="content-text">
            <Col span="8">性别：</Col>
            <Col span="16" style="text-align: right;">{{detial.ProposerSex}}</Col>
          </Row>
          <Divider style="margin:0.3rem 0;"/>
          <Row class="content-text">
            <Col span="8">出生日期：</Col>
            <Col span="16" style="text-align: right;">{{detial.ProposerBirthdate}}</Col>
          </Row>
          <Divider style="margin:0.3rem 0;"/>
          <Row class="content-text">
            <Col span="8">年龄：</Col>
            <Col span="16" style="text-align: right;">{{detial.ProposerAge}}</Col>
          </Row>
          <Divider style="margin:0.3rem 0;"/>
          <Row class="content-text">
            <Col span="8">身份证号码：</Col>
            <Col span="16" style="text-align: right;">{{detial.ProposerIDCard}}</Col>
          </Row>
          <Divider style="margin:0.3rem 0;"/>
          <Row class="content-text">
            <Col span="8">住所地址：</Col>
            <Col span="16" style="text-align: right;">{{detial.ProposerAddress}}</Col>
          </Row>
    </div>
    <div style="position:fixed;width:100%;text-align:center;bottom:0.4rem">
      <!-- <Button type="error" style="width:3rem;font-size:0.28rem;" @click="setMediateState(4)">被申请人拒绝调节</Button>
      <span style="padding:0.2rem"></span> -->
      <Button type="primary" style="width:3rem;font-size:0.28rem;" :to="{name:'CJYMMR',query:{mediateId:detial.MediationId}}">调解操作</Button>
    </div>
  </div>
</template>

<script>
export default {
  name:'detial',
  data() {
    return {
      detial:{},
    };
 },
  methods: {
    getDetial:function(){
      var _this = this
      _this.send({
        url:'/MediationContent_',
        data:{
          MediationId:_this.$route.query.mediateId,
        },
        success:function(res){
          _this.detial = res
        }
      })
    },
    // setMediateState:function(){
    //   var _this = this
    //   _this.send({
    //     url:'/MediationUpdate',
    //     data:{
    //       MediationId:_this.$route.query.mediateId,
    //       ProcessingId:4,
    //     },
    //     success:function(res){
    //       _this.$router.push({name:'CJYMMR',query:{mediateId:_this.detial.MediationId}})
    //     },
    //   })
    // },
  },
  mounted() {
    this.getDetial()
  },
}
</script>
