<template>
  <div>
    <Row type="flex" justify="space-around" style="background-color: #EAEAEA;padding: 20px 0;">
      <Col span="11" style="background-color: #FFF">
        <div style="height: 70px;font-family: PingFang SC;font-weight: bold;border-bottom: 1px solid #DDDDDD">
          <img src="/img/icon/video.png" style="vertical-align: top;margin: 19px 14px 0 20px">
          <div style="display: inline-block;font-size: 14px;color: #333333;line-height: 70px;">视频</div>
          <Button @click="videoShow = true" type="primary" icon="ios-cloud-upload-outline" style="float: right;margin: 17px 18px">上传</Button>
        </div>
        <Row  v-for="(value,index) in videoList" style="height: 180px;background: #FFF;margin-bottom: 20px">
          <Col span="8" style="text-align: center">
            <img :src="value.ImageUrl"
                 style="width: 180px;height: 140px;background: #FFF;border: 6px solid rgba(255, 255, 255, 0.12);box-shadow: 0px 2px 12px 0px rgba(4, 0, 0, 0.1);border-radius: 4px;margin: 20px 0px 20px 0px">
          </Col>
          <Col span="12">
            <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 14px;vertical-align: top;margin:40px 0 20px;overflow: hidden;text-overflow: ellipsis;-webkit-box-orient: vertical;-webkit-line-clamp: 4;display: -webkit-box;">
              {{value.StudyTitle}}</div>
            <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 14px;">
              上传时间：{{value.CreateTime}}
            </div>
          </Col>
          <Col span="4" style="text-align: center">
<!--                          <img style="width: 14px;height: 16px;vertical-align: top;margin-top: 82px" src="/img/icon/deleteImg.png">-->
            <div @click="fileModify(value)" style="line-height: 180px;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #999999;display: inline-block;cursor: pointer;">修改</div>
          </Col>
        </Row>

        <Page :total="count" :page-size="pageSize" show-elevator show-total
              @on-change="page = $event;aStudyList2(2)" style="margin: 20px"/>
      </Col>
      <Col span="11" style="background-color: #FFF">
        <div style="height: 70px;font-family: PingFang SC;font-weight: bold;border-bottom: 1px solid #DDDDDD">
          <img src="/img/icon/doc.png" style="vertical-align: top;margin: 19px 14px 0 20px">
          <div style="display: inline-block;font-size: 14px;color: #333333;line-height: 70px;">文档</div>
          <Button @click="modal = true" type="primary" icon="ios-cloud-upload-outline" style="float: right;margin: 17px 18px">上传</Button>
        </div>
        <Row style="background: #FFF;" type="flex"  justify="center" >
          <Col v-for="(value,index) in fileList" span="22" style="background: #FAFAFA;text-align: left;height: 42px;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 42px;margin-top: 20px">
            <div style="display: inline-block;margin-left: 20px">
              <span v-if="index < 9">0{{index+1}}</span>
              <span v-else>{{index+1}}</span>
              {{value.StudyTitle}}</div>
<!--            <div style="float: right;vertical-align: top;">-->
<!--              <img style="width: 14px;height: 16px;vertical-align: top;margin-top: 13px" src="/img/icon/deleteImg.png">-->
<!--              <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #999999;display: inline-block;margin-right: 20px;">删除</div>-->
<!--            </div>-->
            <div style="float: right;vertical-align: top;" @click="fileModify(value)">
              <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #999999;display: inline-block;margin-right: 20px;">修改</div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
<!--    上传文件-->
    <Modal v-model="modal" title="上传文档" @on-ok="uploadFile(1)" @on-cancel="closeFile" ok-text="上传" style="height: 300px" draggable
    >
      <Upload
          type="drag"
          :action="information.url+'api/upload_file_H5'"
          　　:on-success="Picture1"
          :default-file-list="StudyUrl">
        <div style="padding: 20px 0">
          上传文件
        </div>
      </Upload>
      <Input v-model="StudyTitle" maxlength="300" show-word-limit placeholder="标题" style="margin: 20px 0"/>
      <Input v-model="StudyContent" maxlength="300" show-word-limit type="textarea" placeholder="内容" />
    </Modal>
<!--    videoShow-->
    <Modal v-model="videoShow" title="上传视频" @on-ok="uploadFile(2)" @on-cancel="closeFile" ok-text="上传" style="height: 300px" draggable
    >

      <div class="upload" v-if="videoShow">上传视频
        <input class="change" ref="input" type="file" @change="handleBeforeUpload">
      </div>
      <div style="width: 100%;overflow:hidden;" v-if="StudyUrl != ''">{{StudyUrl[0].name.substring(StudyUrl[0].name.lastIndexOf('/')+1)}}</div>
      <Upload
          type="drag"
          :action="information.url+'api/upload_file_H5'"
          　　:on-success="Picture" :paste="true" accept="image/gif,image/jpeg,image/png,image/jpg,image/bmp"
          :default-file-list="videoData.Picture" style="margin-top: 20px">
        <div style="padding: 20px 0" >
          <div v-if="videoData.Picture != ''">
            <img style="width: auto;height: 150px;" :src="videoData.Picture[0].url">
          </div>
          <div v-else>视频封面</div>
        </div>
      </Upload>
      <Input v-model="StudyTitle" maxlength="300" show-word-limit placeholder="视频标题" style="margin: 20px 0"/>
      <Input v-model="StudyContent" maxlength="300" show-word-limit type="textarea" placeholder="内容" />
    </Modal>
  </div>
</template>

<script>
import  axios from 'axios'
export default {
name: "learningArea",
  data() {
    return {
      fileList:[],
      videoList:[],
      page : 1,
      page1 : 1,
      pageSize : 15,
      count : 0,
      StudyContent : '',
      StudyTitle : '',
      modal : false,
      StudyUrl : [],
      StudyId : '',
      StudyTypeId : '',
      IsUse: '1',//（是否启用 0 不启用 1 启用 ）
      videoShow : false,
      videoData : {
        Picture : [],
      },
    //  上传视频
    }
  },
  methods:{
    handleBeforeUpload : function (file){
      var _this = this;
      _this.$Spin.show();
      var param = new FormData(); //创建form对象
      param.append('video', file.target.files[0]);//通过append向form对象添加数据
      // param.append('MenuId', _this.menuId);
      var config = {}
      axios.post('https://cq.lvwa18.com:8001/api/UploadVideo', param, config)
          .then(response => {
            console.log(response.data.Data.video[0])
            _this.StudyUrl = [{url:window.sessionStorage.url+response.data.Data.video,name:file.target.files[0].name}]
            _this.videoData.Picture = [{name:window.sessionStorage.url+response.data.Data.image,url:window.sessionStorage.url+response.data.Data.image}]
            _this.$Spin.hide();
          })
    },
    //上传视频源文件
    videoSourceFile : function (e){
      var _this = this
      _this.StudyUrl = [{url:window.sessionStorage.url+e.Data.path,name:e.Data.name}]
    },
    closeFile : function (){
      var _this = this
    _this.StudyTitle = ''
    _this.StudyContent = ''
    _this.StudyTypeId = ''
    _this.StudyUrl = []
    _this.videoData.Picture = []
    },
    fileModify : function (value) {
      if(value.StudyTitle){
        this.StudyTitle = value.StudyTitle
      }
      if(value.StudyContent){
        this.StudyContent = value.StudyContent
      }
      if(value.StudyTypeId){
        this.StudyTypeId = value.StudyTypeId
      }
      if(value.StudyUrl){
        console.log(value.StudyUrl.split())
        this.StudyUrl = [{name:value.StudyUrl,url:value.StudyUrl}]
      }
      if(value.ImageUrl){this.videoData.Picture = [{name:value.ImageUrl,url:value.ImageUrl}]}
      this.StudyId = value.StudyId
      if(value.StudyTypeId == 1){ this.modal = true}
      if(value.StudyTypeId == 2){ this.videoShow = true}
    },
    uploadFile : function (type){
      var _this = this
      console.log(_this.StudyUrl)
      _this.send({
        url: '/AUpdateStudy',
        data: {
          AdminId: _this.information.AdminId,
          StudyTypeId : type || _this.StudyTypeId,
          StudyUrl : _this.StudyUrl[0].url,
          ImageUrl : _this.videoData.Picture[0].url,
          StudyTitle  : _this.StudyTitle ,
          StudyContent : _this.StudyContent,
          StudyId : _this.StudyId,
          IsUse : _this.IsUse
        },
        success: function (res) {
          _this.StudyId = ''
          _this.StudyTitle = ''
          _this.StudyContent = ''
          _this.StudyTypeId = ''
          _this.StudyUrl = []
          _this.videoData.Picture = []
          if(type==1 || _this.StudyTypeId == 1){
            _this.aStudyList1(type|| _this.StudyTypeId)
          }else {
            _this.aStudyList2(type|| _this.StudyTypeId)
          }

        }
      })
    },
    Picture1 : function (e,file){
      var _this = this
      _this.StudyUrl = [{url:window.sessionStorage.url+e.Data.path,name:e.Data.name}]
      _this.videoData.Picture = [{url:window.sessionStorage.url+e.Data.path,name:e.Data.name}]
    },
    Picture : function (e,file){
      var _this = this
      _this.videoData.Picture = [{url:window.sessionStorage.url+e.Data.path,name:e.Data.name}]
    },
    aStudyList1:function (e){
      var _this = this
      _this.send({
        url: '/AStudyList',
        data: {
          AdminId: _this.information.AdminId,
          StudyTypeId : e,
          page: _this.page1,
          pageSize: _this.pageSize,
        },
        success: function (res) {
          if(e == 1){
            _this.fileList = res.List
          }
        }
      })
    },
    aStudyList2:function (e){
      var _this = this
      _this.send({
        url: '/AStudyList',
        data: {
          AdminId: _this.information.AdminId,
          StudyTypeId : e,
          page: _this.page,
          pageSize: _this.pageSize,
        },
        success: function (res) {
          if(e == 2){
            _this.videoList = res.List
            _this.count = res.Count
          }
        }
      })
    },
  },
  mounted() {
    this.information = window.sessionStorage
    this.aStudyList1(1)
    this.aStudyList2(2)
  },
}
</script>

<style scoped>
.upload:hover{border: 1px dashed #4982f3;}
.upload{
  position: relative;    border: 1px dashed #dcdee2;
  text-decoration: none;
  color: #666;width: 100%;height: 70px;text-align: center;line-height: 70px;cursor: pointer;
}
.change{
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
  opacity: 0;cursor: pointer;    width: 100%;
  height: 70px;
}
</style>
