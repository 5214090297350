import { render, staticRenderFns } from "./UnderstanderAdmin.vue?vue&type=template&id=1cb1d970&scoped=true"
import script from "./UnderstanderAdmin.vue?vue&type=script&lang=js"
export * from "./UnderstanderAdmin.vue?vue&type=script&lang=js"
import style0 from "./UnderstanderAdmin.vue?vue&type=style&index=0&id=1cb1d970&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb1d970",
  null
  
)

export default component.exports