<style scoped>
    .ziyuan{width: 0.18rem;height: 0.2rem;vertical-align: top;margin: 0 0.02rem}
    .makeaCall{width: 1.46rem;height: 0.54rem;background: #1186E1;border-radius: 0.27rem;font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;vertical-align: top;color: #FFFFFF;display: inline-block;text-align: center;line-height: 0.54rem}
    .jianjie{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.4rem 0.13rem 0 0.39rem}
    .introduction{width: 6.9rem;
        background: #F7F7F7;
        border-radius: 0.04rem;
        font-size: 0.24rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.42rem;display: inline-block}

    .lawFirm{width: 1.2rem;height: 1.2rem;margin: 0.3rem 0.2rem 0 0.3rem}

    .head-title{font-size: .36rem;color: #333;line-height: .88rem;display: inline-block;vertical-align: top;}
    .return{width: .16rem;height: .3rem;display: inline-block;vertical-align: top;float: left;margin-top: .29rem;margin-left: .3rem;}
</style>

<template>
    <div >
      <div style="background-color: #F5F5F5; height: 0.88rem; text-align: center;position: fixed;top: 0;width: 100%;right: 0;z-index: 9;">
        <img src="/img/zhqy/return.png" class="return" @click="$router.go(-1)">
        <p class="head-title">律所信息</p>
      </div>
      <div style="padding: 0.44rem"></div>
        <div style="margin: 0 0.3rem;font-size: 0;padding-bottom:0.39rem;border-bottom: 0.01rem solid #D9D9D9;">
            <div style="font-size: 0.36rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 1.26rem;">
                {{lawFirmData.lawName}}
            </div>
            <div style="display: inline-block">
                <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem;margin: 0 0 0.2rem">
                    <img src="/img/zhqy/ziyuan.png" class="ziyuan">
                    <div style="display: inline-block">{{lawPhone}}</div>
                </div>
                <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem">
                    <img src="/img/zhqy/dizhi.png" class="ziyuan">
                    <div style="display: inline-block;overflow: hidden;text-overflow: ellipsis;width: 5.15rem;white-space: nowrap;">{{lawFirmData.lawAddress}}</div>
                </div>
            </div>
            <div class="makeaCall" @click="phone">拨打电话</div>
        </div>
        <div style="text-align: center">
            <div style="font-size: 0;line-height: 1.1rem;text-align: left">
                <img src="/img/zhqy/jianjie.png" class="jianjie">
                <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #333;">律所简介</div>
            </div>
            <div class="introduction">
                <div style="margin: 0.3rem 0.37rem 0.29rem 0.28rem;text-align: left">
                    {{lawFirmData.lawIntro}}
                </div>
            </div>
        </div>
        <div style="text-align: center">
            <div style="font-size: 0;line-height: 1.1rem;text-align: left">
                <img src="/img/zhqy/tupian.png" class="jianjie">
                <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #333;">律所图片</div>
            </div>
            <div style="background: #F7F7F7;width: 6.9rem;display: inline-block;font-size: 0;margin-bottom: 0.3rem;">
                <div style="text-align: left;margin: 0.29rem 0 ">
                    <img v-for="(value,index) in lawFirmData.lawImage" :src="value.path" style="width:1.9rem;height: 1.42rem;margin-left: 0.3rem"/>
                </div>
            </div>
        </div>
        <div style="background: #F5F5F5;">
            <div style="margin: 0 0 0 0.3rem;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #BDBDBD;line-height: 1.08rem;">律师共计 <span style="color:#1186E1;">{{count}}</span> 位</div>
            <div style="text-align: center">
                <div style="width: 6.9rem;height: 1.80rem;background: #FFFFFF;border-radius: 0.04rem;display: inline-block;text-align: left;margin-bottom: 0.3rem"
                     v-for="(value,index) in lawyerList" @click="lawyerDetails(value.lawyerId)">
                  <img v-if="value.lawyerImage" :src="value.lawyerImage" class="lawFirm">
                  <img v-else src="/img/zhqy/mrtx.png" class="lawFirm">
                  <div style="display: inline-block;font-weight: 500;width: 5rem;vertical-align: top;">
                    <div style="font-size: 0.28rem;color: #333;line-height: 0.28rem;vertical-align: top;overflow: hidden;text-overflow: ellipsis;width: 4.6rem;white-space: nowrap;margin-top: 0.28rem">{{value.lawyerName}}</div>
                    <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem;margin: 0.22rem 0 0.2rem">
                      <img src="/img/zhqy/ziyuan.png" class="ziyuan">
                      <div style="display: inline-block">{{value.lawPhone}}</div>
                    </div>
                    <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem">
                      <img src="/img/zhqy/top_suoshujigou.png" class="ziyuan">
                      <div style="display: inline-block;overflow: hidden;text-overflow: ellipsis;width: 4.6rem;white-space: nowrap;">{{value.lawName}}</div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "looklawFirm",
        data() {
            return {
                lawFirmData : [],
              lawyerList : [],
              count : '',
                lawPhone : '',
            }
        },
        methods :{
          //律师详情
          lawyerDetails : function(lawyerId){
            var _this = this
            console.log(lawyerId)
            _this.$router.push({name: 'findaLawFirm', query: {lawyerId:lawyerId}})
          },
            lawOfficeLoginOrById : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/LawOfficeLoginOrById',
                    data: {
                        lawOfficeId : _this.$route.query.lawOfficeId
                    },
                    success: function (res) {
                        console.log(res)
                        var reg = /^(\d{3})\d{4}(\d{4})$/;
                        if(res){
                            if(res.lawPhone){
                                _this.lawPhone = res.lawPhone.replace(reg, "$1****$2")
                            }
                            _this.lawFirmData = res
                        }
                    }
                })
              _this.send({
                  consulting : true,
                url: '/WhLawyerList',
                data: {
                  lawOfficeId : _this.$route.query.lawOfficeId
                },
                success: function (res) {
                    if(res){
                        var reg = /^(\d{3})\d{4}(\d{4})$/;
                        for(var i in res.List){
                            if(res.List[i].lawPhone){
                                res.List[i].lawPhone = res.List[i].lawPhone.replace(reg, "$1****$2")
                            }
                        }
                        _this.lawyerList = res.List
                        _this.count = res.count
                    }
                }
              })
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
          phone : function (){
            window.location.href = 'tel://' + this.lawFirmData.lawPhone;
          },
        },
        mounted (){
            var _this = this
            _this.setPageSize()
            _this.lawOfficeLoginOrById()
        }
    }
</script>


