<template>
    <div style="padding: 20px;font-size: 16px;color: #000">
        <h1 style="text-align: center">法律咨询服务合同</h1>
        <!--<p>甲方：成都律军风险管理股份有限公司</p>-->
        <!--<p>法定代表人：冯家辉</p>-->
        <!--<p>住所地：成都市高新区天府大道中段1268号1栋天府软件园E2座12层12-18号</p>-->
        <!--<p>联系人：</p>-->
        <!--<p>联系电话：</p>-->
        <!--<p>电子邮箱：</p>-->
        <!--<p>乙方：</p>-->
        <!--<p>住所地：</p>-->
        <!--<p>联系人：</p>-->
        <!--<p>联系电话：</p>-->
        <!--<p>电子邮箱：</p>-->
        <!--<p>鉴于：</p>-->
        <!--<p>甲方系四川省首家致力风险控制、法律咨询、诉讼管理的专业法律服务平台企业，创新法律服务模式，自主研发“案多多”互联网科技法律服务平台，精细化管理律师及委托案件进程，现有平台律师27000+名，已覆盖全国97%的地市州区县，并能够提供跨区域到省市区县及乡镇的全国性在线法律服务。</p>-->
        <!--<p>甲、乙双方根据《中华人民共和国合同法》等相关法律法规，在自愿、平等、诚信的基础上，经友好协商一致，签订《法律咨询服务合同》（以下称“本合同”）。</p>-->
        <!--<p style="font-weight: 900">一、服务内容及方式</p>-->
        <!--<p>1、“案多多”平台为用户提供“复工复产安心包”（以下称“安心包”），有效期1年，自购买之日起计算。</p>-->
        <!--<p>2、安心包服务内容包括但不限于公司治理、劳动人事、业务经营三大板块的法律咨询，由专业律师提供1对1在线法律咨询服务、重大协议专业律师在线修订、律师函，以上电话咨询、在线提问/解决法律问题、律师函三项服务共计12次。同时附送复工复产保险（财产基本险及营业中断保险）一份。</p>-->
        <!--<p>3、线上咨询：法律咨询服务由“案多多”平台为乙方匹配专业律师，由律师通过“案多多”平台提供在线式法律咨询服务。其中线上咨询，每次20分钟，按回答问题有效时间计，超出5分钟以内不另行计价，以提问和回答问题总共有效计时算。乙方在“案多多”平台发出咨询需求后，平台自动为其匹配该问题对应的专业方向的在线律师为其咨询。</p>-->
        <!--<p>4、合同审核修订：乙方在“案多多”平台将需审核修订的合同上传后，平台自动为其匹配在线律师为其审核修订合同，审核修订完毕后，律师上传修订后版本。</p>-->
        <!--<p>5、律师函：乙方在“案多多”平台将律师函需求上传后，甲方平台自动为其匹配在线律师为其出具律师函，律师函出具后，律师上传至甲方平台。</p>-->
        <!--<p>6、乙方涉诉讼时，由“案多多”平台为其匹配律师代理诉讼。</p>-->
        <!--<p style="font-weight: 900">二、费用标准及支付方式	</p>-->
        <!--<p>1、用户确认购买“案多多”提供的“复工复产安心包”一份，费用：2500元/份。</p>-->
        <!--<p>2、“案多多”在用户支付全部费用后，向用户开具等额的增值税专用发票。</p>-->
        <!--<p style="font-weight: 900">三、甲方权利义务</p>-->
        <!--<p>1.“案多多”平台为用户及时匹配咨询律师，为用户提供在线法律咨询服务。</p>-->
        <!--<p>2、“案多多”应对用户指定的法律服务对接人进行“案多多”平台使用培训，以便用户能够使用“案多多”平台进行法律咨询。</p>-->
        <!--<p>3、用户在“案多多”平台发出咨询需求后，“案多多”平台自动为其匹配该问题对应的专业方向的在线律师为其咨询，针对线上咨询，“案多多”响应应时间为最迟4小时内应答。</p>-->
        <!--<p>4、用户在“案多多”平台将需审核修订的合同上传后，“案多多”平台自动为其匹配在线律师为其审核修订合同，审核修订完毕后，律师上传修订后版本。针对重大合同修订，“案多多”响应应时间为最迟8小时内应答。</p>-->
        <!--<p>5、用户在“案多多”平台将律师函需求上传后，“案多多”平台自动为其匹配在线律师为其出具律师函，律师函出具后，律师上传至“案多多”平台。</p>-->
        <!--<p style="font-weight: 900">四、用户权利义务</p>-->
        <!--<p>1.乙方在1年有效期内可在“案多多”平台在线法律咨询服务、重大协议专业律师在线修订服务、律师函服务，电话咨询、在线提问/解决法律问题、律师函三项服务共计12次。</p>-->
        <!--<p>2、咨询结束后，乙方可对当次咨询进行评价，评价为“不满意”的，平台将在用户下一次咨询时，为其匹配其他律师；如评价为“不满意/问题未解决”的，用户可投诉，平台受理投诉后认定投诉成立问题未解决的，免费就该咨询问题更换一次咨询。</p>-->
        <!--<p>3、乙方可对当次审核修订进行评价，评价为“不满意”的，平台将在用户下一次提起合同审核修订时，为其匹配其他律师；如评价为“非常不满意/问题未解决”的，用户可以投诉，平台受理投诉后认为投诉成立的，免费更换一次合同审核修订服务。</p>-->
        <!--<p>4、乙方可对当次审核修订进行评价，评价为“不满意”的，平台将在用户下一次提起合同审核修订时，为其匹配其他律师；如评价为“非常不满意/问题未解决”的，用户可以投诉，平台受理投诉后认为投诉成立的，免费更换一次合同审核修订服务。</p>-->
        <!--<p>5、乙方可对当次律师函进行评价，评价为“不满意”的，平台将在用户下一次提起律师函需求时，为其匹配其他律师；如评价为“非常不满意/问题未解决”的，用户可以投诉，平台受理投诉后认为投诉成立的，免费更换一次律师函服务。</p>-->
        <!--<p style="font-weight: 900">五、违约责任</p>-->
        <!--<p>甲、乙双方应该按照本协议约定履行义务，如一方违约给对方造成了损失，违约方应当赔偿给对方造成的损失。</p>-->
        <!--<p style="font-weight: 900">六、合同解除</p>-->
        <!--<p>乙方违反本合同项下的约定，甲方有权书面提出解除本合同。甲方书面通知送达乙方之日本合同即自行终止。</p>-->
        <!--<p style="font-weight: 900">七、通知和送达</p>-->
        <!--<p>1.甲、乙双方因履行本合同（除财务对账以外）而相互发出或者提供的所有通知、文件、材料，均以本合同所列明的住所地、电子邮箱、联系电话、联系人送达。一方迁址或者变更住所地、电子邮箱、联系电话、联系人的，应当书面通知对方。</p>-->
        <!--<p>2.以当面交付文件方式送达的，交付之时视为送达；以电子邮件方式送达的，发出电子邮件时视为送达；以传真方式送达的，发出传真时视为送达；以邮寄方式送达的，邮件交邮当日视为送达。</p>-->
        <!--<p style="font-weight: 900">八、纠纷解决</p>-->
        <!--<p>甲、乙双方如就本协议事项发生争议，应双方友好协商解决，如协商不成，应向甲方所在地有管辖权的人民法院提起诉讼。</p>-->
        <!--<p style="font-weight: 900">九、其他约定</p>-->
        <!--<p>本合同由甲方提供，乙方已经全文逐条审阅，双方均明确理解不存异议。 </p>-->
        <!--<p>本合同自甲、乙双方加盖公章之日起生效。本合同壹式贰份,甲方执壹份、乙方执壹份，具有同等法律效力。</p>-->
        <!--<p style="font-weight: 900">【以下无正文】</p>-->
        <!--<p>甲方： 成都律军风险管理股份有限公司         乙方：</p>-->
        <!--<p>签订日期：  年  月   日 </p>-->

        <p style="font-weight: 900">一、服务内容及方式</p>
        <p>1、“案多多”平台为用户提供“复工复产安心包”（以下称“安心包”），有效期1年，自购买之日起计算。</p>
        <p>2、安心包服务内容包括但不限于公司治理、劳动人事、业务经营三大板块的法律咨询，由专业律师提供1对1在线法律咨询服务、重大协议专业律师在线修订、律师函，以上电话咨询、在线提问/解决法律问题、律师函三项服务共计12次。同时附送复工复产保险（财产基本险及营业中断保险）一份。</p>
        <p>3、线上咨询：法律咨询服务由“案多多”平台为用户匹配专业律师，由律师通过“案多多”平台提供在线式法律咨询服务。其中线上咨询，每次20分钟，按回答问题有效时间计，超出5分钟以内不另行计价，以提问和回答问题总共有效计时算。用户在“案多多”平台发出咨询需求后，平台自动为其匹配该问题对应的专业方向的在线律师为其咨询。</p>
        <p>4、合同审核修订：用户在“案多多”平台将需审核修订的合同上传后，平台自动为其匹配在线律师为其审核修订合同，审核修订完毕后，律师上传修订后版本。</p>
        <p>5、律师函：用户在“案多多”平台将律师函需求上传后，“案多多”平台自动为其匹配在线律师为其出具律师函，律师函出具后，律师上传至“案多多”平台。</p>
        <p>6、用户涉诉讼时，由“案多多”平台为其匹配律师代理诉讼。</p>
        <p style="font-weight: 900">二、费用标准及支付方式</p>
        <p>1、用户确认购买“案多多”提供的“复工复产安心包”一份，费用：2500元/份。</p>
        <p>2、“案多多”在用户支付全部费用后，向用户开具等额的增值税专用发票。</p>
        <p style="font-weight: 900">三、“案多多”权利义务</p>
        <p>1.“案多多”平台为用户及时匹配咨询律师，为用户提供在线法律咨询服务。</p>
        <p>2、“案多多”应对用户指定的法律服务对接人进行“案多多”平台使用培训，以便用户能够使用“案多多”平台进行法律咨询。</p>
        <p>3、用户在“案多多”平台发出咨询需求后，“案多多”平台自动为其匹配该问题对应的专业方向的在线律师为其咨询，针对线上咨询，“案多多”响应应时间为最迟4小时内应答。</p>
        <p>4、用户在“案多多”平台将需审核修订的合同上传后，“案多多”平台自动为其匹配在线律师为其审核修订合同，审核修订完毕后，律师上传修订后版本。针对重大合同修订，“案多多”响应应时间为最迟8小时内应答。</p>
        <p>5、用户在“案多多”平台将律师函需求上传后，“案多多”平台自动为其匹配在线律师为其出具律师函，律师函出具后，律师上传至“案多多”平台。</p>
        <p style="font-weight: 900">四、用户权利义务</p>
        <p>1.用户在1年有效期内可在“案多多”平台在线法律咨询服务、重大协议专业律师在线修订服务、律师函服务，电话咨询、在线提问/解决法律问题、律师函三项服务共计12次。</p>
        <p>2、咨询结束后，用户可对当次咨询进行评价，评价为“不满意”的，平台将在用户下一次咨询时，为其匹配其他律师；如评价为“不满意/问题未解决”的，用户可投诉，平台受理投诉后认定投诉成立问题未解决的，免费就该咨询问题更换一次咨询。</p>
        <p>3、用户可对当次审核修订进行评价，评价为“不满意”的，平台将在用户下一次提起合同审核修订时，为其匹配其他律师；如评价为“非常不满意/问题未解决”的，用户可以投诉，平台受理投诉后认为投诉成立的，免费更换一次合同审核修订服务。</p>
        <p>4、用户可对当次审核修订进行评价，评价为“不满意”的，平台将在用户下一次提起合同审核修订时，为其匹配其他律师；如评价为“非常不满意/问题未解决”的，用户可以投诉，平台受理投诉后认为投诉成立的，免费更换一次合同审核修订服务。</p>
        <p>5、用户可对当次律师函进行评价，评价为“不满意”的，平台将在用户下一次提起律师函需求时，为其匹配其他律师；如评价为“非常不满意/问题未解决”的，用户可以投诉，平台受理投诉后认为投诉成立的，免费更换一次律师函服务。</p>
        <p style="font-weight: 900">五、违约责任</p>
        <p>双方应该按照本协议约定履行义务，如一方违约给对方造成了损失，违约方应当赔偿给对方造成的损失。</p>
        <p style="font-weight: 900">六、合同解除</p>
        <p>用户违反本合同项下的约定，“案多多”有权书面提出解除本合同。“案多多”书面通知送达用户之日本合同即自行终止。</p>
        <p style="font-weight: 900">七、通知和送达</p>
        <p>1.双方因履行本合同（除财务对账以外）而相互发出或者提供的所有通知、文件、材料，均以本合同所列明的住所地、电子邮箱、联系电话、联系人送达。一方迁址或者变更住所地、电子邮箱、联系电话、联系人的，应当书面通知对方。</p>
        <p>2.以当面交付文件方式送达的，交付之时视为送达；以电子邮件方式送达的，发出电子邮件时视为送达；以传真方式送达的，发出传真时视为送达；以邮寄方式送达的，邮件交邮当日视为送达。</p>
        <p style="font-weight: 900">八、纠纷解决</p>
        <p>双方如就本协议事项发生争议，应双方友好协商解决，如协商不成，应向“案多多”所在地有管辖权的人民法院提起诉讼。</p>
        <p style="font-weight: 900">九、其他约定</p>
        <p>本合同由“案多多”提供，用户已经全文逐条审阅，双方均明确理解不存异议。</p>
        <p>本合同自双方加盖公章之日起生效。本合同壹式贰份,“案多多”执壹份、用户执壹份，具有同等法律效力</p>

    </div>
</template>

<script>
    export default {
        name: "contract"
    }
</script>

<style scoped>
p{text-indent:2em;}
</style>
