<template>
  <div>
    跳转中，请稍后
  </div>
</template>

<script>
export default {
  name: "oneClickLogin",
  data() {
    return {
      account: '',
      password: '',
    }
  },
  methods: {
    //
    login: function () {
      var _this = this
      _this.send({
        url: '/AccountLogin',
        warningTips: true,
        data: {
          AccountLogin: _this.account,
          AccountPassword: _this.password,
          AccountTypeId : '1'
        },
        success: function (res) {
          console.log(res.AdminId)
          // var objStr = JSON.stringify(res)JSON.stringify(res)
          window.sessionStorage.AdminId = res.AdminId
          window.sessionStorage.AdminLogin = res.AccountLogin
          window.sessionStorage.AdminName = res.AccountName
          window.sessionStorage.AccountTypeId = res.AccountTypeId
          window.sessionStorage.AreaName = res.AreaName
          window.sessionStorage.adminLevel = res.adminLevel
          window.sessionStorage.areaCode = res.AreaCode
          window.sessionStorage.Token = res.Token
          window.sessionStorage.Level = res.Level
          window.sessionStorage.AreaId = res.AreaId
          window.sessionStorage.Pid = res.Pid
          window.sessionStorage.ParentId = res.ParentId
          window.sessionStorage.Hierarchy = res.Hierarchy
          window.sessionStorage.IsSign = res.IsSign
          window.sessionStorage.information = JSON.stringify(window.sessionStorage)
          window.sessionStorage.parentIdArrStr = res.PidArr.toString()
          SYSCONFIG.server.information.AdminId = res.AdminId
          SYSCONFIG.server.information.areaCode = res.AreaCode
          SYSCONFIG.server.information.AdminLogin = res.AdminLogin
          SYSCONFIG.server.information.AdminName = res.AdminName
          SYSCONFIG.server.information.AreaName = res.AreaName
          SYSCONFIG.server.information.adminLevel = res.adminLevel
          SYSCONFIG.server.information.Level = res.Level
          SYSCONFIG.server.information.AreaId = res.AreaId
          SYSCONFIG.server.information.Pid = res.Pid
          SYSCONFIG.server.information.ParentId = res.ParentId
          SYSCONFIG.server.information.Hierarchy = res.Hierarchy
          SYSCONFIG.server.information.parentIdArrStr = res.PidArr
          SYSCONFIG.defaultData.Token = res.Token
          if(res.AdminId == '166'){
            window.sessionStorage.sysName = '村居法律服务平台'
          }

          if(window.sessionStorage.AdminLogin == 'cqykt'){
            window.sessionStorage.cqykt = 1
          }
          if(_this.$route.query.type == 1){//type 1:路由名称；2：url；
            _this.$router.push({ name: _this.$route.query.data })
          }
        }
      })

    },
  },
  mounted() {
    //   this.$router.push({name:'adminhome'})
    var _this = this
    if(SYSCONFIG.location == 'chong-qing'){
      window.sessionStorage.sysName = '重庆村居法务平台'
      window.sessionStorage.url = 'https://cq.lvwa18.com:8001/'
    }else{
      window.sessionStorage.sysName = '律蛙数字法治-智慧司法平台'
      window.sessionStorage.url = 'https://vn-server.lvwa18.com/'
    }
    if (this.$route.query.key != undefined && this.$route.query.secret != undefined) {
      _this.account = this.$route.query.key,
      _this.password = require('js-base64').Base64.decode(this.$route.query.secret)
      _this.login()
    }
  },
}
</script>

<style scoped>

</style>
