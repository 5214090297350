<style scoped>
.bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
.introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
.introduce-video-player{width:100%;}
.mediator-list{color:#FFF;}
.mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
.mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
.introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
.introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
.introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div class="introduce-text">
      <div class="introduce-text-title">办理预约合同公证需提交的材料</div>
      <div class="introduce-text-content">
        1、身份证（买卖双方，若已婚则需要配偶二人的）；</br>
        2、户口簿（买卖双方，若已婚则需要配偶二人的）；</br>
        3、结婚证（买卖双方，已婚的才需提供）；</br>
        4、网签合同、拆迁协议及交割单；</br>
        5、房屋信息摘要；</br>
        6、买卖双方签订的合同草案；</br>
        7、转款凭证；</br>
        8、公证员认为应当提交的其他材料。</br>
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <Row style="text-align: center;">
      <Col span="24"><a href="https://scjys.egongzheng.com/H5/templates/index.html"><Button type="warning" style="width:4rem;">去 办 理</Button></a></Col>
    </Row>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'guild9',
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {

  },
}
</script>
