<template>
    <div style="font-family: PingFang SC;">
        <div style="background: url(/img/caseInput/logintp.png);background-size: 100% 100%;width: 7.5rem;height: 3.6rem;text-align: center;font-family: PingFang SC;position: relative">
            <div>
                <img style="width: 0.8rem;height: 0.8rem;vertical-align: top;margin: 0.72rem 0 0.15rem;" src="/img/caseInput/logo.png">
            </div>
            <div style="font-size: 0.4rem;font-weight: bold;color: #FFF;line-height: 0.54rem;">
                <div>成都市武侯区司法局</div>
                <div>律师&律所评价系统</div>
            </div>
        </div>
        <div style="margin-left: 0.57rem;font-size: 0">
            <div style="font-size: 0.48rem;font-weight: bold;color: #000000;margin-top: 0.8rem;">律所登录</div>
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.59rem 0 0.2rem 0">律所名称</div>
            <input v-model="lawLogin" style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;color: #CCCCCC;" placeholder="请输入律所名称" />
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.4rem 0 0.2rem 0">密码</div>
            <input v-model="lawPassword" type="password" style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;color: #CCCCCC;" placeholder="请输入密码" />
            <div style="width: 6.4rem;height: 0.86rem;background: #1186E1;border-radius: 0.04rem;font-size: 0.28rem;font-weight: bold;color: #FFFFFF;text-align: center;
            line-height: 0.86rem;margin-top: 0.4rem;margin-bottom: 0.3rem" @click="login">登录</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "lawFirmendLogin",
        data() {
            return {
                lawLogin : '',
                lawPassword  : '',
            }
        },
        methods :{
            login : function () {
                var _this = this
                _this.send({
                    url : '/LawOfficeLogin',
                    data: {lawLogin : _this.lawLogin,lawPassword :_this.lawPassword},
                    success: function (res) {
                        if(res.lawImage){
                            window.sessionStorage.lawImage = JSON.stringify(res.lawImage) || []
                        }
                        window.sessionStorage.areaName = res.areaName || ''
                        window.sessionStorage.cityName = res.cityName || ''
                        window.sessionStorage.creatTime = res.creatTime || ''
                        window.sessionStorage.lawAddress = res.lawAddress || ''
                        window.sessionStorage.lawIntro = res.lawIntro || ''
                        window.sessionStorage.lawName = res.lawName || ''
                        window.sessionStorage.lawOfficeId = res.lawOfficeId || ''
                        window.sessionStorage.lawPhone = res.lawPhone || ''
                        window.sessionStorage.provinceName = res.provinceName || ''
                        window.sessionStorage.lawProvinceId = res.lawProvinceId || ''
                        window.sessionStorage.lawCityId = res.lawCityId || ''
                        window.sessionStorage.lawAreaId = res.lawAreaId || ''
                        window.sessionStorage.lawPhoto = res.lawPhoto || ''
                        _this.setCookie(_this.lawLogin,_this.lawPassword,res.lawOfficeId)
                        _this.delCookie ('userId')
                        _this.$router.push({name:'caseList'});
                    }
                })
            },
            setCookie(lawLogin,lawPassword,lawOfficeId) {
                var exdate = new Date(); //获取时间
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 30); //保存的天数
                //字符串拼接cookie
                window.document.cookie = "lawLogin" + "=" + lawLogin + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "lawPassword " + "=" + lawPassword  + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "lawOfficeId " + "=" + lawOfficeId  + ";path=/;expires=" + exdate.toGMTString();
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        //    删除cookie
            delCookie (name) {
                var exp = new Date();
                exp.setTime(exp.getTime() - 1);
                var cval = this.getCookie(name);
                if (cval != null)
                    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
            },
            getCookie(name) {
                var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
                if (arr = document.cookie.match(reg)) {
                    return true;
                    // return (arr[2]);
                } else {
                    return false
                }
            }
        },
        mounted () {
            var _this = this
            _this.setPageSize()
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'lawLogin') {
                        window.sessionStorage.lawLogin = arr2[1];
                        _this.lawLogin = window.sessionStorage.lawLogin
                    }
                    if (arr2[0] == 'lawPassword') {
                        window.sessionStorage.lawPassword = arr2[1];
                        _this.lawPassword = window.sessionStorage.lawPassword
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
