<style scoped>

</style>

<template>
	<div style="margin-left:25px;">
		<div>
			<Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="selectAll">全选</Checkbox>
		</div>
		<template v-for="(item,index) in listIn.list">
			<Checkbox v-model="item.selectState" @on-change="itemChange($event,item)">{{item.AdminName}}</Checkbox>
			<!-- <accountTree v-if="item.list" :listIn="{state:item.state,list:item.list}"></accountTree> -->
		</template>
	</div>
</template>

<script>
  export default {
    name: "accountTree",
		props:['listIn'],
		data(){
			return {
				accountIdList:[],
				checkAll: false,
				indeterminate: true,
				handleCheckAll : [],
			}
		},
		mounted(){
		},
		watch:{
			'listIn':{
				handler:function(){
					this.accountIdList = []
					for(var i in this.listIn.list){
						if(this.listIn.list[i].selectState==true){
							this.accountIdList.push(this.listIn.list[i].AdminId)
						}
					}
					this.$emit('getAccountIdList',this.accountIdList)
				},
				deep:true,
			}
		},
		methods:{
			itemChange:function(res,item){
				console.log(res)
				console.log(item)
			},
			selectAll : function () {
				var _this = this
				_this.accountIdList = []
				console.log(_this.accountIdList.length)
				if(_this.accountIdList.length == 0){
					for(var i in this.listIn.list){
						this.listIn.list[i].selectState = true
						_this.accountIdList.push(_this.listIn.list[i].AdminId)
					}
					console.log(_this.accountIdList,_this.listIn.list[i].selectState)

				}else {
					console.log(2)
				}


			}
		},
  }
</script>
