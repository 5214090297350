<style scoped>
    .address{width: 0.25rem;height: 0.3rem;margin: 0.25rem 0.09rem 0 0.3rem;vertical-align: top}
    .findaLawFirm{display:inline-block;padding:0 0.2rem;height: 0.64rem;border-radius: 0.32rem;font-size: 0.28rem;font-weight: 500;color: #FFFFFF;text-align: center;line-height: 0.64rem;}
    .getaLawyer{display:inline-block;padding:0 0.2rem;height: 0.64rem;margin: 0 0.2rem;border-radius: 0.32rem;font-size: 0.28rem;font-weight: 500;color: #333;text-align: center;line-height: 0.64rem;}
    .search{width: 3rem;height: 0.64rem;background: #FFFFFF;border-radius: 0.32rem;font-size: 0.28rem;color: #BFBFBF;font-weight: 500;font-family: PingFang SC;position: relative;display: inline-block;vertical-align: top;}
    .sousuo{width: 0.24rem;height: 0.25rem;vertical-align: top;position: absolute;top: 0.2rem;left: 0.2rem}
    .lawFirm{width: 1.2rem;height: 1.2rem;margin: 0.3rem 0.2rem 0 0.3rem}
    .ziyuan{width: 0.18rem;height: 0.2rem;vertical-align: top;margin-right: 0.1rem}
</style>

<template>
    <div style="font-family: PingFang SC;background: #F5F5F5;">
        <div style="width: 7.5rem;height: 1.8rem;font-size: 0.36rem;line-height: 1.32rem;color: #FFFFFF;text-align: center;background: url(/img/zhqy/bg.png);background-size: 100% 100%">
            <img src="/img/zhqy/logo.png" style="width:0.74rem;height: 0.8rem;vertical-align: top;margin: 0.23rem 0 0.15rem 0;">
            <div style="font-size: 0.36rem;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;line-height: 0.36rem;margin-top: -0.1rem;">成都市武侯区司法局</div>
        </div>
        <div style="width: 7.50rem;height: 0.8rem;background: #FFFFFF;font-size: 0.24rem;font-weight: 400;color: #999999;">
            <img src="/img/zhqy/address.png" class="address">
            <div style="line-height: 0.8rem;display: inline-block;width: 80%;">
              <Cascader :data="addressData" v-model="value1" placeholder="填写您的律所地址" @on-change="addressSelect">
                <div style="line-height: 0.8rem;">{{value2}}</div>
              </Cascader>
            </div>


        </div>
        <div style="font-size: 0;padding: 0.3rem 0 0.3rem 0.3rem">
            <div class="findaLawFirm" @click="onQuery(1)" :style="{background:(type==1?'#1186E1':'#E8E8E8'),color:(type==1?'#FFF':'#333')}">找律所<span style="font-size: 0.24rem;color: red">{{lawCount}}</span></div>
            <div class="getaLawyer"  @click="onQuery(2)" :style="{background:(type==2?'#1186E1':'#E8E8E8'),color:(type==2?'#FFF':'#333')}">找律师<span style="font-size: 0.24rem;color: red">{{lawyerCount}}</span></div>
            <div class="search">
                <img src="/img/zhqy/sousuo.png" class="sousuo">
                <input style="width: 2.65rem;height: 0.64rem;padding-left: 0.56rem;" placeholder="输入关键字查询"/>
            </div>
        </div>
        <div style="text-align: center" v-if="type == 1">
            <div style="width: 6.9rem;height: 1.80rem;background: #FFFFFF;border-radius: 0.04rem;display: inline-block;text-align: left;margin-bottom: 0.3rem"
                 v-for="(value,index) in lawFirmList"  @click="lawFirmDetails(value.lawOfficeId)">
                <img v-if="value.lawPhoto" :src="value.lawPhoto" class="lawFirm">
                <img v-else src="/img/zhqy/lawFirm.png" class="lawFirm">
                <div style="display: inline-block;font-weight: 500;width: 5rem;vertical-align: top;">
                    <div style="font-size: 0.28rem;color: #333;line-height: 0.28rem;vertical-align: top;overflow: hidden;text-overflow: ellipsis;width: 4.6rem;white-space: nowrap;margin-top: 0.28rem">{{value.lawName}}</div>
                    <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem;margin: 0.22rem 0 0.2rem">
                        <img src="/img/zhqy/ziyuan.png" class="ziyuan">
                        <div style="display: inline-block">{{value.lawPhone}}</div>
                    </div>
                    <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem">
                        <img src="/img/zhqy/dizhi.png" class="ziyuan">
                        <div style="display: inline-block;overflow: hidden;text-overflow: ellipsis;width: 4.6rem;white-space: nowrap;">{{value.lawAddress}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align: center" v-if="type == 2">
            <div style="width: 6.9rem;height: 1.80rem;background: #FFFFFF;border-radius: 0.04rem;display: inline-block;text-align: left;margin-bottom: 0.3rem" v-for="(value,index) in lawyerList" @click="lawyerDetails(value.lawyerId)">
                <img v-if="value.lawyerImage" :src="value.lawyerImage" class="lawFirm">
                <img v-else src="/img/zhqy/mrtx.png" class="lawFirm">

                <div style="display: inline-block;font-weight: 500;width: 5rem;vertical-align: top;">
                    <div style="font-size: 0.28rem;color: #333;line-height: 0.28rem;vertical-align: top;overflow: hidden;text-overflow: ellipsis;width: 4.6rem;white-space: nowrap;margin-top: 0.28rem">{{value.lawyerName}}</div>
                    <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem;margin: 0.22rem 0 0.2rem">
                        <img src="/img/zhqy/ziyuan.png" class="ziyuan">
                        <div style="display: inline-block">{{value.lawPhone}}</div>
                    </div>
                    <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem">
                        <img src="/img/zhqy/top_suoshujigou.png" class="ziyuan">
                        <div style="display: inline-block;overflow: hidden;text-overflow: ellipsis;width: 4.6rem;white-space: nowrap;">{{value.lawName}}</div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div style="font-size: 0.3rem;">-->
<!--          {{position}}-->
<!--        </div>-->
      <Modal
          v-model="modal1"
          title="温馨提示"
          @on-ok="login"
          ok-text="去登录" cancel-text="关闭">
        <p>该操作需登录后使用</p>
      </Modal>
      <div style="width: 100%;height: 1rem;background-color: #FFF;position: fixed;bottom: 0;">
          <div style="width: 49%;height: 1rem;font-size: 0.26rem;text-align: center;display: inline-block">
              <img src="/img/zhqy/sy1.png" style="width: 0.36rem;height: 0.36rem;margin-top: 0.1rem">
              <div>首页</div>
          </div>
          <div @click="personalInformation" style="width: 49%;height: 1rem;font-size: 0.26rem;text-align: center;display: inline-block">
            <img src="/img/zhqy/geren.png" style="width: 0.36rem;height: 0.36rem;margin-top: 0.1rem">
            <div>{{name}}</div>
          </div>
      </div>
    </div>
</template>

<script>
    import axios from "axios";
    import wx from 'weixin-js-sdk';
    export default {
        name: "zhqyHome",
        data() {
            return {
              modal1 : false,
                name : '个人信息',
              value1 : [],
              value2 : '四川省成都市武侯区',
              addressData :[],
                type : 1,
                lawyerList : [],
                lawFirmList : [],
                areaKey : '四川省成都市武侯区',//当前地址
                page : 1,
                law : '',
                lawCount : '',
                lawyerCount  : '',
            }
        },
        methods :{
          login : function (){
            var _this = this
            _this.$router.push({name: 'zhqylogin', query: {}})//律师
          },
          personalInformation : function (){
            var _this = this
            if(window.sessionStorage.lawOfficeId){
              if(window.sessionStorage.lawyerId){
                  _this.$router.push({name: 'editInformation', query: {}})//律师
              }else{
                  _this.$router.push({name: 'lawFirmEditInformation', query: {}})//律所
              }
            }else {
                _this.modal1 = true
            }
          },
            whLawyerList : function(){
                var _this = this
                if(_this.type == 1){
                    _this.send({
                        consulting : true,
                        url: '/LawOfficeList',
                        data: {
                            page : _this.page,
                            PageSize : 20,
                            areaKey : _this.areaKey
                        },
                        success: function (res) {
                            if(res){
                                var reg = /^(\d{3})\d{4}(\d{4})$/;
                                for(var i in res.List){
                                    if(res.List[i].lawPhone){
                                        res.List[i].lawPhone = res.List[i].lawPhone.replace(reg, "$1****$2")
                                    }
                                    _this.lawFirmList.push(res.List[i])
                                }
                            }

                        }
                    })
                }if(_this.type == 2){
                    _this.send({
                        consulting : true,
                        url: '/WhLawyerList',
                        data: {
                            page : _this.page,
                            PageSize : 20,
                            lawOfficeId : window.sessionStorage.lawOfficeId
                        },
                        success: function (res) {
                            if(res){
                                var reg = /^(\d{3})\d{4}(\d{4})$/;
                                for(var i in res.List){
                                    if(res.List[i].lawPhone){
                                        res.List[i].lawPhone = res.List[i].lawPhone.replace(reg, "$1****$2")
                                    }
                                    _this.lawyerList.push(res.List[i])
                                }
                            }
                        }
                    })
                }
            },
            //律所详情
            lawFirmDetails : function(lawOfficeId){
                var _this = this
                _this.$router.push({name: 'looklawFirm', query: {lawOfficeId:lawOfficeId}})
            },
            //律师详情
            lawyerDetails : function(lawyerId){
                var _this = this
                _this.$router.push({name: 'findaLawFirm', query: {lawyerId:lawyerId}})
            },
            onQuery:function(e){
                var _this = this
                _this.page = 1
                _this.lawyerList = []
                _this.lawFirmList = []
                _this.type = e
                _this.whLawyerList()
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
          //    地区
          areaList: function() {
            var _this = this
            var data = []
            _this.send({
              url: '/AreaAdminList',
              data: {},
              success: function(res) {
                //
                for(var i in res){
                  data.push(res[i])
                }
                convertTree(data)
                function convertTree(tree) {
                  const result = []
                  tree.forEach((item) => {
                    // 解构赋值
                    let {
                      AreaId: value,
                      AreaName: label,
                      list: children
                    } = item
                    if (children) {
                      children = convertTree(children)
                    }
                    result.push({
                      value,
                      label,
                      children
                    })
                  })
                  _this.addressData = result
                  return result
                }
              }
            })
          },
          addressSelect : function(value, selectedData){
            var _this = this
            _this.value2 = selectedData[0].label+selectedData[1].label+selectedData[2].label
            _this.areaKey = selectedData[0].label+selectedData[1].label+selectedData[2].label
            _this.lawFirmList = []
            _this.whLawyerList()
          },
            lawAndLawyerCount : function () {
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/lawAndLawyerCount',
                    data: {},
                    success: function (res) {
                        if(res){
                            _this.lawCount = res.lawCount
                            _this.lawyerCount = res.lawyerCount
                        }
                    }
                })
            }
        },
        mounted (){
            var _this = this
            _this.setPageSize()
            _this.whLawyerList()
            _this.areaList()
            _this.lawAndLawyerCount()
            if(window.sessionStorage.lawOfficeId || window.sessionStorage.lawyerId){
                if(window.sessionStorage.lawyerId){
                    _this.law = '律师'
                }
                else{
                    _this.law = '律所'
                }
            }else{
                _this.name = '登录'
            }
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'lawyerPhone') {
                        window.sessionStorage.lawyerPhone = arr2[1];
                    }
                    if (arr2[0] == 'lawyerPassword') {
                        window.sessionStorage.lawyerPassword = arr2[1];
                    }
                    if (arr2[0] == 'lawLogin') {
                        window.sessionStorage.lawLogin = arr2[1];
                    }
                }
            }
            var ua = navigator.userAgent.toLowerCase();
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                if (ua.match(/MicroMessenger/i) == "micromessenger") {
                    axios({
                        method: 'post',
                        url: 'https://vn-server.lvwa18.com/api/getConfig',
                        data: {url: window.location.href},

                    })
                        .then(function (res) {
                            wx.config({
                                debug: false, // 因为在手机上测试没法打印，当debug为true时，所有的返回值都会在手机上alert出来
                                appId: res.data.Data.appid, // 必填，公众号唯一标识
                                timestamp: res.data.Data.timestamp, // 必填，生成签名的时间戳
                                nonceStr: res.data.Data.nonceStr, // 必填，生成签名的随机串
                                signature: res.data.Data.signature,// 必填，签名
                                jsApiList: ['chooseImage','uploadImage'] // 必填，需要使用的JS接口列表，需要用到什么接口就去开发者文档查看相应的字段名
                            });
                            wx.ready(function(){
                                wx.getLocation({
                                    type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                                    success: function (res) {
                                        alert(res)
                                        axios({
                                            method: 'post',
                                            url: 'https://ai.lvwa18.com/now_location_cunju',
                                            data: {latitude: res.latitude,longitude : res.longitude},

                                        })
                                            .then(function (response) {
                                                that.value2 = response.data.address
                                                that.areaKey = response.data.address
                                            })
                                    }
                                });
                            });
                        })
                    // _this.send({
                    //     url: '/getConfig ',
                    //     data: {url: window.location.href},
                    //     success: function (res) {
                    //
                    //     }
                    // })
                }else {

                    var that = this
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            // that.latitude = position.coords.latitude
                            // that.longitude = position.coords.longitude
                          axios({
                            method: 'post',
                            url: 'https://ai.lvwa18.com/now_location_cunju',
                            data: {latitude: position.coords.latitude,longitude : position.coords.longitude},

                          })
                              .then(function (response) {
                                that.value2 = response.data.address
                                that.areaKey = response.data.address
                              })
                        }, function (error) {
                            switch (error.code) {
                                case error.PERMISSION_DENIED:
                                    alert('用户拒绝对获取地理位置的请求。')
                                    break;
                                case error.POSITION_UNAVAILABLE:
                                    alert('位置信息是不可用的。')
                                    break;
                                case error.TIMEOUT:
                                    alert('请求用户地理位置超时。')
                                    break;
                                case error.UNKNOWN_ERROR:
                                    alert('未知错误。')
                                    break;
                            }
                        }, { enableHighAcuracy: false });
                    } else {
                        alert('Geolocation is not supported by this browser.')
                    }
                }
            }
        //

        //
        },

        created() {
            let that = this;
            window.onscroll = function () {
                // scrollTop 滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                // windowHeight 可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // scrollHeight 滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                // 滚动条到底部的条件
                if (scrollTop + windowHeight+5 >= scrollHeight) {
                    // 加载数据
                    that.page++
                    that.whLawyerList();
                }
            }
        },
    }
</script>

