<template>
  <div class="home">
    <div style="display: flex;justify-content: space-between;align-items:center;">
      <div>
        <div class="archive-title-icon"></div>
        <span class="archive-title">学习记录</span>
<!--        <div class="archive-statistics">-->
<!--          总上传<span class="statistics-num"></span>条-->
<!--        </div>-->
      </div>
    </div>
    <div style="margin:10px 0;"></div>
    <div style="margin-bottom: 20px">
      <Row :gutter="16">
        <Col span="3">
          <div>区域范围：</div>
          <Select v-model="filter.areaRange.value" placeholder="请选择区域" clearable>
            <Option value="0" :label="information.Level==1?'全市':'全部'">{{ information.Level==1?'全市':'全部' }}</Option>
            <Option v-for="(item, index) in filter.areaRange.list" :value="item.AdminId" :label="item.AdminName">
              {{ item.AdminName }}</Option>
          </Select>
        </Col>
        <Col span="6">
          <div>搜索：</div>
          <Input v-model="filter.InsiderName" search enter-button placeholder="搜索法律明白人" @on-search="recordData.page=1;aStudyRecordList()" />
        </Col>
        <Col span="8">
          <div>学习时间：</div>
          <div style="display: inline-block;">
            <RadioGroup type="button" v-model="filter.serviceDate.identification">
              <Radio label="m">月</Radio>
              <Radio label="q">季</Radio>
              <Radio label="y">年</Radio>
              <Radio label="a">所有</Radio>
              <Radio label="custom">自定义</Radio>
            </RadioGroup>
            <DatePicker v-if="filter.serviceDate.identification=='custom'"
                        @on-change="filter.serviceDate.startTime = $event[0],filter.serviceDate.endTime=$event[1]" @on-ok="recordData.page=1;aStudyRecordList()"
                        type="datetimerange" format="yyyy-MM-dd" placeholder="请选择时间区间"></DatePicker>
          </div>
        </Col>
      </Row>
    </div>
<!--    列表-->
    <Row style="text-align: center;">
      <Col span="24">
        <Row style="text-align: center;">
          <Col span="24">
            <Row style="background-color:#edeef0;min-height: 46px;" align="middle">
              <Col span="2">
                序号
              </Col>
              <Col span="4">法律明白人</Col>
              <Col span="8">视频标题</Col>
              <Col span="5">所属村(社区)</Col>
              <Col span="2">学习进度</Col>
              <Col span="3">学习时间</Col>
            </Row>
          </Col>
          <Col span="24" style="min-height:500px;">
            <Row v-for="(item, index) in recordData.data" :style="{backgroundColor:index%2!=0?'#f5f6f7':'',minHeight:'46px'}" align="middle">
              <Col span="2">
                <span v-if="(recordData.page - 1) * recordData.size + index + 1 < 10">0{{ (recordData.page - 1) * recordData.size + index + 1 }}</span>
                <span v-else>{{ (recordData.page - 1) * recordData.size + index + 1 }}</span>
              </Col>
              <Col span="4">{{item.insider.InsiderName}}</Col>
              <Col span="8">{{item.study.StudyContent}}</Col>
              <Col span="5">{{item.village.CityName}}{{item.village.admin[0].AdminName}}{{item.village.VillagesName}}</Col>
              <Col span="2">{{item.StudyExtent}}%</Col>
              <Col span="3">{{item.CreateTime}}</Col>
            </Row>
          </Col>
        </Row>
    <Spin fix v-if="listLoading">
      <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
      <div>加载中</div>
    </Spin>
    </Col>
    </Row>
    <div style="height:50px;"></div>
    <div style="text-align: center;position:absolute;bottom:20px;width:calc(100% - 40px);">
      <Page
          :total="recordData.total"
          :page-size="recordData.size"
          :page-size-opts="recordData.sizeList"
          :current="recordData.page"
          :disabled="listLoading"
          show-elevator
          show-sizer
          show-total
          @on-change="recordData.page = $event;aStudyRecordList()"
          @on-page-size-change="recordData.size = $event"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "learningRecord",
  data (){
    return{
      listLoading:false,
      filter:{
        areaRange:{value:'0',list:[],},//区域范围
        InsiderName : '',//搜索
        serviceDate:{identification: 'a',startTime: '',endTime: '',},//服务时间
      },
      recordData : {
        data : [],
        DateType : 'a',
        InsiderName : '',
        AdminFind : '',
        StartTime : '',
        EndTime : '',
        page : 1,
        size : 15,
        total:0,
        sizeList:[10,15,30],
      },
      information : [],
    }
  },
  watch: {
    'filter.areaRange.value': function (val) {this.recordData.page=1;this.aStudyRecordList()},
    'filter.serviceDate.identification': function (val) {if(val!='custom'){this.recordData.page=1;this.filter.serviceDate.startTime='';this.filter.serviceDate.endTime='';this.aStudyRecordList()}},
  },
  mounted() {
    this.information = window.sessionStorage
    this.aStudyRecordList()
    this.getAdminList()
  },
  methods:{
    aStudyRecordList : function (){
      var _this = this
      _this.listLoading = true
      _this.send({
        consulting: true,
        url:'/AStudyRecordList',
        data: {
          StartTime: _this.filter.serviceDate.startTime,
          EndTime: _this.filter.serviceDate.endTime,
          DateType: _this.filter.serviceDate.identification,
          AdminFind: _this.filter.areaRange.value,
          InsiderName: _this.filter.InsiderName,
          page : _this.recordData.page,
          pageSize: _this.recordData.size,
        },
        success: function (res) {
          _this.recordData.data = res.List
          _this.recordData.total = res.Count
          _this.listLoading = false
        }
      })
    },
    //获取区域列表
    getAdminList:function(){
      let _this = this
      _this.send({
        url: '/FindBottomVillagesAndAdmin_',
        data:{
          AdminId: window.sessionStorage.AdminId,
        },
        success: function (res) {
          _this.filter.areaRange.list = []
          _this.filter.areaRange.list = res.Admin
        }
      })
    },
  }
}
</script>

<style scoped>
.home{padding:20px;position: relative;}
.archive-title-icon{display: inline-block;width:8px;border-radius:4px;background-color: #2d8cf0;height:22px;vertical-align:middle;}
.archive-title{vertical-align:middle;font-size:16px;margin-left:10px;color:#626775;font-weight:bold;}
.archive-statistics{vertical-align: middle;font-size:15px;display:inline-block;margin-left:40px;color:#2d8cf0;}
.statistics-num{color:#fc2c2d;margin:0 5px;}
.filter>div{width:136px;margin-right:10px;display:inline-block;}
.filter>div>div{margin-bottom:2px;}
.spin-icon-load{animation: ani-demo-spin 1s linear infinite;}
.hide-text{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.forMtitle1{background-color: #f9f9f9;height:40px;text-align: center;}
.forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height:40px;overflow: hidden;white-space: nowrap;}
.forMcontent{color: #666666;font-size:14px;padding:5px 0;border-bottom: 1px solid #dddddd;text-align: center;}
.forMcontent p {display: inline-block;width: 20%;overflow: hidden; vertical-align:middle;white-space: nowrap;padding:10px 0;}
.operation-btn{line-height:30px;cursor: pointer;transition:0.5s;}
.operation-btn:hover{background-color:#efefef;}

</style>
