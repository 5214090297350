<template>
  <div>
    <Row :gutter="16">
      <Col span="14">
        <div style="padding: 18px 20px;">角色列表</div>
        <Table :columns="roleColumns" :data="roleList" row-key="AdminId" :load-data="handleLoadData" highlight-row border @on-row-click="singleLineClick">
          <template slot-scope="{ row }" slot="role">
            <div v-for="(value, index) in row.role">{{ value.name }}</div>
          </template>
        </Table>
      </Col>
      <Col span="10">
        <div style="padding: 18px 20px;display: inline-block">角色列表</div>
        <div style="float: right;margin: 12px 30px 0 0">
          <Checkbox v-model="type" >复用角色</Checkbox>
          <Button type="primary" @click="saveMenu">保存</Button>
        </div>
        <Table :columns="navigationColumns" :data="navigationBar" row-key="id" >
          <template slot-scope="{ row, index }" slot="action">
            <Checkbox v-model="row.show" @on-change="operations(row)"></Checkbox>
          </template>
<!--          <template slot-scope="{ row, index }" slot="action">-->
<!--            <Button type="primary" size="small" style="margin-right: 5px" @click.stop="roleModify(row,'修改菜单')">分配</Button>-->
<!--          </template>-->
        </Table>
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "rolePermission",
  data () {
    return {
      // 角色数据
      roleList: [], // 表格数据
      roleColumns: [ // 表格列定义
        { title: '名称', key: 'AdminName', tree: true },
        { title: '角色名称', key: 'role' , slot: 'role',},
        { title: 'ID', key: 'AdminId' },
      ],
      // 导航栏数据
      navigationBar: [], // 表格数据
      navigationColumns: [ // 表格列定义
        {
          title: '操作',
          slot: 'action',
          width: 150,
          align: 'center'
        },
        { title: '角色名称', key: 'name', tree: true },
        { title: 'ID', key: 'id' },
      ],
      roleAdminId: '',
      type:false,
      index : 1
    }
  },
  methods:{
    // 请求角色权限列表
    rolePerMissionList(AdminId = '',callback) { // 默认值为空字符串
      console.log(AdminId,callback)
      var _this = this;
      this.send({
        url: '/newAdminList',
        data: {
          AdminId: AdminId,
        },
        success: (res) => {
          console.log(res);
          const children = _this.replaceChildren(res);
          if (AdminId) {
            callback(children)
          } else {
            _this.roleList = children; // 初始角色列表
          }

          console.log(_this.roleList); // 打印当前的角色列表，调试用
        }
      });
    },

    // 转换返回的数据为子角色
    replaceChildren(data) {
      return data.map(item => {
        const child = {
          AdminName: item.AdminName,
          AdminId: item.AdminId,
          role: item.role,
          children: [], // 初始化 children
        };
        if (item.Hierarchy < 4) {
          child._loading = false; // 只有在条件满足时添加 _loading
        }
        return child;
      });
    },
    // 查看下级数据
    handleLoadData(item, callback) {
      // 将角色的 AdminId 传入方法
      console.log(callback)
      this.rolePerMissionList(item.AdminId, callback);
    },
    // 点击单行数据
    singleLineClick: function (data,index) {
      console.log(data, index);
      this.navigationBar = this.menuData(this.navigationBar)
      this.roleAdminId = data.AdminId
      const roleIds = data.role.map(role => role.id);

      this.navigationBar.forEach(item => {
        if (roleIds.includes(item.id)) {
          item.show = true; // 或根据需要设置为 false
        }
      });
    },
    // 保存
    saveMenu: function saveMenu() {
      var _this = this;
      // 过滤出 show 为 true 的项，并提取它们的 id
      const trueIds = _this.navigationBar
          .filter(item => item.show) // 只保留 show 为 true 的项
          .map(item => item.id)      // 提取它们的 id
      // 将 id 用逗号隔开
      const idString = trueIds.join(',');
      this.send({
        url : '/updateAdminRole',
        data: {
          AdminId:this.roleAdminId,
          role_id: idString,
          type : this.type ? '2' : '1', // 1是没有下级，2是有下级
        },
        success: function (res) {
          _this.rolePerMissionList()
        }
      })
    },
    operations: function (row) {
      console.log(row);
      function updateShow(data, id) {
        data.forEach(item => {
          if (item.id === id) {
            this.$set(item, 'show', !item.show); // 使用 $set 确保响应性
          }
        });
      }
      updateShow.call(this, this.navigationBar, row.id);
    },



    // 获取菜单列表
    permissionList: function(){
      var _this = this
      this.send({
        url : '/roleList',
        data: {},
        success: function (res) {
          _this.navigationBar = _this.menuData(res.data)
        }
      })
    },
    // 菜单数据
    menuData: function(data) {
      return data.map(item => {
        // 创建一个新对象，只包含需要的属性
        const newItem = {
          name: item.name,
          id: item.id,
          show: false,
        };
        return newItem;
      });
    },
  },
  mounted() {
    this.rolePerMissionList()
    this.permissionList()
    this.send({
      url : '/getMenu',
      data: {},
      success: function (res) {

      }
    })
  }
}
</script>

<style scoped>

</style>
