<template>
    <div>
        <div class="bg" v-if="index == 1">
            <div class="introduce-text">
                <div class="introduce-text-title">人民调解业务信息查询指南</div>
                <div class="introduce-text-content" style="text-indent:2em;">
                    <div>一、人民调解相关概念</div>
                    <div>人民调解：是指人民调解委员会通过说服、疏导等方法，促使当事人在平等协商基础上自愿达成调解协议，解决民间纠纷的活动。</div>
                    <div>人民调解组织：依法设立或授权成立的调解民间纠纷的群众性组织，包括人民调解委员会及其下设的人民调解小组、人民调解工作室等形态。</div>
                    <div>人民调解员：人民调解委员会委员和人民调解委员会聘任从事人民调解工作的人员。</div>
                    <div>人民调解委员会调解民间纠纷遵循下列原则：</div>
                    <div>（一）在当事人自愿、平等的基础上进行调解；</div>
                    <div>（二）不违背法律、法规和国家政策；</div>
                    <div>（三）尊重当事人的权利，不得因调解而阻止当事人依法通过仲裁、行政、司法等途径维护自己的权利。</div>
                    <div>人民调解委员会调解民间纠纷，不收取任何费用。</div>
                    <div>当事人在人民调解活动中享有下列权利：</div>
                    <div>（一）选择或者接受人民调解员；</div>
                    <div>（二）接受调解、拒绝调解或者要求终止调解；</div>
                    <div>（三）要求调解公开进行或者不公开进行；</div>
                    <div>（四）自主表达意愿、自愿达成调解协议。</div>
                    <div>（五）委托代理人参与调解。</div>
                    <div>当事人在人民调解活动中履行下列义务：</div>
                    <div>（一）如实陈述纠纷事实；</div>
                    <div>（二）遵守调解现场秩序，尊重人民调解员；</div>
                    <div>（三）尊重对方当事人行使权利。</div>
                    <div>（四）自觉履行调解协议。</div>
                    <div>司法确认：司法确认是对于人民调解效力确认所设立的一种制度。人民调解委员会调解达成调解协议的民事权利义务，当事人共同向人民法院申请确认其法律效力的活动。</div>
                    <div>司法确认人民调解协议书：经人民调解委员会调解达成的调解协议，具有法律约束力，当事人应当按照约定履行。对具有民事权利、义务内容的调解协议，当事人可以自调解协议生效之日起30日内共同向有管辖权的人民法院申请司法确认。人民法院依法确认调解协议内容部分或全部无效的，当事人可以通过人民调解方式变更原调解协议或者达成新的调解协议，也可以向人民法晥提起诉讼。经人民法院确认合法有效的调解协议，一方当事人拒绝履行或者未全部履行的，对方当事人可向人民法院申请强制执行。</div>
                    <div>二、人民调解委员会分布情况</div>
                    <div>我市现有乡镇（街道）人民调解委员会22个，村（社区）人民调解委员会291个；企业事业单位调委会12个，建有交通、医患、消费、物业、婚姻家庭、诉调、访调、知识产权、劳动9个专业性、行业性人民调解委员会。</div>
                </div>
            </div>
            <!--  -->
            <div style="padding:15rpx;"></div>
            <div class="introduce-text">
                <div class="introduce-text-title">简阳市专业性行业性人民调解委员会通告</div>
                <div class="introduce-text-content" style="text-indent:2em;">
                    <div>简阳市道路交通事故纠纷、医疗纠纷等9家行业性专业性人民调解委员会已成立，现予以公告。广大市民如遇需调解的民间纠纷，可到相关的行业性专业性人民调解委员会申请调解。</div>
                    <div>1.简阳市交通事故纠纷人民调解委员会</div>
                    <div>联系人：伍炳兴 电话：15892315198</div>
                    <div>人民调解员：伍炳兴 钟政 杨学英</div>
                    <div>调委会成立时间：2008年4月</div>
                    <div>办公地址：简阳市公安局交通警察大队1楼</div>
                    <div>调解纠纷范围：一是受理并调解双方当事人自愿提交的财损交通事故及因交通事故中的财物损失所发生的民事赔偿纠纷;二是负责伤人事故的交通事故认定书送达后到民事赔偿前的各方接待工作;三是通过调解工作宣传法律、法规、规章和政策，教育当事人遵纪守法，做好纠纷激化的预防工作。</div>
                    <div>备注：交通事故纠纷人民调解委员会主要职责:一是依法受理并调解双方当事人申请，未经交警部门认定，显著轻微且财物损失金额在500元以下并自愿赔偿的；二是双方当事人申请调解，持有交警部门交通事故认定书，财物损失较大且有财物定损金额的；三是受理和调解双方当事人申请，提供交警部门交通事故认定书且提交伤亡人员相关信息、证明、资料、票据等有关材料的；四是依法调解成功的交通事故纠纷案件当即制发调解书并由当事人申请司法确认；五是调解不成的案件，引导当事人依法起诉，主张民事赔偿权利；六是向当事人宣传交通法规，倡导遵章守法，预防和减少交通事故发生。</div>
                    <div>2.简阳市医患纠纷人民调解委员会</div>
                    <div>联系人：樊正刚 电话： 18328291838</div>
                    <div>人民调解员：廖启富  樊正刚</div>
                    <div>调委会成立时间：2010年1月</div>
                    <div>办公地址：市人社局5楼</div>
                    <div>调解纠纷范围：受理因医疗事故或医疗过错引起的人身损害赔偿纠纷;非医疗事故或医疗过错引起的人身损害赔偿;患者与药品的生产商或经销商因服用药品引起的人身损害赔偿纠纷等。经人民调解委员会调解后，当事双方仍协商不成，进入诉讼程序，确保当事人的合法权利。</div>
                    <div>3.简阳市消费纠纷人民调解委员会</div>
                    <div>联系人：张明 电话：13778982806</div>
                    <div>人民调解员：黄长明 徐根蓉 杨坤 严光明 杨超</div>
                    <div>调委会成立时间：2010年9月</div>
                    <div>办公地址：市市场监管局5楼516室</div>
                    <div>调解纠纷范围：接待简阳市消费者在消费过程中发生矛盾纠纷的来信来访，开展有关法律、法规、规章和政策宣传、咨询工作；根据矛盾纠纷当事人的申请，依据法律、法规、规章、政策以及社会公德，通过说服教育、规劝疏导、协商等方法，调解矛盾纠纷；组织开展矛盾纠纷排查调处工作；积极向有关部门和单位反映矛盾纠纷发生和调解工作情况，提出预防矛盾纠纷发生的建议。</div>
                    <div>4.简阳市婚姻家庭纠纷人民调解委员会</div>
                    <div>联系人：汪莉 电话：15182195820</div>
                    <div>人民调解员：汪莉 张雯</div>
                    <div>调委会成立时间：2014年4月</div>
                    <div>办公地址：妇联办公室3楼307室</div>
                    <div>调解纠纷范围：调解涉及妇女、儿童和老年人权益保护的赡养、抚养、扶养、继承等婚姻家庭纠纷工作；为婚姻家庭纠纷当事人提供法律咨询服务；其他当事人双方愿意调解的婚姻家庭纠纷等。</div>
                    <div>5.简阳市诉讼与调解对接人民调解委员会</div>
                    <div>联系人：冯兰林 电话：13678248612</div>
                    <div>人民调解员：冯简 李木林 袁小琴</div>
                    <div>调委会成立时间：2015年10月</div>
                    <div>办公地址：市人民法院1楼105室</div>
                    <div>调解纠纷范围：受理调处诉前调解的民事案件；调解人民法院委托或移送调解的民事案件和行政赔偿案件；接受人民法院邀请参与民事案件的调解；受理调解诉后执行和解案件。</div>
                    <div>6.简阳市物业纠纷人民调解委员会</div>
                    <div>联系人：曾志玉 电话：18982929380</div>
                    <div>人民调解员：曾志玉 </div>
                    <div>调委会成立时间：2017年11月</div>
                    <div>办公地址：市人民法院1楼104室</div>
                    <div>调解纠纷范围：受理调处在物业管理区域内涉及物业服务、物业使用和维护、物业项目服务管理交接中业主（使用人）之间、业主与业主委员会之间、业主（使用人）或业主委员会与物业服务企业之间、物业服务企业之间发生的各类适用通过人民调解方式解决的民间纠纷。</div>
                    <div>7.简阳市信访事项人民调解委员会</div>
                    <div>联系人：袁平 电话：18708250662</div>
                    <div>人民调解员：王廷刚</div>
                    <div>调委会成立时间：2018年7月</div>
                    <div>办公地址：市信访局1楼</div>
                    <div>调解纠纷范围：受理信访部门委托移送、党委政府交办、其他人民调解组织移送的以下矛盾纠纷：公民之间有关人身损害赔偿、财产权益和其他权益的纠纷；公民与法人之间或其他社会组织之间的民事纠纷；公民、法人或其他社会组织与行政机关之间发生的具有民事权利义务争议的纠纷；其他依法可以通过人民调解处理的信访事项。</div>
                    <div>8.简阳市知识产权纠纷人民调解委员会</div>
                    <div>联系人：曾智慧 电话：181 8848 8760</div>
                    <div>人民调解员：李进 樊亮 李启德</div>
                    <div>调委会成立时间：2020年4月</div>
                    <div>办公地址：市司法局5楼511室</div>
                    <div>调解纠纷范围：受理和调解公民、法人和其他组织之间发生的知识产权权益纠纷和知识产权合同纠纷；开展有关知识产权法律法规和政策的法治宣传教育工作等。</div>
                    <div>9.简阳市劳动纠纷人民调解委员会</div>
                    <div>联系人：罗翠 电话：18328346766</div>
                    <div>人民调解员：罗翠 彭跃平 邓传奎 朱家铖 程正权    李常旺 陈诗宇</div>
                    <div>调委会成立时间：2020年9月</div>
                    <div>办公地址：劳动纠纷一站式联处中心（人社局斜对面）</div>
                    <div>调解纠纷范围：劳动纠纷人民调解委员会主要履行以下职责：（一） 遵循便民、高效的工作原则，劳动人事争议各方当事人自愿平等的基础上，依据法律、法规、规章和政策进行调解，确保调解工作的公开、公平、公正；（二） 组织纠纷当事人及有关部门就劳动人事争议纠纷进行调解，及时化解矛盾，防止劳动人事争议纠纷激化；（三） 对劳动人事争议纠纷各方当事人达成的调解协议的，督促其履行调解协议；（四） 为劳动人事争议各方当事人提供法律咨询；（五） 定期和及时向主管部门汇报劳动人事争议纠纷的调解工作情况。</div>
                </div>
                <div style="padding:15rpx;"></div>
            </div>
            <!--  -->
            <div style="padding:50rpx;"></div>
        </div>
        <div class="bg" v-if="index == 2">
            <div class="introduce-text" style="margin-top:0.40rem;" v-for="(value,index) in lawFirm">
                <div class="introduce-text-title">{{value.company}}</div>
                <div class="introduce-text-content" style="text-indent:2em;">主任：{{value.director}}</div>
                <div class="introduce-text-content" style="text-indent:2em;">联系电话：{{value.telephone}}</div>
                <div class="introduce-text-content" style="text-indent:2em;">地址：{{value.address}}</div>
            </div>
        </div>
        <div class="bg" v-if="index == 3">
            <div v-for="(item,key) in videoList" style="margin-top:0.40rem;border-radius: 0.10rem;background-color: #FFF;text-align:center;padding:0.2rem 0;">
                <div class="introduce-text-title" style="padding-top: 0.25rem;">{{item.name}}</div>
                <div class="frame">
                  <video class="frameTwo" :src="item.src" controls="controls"></video>
                </div>
            </div>
        </div>
        <div class="bg" v-if="index == 4">
            <div class="introduce-text" style="margin-top:0.40rem;" >
                <div @click="pdf('https://ai.lvwa18.com/img/rusticate/audio/pdf/未成年人法律援助宣传手册.pdf','未成年人法律援助宣传手册')">
                    1、未成年人法律援助宣传手册<span style="color:#008fd6;">(查看)</span>
                </div>
            </div>
            <div class="introduce-text" style="margin-top:0.40rem;" >
                <div @click="pdf('https://ai.lvwa18.com/img/rusticate/audio/pdf/农民工法律援助宣传手册.pdf','农民工法律援助宣传手册')">
                    2、农民工法律援助宣传手册<span style="color:#008fd6;">(查看)</span>
                </div>
            </div>
            <div class="introduce-text" style="margin-top:0.40rem;" >
                <div @click="pdf('https://ai.lvwa18.com/img/rusticate/audio/pdf/老年人法律援助宣传手册.pdf','老年人法律援助宣传手册')">
                    3、老年人法律援助宣传手册<span style="color:#008fd6;">(查看)</span>
                </div>
            </div>
            <div class="introduce-text" style="margin-top:0.40rem;" >
                <div @click="pdf('https://ai.lvwa18.com/img/rusticate/audio/pdf/妇女法律援助手册.pdf','妇女法律援助手册')">
                    4、妇女法律援助手册<span style="color:#008fd6;">(查看)</span>
                </div>
            </div>
            <div class="introduce-text" style="margin-top:0.40rem;" >
                <div @click="pdf('https://ai.lvwa18.com/img/rusticate/audio/pdf/残疾人法律援助宣传手册.pdf','残疾人法律援助宣传手册')">
                    5、残疾人法律援助宣传手册<span style="color:#008fd6;">(查看)</span>
                </div>
            </div>
        </div>

        <Modal
                v-model="modal1" width="auto"
                :title="title" ok-text="关闭" cancel-text=" ">
            <div  id="demo"></div>
        </Modal>
    </div>
</template>

<script>
    import Pdfh5 from "pdfh5";
    import "pdfh5/css/pdfh5.css"
    export default {
        name: "jysData",
        data:function(){
            return{
              videoList:[
                {name:'公共法律服务',src:'https://cj.lvwa18.com/static/jy/公共法律服务0528.mp4'},
                {name:'法宣视频',src:'https://cj.lvwa18.com/static/jy/法宣未命名项目-1080p.mp4'},
                {name:'公证宣传视频',src:'https://cj.lvwa18.com/static/customization/jian-yang/videos/f1040b1fba40fd17ad704cb0c0984f2b.mp4'},
                {name:'社区矫正法动漫视频',src:'https://cj.lvwa18.com/static/jy/法宣社区矫正法动漫视频（对外宣传版）.mp4'},
                {name:'防诈骗：偷换二维码',src:'https://resources.lvwa18.com/cj/videos/jian-yang/1-偷换二维码.m4v'},
                {name:'防诈骗：直播带货',src:'https://resources.lvwa18.com/cj/videos/jian-yang/2-直播带货.m4v'},
                {name:'法律援助',src:'https://resources.lvwa18.com/cj/videos/jian-yang/3-法律援助.m4v'},
                {name:'清明节烧纸',src:'https://resources.lvwa18.com/cj/videos/jian-yang/4-清明节烧纸.M4V'},
                {name:'拒绝宰客',src:'https://resources.lvwa18.com/cj/videos/jian-yang/5-拒绝宰客.m4v'},
                {name:'买卖个人账户',src:'https://resources.lvwa18.com/cj/videos/jian-yang/6-买卖个人账户.m4v'},
                {name:'电信诈骗',src:'https://resources.lvwa18.com/cj/videos/jian-yang/7-电信诈骗.m4v'},
                {name:'冒充国家机构诈骗',src:'https://resources.lvwa18.com/cj/videos/jian-yang/8-冒充国家机构诈骗.m4v'},
                {name:'个人担保',src:'https://resources.lvwa18.com/cj/videos/jian-yang/9-个人担保.m4v'},
                {name:'欠钱不还',src:'https://resources.lvwa18.com/cj/videos/jian-yang/10-欠钱不还.m4v'},
                {name:'租房问题',src:'https://resources.lvwa18.com/cj/videos/jian-yang/11-租房问题.m4v'},
                {name:'垃圾分类',src:'https://resources.lvwa18.com/cj/videos/jian-yang/垃圾分类 - 副本.m4v'},
                {name:'离婚？请先冷静',src:'https://resources.lvwa18.com/cj/videos/jian-yang/12-离婚？请先冷静.mp4'},
                {name:'不属于夫妻共同债务，对方有义务偿还吗？',src:'https://resources.lvwa18.com/cj/videos/jian-yang/13-不属于夫妻共同债务，对方有义务偿还吗？.mp4'},
                {name:'法定继承男女平等',src:'https://resources.lvwa18.com/cj/videos/jian-yang/14-法定继承男女平等.mp4'},
                {name:'“典”佑一身—民法典宣传片',src:'https://resources.lvwa18.com/cj/videos/jian-yang/15-“典”佑一身—民法典宣传片.mp4'},
              ],
                modal1: false,
                title :'',
                pdfh5:  null,
                index : '',
                autoplay :false,
                loadingHidden: true,
                lawFirm : [
                    {company:'四川阳安律师事务所',director:'陈洪国',telephone:'13982916622',address:'简阳市河东新区园中苑A区8楼',},
                    {company:'四川瑞能律师事务所',director:'刘怀军',telephone:'13982913636',address:'简阳市河东新区人民路园中苑B区1幢5楼',},
                    {company:'四川得力律师事务所',director:'刘光成',telephone:'13980385045',address:'简阳市河东新区园中苑A区1号楼4楼2号',},
                    {company:'四川衡通律师事务所',director:'罗良宗',telephone:'13708247608',address:'简阳市南街142号',},
                    {company:'四川理创律师事务所',director:'钟  胜',telephone:'13982928799',address:'简阳市河东新区泛月路290-292号',},
                    {company:'四川深兰律师事务所',director:'谭德强',telephone:'13982933639',address:'简阳市射洪路南路236号附201号1层',},
                    {company:'四川品鑫律师事务所',director:'唐德银',telephone:'13982939256',address:'简阳市射洪坝街道办事处人民路397号4层1号',},
                    {company:'四川九匡律师事务所',director:'汪文秀',telephone:'18121878711',address:'简阳市河东新区凌云北路凤凰城4楼1-10号',},
                    {company:'四川简兴律师事务所',director:'邱能',telephone:'15308358418',address:'简阳市射洪坝街道办事处人民路427号2号楼3楼1号',},
                    {company:'四川启发律师事务所',director:'严应兵',telephone:'15983219126',address:'简阳市河东新区鳌山路220号-1层',},
                ]
            }
        },
        methods : {
            pdf(url,title) {
                // window.loacation.href = url
                this.modal1 = true
                this.title = title
                var  pdfh5 = new  Pdfh5('#demo', {    pdfurl:  url});

            },
        },
        mounted(){
            var _this = this
            _this.index = _this.$route.query.index
        },
    }
</script>

<style scoped>
    .bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
    .introduce-video{text-align:center;display: inline-block;padding:0.20rem;border-radius:0.10rem;background-color:rgba(160,160,160,0.5);}
    .introduce-video-player{width:100%;}
    .mediator-list{color:#FFF;}
    .mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.10rem;}
    .mediator-list-content{font-size:0.24rem;margin:0.10rem 0;}
    .introduce-text{background-color:#FFF;padding:0.40rem 0.20rem;border-radius:0.10rem;}
    .introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
    .introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}

    .frame{
        background: linear-gradient(80deg, #006FD6, #4C97FF);
        padding:0.2rem;
        box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);border-radius: 0.04rem;width: 6.90rem;
        display: inline-block;}
    .frameTwo{
        border-radius: 4px;width:100%;
        border: 0.16rem solid rgba(255, 255, 255, 0.5);}
</style>
