<template>
  <div style="padding:20px;">
    <div style="background-color: white;">
      <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px;display: inline-block">
        问卷管理
        <div style="display: inline-block;vertical-align:middle;margin-left:10px">
          <span style="margin-left: 30px">共{{count}}条</span>
        </div>
      </div>
      <div style="display: inline-block;float: right;margin: 0 30px 10px 0">
        <Button v-if="active == '2'" type="primary" @click="addToData()">添加问卷</Button>
      </div>
<!--      <div style="margin: 15px 0">-->
<!--        <DatePicker :value="timeData" v-model="timeData" type="daterange" split-panels placeholder="请选择时间"-->
<!--                    style="width: 200px" @on-change="totalTime3"></DatePicker>-->

<!--        <Select style="width:200px" v-model="model140" filterable remote clearable :remote-method="remoteMethod2"-->
<!--                @on-change="lawyerClick3" :loading="loading2" placeholder="请输入社区">-->
<!--          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">-->
<!--            {{value.VillagesName}}</Option>-->
<!--        </Select>-->
<!--        <div style="width: 300px;display: inline-block;vertical-align: top;">-->
<!--          <Input v-model="userKey" search enter-button placeholder="搜索姓名" @on-search="userSearch" />-->
<!--        </div>-->
<!--      </div>-->
      <Tabs :value="active" @on-click="activeClick">
        <TabPane label="明白人问卷管理" name="1"></TabPane>
        <TabPane :label="information.AreaName+'问卷管理'" name="2"></TabPane>
      </Tabs>
      <div class="publicWelfare" v-if="active == '1'">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>姓名</th>
            <th>试卷标题</th>
            <th>试卷说明</th>
            <th>上传时间</th>
            <th>审核状态</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in adminUserData">
            <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
            <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
            <td nowrap="nowrap">
                <div v-if="value.InsidersNames">{{value.InsidersNames[0].InsiderName}}</div>
                <div v-if="value.InsiderName">{{value.InsiderName}}</div>
            </td>
            <td>{{value.QuestionnaireTitle}}</td>
            <td>{{value.QuestionnaireExplain}}</td>
            <td>{{value.CreateTime}}</td>
            <td>
              <div :style="{'color':value.IsCheck==1?'red':''}">{{value.IsCheck==0?'未审核':value.IsCheck==1?'审核通过':'已驳回'}}</div>
            </td>
            <td nowrap="nowrap">
              <Button type="primary" @click="see(value)">查看</Button>
              <Button type="primary" @click="answerList(value)" style="margin-left: 20px">答卷列表</Button>
<!--              <Button type="primary" @click="modifyForm(value)" style="margin-left: 20px">修改</Button>-->
              <Button type="primary" @click="deleteData(value)" style="margin-left: 20px">删除</Button>
            </td>
          </tr>
          </tbody>
        </table>
        <Page :total="count" :page-size="pageSize" show-elevator show-total
              @on-change="page = $event;aQuestionnaireList(2)" />
      </div>
      <div class="publicWelfare" v-if="active == '2'">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>姓名</th>
            <th>试卷标题</th>
            <th>试卷说明</th>
            <th>上传时间</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in adminUserData">
            <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
            <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
            <td nowrap="nowrap">
                <div v-if="value.admin">{{value.admin.AdminName}}</div>
            </td>
            <td>{{value.QuestionnaireTitle}}</td>
            <td>{{value.QuestionnaireExplain}}</td>
            <td>{{value.CreateTime}}</td>
            <td nowrap="nowrap">
              <Button type="primary" @click="see(value)">查看</Button>
              <Button type="primary" @click="answerList(value)" style="margin-left: 20px">答卷列表</Button>
              <Button type="primary" @click="modifyForm(value)" style="margin-left: 20px">修改</Button>
              <Button type="primary" @click="deleteData(value)" style="margin-left: 20px">删除</Button>
            </td>
          </tr>
          </tbody>
        </table>
        <Page :total="count" :page-size="pageSize" show-elevator show-total
              @on-change="page = $event;questionnaireListByAdmin(2)" />
      </div>
    </div>
<!--    审核-->
    <Modal v-model="modal" :title="seeData.QuestionnaireTitle" width="950" draggable scrollable :styles="{top: '0'}":zIndex="zIndex">
      <Row>
        <Col span="11"  v-for="(value,index) in questionnaireData" style="margin: 20px 0 0 20px">
          <Col  v-if="value.QuestionTypeId == 3" >
            <div >{{value.QuestionTitle}}</div>
            <Input disabled style="width: 300px" />
          </Col>
          <Col  v-if="value.QuestionTypeId == 1">
            <div>{{value.QuestionTitle}}</div>
            <RadioGroup>
              <Radio disabled v-for="(item,k) in value.QuestionContent">{{item.name}}</Radio>
            </RadioGroup>
          </Col>
          <Col v-if="value.QuestionTypeId == 2">
            <div>{{value.QuestionTitle}}</div>
            <CheckboxGroup >
              <Checkbox disabled v-for="(item,k) in value.QuestionContent">{{item.name}}</Checkbox>
            </CheckboxGroup>
          </Col>
        </Col>
      </Row>
      <div slot="footer">
        <template v-if="seeData.IsCheck==0">
          <div style="padding:3px;"></div>
          <!-- /审核失败/未审核 -->
          <Button type="success" @click="toExamine(1,seeData.QuestionnaireId)">通过</Button>
          <span style="padding:5px;"></span>
          <Button type="warning" @click="toReject(seeData.QuestionnaireId)">驳回</Button>
        </template>
        <Button @click="cancel">关闭</Button>
      </div>
    </Modal>
    <Modal v-model="modal6" title="驳回理由" @on-ok="toRejectDetermine" ok-text="发送" style="height: 300px" draggable
    >
      <Input v-model="checkMsg" maxlength="300" show-word-limit type="textarea" placeholder="驳回理由" />
    </Modal>
<!--   答卷列表 -->
    <Modal v-model="modal2" :title="seeData.QuestionnaireTitle" width="950" draggable scrollable :styles="{top: '0'}":zIndex="zIndex">
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>姓名</th>
            <th>开始时间-结束时间</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in aQRespondentData">
            <td>
              {{value.user.UserName}}</td>
            <td>{{value.CreateTime}}-{{value.EndTime}}</td>
            <td>
              <Button type="primary" @click="clickDetails(value)">详情</Button>
            </td>
          </tr>
          </tbody>
        </table>
        <Page :total="answerCount" :page-size="pageSize" show-elevator show-total
              @on-change="answerpage = $event;answerList(seeData)" />
      </div>
    </Modal>
    <Modal v-model="modal3" :title="seeData.QuestionnaireTitle" width="950" draggable scrollable :styles="{top: '0'}":zIndex="zIndex">
      <Row>
<!--        <Col span="11"  v-for="(value,index) in aGetQRespondentData" style="margin: 20px 0 0 20px">-->
<!--          <Card v-if="value.QuestionTypeId == 3" >-->
<!--            <p slot="title" >文本框说明文字：{{value.QuestionTitle}}</p>-->
<!--            <div >{{value.AnswerContent}}</div>-->
<!--          </Card>-->
<!--          <Card v-if="value.QuestionTypeId == 1">-->
<!--            <p slot="title" >单选说明文字：{{value.QuestionTitle}}</p>-->
<!--            <div v-for="(item,k) in value.AnswerContent" :style="{'color':item.checked?'red':''}">{{k+1}}、{{item.name}}</div>-->
<!--          </Card>-->
<!--          <Card v-if="value.QuestionTypeId == 2">-->
<!--            <div>{{value.QuestionTitle}}</div>-->
<!--            <Checkbox disabled v-for="(item,k) in value.AnswerContent" v-model="item.checked">{{item.name}}</Checkbox>-->
<!--          </Card>-->
<!--        </Col>-->
        <Col span="11"  v-for="(value,index) in aGetQRespondentData" style="margin: 20px 0 0 20px">
          <Col  v-if="value.QuestionTypeId == 3" >
            <div >{{value.QuestionTitle}}</div>
            <Input disabled style="width: 300px" v-model="value.AnswerContent"/>
          </Col>
          <Col  v-if="value.QuestionTypeId == 1">
            <div>{{value.QuestionTitle}}</div>
            <Radio disabled v-for="(item,k) in value.AnswerContent" v-model="item.checked">{{item.name}}</Radio>
          </Col>
          <Col v-if="value.QuestionTypeId == 2">
            <div>{{value.QuestionTitle}}</div>
            <Checkbox disabled v-for="(item,k) in value.AnswerContent" v-model="item.checked">{{item.name}}</Checkbox>
          </Col>
        </Col>
      </Row>
    </Modal>
<!--    新建-->
    <Modal
        v-model="questionnaireShow"
        title="问卷"
        @on-ok="submitQuestionnaire" @on-cancel="closeform" @close="closeform">
      <Row >
        <Form :model="questionnaireForm" :label-width="80">
          <Input v-model="questionnaireForm.QuestionnaireTitle"  placeholder="试卷标题"></Input>
          <div style="padding: 8px"></div>
          <Input v-model="questionnaireForm.QuestionnaireExplain" type="textarea" placeholder="试卷说明"></Input>
          <div style="padding: 8px"></div>
          <Checkbox v-if="articlePushModal.accountList != ''" :indeterminate="indeterminate1" :value="checkAll1" @click.prevent.native="secondLevel">全选</Checkbox>
          <CheckboxGroup v-model="checkAllGroup1" @on-change="">
            <Checkbox v-for="(value,index) in articlePushModal.accountList" :label="value.AdminId">{{value.AdminName}}</Checkbox>
          </CheckboxGroup>
          <div style="padding: 8px"></div>
          <Col span="8" style="text-align: center">
            <Button type="primary" shape="circle" icon="logo-tumblr" @click="addForm(3)">文本框</Button>
          </Col>
          <Col span="8" style="text-align: center">
            <Button type="primary" shape="circle" icon="md-radio-button-on" @click="addForm(1)">单选按钮</Button>
          </Col>
          <Col span="8" style="text-align: center">
            <Button type="primary" shape="circle" icon="md-checkbox" @click="addForm(2)">多选按钮</Button>
          </Col>
          <div style="padding: 24px"></div>
          <Col span="24" v-for="(value,index) in questionnaireAdd" >
            <Col span="24" v-if="value.QuestionTypeId == 3">
              <FormItem label="文本框说明文字" :label-width="120">
                <Input v-model="value.QuestionTitle" placeholder="请输入说明文字">
                  <Button slot="append" icon="md-close" style="color: #ff0000;" @click="deleteButton(index)"></Button>
                </Input>
              </FormItem>
            </Col>
            <!--          单选-->
            <Col span="24"  v-if="value.QuestionTypeId == 1">
              <FormItem label="单选说明文字" :label-width="100" style="margin-bottom: 10px">
                <Input v-model="value.QuestionTitle" placeholder="请输入单选说明文字">
                  <span slot="append" style="cursor: pointer" @click="addButton(index)">添加单选按钮</span>
                  <Button slot="append" icon="md-close" style="color: #ff0000;" @click="deleteButton(index)"></Button>
                </Input>
              </FormItem>
              <Col span="8" v-for="(item,j) in value.QuestionContent" style="padding: 0 10px 10px 0">
                <Input v-model="item.name" placeholder="请输入单选内容">
                  <Button slot="append" icon="md-close" style="color: #ff0000;" @click="deleteButton(index,j)"></Button>
                </Input>
              </Col>
            </Col>
            <!--          多选-->
            <Col span="24" style="margin-top: 10px"  v-if="value.QuestionTypeId == 2">
              <FormItem label="多选说明文字" :label-width="100" style="margin-bottom: 10px">
                <Input v-model="value.QuestionTitle" placeholder="请输入多选说明文字">
                  <span slot="append" style="cursor: pointer" @click="addButton(index)">添加多选按钮</span>
                  <Button slot="append" icon="md-close" style="color: #ff0000;" @click="deleteButton(index)"></Button>
                </Input>
              </FormItem>
              <Col span="8" v-for="(item,j) in value.QuestionContent" style="padding: 0 10px 10px 0">
                <Input v-model="item.name" placeholder="请输入多选内容">
                  <Button slot="append" icon="md-close" style="color: #ff0000;" @click="deleteButton(index,j)"></Button>
                </Input>
              </Col>
            </Col>
          </Col>

        </Form>

      </Row>


    </Modal>
  </div>
</template>

<script>
export default {
  name: "userAdministration",
  data() {
    return {
      adminUserData:[],
      information:[],
      page : 1,
      answerpage : 1,
      pageSize : 15,
      startTime : '',
      endTime : '',
      timeData: '',
      model140: false,
      loading2: false,
      modal: false,
      modal2: false,
      modal3: false,
      modal6: false,
      options2: [],
      seeData: [],
      questionnaireData: [],
      userKey : '',
      QuestionnaireId : '',
      checkMsg : '',
      answerCount : 0,
      zIndex : 9,
      aQRespondentData : [],
      aGetQRespondentData : [],
      count : 0,
      questionnaireShow : false,
      InsiderId : '',
      questionnaireForm : {
        QuestionnaireTitle : '',
        QuestionnaireExplain : '',
        QuestionnaireId : '',
        VillagesArr : [],
      },
      questionnaireAdd : [],
      adminLevel : '',
      serachData : [],
      serachValue : [],
      VillageByData : [],
      VillagesId : [],
      searchLaw : '',
      villageByPage : 1,
      villageByCount : 0,
      modelMbr : [],
      adminLawyerList : [],
      currentMbr : 1,
      totalMbr  : 0,
      modifyID : '',
    //
      active : '1',
      checkAllGroup1: [],
      articlePushModal:{
        state:false,
        id:'',
        pushList:[],
        accountList:[],
        vallageList:[],
        accountIdList:[],
      },
      indeterminate1: true,
      checkAll1: false,
    }
  },
  methods:{
    // 管理端上传
    questionnaireListByAdmin:function (){
      var _this = this
      _this.send({
        url: '/QuestionnaireListByAdmin',
        data: {
          AdminId: _this.information.AdminId,
          typeId: 4,
          page: _this.page,
          pageSize: _this.pageSize,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime,
          VillagesId: _this.VillagesId.toString(),
          key : _this.userKey,
        },
        success: function (res) {
          _this.adminUserData = res.List
          _this.count = res.Count
        }
      })
    },
    activeClick : function (e) {
      this.active = e
      this.adminUserData = []
      this.page = 1
      this.count = 0
      if(e == 1){this.aQuestionnaireList()}
      if(e == 2){this.questionnaireListByAdmin()}
    },
    clickDetails : function (e) {
      var _this = this
      _this.send({
        url: '/AGetQRespondent',
        data: {
          AdminId: _this.information.AdminId,
          QRespondentId : e.QRespondentId,
        },
        success: function (res) {
          _this.modal3 = true
          _this.aGetQRespondentData = res
        }
      })
    },
    answerList : function (e){
      this.seeData = e
      var _this = this
      _this.send({
        url: '/AQRespondentList',
        data: {
          AdminId: _this.information.AdminId,
          QuestionnaireId : e.QuestionnaireId,
          page : _this.answerpage,
        },
        success: function (res) {
          _this.modal2 = true
          _this.aQRespondentData = res.List
          _this.answerCount = res.Count
        }
      })
    },
    see : function (e){
      this.seeData = e
      var _this = this
      _this.send({
        url: '/AQuestionnaireContent',
        data: {
          AdminId: _this.information.AdminId,
          QuestionnaireId : e.QuestionnaireId,
          InsiderId : e.InsiderId,
          page : _this.page,
        },
        success: function (res) {
          _this.modal = true
          _this.questionnaireData = res.data
        }
      })
    },
    userSearch : function (e){
      this.userKey = e
      this.aQuestionnaireList()
    },
    aQuestionnaireList:function (){
      var _this = this
      _this.send({
        url: '/AQuestionnaireList',
        data: {
          AdminId: _this.information.AdminId,
          typeId: 4,
          page: _this.page,
          pageSize: _this.pageSize,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime,
          VillagesId: _this.VillagesId.toString(),
          key : _this.userKey,
        },
        success: function (res) {
          _this.adminUserData = res.List
          _this.count = res.Count
        }
      })
    },
    totalTime3: function (e) {
      var _this = this
      _this.startTime = e[0],//开始时间
          _this.endTime = e[1]//结束时间
      _this.page = 1
      _this.aQuestionnaireList(2)
    },
    lawyerClick3: function (e) {
      var _this = this
      if (e == undefined) {
        _this.VillagesId = ''
        _this.options2 = []
      } else {
        _this.VillagesId = e
      }
      _this.page = 1
      _this.aQuestionnaireList(2)
      console.log(e)
    },
    remoteMethod2(query) {
      console.log(query)
      var _this = this
      _this.send({
        url: '/villagesList',
        data: {
          AdminId: _this.information.AdminId,
          Key: query,
        },
        success: function (res) {
          console.log(res)
          _this.options2 = res.list
        }
      })
    },
    cancel() {
      this.seeData = []
      this.modal = false
    },
    toRejectDetermine: function () {
      var _this = this
      if (_this.checkMsg) {
        _this.send({
          url: '/AUpdateQuestionnaire',
          data: {
            IsCheck: 2,
            QuestionnaireId: _this.QuestionnaireId,
            CheckMsg: _this.checkMsg,
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            _this.modal = false
            _this.aQuestionnaireList()
          }
        })
      } else {
        _this.$Message.error('驳回理由不能为空');
      }

    },
    addToData : function (){
      var _this = this
      this.questionnaireShow = true
      this.questionnaireForm = []
      this.VillagesId = []
      this.questionnaireAdd = []
      this.modelMbr = ''
      _this.send({
        url : '/justiceList',
        data: {
          AdminId : window.sessionStorage.AdminId,
          Level : _this.information.adminLevel,
          page: _this.page,
          pageSize : 9999,
        },
        success: function (res) {
          _this.articlePushModal.accountList = res.list
          _this.articlePushModal.state = true
          console.log(res.list)
        }
      })
    },
    toReject: function (QuestionnaireId) {
      var _this = this
      _this.QuestionnaireId = QuestionnaireId
      _this.modal6 = true
    },
    toExamine: function (isCheck, QuestionnaireId) {
      var _this = this
      _this.send({
        url: '/AUpdateQuestionnaire',
        confirm: true,
        data: {
          IsCheck: isCheck,
          QuestionnaireId: QuestionnaireId,
          AdminId: _this.information.AdminId,
          CheckMsg : '',
        },
        success: function (res) {
          _this.modal = false
          _this.aQuestionnaireList()
        }
      })
    },
  //  添加问卷

    serachSelectPage : function (value){
      var _this = this
      console.log(value)
      _this.villageByPage = value
      _this.VillageByAdmin()
    },
    serachSelect : function (value, selectedData){
      var _this = this
      console.log(value, selectedData)
      if(value != ''){
        _this.serachAdminId = value[value.length-1]
        _this.VillageByAdmin()
      }else {
        _this.VillageByData = []
      }
    },
    villageByQuery : function (e){
      console.log(e,this.VillagesId)
      this.serachkey = e
      this.villageByPage = 1
      this.villageByCount = 0
      this.VillageByAdmin()
    },
    villageByOpen : function (e){
      console.log(e)
    },
    VillageByAdmin : function (e){
      console.log(e)
      var _this = this
      if(_this.modelMbr != ''){
        _this.send({
          url: '/AInsiderByVillages',
          data: {
            InsiderId : _this.modelMbr.toString(),
          },
          success: function(res) {
            _this.VillageByData = res
            // _this.villageByCount = res.count
          }
        })
      }
    },

    CqAdminSerach : function (){
      var _this = this
      var data = []
      _this.send({
        url: '/CqAdminSerach',
        data: {
          AdminId: window.sessionStorage.AdminId,
          Level: window.sessionStorage.adminLevel,
          page : 1,
          pageSize : '999',
        },
        success: function(res) {
          for(var i in res.list){
            data.push(res.list[i])
          }
          convertTree(data)
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let {
                AdminId: value,
                AdminName: label,
                Level : Level,
                Pid : Pid,
                list: children
              } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                Level,
                Pid,
                children
              })
            })
            _this.serachData = result
            return result
          }
        }
      })
    },

    onSearchMbr : function (e){
      this.searchLaw = e
      this.currentMbr = 1
      this.totalMbr = 0
      this.lawyerList = []
      this.serachInsider()
    },

    serachInsider : function (value){
      var _this = this
      _this.send({
        url: '/AAdminInsiderList',
        data: {
          AdminId: _this.information.AdminId,
          page: _this.currentMbr,
          pageSize: _this.pageSize,
          key: _this.searchLaw
        },
        success: function (res) {
          _this.adminLawyerList = res.List
          _this.totalMbr = res.count
        }
      })
    },
    handlePageName : function (e){
      console.log(e)
      this.currentMbr = e
      this.serachInsider()
    },
    //关闭添加表单
    closeform : function (){
      this.VillagesId = []
      this.modelMbr = []
      this.adminLawyerList = []
      this.VillagesId = []
      this.VillageByData = []
      this.questionnaireForm.QuestionnaireTitle = ''
      this.questionnaireForm.QuestionnaireExplain = ''
      this.questionnaireForm.QuestionnaireId = ''
      this.questionnaireForm.VillagesArr = []
      this.modifyID = ''
      this.articlePushModal.accountList = []

    },
    modifyForm : function (value){
      var _this = this
      this.modifyID = value.QuestionnaireId

      this.modelMbr = []
      this.adminLawyerList = []
      this.VillagesId = []
      this.VillageByData = []
      if(value.InsiderId){this.modelMbr = value.InsiderId}
      _this.VillagesId = []
      _this.send({
        url: '/FQuestionnaireDetail',
        data: {
          InsiderId:value.InsiderId,
          QuestionnaireId : value.QuestionnaireId,
        },
        success: function (res) {
            console.log(res)
            _this.questionnaireShow = true
            _this.questionnaireForm.QuestionnaireExplain = res.Data.QuestionnaireExplain
            _this.questionnaireForm.QuestionnaireTitle = res.Data.QuestionnaireTitle
            for(var i in res.Data.InsiderArr){
              _this.modelMbr.push(res.Data.InsiderArr[i].InsiderId)
              _this.adminLawyerList.push(res.Data.InsiderArr[i])
            }
            for(var i in res.Data.Villages){
              console.log(res.Data.Villages[i].VillagesId)
              _this.VillagesId.push(res.Data.Villages[i].VillagesId)
              _this.VillageByData.push(res.Data.Villages[i])
            }
          _this.FQuestionnaireContent(_this.modelMbr,value.QuestionnaireId)
          _this.VillageByAdmin()
          console.log(typeof _this.VillagesId,_this.modelMbr)
        }
      })

    },
    FQuestionnaireContent : function (modelMbr,QuestionnaireId){
      var _this = this
      _this.send({
        url: '/QuestionnaireContentByAdmin',
        data: {
          InsiderId:modelMbr.toString(),
          QuestionnaireId : QuestionnaireId,
        },
        success: function (res) {
          _this.questionnaireAdd = res.data
        }
      })
    },
    submitQuestionnaire : function (id){
      console.log(this.modelMbr)
      console.log(this.VillagesId)
      var _this = this
      if(_this.modifyID){
        _this.send({
          url: '/UpdateQuestionnaireByAdmin',
          data: {
            // InsiderId:_this.modelMbr.toString(),
            QuestionnaireExplain : _this.questionnaireForm.QuestionnaireExplain,
            QuestionnaireTitle : _this.questionnaireForm.QuestionnaireTitle,
            // VillagesArr:_this.VillagesId.toString(),
            // AdminIdArr : res.toString(),
            QuestionnaireId:_this.modifyID,
          },
          success: function (res) {
            _this.questionnaireListByAdmin()

            _this.questionnaireForm.QuestionnaireTitle = ''
            _this.questionnaireForm.QuestionnaireExplain = ''
            _this.questionnaireForm.QuestionnaireId = ''
            _this.questionnaireForm.VillagesArr = []
            _this.modifyID = ''
          }
        })
        _this.fUpdateQuestion()
      }else {
        _this.send({
          url : '/AdminArrArticle',
          data: {
            AdminIdArr : _this.checkAllGroup1.toString(),
            typeId :4,
            page : 1,
            pageSize :99999999,
            key :'',
            start_time : '' ,//开始时间
            end_time :'',
            sort :''
          },
          success: function (res) {

            _this.send({
              url: '/AddQuestionnaireByAdmin',
              data: {
                // InsiderId:_this.modelMbr.toString(),
                QuestionnaireExplain : _this.questionnaireForm.QuestionnaireExplain,
                QuestionnaireTitle : _this.questionnaireForm.QuestionnaireTitle,
                // VillagesArr:_this.VillagesId.toString(),
                AdminIdArr : res.toString(),
              },
              success: function (res) {
                _this.questionnaireListByAdmin()
                _this.fUpdateQuestion(res)
                _this.questionnaireForm.QuestionnaireTitle = ''
                _this.questionnaireForm.QuestionnaireExplain = ''
                _this.questionnaireForm.QuestionnaireId = ''
                _this.questionnaireForm.VillagesArr = []
                _this.modifyID = ''
              }
            })

          }
        })
      }
    },
    fUpdateQuestion : function (id){
      var _this = this
      console.log(id,_this.questionnaireAdd)
      for(var i in _this.questionnaireAdd){
        if(_this.questionnaireAdd[i].QuestionId){
          _this.send({
            url: '/UpdateQuestionByAdmin',
            data: {
              QuestionnaireId:id||_this.modifyID,
              QuestionTypeId : _this.questionnaireAdd[i].QuestionTypeId,
              QuestionTitle : _this.questionnaireAdd[i].QuestionTitle,
              QuestionContent : _this.questionnaireAdd[i].QuestionContent,
              QuestionId : _this.questionnaireAdd[i].QuestionId,
              // InsiderId:_this.modelMbr.toString(),
            },
            success: function (res) {

            }
          })
        }else{
          _this.send({
            url: '/AddQuestionByAdmin',
            data: {
              QuestionnaireId:id||_this.modifyID,
              QuestionTypeId : _this.questionnaireAdd[i].QuestionTypeId,
              QuestionTitle : _this.questionnaireAdd[i].QuestionTitle,
              QuestionContent : _this.questionnaireAdd[i].QuestionContent,
              QuestionId : _this.questionnaireAdd[i].QuestionId,
              // InsiderId:_this.modelMbr.toString(),
            },
            success: function (res) {

            }
          })
        }
      }
    },
    addForm : function (type){
      console.log(type)
      this.questionnaireAdd.push({
        QuestionTitle: '',
        QuestionTypeId: type,
        QuestionContent: []
      })
      console.log(this.questionnaireAdd);
    },
    addButton : function (index){
      console.log(index)
      this.questionnaireAdd[index].QuestionContent.push({name:'',index:index,checked:false})
      console.log(this.questionnaireAdd[index].QuestionContent)
    },
    deleteButton : function (index,j){
      var _this = this
      if(j){
        this.questionnaireAdd[index].QuestionContent.splice(j, 1)
      }else {
        console.log(this.questionnaireAdd[index])
        if(this.questionnaireAdd[index].QuestionId){
          _this.send({
            url: '/DelQuestionByAdmin',
            data: {
              QuestionId:_this.questionnaireAdd[index].QuestionId,
              QuestionnaireId:_this.questionnaireAdd[index].QuestionnaireId,
              InsiderId:_this.modelMbr,
            },
            success: function (res) {
              _this.questionnaireAdd.splice(index, 1)
            }
          })
        }else {
          this.questionnaireAdd.splice(index, 1)
        }
      }
      console.log(this.questionnaireAdd)
    },
    deleteData : function (value){
      var _this = this
      if(_this.active == '1'){
        _this.send({
          url: '/FDelQuestionnaire',
          confirm: true,
          data: {
            AdminId: _this.information.AdminId,
            QuestionnaireId : value.QuestionnaireId,
            InsiderId :value.InsiderId
          },
          success: function (res) {
            _this.modal = false
            _this.aQuestionnaireList()
          }
        })
      }else {
        _this.send({
          url: '/DelQuestionnaireByAdmin',
          confirm: true,
          data: {
            AdminId: _this.information.AdminId,
            QuestionnaireId : value.QuestionnaireId,
            InsiderId :value.InsiderId
          },
          success: function (res) {
            _this.modal = false
            _this.questionnaireListByAdmin()
          }
        })
      }
    },
    secondLevel : function(){
      var _this = this
      console.log(_this.checkAllGroup1)
      var checkAllGroup1 = []//全选
      var back = []//反选
      console.log(_this.checkAllGroup1.length,_this.articlePushModal.accountList.length)
      console.log(_this.checkAllGroup1.length,_this.articlePushModal.accountList.length)
      if(_this.checkAllGroup1.length == 0){
        //全选
        for(var i in _this.articlePushModal.accountList){
          checkAllGroup1.push(_this.articlePushModal.accountList[i].AdminId)
        }
        _this.checkAllGroup1 = checkAllGroup1
        console.log(_this.checkAllGroup1)
      }
      else{
        //反选
        console.log(_this.checkAllGroup1)
        for(var i in _this.articlePushModal.accountList){
          checkAllGroup1.push(_this.articlePushModal.accountList[i].AdminId)
        }
        // 首先构造Object
        const idSet = _this.checkAllGroup1.reduce((acc, v) => {
          acc[v] = true;
          return acc;
        }, {});
        // 遍历list2，去掉在idSet中存在的id
        const result = checkAllGroup1.filter(v => !idSet[v]);
        _this.checkAllGroup1 = result
        console.log(2)
      }
    },
  },
  mounted() {
    var _this = this
    this.information = window.sessionStorage
    this.aQuestionnaireList()
  //  添加
    _this.adminLevel = window.sessionStorage.adminLevel
    _this.CqAdminSerach()
    _this.serachInsider()
  },
}
</script>

<style scoped>

/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>
