<template>
    <div class="informationFillin">

<!--      0-->
      <!--v-if="SupplementContent.sfcl"-->
<!--      1-->
      <van-form @submit="submit"><!--对方当事人信息-->
        <van-field
            readonly
            clickable
            name="SupplementContent.legalhelpreasonname"
            :value="SupplementContent.legalhelpreasonname"
            placeholder="选择类别" label-width="10em"
            @click="dropDownChoice('yz')" :rules="[{ required: true, message: '' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请类别：</div></div></van-field>
        <van-field
            readonly
            clickable
            name="SupplementContent.webcrowdtypename"
            :value="SupplementContent.webcrowdtypename"
            placeholder="选择类别" label-width="10em"
            @click="dropDownChoice('lb')" :rules="[{ required: true, message: '' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>人群类型：</div></div></van-field>
        <van-field
            readonly
            clickable
            name="SupplementContent.monthlyincomename"
            :value="SupplementContent.monthlyincomename"
            placeholder="选择月收入范围" label-width="10em"
            @click="dropDownChoice('sr')"  :rules="[{ required: true, message: '' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>家庭月收入范围：</div></div></van-field>
        <van-field
            v-model="formValidate.caseremark"
            name="formValidate.caseremark" type="textarea"
            placeholder="申请援助事项信息"label-width="12em"
            :rules="[{ required: true, message: '' }]"
        >
          <div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请事项内容说明：</div></div>
        </van-field>
        <van-field
            v-model="formValidate.phone"
            name="sqrsjhm"
            placeholder="请输入"label-width="8em"
            :rules="[{ required: true, message: '联系电话' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>联系电话：</div></div></van-field>
        <!--上传身份证-->
        <div style="margin: 0.15rem;">
          <div class="inline-block"><span style="color: red;padding: 0 0.2rem;">*</span>身份证：</div>
          <div style="text-align: center;">
            <van-uploader :after-read="afterRead" >
              <img v-if="SupplementContent.sfcl" :src="SupplementContent.sfcl" style="width:3.6rem;height:2.5rem;"/>
              <img v-else src="/img/legalAid/IDcard1.png" style="width:3.6rem;height:2.5rem;"/>
              <div>点击拍摄/更换身份证照片</div>
              <div>1.上传申请人身份证，自动录入信息；</div>
              <div>2.身份证上的信息不能被遮挡，且清晰可见；</div>
              <div>3.支持jpg/bmp格式，最大不超过2MB。</div>
            </van-uploader>
          </div>
        </div>
        <div style="padding: 0 0.2rem;" class="genderDateNation">
          <div style="display:inline-block;width: 50%;border-bottom: 0.01rem solid #e8e8e8;">
            <van-field
                v-model="formValidate.name"
                name="sqrxm"
                placeholder="请输入"
                :rules="[{ required: true, message: '请填写姓名' }]"
            >
              <div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>姓名：</div></div>
            </van-field>
          </div>
          <div style="display:inline-block;width: 50%;border-bottom: 0.01rem solid #e8e8e8;">
            <van-field
                readonly
                clickable
                name="SupplementContent.sex"
                :value="SupplementContent.sex"
                placeholder="性别"
                @click="dropDownChoice('xb')" :rules="[{ required: true, message: '性别' ,trigger :'onChange'}]"
            ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>性别：</div></div></van-field>
          </div>
        </div>
        <!--        性别、民族、日期-->
        <div style="padding: 0 0.2rem;" class="genderDateNation">
          <div style="display:inline-block;width: 50%;border-bottom: 0.01rem solid #e8e8e8;">
            <van-field
                readonly
                clickable
                v-model="mz"
                name="mz"
                placeholder="民族"
                @click="dropDownChoice('mz')" :rules="[{ required: true, message: '请选择民族',trigger :'onChange' }]"
            ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>民族：</div></div></van-field>
          </div>
          <div style="display:inline-block;width: 50%;border-bottom: 0.01rem solid #e8e8e8;">
            <van-field
                v-model="SupplementContent.csrq"
                name="csrq"
                placeholder="出生日期" :rules="[{ required: true, message: '出生日期' ,trigger :'onChange'}]"
            ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>出生日期：</div></div></van-field>
          </div>
        </div>

        <!--        -->
        <van-field
            v-model="formValidate.identitycardnumber"
            name="sqrsfzh"
            placeholder="请输入"label-width="8em"
            :rules="[{ required: true, message: '请填写身份证号码' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证号码：</div></div></van-field>

        <van-field readonly clickable
                   v-model="formValidate.familyaddress"
                   name="sqrdz"
                   placeholder="请输入"label-width="8em" @click="showRegion = true;address = 1"
                   :rules="[{ required: true, message: '' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>居住地址：</div></div></van-field>

        <van-field
            v-model="formValidate.opposingname"
            name="sqrxm"
            placeholder="请输入"label-width="12em"
            :rules="[{ required: true, message: '请填写姓名' }]"
        >
          <div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>对方当事人姓名：</div></div>
        </van-field>
        <van-field readonly clickable
                   v-model="formValidate.dfFamilyaddress"
                   name="sqrdz"
                   placeholder="请输入"label-width="12em" @click="showRegion = true;address = 2"
                   :rules="[{ required: true, message: '' }]"
        ><div slot="label"><div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>对方当事人住所地：</div></div></van-field>
<!--        多文件上传-->
        <div style="margin: 0.3rem 0 0.1rem 0.3rem;font-weight: bold"> 材料上传</div>
        <div style="margin-left: 0.3rem">

          <van-uploader v-model="fileList" multiple  :after-read="multipleFiles" :before-delete="deleteImg"/>
        </div>

        <div style="padding: 0.6rem"></div>
        <van-button round block type="info" native-type="submit" style="width: 100%;background-color: rgb(45 140 240);height: 0.8rem;line-height: 0.8rem;color: #FFF;font-size: 0.32rem;font-weight: bold;text-align: center;position: fixed;bottom: 0;border-radius: 0;right: 0">
          提交
        </van-button>
      </van-form>

<!--        2-->
        <!--下拉-->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="dropDownData"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
            ></van-picker>
        </van-popup>
        <!--出生日期-->
        <van-popup v-model="birthday" position="bottom">
            <van-datetime-picker v-model="currentDate"
                                 type="date"
                                 @confirm="onBirthday"
                                 @cancel="birthday = false"
                                 :min-date="minDate" :max-date="maxDate"
            ></van-datetime-picker>
        </van-popup>
        <!--预约时间-->
        <van-popup v-model="aboutTime" position="bottom">
            <van-datetime-picker v-model="currentDate"
                                 type="datetime"
                                 @confirm="onAboutTime"
                                 @cancel="aboutTime = false"
                                 :min-date="minDate" :max-date="maxDate"
            ></van-datetime-picker>
        </van-popup>
        <!--地区-->
        <van-popup v-model="showRegion" position="bottom">
            <van-picker show-toolbar title="" :columns="addressData" @confirm="regionDetermine"/>
        </van-popup>
    </div>
</template>

<script>
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Toast } from 'vant';
    import { Uploader } from 'vant';
    import axios from 'axios'
    import { ref } from 'vue';
    export default {
        name: "cqslegalAidInput",
        data (){
            return {
                addressData : [],
                //上传身份证
                uploadPicture: [],
                address : '',
                crowd : [],
                monthly : [],
                legalAid : [],
                currentDate: new Date(),
                birthday: false,
                aboutTime: false,
                showRegion: false,
                riseStop : '',
                dropDownData : [],
                dispute : [],
                nation : [],
                minDate: new Date(1900, 0, 1),
                maxDate : new Date(),
                sex : '',
                humantype : '',
                UserId:'',
                AreaId:'',
                totalDropDownData : {
                    xb : ['男','女'],
                    lb : [],
                    sr : [],
                    yz : [],
                    mz : [],
                },
                type : '',
                showPicker: false,
                active : 0,
                formValidate :{
                    name:'',
                    phone:'',
                    sex:'',
                    csrq : '',
                    identitycardnumber : '',
                    familyregincode : '',
                    humantype : '',
                    webcrowdtype : '',
                    monthlyincome: '',
                    opposingname : '',
                    opposingpartyaddressregincode : '',//对方当事人居住地区划编码
                    orginregincode : '',
                    familyaddress :'',
                    dfFamilyaddress :'',
                    legalhelpreason : '',
                    caseremark : '',
                },
                SupplementContent:{
                    sex : '',
                    mz : '',
                    csrq : '',
                    webcrowdtypename: "",
                    humantypename: "",
                    monthlyincomename: "",
                    legalhelpreasonname: "",
                  dfFamilyaddress: "",
					photoids : [],
					ocr : [],
					sfcl : '',
                },
              mz : '',
                content : {
                    thirdFlow:'',
                    baseCode:'fwaj',
                    data : []
                },
            //  多文件上传
              fileList : [],
              fileData : [],
            }
        },
        methods:{
          afterRead(file) {
            // 此时可以自行将文件上传至服务器file: (binary)Identification
            console.log(file);
            var _this = this;
            var param = new FormData(); //创建form对象
            param.append('file', file.file);//通过append向form对象添加数据
            // param.append('MenuId', _this.menuId);
            var config = {}
            axios.post('https://cq.lvwa18.com:8001/api/upload_file_H5', param, config)
                .then(response => {
                  console.log(response)
                  _this.SupplementContent.sfcl = 'https://cq.lvwa18.com:8001/'+response.data.Data.path
                  console.log('https://cq.lvwa18.com:8001/'+response.data.Data.path)
                  _this.Picture(response.data)
                })
          },
            regionDetermine : function(value,index){
                console.log(this.addressData[index[0]])
                if(this.address == 1){
                    this.formValidate.familyaddress = this.addressData[index[0]].text+this.addressData[index[0]].children[index[1]].text
                    this.formValidate.familyregincode = this.addressData[index[0]].children[index[1]].AreaCode//申请人居住地区划编码
                    this.formValidate.orginregincode = this.addressData[index[0]].children[index[1]].AreaCode//申请人户籍地区划编码
                }
                if(this.address == 2){
                    this.SupplementContent.dfFamilyaddress = this.addressData[index[0]].text+this.addressData[index[0]].children[index[1]].text
                    this.formValidate.dfFamilyaddress = this.addressData[index[0]].text+this.addressData[index[0]].children[index[1]].text
                    this.formValidate.opposingpartyaddressregincode = this.addressData[index[0]].children[index[1]].AreaCode//对方当事人居住地区划编码
                }

                this.showRegion = false
            },
            deleteValuePicture: function(file, fileList) {
                console.log(file, fileList)
            },
            Picture: function(response, file, fileList) {
                console.log(response)
                var _this = this;
                _this.send({
                    url:'/Identification',
                    data:{
                        ImgPath:'https://cq.lvwa18.com:8001/'+response.Data.path
                    },
                    success:function(res){
                        _this.SupplementContent.photoids = response.Data
                        _this.SupplementContent.sfcl = 'https://cq.lvwa18.com:8001/'+response.Data.path
                        if(res.Sex == '男'){_this.formValidate.sex = 1}else{_this.formValidate.sex = 2}
                        _this.SupplementContent.sex = res.Sex
                        _this.formValidate.name = res.Name
                        if(res.Nation == '汉'){
                          _this.formValidate.mz = 1,
                          _this.mz = res.Nation +'族'
                          _this.SupplementContent.mz = res.Nation +'族'
                        }
                        _this.SupplementContent.csrq = res.Birth
                        _this.formValidate.csrq = res.Birth
                        _this.formValidate.opposingname = res.Name
                        _this.formValidate.identitycardnumber = res.IdNum
                        //地址
                        // _this.formValidate.familyaddress = res.Address
                        // _this.formValidate.dfFamilyaddress = res.Address
                        _this.SupplementContent.ocr = res
                        console.log(_this.SupplementContent)
                    }
                })
            },
            submit : function(){
                console.log(this.formValidate)
                var _this = this
                _this.send({
                    url:'/CqFlyzApply',
                    data :{
                        content:_this.formValidate,
                        UserId:window.sessionStorage.userId,
                        AreaId:window.sessionStorage.AreaId,
                        type:_this.type,
                        SupplementContent : _this.SupplementContent,
                        SupplementFile : _this.fileData.join(",")
                    },
                    success:function(res){
                        console.log(res)
                        window.history.go(-1)
                        Toast('提交成功');
                    }
                })
            },
            nexStep : function(values){
                this.active++
                console.log('submit', values);
                console.log(this.formValidate)
            },
            dropDownChoice : function(type){
                var _this = this
                _this.showPicker = true
                _this.type = type
                if(type == 'xb'){_this.dropDownData = _this.totalDropDownData.xb}
                if(type == 'lb'){_this.dropDownData = _this.totalDropDownData.lb}
                if(type == 'mz'){_this.dropDownData = _this.totalDropDownData.mz}
                if(type == 'yz'){_this.dropDownData = _this.totalDropDownData.yz}
                if(type == 'sr'){_this.dropDownData = _this.totalDropDownData.sr}
            },
            initialization : function(){
                var _this = this
                //民族
                _this.send({
                  url : '/EthnicityList',
                  success: function (res) {
                    _this.nation = res
                    for(var i in res){
                      _this.totalDropDownData.mz.push(res[i].EthnicityName)
                    }
                  }
                })
                //月收入
                _this.send({
                    url : '/MonthlyIncome',
                    success: function (res) {
                        _this.monthly = res
                        for(var i in res){
                            _this.totalDropDownData.sr.push(res[i].income)
                        }
                    }
                })
                //法律援助
                _this.send({
                    url : '/LegalReason',
                    success: function (res) {
                        _this.legalAid = res
                        for(var i in res){
                            _this.totalDropDownData.yz.push(res[i].reasonContent)
                        }
                    }
                })
                //人群类型
                _this.send({
                    url : '/HumanType',
                    success: function (res) {
                        _this.crowd = res
                        for(var i in res){
                            _this.totalDropDownData.lb.push(res[i].humanType)
                        }
                    }
                })
            },
            onBirthday : function(refName){
                var date = new Date(refName);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
                var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
                if(this.active == 0){this.formValidate.sqrcsrq = Y + M + D}
                if(this.active == 1){this.formValidate.bsqrcsrq = Y + M + D}
                this.birthday = false
            },
            onAboutTime : function(refName){
                var date = new Date(refName);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
                var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
                var h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
                var m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';
                var s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
                if(this.riseStop == 1){this.formValidate.yysjq = Y + M + D + h +  m + s}
                if(this.riseStop == 2){this.formValidate.yysjz = Y + M + D + h +  m + s}

                this.aboutTime = false
            },
            onConfirm(value,index) {
                console.log(value,index)
                if(this.type == 'xb'){
                    this.SupplementContent.sex = value
					if(value == '男'){this.formValidate.sex = 1}
					if(value == '女'){this.formValidate.sex = 2}

                }
                if(this.type == 'lb'){
                    this.SupplementContent.webcrowdtypename = value
                    this.formValidate.webcrowdtype = this.crowd[index].humanTypeId
                }
                if(this.type == 'sr'){
                    this.SupplementContent.monthlyincomename = value
                    this.formValidate.monthlyincome = this.monthly[index].monthlyIncomeId
                }
                if(this.type == 'yz'){
                    this.SupplementContent.legalhelpreasonname = value
                    this.formValidate.legalhelpreason = this.legalAid[index].reasonId
                }
              if(this.type == 'mz'){
                this.formValidate.mz = this.nation[index].EthnicityId,
                this.mz = value,
                this.SupplementContent.mz = value
              }
              this.showPicker = false;
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
            cqAreaList:function(){
                var _this = this
                var data = []
                _this.send({
                    url:'/CqAreaList',
                    success:function(res){
                        for(var i in res){
                            data.push(res[i])
                        }
                        convertTree(data)
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let {
                                    AreaId: value,
                                    AreaName: text,
                                    AreaCode : AreaCode,
                                    list: children
                                } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    value,
                                    text,
                                    AreaCode,
                                    children
                                })
                            })
                            _this.addressData = result
                            console.log(_this.addressData)
                            return result
                        }
                    }
                })
            },
          //多文件上传
          multipleFiles : function (file,index){
            var _this = this
            // 此时可以自行将文件上传至服务器file: (binary)Identification
            console.log(file);
            var _this = this;
            var param = new FormData(); //创建form对象
            param.append('file', file.file);//通过append向form对象添加数据
            // param.append('MenuId', _this.menuId);
            var config = {}
            axios.post('https://cq.lvwa18.com:8001/api/upload_file_H5', param, config)
                .then(response => {
                  _this.fileData.push('https://cq.lvwa18.com:8001/'+response.data.Data.path)
                })
          },
          deleteImg : function (file,value){
            var _this = this
            _this.fileList.splice(value,1)
            _this.fileData.splice(value,1)
          },
        },
        mounted(){
          SYSCONFIG.server.default.host = 'cq.lvwa18.com'
          SYSCONFIG.server.default.port = '8001'
          this.formValidate.phone = window.sessionStorage.userphone
            this.initialization()
            this.setPageSize()
            this.cqAreaList()
            //默认选择
        },
    }
</script>

<style scoped>
    .informationFillin :deep(.van-cell){line-height: 0.5rem;}
    /*height: 0.6rem;padding: 0 0.1rem;margin: 0.01rem 0;*/
    .informationFillin :deep(.van-field__control div){height: 0.5rem;}
    .informationFillin :deep(.van-field__label){height: 0.5rem;}
    .genderDateNation :deep(.van-cell){padding: 10px 0;}
    .genderDateNation :deep(.van-field__label){margin-right: 0;width: auto;}
</style>
