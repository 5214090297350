<style scoped>
  .bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
  .introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
  .introduce-video-player{width:100%;}
  .mediator-list{color:#FFF;}
  .mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
  .mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
  .introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
  .introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
  .introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div class="introduce-text">
      <div class="introduce-text-title">法律援助办事指南</div>
      <div class="introduce-text-content">
        一、适用范围</br>
        （一）符合规定的经济困难标准；</br>
        （二）申请事项依法在本行政区域审理或者处理；</br>
        （三）因维护自身合法权益需要无偿法律服务。</br>
        二、申请条件</br>
        (一)符合经济困难标准的公民：</br>
        经济困难标准，按照申请人家庭成员人均收入不足住所地或者受理法律援助申请地最低工资标准确定。住所地与受理申请地最低工资标准不一致的，适用有利于申请人的标准。</br>
        （二）申请人有下列情形之一的，免于经济困难审查：</br>
        1.重点优抚对象（享受抚恤和生活补助的人员）；</br>
        2.纳入享受最低生活保障待遇范围的城乡低保对象、城乡特困人员、孤儿；</br>
        3.在社会福利机构由政府供养的或者在救助管理机构接受救助的；</br>
        4.无固定生活来源的残疾人、老年人、患有严重疾病的人；</br>
        5.在本市行政区域内务工的农民因请求支付劳动报酬、经济补偿或者工伤赔偿的；</br>
        6.因遭受自然灾害或者其他不可抗力正在接受社会救济的；</br>
        7.申请的法律援助事项已经获得公安机关、人民检察院、人民法院司法救助的；</br>
        8.因见义勇为行为、实施志愿服务行为或者为保护社会公共利益致使其合法权益受到损害请求赔偿或者补偿的；</br>
        9.义务兵、供给制学员以及军属，执行作战、重大非战争军事行动任务的军人以及军属，烈士、因公牺牲或者病故的军人、人民警察、消防救援人员的遗属；军队中的文职人员、非现役公勤人员、在编职工，由军队管理的离退休人员，以及执行军事任务的预备役人员和其他人员，参照军人条件执行；</br>
        10.法律法规以及国家政策规定的其他情形。</br>
        三、申请材料</br>
        1.身份证或者其他有效身份证明，如委托他人代理的，代理申请人还应提交有代理权的证明；</br>
        2.经济困难证明材料（由其户籍所在地或者经常居住地镇人民政府或者街道办事处协助核查后加盖公章，也可以选择在政务中心公证处窗口免费办理“申请法律援助承诺书公证”）；</br>
        3.与申请事项相关的证明材料；</br>
        四、办理程序</br>
        第一步：申请人向市法律援助中心接待受理大厅提出申请；填写法律援助申请表、提交身份证明、经济困难证明材料及与所申请的法律援助事项相关的案件证明材料。</br>
        第二步：法律援助中心收到法律援助申请后，按照法律援助规定进行审查，并在7个工作日内做出是否提供法律援助的决定。对不符合法律援助条件的，说明理由，告知申请人享有向同级或者上一级司法行政部门提出异议的权利。</br>
        申请人提供的申请材料不齐全的，法律援助中心一次性告知申请人作出补充或者说明，未按要求补充材料的，视为撤销申请。</br>
        第三步：法律援助中心决定提供法律援助的，在作出决定之日起3个工作日内指派法律服务机构安排人员承办。</br>
        第四步：法律援助人员接到指派通知书后，5个工作日内与申请人签订委托代理（辨护）协议，开展法律援助服务。</br>

      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <Row style="text-align: center;">
      <Col span="24"><Button type="warning" :to="{name:'legalAidListH',query:{id:id,AreaId:AreaId,pid:pid,CityId:CityId,CityName:CityName,token:token}}" style="width:4rem;">去 办 理</Button></Col>
    </Row>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'introduce',
  data() {
    return {
      mediatorList:[],
      id : '',
      AreaId : '',
      pid : '',
      CityId : '',
      CityName : '',
      token : '',
    };
 },
  mounted() {
    var _this = this
    _this.id = _this.$route.query.id
    _this.AreaId = _this.$route.query.AreaId
    _this.pid = _this.$route.query.pid
    _this.CityId = _this.$route.query.CityId
    _this.CityName = _this.$route.query.CityName
    _this.token = _this.$route.query.token
  },
  methods: {
  },
  computed:{
    userInfo:function(){
      return this.$store.state.userInfo
    },
  },
}
</script>
