<style scoped>
.content {
  padding: 20px;
}
.list-item {
  border: 1px solid #e5e5e5;
  padding: 10px;
  margin-bottom: 20px;
  transition: 0.1s;
  position: relative;
}
.list-item:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px -3px;
  transform: scale(101%);
}
.list-item:hover .list-more-btn {
  opacity: 1;
}
.list-title {
  font-size: 14px;
  font-weight: bold;
}
.list-icon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  vertical-align: middle;
  margin-right: 6px;
}
.list-content-text {
  font-size: 13px;
  color: #5e5e5e;
}
.list-more-btn {
  width: 120px;
  margin-left: -60px;
  height: 25px;
  line-height: 25px;
  position: absolute;
  left: 50%;
  bottom: -15px;
  cursor: pointer;
  padding: 0 10px;
  background-color: rgb(232 67 53);
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: 0.5s;
}

.screen :deep(.ivu-tabs-nav-scroll ){
  height: 60px;
  line-height: 60px;
}
/*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
.screen :deep(.ivu-tabs-nav ){
  height: 60px;
  line-height: 60px;
}
/*.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}*/
/*.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}*/
/*.screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}*/
.screen :deep(.ivu-tabs-bar ){
  border-bottom: 2px solid #d7d7d7;
  margin-bottom: 40px;
}
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}
.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
.details p {
  color: #000000;
}
.details span {
  color: #5c6b77;
}
</style>

<template>
  <div class="content screen">
    <div style="padding: 15px"></div>
    <div
        style="
                    font-size: 16px;
                    text-align: left;
                    margin-left: 20px;
                    border-left: solid #de4f0b 3px;
                    padding-left: 5px;
                    display: inline-block;margin-right: 30px;
                "
    >
      法律援助
    </div>
    <!--    法律援助-->
    <div >
      <legalaidModal
          :tableData="legalaidPanel.data"
          :state="legalaidPanel.state"
          @on-close="legalaidPanel.state = $event"
      ></legalaidModal>
      <div>
        <div class="screen">
          <div style="background-color: white; margin: 20px">
            <div class="publicWelfare">
              <table class="formtable">
                <thead>
                <tr>
<!--                  <th>申请用户</th>-->
                  <th>申请人</th>
                  <th>事项类别</th>
                  <th>机构所在</th>
<!--                  <th>所属村（社区）</th>-->
                  <th>申请时间</th>
                  <th>律所</th>
                  <th>律师</th>
                  <th>回复状态</th>
                  <th>回复时间</th>
                  <th>操作</th>
                </tr>
                </thead>
                <tbody v-for="(value, index) in legalAidData">
                <tr >
<!--                  <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>-->
                  <td>{{ value.Content.zxr }}</td>
                  <td style="width:150px;">
                    <Tooltip :content="value.Content.zxsxlxzx" placement="right" max-width="-">
                      <div style=" white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 150px">
                        {{ value.Content.zxsxlxzx }}
                      </div>
                    </Tooltip>

                  </td>
                  <td>
                    {{value.Content.orgCity + value.Content.orgArea}}
                  </td>
<!--                  <td>{{ value.CityName }}{{ value.AdminName }}{{ value.VillagesName }}</td>-->
                  <td>{{ value.CreateTime }}</td>
                  <td>
                    <template v-if="value.organization == null"><Tag color="red">未分配</Tag></template>
                    <template v-else>{{value.organization.AdminName}}</template>
                  </td>
                  <td>
                    <template v-if="value.lawyer == null"><Tag color="red">未分配</Tag></template>
                    <template v-else>{{value.lawyer.LawyerName}}</template>
                  </td>
                  <td>
                    <template v-if="value.ReplyContent == null && value.PlatformApply == null"><Tag color="red">未回复</Tag></template>
                    <template v-else-if="value.ReplyContent != null"><Tag color="green">机构已回复</Tag></template>
                    <template v-else-if="value.PlatformApply != null"><Tag color="green">平台已回复</Tag></template>
                  </td>
                  <td>
                    <div v-if="value.ReplyContent != null">
                      <div>{{value.ReplyContent.data.jdrq}}</div>
                    </div>
                    <div v-else-if="value.PlatformApply != null">
                      <div>{{value.PlatformApplyTime}}</div>
                    </div>
                    <div v-else>
                      --
                    </div>
                  </td>
                  <td>
                    <!--                                          see(value.Content, value.UserId, value.FlyzApplyId,value);-->
                    <span @click="(legalaidPanel.data = value), (legalaidPanel.state = true)" style="cursor: pointer;color:#2d8cf0;">查看</span>
                    <div style="display:inline-block;" v-if="value.lawyer == null || value.organization == null">
                      <span style="padding:3px;"></span>
                      <span v-if="information.Level != 4" @click="assignLawFirms(value)" style="cursor: pointer;color:#1890ff;">分配律所</span>
                      <span v-if="information.Level == 4" @click="assignAlawyer(value)" style="cursor: pointer;color:#1890ff;">分配律师</span>
                    </div>
                    <span style="padding:3px;"></span>
                    <span  v-if="value.ReplyContent == null && value.PlatformApply == null" @click="onreply(value.OrganizationId, value.PlatformApply, value.FlyzApplyId, false)" style="cursor: pointer;color:#f90;">点击回复</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div style="text-align: left; margin-left: 40px; font-size: 16px; padding-bottom: 20px">
              <Page
                  :total="count"
                  show-total
                  :page-size="pageSize"
                  show-elevator
                  @on-change="page = $event;flyzApplyList()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    分配律所-->
    <Modal
        v-model="assignLawFirmsData.show"
        title="分配律所" width="900" ok-text="关闭"
        cancel-text="">
      <div class="publicWelfare" >
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>律所</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in assignLawFirmsData.data">
            <td>{{index+1}}</td>
            <td>{{value.AdminName}}</td>
            <td>
              <span @click="assignOrganization(value)" style="cursor: pointer;color:rgb(24, 144, 255);">分配</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
<!--    分配律师-->

    <Modal
        v-model="assignaLawyerData.assignShow"
        title="分配律师" width="900" ok-text="关闭"
        cancel-text="">
      <div class="publicWelfare" >
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>律师名称</th>
            <th>手机号</th>
            <th>所属律所</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in assignaLawyerData.assignAlawyerList">
            <td>{{index+1}}</td>
            <td>{{value.LawyerName}}</td>
            <td>{{value.LawyerPhone}}</td>
            <td>{{value.Organization || '--'}}</td>
            <td>
              <span @click="assignClick(value.LawyerId,value.LawyerName)" style="cursor: pointer;color:rgb(24, 144, 255);">分配</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>

    <!--平台回复-->
    <Modal v-model="replyData.replyShow" title="平台回复" @on-ok="submit" ok-text="发送" style="height: 300px">
      <Input
          v-model="replyData.PlatformApply" :autosize="true"
          maxlength="300"
          show-word-limit
          type="textarea"
          :disabled="replyData.readonly"
          placeholder="回复内容"
      />
    </Modal>
  </div>
</template>

<script>
import legalaidModal from '../../manager/mediation/modules/legalaidModal'
import echarts from "echarts";
export default {
  name: 'list',
  data() {
    return {
      legalaidPanel: {
        state: false,
        data: {}
      },
      information: [],
      legalAidData: [],
      count: 0,
      pageSize: 10,
      page: 1,
      assignLawFirmsData: {
        data: [],
        show: false,
        FlyzApplyId: '',
      },
      assignaLawyerData: {
        assignShow: false,
        FlyzApplyId: '',
        assignAlawyerList: []
      },
      // 点击回复数据
      replyData: {
        replyShow: false,
        FlyzApplyId: '',
        PlatformApply: '',
        OrganizationId: '',
        readonly: false,
      }
    }
  },
  components: { legalaidModal },
  mounted() {
    SYSCONFIG.server.default.src = ''
    this.information = window.sessionStorage
    this.flyzApplyList()
  },
  methods: {
    submit: function () {
      var _this = this
      _this.send({
        url: '/zmd/mediation/api/AdminflyzApply',
        data: {
          PlatformApply:_this.replyData.PlatformApply,
          OrganizationId: _this.replyData.OrganizationId,
          FlyzApplyId: _this.replyData.FlyzApplyId,
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          _this.$Notice.success({
            title: '系统提示',
            desc: '发送成功'
          })
          _this.PlatformApply = ''
          _this.flyzApplyList()
        }
      })
    },
    // 回复
    onreply: function (OrganizationId, PlatformApply, FlyzApplyId, readonly) {
      this.replyData.OrganizationId = OrganizationId
      this.replyData.FlyzApplyId = FlyzApplyId
      this.replyData.PlatformApply = PlatformApply
      this.replyData.readonly = readonly
      this.replyData.replyShow = !this.replyShow
    },
    // 点击分配律师
    assignClick: function (id,name){
      var _this = this
      _this.send({
        url: '/zmd/mediation/api/assignLawyer',
        confirm: true,
        data: {
          FlyzApplyId:_this.assignaLawyerData.FlyzApplyId,
          LawyerId:id,
          lawyerName:name,
        },
        success: function (res) {
          _this.$Message.success('指派成功')
          _this.assignaLawyerData.assignShow = !_this.assignaLawyerData.assignShow
          _this.assignaLawyerData.FlyzApplyId = ''
          _this.flyzApplyList()
        }
      })
    },
    // 分配律师弹窗
    assignAlawyer: function (value) {
      this.assignaLawyerData.FlyzApplyId = value.FlyzApplyId
      this.assignaLawyerData.assignShow = !this.assignaLawyerData.assignShow
      var _this = this
      _this.send({
        url: '/zmd/mediation/api/lawyerList',
        data: {
          AdminId: window.sessionStorage.AdminId,},
        success: function (res) {
          _this.assignaLawyerData.assignAlawyerList = res
        }
      })
    },
    // 分配律所事件
    assignOrganization: function (value){
      var _this = this
      _this.send({
        url: '/zmd/mediation/api/assignOrganization',
        warningTips: true,
        data: {
          FlyzApplyId: _this.assignLawFirmsData.FlyzApplyId,
          OrganizationId: value.AdminId,
        },
        success: function (res) {
          _this.$Message.success('分配成功')
          _this.assignLawFirmsData.FlyzApplyId = ''
          _this.assignLawFirmsData.show = false
          _this.flyzApplyList()
        }
      })
    },
    // 律所列表
    assignLawFirms: function (value){
      var _this = this
      _this.assignLawFirmsData.FlyzApplyId = value.FlyzApplyId
      _this.send({
        url: '/zmd/mediation/api/OrganizationList',
        warningTips: true,
        data: {
          AdminId : window.sessionStorage.AdminId,
        },
        success: function (res) {
          console.log(res)
          _this.assignLawFirmsData.data = res
          _this.assignLawFirmsData.show = true
        }
      })
    },
    // 法律援助列表
    flyzApplyList: function (){
      var _this = this
      _this.send({
        url: '/zmd/mediation/api/FlyzApplyList',
        warningTips: true,
        data: {
          Level: window.sessionStorage.Level,
          AdminId : window.sessionStorage.AdminId,
          page: _this.page,
          pageSize: _this.pageSize
        },
        success: function (res) {
          _this.legalAidData = res.List
          _this.count = res.Count
        }
      })
    }
  }
}
</script>
