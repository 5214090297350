<template>
  <div style="padding: 20px">
    <div style="background-color: white">
      <div style="font-size: 16px; text-align: left; border-left: solid #de4f0b 3px; padding-left: 5px">
        补贴发放记录
        <div style="display: inline-block; vertical-align: middle; margin-left: 10px">
          <span style="margin-left: 30px">共{{contractList.count}}条</span>
        </div>
        <Button type="primary" style="margin-left: 20px" @click="newlyAdded();">新增</Button>
      </div>
      <div style="padding: 10px"></div>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>上传账号</th>
            <th>时间周期</th>
            <th>上传时间</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in contractList.data">
            <td>{{value.admin.AdminName}}</td>
            <td>{{value.date}}</td>
            <td>{{value.time}}</td>
            <td><Button type="primary" style="margin-left: 20px" @click="downloadFiles(value.file_contract)">查看文件</Button></td>
          </tr>
          </tbody>
        </table>
                <Page
                    :total="contractList.count"
                    :page-size="contractList.pageSize"
                    show-elevator
                    show-total
                    @on-change="contractList.page = $event;adminContractList()"
                />
      </div>
    </div>
    <Modal :draggable="true" v-model="subsidyData.state" title="绑定" @on-ok="submitTo" width="470">
      <div>
        时间周期：
        <RadioGroup v-model="subsidyData.phone">
          <Radio :label="value.type" v-for="(value,index) in subsidyData.cycleTime">
            <span>{{value.name}}</span>
          </Radio>
        </RadioGroup>
        <DatePicker type="year" placeholder="选择年份" style="width: 200px" v-model="subsidyData.timeData"></DatePicker>
      </div>
      <div style="margin-top: 20px">
        <Upload
            type="drag"
            action="https://cq.lvwa18.com:8001/api/upload_file_H5"
            　　:on-success="Picture" :multiple="false" accept=".PDF,.DOC,.DOCX,.PPT,.PPTX,.XLS,.XLSX"
            :default-file-list="subsidyData.StudyUrl">
          <div style="padding: 20px 0">
            上传文件
          </div>
        </Upload>
      </div>
    </Modal>

  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";

export default {
  name: "backstageAdmin",
  data() {
    return {
      subsidyData:{
        state:false,
        cycleTime : [
          {name:'年度',type:1},
        ],
        phone:1,
        StudyUrl : [],
        timeData : '',
      },
      contractList : {
        data : [],
        count : 0,
        page: 1,
        pageSize: 10,
      },
      information : [],
      adminUserData : [],
    }
  },
  mounted() {
    this.information = window.sessionStorage
    this.adminContractList()
  },
  methods: {
    newlyAdded : function (){
      this.subsidyData.state = true
    },
    Picture : function (e,file){
      this.subsidyData.StudyUrl = [{name:e.Data.path,url:e.Data.path}]
      console.log(this.subsidyData.StudyUrl)
    },
  //   window.sessionStorage.url+
  //   提交
    submitTo : function (){
      var _this = this
      _this.send({
        url: '/AddAdminContract',
        data: {
          file_contract: _this.subsidyData.StudyUrl[0].url,
          type: _this.subsidyData.phone,
          date: new Date(this.subsidyData.timeData).getFullYear(),
        },
        success: function (res) {
          _this.adminContractList()
          _this.subsidyData.StudyUrl = []
          _this.subsidyData.timeData = ''
        }
      })
    },
    // 列表
    adminContractList : function (){
      var _this = this
      _this.send({
        url: '/AdminContractList',
        data: {
          type: 1,
          page: _this.contractList.page,
          pageSize: _this.contractList.pageSize,
        },
        success: function (res) {
          _this.contractList.data = res.list
          _this.contractList.count = res.count
        }
      })
    },
    downloadFiles : function (url){
      var _this = this
      var fileType = url.substr(url.lastIndexOf(".") + 1, 4).toLowerCase()
      if (fileType == "txt") {
        axios({
          method: 'get',
          responseType: 'blob',
          url: _this.assetsLink+url,
          transformResponse: [function (data) {
            var render = new FileReader()
            render.readAsText(data, 'UTF8')
            render.onload = function () {
              if (render.result.indexOf('�') != -1) {
                var renderGBK = new FileReader()
                renderGBK.readAsText(data, 'GBK')
                renderGBK.onload = function () {
                }
              }
              var newWindow = window.open('about:blank', '', 'width=400,height=400')
              newWindow.document.write(renderGBK.result)
            }
          }]
        })
      }
      else {
        if (fileType == 'pdf') {
          window.open(_this.assetsLink+url)
        } else {
          if (SYSCONFIG.location == 'chong-qing') {
            _this.showPdf(url)
          } else {
            window.open('https://view.officeapps.live.com/op/view.aspx?src=' + _this.assetsLink+url)
          }
        }
      }
    },
    showPdf: function (e) {
      var _this = this
      if(SYSCONFIG.location == 'chong-qing'){
        var url = '/ShowPdf'
      } else {
        var url = '/CD_ShowPdf'
      }
      window.open(window.sessionStorage.url + 'api' + url + '?FilePath='+ e)
    },
  },
  computed:{
    assetsLink:function(){
      return `${SYSCONFIG.server.default.ssl?'https':'http'}://${SYSCONFIG.server.default.host}:${SYSCONFIG.server.default.port}/`
    }
  },
}
</script>


<style scoped>
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>
