// import login from '../../../views/login'
import loginGov from '../../../views/loginGov'
// import home from '../../../views/home'
import lawAdmin from './lawAdmin'

//
import lawAdminMdPage from "@/views/manager/mdPage"
import service from "../../../views/lawAdmin/service"
import archives from "../../../views/lawAdmin/archives"
import lecture from "../../../views/lawAdmin/lecture"
import caseBase from "../../../views/lawAdmin/case-base"
import lawFirm from "../../../views/lawAdmin/lawFirm"
import judicialoffice from "../../../views/lawAdmin/judicialoffice"
import villageAdmin from "../../../views/lawAdmin/villageAdmin"
import lawyerManagement from "../../../views/lawAdmin/lawyerManagement"
import lawyerScoreList from "../../../views/lawAdmin/lawyerScoreList"
import communityRevise from "../../../views/lawAdmin/communityRevise"
import community from "../../../views/lawAdmin/community"
// import totalAdmin from "../../../views/lawAdmin/totalAdmin"
import lawyer from "../../../views/lawAdmin/lawyer"
import article from "../../../views/lawAdmin/article"
import videoUpload from "../../../views/lawAdmin/videoUpload"
import publicityofSpd from "../../../views/lawAdmin/publicityofSpd"
import hotspotlaw from "../../../views/lawAdmin/hotspotlaw"
import legalVideo from "../../../views/lawAdmin/legalVideo"
import statute from "../../../views/lawAdmin/statute"
import feedback from "../../../views/lawAdmin/feedback"
import supervise from "../../../views/lawAdmin/supervise"
import opinionCollect from "../../../views/lawAdmin/opinionCollect"
import runReport from "../../../views/lawAdmin/runReport"
import backstageAdmin from "../../../views/lawAdmin/backstageAdmin"
import subsidyRecord from "../../../views/lawAdmin/subsidyRecord"
import pointsMenu from "../../../views/lawAdmin/pointsMenu"
import publicInterestLawyer from "../../../views/lawAdmin/publicInterestLawyer"
import dataPush from "../../../views/lawAdmin/dataPush"
import punchIn from "../../../views/lawAdmin/punchIn"
import replaceCommunity from "../../../views/lawAdmin/replaceCommunity"
import legalAidList from "../../../views/lawAdmin/legalAidList"
import mediateList from "../../../views/lawAdmin/mediateList"
import mediateN from "../../../views/lawAdmin/mediateN"
import mediator from "../../../views/lawAdmin/mediatorList"
import report from "@/views/lawAdmin/report"
import timeSet from "../../../views/lawAdmin/timeSet"
import correctionL from "../../../views/lawAdmin/correctionL"
import correctionAE from "../../../views/lawAdmin/correctionAE"
import correctionC from "../../../views/lawAdmin/correctionC"
import correctionR from "../../../views/lawAdmin/correctionR"
import correctionE from "../../../views/lawAdmin/correctionE"
import Atest from "../../../views/lawAdmin/Atest"
import bigData from "../../../views/lawAdmin/bigData"
import userAdministration from "../../../views/lawAdmin/userAdministration"
import consultingService from "../../../views/lawAdmin/consultingService"
import handlingServices from "../../../views/lawAdmin/handlingServices"
import queryService from "../../../views/lawAdmin/queryService"
import earlyWarning from "../../../views/lawAdmin/earlyWarning"
import earlyWarningLookover from "../../../views/lawAdmin/earlyWarningLookover"
import dataReport from "../../../views/lawAdmin/dataReport"
import enterpriseRuleOfLaw from "../../../views/lawAdmin/enterpriseRuleOfLaw"
import reconsider from "../../../views/lawAdmin/reconsider"
import chongqing from "../../../views/pc/open/chongqing/Q&A/chongqing"
import cqadviser from "../../../views/pc/open/chongqing/Q&A/cqadviser"
import search from "../../../views/pc/open/chongqing/Q&A/search"
import cqdownload from "../../../views/pc/open/chongqing/Q&A/cqdownload"
//H5
import legalAidListH from "../../../views/h5/legalAidListH"
import legalAidStep1 from "../../../views/h5/legalAid/step1"
import legalAidStep2 from "../../../views/h5/legalAid/step2"
import legalAidStep3 from "../../../views/h5/legalAid/step3"
import mediateListH from "../../../views/h5/mediateListH"
import mediateStep1 from "../../../views/h5/mediate/step1"
import mediateStep2 from "../../../views/h5/mediate/step2"
import mediateStep3 from "../../../views/h5/mediate/step3"
import legalAid from "../../../views/h5/legalAid"
import mediate from "../../../views/h5/mediate"
import contract from "../../../views/h5/contract"
//pls
import plsHome from "../../../views/h5/pls/plsHome"
import cdsftPls from "../../../views/h5/pls/cdsftPls"
//zhqy
import zhqylogin from "../../../views/h5/zhqy/zhqylogin"
import lawFirmLogin from "../../../views/h5/zhqy/lawFirmLogin"
import lawFirmEditInformation from "../../../views/h5/zhqy/lawFirmEditInformation"
import zhqyHome from "../../../views/h5/zhqy/zhqyHome"
import looklawFirm from "../../../views/h5/zhqy/looklawFirm"
import findaLawFirm from "../../../views/h5/zhqy/findaLawFirm"
import editInformation from "../../../views/h5/zhqy/editInformation"
//案件录入
import userLogin from "../../../views/h5/caseInput/userLogin"
import lawFirmendLogin from "../../../views/h5/caseInput/lawFirmendLogin"
import informationInput from "../../../views/h5/caseInput/informationInput"
import caseList from "../../../views/h5/caseInput/caseList"
import lawfirmList from "../../../views/h5/caseInput/lawfirmList"
import evaluate from "../../../views/h5/caseInput/evaluate"
import caseDetails from "../../../views/h5/caseInput/caseDetails"
import evaluateList from "../../../views/h5/caseInput/evaluateList"

import gtfbjs from "../../../views/mobile/customization/qing-bai-jiang/introduce.vue"
//cqs
import cqsmediateList from "../../../views/h5/cqs/mediateList"
import cqsmediateapply from "../../../views/h5/cqs/mediateapply"
import mediateRegion from "../../../views/h5/cqs/mediateapply/mediateRegion"
import informationFillin from "../../../views/h5/cqs/mediateapply/informationFillin"
import cqslegalAidInput from "../../../views/h5/cqs/cqslegalAidInput"
import cqslegalAidList from "../../../views/h5/cqs/legalAidList"
import cqslegalAidapply from "../../../views/h5/cqs/legalAidapply"
//法律文书
import templateStyle from "../../../views/h5/document/templateStyle"
//h5文章
import articleH from "../../../views/h5/articleH"
//法律明白人
import lawList from "../../../views/lawAdmin/understandingPlatform/lawList"
import learningArea from "../../../views/lawAdmin/understandingPlatform/learningArea"
import learningRecord from "../../../views/lawAdmin/understandingPlatform/learningRecord"
import questionnaireList from "../../../views/lawAdmin/understandingPlatform/questionnaireList"
import understandingList from "../../../views/lawAdmin/understandingPlatform/understandingList"
import UnderstanderAdmin from "../../../views/lawAdmin/understandingPlatform/UnderstanderAdmin"
import regionAdmin from "../../../views/lawAdmin/understandingPlatform/regionAdmin"
import toconfigureAdmin from "../../../views/lawAdmin/understandingPlatform/toconfigureAdmin"
import workLog from "../../../views/lawAdmin/understandingPlatform/workLog"
import aScheduleList from "../../../views/lawAdmin/understandingPlatform/aScheduleList"
import integralManage from "../../../views/lawAdmin/understandingPlatform/integralManage"
import recommendation from "../../../views/lawAdmin/understandingPlatform/recommendation"
import notice from "../../../views/lawAdmin/understandingPlatform/notice"
import subscribeList from "../../../views/lawAdmin/understandingPlatform/subscribeList"
//公证员
import notaryAdd from "../../../views/lawAdmin/notaryAdmin/notaryAdd"
import notaryList from "../../../views/lawAdmin/notaryAdmin/notaryList"
import notaryLog from "../../../views/lawAdmin/notaryAdmin/notaryLog"
import notaryAdmin from "../../../views/lawAdmin/notaryAdmin/notaryAdmin"
import regionAdminGzt from "../../../views/lawAdmin/notaryAdmin/regionAdminGzt"
// 律所页面
import lawFirmLegalAid from "../../../views/lawAdmin/lawFirm/legalAid"
// 自定义菜单页面-管理端
import menuAdmin from "../../../views/lawAdmin/customizeMenu/menuAdmin.vue"
import roles from "../../../views/lawAdmin/customizeMenu/roles.vue"
import rolePermission from "../../../views/lawAdmin/customizeMenu/rolePermission.vue"
const routes = [
  {
    path:'',
    component:lawAdminMdPage,
    children:[
      {path: '/loginGov', name:'loginGov',component:loginGov,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/service', name:'service',component:service,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/report', name:'report',component:report,meta:{title:'村居法律顾问平台'}},
      {path: '/archives', name:'archives',component:archives,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/lecture', name:'lecture',component:lecture,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/case-base', name:'case-base',component:caseBase,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/lawFirm', name:'lawFirm',component:lawFirm,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/timeSet', name:'timeSet',component:timeSet,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/judicialoffice', name:'judicialoffice',component:judicialoffice,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/villageAdmin', name:'villageAdmin',component:villageAdmin,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/lawyerManagement', name:'lawyerManagement',component:lawyerManagement,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/lawyerScoreList', name:'lawyerScoreList',component:lawyerScoreList,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/communityRevise', name:'communityRevise',component:communityRevise,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/community', name:'community',component:community,meta:{title:'律蛙数字法治-智慧司法平台'}},
      // {path: '/totalAdmin', name:'totalAdmin',component:totalAdmin,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/lawyer', name:'lawyer',component:lawyer,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/article', name:'article',component:article,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/videoUpload', name:'videoUpload',component:videoUpload,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/publicityofSpd', name:'publicityofSpd',component:publicityofSpd,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/hotspotlaw', name:'hotspotlaw',component:hotspotlaw,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/legalVideo', name:'legalVideo',component:legalVideo,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/statute', name:'statute',component:statute,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/feedback', name:'feedback',component:feedback,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/supervise', name:'supervise',component:supervise,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/opinionCollect', name:'opinionCollect',component:opinionCollect,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/runReport', name:'runReport',component:runReport,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/backstageAdmin', name:'backstageAdmin',component:backstageAdmin,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/subsidyRecord', name:'subsidyRecord',component:subsidyRecord,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/pointsMenu', name:'pointsMenu',component:pointsMenu,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/publicInterestLawyer', name:'publicInterestLawyer',component:publicInterestLawyer,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/dataPush', name:'dataPush',component:dataPush,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/punchIn', name:'punchIn',component:punchIn,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/replaceCommunity', name:'replaceCommunity',component:replaceCommunity,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/legalAidList', name:'legalAidList',component:legalAidList,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/mediateList', name:'mediateList',component:mediateList,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/mediateN', name:'mediateN',component:mediateN,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/mediator', name:'mediatorN',component:mediator,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/correctionL', name:'correctionL',component:correctionL,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/correctionAE', name:'correctionAE',component:correctionAE,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/correctionC', name:'correctionC',component:correctionC,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/correctionR', name:'correctionR',component:correctionR,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/correctionE', name:'correctionE',component:correctionE,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/Atest', name:'Atest',component:Atest,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/userAdministration', name:'userAdministration',component:userAdministration,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/consultingService', name:'consultingService',component:consultingService,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/handlingServices', name:'handlingServices',component:handlingServices,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/queryService', name:'queryService',component:queryService,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/earlyWarning', name:'earlyWarning',component:earlyWarning,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/earlyWarningLookover', name:'earlyWarningLookover',component:earlyWarningLookover,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/dataReport', name:'dataReport',component:dataReport,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/enterpriseRuleOfLaw', name:'enterpriseRuleOfLaw',component:enterpriseRuleOfLaw,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/reconsider', name:'reconsider',component:reconsider,meta:{title:'律蛙数字法治-智慧司法平台'}},
      //  法律明白人平台
      {path: '/lawList', name:'lawList',component:lawList,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/learningArea', name:'learningArea',component:learningArea,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/learningRecord', name:'learningRecord',component:learningRecord,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/questionnaireList', name:'questionnaireList',component:questionnaireList,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/understandingList', name:'understandingList',component:understandingList,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/UnderstanderAdmin', name:'UnderstanderAdmin',component:UnderstanderAdmin,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/regionAdmin', name:'regionAdmin',component:regionAdmin,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/toconfigureAdmin', name:'toconfigureAdmin',component:toconfigureAdmin,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/workLog', name:'workLog',component:workLog,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/aScheduleList', name:'aScheduleList',component:aScheduleList,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/integralManage', name:'integralManage',component:integralManage,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/recommendation', name:'recommendation',component:recommendation,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/notice', name:'notice',component:notice,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/subscribeList', name:'subscribeList',component:subscribeList,meta:{title:'律蛙数字法治-智慧司法平台'}},
      //  公证员
      {path: '/notaryList', name:'notaryList',component:notaryList,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/notaryAdd', name:'notaryAdd',component:notaryAdd,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/notaryLog', name:'notaryLog',component:notaryLog,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/notaryAdmin', name:'notaryAdmin',component:notaryAdmin,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/regionAdminGzt', name:'regionAdminGzt',component:regionAdminGzt,meta:{title:'律蛙数字法治-智慧司法平台'}},
      //   律所
      {path: '/lawFirmLegalAid', name:'lawFirmLegalAid',component:lawFirmLegalAid,meta:{title:'律蛙数字法治-智慧司法平台'}},
      // 自定义菜单
      {path: '/menuAdmin', name:'menuAdmin',component:menuAdmin,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/roles', name:'roles',component:roles,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: '/rolePermission', name:'rolePermission',component:rolePermission,meta:{title:'律蛙数字法治-智慧司法平台'}},
    ],
  },
  //  h5
  {path: '/legalAid', name:'legalAid',component:legalAid,meta:{title:'律蛙数字法治-智慧司法平台-申请法律援助'}},
  {path: '/legalAidListH', name:'legalAidListH',component:legalAidListH,meta:{title:'律蛙数字法治-智慧司法平台-法律援助'}},
  {path: '/legalAidStep1', name:'legalAidStep1',component:legalAidStep1,meta:{title:'律蛙数字法治-智慧司法平台-法律援助'}},
  {path: '/legalAidStep2', name:'legalAidStep2',component:legalAidStep2,meta:{title:'律蛙数字法治-智慧司法平台-法律援助'}},
  {path: '/legalAidStep3', name:'legalAidStep3',component:legalAidStep3,meta:{title:'律蛙数字法治-智慧司法平台-法律援助'}},
  {path: '/mediateListH', name:'mediateListH',component:mediateListH,meta:{title:'律蛙数字法治-智慧司法平台-申请人民调解'}},
  {path: '/mediateStep1', name:'mediateStep1',component:mediateStep1,meta:{title:'律蛙数字法治-智慧司法平台-申请人民调解'}},
  {path: '/mediateStep2', name:'mediateStep2',component:mediateStep2,meta:{title:'律蛙数字法治-智慧司法平台-申请人民调解'}},
  {path: '/mediateStep3', name:'mediateStep3',component:mediateStep3,meta:{title:'律蛙数字法治-智慧司法平台-申请人民调解'}},
  {path: '/mediate', name:'mediate',component:mediate,meta:{title:'律蛙数字法治-智慧司法平台-人民调解'}},
  {path: '/contract', name:'contract',component:contract,meta:{title:'法律咨询服务合同'}},
  {path: '/gtfbjs', name:'gtfbjs',component:gtfbjs,meta:{title:'成都青白江铁路港片区法律服务中心'}},
  //  h5文章
  {path: '/articleH', name:'articleH',component:articleH,meta:{title:'律蛙数字法治-智慧司法平台-法律援助'}},
  //  pls
  {path: '/plsHome', name:'plsHome',component:plsHome,meta:{title:'成都司法通-PLS'}},
  {path: '/cdsftPls', name:'cdsftPls',component:cdsftPls,meta:{title:'成都司法通-PLS'}},
  //  cqs
  {path: '/cqsmediateList', name:'cqsmediateList',component:cqsmediateList,meta:{title:'人民调解'}},
  {path: '/cqsmediateapply', name:'cqsmediateapply',component:cqsmediateapply,meta:{title:'人民调解'}},
  {path: '/mediateRegion', name:'mediateRegion',component:mediateRegion,meta:{title:'人民调解'}},
  {path: '/informationFillin', name:'informationFillin',component:informationFillin,meta:{title:'人民调解'}},
  {path: '/cqslegalAidInput', name:'cqslegalAidInput',component:cqslegalAidInput,meta:{title:'法律援助'}},
  {path: '/cqslegalAidList', name:'cqslegalAidList',component:cqslegalAidList,meta:{title:'重庆市'}},
  {path: '/cqslegalAidapply', name:'cqslegalAidapply',component:cqslegalAidapply,meta:{title:'重庆市'}},
  {path: '/templateStyle', name:'templateStyle',component:templateStyle,meta:{title:'法律文书'}},
  //zhqy
  {path: '/zhqylogin', name:'zhqylogin',component:zhqylogin,meta:{title:'律师登录'}},
  {path: '/lawFirmLogin', name:'lawFirmLogin',component:lawFirmLogin,meta:{title:'律所登录'}},
  {path: '/zhqyHome', name:'zhqyHome',component:zhqyHome,meta:{title:'律所信息查询'}},
  {path: '/looklawFirm', name:'looklawFirm',component:looklawFirm,meta:{title:'律所信息'}},
  {path: '/findaLawFirm', name:'findaLawFirm',component:findaLawFirm,meta:{title:'律师信息'}},
  {path: '/editInformation', name:'editInformation',component:editInformation,meta:{title:'编辑信息'}},
  {path: '/lawFirmEditInformation', name:'lawFirmEditInformation',component:lawFirmEditInformation,meta:{title:'编辑信息'}},
  //
  {path: '/userLogin', name:'userLogin',component:userLogin,meta:{title:'用户登录'}},
  {path: '/lawFirmendLogin', name:'lawFirmendLogin',component:lawFirmendLogin,meta:{title:'律所登录'}},
  {path: '/informationInput', name:'informationInput',component:informationInput,meta:{title:'录入案件信息'}},
  {path: '/caseList', name:'caseList',component:caseList,meta:{title:'案件列表'}},
  {path: '/lawfirmList', name:'lawfirmList',component:lawfirmList,meta:{title:'律师律所列表'}},
  {path: '/evaluate', name:'evaluate',component:evaluate,meta:{title:'评价'}},
  {path: '/caseDetails', name:'caseDetails',component:caseDetails,meta:{title:'案件详情'}},
  {path: '/evaluateList', name:'evaluateList',component:evaluateList,meta:{title:'我的评价'}},
  {
    path:'/lawAdmin',
    component:lawAdmin.mdPage,
    children:[
      {path: 'home', name: 'lawHome', component:lawAdmin.home,meta:{title:'律蛙数字法治-智慧司法平台'}},
      {path: 'service', name: 'lawService', component:lawAdmin.service,meta:{title:'律蛙数字法治-智慧司法平台'}},
    ]
  },
  {path: '/bigData', name:'bigData',component:bigData,meta:{title:'律蛙数字法治-智慧司法平台'}},
  {path: '/chongqing', name:'chongqing',component:chongqing,meta:{title:'智能法律问答系统'}},
  {path: '/cqadviser', name:'cqadviser',component:cqadviser,meta:{title:'智能法律问答系统'}},
  {path: '/search', name:'search',component:search,meta:{title:'智能法律问答系统'}},
  {path: '/cqdownload', name:'cqdownload',component:cqdownload,meta:{title:'智能法律问答系统'}},
]

export default {
  routes:routes
}
