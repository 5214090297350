<template>
    <div>
        <div style="background: url(/img/caseInput/banner.png);background-size: 100% 100%;width: 7.5rem;height: 2.63rem;text-align: center;font-family: PingFang SC;position: relative">
            <div>
                <img style="width: 0.8rem;height: 0.8rem;vertical-align: top;margin: 0.2rem 0 0.15rem;" src="/img/caseInput/logo.png">
            </div>
            <div style="font-size: 0.4rem;font-weight: bold;color: #0070CF;line-height: 0.54rem;">
                <div>成都市武侯区司法局</div>
                <div>律师&律所评价系统</div>
            </div>
        </div>
        <div style="height: 1.08rem;border-bottom: 0.01rem solid #DFDFDF;font-family: PingFang SC;line-height: 1.08rem;">
            <img style="width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.39rem 0.1rem 0 0.31rem;" src="/img/caseInput/anjianliebiao.png">
            <div style="display: inline-block;font-size: 0.3rem;font-weight: bold;color: #000000;">律师律所列表</div>
            <div style="display: inline-block;float: right;margin: 0.3rem 0.3rem 0 0;width: 3.46rem;height: 0.56rem;background: #EBEBEB;border-radius: 0.04rem;position: relative">
                <input v-model="key" style="width: 100%;height: 100%;vertical-align: top;padding-left: 0.2rem;font-size: 0.24rem" placeholder="输入关键词搜索"/>
                <img @click="search" style="width: 0.3rem;height: 0.3rem;position: absolute;right: 0.2rem;top: 0.13rem;" src="/img/caseInput/sousuo.png">
            </div>
        </div>
        <!--按钮-->
        <div style="font-family: PingFang SC;margin: 0.3rem 0.3rem 0;">
            <div class="lawFirmBottom" :style="{background:(type==2?'#0694E6':'#CCCCCC')}" style="margin-right: 0.2rem;" @click="onType(2)">律师</div>
            <div class="lawFirmBottom" :style="{background:(type==1?'#0694E6':'#CCCCCC')}"  @click="onType(1)">律所</div>
        </div>
        <!--列表-->
        <div v-for="(value,index) in indexLawyerAndLawList" style="font-family: PingFang SC;margin: 0 0.3rem;height: 1.80rem;border-bottom: 0.01rem solid #DFDFDF" v-if="type==2" @click="lawDetails(value.lawyerId)">
            <img style="width: 1.2rem;height: 1.2rem;margin: 0.3rem 0.19rem 0 0" src="/img/caseInput/mrtx.png" v-if="value.lawyerImage == null">
            <img style="width: 1.2rem;height: 1.2rem;margin: 0.3rem 0.19rem 0 0" :src="value.lawyerImage" v-else>
            <div style="display: inline-block;vertical-align: top;">
                <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.84rem;margin-top: 0.05rem">
                    {{value.lawyerName}}
                    <img style="width:0.24rem;height: 0.24rem;margin-left: 0.15rem;margin-right: 0.07rem;" src="/img/caseInput/pingfen.png">
                    <span style="font-size: 0.22rem;color: rgba(51, 51, 51, 1);vertical-align: top;">{{value.lawyerStar}}</span>
                </div>
                <div style="font-size: 0.24rem;font-weight: 500;color: #999999;">
                    <img src="/img/caseInput/top_suoshujigou.png" style="width: 0.18rem;height: 0.2rem;vertical-align: top;margin: 0.08rem 0.1rem 0 0">
                    <div style="display: inline-block;overflow: hidden;text-overflow: ellipsis;width: 4.6rem;white-space: nowrap;">{{value.lawName}}</div>
                </div>
            </div>
        </div>
        <div v-for="(value,index) in indexLawyerAndLawList" @click="lawfirmDetails(value.lawOfficeId)" style="font-family: PingFang SC;margin: 0 0.3rem;height: 1.80rem;border-bottom: 0.01rem solid #DFDFDF" v-if="type==1">
            <img style="width: 1.2rem;height: 1.2rem;margin: 0.3rem 0.19rem 0 0" src="/img/caseInput/lawFirm.png" v-if="value.lawPhoto == null">
            <img style="width: 1.2rem;height: 1.2rem;margin: 0.3rem 0.19rem 0 0" :src="value.lawPhoto" v-else>
            <div style="display: inline-block;vertical-align: top;">
                <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.84rem;margin-top: 0.05rem">
                    {{value.lawName}}
                </div>
                <div>
                    <img style="width:0.24rem;height: 0.24rem;margin-right: 0.07rem;margin-bottom: 0.01rem;" src="/img/caseInput/pingfen.png">
                    <span style="font-size: 0.22rem;color: rgba(51, 51, 51, 1);vertical-align: top;margin-top: 0.1rem;">{{value.lawStar}}</span>
                </div>
            </div>
        </div>
        <div style="padding: 0.5rem"></div>
        <van-tabbar v-model="active" style="height: 1rem;font-size: 0.2rem">
            <van-tabbar-item>
                <span>首页</span>
                <template #icon="props">
                    <img :src="active ? iconShow.active : iconShow.inactive" style="width: 0.48rem;height: 0.48rem"/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="jump">
                <span>我的</span>
                <template #icon="props">
                    <img :src="active ? iconmy.active : iconmy.inactive" style="width: 0.48rem;height: 0.48rem"/>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        name: "lawfirmList",
        data() {
            return {
                key : '',
                type : 2,
                page : 1,
                indexLawyerAndLawList : [],
                loadData : false,
                name : '我的',
                active: 0,
                iconShow: {
                    active: '/img/caseInput/home1.png',
                    inactive: '/img/caseInput/home.png',
                },
                iconmy: {
                    active: '/img/caseInput/my.png',
                    inactive: '/img/caseInput/my1.png',
                },
                userId : '',
            }
        },
        methods :{
            jump : function(){
                this.$router.push({name: 'evaluateList'})
            },
            lawDetails : function(lawyerId){
                this.$router.push({name: 'evaluate', query: {lawyerId:lawyerId}})
            },
            lawfirmDetails : function(lawOfficeId){
                this.$router.push({name: 'evaluate', query: {lawOfficeId:lawOfficeId}})
            },
            search : function(){
                this.page = 1
                this.indexLawyerAndLawList = []
                this.indexLawyerAndLaw()
            },
            onType : function(type){
                this.type = type
                this.page = 1
                this.indexLawyerAndLawList = []
                this.indexLawyerAndLaw()
            },
            indexLawyerAndLaw : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/indexLawyerAndLaw',
                    data: {
                        userId  : _this.userId,
                        type : _this.type,
                        key : _this.key,
                        PageSize : 20,
                        page : _this.page,
                    },
                    success: function (res) {
                        console.log(res)
                        if(res){
                            for(var i in res.List){
                                _this.indexLawyerAndLawList.push(res.List[i])
                            }
                            _this.loadData = false
                        }
                    }
                })
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
        mounted () {
            var _this = this
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    if (arr2[0] == 'userId') {
                        _this.userId = arr2[1]
                    }
                }
            }
            _this.setPageSize()
            _this.indexLawyerAndLaw()
        },

        created() {
            let that = this;
            window.onscroll = function () {
                // scrollTop 滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                // windowHeight 可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // scrollHeight 滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                // 滚动条到底部的条件
                if (scrollTop + windowHeight+5 >= scrollHeight &&!that.loadData) {
                    that.loadData = true
                    // 加载数据
                    that.page++
                    that.indexLawyerAndLaw();
                }
            }
        },
    }
</script>

<style scoped>
    .lawFirmBottom{width: 0.94rem;height: 0.46rem;border-radius: 0.04rem;font-size: 0.24rem;font-weight: bold;color: #FFFFFF;text-align: center;line-height: 0.46rem;
    display: inline-block;}
</style>
