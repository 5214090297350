<style scoped>
    .home{background-color: rgb(241,239,240);padding:20px;}
    .screen{width: calc(100% - 80px);display: inline-block;}
    .screen >>> .ivu-tabs-nav-scroll{height: 60px;line-height: 60px;}
    /*.screen >>> .ivu-tabs .ivu-tabs-tabpane{width: calc(100% - 180px);}*/
    .screen >>> .ivu-tabs-nav{height: 60px;line-height: 60px;}
    .screen >>> .ivu-tabs-nav .ivu-tabs-tab-active{color: #00b86c;padding: 0px 16px;}
    .screen >>> .ivu-tabs-nav .ivu-tabs-tab:hover{color: #00b86c;}
    .screen >>> .ivu-tabs-ink-bar{background-color: #00b86c;}
    .screen >>> .ivu-tabs-bar{border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
    .house{width: 72px;height: 72px;margin-top: 29px;}
    .number{font-size: 48px;color: #7a7a7a;}
    .tipsName{font-size: 16px;color: #8d8d8d;}
    .displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
    .administration{border-bottom: 1px solid #dddddd;text-align: left;margin-bottom: 0}
    .administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
    .label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
    .forMtitle1{background-color: #f9f9f9;height: 60px;text-align: center;}
    .forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
    .forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;height: 60px;}
    .forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
    .forMcontent{color: #666666;font-size:14px;padding:5px 0;border-bottom: 1px solid #dddddd;text-align: center;}
    .forMcontent p {display: inline-block;width: 20%;overflow: hidden; vertical-align:middle;white-space: nowrap;padding:10px 0;}
    .smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}
    .alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -33px;}
    .alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
    .qrCode{width: 100px;height: 100px;}
    .addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
    .myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
    .icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
    .contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
    .single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
    .inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}
    /*表格*/
    .publicWelfare table {
      font-family: Consolas, Menlo, Courier, monospace;
      font-size: 12px;
      border-collapse: collapse;
      border-spacing: 0;
      empty-cells: show;
      border: 1px solid #e9e9e9;
      width: 100%;
      margin-bottom: 24px;
    }

    .formtable {
      font-family: Consolas, Menlo, Courier, monospace;
      font-size: 12px;
      border-collapse: collapse;
      border-spacing: 0;
      empty-cells: show;
      border: 1px solid #e9e9e9;
      width: 100%;
      margin-bottom: 24px;
    }

    .publicWelfare table th {
      background: #f7f7f7;
      white-space: nowrap;
      color: #5c6b77;
      font-weight: 600;
    }

    .publicWelfare table td,
    .publicWelfare table th {
      border: 1px solid #e9e9e9;
      padding: 8px 16px;
      text-align: left;
    }
</style>

<template>
  <div class="home">
    <!--审核-->
    <div>
      <div class="font-size" style="width:100%;display: inline-block;">
        <Tabs v-model="tabsType"  @on-click="switchClick" >
          <TabPane name="1" label="村居法律顾问">
            <div style="padding:10px;"></div>
            <div style="font-size:16px;border-left:solid #de4f0b 3px;padding-left:10px;">
              <span style="vertical-align:middle;">服务档案</span>
              <div style="display: inline-block;vertical-align:middle;margin-left:10px">
                （今日上传<span style="color: red;">{{getfileData.day}}</span>条
                总上传<span style="color: red;">{{count}}</span>条）
              </div>
              <div style="display: inline-block;vertical-align:middle;margin-left:10px">
                总服务时长：<span style="color: red;">{{parseInt(serviceDuration/60)}}</span>小时<span style="color: red;">{{serviceDuration%60}}</span>分钟
              </div>
<!--              -->
              <div v-if="monthAssessment.ClockNum" style="display: inline-block">

                <span style="color: #e5e5e5; margin: 0 7px">|</span>
                本月上传：<span style="color: red">{{ monthAssessment.FilesCount }}</span
              >个电子档案
              </div>
              <div style="display: inline-block" v-if="fileTotalMonthData">
                <span style="color: #e5e5e5; margin: 0 7px">|</span>
                本月服务时长
                <span style="color: red">
                      {{ parseInt(fileTotalMonthData / 60) }}小时{{fileTotalMonthData % 60}}分钟
                    </span>
              </div>
            </div>
            <div style="padding:10px;"></div>
            <div class="administration">
              <div style="padding:0 20px;">
                <div style="font-size:15px;">
                  <span style="vertical-align:middle;">数据筛选：</span>
                  <span style="padding:5px;"></span>
                  <div class="inline-block">
                    <Select @on-open-change="getAreaData" v-model="model8" style="width:200px" @on-change="choice"
                            :label-in-value="labelInValue" :placeholder="tipsWord" clearable>
                      <Option v-for="(item,index) in cityList" v-if="cityList != ''"
                              :value="item.AdminId +','+ index  +','+ '2'" :label="item.AdminName" :key="index">
                        {{ item.AdminName}}</Option>
                      <Option v-for="(item,index) in villagesList" v-if="villagesList != ''"
                              :value="item.VillagesId +','+ index  +','+ '2'" :label="item.VillagesName" :key="index">
                        {{ item.VillagesName }}</Option>
                    </Select>
                    <Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue"
                            v-if="areaData" :placeholder="tipsWord" clearable>
                      <Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName"
                              :key="index">{{ i.AreaName }}</Option>
                    </Select>
                  </div>
                  <span style="padding:10px;"></span>
                  <Select v-model="model10" clearable style="width:200px" @on-change="stateChoice" placeholder="请选择审核状态">
                    <Option v-for="item in stateCityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                  </Select>
                  <span style="padding:10px;"></span>
                  <div class="inline-block">
                    <Cascader :data="evaluationData" v-model="evaluation" @on-change="addressSelect" placeholder="请选择考核分类" clearable></Cascader>
                    <!--<Select v-model="model1" style="width:200px;" @on-change="addressSelect" placeholder="请选择分栏"-->
                    <!--clearable>-->
                    <!--<Option v-for="item in evaluationData" :value="item.value" :key="item.value">-->
                    <!--{{ item.label }}</Option>-->
                    <!--</Select>-->
                  </div>
                  <!--<Select v-model="model1" style="width:200px;" @on-change="popularizingLaw"-->
                  <!--placeholder="请选择档案类型" clearable>-->
                  <!--<Option v-for="item in typeList" :value="item.FileTypeId" :key="item.FileTypeId">-->
                  <!--{{ item.FileTypeName }}</Option>-->
                  <!--</Select>-->
                  <span style="padding:10px;"></span>
                  <Select v-model="model2" style="width:200px;" @on-change="onLawyerName" placeholder="请选择法律顾问"
                          clearable filterable>
                    <Option v-for="item in lawyerData" :value="item.LawyerId" :key="'L'+item.LawyerId">
                      {{ item.LawyerName }}</Option>
                  </Select>
                  <span style="padding:10px;"></span>
                  <Select v-model="serviceSerData.show" style="width:200px;" @on-change="serviceSerName" placeholder="请选择服务方式"
                          clearable filterable>
                    <Option value="0" key="0">暂无</Option>
                    <Option v-for="item in serviceSerData.list" :value="item.ServiceTypeId" :key="item.ServiceTypeId">
                      {{ item.ServiceTypeName }}</Option>
                  </Select>
                  <span style="padding:10px;"></span>
                  <Select style="width:200px" v-model="model140" filterable remote clearable :remote-method="remoteMethod2"
                          @on-change="lawyerClick3" :loading="loading2" placeholder="请输入社区">
                    <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">
                      {{value.VillagesName}}</Option>
                  </Select>
                  <div style="margin:10px 0px;display: inline-block;">
                    <span>服务时间：</span>
                    <div style="display: inline-block;">
                      <RadioGroup type="button" v-model="serviceDate.identification" @on-change="fileAdminList(1)">
                        <Radio label="m">本月</Radio>
                        <Radio label="q">本季</Radio>
                        <Radio label="y">本年</Radio>
                        <Radio label="a">所有</Radio>
                        <Radio label="custom">自定义</Radio>
                      </RadioGroup>
                      <DatePicker v-if="serviceDate.identification=='custom'"
                                  @on-change="serviceDate.startTime = $event[0],serviceDate.endTime=$event[1]"
                                  @on-ok="fileAdminList(1)" type="datetimerange" format="yyyy-MM-dd" placeholder="请选择时间区间">
                      </DatePicker>
                    </div>
                  </div>
                  <div style="margin:10px 10px;display: inline-block;">
                    <Button type="primary" @click="clockDownload" >筛选导出</Button>
                  </div>
                </div>
              </div>
              <div style="padding:10px;"></div>
            </div>
            <div style="margin-bottom: 40px;font-size: 16px;text-align: left;">
              <div style="text-align: left;margin-top: 20px">
                <Button @click="exportLawyerData='';LawyerName='';endTime='';submit('选择')" type="primary" size="small">批量导出</Button>
                <Button @click="submitCheckActive()" type="success" size="small" style="margin-left: 10px">批量审核</Button>
              </div>
              <div style="text-align: center;padding:20px;">
                <div class="forMtitle1">
                  <p style="width:4%"><Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">序号</Checkbox></p>
                  <p style="width:15%">所属村(社区)</p>
                  <p style="width:7%">村居法律顾问</p>
                  <p style="width:4%">用户</p>
                  <p style="width: 9%">服务方式</p>
                  <p style="width:7%;">服务时间</p>
                  <p style="width: 7%">服务时长</p>
                  <p style="width: 5%" v-if="cq != ''">评价</p>
                  <p style="width:9%;">服务档案上传时间</p>
                  <!--                  <p style="width:10%">街道（镇）</p>-->
                                    <p v-if="cq == ''" style="width: 8%">服务类型</p>
                  <!--                  <p style="width: 15%">标题</p>-->
                  <!--                  <p style="width: 10%">服务时间</p>-->
                  <!--                  <p style="width: 10%">上传时间</p>-->
                  <p style="width: 9%">服务档案审核状态</p>
                  <p style="width: 21%">操作</p>
                </div>
                <div class="forMcontent" v-for="(value,index) in ElectronicsList">
                  <p style="width:4%">
                    <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange" class="articel">
                      <Checkbox :label="value.FileId+','+value.IsCheck">
                        <span v-if="(page-1)*pageSize+index+1 < 10">0{{(page-1)*pageSize+index+1}}</span>
                        <span v-else>{{(page-1)*pageSize+index+1}}</span>
                      </Checkbox>
                    </CheckboxGroup>
                  </p>
                  <p style="width:15%" >
                    {{value.CityName}}{{value.AdminName}}{{value.VillagesName}}
                  </p>
                  <p style="width:7%">{{value.LawyerName}}</p>
                  <p style="width: 4%">
                    {{value.ServiceLinkman||'--'}}
                  </p>
                  <p style="width: 9%;" v-if="cq == ''">
                    {{value.DailyType?value.DailyType.toString():'--'}}
                  </p>
                  <p v-else style="width: 9%;">{{value.ServiceTypeName}}</p>
                  <p style="width: 7%">
                    {{uploadDate(value.ServiceTime,2)||'暂无'}}
                  </p>
                  <p style="width: 7%">
                    {{value.ServiceLong}}小时{{value.ServiceMins}}分钟
                  </p>
                  <p v-if="cq != ''" style="width: 5%;display: inline-block;vertical-align:middle;">
                    <span v-if="value.OrderGrade == 5">非常满意</span>
                    <span v-else-if="value.OrderGrade == 4">满意</span>
                    <span v-else-if="value.OrderGrade == 3">基本满意</span>
                    <span v-else-if="value.OrderGrade == 2">不满意</span>
                    <span v-else-if="value.OrderGrade == 1">非常不满意</span>
                    <span v-else>--</span>
                  </p>
                  <p style="width: 9%">
                    {{uploadDate(value.FileTime,1)||'暂无'}}
                  </p>
                                    <p style="width: 8%;" v-if="cq == ''">
                                      {{value.title}}-{{value.pTitle}}
                                    </p>



                  <div style="width: 9%;display: inline-block;vertical-align:middle;">
                    <div :style="{'color':value.IsCheck==1?'red':''}">{{value.IsCheck==0?'未审核':value.IsCheck==1?'审核通过':'已驳回'}}</div>
                  </div>
                  <div style="width: 21%;display: inline-block;vertical-align:middle;">
                    <Button type="primary" size="small"
                            @click="see(value.FileStatsId);modal3 = true,imgPanel=value">查看/审核</Button>
                    <span style="padding:3px;"></span>
                    <Button type="primary"  size="small" @click="submit(value.FileId)" >导出</Button>
                    <span style="padding:3px;"></span>
                    <Button type="primary"  size="small" @click="exportLawyerData='';LawyerName='';endTime='';submit(value.FileId,'1')" >打印</Button>
                    <span style="padding:3px;"></span>
                    <Button v-if="cq != ''" type="error" size="small" @click="deleteClick(value.FileId)">删除</Button>
                  </div>
                </div>
                <div style="padding:10px;"></div>
                <Page :total="count" show-total :page-size="pageSize" show-elevator
                      @on-change="page = $event;fileAdminList()" :current="page" />
              </div>
            </div>
          </TabPane>
<!--          <TabPane label="社区公证员" name="2" v-if="cq != ''">-->
<!--            <div style="background-color: white;">-->
<!--              <div style="padding:10px;"></div>-->
<!--              <div style="font-size:16px;border-left:solid #de4f0b 3px;padding-left:10px;">-->
<!--                <span style="vertical-align:middle;">工作日志</span>-->
<!--                <div style="display: inline-block;vertical-align:middle;margin-left:30px;">-->
<!--                  共<span style="color: red">{{adminUserGzyData.count}}</span>条-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="padding:10px;"></div>-->
<!--              <div style="margin-bottom: 40px;font-size: 16px;text-align: left;">-->
<!--                <div style="text-align: center;padding:20px;">-->
<!--                  <div class="forMtitle1">-->
<!--                    <p style="width:4%">-->
<!--                      序号-->
<!--                      &lt;!&ndash;              <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll"></Checkbox>&ndash;&gt;-->
<!--                    </p>-->
<!--                    <p style="width:9%;">上传时间</p>-->
<!--                    &lt;!&ndash;            <p style="width:7%;">服务时间</p>&ndash;&gt;-->
<!--                    <p style="width:22%">所属村(社区)</p>-->
<!--                    <p style="width:4%">用户</p>-->
<!--                    <p style="width:7%">村居公证员</p>-->
<!--                    <p style="width: 9%">服务方式</p>-->
<!--                    <p style="width: 7%">服务时长</p>-->
<!--                    <p style="width: 5%">评价</p>-->
<!--                    <p style="width: 7%">审核状态</p>-->
<!--                    <p style="width: 21%">操作</p>-->
<!--                  </div>-->
<!--                  <div class="forMcontent" v-for="(value,index) in adminUserGzyData.List">-->
<!--                    <p style="width:4%">-->
<!--                      <span v-if="(adminUserGzyData.page-1)*adminUserGzyData.pageSize+index+1 < 10">0{{(adminUserGzyData.page-1)*adminUserGzyData.pageSize+index+1}}</span>-->
<!--                      <span v-else>{{(adminUserGzyData.page-1)*adminUserGzyData.pageSize+index+1}}</span>-->
<!--                    </p>-->
<!--                    <p style="width: 9%">-->
<!--                      {{value.CreateTime||'暂无'}}-->
<!--                    </p>-->
<!--                    <p  style="width:22%">-->
<!--                      {{value.VillagesName.CityName[0].replace('重庆市', '')}}{{value.VillagesName.AdminName[0]}}{{value.VillagesName.VillagesName[0]}}-->
<!--                    </p>-->
<!--                    <p style="width: 4%">-->
<!--                      {{value.ServiceLinkman||'&#45;&#45;'}}-->
<!--                    </p>-->
<!--                    <p style="width:7%">{{value.GreffierName}}</p>-->
<!--                    <p style="width: 9%;">{{value.ServiceTypeName}}</p>-->
<!--                    <p style="width: 7%">-->
<!--                      {{value.ServiceLong}}小时{{value.ServiceMins}}分钟-->
<!--                    </p>-->
<!--                    <p style="width: 5%;display: inline-block;vertical-align:middle;">-->
<!--                      <span v-if="value.OrderGrade == 5">非常满意</span>-->
<!--                      <span v-else-if="value.OrderGrade == 4">满意</span>-->
<!--                      <span v-else-if="value.OrderGrade == 3">基本满意</span>-->
<!--                      <span v-else-if="value.OrderGrade == 2">不满意</span>-->
<!--                      <span v-else-if="value.OrderGrade == 1">非常不满意</span>-->
<!--                      <span v-else>&#45;&#45;</span>-->
<!--                    </p>-->
<!--                    <div style="width: 7%;display: inline-block;vertical-align:middle;">-->
<!--                      <div :style="{'color':value.IsCheck==1?'red':''}">{{value.IsCheck==0?'未审核':value.IsCheck==1?'审核通过':'已驳回'}}</div>-->
<!--                    </div>-->
<!--                    <div style="width: 21%;display: inline-block;vertical-align:middle;">-->
<!--                      <Button type="primary" size="small"-->
<!--                              @click="seeGzy(value)">查看/审核</Button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div style="padding:10px;"></div>-->
<!--                  <Page :total="adminUserGzyData.count" show-total :page-size="adminUserGzyData.pageSize" show-elevator-->
<!--                        @on-change="adminUserGzyData.page = $event;aQuestionnaireList()" :current="adminUserGzyData.page" />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </TabPane>-->
<!--          <TabPane label="法律明白人" name="3" v-if="cq != ''">-->
<!--            <div style="background-color: white;">-->
<!--              <div style="padding:10px;"></div>-->
<!--              <div style="font-size:16px;border-left:solid #de4f0b 3px;padding-left:10px;">-->
<!--                <span style="vertical-align:middle;">工作日志</span>-->
<!--                <div style="display: inline-block;vertical-align:middle;margin-left:30px;">-->
<!--                  共<span style="color: red">{{adminUserMbrData.count}}</span>条-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="padding:10px;"></div>-->
<!--              <div style="margin-bottom: 40px;font-size: 16px;text-align: left;">-->
<!--                <div style="text-align: center;padding:20px;">-->
<!--                  <div class="forMtitle1">-->
<!--                    <p style="width:4%">-->
<!--                      序号-->
<!--                    </p>-->
<!--                    <p style="width:9%;">上传时间</p>-->
<!--                    <p style="width:22%">所属村(社区)</p>-->
<!--                    <p style="width:4%">用户</p>-->
<!--                    <p style="width:7%">村居明白人</p>-->
<!--                    <p style="width: 9%">服务方式</p>-->
<!--                    <p style="width: 7%">服务时长</p>-->
<!--                    <p style="width: 5%">评价</p>-->
<!--                    <p style="width: 7%">审核状态</p>-->
<!--                    <p style="width: 21%">操作</p>-->
<!--                  </div>-->
<!--                  <div class="forMcontent" v-for="(value,index) in adminUserMbrData.List">-->
<!--                    <p style="width:4%">-->
<!--                      <span v-if="(adminUserMbrData.page-1)*adminUserMbrData.pageSize+index+1 < 10">0{{(adminUserMbrData.page-1)*adminUserMbrData.pageSize+index+1}}</span>-->
<!--                      <span v-else>{{(adminUserMbrData.page-1)*adminUserMbrData.pageSize+index+1}}</span>-->
<!--                    </p>-->
<!--                    <p style="width: 9%">-->
<!--                      {{value.CreateTime||'暂无'}}-->
<!--                    </p>-->
<!--                    <p  style="width:22%">-->
<!--                      {{value.VillagesName.CityName[0].replace('重庆市', '')}}{{value.VillagesName.AdminName[0]}}{{value.VillagesName.VillagesName[0]}}-->
<!--                    </p>-->
<!--                    <p style="width: 4%">-->
<!--                      {{value.ServiceLinkman||'&#45;&#45;'}}-->
<!--                    </p>-->
<!--                    <p style="width:7%">{{value.InsiderName}}</p>-->
<!--                    <p style="width: 9%;">{{value.ServiceTypeName}}</p>-->
<!--                    <p style="width: 7%">-->
<!--                      {{value.ServiceLong}}小时{{value.ServiceMins}}分钟-->
<!--                    </p>-->
<!--                    <p style="width: 5%;display: inline-block;vertical-align:middle;">-->
<!--                      <span v-if="value.OrderGrade == 5">非常满意</span>-->
<!--                      <span v-else-if="value.OrderGrade == 4">满意</span>-->
<!--                      <span v-else-if="value.OrderGrade == 3">基本满意</span>-->
<!--                      <span v-else-if="value.OrderGrade == 2">不满意</span>-->
<!--                      <span v-else-if="value.OrderGrade == 1">非常不满意</span>-->
<!--                      <span v-else>&#45;&#45;</span>-->
<!--                    </p>-->
<!--                    <div style="width: 7%;display: inline-block;vertical-align:middle;">-->
<!--                      <div :style="{'color':value.IsCheck==1?'red':''}">{{value.IsCheck==0?'未审核':value.IsCheck==1?'审核通过':'已驳回'}}</div>-->
<!--                    </div>-->
<!--                    <div style="width: 21%;display: inline-block;vertical-align:middle;">-->
<!--                      <Button type="primary" size="small"-->
<!--                              @click="seeMbr(value);">查看/审核</Button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div style="padding:10px;"></div>-->
<!--                  <Page :total="adminUserMbrData.count" show-total :page-size="adminUserMbrData.pageSize" show-elevator-->
<!--                        @on-change="adminUserMbrData.page = $event;aQuestionnaireList()" :current="adminUserMbrData.page" />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </TabPane>-->
        </Tabs>
      </div>
    </div>
    <!--查看img-->
    <Modal v-model="modal3" :title="eleFileViewData.FileName" width="950" draggable scrollable :styles="{top: '0'}":zIndex="zIndex" @on-cancel="cancel">
      <div style="height: 420px;overflow: auto;">
        <!--<p>{{fileurl}}</p>-->
        <div style="margin-bottom: 10px;" v-if="eleFileViewData.DocumentUrl">
          <div v-for="(value,index) in eleFileViewData.DocumentUrl" style="margin-left: 30px" >
            {{value.name}}
            <Button type="primary" @click="seeFile(domainName+value.url,1)" >查看</Button>
            <Button type="primary" @click="seeFile(domainName+value.url,2)" >下载</Button>
          </div>

        </div>
        <!--<Carousel v-model="value1" v-if="modal3 && fileurl" @on-click="enlarge">-->
        <!--<CarouselItem v-for="(value,index) in fileurl" >-->
        <!--<img :src="value" style="width: 100%;">-->
        <!--</CarouselItem>-->
        <!--</Carousel>-->
        <viewer :images="tupians" v-if="eleFileViewData.FileUrl" style="width: 47%;display: inline-block;">
          <!--<i-col span="4" v-for="item in tupians" v-if="tupians">-->
          <!--<div class="detailed">-->
          <!--<img :src="item" alt="">-->
          <!--</div>-->
          <!--</i-col>-->
          <Carousel v-model="value1" v-if="modal3 && eleFileViewData.FileUrl" @on-click="enlarge" dots="none">
            <CarouselItem v-for="(value,index) in eleFileViewData.FileUrl" style="text-align: center">
              <img :src="domainName+value" style="max-width:100%;max-height: 420px">
            </CarouselItem>
          </Carousel>
        </viewer>
        <div style="display: inline-block;vertical-align: top;width: 50%;margin-left: 20px">
          <div v-if="eleFileViewData.villagesNameArr">所属村(社区)：<p v-for="(value,index) in eleFileViewData.villagesNameArr" style="display: inline-block">
              {{value.VillagesName}}<span v-if="index+1<eleFileViewData.villagesNameArr.length">-</span></p>
          </div>
          <div v-if="cq == ''&& eleFileViewData.title">
            考核分栏：<span v-if="eleFileViewData.title">{{eleFileViewData.title}}-</span>{{eleFileViewData.pTitle}}
          </div>
          <div v-if="eleFileViewData.EvaluationCount">
            考核计数：<span>{{eleFileViewData.EvaluationCount}}{{eleFileViewData.Unit}}</span>
          </div>
          <div v-if="eleFileViewData.ServiceLinkman">联系人：{{eleFileViewData.ServiceLinkman}}</div>
          <div v-if="eleFileViewData.ServiceTel">联系电话：{{eleFileViewData.ServiceTel}}</div>
          <div v-if="eleFileViewData.ConsultHumanTypeArr">咨询人类别类型：
            <p v-for="(value,index) in eleFileViewData.ConsultHumanTypeArr" style="display: inline-block">
              {{value}}<span v-if="index+1<eleFileViewData.ConsultHumanTypeArr.length">-</span></p>
          </div>
          <div v-if="eleFileViewData.ManageSerTypeArr">服务事项：
            <p v-for="(value,index) in eleFileViewData.ManageSerTypeArr" style="display: inline-block">
              {{value}}<span v-if="index+1<eleFileViewData.ManageSerTypeArr.length">-</span></p>
          </div>
          <div v-if="eleFileViewData.MediateTypeArr">纠纷类型：
            <p v-for="(value,index) in eleFileViewData.MediateTypeArr" style="display: inline-block">
              {{value}}<span v-if="index+1<eleFileViewData.MediateTypeArr.length">-</span></p>
          </div>
          <div v-if="eleFileViewData.ConsultTypeArr">知识类型：
            <p v-for="(value,index) in eleFileViewData.ConsultTypeArr" style="display: inline-block">
              {{value}}<span v-if="index+1<eleFileViewData.ConsultTypeArr.length">-</span></p>
          </div>
          <div v-if="eleFileViewData.DealTypeArr">处理方式：
            <p v-for="(value,index) in eleFileViewData.DealTypeArr" style="display: inline-block">
              {{value}}<span v-if="index+1<eleFileViewData.DealTypeArr.length">-</span></p>
          </div>
          <div v-if="eleFileViewData.MediateHumanTypeArr">纠纷人群：
            <p v-for="(value,index) in eleFileViewData.MediateHumanTypeArr" style="display: inline-block">
              {{value}}<span v-if="index+1<eleFileViewData.MediateHumanTypeArr.length">-</span></p>
          </div>
          <div v-if="eleFileViewData.PetitionerArr">
            <div v-for="(value,index) in eleFileViewData.PetitionerArr" style="display: inline-block">
              <div>信访人：{{value.name}}  信访人电话：{{value.telephone}}</div>
            </div>
          </div>
          <div v-if="eleFileViewData.MediateResult">调解结果：
            <span v-if="eleFileViewData.MediateResult == 1">调解成功</span>
            <span v-if="eleFileViewData.MediateResult == 2">调解不成功</span>
          </div>
          <div v-if="eleFileViewData.ServiceCount">服务人数：{{eleFileViewData.ServiceCount}}</div>
          <div v-if="eleFileViewData.ServiceTime">服务时间：{{uploadDate(eleFileViewData.ServiceTime,2)}}</div>
          <div v-if="parseInt(eleFileViewData.ServiceLong) > 0 || eleFileViewData.ServiceMins">服务时长：{{parseInt(eleFileViewData.ServiceLong)}}小时
            <span v-if="eleFileViewData.ServiceMins">{{eleFileViewData.ServiceMins}}分钟</span>
          </div>
          <div v-if="cq == '' && eleFileViewData.DailyType">服务方式：<p v-for="(value,index) in eleFileViewData.DailyType" style="display: inline-block">
              {{value}}<span v-if="index+1<DailyType.length">-</span></p>
          </div>
          <div v-if="cq != '' && eleFileViewData.ServiceTypeName">
            服务方式：<span style="width: 8%;" v-if="eleFileViewData.ServiceTypeName">{{eleFileViewData.ServiceTypeName}}</span>
                    <span style="width: 8%;" v-else>{{eleFileViewData.EvaluationTypePid}}</span>
          </div>
          <div v-if="eleFileViewData.FileExplain">内容：{{eleFileViewData.FileExplain}}</div>
          <div v-if="eleFileViewData.OrderGrade">评价：
            <span v-if="eleFileViewData.OrderGrade == 5">非常满意</span>
            <span v-if="eleFileViewData.OrderGrade == 4">满意</span>
            <span v-if="eleFileViewData.OrderGrade == 3">基本满意</span>
            <span v-if="eleFileViewData.OrderGrade == 2">不满意</span>
            <span v-if="eleFileViewData.OrderGrade == 1">非常不满意</span>
          </div>
        </div>
      </div>

      <div style="position: relative">
        <div style="width: 47%;position: absolute;z-index: 999;bottom: 0;text-align: center;">
          <img :style="value1 == index ?'outline: 2px solid #000;':'border: 2px;'" v-for="(value,index) in eleFileViewData.FileUrl" :src="domainName+value" style="width:40px;height: 40px;margin: 0 5px" @click="switchWalkingLantern(index)">
        </div>
      </div>
      <div slot="footer">
        <template v-if="imgPanel.IsCheck==0">
          <div style="padding:3px;"></div>
          <!-- /审核失败/未审核 -->
          <Button type="success" @click="toExamine(1,imgPanel.FileId)">通过</Button>
          <span style="padding:5px;"></span>
          <Button type="warning" @click="toReject(imgPanel.FileId)">驳回</Button>
        </template>
        <Button @click="cancel">关闭</Button>
      </div>
    </Modal>
    <Modal v-model="modal6" title="驳回理由" @on-ok="toRejectDetermine" ok-text="发送" style="height: 300px" draggable
     >
      <Input v-model="checkMsg" maxlength="300" show-word-limit type="textarea" placeholder="驳回理由" />
    </Modal>
<!--    导出-->
    <Modal
        v-model="showExport"
        title="导出"
        @on-ok="submit" @on-cancel="onCancel"
        ok-text="导出" style="height: 300px">
        <DatePicker :value="timeData" v-model="timeData" :transfer="transfer" type="daterange" placement="bottom-end"
                  placeholder="选择时间" style="width: 200px" @on-change="totalTime"></DatePicker>
        <Select v-model="model3" style="width:200px;margin-left: 20px" @on-change="exportLawyerName" placeholder="全部律师"
                clearable filterable>
          <Option v-for="item in lawyerData" :value="item.LawyerId" :key="'L'+item.LawyerId">
            {{ item.LawyerName }}</Option>
        </Select>
    </Modal>
<!--    明白人板块-->
    <Modal v-model="adminUserMbrData.modal" :title="adminUserMbrData.seeData.InsiderFileTitle" width="950" draggable scrollable :styles="{top: '0'}":zIndex="zIndex">
      <div style="width: 47%;display: inline-block;">
        <div style="margin-bottom: 10px;" v-if="adminUserMbrData.documentUrl">
          <div v-for="(value,index) in adminUserMbrData.documentUrl" style="margin: 0 0 30px 30px" >
            {{value.name}}
            <Button type="primary" @click="seeFile(domainName+value.url,1)" >查看</Button>
            <Button type="primary" @click="seeFile(domainName+value.url,2)" >下载</Button>
          </div>
        </div>
        <viewer :images="adminUserMbrData.FileImg" v-if="adminUserMbrData.FileImg">
          <Carousel v-model="adminUserMbrData.value1" v-if="adminUserMbrData.modal && adminUserMbrData.FileImg" @on-click="enlarge" dots="none">
            <CarouselItem v-for="(value,index) in adminUserMbrData.FileImg" style="text-align: center">
              <img :src="domainName+value" style="max-width:100%;max-height: 420px">
            </CarouselItem>
          </Carousel>
        </viewer>
      </div>
      <div style="display: inline-block;vertical-align: top;width: 50%;margin-left: 20px">
        <div v-if="adminUserMbrData.seeData.InsiderName">姓名:{{adminUserMbrData.seeData.InsiderName}}</div>
        <div v-if="adminUserMbrData.seeData.InsiderFileTitle">标题:{{adminUserMbrData.seeData.InsiderFileTitle}}</div>
        <div v-if="adminUserMbrData.seeData.VillagesName">社区(村):
          <p v-for="(value,index) in adminUserMbrData.seeData.VillagesName.VillagesName" style="display: inline-block">
            {{value}}<span v-if="index+1<adminUserMbrData.seeData.VillagesName.length">-</span></p>
        </div>
        <div v-if="adminUserMbrData.seeData.ServiceLinkman">联系人:{{adminUserMbrData.seeData.ServiceLinkman}}</div>
        <div v-if="adminUserMbrData.seeData.ServiceTel">联系电话:{{adminUserMbrData.seeData.ServiceTel}}</div>
        <div v-if="adminUserMbrData.seeData.Content">走访情况:{{adminUserMbrData.seeData.Content}}</div>
        <div v-if="adminUserMbrData.seeData.CreateTime">上传时间:{{adminUserMbrData.seeData.CreateTime}}</div>
      </div>
      <div slot="footer">
        <template v-if="adminUserMbrData.seeData.IsCheck==0">
          <div style="padding:3px;"></div>
          <!-- /审核失败/未审核 -->
          <Button type="success" @click="toExamineMbr(1,adminUserMbrData.seeData.InsiderFileId)">通过</Button>
          <span style="padding:5px;"></span>
          <Button type="warning" @click="toRejectMbr(adminUserMbrData.seeData.InsiderFileId)">驳回</Button>
        </template>
        <Button @click="cancel">关闭</Button>
      </div>
    </Modal>
    <Modal v-model="adminUserMbrData.modal6" title="驳回理由" @on-ok="toRejectDetermineMbr" ok-text="发送" draggable
    >
      <Input v-model="adminUserMbrData.checkMsg" maxlength="300" show-word-limit type="textarea" placeholder="驳回理由" />
    </Modal>
<!--    公证员板块-->
    <Modal v-model="adminUserGzyData.modal" :title="adminUserGzyData.seeData.GreffierFileTitle" width="950" draggable scrollable :styles="{top: '0'}":zIndex="zIndex">
      <div style="width: 47%;display: inline-block;">
        <div style="margin-bottom: 10px;" v-if="adminUserGzyData.documentUrl">
          <div v-for="(value,index) in adminUserGzyData.documentUrl" style="margin: 0 0 30px 30px" >
            {{value.name}}
            <Button type="primary" @click="seeFile(domainName+value.url,1)" >查看</Button>
            <Button type="primary" @click="seeFile(domainName+value.url,2)" >下载</Button>
          </div>
        </div>
        <viewer :images="adminUserGzyData.FileImg" v-if="adminUserGzyData.FileImg">
          <Carousel v-model="value1" v-if="adminUserGzyData.modal && adminUserGzyData.FileImg" @on-click="enlarge" dots="none">
            <CarouselItem v-for="(value,index) in adminUserGzyData.FileImg" style="text-align: center">
              <img :src="domainName+value" style="max-width:100%;max-height: 420px">
            </CarouselItem>
          </Carousel>
        </viewer>
      </div>
      <div style="display: inline-block;vertical-align: top;width: 50%;margin-left: 20px">
        <div v-if="adminUserGzyData.seeData.GreffierName">姓名:{{adminUserGzyData.seeData.GreffierName}}</div>
        <div v-if="adminUserGzyData.seeData.GreffierFileTitle">标题:{{adminUserGzyData.seeData.GreffierFileTitle}}</div>
        <div v-if="adminUserGzyData.seeData.VillagesName">社区(村):
          <p v-for="(value,index) in adminUserGzyData.seeData.VillagesName.VillagesName" style="display: inline-block">
            {{value}}<span v-if="index+1<adminUserGzyData.seeData.VillagesName.length">-</span></p>
        </div>
        <div v-if="adminUserGzyData.seeData.ServiceLinkman">联系人:{{adminUserGzyData.seeData.ServiceLinkman}}</div>
        <div v-if="adminUserGzyData.seeData.ServiceTel">联系电话:{{adminUserGzyData.seeData.ServiceTel}}</div>
        <div v-if="adminUserGzyData.seeData.Content">走访情况:{{adminUserGzyData.seeData.Content}}</div>
        <div v-if="adminUserGzyData.seeData.CreateTime">上传时间:{{adminUserGzyData.seeData.CreateTime}}</div>
      </div>
      <div slot="footer">
        <template v-if="adminUserGzyData.seeData.IsCheck==0">
          <div style="padding:3px;"></div>
          <!-- /审核失败/未审核 -->
          <Button type="success" @click="toExamineGzy(1,adminUserGzyData.seeData.GreffierFileId)">通过</Button>
          <span style="padding:5px;"></span>
          <Button type="warning" @click="toRejectGzy(adminUserGzyData.seeData.GreffierFileId)">驳回</Button>
        </template>
        <Button @click="cancel">关闭</Button>
      </div>
    </Modal>
    <Modal v-model="adminUserGzyData.modal6" title="驳回理由" @on-ok="toRejectDetermineGzy" ok-text="发送"  draggable
    >
      <Input v-model="adminUserGzyData.checkMsg" maxlength="300" show-word-limit type="textarea" placeholder="驳回理由" />
    </Modal>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "archives",
    // components: {HelloWorld,},
    data() {
      return {
        zIndex : 9,
        serviceDate: {
          identification: 'a',
          startTime: '',
          endTime: '',
        },
        // villageCountDate: {
        //   identification: 'a',
        //   startTime: '',
        //   endTime: '',
        // },
        lawyerCountDate: {
          identification: 'a',
          startTime: '',
          endTime: '',
        },
        FileExplain: '',
        OrderEvaluate: '',
        OrderGrade: '',
        value1: 0,
        value2: ['0', '1', '2'],
        addressData: [{}],
        fileurl: '',
        fileName: '',
        documentUrl: '',
        lawyerId: '',
        modal3: false,
        modal1: false,
        modal2: false,
        modal4: false,
        modal6: false,
        modal5: '0',
        model8: '',
        model9: '',
        model10: '',
        tipsWord: '请选择区域',
        cityList: [],
        villagesList: [],
        ElectronicsList: [],
        areaData: null,
        labelInValue: true,
        fileId: '',
        checkMsg: '',
        listData: [],
        arCode: false, //二维码
        current: '',
        lawyerPhone: '',
        lawyerName: '',
        information: [],
        lawyerTable: {
          col: [],
          data: [],
        },
        vallageTable: {
          col: [],
          data: [],
        },
        page: 1,
        pageSize: 20,
        count: 0,
        page1: 1,
        pageSize1: 5,
        count1: 0,
        adminLogin: '',
        adminPassword: '',
        organizationPhone: '',
        organizationLinkman: '',
        organizationName: '',
        EvaluationCount: '',
        statisticsData: [
          { name: '服务社区（村）数量', value: '' },
          { name: '服务用户数量', value: '' },
          { name: '律师数量', value: '' },
          { name: '咨询消息数量', value: '' }
        ],
        timelist: [
          { name: '本月', value: 0 },
          { name: '本季', value: 1 },
          { name: '本年', value: 2 },
          { name: '总计', value: 3 },
        ],
        activeListData: [],
        tupians: [],
        typeList: [],
        fileType: '',
        model1: '',
        model2: '',
        model140: '',
        model3: '',
        getfileData: [],
        lawyerData: [],
        lawyerNameData: '',
        AreaId: '',
        ServiceTime: '',
        ServiceLong: '',
        ServiceMins: '',
        ServiceCount: '',
        ServiceTel: '',
        EvaluationTypePid: '',
        EvaluationTitle: '',
        ServiceLinkman: '',
        DailyType: '',
        VillagesName: [],
        imgPanel: {

        },
        evaluationData: [],
        evaluation: [],
        evaluationTypeId: '',
        showExport : false,
        timeData: '',
        transfer: true,
        startTime: '',//开始时间
        endTime: '',
        exportLawyerData: '',
        checkAllGroup: [],
        indeterminate: true,
        checkAll: false,
        checkAllGroupPage : [],
        archivesDetails : [],
        cq : '',
        stateCityList : [
          {
            value: '0',
            label: '未审核'
          },
          {
            value: '1',
            label: '审核通过'
          },
          {
            value: '2',
            label: '已驳回'
          },
        ],
        tabsType : '1',
      //  明白人
        adminUserMbrData : {
          List : [],
          page: 1,
          pageSize: 15,
          start_time: '',
          end_time: '',
          VillagesId: '' ,
          userKey : '',
          count : 0,
          InsiderFileId : '',
          checkMsg : '',
          FileImg : '',
          options2 : [],
          documentUrl : [],
          seeData : [],
          value1 : 0,
          modal : false,
          modal6: false,
        },
      //  公证员
        adminUserGzyData : {
          List : [],
          page: 1,
          pageSize: 15,
          start_time: '',
          end_time: '',
          VillagesId: '' ,
          userKey : '',
          count : 0,
          InsiderFileId : '',
          checkMsg : '',
          FileImg : '',
          options2 : [],
          documentUrl : [],
          seeData : [],
          value1 : 0,
          modal : false,
          modal6: false,
        },
        loading2: false,
        options2: [],
        url : '',
        domainName : '',
        serviceDuration : '',
        eleFileViewData : [],
        serviceSerData : {
          list : [],
          show : '',
          id : '',
        },
        monthAssessment: {},
        fileTotalMonthData :0,
      }
    },
    watch: {
      // 'villageCountDate.identification': function (val) {
      //   if (val != 'custom') {
      //     this.getVallageTable()
      //   }
      // },
      'lawyerCountDate.identification': function (val) {
        if (val != 'custom') {
          this.getLawyerTableData()
        }
      },
      'serviceDate.identification': function (val) {
        if (val != 'custom') {
          this.getAdminCount()
        }
      },
    },
    methods: {
      remoteMethod2(query) {
        console.log(query)
        var _this = this
        _this.send({
          url: '/villagesList',
          data: {
            AdminId: _this.information.AdminId,
            Key: query,
          },
          success: function (res) {
            console.log(res)
            _this.options2 = res.list
          }
        })
      },
      stateChoice :function (e){
        this.IsCheck = e
        this.onLawyerName()
      },
      handleCheckAll : function(){
        var _this = this
        var checkAllGroup = []//全选
        var back = []//反选
        console.log(_this.checkAllGroup)
        if(_this.checkAllGroup.length == 0){
          //全选
          for(var i in _this.ElectronicsList){
            checkAllGroup.push(_this.ElectronicsList[i].FileId+','+_this.ElectronicsList[i].IsCheck)
          }
          _this.checkAllGroup = checkAllGroup
          _this.checkAllGroupPage[_this.page-1] = checkAllGroup
          _this.indeterminate = true
        }
        // _this.checkAllGroup.length < _this.ElectronicsList.length && _this.checkAllGroup.length != 0
        else{
          //反选
          for(var i in _this.ElectronicsList){
            checkAllGroup.push(_this.ElectronicsList[i].FileId+','+_this.ElectronicsList[i].IsCheck)
          }
          // 首先构造Object
          const idSet = _this.checkAllGroup.reduce((acc, v) => {
            acc[v] = true;
            return acc;
          }, {});
          // 遍历list2，去掉在idSet中存在的id
          const result = checkAllGroup.filter(v => !idSet[v]);
          _this.checkAllGroup = result
          _this.checkAllGroupPage[_this.page-1] = result
        }
        console.log(_this.checkAllGroup)
        console.log(_this.checkAllGroupPage)
      },
      checkAllGroupChange : function(data){
        var _this = this
        _this.checkAllGroup = data
        _this.checkAllGroupPage[_this.page-1] = data
        console.log(_this.checkAllGroup)
      },
      onCancel : function (){
        var _this = this
        _this.endTime = ''
        _this.startTime = ''
        _this.exportLawyerData = ''
        _this.timeData = ''
        _this.model3 = ''
      },
      submit : function (ClockInId,type){
        var _this = this
        var clockArr = []
        if(ClockInId == '选择'){
          if(_this.checkAllGroupPage != ''){
            for(var i in _this.checkAllGroupPage){
              for(var index in _this.checkAllGroupPage[i]){
                clockArr.push(_this.checkAllGroupPage[i][index].split(',')[0])
              }
            }
          }else{
            this.$Message.info('请选择后导出');
            return
          }
        }
        console.log(_this.startTime,_this.endTime,_this.exportLawyerData)
        if(SYSCONFIG.location == 'chong-qing'){
          var url = '/FileAdminListWord'
        }else{
          var url = '/CD_FileAdminListWord'
        }
        _this.send({
          url: url,
          data: {
            // start_time: _this.startTime,
            // end_time: _this.endTime,
            // key: _this.exportLawyerData,
            // AdminId: _this.information.AdminId,

            StartTime: _this.startTime,
            EndTime: _this.endTime,
            DateType: 'custom',
            AreaId: _this.AreaId,
            AdminId: _this.information.AdminId,
            FileTypeId: _this.fileType,
            EvaluationTypeId: _this.evaluationTypeId,
            LawyerId: _this.exportLawyerData,
            FileArr : clockArr.toString() || ClockInId
          },
          success: function (res) {
            if(res != ''){
              if(type == 1){
                if(SYSCONFIG.location == 'chong-qing'){
                  _this.showPdf(window.sessionStorage.url+res)
                }else{
                  if(res.substring(res.lastIndexOf('.')+1) == 'zip'){
                    window.open(window.sessionStorage.url+res);
                  }else{
                    window.open('https://view.officeapps.live.com/op/view.aspx?src=' + window.sessionStorage.url+res)
                  }
                }
              }else{
                window.open(window.sessionStorage.url+res);
              }
            }
            _this.endTime = ''
            _this.startTime = ''
            _this.exportLawyerData = ''
            _this.timeData = ''
            _this.model3 = ''
            _this.checkAllGroupPage = []
            _this.checkAllGroup = []
            _this.endTime = ''
            _this.LawyerName = ''
          }
        })
      },
      showPdf : function (e){
        var _this = this
        if(SYSCONFIG.location == 'chong-qing'){
          var url = '/ShowPdf'
        }else{
          var url = '/CD_ShowPdf'
        }
        _this.send({
          url: url,
          data: {
            FilePath: e,
          },
          success: function (res) {
            window.open(window.sessionStorage.url+res)
          }
        })
      },
      totalTime: function (e) {
        var _this = this
        _this.startTime = e[0],//开始时间
        _this.endTime = e[1]//结束时间
      },
      clockDownload : function (){
          this.showExport = !this.showExport
      },
      setTime: function (val, val1, val2) {
        console.log(val, val1, val2)
      },
      clickCell: function (item, col, index, event) {
        var _this = this
        var checkType = 0
        var key = col.key.split('-')[0] || ''
        var colId = col.key.split('-')[1] || ''
        console.log(item)
        key == 'check' ? checkType = 1 : key == 'uncheck' ? checkType = 0 : checkType = ''
        if (item.VillagesId) {
          _this.send({
            url: '/VillagesFileList',
            data: {
              VillagesId: item.VillagesId,
              FileTypeId: colId,
              IsCheck: checkType,
              EndTime: '',
              StartTime: '',
            },
            success: function (res) {
              console.log(res)
            }
          })
        } else if (item.LawyerId) {
          _this.send({
            url: '/LawyerFileList',
            data: {
              LawyerId: item.LawyerId,
              FileTypeId: colId,
              IsCheck: checkType,
              EndTime: '',
              StartTime: '',
            },
            success: function (res) {
              console.log(res)
            }
          })
        }
      },
      getLawyerTableData: function () {
        var _this = this
        _this.vallageTable.data = []
        _this.send({
          url: '/FileLawyerGrouping',
          data: {
            AdminId: _this.information.AdminId,
            StartTime: _this.lawyerCountDate.startTime,
            EndTime: _this.lawyerCountDate.endTime,
            DateType: _this.lawyerCountDate.identification,
          },
          success: function (res) {
            var firstIndex
            var list = []
            for (var index in res.list) {
              var tempObj = res.list[index]
              if (!firstIndex) {
                firstIndex = index
                _this.lawyerTable.col = []
                _this.lawyerTable.col.push({ title: '姓名', key: 'LawyerName', sortable: true, minWidth: 100 })
                _this.lawyerTable.col.push({
                  title: '服务时长',
                  key: 'OnlineTime',
                  sortable: true, minWidth: 80,
                  render: (h, params) => {
                    return h('span', [
                      h('div', { style: { 'color': 'red' } }, parseInt(parseInt(params.row.OnlineTime) / 60) + '小时'),
                      h('div', { style: { 'color': 'red' } }, parseInt(params.row.OnlineTime) % 60 + '分钟'),
                    ])
                  }
                })
                for (let i in res.list[firstIndex].Count) {
                  _this.lawyerTable.col.push({
                    title: res.list[firstIndex].Count[i].FileTypeName,
                    children: [
                      { title: '已审核', key: 'check-' + i, sortable: true, minWidth: 60 },
                      { title: '未审核', key: 'uncheck-' + i, sortable: true, minWidth: 60 },
                      {
                        title: '合计',
                        key: 'add-' + i,
                        sortable: true,
                        minWidth: 60,
                        render: (h, params) => {
                          return h('span', { style: { 'color': 'red' } }, params.row['add-' + i])
                        }
                      },
                    ]
                  })
                }
                // _this.lawyerTable.col.push({
                //   title: '服务时长(时)',
                //   children:[
                //     {title:'已审核',key: 'service-'+i,sortable: true,minWidth:60},
                //     {title:'未审核',key: 'unservice-'+i,sortable: true,minWidth:60},
                //     {title:'合计',key: 'addservice-'+i,sortable: true,minWidth:60},
                //   ],
                // })

              }
              for (var index1 in res.list[index].Count) {
                tempObj['check-' + index1] = res.list[index].Count[index1].Count
                tempObj['uncheck-' + index1] = res.list[index].UnCheck[index1].Count
                tempObj['add-' + index1] = parseInt(res.list[index].Count[index1].Count) + parseInt(res.list[index].UnCheck[index1].Count)
              }
              // tempObj['service-'+index1] = res.list[index].ServiceLong[1]
              // tempObj['unservice-'+index1] = res.list[index].ServiceLong[0]
              // tempObj['addservice-'+index1] = parseInt(res.list[index].ServiceLong[1])+parseInt(res.list[index].ServiceLong[0])
              delete tempObj.UnCheck
              delete tempObj.Count
              list.push(tempObj)
            }
            // console.log(list)
            _this.lawyerTable.data = list
          }
        })
      },
      // getVallageTable: function () {
      //   var _this = this
      //   _this.vallageTable.data = []
      //   _this.send({
      //     url: '/FileVillagesGrouping',
      //     data: {
      //       AdminId: _this.information.AdminId,
      //       StartTime: _this.villageCountDate.startTime,
      //       EndTime: _this.villageCountDate.endTime,
      //       DateType: _this.villageCountDate.identification,
      //     },
      //     success: function (res) {
      //       var firstIndex
      //       var list = []
      //       for (var index in res.list) {
      //         var tempObj = res.list[index]
      //         if (!firstIndex) {
      //           firstIndex = index
      //           _this.vallageTable.col = []
      //           _this.vallageTable.col.push({ title: '名称', key: 'VillagesName', sortable: true, minWidth: 100 })
      //           for (var i in res.list[firstIndex].Count) {
      //             _this.vallageTable.col.push({
      //               title: res.list[firstIndex].Count[i].FileTypeName,
      //               children: [
      //                 { title: '已审核', key: 'check-' + i, sortable: true, minWidth: 60 },
      //                 { title: '未审核', key: 'uncheck-' + i, sortable: true, minWidth: 60 },
      //                 {
      //                   title: '合计',
      //                   key: 'add-' + i,
      //                   sortable: true,
      //                   minWidth: 60,
      //                   render: (h, params) => {
      //                     return h('span', { style: { 'color': 'red' } }, params.row['add-' + i])
      //                   }
      //                 },
      //               ]
      //             })
      //           }
      //         }
      //         for (var index1 in res.list[index].Count) {
      //           tempObj['check-' + index1] = res.list[index].Count[index1].Count
      //           tempObj['uncheck-' + index1] = res.list[index].UnCheck[index1].Count
      //           tempObj['add-' + index1] = parseInt(res.list[index].Count[index1].Count) + parseInt(res.list[index].UnCheck[index1].Count)
      //         }
      //         delete tempObj.UnCheck
      //         delete tempObj.Count
      //         list.push(tempObj)
      //       }
      //       // console.log(list)
      //       _this.vallageTable.data = list
      //     }
      //   })
      // },
      exportLawyerName : function (value){
        var _this = this
        _this.exportLawyerData = value
      },
      onLawyerName: function (value) {
        var _this = this
        _this.lawyerNameData = value
        _this.page = 1
        _this.count = 0
        _this.ElectronicsList = []
        console.log(_this.Count)
        if(_this.cityList != ''){var AdminFind = _this.AreaId}else{var AdminFind = ''}
        if(_this.villagesList != ''){var VillagesId = _this.AreaId}else{var VillagesId = _this.VillagesId}
        _this.send({
          consulting: true,
          url: _this.url,
          data: {
            StartTime: _this.serviceDate.startTime,
            EndTime: _this.serviceDate.endTime,
            DateType: _this.serviceDate.identification,
            // AreaId: _this.AreaId,
            AdminFind: AdminFind,
            VillagesId: VillagesId,
            AdminId: _this.information.AdminId,
            FileTypeId: _this.fileType,
            EvaluationTypeId: _this.evaluationTypeId,
            LawyerId: _this.lawyerNameData,
            page: _this.page,
            pageSize: _this.pageSize,
            IsCheck : _this.IsCheck,
            ServiceTypeId : _this.serviceSerData.id
          },
          success: function (res) {
            console.log(res)
            if (res) {
              var ElectronicsList = []
              _this.ElectronicsList = res.List
              _this.count = res.Count
            } else {
              _this.ElectronicsList = []
              _this.count = 0
            }
          }
        })
      },
      popularizingLaw: function (value) {
        var _this = this
        console.log(value)
        _this.fileType = value
        _this.page = 1
        _this.count = 0
        _this.ElectronicsList = []
        if(_this.cityList != ''){var AdminFind = _this.AreaId}else{var AdminFind = ''}
        if(_this.villagesList != ''){var VillagesId = _this.AreaId}else{var VillagesId = _this.VillagesId}
        _this.send({
          consulting: true,
          url: _this.url,
          data: {
            StartTime: _this.serviceDate.startTime,
            EndTime: _this.serviceDate.endTime,
            DateType: _this.serviceDate.identification,
            // AreaId: _this.AreaId,
            AdminFind: AdminFind,
            VillagesId: VillagesId,
            AdminId: _this.information.AdminId,
            FileTypeId: _this.fileType,
            EvaluationTypeId: _this.evaluationTypeId,
            LawyerId: _this.lawyerNameData,
            page: _this.page,
            pageSize: _this.pageSize,
            IsCheck : _this.IsCheck,
            ServiceTypeId : _this.serviceSerData.id
          },
          success: function (res) {
            console.log(res)
            if (res) {
              var ElectronicsList = []
              _this.ElectronicsList = res.List
              _this.count = res.Count
            } else {
              _this.ElectronicsList = []
              _this.count = 0
            }
          }
        })
      },
      getTypeList: function () {
        var _this = this
        var data = []
        _this.send({
          url: '/fileListAdmin',
          success: function (res) {
            _this.typeList = res
            // console.log(res)
          }
        })
        _this.send({
          url: '/EvaluationTypeList',
          data : {AdminId: _this.information.AdminId,},
          success: function (res) {
            for (var i in res) {
              data.push(res[i])
            }
            convertTree(data)
            function convertTree(tree) {
              const result = []
              tree.forEach((item) => {
                // 解构赋值
                let {
                  EvaluationTypeId: value,
                  EvaluationTitle: label,
                  Unit : Unit,
                  list: children
                } = item
                if (children) {
                  children = convertTree(children)
                }else {
                  children = []
                }
                result.push({
                  value,
                  label,
                  Unit,
                  children
                })
              })
              _this.evaluationData = result
              return result
            }
            console.log(_this.evaluationData)
          }
        })
      },
      addressSelect: function (value, selectedData) {
        var _this = this
        _this.page = 1
        if(selectedData != ''){
          if(selectedData[0].value == 0){
            _this.evaluationTypeId = 0
          }else{
            _this.evaluationTypeId = selectedData[1].value
          }
        }else{
          _this.evaluationTypeId = ''
        }
        // if (value) {
        //   _this.evaluationTypeId = value
        // } else {
        //   _this.evaluationTypeId = ''
        // }

        _this.fileAdminList()
      },
      enlarge: function (e) {
        this.tupians = this.fileurl
        console.log(this.fileurl)
      },
      addMember: function () {
        var _this = this
        _this.send({
          url: '/AddMember',
          data: {
            Level: _this.information.adminLevel,
            AdminId: _this.information.AdminId,
            AdminLogin: _this.adminLogin,
            AdminPassword: _this.adminPassword,
            OrganizationLinkman: _this.organizationLinkman,
            OrganizationPhone: _this.organizationPhone,
            OrganizationName: _this.organizationName,

          },
          success: function (res) {
            _this.adminList()
          }
        })
      },

      cancel() {
        this.fileurl = ''
        this.fileName = ''
        this.documentUrl = ''
        this.modal3 = false
        this.adminUserMbrData.seeData = []
        this.adminUserMbrData.modal = false
        this.adminUserGzyData.seeData = []
        this.adminUserGzyData.modal = false
      },
      enter: function (index) {
        console.log(index)
        this.arCode = true
        this.current = index
      },
      leave() {
        this.arCode = false
        this.current = null
      },
      //列表审核
      activeList: function () {
        var _this = this
        _this.activeListData = []
        _this.count1 = ''
        _this.send({
          url: '/ActiveList',
          data: {
            OrganizationId: _this.information.OrganizationId,
            page: _this.page1,
            pageSize: _this.pageSize1,
          },
          success: function (res) {
            _this.activeListData = res.List
            _this.count1 = res.count
            // console.log(_this.activeListData)
          }
        })
      },
      toRejectDetermine: function () {
        var _this = this
        if (_this.checkMsg) {
          _this.send({
            url: '/CheckActive',
            data: {
              IsCheck: 2,
              FileId: _this.fileId,
              CheckMsg: _this.checkMsg,
              AdminId: _this.information.AdminId,
            },
            success: function (res) {
              _this.fileAdminList()
            }
          })
        } else {
          _this.$Message.error('驳回理由不能为空');
        }

      },
      toReject: function (fileId) {
        var _this = this
        _this.fileId = fileId
        _this.modal6 = true
      },
      submitCheckActive : function (){
        var _this = this
        var clockArr = []
        for(var i in _this.checkAllGroupPage){
          for(var index in _this.checkAllGroupPage[i]){
            if(_this.checkAllGroupPage[i][index].split(',')[1] != 2){
              clockArr.push(_this.checkAllGroupPage[i][index].split(',')[0])
            }
          }
        }
        console.log(clockArr)
        var _this = this
        if(clockArr != ''){
          _this.send({
            url: '/CheckActive',
            confirm: true,
            data: {
              IsCheck: 1,
              FileId: clockArr.toString(),
              AdminId: _this.information.AdminId,
            },
            success: function (res) {
              _this.fileAdminList()
              _this.checkAllGroupPage = []
              _this.checkAllGroup = []
            }
          })
        }else{
          this.$Message.warning('请选择未审核状态');
        }
      },
      toExamine: function (isCheck, fileId) {
        var _this = this
        _this.send({
          url: '/CheckActive',
          confirm: true,
          data: {
            IsCheck: isCheck,
            FileId: fileId,
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            _this.fileAdminList()
          }
        })
      },
      getFileName (name) {
        return name.substring(name.lastIndexOf("/"))
      },
      see: function (FileStatsId) {
        var _this = this
        _this.send({
          url: '/eleFileView',
          data: {
            FileStatsId: FileStatsId,
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            try{
              res.DocumentUrl =  JSON.parse(res.DocumentUrl)
            }catch(e){
              if(res.DocumentUrl != ''){
                res.DocumentUrl =  [{name:_this.getFileName(res.DocumentUrl),url:res.DocumentUrl}]
              }
            }
            if(res.FileUrl){
              res.FileUrl = res.FileUrl.split(",")
            }
            _this.eleFileViewData = res
            console.log(_this.eleFileViewData)
          }
        })
      },
      //type 1查看 2下载
      seeFile: function (url,type) {
        var _this = this
        var fileType = url.substr(url.lastIndexOf(".") + 1, 4).toLowerCase()
        if (fileType == "txt") {
          axios({
            method: 'get',
            responseType: 'blob',
            url: url,
            transformResponse: [function (data) {
              var render = new FileReader()
              render.readAsText(data, 'UTF8')
              render.onload = function () {
                if (render.result.indexOf('�') != -1) {
                  var renderGBK = new FileReader()
                  renderGBK.readAsText(data, 'GBK')
                  renderGBK.onload = function () {
                    var newWindow = window.open('about:blank', '', 'width=400,height=400')
                    newWindow.document.write(renderGBK.result)
                  }
                } else {
                  var newWindow = window.open('about:blank', '', 'width=400,height=400')
                  newWindow.document.write(render.result)
                }
              }
            }]
          })
        }
        // else if (fileType == "doc" || fileType == "doc") {
        //   _this.send({
        //     url: "/AdminFileContent",
        //     data: {
        //       FileId: _this.fileId,
        //     },
        //     success: res => {
        //       window.open((SYSCONFIG.server.default.port == 443 ? 'https://' : 'http://') + SYSCONFIG.server.default.host + res.DocumentUrl)
        //       // window.location.href = res
        //     }
        //   })
        // }
        else {
          if(fileType == 'pdf'){
            window.open(url)
          }else{
            if(SYSCONFIG.location == 'chong-qing'){
              if(type == 2){
                window.open(url)
              }else{
                _this.showPdf(url)
              }
            }else{
              window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
            }
          }
        }
      },
      getImgArrayBuffer(url) {
        let _this = this;
        return new Promise((resolve, reject) => {
          //通过请求获取文件blob格式
          let xmlhttp = new XMLHttpRequest();
          xmlhttp.open("GET", url, true);
          xmlhttp.responseType = "blob";
          console.log(xmlhttp)
          xmlhttp.onload = function () {
            if (this.status == 200) {
              resolve(this.response);
            } else {
              reject(this.status);
            }
          }
          xmlhttp.send();
        });

      },
      //    地区
      getAreaData: function () {
        var _this = this
        _this.send({
          url: '/FindBottomVillagesAndAdmin_',
          data: {
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            //
            if(res.Admin != ''){
              _this.cityList = res.Admin
            }
            if(res.Villages != ''){
              _this.villagesList = res.Villages
            }

            _this.send({
              url: '/GetfileDay',
              data: {
                AreaId: _this.AreaId,
                AdminId: _this.information.AdminId,
              },
              success: function (res) {
                console.log(res)
                _this.getfileData = res

                _this.page = 1
              }
            })
            //
            // convertTree(res[1])
            // function convertTree(tree) {
            //     const result = []
            //     tree.forEach((item) => {
            //         // 解构赋值
            //         let {
            //             AreaId: value,
            //             AreaName: label,
            //             children: children
            //         } = item
            //         if (children) {
            //             children = convertTree(children)
            //         }
            //         result.push({
            //             value,
            //             label,
            //             children
            //         })
            //     })
            //     _this.addressData = result
            //     return result
            // }
            // console.log(_this.addressData)
          }
        })
      },
      choice: function (value) {
        var _this = this
        _this.page = 1
        console.log(value)
        if (value) {
          var data = value.value.split(',')
        } else {
          var data = ['0']
        }
        _this.AreaId = data[0]
        if (data[2] == '1') {
          _this.areaData = _this.cityList[data[1]].children
        } else {

        }
        if(_this.cityList != ''){var AdminFind = data[0]}else{
          var AdminFind = ''
          if(_this.villagesList != ''){var VillagesId = data[0]}else{var VillagesId = _this.VillagesId}
        }

        _this.send({
          consulting: true,
          url: _this.url,
          data: {
            StartTime: _this.serviceDate.startTime,
            EndTime: _this.serviceDate.endTime,
            DateType: _this.serviceDate.identification,
            // AreaId: data[0],
            AdminFind: AdminFind,
            VillagesId: VillagesId,
            AdminId: _this.information.AdminId,
            FileTypeId: _this.fileType,
            EvaluationTypeId: _this.evaluationTypeId,
            LawyerId: _this.lawyerNameData,
            page: _this.page,
            pageSize: _this.pageSize,
            IsCheck : _this.IsCheck,
            ServiceTypeId : _this.serviceSerData.id
          },
          success: function (res) {
            console.log(res)
            _this.model140 = ''
            if (res) {
              _this.ElectronicsList = res.List
              _this.count = res.Count
            } else {
              _this.ElectronicsList = []
              _this.count = 0
            }

          }
        })
      },
      choice1: function (value) {
        var _this = this
        var data = value.value.split(',')
        console.log(data[0])
      },
      screenData: function (value, selectedData) {
        var _this = this
        if(_this.cityList != ''){var AdminFind = selectedData[1].value}else{var AdminFind = ''}
        if(_this.villagesList != ''){var VillagesId = selectedData[1].value}else{var VillagesId = _this.VillagesId}
        _this.send({
          consulting: true,
          url: _this.url,
          data: {
            StartTime: _this.serviceDate.startTime,
            EndTime: _this.serviceDate.endTime,
            DateType: _this.serviceDate.identification,
            // AreaId: selectedData[1].value,
            AdminFind: AdminFind,
            VillagesId: VillagesId,
            AdminId: _this.information.AdminId,
            FileTypeId: _this.fileType,
            EvaluationTypeId: _this.evaluationTypeId,
            LawyerId: _this.lawyerNameData,
            page: _this.page,
            pageSize: _this.pageSize,
            IsCheck : _this.IsCheck,
            ServiceTypeId : _this.serviceSerData.id
          },
          success: function (res) {
            // console.log(res)
          }
        })
      },
      deleteClick: function (FileId) {
        var _this = this
        _this.send({
          confirm: true,
          url: '/AdminActiveDelete',
          data: {
            AdminId: _this.information.AdminId,
            FileId: FileId,
          },
          success: function (res) {
            _this.fileAdminList()
          }
        })
      },

      lawyerList: function () {
        var _this = this
        _this.send({
          url: '/LawyerList',
          data: {
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            _this.lawyerData = res.list
            // console.log(res)

          }
        })
      },
      fileAdminList: function (page) {
        var _this = this
        if(_this.serviceDate.identification == 'custom'){
          if(_this.serviceDate.endTime == '' || _this.serviceDate.startTime == ''){
            return
          }
        }
        if (page) { _this.page = page }
        _this.ElectronicsList = []
        console.log(_this.page,_this.AreaId)
        if(_this.cityList != ''){var AdminFind = _this.AreaId}else{var AdminFind = ''}
        if(_this.villagesList != ''){var VillagesId = _this.VillagesId }else{var VillagesId = _this.VillagesId }
        _this.send({
          consulting: true,
          url: _this.url,
          data: {
            StartTime: _this.serviceDate.startTime,
            EndTime: _this.serviceDate.endTime,
            DateType: _this.serviceDate.identification,
            // AreaId: _this.AreaId,
            AdminFind: AdminFind,
            VillagesId: VillagesId,
            AdminId: _this.information.AdminId,
            FileTypeId: _this.fileType,
            EvaluationTypeId: _this.evaluationTypeId,
            LawyerId: _this.lawyerNameData,
            page: _this.page,
            pageSize: _this.pageSize,
            IsCheck : _this.IsCheck,
            ServiceTypeId : _this.serviceSerData.id
          },
          success: function (res) {
            // console.log(res)
            if (res) {
              var ElectronicsList = []
              _this.ElectronicsList = res.List
              _this.count = res.Count
              _this.checkAllGroup = _this.checkAllGroupPage[_this.page-1] || []
            } else {
              _this.ElectronicsList = []
              _this.count = 0
            }
          }
        })
      },
      switchWalkingLantern : function (index){
        this.value1 = index
      },
      switchClick : function (e){
        var _this = this
        _this.tabsType = e
        if(e == '1'){}
        if(e == '2'){_this.AGWorkFileListGzy()}
        if(e == '3'){_this.aQuestionnaireList()}
      },
    //  明白人日志列表
      userSearch : function (e){
        this.adminUserMbrData.userKey = e
        this.aQuestionnaireList()
      },
      aQuestionnaireList:function (){
        var _this = this
        _this.send({
          url: '/AWorkFileList',
          data: {
            AdminId: _this.information.AdminId,
            typeId: 4,
            page: _this.adminUserMbrData.page,
            pageSize: _this.adminUserMbrData.pageSize,
            start_time: _this.adminUserMbrData.startTime,//开始时间
            end_time: _this.adminUserMbrData.endTime,
            VillagesId: _this.adminUserMbrData.VillagesId,
            key : _this.adminUserMbrData.userKey,
          },
          success: function (res) {
            _this.adminUserMbrData.List = res.List
            _this.adminUserMbrData.count = res.Count
          }
        })
      },
      totalTime3: function (e) {
        var _this = this
        _this.adminUserMbrData.startTime = e[0],//开始时间
            _this.adminUserMbrData.endTime = e[1]//结束时间
        _this.adminUserMbrData.page = 1
        _this.aQuestionnaireList(2)
      },
      lawyerClick3: function (e) {
        var _this = this
        if (e == undefined) {
          _this.VillagesId = ''
          _this.options2 = []
        } else {
          _this.VillagesId = e
        }
        _this.page = 1
        _this.model8 = ''
        this.fileAdminList()
        console.log(e)
        // if (e == undefined) {
        //   _this.adminUserMbrData.VillagesId = ''
        //   _this.adminUserMbrData.options2 = []
        // } else {
        //   _this.adminUserMbrData.VillagesId = e
        // }
        // _this.adminUserMbrData.page = 1
        // _this.aQuestionnaireList(2)
        // console.log(e)
      },
      seeMbr : function (e){
        console.log(e)
        if (e.FileImg) {
          this.adminUserMbrData.FileImg = e.FileImg.split(",")
          this.adminUserMbrData.modal = true
        }else{this.adminUserMbrData.FileImg = ''}
        if (e.DocumentUrl) {
          this.adminUserMbrData.documentUrl = e.DocumentUrl
          this.adminUserMbrData.modal = true
        }else{
          this.adminUserMbrData.documentUrl = []
        }
        this.adminUserMbrData.seeData = e
      },
      toRejectMbr: function (InsiderFileId) {
        var _this = this
        _this.adminUserMbrData.InsiderFileId = InsiderFileId
        _this.adminUserMbrData.modal6 = true
      },
      toRejectDetermineMbr: function () {
        var _this = this
        if (_this.adminUserMbrData.checkMsg) {
          _this.send({
            url: '/CheckActive',
            data: {
              IsCheck: 2,
              InsiderFileId: _this.adminUserMbrData.InsiderFileId,
              CheckMsg: _this.adminUserMbrData.checkMsg,
              AdminId: _this.information.AdminId,
            },
            success: function (res) {
              _this.adminUserMbrData.modal = false
              _this.aQuestionnaireList()
            }
          })
        } else {
          _this.$Message.error('驳回理由不能为空');
        }

      },
      toExamineMbr: function (isCheck, InsiderFileId) {
        var _this = this
        _this.send({
          url: '/ACheckWorkFile',
          confirm: true,
          data: {
            IsCheck: isCheck,
            InsiderFileId: InsiderFileId,
            AdminId: _this.information.AdminId,
            CheckMsg : '',
          },
          success: function (res) {
            _this.adminUserMbrData.modal = false
            _this.aQuestionnaireList()
          }
        })
      },
    //  公证员
      AGWorkFileListGzy:function (){
        var _this = this
        _this.send({
          url: '/AGWorkFileList',
          data: {
            AdminId: _this.information.AdminId,
            typeId: 4,
            page: _this.adminUserGzyData.page,
            pageSize: _this.adminUserGzyData.pageSize,
            start_time: _this.adminUserGzyData.startTime,//开始时间
            end_time: _this.adminUserGzyData.endTime,
            VillagesId: _this.adminUserGzyData.VillagesId,
            key : _this.adminUserGzyData.userKey,
          },
          success: function (res) {
            _this.adminUserGzyData.List = res.List
            _this.adminUserGzyData.count = res.Count
          }
        })
      },
      totalTimeGzy: function (e) {
        var _this = this
        _this.adminUserGzyData.startTime = e[0],//开始时间
            _this.adminUserGzyData.endTime = e[1]//结束时间
        _this.adminUserGzyData.page = 1
        _this.AGWorkFileListGzy(2)
      },
      lawyerClickGzy: function (e) {
        var _this = this
        if (e == undefined) {
          _this.adminUserGzyData.VillagesId = ''
          _this.adminUserGzyData.options2 = []
        } else {
          _this.adminUserGzyData.VillagesId = e
        }
        _this.adminUserGzyData.page = 1
        _this.AGWorkFileListGzy(2)
        console.log(e)
      },
      toRejectGzy: function (GreffierFileId) {
        var _this = this
        _this.adminUserGzyData.GreffierFileId = GreffierFileId
        _this.adminUserGzyData.modal6 = true
      },
      toRejectDetermineGzy: function () {
        var _this = this
        if (_this.adminUserGzyData.checkMsg) {
          _this.send({
            url: '/AGCheckWorkFile',
            data: {
              IsCheck: 2,
              GreffierFileId: _this.adminUserGzyData.GreffierFileId,
              CheckMsg: _this.adminUserGzyData.checkMsg,
              AdminId: _this.information.AdminId,
            },
            success: function (res) {
              _this.adminUserGzyData.modal = false
              _this.AGWorkFileListGzy()
            }
          })
        } else {
          _this.$Message.error('驳回理由不能为空');
        }
      },
      seeGzy : function (e){
        console.log(e)
        if (e.FileImg) {
          this.adminUserGzyData.FileImg = e.FileImg.split(",")
          this.adminUserGzyData.modal = true
        }else{this.adminUserGzyData.FileImg = ''}
        if (e.DocumentUrl) {
          this.adminUserGzyData.documentUrl = e.DocumentUrl
          this.adminUserGzyData.modal = true
        }else{
          this.adminUserGzyData.documentUrl = []
        }
        this.adminUserGzyData.seeData = e
      },
      toExamineGzy: function (isCheck, GreffierFileId) {
        var _this = this
        _this.send({
          url: '/AGCheckWorkFile',
          confirm: true,
          data: {
            IsCheck: isCheck,
            GreffierFileId: GreffierFileId,
            AdminId: _this.information.AdminId,
            CheckMsg : '',
          },
          success: function (res) {
            _this.adminUserGzyData.modal = false
            _this.AGWorkFileListGzy()
          }
        })
      },
      uploadDate : function (source,type){
          var date = new Date(1000 * source); //获取一个时间对象
          var Y = date.getFullYear() + '-';
          var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
          var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
          var H = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
          var B = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' ';
          if(type == 1){return Y + M + D + H + B}
          if(type == 2){return Y + M + D}
      },
      serviceSerType : function () {
        var _this = this
        _this.send({
          url: '/ServiceSerType',
          data: {},
          success: function (res) {
            _this.serviceSerData.list = res
          }
        })
      },
      serviceSerName : function (e) {
        var _this = this
        _this.serviceSerData.id = e
        _this.fileAdminList(1)
      },

      getMonthAssessment: function () {
        var _this = this
        _this.send({
          url: '/MouthClock',
          data: {
            AdminId: window.sessionStorage.AdminId
          },
          success: (res) => {
            _this.monthAssessment = res
          }
        })
        _this.send({
          url: '/fileTotalMonth',
          data: {
            AdminId: window.sessionStorage.AdminId
          },
          success: (res) => {
            _this.fileTotalMonthData = res
          }
        })
      },
    },

    mounted() {
      var _this = this
      _this.url = '/EleFileList'
      if (window.sessionStorage.adminLevel == undefined) {
        // this.$router.push({name:'login'})
      } else {
        // var data = JSON.parse(window.sessionStorage.information)
        // SYSCONFIG.server.information = JSON.parse(window.sessionStorage.information)
        // console.log(SYSCONFIG.server.information.AdminLogin)
        this.information = window.sessionStorage
        // this.getLawyerTableData()
        // this.getVallageTable()
        // _this.getAreaData()
        _this.getTypeList()
        _this.fileAdminList()
        _this.serviceSerType()
        _this.getMonthAssessment()
        _this.send({
          url: '/GetfileDay',
          data: {
            AreaId: _this.AreaId,
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            console.log(res)
            _this.getfileData = res
          }
        })
        _this.send({
          url: '/fileTotal',
          data: {
            AreaId: _this.AreaId,
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            console.log(res)
            _this.serviceDuration = res
          }
        })
      }

      _this.lawyerList()
      if(SYSCONFIG.location == 'chong-qing'){
          _this.cq = 'cq'
      }
      _this.domainName = window.sessionStorage.url
    },
  }
</script>
