<style scoped>
	.header{background-image: url(/static/customization/jian-yang/images/correction-banner.png);height:1.6rem;background-size:100% auto;background-repeat: no-repeat;}
	.menu{font-size:0.24rem;padding:0.3rem 0.3rem;}
	.list{padding:0.2rem 0;}
  .list-def-img{width:1.6rem;height:1.2rem;}
  .list-title{color:#333333;font-size:0.28rem;font-weight:bold;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<div class='header'></div>
		<div class='body'>
			<div class="menu">
				<Row>
          <Col span="12" style="font-size:0.3rem;font-weight: bold;">
            <Icon type="ios-list" style="font-size:0.5rem;vertical-align:middle;"/>课程列表
          </Col>
				</Row>
			</div>
			<ul v-if="list.length>0">
				<li v-for="(item,index) in list" :style="index%2==0?'background-color: #fff;':''" class='list'>
          <Row type="flex" align="middle" style="font-size:0.2rem;padding:0 0.3rem;">
            <Col span="6">
            <img class="list-def-img" src="/static/customization/jian-yang/images/course-list-default-img.png"/></Col>
            <Col span="18">
              <Row>
                <Col span="24" class="list-title">
                  {{item.CurriculumTitle}}
                  <span style="color: gray;font-size:0.14rem;font-weight: normal;"></span>
                </Col>
                <Col span="24">
                  <div>发布单位：{{item.AdminName}}</div>
                  <div>
                    学习状态：
                    <span style="color:#2d8cf0;" v-if="item.IsStudy==1">已学习</span>
                    <span v-else>未学习</span>
                    <span style="padding:0.1rem;">
                    </span>考试状态：
                    <span v-if="item.IsExam==0">未考试</span>
                    <template v-else>
                      <span style="color: green;" v-if="item.IsPassing==1">已通过</span>
                      <span style="color: red;" v-else>未通过</span>
                    </template>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider dashed style="margin:0.1rem;"/>
          <div style="text-align:center;">
            <Button size="small" type="success" ghost :to="{name:'CJYCD',query:{correctionId:item.CurriculumId}}">去学习</Button>
            <span style="padding:0 0.2rem"></span>
            <Button size="small" type="error" ghost :to="{name:'CJYCE',query:{correctionId:item.CurriculumId}}">去考试</Button>
            <span style="padding:0 0.2rem"></span>
            <Button size="small" type="primary" ghost :to="{name:'CJYCH',query:{correctionId:item.CurriculumId}}">考试记录</Button>
          </div>
				</li>
			</ul>
			<div v-else style="background-image:url(/img/empty.png);height:4.95rem;width:6.9rem;background-size:100% 100%;margin:1.1rem auto;"></div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'list',
		data(){
			return{
				list:[],
			}
		},
		mounted(){
			var _this = this
			var tempTime = setInterval(()=>{
				if(_this.$store.state.userInfo.AdminId){
					this.getList()
					clearInterval(tempTime)
				}
				console.log(1)
			},200)
		},
		methods:{
      getList:function(){
        var _this = this
        _this.send({
          url:'/UserCurriculumList',
          data:{
            UserId:_this.$store.state.userInfo.UserId,
            AdminId:_this.$store.state.userInfo.AdminId
          },
          success:function(res){
            _this.list = res
          }
        })
      },
		},
	}
</script>
