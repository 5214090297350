<style scoped>
.tableTitle{font-size:20px;}
.export-btn{font-size:10px;color:#2d8cf0;cursor:pointer;}
</style>

<template>
  <div>
    <div style="padding:10px"></div>
    <div style="text-align: center;">
      <span class="tableTitle">顾问总统计表</span>
      <div class="export-btn" @click="$emit('exportCsv',{dom:$refs.table,fileName:'顾问总统计表'})">[导出数据]</div>
    </div>
    <div style="padding:5px"></div>
    <Table height="500" border :loading="table.loading" :columns="table.columns" :data="table.data" :resizable="true" ref="table" show-summary :summary-method="handleSummary"></Table>
    <div style="padding:20px"></div>
  </div>
</template>

<script>
//表头控制文件
import * as tableColumns from "./table-columns"
import * as tableDataSorting from "./data-sorting"
export default {
  name:'advisoryPresidentTable',//分区域总统计表
  props:{
    dateSelecter:{type:Object,required:true,},
  },
  data:function(){
    return{
      table:{
        loading:true,
          columns:tableColumns.advisoryPresidentTable,
        data:[],
      },
    }
  },
  watch:{
    'dateSelecter.identification':function(val){
      if(val != 'custom'){
        this.getTableData()
      }
    }
  },
  mounted:function(){
    this.getTableData()
  },
  methods:{
    handleSummary ({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[key] = {
            key,
            value: v + ''
          };
        } else {
          sums[key] = {
            key,
            value: window.sessionStorage.AdminName
          };
        }
      });

      return sums;
    },
    getTableData:function(){
      var _this = this
      _this.table.loading = true
      _this.table.data = []
      _this.$parent.requestHub([
        "/ReportLOrderData", //累计咨询请求
        "/ReportLOrderDealData", //累计办理咨询请求
        "/ReportLVillageGovernanceData", //累计村居治理请求
        "/ReportLawyerTotalFileData", //累计电子档案
        "/ReportLawyerEvaluateData", //累计评价
    ])
          .then(res=>{
            _this.table.loading = false
            _this.table.data = tableDataSorting.dataMerge(res,
              {local:'lawyerId',remote:'LawyerId'},
              [
                {columnName:'lawyerName',dataIndex:0,remoteVariable:['LawyerName']},
                {columnName:'newReportLOrder',dataIndex:0,remoteVariable:['Count','Intime']},
                {columnName:'totalReportLOrder',dataIndex:0,remoteVariable:['Count','Total']},
                {columnName:'newReportLOrderDeal',dataIndex:1,remoteVariable:['Count','Intime']},
                {columnName:'totalReportLOrderDeal',dataIndex:1,remoteVariable:['Count','Total']},
                {columnName:'newReportLVillageGovernance',dataIndex:2,remoteVariable:['Count','Intime']},
                {columnName:'totalReportLVillageGovernance',dataIndex:2,remoteVariable:['Count','Total']},
                {columnName:'newReportLawyerTotalFile',dataIndex:3,remoteVariable:['Count','Intime']},
                {columnName:'totalReportLawyerTotalFile',dataIndex:3,remoteVariable:['Count','Total']},
                {columnName:'newReportLawyerEvaluate',dataIndex:4,remoteVariable:['Count','Intime']},
                {columnName:'totalReportLawyerEvaluate',dataIndex:4,remoteVariable:['Count','Total']},
              ]
            )
          })
          .catch(err=>{console.log("区域模块访问统计表：失败！")})
    },
  },
}
</script>
