<style scoped>
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
  .details p{color: #000000}
  .details span{color: #5c6b77}
</style>

<template>
  <div>
    <div style="padding:15px"></div>
    <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px">
      社区矫正 - 考试列表
    </div>
    <div style="padding:15px"></div>
    <Row style="background-color: #FFF;" type="flex" align="middle">
      <Col span="20">
        <Menu mode="horizontal" theme="light"  :active-name="isCorrection" @on-select="isCorrection = $event,adminflyzApplyList()">
          <MenuItem :name="1">
            <Icon type="ios-people" />
            矫正人员
          </MenuItem>
          <MenuItem :name="0">
            <Icon type="ios-people" />
            非矫正人员
          </MenuItem>
        </Menu>
      </Col>
      <Col span="4">
        <Button type="success" @click="exportExcel">导出EXCEL</Button>
      </Col>
    </Row>
    <div class="publicWelfare">
      <table class="formtable">
        <thead>
          <tr>
            <th>姓名</th>
            <th>昵称</th>
            <th>考试时间</th>
            <th>分数</th>
            <th>所属社区</th>
            <th>状态</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value,index) in legalAidData">
            <td>{{value.RealName}}</td>
            <td>{{value.UserName}}</td>
            <td>{{value.CreatedTime}}</td>
            <td>{{value.UserScore}}</td>
            <td>{{value.VillagesName}}</td>
            <td>{{value.IsChecking==0?'未阅卷':'已阅卷'}}</td>
            <td>
              <Button type="primary" :to="{name:'correctionE',query:{examId:value.UserExamId}}">查看/打分</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="text-align: left;margin-left: 40px;font-size: 16px;padding-bottom: 20px">
      <!-- <Page
                    :total="count"
                    show-total
                    :page-size="pageSize"
                    show-elevator
                    @on-change="page = $event;adminflyzApplyList()"
                  /> -->
    </div>
  </div>
</template>

<script>
  import XLSX from 'xlsx'
  export default {
      name: "mediateList",
      data () {
          return {
              information : [],
              legalAidData : [],
              count : '0',
              isCorrection:1,
              pageSize : 10,
              page : 1,
              modal1 : false,
              detailsData : [],
              sfclmc : ''
          }
      },
      methods : {
        exportExcel:function(){
          var table = []
          table.push({
            A:'姓名',
            B:'昵称',
            C:'考试时间',
            D:'分数',
            E:'所属社区',
            F:'状态',
          })
          for(var index in this.legalAidData){
            table.push({
              A:this.legalAidData[index].RealName,
              B:this.legalAidData[index].UserName,
              C:this.legalAidData[index].CreatedTime,
              D:this.legalAidData[index].UserScore,
              E:this.legalAidData[index].VillagesName,
              F:this.legalAidData[index].IsChecking==0?'未阅卷':'已阅卷',
            })
          }
          var wb = XLSX.utils.book_new()
          var ws = XLSX.utils.json_to_sheet(table, {header:["A","B","C","D","E"], skipHeader:true})
          ws['!cols']= [
              {width:10},
              {width:15},
              {width:15},
              {width:5},
              {width:20},
              {width:10},
          ];
          var timestamp = (new Date()).getTime()
          XLSX.utils.book_append_sheet(wb, ws, "file")
          XLSX.writeFile(wb,"矫正成绩"+timestamp+".xlsx")
        },
        deleteCorrection:function(id){
          var _this = this
          _this.send({
              url:'/CurriculumDelete',
              data:{
                  AdminId:window.sessionStorage.AdminId,
                  CurriculumId:id,
              },
              confirm:true,
              success:function (res) {
                _this.$Notice.success({
                  title:'系统提示',
                  desc:'数据更新成功！'
                })
                  _this.adminflyzApplyList()
              }
          })
        },
          adminflyzApplyList : function () {
              var _this = this
              _this.send({
                  url:'/TotalExamListAdmin',
                  data:{
                      CurriculumId:_this.$route.query.correctionId,
                      IsRect:_this.isCorrection,
                  },
                  success:function (res) {
                      _this.legalAidData = res
                  }
              })
          },
          see : function (e) {
              console.log(e)
              this.detailsData = e
          },
          sfclmcImg : function (e) {
              this.sfclmc = e
          }
      },
      mounted (){
          var _this = this
          if(window.sessionStorage.adminLevel == undefined){
              // this.$router.push({name:'login'})
          }else {
              this.information = window.sessionStorage
              _this.adminflyzApplyList()
          }
      }
  }
</script>


