<style scoped>
    .document :deep(.van-tabs--line .van-tabs__wrap){height: 100px;}
    .document :deep(.van-tab){line-height: 100px;font-size: 30px;color: #666666;}
    /*选中*/
    .document :deep(.van-tab--active){line-height: 100px;font-size: 30px;color: #333333;}
    .document :deep(.van-tabs--line){padding-top: 100px;}
    .document :deep(.van-tabs__line){background-color: #438bff}
    /**/
    .option {background-color: #ffffff;width: 100%;height: 110px;font-size: 28px;line-height: 110px;margin-top: 3px;text-align: left}
    .document-img {width: 36px;height: 36px;margin: 39px 18px 35px 30px;}
    .name {font-size: 16px;vertical-align: top;width: 577px;white-space: nowrap;overflow: hidden;}
    .download {width: 80px;height: 80px;float: right;margin-top: 30px;}
    .head-title{font-size: 36px;color: #333333;line-height: 88px;display: inline-block; vertical-align: top;}
    .return{width: 16px;height: 30px;display: inline-block;vertical-align: top;float: left;margin-top: 29px;margin-left: 30px}
    div::-webkit-scrollbar{
        width:10px;
        height:10px;
        border-radius: 6px;
        opacity: 0.1;
        /**/
    }
    div::-webkit-scrollbar-track{
        background: rgba(16, 46, 77, 0.1);
        border-radius:2px;
    }
    div::-webkit-scrollbar-thumb{
        background: rgba(136, 193, 251, 1);
        border-radius:10px;
    }
    div::-webkit-scrollbar-corner{
        background: #179a16;
    }
</style>
<template>
    <div style="background: linear-gradient(-90deg, #2340EE, #339AEF);background-size: 100% 100%;position: fixed;height: 100%;width: 100%;text-align: center">
        <div style="background: url(/img/pc/bg.png);min-width: 1200px;text-align: left;margin-bottom: 60px;">
            <div style="height: 180px;width: 100%;background: rgba(255,255,255,0.1);font-size: 48px;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;">
                <div>
                    <img src="/img/pc/mark.png" style="margin:39px 20px 0 60px;">
                    <div style="display: inline-block;line-height: 180px;vertical-align: top">重庆市渝中区人民法院—智能法律问答系统</div>
                    <!--<span style="font-size: 18px;float: right;vertical-align: top;margin-right: 60px;line-height: 180px"> 已回答 <span style="color: #FFC618">{{total_click}}</span> 个问题</span>-->
                    <div style="float: right;vertical-align: top;margin-right: 60px;line-height: 180px" @click="$router.go(-1)">
                        <img style="vertical-align: top;margin: 90px 10px 10px 0;" src="/img/pc/sy.png">
                        <div style="display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;vertical-align: top;margin: 10px 10px 10px 0">返回上一页</div>
                    </div>
                </div>
            </div>
        </div>

        <div style="text-align: center;height: 70%;overflow: auto;overflow-x: hidden;overflow-y: auto;width: 1200px;display: inline-block" class="nui-scroll">
            <div style="width: 1200px;display: inline-block;height: 70%">
                <div class="document" style="background-color: rgb(240,244,247)">
                    <Tabs :value="active" @on-click="datahandover">
                        <TabPane :label="value.text" :name="index" v-for="(value,index) in groceryList" :title="value.text" >
                            <div class="option" v-for="(value,index) in content" @click="template(value.id)">
                                <img src="/img/pc/word-img.png" class="document-img inline-block">
                                <p class="name inline-block">{{value.name}}</p>
                                <img src="/img/pc/download.png" class="download inline-block">
                            </div>
                        </TabPane>
                    </Tabs>
                    <div style="padding: 0.2rem;"></div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import axios from 'axios'
    export default {
        name: "search",//模板搜索
        data() {
            return {
                active: 0,
                groceryList: [//选着类型
                    {id: 0, text: '起诉', type: 'qisu'},
                    {id: 1, text: '上诉', type: 'shangsu'},
                    {id: 2, text: '申请', type: 'shenqin'},
                    {id: 4, text: '常用合同', type: 'changyong'},
                    // {id: 3, text: '仲裁', type: 'zhongcai'},
                ],
                //默认参数
                kind : '1',
                content : [],
                source : '',
            };
        },
        methods:{
            datahandover:function (index) {
                console.log(index)
                var _this = this
                if(_this.groceryList[index].id == '0'){
                    _this.content = [
                        {id: 1, name: "民事起诉状"},
                        {id: 11, name: "民事反诉状"},
                        {id: 18, name: "公民民事撤诉状"},
                        {id: 8, name: "行政起诉状"},
                        {id: 42, name: "刑事自诉状（样式一）"},
                        {id: 10, name: "附带民事起诉状（附带民事诉讼案件用）"},
                        {id:103 ,name:"民事起诉状（离婚后财产纠纷） "},
                        {id:104 ,name:"民事起诉状（民间借贷合同纠纷） "},
                        // {id: 2, name: "刑事起诉状"},
                        // {id: 3, name: "民事起诉状（公民使用）"},
                        // {id: 4, name: "选民资格案件起诉状"},
                        // {id: 5, name: "民事起诉状（法人或其他组织提起民事诉讼用）"},
                        // {id: 6, name: "起诉状（公民提起民事、行政诉讼用）"},
                        // {id: 7, name: "经营承包合同纠纷起诉状"},
                        // {id: 9, name: "行政起诉状（法人或其他组织提起行政诉讼用）"},
                        // {id: 12, name: "民事申诉状"},
                        // {id: 13, name: "民事上诉状"},
                        // {id: 14, name: "刑事反诉状"},
                        // {id: 15, name: "申诉状（民事）"},
                        // {id: 16, name: "行政申诉状"},
                        // {id: 17, name: "行政上诉状"},
                        // {id: 19, name: "刑事上诉状"},
                        // {id: 20, name: "经济仲裁申诉状"},
                        // {id: 21, name: "撤回上诉状（行政）"},
                    ]
                }
                if(_this.groceryList[index].id == '1'){
                    _this.content = [
                        {id: 22, name: "民事上诉状"},
                        {id: 23, name: "行政上诉状"},
                        {id: 28, name: "刑事上诉状（刑事案件上诉用）"},
                        {id: 25, name: "撤回上诉状（行政）"},
                        {id: 26, name: "公民撤回上诉状"},
                        {id: 27, name: "法人或其他组织撤回上诉状"},


                        // {id: 24, name: "刑事上诉状"},
                        // {id: 29, name: "撤诉状（法人或其他组织撤回上诉时使用）"},
                        // {id: 30, name: "民事起诉状"},
                        // {id: 31, name: "民事反诉状"},
                        // {id: 32, name: "刑事起诉状"},
                        // {id: 33, name: "民事申诉状"},
                        // {id: 34, name: "民事起诉状（公民使用）"},
                        // {id: 35, name: "刑事反诉状"},
                        // {id: 36, name: "申诉状（民事）"},
                        // {id: 37, name: "行政申诉状"},
                        // {id: 38, name: "经济仲裁申诉状"},
                        // {id: 39, name: "公民民事撤诉状"},
                        // {id: 40, name: "行政上诉书"},
                        // {id: 41, name: "选民资格案件起诉状"},
                        // {id: 42, name: "刑事自诉状（样式一）"},
                    ]
                }
                if(_this.groceryList[index].id == '2'){
                    _this.content = [
                        {id: 43, name: "鉴定申请书"},
                        {id: 46, name: "申请书（个人用）"},
                        {id: 57, name: "调查证据申请书"},
                        {id: 56, name: "参加诉讼申请书（民事）"},
                        {id: 54, name: "参加诉讼申请书（行政）"},
                        {id: 61, name: "强制执行申请书"},
                        {id: 55, name: "停止执行申请书"},
                        {id: 60, name: "取保候审申请书（样式三）"},
                        {id: 52, name: "解除取保候审申请书"},
                        {id: 62, name: "减刑、假释申请书"},



                    // {id: 44, name: "运输变更申请书"},
                    // {id: 45, name: "申请书（法院）"},
                    // {id: 47, name: "包机申请书"},
                    // {id: 48, name: "反请求申请书"},
                    // {id: 49, name: "商标变更申请书"},
                    // {id: 50, name: "更正商标申请/注册事项申请书"},
                    // {id: 51, name: "备案申请书（单位用）"},
                    // {id: 53, name: "复议申请书（样式二）"},
                    // {id: 58, name: "仲裁反申请书"},
                    // {id: 59, name: "回避申请书（仲裁）"},
                    // {id: 63, name: "公司名称申请书"},
                    ]
                }
                if(_this.groceryList[index].id == '4'){
                    _this.content = [
                        {id: 85, name: "借据模板"},
                        {id: 87, name: "收条模板"},
                        {id: 88, name: "领条模板"},
                        {id: 89, name: "租赁合同模板"},
                        {id: 90, name: "收入证明模板"},
                        {id: 91, name: "离职证明模板"},
                        {id: 92, name: "遗嘱模板"},

                        {id:95,name:"二手房买卖合同（自行成交版)"},
                        {id:96,name:"房地产经纪服务合同"},
                        {id:97,name:"工伤认定申请表"},
                        {id:98,name:"交通事故和解协议（两种版本）"},
                        {id:99,name:"借条（有利息_ 有还款时间）"},
                        {id:100,name:"旧机动车买卖合同 "},
                        {id:101,name:"劳动合同解除_终止证明单 "},
                        {id:102,name:"劳动合同书  "},
            ]
                }
                // axios.post('https://ai.lvwa18.com/doc_template',{
                //     kind : _this.kind,
                //     type : _this.groceryList[index].type
                // })
                //     .then((res)=>{
                //         _this.content = res.data.list
                //     })
                _this.active = index
            },
            template : function (id) {
                var _this = this
                console.log(id)
                // window.sessionStorage.id = id
                this.$router.push({path: '/cqdownload', query: {id: id,name:_this.groceryList[_this.active].text}})
                // window.location.href = "/download"
            }
        },
        mounted : function () {
            this.datahandover(0)
        }
    }
</script>
