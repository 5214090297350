<template>
    <div>
        <div class="screen">
            <div style="background-color: white;margin:20px;">
                <div style="padding:15px"></div>
                <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px">法律援助</div>
                <div style="padding:15px"></div>
                <div class="publicWelfare" >
                    <table class="formtable">
                        <thead>
                        <tr>
                            <th>申请用户</th>
                            <th>律师名称</th>
                            <th>申请内容</th>
                            <th>申请时间</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value,index) in legalAidData">
                            <td><Avatar :src="value.UserImg" /> {{value.UserName}}</td>
                            <td>{{value.LawyerName}}</td>
                            <td> <Button type="primary" @click="see(value.Content);modal1 = true">查看</Button></td>
                            <td>{{value.CreateTime}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style="text-align: left;margin-left: 40px;font-size: 16px;padding-bottom: 20px">
                    <Page
                            :total="count"
                            show-total
                            :page-size="pageSize"
                            show-elevator
                            @on-change="page = $event;adminflyzApplyList()"
                    />
                </div>
            </div>
        </div>
        <Modal
                v-model="modal1"
                title="法律援助"
                ok-text="关闭"
                cancel-text=""
        >
            <div class="details">
                <div style="font-size: 16px">1、基本信息</div>
                <p><span>姓   名：</span>{{detailsData.applyName}}</p>
                <p><span>性别：</span>{{detailsData.sex}}</p>
                <p><span>身份证号码：</span>{{detailsData.idCard}}</p>
                <p><span>民族：</span>{{detailsData.nation}}</p>
                <p><span>出生日期：</span>{{detailsData.birthday}}</p>
                <p><span>年龄：</span>{{detailsData.age}}</p>
                <p><span>住所地址：</span>{{detailsData.address}}</p>
                <p><span>联系电话：</span>{{detailsData.phone}}</p>
                <div style="font-size: 16px">2、被申请人信息</div>
                <p><span>姓名：</span>{{detailsData.respondent}}</p>
                <p><span>身份证号码：</span>{{detailsData.respondentIdCard}}</p>
                <p><span>住所地址：</span>{{detailsData.respondentAddress}}</p>
                <p><span>联系电话：</span>{{detailsData.respondentPhone}}</p>
                <div style="font-size: 16px">3、申请人民调解的纠纷概述</div>
                <p><span>纠纷类型：</span>{{detailsData.applyType}}</p>
                <p><span>纠纷概述：</span>{{detailsData.proposerExcuse}}</p>
                <p><span>机构所在：</span>{{detailsData.orgCity}}{{detailsData.orgArea}}{{detailsData.orgZhen}}{{detailsData.hui}}{{detailsData.ren}}</p>
            </div>
        </Modal>
        <!---->
        <Modal
                v-model="modal2"
                title="身份证"
                ok-text="关闭"
                cancel-text="">
            <div v-model="sfclmc" >
                <img :src="sfclmc" style="width: 100%;">
            </div>
        </Modal>

    </div>
</template>

<script>
    export default {
        name: "mediateList",
        data () {
            return {
                information : [],
                legalAidData : [],
                count : '0',
                pageSize : 10,
                page : 1,
                modal1 : false,
                detailsData : [],
                sfclmc : ''
            }
        },
        methods : {
            adminflyzApplyList : function () {
                var _this = this
                _this.send({
                    url:'/AdminMediateList',
                    data:{
                        AdminId:window.sessionStorage.AdminId
                    },
                    success:function (res) {
                        _this.legalAidData = res.List
                        _this.count = res.Count
                    }
                })
            },
            see : function (e) {
                console.log(e)
                this.detailsData = e
            },
            sfclmcImg : function (e) {
                this.sfclmc = e
            }
        },
        mounted (){
            var _this = this
            if(window.sessionStorage.adminLevel == undefined){
                // this.$router.push({name:'login'})
            }else {
                this.information = window.sessionStorage
                _this.adminflyzApplyList()
            }
        }
    }
</script>

<style scoped>
    .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
    /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
    .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
    .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
    .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
    .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
    .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
    .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
    .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
    .details p{color: #000000}
    .details span{color: #5c6b77}
</style>
