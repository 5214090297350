<style scoped>
table,tr,th,td{ border:1px solid #000000;padding:12px 10px;}
*{font-family:SimSun;}
</style>

<template>
  <Modal v-model="ownState" width="750px" :closable="false" :styles="{top:'7%'}">
    <table style="border-collapse: collapse;border-spacing:0;width: 100%;" v-if="JSON.stringify(tableData)!='{}'">
      <thead>
      <tr>
        <td colspan="6" style="text-align: center;font-size:24px;font-weight:bold;">法律援助申请表</td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>申请类别</td>
        <td colspan="5">{{tableData.aid_context.lawHelperType}}</td>
      </tr>
      <tr>
        <td colspan="6" style="font-size:18px;font-weight:bold;">申请人基本信息</td>
      </tr>
      <template v-for="(value, index) in tableData.aid_context.applyList" v-if="tableData.aid_context.applyList">
        <tr>
          <td>申请人</td>
          <td>{{value.name}}</td>
          <td>性别</td>
          <td>{{value.sex}}</td>
          <td>民族</td>
          <td>{{value.nationality}}</td>
        </tr>
        <tr>
          <td>身份证号码</td>
          <td>{{value.idCard}}</td>
          <td>年龄</td>
          <td>{{value.age}}</td>
          <td>联系方式</td>
          <td>{{value.phoneNum}}</td>
        </tr>
        <tr>
          <td>住所地址</td>
          <td>{{value.address}}</td>
          <td>出生日期</td>
          <td>{{value.birthDate}}</td>
          <td>申请时间</td>
          <td>{{tableData.created_at}}</td>
        </tr>
        <tr>
          <td>身份证明</td>
          <td colspan="5">
            <a :href="value.idCardFrontUrl" target="blank"><img height="140px" :src="value.idCardFrontUrl" style="border:5px solid #e5e5e5;"/></a>
            <span style="padding:5px;"></span>
            <a :href="value.idCardBackUrl" target="blank"><img height="140px" :src="value.idCardBackUrl" style="border:5px solid #e5e5e5;"/></a>
          </td>
        </tr>
      </template>
<!--      判断老数据-可删除-->
      <template v-if="tableData.aid_context.applyList == undefined">
        <tr>
          <td>申请人</td>
          <td>{{tableData.aid_context.name}}</td>
          <td>性别</td>
          <td>{{tableData.aid_context.sex}}</td>
          <td>民族</td>
          <td>{{tableData.aid_context.nationality}}</td>
        </tr>
        <tr>
          <td>身份证号码</td>
          <td>{{tableData.aid_context.idCard}}</td>
          <td>年龄</td>
          <td>{{tableData.aid_context.age}}</td>
          <td>联系方式</td>
          <td>{{tableData.aid_context.phoneNum}}</td>
        </tr>
        <tr>
          <td>出生日期</td>
          <td colspan="2">{{tableData.aid_context.birthDate}}</td>
          <td>申请时间</td>
          <td colspan="2">{{tableData.created_at}}</td>
        </tr>
        <tr>
          <td>身份证明</td>
          <td colspan="5">
            <a :href="tableData.aid_context.idCardFrontUrl" target="blank"><img height="140px" :src="tableData.aid_context.idCardFrontUrl" style="border:5px solid #e5e5e5;"/></a>
            <span style="padding:5px;"></span>
            <a :href="tableData.aid_context.idCardBackUrl" target="blank"><img height="140px" :src="tableData.aid_context.idCardBackUrl" style="border:5px solid #e5e5e5;"/></a>
          </td>
        </tr>
      </template>
      <tr>
        <td>否有低保证</td>
        <td  colspan="2">{{tableData.aid_context.diBao == 1? '是' : '否'}}</td>
        <td v-if="tableData.aid_type">受援人类型</td>
        <td v-if="tableData.aid_type"  colspan="2">{{tableData.aid_type.type_name}}</td>
      </tr>
      <template v-if="tableData.aid_context.respondentList">
        <tr>
          <td colspan="6" style="font-size:18px;font-weight:bold;">被申请人信息</td>
        </tr>
        <tr v-for="value in tableData.aid_context.respondentList">
          <td>姓名</td>
          <td>{{value.respondentName}}</td>
          <td>手机号</td>
          <td>{{value.respondentPhone}}</td>
          <td>住所地址</td>
          <td>{{value.respondentAddress}}</td>
        </tr>
      </template>
      <template v-else>
        <tr v-if="tableData.aid_context.respondentName">
          <td colspan="6" style="font-size:18px;font-weight:bold;">被申请人信息</td>
        </tr>
        <tr v-if="tableData.aid_context.respondentName">
          <td>姓名</td>
          <td>{{tableData.aid_context.respondentName}}</td>
          <td>手机号</td>
          <td>{{tableData.aid_context.respondentPhone}}</td>
          <td>住所地址</td>
          <td>{{tableData.aid_context.respondentAddress}}</td>
        </tr>
      </template>
      <tr>
        <td>相关印证材料</td>
        <td colspan="5">
          <div style="display:inline-block;margin-right: 10px" v-for="(value,index) in tableData.aid_context.multipleFileUrl">
            <a :href="value" target="blank"><img height="140px" :src="value" style="border:5px solid #e5e5e5;"/></a>
          </div>
        </td>
      </tr>
      <tr v-if="tableData.aid_context.uploadUrl">
        <td>证件</td>
        <td colspan="5">
          <a :href="tableData.aid_context.uploadUrl" target="blank"><img height="140px" :src="tableData.aid_context.uploadUrl" style="border:5px solid #e5e5e5;"/></a>
        </td>
      </tr>
      </tbody>
    </table>
    <div slot="footer">
      <Button type="warning" long @click="ownState=false"><Icon type="ios-alert-outline"/> 关闭</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name:'legalaidModalhhht',
  props:{
    tableData:{
      type:Object,
      default:()=>{return {}},
    },
    state:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    state:function(val){
      this.ownState = val
    },
    ownState:function(val){
      this.$emit('on-close',val)
    },
  },
  data() {
    return{
      ownState:false
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>
