import nullPage from '@/views/nullPage'

const userClient = {
  list:require('@/views/mobile/mediate/user-client/list').default,
  apply:{
    step1:require('@/views/mobile/mediate/user-client/apply/step1').default,
    step2:require('@/views/mobile/mediate/user-client/apply/step2').default,
    step3:require('@/views/mobile/mediate/user-client/apply/step3').default,
  },
}
const mediatorClient = {
  list:require('@/views/mobile/mediate/mediator-client/list').default,
  detial:require('@/views/mobile/mediate/mediator-client/detial').default,
}

const routes = [
  {
    path:'user-client',
    component:nullPage,
    children:[
      {
        path:'mediate',
        component:nullPage,
        children:[
          {path:'list',name:'userClientList',component:userClient.list,meta:{title:'人民调解-申请列表'}},
          {
            path:'apply',
            component:nullPage,
            children:[
              {path:'step1',name:'MUCAS1',component:userClient.apply.step1,meta:{title:'申请人民调解-步骤一'}},
              {path:'step2',name:'MUCAS2',component:userClient.apply.step2,meta:{title:'申请人民调解-步骤二'}},
              {path:'step3',name:'MUCAS3',component:userClient.apply.step3,meta:{title:'申请人民调解-步骤三'}},
            ]
          },
        ]
      },
    ]
  },
  {
    path:'mediator-client',
    component:nullPage,
    children:[
      {path:'list',name:'mediatorClientList',component:mediatorClient.list,meta:{title:'人民调解-纠纷列表'}},
      {path:'detial',name:'mediatorClientDetial',component:mediatorClient.detial,meta:{title:'人民调解-纠纷详情'}},
    ]
  },
]

export default {
  routes:routes,
}