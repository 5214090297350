<style scoped>
.archiveItem{border-bottom:1px #e8eaec dashed;padding:7px 16px;transition:0.2s all;cursor: pointer;}
.archiveItem:hover{background-color:#efefef;}
</style>

<template>
  <div>
    <!-- <icon-svg v-if="archiveDetail.data.IsCheck == 0" src="/img/icons-svg/archives/reviewing.svg" height="120px" width="120px" :resetColor="false" style="position: absolute;top:140px;right:40px;z-index:1;opacity:0.5;"></icon-svg> -->
    <icon-svg v-if="archiveDetail.data.IsCheck == 1" src="/img/icons-svg/archives/pass.svg" height="120px" width="120px" :resetColor="false" style="position: absolute;top:140px;right:40px;z-index:1;opacity:0.5;"></icon-svg>
    <icon-svg v-if="archiveDetail.data.IsCheck == 2" src="/img/icons-svg/archives/failed.svg" height="120px" width="120px" :resetColor="false" style="position: absolute;top:140px;right:40px;z-index:1;opacity:0.5;"></icon-svg>
    <Row align="middle" :gutter="10">
      <Col span="12" v-if="archiveDetail.data.FileUrl">
        <!-- 图片预览 -->
        <viewer :images="archiveDetail.data.FileUrl">
          <Carousel v-if="archiveDetail.data.FileUrl">
            <CarouselItem v-for="(value, index) in archiveDetail.data.FileUrl" style="text-align: center">
              <img :src="assetsLink + value" style="width:auto;height:400px;">
            </CarouselItem>
          </Carousel>
        </viewer>
      </Col>
      <Col :span="archiveDetail.data.FileUrl?'12':'24'">
        <div>
          <div class="archiveItem" style="border-bottom:1px solid #efefef;font-size:18px;"><Icon type="ios-list-box-outline"></Icon> 档案详情</div>
          <Row class="archiveItem">
            <Col span="6">所属村(社区)：</Col>
            <Col span="18" style="text-align:right;">
              <template v-for="(item, index) in archiveDetail.data.villagesNameArr">
                <template v-if="index>0">、</template>
                {{ item.VillagesName }}
              </template>
            </Col>
          </Row>
          <Row v-if="location!='chong-qing'" class="archiveItem">
            <Col span="6">考核分栏：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.title+' - '+archiveDetail.data.pTitle}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.EvaluationCount" class="archiveItem">
            <Col span="6">考核计数：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.EvaluationCount+' '+archiveDetail.data.Unit}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.ServiceLinkman" class="archiveItem">
            <Col span="6">服务对象名称：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.ServiceLinkman}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.ServiceTel" class="archiveItem">
            <Col span="8">服务对象联系电话：</Col>
            <Col span="16" style="text-align:right;">{{archiveDetail.data.ServiceTel}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.ConsultHumanTypeArr" class="archiveItem">
            <Col span="6">咨询人类型：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.ConsultHumanTypeArr.toString()}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.ManageSerTypeArr" class="archiveItem">
            <Col span="6">服务事项：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.ManageSerTypeArr.toString()}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.MediateTypeArr" class="archiveItem">
            <Col span="6">纠纷类型：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.MediateTypeArr.toString()}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.ConsultTypeArr" class="archiveItem">
            <Col span="6">知识类型：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.ConsultTypeArr.toString()}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.DealTypeArr" class="archiveItem">
            <Col span="6">处理方式：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.DealTypeArr.toString()}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.MediateHumanTypeArr" class="archiveItem">
            <Col span="6">纠纷人群：</Col>
            <Col span="18" style="text-align:right;">{{ eleFileViewData.MediateHumanTypeArr.toString() }}</Col>
          </Row>
          <Row v-if="archiveDetail.data.PetitionerArr" class="archiveItem">
            <Col span="6">信访人：</Col>
            <Col span="18" style="text-align:right;">
              <template v-if="archiveDetail.data.PetitionerArr" v-for="(item, index) in archiveDetail.data.PetitionerArr">
                <div>
                  名称：{{ item.name }}|电话：{{ item.telephone }}
                </div>
              </template>
            </Col>
          </Row>
          <Row v-if="archiveDetail.data.MediateResult" class="archiveItem">
            <Col span="6">调解结果：</Col>
            <Col span="18" style="text-align:right;">{{ archiveDetail.data.MediateResult==1?'成功':'不成功' }}</Col>
          </Row>
          <Row v-if="archiveDetail.data.ServiceCount" class="archiveItem">
            <Col span="6">服务人数：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.ServiceCount+'人'}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.ServiceTime" class="archiveItem">
            <Col span="6">服务时间：</Col>
            <Col span="18" style="text-align:right;">{{timestampToDate(archiveDetail.data.ServiceTime,'date')}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.ServiceLong" class="archiveItem">
            <Col span="6">服务时长：</Col>
            <Col span="18" style="text-align:right;">{{(archiveDetail.data.ServiceLong>0?archiveDetail.data.ServiceLong+'小时':'')+(archiveDetail.data.ServiceMins>0?archiveDetail.data.ServiceMins+'分钟':'')}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.ServiceTypeName&&location=='chong-qing'" class="archiveItem">
            <Col span="6">服务方式：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.ServiceTypeName?archiveDetail.data.ServiceTypeName:archiveDetail.data.EvaluationTypePid}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.DailyType&&location!='chong-qing'" class="archiveItem">
            <Col span="6">服务方式：</Col>
            <Col span="18" style="text-align:right;">{{archiveDetail.data.DailyType.toString()}}</Col>
          </Row>
          <Row v-if="archiveDetail.data.OrderGrade" class="archiveItem">
            <Col span="6">评价：</Col>
            <Col span="18" style="text-align:right;">
              <span v-if="archiveDetail.data.OrderGrade == 5"><Tag color="green">非常满意</Tag></span>
              <span v-if="archiveDetail.data.OrderGrade == 4"><Tag color="blue">满意</Tag></span>
              <span v-if="archiveDetail.data.OrderGrade == 3"><Tag color="default">基本满意</Tag></span>
              <span v-if="archiveDetail.data.OrderGrade == 2"><Tag color="gold">不满意</Tag></span>
              <span v-if="archiveDetail.data.OrderGrade == 1"><Tag color="red">非常不满意</Tag></span>
            </Col>
          </Row>
          <div style="padding-top:15px;"></div>
        </div>
      </Col>
    </Row>
    <div style="padding:10px 0;"></div>
    <Alert :type="archiveDetail.data.IsCheck == 0?'info':(archiveDetail.data.IsCheck == 1?'success':'warning')" show-icon v-if="archiveDetail.data.FileExplain">
      服务内容及处理结果：
      <span slot="desc">{{archiveDetail.data.FileExplain}}</span>
    </Alert>
    <Row v-if="archiveDetail.data.DocumentUrl&&archiveDetail.data.DocumentUrl.length>0" class="archiveItem">
      <Col span="4">附件：</Col>
      <Col span="20" style="text-align:right;">
        <template v-for="(item, index) in archiveDetail.data.DocumentUrl" >
          <div style="border-bottom:1px dashed #efefef;margin:5px 0;" v-if="index>0"></div>
          <div style="margin-left: 30px">
            {{ item.name }}
            <Button type="success" size="small" ghost @click="openFile(item.url)"><Icon type="md-eye" /></Button>
            <span style="margin:3px;"></span>
            <Button type="warning" size="small" ghost  @click="downloadFile(item.name,item.url)"><Icon type="md-download" /></Button>
          </div>
        </template>
      </Col>
    </Row>
<!--    <template v-if="archiveDetail.data.IsCheck == 0">-->
<!--      <div style="padding-top:50px;"></div>-->
<!--      <div style="position: absolute;bottom:0;height:50px;width:100%;left:0;">-->
<!--        <Row>-->
<!--          <Col span="12"><Button type="warning" long style="height:50px;border-radius:0 0 0 5px;" @click="toReject(archiveDetail.data.FileId)">审核不通过</Button></Col>-->
<!--          <Col span="12"><Button type="success" long style="height:50px;border-radius:0 0 5px 0;" @click="toExamine(1, archiveDetail.data.FileId)">审核通过</Button></Col>-->
<!--        </Row>-->
<!--      </div>-->
<!--    </template>-->

    <Modal v-model="modal6" title="驳回理由" @on-ok="toRejectDetermine" ok-text="发送" style="height: 300px" draggable>
      <Input v-model="checkMsg" maxlength="300" show-word-limit type="textarea" placeholder="驳回理由" />
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import iconSvg from '@/components/public/icon-svg'
export default {
  name:'archiveDetail',
  props:{
    archiveDetail:{require:true}
  },
  components:{iconSvg},
  data() {
    return {
      modal6 : false,
      fileId : '',
      checkMsg:'',
    };
  },
  mounted() {
  },
  methods: {
    timestampToDate: function (timestamp,type) {
      var date = new Date(1000 * timestamp); //获取一个时间对象
      var Y = date.getFullYear();
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      var H = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours());
      var B = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
      if (type == 'datetime') { return `${Y}年${M}月${D}日 ${H}:${B}`}
      if (type == 'date') { return `${Y}年${M}月${D}日`}
    },
    openFile:function(url){
      var _this = this
      var fileType = url.substr(url.lastIndexOf(".") + 1, 4).toLowerCase()
      if (fileType == "txt") {
        axios({
          method: 'get',
          responseType: 'blob',
          url: _this.assetsLink+url,
          transformResponse: [function (data) {
            var render = new FileReader()
            render.readAsText(data, 'UTF8')
            render.onload = function () {
              if (render.result.indexOf('�') != -1) {
                var renderGBK = new FileReader()
                renderGBK.readAsText(data, 'GBK')
                renderGBK.onload = function () {
                }
              }
              var newWindow = window.open('about:blank', '', 'width=400,height=400')
              newWindow.document.write(renderGBK.result)
            }
          }]
        })
      }
      else {
        if (fileType == 'pdf') {
          window.open(_this.assetsLink+url)
        } else {
          if (SYSCONFIG.location == 'chong-qing') {
            _this.showPdf(url)
          } else {
            window.open('https://view.officeapps.live.com/op/view.aspx?src=' + _this.assetsLink+url)
          }
        }
      }
    },
    downloadFile:function(fileName,url){
      let _this = this
      axios({
        method:'get',
        url:_this.assetsLink+url,
        responseType: 'blob',
      })
          .then(res=>{
            let blob = new Blob([res.data]);
            let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
          })
    },
    showPdf: function (e) {
      var _this = this
      if (_this.location == 'chong-qing') {
        var url = '/ShowPdf'
      } else {
        var url = '/CD_ShowPdf'
      }
      window.open(window.sessionStorage.url + 'api' + url + '?FilePath='+ e)
    },
    toRejectDetermine: function () {
      var _this = this
      if (_this.checkMsg) {
        _this.send({
          url: '/CheckActive',
          data: {
            IsCheck: 2,
            FileId: _this.fileId,
            CheckMsg: _this.checkMsg,
            AdminId: window.sessionStorage.AdminId,
            PidArr : window.sessionStorage.parentIdArrStr.split(),
          },
          success: function (res) {
            _this.$parent.getArchiveList()
            _this.archiveDetail.state = false
          }
        })
      } else {
        _this.$Message.error('驳回理由不能为空');
      }

    },
    toExamine: function (isCheck, fileId) {
      var _this = this
      _this.send({
        url: '/CheckActive',
        confirm: true,
        data: {
          IsCheck: isCheck,
          FileId: fileId,
          AdminId: window.sessionStorage.AdminId,
          PidArr : window.sessionStorage.parentIdArrStr.split(),
        },
        success: function (res) {
          _this.$parent.getArchiveList()
          _this.archiveDetail.state = false
        }
      })
    },
    toReject: function (fileId) {
      var _this = this
      _this.fileId = fileId
      _this.modal6 = true
    },
  },
  computed:{
    location:function(){
      return SYSCONFIG.location
    },
    assetsLink:function(){
      return `${SYSCONFIG.server.default.ssl?'https':'http'}://${SYSCONFIG.server.default.host}:${SYSCONFIG.server.default.port}/`
    }
  },
}
</script>
