<style scoped>
.bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
.introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
.introduce-video-player{width:100%;}
.mediator-list{color:#FFF;}
.mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
.mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
.introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
.introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
.introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div class="introduce-text">
      <div class="introduce-text-title">申办分房现场监督公证需提交的材料</div>
      <div class="introduce-text-content">
        1、镇(街道)上级部门确定xxx组织分房的相关依据。</br>
        2、街道办主任、镇长的任职文件，身份证复印件。</br>
        3、街道办主任、镇长的授权委托书（授权XXX人申办）</br>
        分房现场监督公证，街道办主任、镇长签名，政府盖章。</br>
        4、经办人的身份证。</br>
        5、征地文件（待分配的村、组的征地文件批复）。</br>
        6、还房验收合格文件。</br>
        7、被拆迁户还房公示花名册。</br>
        8、还房房源公示册。</br>
        9、拆迁还房分配方案。</br>
        公证处联系人：马丹</br>
        电话：13982917866
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <Row style="text-align: center;">
      <Col span="24"><a href="https://scjys.egongzheng.com/H5/templates/index.html"><Button type="warning" style="width:4rem;">去 办 理</Button></a></Col>
    </Row>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'guild1',
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {

  },
}
</script>
