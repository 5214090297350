<style scoped>
</style>

<template>
  <div v-if="src" :style="`width:${width};height:${height};overflow:hidden;display:inline-block;vertical-align:middle;font-size:0;`" ref="icon">
    <embed :src="src" :style="style" type="image/svg+xml"/>
  </div>
</template>

<script>
export default{
  name: 'icon-svg',
  props: {
    src:{type:String,default:'',},
    width:{type:String,default:'14px',},
    height:{type:String,default:'14px',},
    color:{type:String,},
    resetColor:{type:Boolean,default:true},
  },
  data:function(){
    return{
      iconHeight:'14px',
      iconColor:'#999',
      style:{}
    }
  },
  mounted:function(){
    this.iconColor = this.color||this.$refs.icon.style.color
    if(this.resetColor){
      this.style = {width:this.width,height:this.height,marginLeft:'-'+this.width,filter:`drop-shadow(${this.iconColor} ${this.width} 0)`}
    }else{
      this.style = {width:this.width,height:this.height}
    }
  },
}
</script>