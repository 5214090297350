<template>
    <div>
        <div style="border-bottom: solid 0.01rem #DFDFDF">
            <img src="/img/caseInput/mrtx.png" style="width: 1.2rem;height: 1.2rem;margin: 0.3rem 0.2rem 0.6rem 0.3rem;">
            <div style="display: inline-block;vertical-align: top;margin-top: 0.5rem">
                <div style="font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.3rem;margin-bottom: 0.2rem">{{userPhone}}</div>
                <div style="display: inline-block;padding: 0rem 0.2rem;background: #E8E8E8;border-radius: 0.04rem;font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #FC6553;line-height: 0.41rem;">
                    {{count}} 条评价
                </div>
            </div>
        </div>
        <div>
            <div style="font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #0694E6;line-height: 1.09rem;margin-left: 0.3rem">我的评价</div>
            <div style="text-align: center;margin-bottom: 0.3rem" v-for="(value,index) in userCommentByIdData"  @click="caseDetails(value.caseId)">
                <div style="width: 6.9rem;background: #F0F0F5;border-radius: 0.04rem;display: inline-block;text-align: left;padding: 0 0.3rem 0.3rem">
                    <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: bold;color: #333;line-height: 0.28rem;padding: 0.3rem 0 0.2rem 0">张三诉李四民事案件</div>
                    <div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #999;line-height: 0.24rem">{{value.caseNum}}</div>
                    <div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #999;line-height: 0.24rem;margin-top: 0.2rem">{{value.commentTime}}</div>
                    <img style="width:1.14rem;height: 0.18rem;margin: 0.2rem 0 0.26rem 0;"   :src="'/img/caseInput/score'+value.star+'.png'">
                    <div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #999999;line-height: 0.32rem;">
                        {{value.comment}}
                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 0.5rem"></div>
        <van-tabbar v-model="active" style="height: 1rem;font-size: 0.2rem">
            <van-tabbar-item @click="jump">
                <span>首页</span>
                <template #icon="props">
                    <img :src="active ? iconShow.active : iconShow.inactive" style="width: 0.48rem;height: 0.48rem"/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item>
                <span>我的</span>
                <template #icon="props">
                    <img :src="active ? iconmy.active : iconmy.inactive" style="width: 0.48rem;height: 0.48rem"/>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        name: "evaluateList",
        data() {
            return {
                active: 1,
                iconShow: {
                    active: '/img/caseInput/home1.png',
                    inactive: '/img/caseInput/home.png',
                },
                iconmy: {
                    active: '/img/caseInput/my.png',
                    inactive: '/img/caseInput/my1.png',
                },
                page : '',
                userId : '',
                userPhone : '',
                userCommentByIdData : [],
                loadData : false,
                count : 0
            }
        },
        methods :{
            caseDetails : function(caseId){
                var _this = this
                _this.$router.push({name: 'caseDetails', query: {caseId:caseId,qr:1}})
            },
            userCommentByIdList : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/userCommentByIdList',
                    data: {
                        userId  : _this.userId,
                        PageSize : 10,
                        page : _this.page,
                    },
                    success: function (res) {
                        console.log(res)
                        if(res){
                            for(var i in res.list){
                                res.list[i].star = parseInt(res.list[i].star)
                                _this.userCommentByIdData.push(res.list[i])
                            }
                            _this.count = res.count
                            _this.loadData = false
                        }
                    }
                })
            },
            jump : function(){
                this.$router.push({name: 'lawfirmList'})
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
        mounted () {
            var _this = this
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    if (arr2[0] == 'userId') {
                        _this.userId = arr2[1]
                    }
                    if (arr2[0] == 'userPhone') {
                        _this.userPhone = arr2[1]
                    }
                }
            }
            _this.setPageSize()
            _this.userCommentByIdList()
        },
        // created() {
        //     let that = this;
        //     window.onscroll = function () {
        //         // scrollTop 滚动条滚动时，距离顶部的距离
        //         var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //         // windowHeight 可视区的高度
        //         var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //         // scrollHeight 滚动条的总高度
        //         var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        //         // 滚动条到底部的条件
        //         if (scrollTop + windowHeight+5 >= scrollHeight &&!that.loadData) {
        //             that.loadData = true
        //             // 加载数据
        //             that.page++
        //             that.userCommentByIdList();
        //         }
        //     }
        // },
    }
</script>

<style scoped>

</style>
