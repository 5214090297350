<style scoped>
.bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
.introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
.introduce-video-player{width:100%;}
.mediator-list{color:#FFF;}
.mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
.mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
.introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
.introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
.introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div class="introduce-text">
      <div class="introduce-text-title">遗嘱公证需提交的材料</div>
      <div class="introduce-text-content">
        1、立遗嘱的身份证原件；</br>
        2、户口簿原件；</br>
        3、结婚证原件（离了婚的出示离婚证、离婚协议）；</br>
        4、城镇房屋不动产证或房产证、土地证，商品房网签买卖合同，旧城改造中的拆旧还新合同（必须是交了还房的并由还房人开具还房基本情况证明）；</br>
        5、受益人身份证复印件；</br>
        6、行为能力司法鉴定报告（立遗嘱人70岁以上的或70岁以下有重大疾病的）；</br>
        7、收费为财产价值30万以下的480，30万元以上的880。
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <Row style="text-align: center;">
      <Col span="24"><a href="https://scjys.egongzheng.com/H5/templates/index.html"><Button type="warning" style="width:4rem;">去 办 理</Button></a></Col>
    </Row>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'guild8',
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {

  },
}
</script>