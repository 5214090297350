<style scoped>
table,tr,th,td{ border:1px solid #000000;padding:12px 10px;}
*{font-family:SimSun;}
</style>

<template>
  <Modal v-model="ownState" width="750px" :closable="false" :styles="{top:'7%'}">
    <table style="border-collapse: collapse;border-spacing:0;" v-if="JSON.stringify(tableData)!='{}'">
      <thead>
      <tr>
        <td colspan="6" style="text-align: center;font-size:24px;font-weight:bold;">法律援助申请表</td>
      </tr>
      </thead>
      <tbody v-if="tableData.SupplementContent == null">
      <tr>
        <td>申请类别</td>
        <td colspan="5">{{tableData.Content.zxsxlxzx}}</td>
      </tr>
      <tr>
        <td colspan="6" style="font-size:18px;font-weight:bold;">基本信息</td>
      </tr>
      <tr>
        <td>申请人</td>
        <td>{{tableData.Content.zxr}}</td>
        <td>性别</td>
        <td>{{tableData.Content.xb}}</td>
        <td>民族</td>
        <td>{{tableData.Content.mz}}</td>
      </tr>
      <tr>
        <td>身份证号码</td>
        <td>{{tableData.Content.zjhm}}</td>
        <td>年龄</td>
        <td>{{tableData.Content.nl}}</td>
        <td>联系方式</td>
        <td>{{tableData.Content.lxdh}}</td>
      </tr>
      <tr>
        <td>出生日期</td>
        <td colspan="2">{{tableData.Content.csrq}}</td>
        <td>申请时间</td>
        <td colspan="2">{{tableData.CreateTime}}</td>
      </tr>
      <tr>
        <td>身份证明</td>
        <td colspan="5">
          <a :href="tableData.Content.sfcl" target="blank"><img height="140px" :src="tableData.Content.sfcl" style="border:5px solid #e5e5e5;"/></a>
          <span style="padding:5px;"></span>
          <!--            <a :href="tableData.Content.sfclmc" target="blank"><img height="140px" :src="tableData.Content.sfclmc" style="border:5px solid #e5e5e5;"/></a>-->
        </td>
      </tr>
      <tr>
        <td colspan="6" style="font-size:18px;font-weight:bold;">被申请人信息</td>
      </tr>
      <tr>
        <td>姓名</td>
        <td>{{tableData.Content.bsqr}}</td>
        <td>身份证号码</td>
        <td>{{tableData.Content.bsqrzjhm}}</td>
        <td>住所地址</td>
        <td>{{tableData.Content.bsqrdz}}</td>
      </tr>
      <tr>
        <td>机构所在</td>
        <td colspan="5">{{tableData.Content.orgCity}}{{tableData.Content.orgArea}}</td>
      </tr>
      <tr>
        <td>案情及申请 理由概述</td>
        <td colspan="5">{{tableData.Content.zxnr}}</td>
      </tr>
      <tr>
        <td>{{tableData.ReplyContent != null ? '机构回复' : '平台回复'}}</td>
        <td colspan="2">
          <div>
            <div v-if="tableData.ReplyContent != null">
              {{ tableData.ReplyContent.data.dfyj }}
            </div>
            <div v-else-if="tableData.PlatformApply != null">
              {{tableData.PlatformApply}}
            </div>
            <div v-else>
              未回复
            </div>
          </div>
        </td>
        <td>回复时间</td>
        <td colspan="2">
          <div v-if="tableData.ReplyContent != null">
            <div>{{tableData.ReplyContent.data.jdrq}}</div>
          </div>
          <div v-else-if="tableData.PlatformApply != null">
            <div>{{tableData.PlatformApplyTime}}</div>
          </div>
          <div v-else>
            --
          </div>
        </td>
      </tr>
      </tbody>
      <!--重庆-->
      <tbody v-else="">
      <tr>
        <td>姓名</td>
        <td>{{tableData.Content.data.sqrxm}}</td>
        <td>性别</td>
        <td><span v-if="tableData.Content.data.sqrxb == 1">男</span><span v-if="tableData.Content.data.sqrxb == 2">女</span></td>
        <td>民族</td>
        <td v-if="tableData.SupplementContent.mz">{{tableData.SupplementContent.mz}}</td>
        <td v-else="">{{tableData.Content.data.sqrmz}}</td>
      </tr>
      <tr>
        <td>身份证号码</td>
        <td>{{tableData.Content.data.sqrsfzh}}</td>
        <td>地点</td>
        <td>{{tableData.Content.data.sqrdz}}</td>
        <td>联系方式</td>
        <td>{{tableData.Content.data.sqrsjhm}}</td>
      </tr>
      <tr>
        <td>出生日期</td>
        <td>{{tableData.Content.data.sqrcsrq}}</td>
        <td>纠纷类型</td>
        <td>{{tableData.Content.data.jflx}}</td>
        <td>申请时间</td>
        <td>{{tableData.CreateTime}}</td>
      </tr>
      <!--<tr>-->
      <!--<td>身份证明</td>-->
      <!--<td colspan="5">-->
      <!--<a :href="tableData.Content.sfcl" target="blank"><img height="140px" :src="tableData.Content.sfcl" style="border:5px solid #e5e5e5;"/></a>-->
      <!--<span style="padding:5px;"></span>-->
      <!--<a :href="tableData.Content.sfclmc" target="blank"><img height="140px" :src="tableData.Content.sfclmc" style="border:5px solid #e5e5e5;"/></a>-->
      <!--</td>-->
      <!--</tr>-->
      <tr>
        <td>纠纷简介</td>
        <td colspan="5">
          <div style="min-height:240px;">
            {{tableData.Content.data.jfjj}}
          </div>
        </td>
      </tr>
      <tr>
        <td>调解单位</td>
        <td colspan="5">{{tableData.SupplementContent.twhmc}}</td>
      </tr>
      <tr>
        <td>调解员</td>
        <td colspan="5">{{tableData.SupplementContent.fzr}}</td>
      </tr>
      </tbody>
    </table>
    <div slot="footer">
      <Button type="warning" long @click="ownState=false"><Icon type="ios-alert-outline"/> 关闭</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name:'legalaidModal',
  props:{
    tableData:{
      type:Object,
      default:()=>{return {}},
    },
    state:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    state:function(val){
      this.ownState = val
    },
    ownState:function(val){
      this.$emit('on-close',val)
    },
  },
  data() {
    return{
      ownState:false
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>
