import nullPage from '@/views/nullPage'

const account = {
  admin:require('@/views/manager/account/adminList').default,
  //admin:require('@/views/lawAdmin/totalAdmin').default,
}

const routes = [
  {
    path:'account',
    component:nullPage,
    children:[
      {path:'admin',component:account.admin,name:'MAAL',meta:{title:'律蛙数字法治-智慧司法平台-管理员账户列表'},},
    ]
  },
]

export default {
  routes:routes,
}
