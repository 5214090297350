<template>
  <div>
    <div class="screen">
      <div style="background-color: white;">
        <div style="padding:15px"></div>
        <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 运行报告</div>
        <div style="padding:15px"></div>
        <Button type="primary" @click="showExport = true" style="margin: 0 0 30px 30px">生成运行报告</Button>
        <div class="publicWelfare" >
          <table class="formtable">
            <thead>
            <tr>
              <th>序号</th>
              <th>文件名</th>
              <th>运行报告时间段</th>
              <th>生成时间</th>
              <th>生成状态</th>
              <th>下载状态</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(value,index) in reportData.data">
              <td>{{index+1}}</td>
              <td>{{value.name}}</td>
              <td>{{value.range}}</td>
              <td>{{value.create_time}}</td>
              <td>
                <template v-if="value.status == 1"><Tag color="orange">生成中</Tag></template>
                <template v-if="value.status == 2"><Tag color="green">已生成</Tag></template>
                <template v-if="value.status == 3"><Tag color="red">生成失败</Tag></template>
              </td>
              <td>
                <template v-if="value.is_download == 1"><Tag color="orange">未下载</Tag></template>
                <template v-else><Tag color="green">已下载</Tag></template>
              </td>
              <td>
                <Button type="primary" @click="downloadFiles(value)" :disabled="value.status == 1">下载</Button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Modal v-model="showExport" title="生成报告 " @on-ok="exportRunReport" ok-text="生成报告" style="height: 300px">
      <DatePicker :value="timeData" v-model="timeData" :transfer="true" type="daterange" placement="bottom-end"
                  placeholder="选择时间" style="width: 200px" @on-change="totalTime"></DatePicker>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "runReport",
  data(){
    return{
      showExport: false,
      timeData: '',
      start: '',
      end: '',
      polling: null,
      reportData: {
        data: [],
      }
    }
  },
  methods:{
    // 选择时间
    totalTime: function (e) {
      var _this = this
      _this.start = e[0],//开始时间
          _this.end = e[1]//结束时间
    },
    // 获取运行报告列表
    reportList: function (){
      var _this = this
      _this.send({
        url : '/reportList',
        data: {
          AdminId : window.sessionStorage.AdminId,
        },
        success: function (res) {
          _this.reportData.data = res
          var successCount = 0;
          for(var i in res){
            if(res[i].status != 1){
              successCount++;
              if(successCount === res.length){
                _this.reportCount()
                window.clearInterval(_this.polling)
              }
            }
          }
        }
      })
    },
  //   点击导出运行报告
    exportRunReport: function (){
      var _this = this
      if (SYSCONFIG.location == 'chong-qing') {
        var url = '/exportRunReport'
      } else {
        var url = '/otherExportRunReport'
      }
      _this.send({
        url : url,
        data: {
          start:_this.start,
          end:_this.end,
          AdminId : window.sessionStorage.AdminId,
        },
        success: function (res) {
          window.clearInterval(_this.polling)
          _this.polling = setInterval(()=>{_this.reportList()},5000)
          _this.$Message.warning('后台生成中');
        }
      })
    },
  //   未下载数量
    reportCount: function (){
      var _this = this
      _this.send({
        url : '/reportCount',
        data: {
          AdminId : window.sessionStorage.AdminId,
        },
        success: function (res) {
          _this.resetSetItem('printReportShowQuery',  JSON.stringify({pollingCount:res}));
        }
      })
    },
  //  下载文件
    downloadFiles: function (value){
      var _this = this
      window.open(_this.assetsLink+value.path)
      _this.send({
        url : '/reportDownload',
        data: {
          AdminId : window.sessionStorage.AdminId,
          id: value.id
        },
        success: function (res) {
          _this.reportList()
        }
      })
    },
  },
  mounted() {
    var _this = this
    // this.reportList()
    this.reportCount()
    _this.send({
      url : '/reportList',
      data: {
        AdminId : window.sessionStorage.AdminId,
      },
      success: function (res) {
        _this.reportData.data = res
        for(var i in res){
          // 判断在生成中循环
          if(res[i].status != 2){
            _this.polling = setInterval(()=>{_this.reportList()},5000)
            break;
          }
        }
      }
    })
  },
  computed:{
    assetsLink:function(){
      return `${SYSCONFIG.server.default.ssl?'https':'http'}://${SYSCONFIG.server.default.host}:${SYSCONFIG.server.default.port}/`
    }
  },
  beforeDestroy() {
    // 在页面销毁前关闭定时器
    window.clearInterval(this.polling)
  }
}
</script>

<style scoped>
.screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
/*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
.screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
.screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
.screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
.publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
.formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
.publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
.publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
.details p{color: #000000}
.details span{color: #5c6b77}
.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

</style>
