<style scoped>
  .tableTitle{font-size:20px;}
  .export-btn{font-size:10px;color:#2d8cf0;cursor:pointer;}
</style>

<template>
  <div>
    <div style="padding:10px"></div>
    <div style="text-align: center;">
      <div class="tableTitle">区域总统计</div>
      <span class="export-btn" @click="exportData(table.data)">[导出数据]</span>
    </div>
    <div style="padding:5px"></div>
    <Table height="500" border :loading="table.loading" :columns="table.columns" :data="table.data" ref="table" :resizable="true" @on-row-click="lowerLevel" show-summary :summary-method="handleSummary"></Table>
    <div style="padding:20px"></div>
<!--    查看下级数据-->
    <Modal
        v-model="modal1"
        title="区域总统计" @on-cancel="cancel" width="1200">
      <div slot="footer">
        <template>
          <Button
              size="large"
              @click="cancel"
          >关闭</Button>
        </template>
      </div>
      <div>
        <div style="text-align: center;">
          <div class="tableTitle">区域总统计</div>
          <span class="export-btn" @click="exportData(table1.data)">[导出数据]</span>
        </div>
        <div style="padding:5px"></div>
        <Table v-if="modal1" height="550" border :loading="table1.loading" :columns="table1.columns" :data="table1.data" ref="table1" :resizable="true" show-summary :summary-method="handleSummary"></Table>
        <div style="padding:20px"></div>
      </div>
    </Modal>
  </div>
</template>

<script>
//表头控制文件
import * as tableColumns from "./table-columns"
import * as tableDataSorting from "./data-sorting"
export default {
  name:'area-table',//区域总统计
  props:{
    dateSelecter:{type:Object,required:true,},
    unitData:{type:String,required:true,},
  },
  data:function(){
    return{
      table:{
        loading:false,
        columns:tableColumns.areaTable,
        data:[],
      },
      table1:{
        loading:false,
        columns:tableColumns.areaTable,
        data:[],
        areaName : '',
      },
      modal1 : false
    }
  },
  watch:{
    'dateSelecter.identification':function(val){
      if(val != 'custom'){
        this.getTableData()
      }
    },
    'unitData':function(val){
      this.getTableData()
    }
  },
  mounted:function(){
    this.getTableData()
  },
  methods:{
    handleSummary ({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[key] = {
            key,
            value: v + ''
          };
        } else {
          if(this.modal1){
            sums[key] = {
              key,
              value: this.table1.areaName
            };
          }else {
            sums[key] = {
              key,
              value: window.sessionStorage.AdminName
            };
          }
        }
      });

      return sums;
    },
    exportData : function (data){
      var _this = this
      this.$refs.table.exportCsv({
        filename: '区域总统计',
        columns : _this.table.columns,
        data : data
      });
    },
    lowerLevel : function (data,index){
      console.log(data)
      // console.log(this.dateSelecter)
      if(window.sessionStorage.Hierarchy == 2 || window.sessionStorage.Hierarchy == 1){
        this.table1.data = []
        this.table1.areaName = data.areaName
        this.table.loading = false
        this.dateSelecter.AdminId = data.adminId
        this.modal1 = true
        this.getTableData()
      }
    },
    // 弹窗取消
    cancel : function (){
      this.dateSelecter.AdminId = ''
      this.modal1 = false
    },
    //区域咨询申办统计表
    getTableData:function(){
      var _this = this
      if(this.modal1 == false){_this.table.loading = true}
      _this.table1.loading = true
      // _this.table.data = []
      //判断司法所
      if(_this.unitData == 'county'){
        if(SYSCONFIG.location == 'chong-qing'){
          if(window.sessionStorage.Hierarchy == 4){
            var Data = ['/ReportVillageLawyerData',//律师数据
              '/ReportVillageUserData',//用户数据
              '/ReportVillageOrderData',//服务数据
              '/ReportVillageFileData',//档案数据
              '/ReportVillageEvaluateData',//评价数据
              '/ReportFileServiceDataByVillage',//服务时长
            ]
          }else{
            var Data = [
              '/ReportLawyerData',//律师数据
              '/ReportUserData',//用户数据
              '/ReportOrderData',//服务数据
              '/ReportFileData',//档案数据
              '/ReportEvaluateData',//评价数据
              '/ReportFileServiceData',//服务时长
            ]
          }
        }else {
          if(window.sessionStorage.Hierarchy == 4){
            var Data = ['/CDReportVillageLawyerData',//律师数据
              '/CDReportVillageUserData',//用户数据
              '/CDReportVillageOrderData',//服务数据
              '/CDReportVillageFileData',//档案数据
              '/CDReportVillageEvaluateData',//评价数据
              '/CDReportFileServiceDataByVillage',//服务时长
            ]
          }else{
            var Data = [
              '/CDReportLawyerData',//律师数据
              '/CDReportUserData',//用户数据
              '/CDReportOrderData',//服务数据
              '/CDReportFileData',//档案数据
              '/CDReportEvaluateData',//评价数据
              '/CDReportFileServiceData',//服务时长
            ]
          }
        }
      }else {
        var Data = [
          '/streetLawyerData',//律师数据
          '/streetUserData',//用户数据
          '/StreetOrderData',//服务数据
          '/streetFileData',//档案数据
          '/streetEvaluateData',//评价数据
          '/streetServiceData',//服务时长
        ]
      }


      _this.$parent.requestHub(Data).then(res=>{
        if(_this.unitData == 'street'){
          var modifyData = []
          for(var i in res){
            modifyData[i] = []
            console.log(modifyData[i],'AA')
            for(var j in res[i]){
              modifyData[i].push({
                AdminId:res[i][j].admin.AdminId,
                AdminName:res[i][j].admin.parent.AreaShortName+res[i][j].admin.AdminName,
                Count:{Intime:res[i][j].Intime,Total:res[i][j].Total},
                Level: 3,
                LevelPid: 166,
              })
            }
          }
          res = modifyData
        }
        console.log(res)
        if(this.modal1 == false){_this.table.loading = false}
        _this.table1.loading = false
        var data = tableDataSorting.dataMerge(res,{local:'adminId',remote:'AdminId'},[
          {columnName:'areaName',dataIndex:0,remoteVariable:['AreaShortName||AdminName']},
          {columnName:'newLawyer',dataIndex:0,remoteVariable:['Count','Intime']},
          {columnName:'totalLawyer',dataIndex:0,remoteVariable:['Count','Total']},
          {columnName:'newUser',dataIndex:1,remoteVariable:['Count','Intime']},
          {columnName:'totalUser',dataIndex:1,remoteVariable:['Count','Total']},
          {columnName:'newService',dataIndex:2,remoteVariable:['Count','Intime']},
          {columnName:'totalService',dataIndex:2,remoteVariable:['Count','Total']},
          {columnName:'newArchives',dataIndex:3,remoteVariable:['Count','Intime']},
          {columnName:'totalArchives',dataIndex:3,remoteVariable:['Count','Total']},
          {columnName:'newEvaluate',dataIndex:4,remoteVariable:['Count','Intime']},
          {columnName:'totalEvaluate',dataIndex:4,remoteVariable:['Count','Total']},
          {columnName:'newServiceDuration',dataIndex:5,remoteVariable:['Count','Intime']},
          {columnName:'totalServiceDuration',dataIndex:5,remoteVariable:['Count','Total']},
        ])
        if(_this.dateSelecter.AdminId == undefined || _this.dateSelecter.AdminId == ''){
          _this.table.data = data
        }else {
          _this.table1.data = data
        }

      })
          //.catch(err=>{console.log("区域总统计：失败！")})
    },
  },
}
</script>
