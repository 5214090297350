<template>
  <div style="padding: 20px">
    <div style="background-color: white">
      <div style="font-size: 16px; text-align: left; border-left: solid #de4f0b 3px; padding-left: 5px">
        小程序数据管理
        <div style="display: inline-block; vertical-align: middle; margin-left: 10px">
          <span style="margin-left: 30px">共{{adminUserData.length}}条</span>
        </div>
        <Button type="primary" style="margin-left: 20px" @click="binding();">绑定</Button>
      </div>
      <div style="padding: 10px"></div>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>姓名</th>
            <th>手机号码</th>
<!--            <th>所在村（社区）</th>-->
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in adminUserData">
            <td>{{value.UserName}}</td>
            <td>{{value.UserPhone||'--'}}</td>
<!--            <td>{{ value.village.CityName.replace('重庆市', '') }}{{ value.village._AdminName }}{{ value.village.VillagesName }}</td>-->
            <td><Button type="primary" style="margin-left: 20px" @click="deleteClick(value.id);">删除</Button></td>
          </tr>
          </tbody>
        </table>
<!--        <Page-->
<!--            :total="count"-->
<!--            :page-size="pageSize"-->
<!--            show-elevator-->
<!--            show-total-->
<!--            @on-change="-->
<!--                        page = $event-->
<!--                        adminUserList(2)-->
<!--                    "-->
<!--        />-->
      </div>
    </div>
    <Modal :draggable="true" v-model="areaSPCPanel.state" title="绑定" @on-ok="" width="470">
      <div>第一步：微信扫描太阳码进入程序</div>
      <div>第二步：点击我的-->数据统计查看</div>
      <div class="publicWelfare">
        <img :src="areaSPCPanel.imgSrc">
      </div>
    </Modal>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "backstageAdmin",
  data() {
    return {
      areaSPCPanel:{
        state:false,
        imgSrc:'',
      },
      information : [],
      adminUserData : [],
    }
  },
  mounted() {
    this.information = window.sessionStorage
    this.adminUserList()
  },
  methods: {
    deleteClick: function (id) {
      var _this = this
      _this.send({
        confirm: true,
        url: '/delBindingUser',
        data: {
          AdminId: _this.information.AdminId,
          id: id,
        },
        success: function (res) {
          _this.adminUserList()
        }
      })
    },
    adminUserList : function (){
      var _this = this
      _this.send({
        url: '/bindingUserList',
        data: {
          AdminId: window.sessionStorage.AdminId,
        },
        success: function (res) {
            _this.adminUserData = res
        }
      })
    },
    binding : function (){
        var _this = this
      if(SYSCONFIG.location == 'chong-qing'){
        var scene ="bingdingAdmin-"+window.sessionStorage.AdminId
        var AreaId = 2236
        var url = '/getVillageSunCode'
      }else{
        var scene =  "bingdingAdmin-"+window.sessionStorage.AdminId
        var url = '/CD_getVillageSunCode'
      }
      _this.areaSPCPanel.state = false
      _this.areaSPCPanel.imgSrc = ''
      _this.send({
        url:url,
        consulting1:true,
        responseType:"arraybuffer",
        data: {
          scene:scene,
          width: '430',
          jpg: 'jpg',
          page:'public/scanIn/scanIn',
          AreaId: AreaId
        },
        success:function(res){
          _this.areaSPCPanel.state = true
          _this.areaSPCPanel.imgSrc = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
        }
      })
    },
  },
}
</script>


<style scoped>
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>
