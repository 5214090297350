<template>
  <div>
    <Table :columns="columns11" :data="lawyerList" border >
      <template slot-scope="{ row, index }">
        <span>{{ (currentPageName - 1) * pageSize + index + 1 }}</span>
      </template>
    </Table>
    <div class="publicWelfare">
<!--      <table class="formtable">-->
<!--        <thead>-->
<!--        <tr>-->
<!--          <th>序号</th>-->
<!--          <th>姓名</th>-->
<!--          <th>群众评价</th>-->
<!--          <th>每日登录</th>-->
<!--          <th>提供法律咨询、法治宣传、法律服务、矛盾纠纷调处</th>-->
<!--          <th>参加会议、对重大事务进行法治审查</th>-->
<!--          <th>坐班服务</th>-->
<!--          <th>法治讲座</th>-->
<!--          <th>法律服务进村(社区)</th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--        <tr v-for="(value, index) in lawyerList">-->
<!--          <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>-->
<!--          <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>-->
<!--          <td><Avatar :src="value.LawyerImg" style="margin-right: 15px" />{{ value.LawyerName }}</td>-->

<!--          <td>{{ value.score.argScore }}</td>&lt;!&ndash;群众评价&ndash;&gt;-->
<!--          <td>{{ value.score.loginScore }}</td>&lt;!&ndash;每日登录&ndash;&gt;-->
<!--          <td>{{ value.score.fileScore}}</td>&lt;!&ndash;提供法律咨询、法治宣传、法律服务、矛盾纠纷调处&ndash;&gt;-->
<!--          <td>{{value.score.lawScore}}</td>&lt;!&ndash;参加会议、对重大事务进行法治审查&ndash;&gt;-->
<!--          <td>{{ value.score.clockScore}}</td>&lt;!&ndash;坐班服务&ndash;&gt;-->
<!--          <td>{{ value.score.courseScore}}</td>&lt;!&ndash;法治讲座&ndash;&gt;-->
<!--          <td>{{ value.score.serverTotal}}</td>&lt;!&ndash;法律服务进村(社区)&ndash;&gt;-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </table>-->
      <Page
          :total="totalName"
          show-total
          :page-size="pageSize"
          show-elevator
          @on-change="
                    currentPageName = $event
                    serachLawyer()
                "
          :current="currentPageName"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "lawyerScoreList",
  data() {
    return {
      lawyerList: [],
      pageSize: 15,
      totalName: 0,
      currentPageName: 1,
      page: 1,
      columns11: [
        {
          title: '序号',
          key: 'index',
          align: 'center',
          render: (h, params) => {
            const index = (this.currentPageName - 1) * this.pageSize + params.index + 1;
            console.log(index);
            return h('span', index); // 使用 h() 创建一个 span 元素来渲染序号
          }
        },
        { title: '姓名', key: 'LawyerName' },
        {
          title: '基层干部与群众评价',
          align: 'center',
          children: [
            {
              title: '群众评价',
              key: 'argScore',
              align: 'center',
            },
            {
              title: '干部评价',
              key: 'caderScore',
              align: 'center',
            },
            {
              title: '司法所评价',
              key: 'placeScore',
              align: 'center',
            }
          ]
        },
        {
          title: '工作实绩考核',
          align: 'center',
          children: [
            {
              title: '每日登录',
              key: 'loginScore',
              align: 'center',
            },
            {
              title: '提供法律咨询、法治宣传、法律服务、矛盾纠纷调处',
              key: 'fileScore',
              align: 'center',
            },
            {
              title: '参加会议、对重大事务进行法治审查',
              key: 'lawScore',
              align: 'center',
            },
            {
              title: '坐班服务',
              key: 'clockScore',
              align: 'center',
            },
            {
              title: '法治讲座',
              key: 'courseScore',
              align: 'center',
            },
            {
              title: '法律服务进村(社区)',
              key: 'serverTotal',
              align: 'center',
            },
          ]
        },
      ],
    }
  },
  mounted() {
    this.serachLawyer()
  },
  methods:{
    serachLawyer: function () {
      var _this = this
      _this.send({
        url: '/lawyerScoreList',
        loading: true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          key: _this.key,
          page: _this.currentPageName,
          pageSize: _this.pageSize
        },
        success: function (res) {
          var data = []
          for(var i in res.data){
            const { score, ...rest } = res.data[i];
            const transformed = { ...rest, ...score };
            data.push(transformed)
          }
          _this.lawyerList = data
          console.log(_this.lawyerList)
          _this.totalName = res.total
        }
      })
    },
  }
}
</script>

<style scoped>
.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
}
.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
  text-align: center;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
}
</style>
