<style scoped>
  .content{padding:20px;}
  .list-item{border:1px solid #e5e5e5;padding:10px;margin-bottom:20px;transition:0.1s;position: relative;}
  .list-item:hover{box-shadow:rgba(0,0,0,0.3) 0px 0px 8px -3px;transform:scale(101%);}
  .list-item:hover .list-more-btn{opacity:1;}
  .list-title{font-size:14px;font-weight: bold;}
  .list-icon{width:20px;height:20px;border-radius:10px;vertical-align:middle;margin-right:6px;}
  .list-content-text{font-size:13px;color:#5e5e5e;}
  .list-more-btn{width:120px;margin-left:-60px;height:25px;line-height:25px;position: absolute;left:50%;bottom:-15px;cursor:pointer;padding:0 10px;background-color:rgb(232 67 53);color:#fff;text-align:center;opacity:0;transition:0.5s;}

</style>

<template>
  <div class="content">
    <dataModal :tableData="dataPanel.data" :state="dataPanel.state" @on-close="dataPanel.state = $event"></dataModal>
    <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px">人民调解申请数据</div>
    <div style="padding:10px;"></div>
    <ul>
      <li v-for="(item,key) in list" class="list-item">
        <Row :gutter="16" class="list-title">
          <Col span="8">
            <img :src="item.UserImg" class="list-icon"/>
            <span style="vertical-align:middle;">用户：{{item.UserName}}</span>
          </Col>
          <Col span="8">所属村（社区）：{{item.CityName}}{{item.AdminName}}{{item.VillagesName}}</Col>
          <Col span="8">村（社区）律师：{{item.LawyerName}}</Col>
        </Row>
        <div v-if="item.SupplementContent == null">
          <Divider style="margin:7px 0;"/>
          <Row :gutter="16" class="list-content-text">
            <Col span="8">姓名：{{item.Content.applyName}}</Col>
            <Col span="8">联系方式：{{item.Content.phone}}</Col>
            <Col span="8">申请时间：{{item.CreateTime}}</Col>
          </Row>
          <div style="padding:3px;"></div>
          <Row :gutter="16" class="list-content-text">
            <Col span="8">调解类型：{{item.Content.applyType}}</Col>
            <Col span="8">受理单位：{{item.Content.hui}}</Col>
            <Col span="8">调解员：{{item.Content.ren}}</Col>
          </Row>
        </div>
        <div v-else="">
          <Divider style="margin:7px 0;"/>
          <Row :gutter="16" class="list-content-text">
            <Col span="8">姓名：{{item.Content.data.sqrxm}}</Col>
            <Col span="8">联系方式：{{item.Content.data.sqrsjhm}}</Col>
            <Col span="8">申请时间：{{item.CreateTime}}</Col>
          </Row>
          <div style="padding:3px;"></div>
          <Row :gutter="16" class="list-content-text">
            <Col span="8">调解类型：{{item.SupplementContent.jflx}}</Col>
            <Col span="8">受理单位：{{item.SupplementContent.twhmc}}</Col>
            <Col span="8">调解员：{{item.SupplementContent.fzr}}</Col>
          </Row>
        </div>
        <div class="list-more-btn" @click="dataPanel.data = item,dataPanel.state = true">
          更多信息 <Icon type="ios-arrow-dropdown" />
        </div>
      </li>
    </ul>
    <Page
      :total="pageBar.total"
      :current="pageBar.current"
      :page-size="pageBar.pageSize"
      :page-size-opts="[10,15,20,30,50]"
      @on-change="getList"
      @on-page-size-change="pageBar.pageSize=$event,getList()"
      show-elevator show-total show-sizer
    />
  </div>
</template>

<script>
import dataModal from "./modules/data-modal"
export default {
  name:'list',
  data() {
    return {
      list:[],
      pageBar:{
        total:0,
        pageSize:10,
      },
      dataPanel:{
        state:false,
        data:{},
      },
    };
  },
  components:{dataModal},
  mounted() {
    this.getList()
  },
  methods: {
    getList:function(currentPage){
      var _this = this
      _this.send({
        url:'/AdminMediateList',
        data:{
          AdminId:window.sessionStorage.AdminId,
          page : currentPage||1,
          pageSize : _this.pageBar.pageSize,
        },
        success:function(res) {
          _this.list = res.List
          _this.pageBar.total = res.Count
        }
      })
    },
  },
}
</script>
