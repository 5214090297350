<style scoped>
  .header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
  .menu{font-size:0.24rem;padding:0.3rem 0.3rem;}
  .list{padding:0.2rem 0;}
  .colour{}
</style>

<template>
  <div style="background-color:#f8f9fb;">
    <Modal
            v-model="detialPanel.state"
            title="申请详情"
            ok-text="关闭"
            cancel-text
    >
      <template v-if="detialPanel.data.Content">
<!--        <div>来源：{{detialPanel.data.Content.data.ly}}</div>-->
        <div>调解单位：<span class="colour">{{detialPanel.data.Content.data.twhmc}}</span></div>
        <div>申请人姓名：<span class="colour">{{detialPanel.data.Content.data.sqrxm}}</span></div>
        <div>申请人性别：<span class="colour" v-if="detialPanel.data.Content.data.sqrxb == 1">男</span><span class="colour" v-if="detialPanel.data.Content.data.sqrxb == 2">女</span></div>
        <div>申请人身份证号：<span class="colour">{{detialPanel.data.Content.data.sqrsfzh}}</span></div>
        <div>申请人民族：<span class="colour">{{detialPanel.data.Content.data.sqrmz}}</span></div>
        <div>申请人出生日期：<span class="colour">{{detialPanel.data.Content.data.sqrcsrq}}</span></div>
        <div>申请人地址：<span class="colour">{{detialPanel.data.Content.data.sqrdz}}</span></div>
        <div>申请人手机号码：<span class="colour">{{detialPanel.data.Content.data.sqrsjhm}}</span></div>
        <div>被申请人姓名：<span class="colour">{{detialPanel.data.Content.data.bsqrxm}}</span></div>
        <div>被申请人性别：<span v-if="detialPanel.data.Content.data.bsqrxb == 1">男</span><span v-if="detialPanel.data.Content.data.bsqrxb == 2">女</span></div>
        <div>被申请人身份证号：<span class="colour">{{detialPanel.data.Content.data.bsqrsfzh}}</span></div>
        <div>被申请人民族：<span class="colour">{{detialPanel.data.Content.data.bsqrmz}}</span></div>
        <div>被申请人出生日期：<span class="colour">{{detialPanel.data.Content.data.bsqrcsrq}}</span></div>
        <div>被申请人地址：<span class="colour">{{detialPanel.data.Content.data.bsqrdz}}</span></div>
        <div>被申请人手机号码：<span class="colour">{{detialPanel.data.Content.data.bsqrsjhm}}</span></div>
        <div>纠纷简介：<span class="colour">{{detialPanel.data.Content.data.jfjj}}</span></div>
        <div>纠纷类型：<span class="colour">{{detialPanel.data.Content.data.jflx}}</span></div>
        <div>预约时间起：<span class="colour">{{detialPanel.data.Content.data.yysjq}}</span></div>
        <div>预约时间止：<span class="colour">{{detialPanel.data.Content.data.yysjz}}</span></div>
      </template>
    </Modal>
    <div class='header'></div>
    <div class='body'>


<!--      <div style="text-align: center">-->
<!--        <div @click="modal = true" style="margin-top:0.3rem;line-height: 1rem;display:inline-block;width: 6.6rem;height: 1rem;background: #08C47B;border-radius: 0.04rem;color: #FFFFFF;font-size: 0.3rem;font-weight: bold;">-->
<!--          <img src="/img/legalAid/shenqing.png" style="width: 0.32rem;height: 0.36rem;vertical-align: top;margin: 0.3rem 0.1rem 0 0">-->
<!--          <div style="display: inline-block">去申请</div>-->
<!--        </div>-->
<!--      </div>-->

      <div v-if="list.length>0" style="text-align: center">
        <div style="margin-top:0.3rem;line-height: 1rem;display:inline-block;width: 6.6rem;height: 1rem;background: #008FD6;border-radius: 0.04rem 0.04rem 0 0;color: #FFFFFF;font-size: 0.3rem;font-weight: bold;text-align: left">
          <img src="/img/legalAid/shenqingjilu.png" style="width: 0.32rem;height: 0.36rem;vertical-align: top;margin: 0.32rem 0.1rem 0 0.3rem">
          <div style="display: inline-block">申请记录</div>
        </div>
        <div style="text-align: center;margin-bottom: 0.3rem" v-for="(item,index) in list">
          <div style="width: 6.6rem;height: 1rem;background: #008FD6;border-radius: 0.04rem 0.04rem 0rem 0rem;line-height: 1rem;
color: #FFFFFF;font-size: 0.28rem;display: inline-block;text-align: left;">
            <div style="width: 0.6rem;height: 0.6rem;background: #FFFFFF;border-radius: 50%;display: inline-block;color: #008FD6;text-align: center;line-height: 0.6rem;margin-left: 0.3rem">
              <span v-if="index+1<=9">0{{index+1}}</span>
              <span v-else>{{index+1}}</span>
            </div>
            <div style="float: right" @click="getDetail(item)">
              <div style="display: inline-block">查看详情</div>
              <img src="/img/legalAid/youbian.png" style="width: 0.2rem;height: 0.2rem;margin: 0 0.2rem 0 0.1rem">
            </div>
          </div>
          <div style="text-align: center;background-color: #FFF;width: 6.6rem;display: inline-block;box-shadow: 0px 5px 8px 0px rgba(161, 159, 158, 0.29);border-radius: 0.06rem;">
            <div style="border-bottom: 0.01rem #DDDDDD solid;font-size: 0.28rem;color: #999999;height: 0.86rem;line-height: 0.86rem;text-align: left;display: inline-block;width: 6.60rem">
              <div style="display: inline-block;margin-left: 0.3rem">申请人：</div>
              <div style="display: inline-block;color: #333333">{{item.Content.data.sqrxm}}</div>
            </div>
            <div style="border-bottom: 0.01rem #DDDDDD solid;font-size: 0.28rem;color: #999999;height: 0.86rem;line-height: 0.86rem;text-align: left;display: inline-block;width: 6.60rem">
              <div style="display: inline-block;margin-left: 0.3rem">申请时间：</div>
              <div style="display: inline-block;color: #333333">{{item.CreateTime}}</div>
            </div>
            <div style="border-bottom: 0.01rem #DDDDDD solid;font-size: 0.28rem;color: #999999;height: 0.86rem;line-height: 0.86rem;text-align: left;display: inline-block;width: 6.60rem">
              <div style="display: inline-block;margin-left: 0.3rem">调解类型：</div>
              <div style="display: inline-block;color: #333333">{{item.Content.data.jflx}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="background-image:url(/img/legalAid/empty1.png);height:4.92rem;width:6.9rem;background-size:100% 100%;margin:1.1rem auto 0;">
        <!--                          <p style="color: #ababab;text-align: center;padding-top: 3.7rem;font-size: 0.30rem;">未申请</p>-->
      </div>
      <div style="padding: 0.2rem"></div>
<!--      <ul v-if="list.length>0">-->
<!--        <li v-for="(item,index) in list" :style="index%2==0?'background-color: #fff;':''" class='list'>-->
<!--          <Row type="flex" align="middle" style="font-size:0.2rem;padding:0.1rem 0.3rem;">-->
<!--            <Col span="20">-->
<!--              <Row>-->
<!--                <Col span="24" style="font-size: 0.28rem;font-weight: bold;padding-bottom:0.1rem"> 负责人：-->
<!--                  <span v-if="item.SupplementContent">{{item.SupplementContent.fzr}}</span>-->
<!--                  <span v-else>暂无</span>-->
<!--                </Col>-->
<!--                <Col span="24" style="color:#666666">-->
<!--                  申请人：{{item.Content.data.sqrxm}} | 申请时间：{{item.CreateTime}}-->
<!--                </Col>-->
<!--              </Row>-->
<!--            </Col>-->
<!--            <Col span="4" style="color:#008fd6"><span @click="getDetail(item)">查看详情 ></span></Col>-->
<!--          </Row>-->
<!--        </li>-->
<!--      </ul>-->
<!--      <div v-else style="background-image:url(/img/empty.png);height:4.95rem;width:6.9rem;background-size:100% 100%;margin:1.1rem auto;"></div>-->
      <div  v-if="pid == 1731" style="padding: 30px;font-size: 12px;line-height: 20px;">
        <div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">人民调解</div>
        <div style="text-indent: 2em;">人民调解是指人民调解委员会通过说服、疏导等方法，促使当事人在平等协商基础上自愿达成调解协议，解决民间纠纷的活动。人民调解委员会调解的民间纠纷包括：发生在公民与公民之间，公民与法人之间和其他社会组织之间涉及民事权利义务争议的各种纠纷。目前，我县共建立367个调解组织，其中乡镇调解委员会12个，村（社区）调委会290个企事业单位调委会51个，行业性专业性调委会14个。</div>
      </div>
    </div>
    <!--     电话 -->
    <div style="text-align: center;margin: 0.3rem 0">
      <div style="width: 6.90rem;height: 0.72rem;background: #FFFFFF;border: 0.01rem solid #D6DCE3;border-radius: 0.36rem;position: relative;display: inline-block;text-align: left;">
        <input v-model="key" placeholder="搜索关键词" placeholder-style="color:#CCD3DC;" style="width: 6.5rem;height: 0.72rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #000;line-height: 0.72rem;padding-left: 0.40rem;" />
        <img style="width:0.34rem;height:0.36rem;position: absolute;top: 0.18rem;right: 0.40rem;" src="https://ai-cdn.lvwa18.com/img/rusticate/notarizationSS.png"/>
      </div>
    </div>
    <div style="background:#FFF;margin-bottom:0.30rem;" v-for="(item,index) in hotlineData">
      <div style="margin:0 0.30rem;">
        <div style="font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #4D5357;line-height: 0.38rem;padding:0.30rem 0 0.26rem;;">
          <span style="color:#878D96;">办公地址：</span>
          {{item.twhmc}}
        </div>
        <div style="width: 6.90rem;height: 1px;background: #DDDDDD;"></div>
        <div style="margin:0.30rem 0 0 0">
          <div style="display:inline-block;">
            <div style="font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #878D96;line-height: 0.26rem;margin-bottom:0.20rem;">业务咨询电话</div>
            <div style="font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #4D5357;line-height: 0.26rem;margin-bottom:0.32rem;">{{item.lxdh}}</div>
          </div>
          <div style="float: right;display:inline-block;width: 1.80rem;height: 0.64rem;background: linear-gradient(90deg, #64C3FF, #3391FE);box-shadow: 0px 4rem 6rem 0px rgba(53,148,255,0.26);border-radius: 0.32rem;">
            <img style="width:0.32rem;height:0.32rem;margin:0.16rem 0.10rem 0.16rem 0.18rem;vertical-align: top;" src="https://ai-cdn.lvwa18.com/img/rusticate/notarizationDH.png"/>
            <span style="font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.64rem;" @click="dialTelephone(item.lxdh)">一键拨打</span>
          </div>
        </div>
      </div>
    </div>
    <!--      去申请-->
    <div style="height: 0.8rem"></div>
    <Row>
      <div @click="modal = true" ><Col span="24" style="text-align: center;background-color:#008fd6;height: 0.8rem;font-size: 0.26rem;line-height: 0.8rem;position: fixed;bottom: 0;width: 100%;">
        <span style="color: #FFF;">线上办理</span>
      </Col></div>
    </Row>
    <Modal
        v-model="modal"
        title="温馨提示"
        ok-text="继续" cancel-text="" :mask-closable="false"
        @on-cancel="cancelButton"  @on-ok="goApply">

      <p>1.人民调解是指人民调解委员会及人民调解员依照法律、政策及社会主义道德规范，对纠纷当事人进行说服规劝，促其彼此互谅互让，在自主自愿情况下达成协议，消除纷争的活动。</p>
      <p>2.如需获得人民调解服务，当事人可以向人民调解委员会申请调解;人民调解委员会也可以主动调解。当事人一方明确拒绝调解的，不得调解。</p>
      <p>3.人民调解申请，可向就近的人民调解组织现场申请，也可通过12348重庆法网(网站、公众号、APP)、重庆村居法律顾问小程序等网络渠道申请。</p>
      <p>4.申请人民调解，填写的信息必须真实、准确、完整，否则将导致信息传递失败和无法提供调解服务</p>

    </Modal>

    <!--      -->
    <Modal
        v-model="modal1"
        title="温馨提示" ok-text="确定" cancel-text="" :mask-closable="false"
        @on-ok="ok">
      <p>功能升级中</p>
    </Modal>
  </div>
</template>

<script>
  export default{
    name:'mediateList',
    data(){
      return{
        list:[],
        detialPanel:{
          state:false,
          data:{},
          result:{},
        },
        pid : 0,
        modal : false,
        modal1 : false,
        hotlineData: [],
        page : 1,
        key: '',
      }
    },
    mounted(){
      var _this = this
      SYSCONFIG.server.default.host = 'cq.lvwa18.com'
      SYSCONFIG.server.default.port = '8001'
      window.sessionStorage.url = 'https://cq.lvwa18.com:8001/'
      if(window.sessionStorage.userId == undefined){
        if( _this.$route.query.id){
          window.sessionStorage.userId = _this.$route.query.id
          _this.getList()
          window.sessionStorage.AreaId = _this.$route.query.AreaId
          window.sessionStorage.pid = _this.$route.query.pid
          window.sessionStorage.userphone = _this.$route.query.userphone
          window.sessionStorage.sourceFrom = _this.$route.query.sourceFrom || ''
          window.sessionStorage.Admin_id = _this.$route.query.Admin_id || ''
          window.sessionStorage.villagesid = _this.$route.query.villagesid || ''
          _this.pid =window.sessionStorage.pid
        }else{
          _this.$Modal.error({
            title:'系统通知',
            content:'未获取到用户信息！请重新进入！',
          })
        }
      }else{
        _this.getList()
        if( _this.$route.query.id){
          window.sessionStorage.userId = _this.$route.query.id
          window.sessionStorage.AreaId = _this.$route.query.AreaId
          window.sessionStorage.pid = _this.$route.query.pid
          window.sessionStorage.userphone = _this.$route.query.userphone
          window.sessionStorage.sourceFrom = _this.$route.query.sourceFrom || ''
          window.sessionStorage.Admin_id = _this.$route.query.Admin_id || ''
          window.sessionStorage.villagesid = _this.$route.query.villagesid || ''
          _this.pid =window.sessionStorage.pid
        }
      }
      _this.setPageSize()
      _this.aidInfo()
    },
    watch:{
      'key': function (val) {this.page=1;this.hotlineData=[];this.aidInfo()},
    },
    methods:{
      //   拨打电话
      dialTelephone: function (phoneNumber){
        window.location.href = 'tel:' + phoneNumber;
      },
      aidInfo: function (){
        var _this = this
        _this.send({
          url:'/CqTwhlist',
          data :{
            content:{
              limit: 20,
              page: _this.page,
              thirdFlow: "",
              baseCode:"twh",
              areaCode: '',
            },
            key: _this.key,
          },
          success:function(res){
            if(res != ''){
              for(var i in res){
                _this.hotlineData.push(res[i])
              }
            }else{
              // _this.hotlineData = ''
            }
          }
        })
      },
      cancelButton : function (){
        this.$router.go(-1)
      },
      getList:function(){
        var _this = this
        _this.send({
          consulting: true,
          url:'/mediateList',
          data:{
            UserId:window.sessionStorage.userId,
          },
          success:function(res){
            if(res){
              _this.list = res.List
            }
          }
        })
      },
      getDetail:function(item){
        var _this = this
        _this.detialPanel.state = true
        _this.detialPanel.data = item
        return
        _this.send({
          url:'/mediate_resAdmin',
          data:{
            UserId :window.sessionStorage.userId,
            MediateId :item.MediateId,
          },
          success:function(res){
            _this.detialPanel.state = true
            _this.detialPanel.data = item
            _this.detialPanel.result = res.data
          }
        })
      },
      setPageSize:function() {
        var wsw = document.body.clientWidth
        document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
      },

      goApply : function (){
        this.$router.push({name:'mediateRegion'})
      },
    },
    created() {
      let that = this;
      window.onscroll = function () {
        // scrollTop 滚动条滚动时，距离顶部的距离
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // windowHeight 可视区的高度
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        // scrollHeight 滚动条的总高度
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        // 滚动条到底部的条件
        if (scrollTop + windowHeight+5 >= scrollHeight) {
          // 加载数据
          that.page++
          that.aidInfo();
        }
      }
    },
  }
</script>
