<style scoped>
  table,tr,th,td{ border:1px solid #000000;padding:12px 10px;}
  *{font-family:SimSun;}
</style>

<template>
  <Modal v-model="ownState" width="750px" :closable="false" :styles="{top:'7%'}">
    <table style="border-collapse: collapse;border-spacing:0;" v-if="JSON.stringify(tableData)!='{}'">
      <thead>
        <tr>
          <td colspan="6" style="text-align: center;font-size:24px;font-weight:bold;">人民调解申请表</td>
        </tr>
      </thead>
      <tbody v-if="tableData.SupplementContent == null">
        <tr>
          <td>姓名</td>
          <td>{{tableData.Content.applyName}}</td>
          <td>性别</td>
          <td>{{tableData.Content.sex}}</td>
          <td>民族</td>
          <td>{{tableData.Content.nation}}</td>
        </tr>
        <tr>
          <td>身份证号码</td>
          <td>{{tableData.Content.idCard}}</td>
          <td>地点</td>
          <td>{{tableData.Content.orgCity}}{{tableData.Content.orgArea}}{{tableData.Content.orgZhen}}</td>
          <td>联系方式</td>
          <td>{{tableData.Content.phone}}</td>
        </tr>
        <tr>
          <td>出生日期</td>
          <td>{{tableData.Content.birthday}}</td>
          <td>纠纷类型</td>
          <td>{{tableData.Content.applyType}}</td>
          <td>申请时间</td>
          <td>{{tableData.CreateTime}}</td>
        </tr>
        <tr>
          <td>身份证明</td>
          <td colspan="5">
            <a v-if="tableData.Content.sfcl" :href="tableData.Content.sfcl" target="blank"><img height="140px" :src="tableData.Content.sfcl" style="border:5px solid #e5e5e5;"/></a>
            <span style="padding:5px;"></span>
            <div v-if="tableData.Content.idCardFrontUrl && tableData.Content.idCardBackUrl">
              <a :href="tableData.Content.idCardFrontUrl" target="blank"><img height="140px" :src="tableData.Content.idCardFrontUrl" style="border:5px solid #e5e5e5;"/></a>
              <span style="padding:5px;"></span>
              <a :href="tableData.Content.idCardBackUrl" target="blank"><img height="140px" :src="tableData.Content.idCardBackUrl" style="border:5px solid #e5e5e5;"/></a>
            </div>
<!--            <a :href="tableData.Content.sfclmc" target="blank"><img height="140px" :src="tableData.Content.sfclmc" style="border:5px solid #e5e5e5;"/></a>-->
          </td>
        </tr>
        <tr>
          <td>纠纷描述</td>
          <td colspan="5">
            <div style="min-height:240px;">
              {{tableData.Content.proposerExcuse}}
            </div>
          </td>
        </tr>
        <tr>
          <td>调解单位</td>
          <td colspan="5">{{tableData.Content.hui}}</td>
        </tr>
        <tr>
          <td>调解员</td>
          <td colspan="5">{{tableData.Content.ren}}</td>
        </tr>
        <tr v-if="tableData.refuse_msg">
          <td>驳回理由</td>
          <td colspan="5">{{tableData.refuse_msg || '未回复'}}</td>
        </tr>
        <tr v-else>
          <td>平台回复</td>
          <td colspan="5">{{tableData.replyMsg || '未回复'}}</td>
        </tr>
      </tbody>
      <!--重庆-->
      <tbody v-else="">
      <tr>
        <td>姓名</td>
        <td>{{tableData.Content.data.sqrxm}}</td>
        <td>性别</td>
        <td><span v-if="tableData.Content.data.sqrxb == 1">男</span><span v-if="tableData.Content.data.sqrxb == 2">女</span></td>
        <td>民族</td>
        <td v-if="tableData.SupplementContent.sqrmz">{{tableData.SupplementContent.sqrmz}}</td>
        <td v-else="">{{tableData.Content.data.sqrmz}}</td>
      </tr>
      <tr>
        <td>身份证号码</td>
        <td>{{tableData.Content.data.sqrsfzh}}</td>
        <td>地点</td>
        <td>{{tableData.Content.data.sqrdz}}</td>
        <td>联系方式</td>
        <td>{{tableData.Content.data.sqrsjhm}}</td>
      </tr>
      <tr>
        <td>出生日期</td>
        <td>{{tableData.Content.data.sqrcsrq}}</td>
        <td>纠纷类型</td>
        <td>{{tableData.SupplementContent.jflx}}</td>
        <td>申请时间</td>
        <td>{{tableData.CreateTime}}</td>
      </tr>
      <tr>
        <td>身份证明</td>
        <td colspan="5">
          <a :href="tableData.SupplementContent.sfcl" target="blank"><img height="140px" :src="tableData.SupplementContent.sfcl" style="border:5px solid #e5e5e5;"/></a>
<!--          <span style="padding:5px;"></span>-->
<!--          <a :href="tableData.SupplementContent.sfclmc" target="blank"><img height="140px" :src="tableData.SupplementContent.sfclmc" style="border:5px solid #e5e5e5;"/></a>-->
        </td>
      </tr>
      <tr>
        <td>纠纷简介</td>
        <td colspan="5">
          <div style="min-height:240px;">
            {{tableData.Content.data.jfjj}}
          </div>
        </td>
      </tr>
      <tr>
        <td>调解单位</td>
        <td colspan="5">{{tableData.SupplementContent.twhmc}}</td>
      </tr>
      <tr>
        <td>调解员</td>
        <td colspan="5">{{tableData.SupplementContent.fzr}}</td>
      </tr>
      <tr>
        <td>平台回复</td>
        <td colspan="5">{{tableData.replyMsg || '未回复'}}</td>
      </tr>
      </tbody>
    </table>
    <div slot="footer">
      <Button type="warning" long @click="ownState=false"><Icon type="ios-alert-outline"/> 关闭</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name:'data-model',
  props:{
    tableData:{
      type:Object,
      default:()=>{return {}},
    },
    state:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    state:function(val){
      this.ownState = val
    },
    ownState:function(val){
      this.$emit('on-close',val)
    },
  },
  data() {
    return{
      ownState:false
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>
