<style scoped>
  .bg{text-align: center;background-image: url(/static/customization/jian-yang/images/login-bg.png);position:fixed;top:0;bottom:0;left:0;right:0;background-size:cover;background-repeat: no-repeat;}
</style>

<template>
	<div class="bg">
    <img src="/img/logoGov.png" style="width:1.42rem;height:1.53rem;margin-top:0.84rem;margin-bottom:0.3rem;"/>
    <div style="font-size:0.42rem;color: #FFF;">律蛙数字法治-智慧司法平台</br>调解员登录</div>
    <div style="width: 5.6rem;margin: auto;margin-top:2rem;">
      <Input v-model="userName" prefix="md-contact" placeholder="请输入手机号码" />
      <div style="padding:0.2rem;"></div>
      <Input v-model="passwd" prefix="ios-key" placeholder="输入密码"/>
      <div style="padding:0.2rem;"></div>
      <Button type="primary" style="width:100%;" @click="login">登录</Button>
    </div>
  </div>
</template>

<script>
	export default{
		name:'login',
		data(){
			return{
				userName:'',
				passwd:'',
			}
		},
		mounted(){

		},
		methods:{
			login:function(){
        var _this = this
          _this.send({
              url:'/MediatorLogin',
              data:{
                MediatorPhone:_this.userName,
                MediatorPassword:_this.passwd,
              },
              success:function (res) {
                _this.$store.state.mediatorInfo.id = res.MediatorId
                _this.$store.state.mediatorInfo.name = res.MediatorName
                _this.$router.push({name:'CJYMML'})
              }
          })
      },
		},
	}
</script>
