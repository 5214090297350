<template>
    <div>
        <Tabs :value="tabsType" @on-click="switchClick" v-if="cq != ''">
            <TabPane label="顾问管理" name="1"></TabPane>
            <!--            <TabPane label="社区公证员" name="2"></TabPane>-->
            <!--            <TabPane label="法律明白人" name="3"></TabPane>-->
        </Tabs>
        <div class="publicWelfare" v-if="tabsType == 1">
            <Row style="margin: 20px">
                <Col span="12">
                <Input v-model="key" search enter-button="搜索" placeholder="搜索顾问姓名/执业机构/村（社区）"
                    @on-search="serachLawyer" />
                </Col>
                <Col span="12">
                <Button type="primary" @click="
                    cqCommunity()
                modal = true
                    " style="margin-left: 30px">添加</Button>
                </Col>
            </Row>
            <!---->

            <!---->
            <table class="formtable">
                <thead>
                    <tr>
                        <th>序号</th>
                        <th>姓名</th>
                        <th>移动电话</th>
                        <th>执业机构</th>
                        <th>执业证号</th>
                        <th>擅长领域</th>
                        <th>服务起始时间</th>
                        <th>服务终止时间</th>
                        <!--          <th>类别</th>-->
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, index) in lawyerList">
                        <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
                        <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
                        <td>
                            <Avatar :src="value.LawyerImg" style="margin-right: 15px" />{{ value.LawyerName }}
                        </td>
                        <td>{{ value.LawyerPhone }}</td>
                        <td>{{ value.AdminName }}</td>
                        <td>{{ value.PracticeNumber || '--' }}</td>
                        <td>
                            <div v-if="value.skill_ids">
                                <Tooltip placement="top" max-width="300">
                                    <div slot="content">
                                        <span v-for="(item, i) in value.skill_ids">
                                            {{ item.keyword }}{{ i + 1 < value.skill_ids.length ? '、' : '' }} </span>
                                    </div>
                                    <div style="display: inline-block" v-if="i < 3" v-for="(item, i) in value.skill_ids">
                                        {{ item.keyword }}<span v-if="i < 2">{{ i + 1 < value.skill_ids.length
                                                ?'、':''}}</span>
                                    </div>
                                </Tooltip>
                            </div>
                            <div v-else>--</div>
                        </td>
                        <td>{{ value.ServiceStartTime || '--' }}</td>
                        <td>{{ value.ServiceEndTime || '--' }}</td>
                        <!--          <td>村（居）法律顾问</td>-->
                        <td style="text-align: center">
                            <Button type="primary" @click="replaceLawyer(value)">修改</Button>
                            <Button type="primary" style="margin-left: 10px" @click="getLawyerContract(value.LawyerId)"
                                :disabled="value.file == null">查看合同</Button>
                            <!--                            <div style="margin-left: 20px;display: inline-block">-->
                            <!--                              <Button-->
                            <!--                                  v-if="value.IsUse == 0"-->
                            <!--                                  type="error"-->
                            <!--                                  @click="ClockSet(value.LawyerId, 1)"-->
                            <!--                              >禁用</Button>-->
                            <!--                              <Button-->
                            <!--                                  v-if="value.IsUse == 1"-->
                            <!--                                  type="success"-->
                            <!--                                  @click="ClockSet(value.LawyerId, 0)"-->
                            <!--                              >启用</Button>-->
                            <!--                            </div>-->
                            <!--                        <Button type="error" style="margin-left: 30px"  @click="removeLawyer(value.LawyerId)">删除顾问</Button>-->
                        </td>
                    </tr>
                </tbody>
            </table>
            <Page :total="totalName" show-total :page-size="pageSize" show-elevator @on-change="
                currentPageName = $event
            serachLawyer()
                " :current="currentPageName" />
            <div style="padding: 10px"></div>
        </div>
        <div class="publicWelfare" v-if="tabsType == 2">
            <div style="background-color: white">
                <div class="publicWelfare">
                    <Row style="margin: 20px">
                        <Col span="12">
                        <Input v-model="adminLawyerList.searchLaw" search enter-button placeholder="搜索公证员"
                            @on-search="aQuestionnaireList" />
                        </Col>
                        <!--<Col span="12" style="text-align: center">-->
                        <!--<Button type="primary" @click="sendOutinformation()">一键发送</Button>-->
                        <!--</Col>-->
                        <Col span="12">
                        <Button type="primary" @click="
                            cqCommunity()
                        modal = true
                            " style="margin-left: 30px">添加</Button>
                        </Col>
                    </Row>

                    <table class="formtable">
                        <thead>
                            <tr>
                                <th>序号</th>
                                <th>姓名</th>
                                <th>移动电话</th>
                                <th>执业机构</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, index) in adminLawyerList.data">
                                <td v-if="(adminLawyerList.page - 1) * adminLawyerList.pageSize + index + 1 < 10">
                                    0{{ (adminLawyerList.page - 1) * adminLawyerList.pageSize + index + 1 }}
                                </td>
                                <td v-else>{{ (adminLawyerList.page - 1) * adminLawyerList.pageSize + index + 1 }}</td>
                                <td>
                                    <Avatar :src="value.GreffierImg" /> {{ value.GreffierName }}
                                </td>
                                <td>{{ value.GreffierPhone }}</td>
                                <td>{{ value.GreffierFirm }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <Page :total="adminLawyerList.count" :page-size="adminLawyerList.pageSize" show-elevator show-total
                        @on-change="
                            adminLawyerList.page = $event
                        aQuestionnaireList()
                            " :current="adminLawyerList.page" />
                </div>
            </div>
        </div>
        <div class="publicWelfare" v-if="tabsType == 3">
            <div class="publicWelfare">
                <Row style="margin: 20px">
                    <Col span="12">
                    <Input v-model="understandingList.searchLaw" search enter-button placeholder="搜索明白人"
                        @on-search="aAdminInsiderClick" />
                    </Col>
                    <Col span="12">
                    <Button type="primary" @click="
                        cqCommunity()
                    modal = true
                        " style="margin-left: 30px">添加</Button>
                    </Col>
                </Row>

                <table class="formtable">
                    <thead>
                        <tr>
                            <th>序号</th>
                            <th>姓名</th>
                            <th>移动电话</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(value, index) in understandingList.data">
                            <td v-if="(understandingList.page - 1) * understandingList.pageSize + index + 1 < 10">
                                0{{ (understandingList.page - 1) * understandingList.pageSize + index + 1 }}
                            </td>
                            <td v-else>{{ (understandingList.page - 1) * understandingList.pageSize + index + 1 }}</td>
                            <td>
                                <Avatar :src="value.InsiderImg" /> {{ value.InsiderName }}
                            </td>
                            <td>{{ value.InsiderPhone }}</td>
                        </tr>
                    </tbody>
                </table>
                <Page :total="understandingList.count" :page-size="understandingList.pageSize" show-elevator show-total
                    @on-change="
                        understandingList.page = $event
                    aAdminInsiderClick()
                        " :current="understandingList.page" />
            </div>
        </div>
        <!--    <Tabs value="name1">-->
        <!--      <TabPane label="村（社区）管理" name="name2">-->

        <!--      <TabPane label="律所管理" name="name3">-->
        <!--        <div class="publicWelfare">-->
        <!--          <Row style="margin: 20px">-->
        <!--            <Col span="12">-->
        <!--              <Input v-model="organizationSearch" search enter-button placeholder="搜索律所名称" @on-search="adminOrganizationList" />-->
        <!--            </Col>-->
        <!--            <Col span="12">-->
        <!--              <Button type="primary"  @click="lawFirmShow = true" style="margin-left: 30px">添加律所</Button>-->
        <!--            </Col>-->
        <!--          </Row>-->
        <!--          <Collapse v-model="value1" accordion @on-change="lawList()">-->
        <!--            <Panel v-for="(value,index) in lawFirmData" :name="index.toString()" >-->
        <!--              {{value.AdminName}}-->
        <!--              <div slot="content">-->
        <!--                <table class="formtable">-->
        <!--                  <thead>-->
        <!--                  <tr>-->
        <!--                    <th>律所名称</th>-->
        <!--                    <th>登录名</th>-->

        <!--                    <th>添加时间</th>-->
        <!--                    <th>操作</th>-->
        <!--                  </tr>-->
        <!--                  </thead>-->
        <!--                  <tbody>-->
        <!--                  <tr >-->
        <!--                    <td>{{value.AdminName}}</td>-->
        <!--                    <td>{{value.AdminLogin}}</td>-->
        <!--                    <td>{{value.CreatTime}}</td>-->
        <!--                    <td><Button icon="md-create" type="primary" @click.stop="addTto(3,value.AdminId,1,value.AdminName,value.AdminLogin,value.AccountPassword,value.AreaId,value.AreaName)">编辑</Button></td>-->
        <!--                  </tr>-->
        <!--                  </tbody>-->
        <!--                </table>-->
        <!--              </div>-->

        <!--              <div slot="content" class="publicWelfare">-->
        <!--                <div>-->
        <!--                  <div class="font-size" style="width: 100%;display: inline-block;">-->
        <!--                    <div class="administration">-->
        <!--                      <img class="label" src="/img/label.png">-->
        <!--                      <p>管理</p>-->
        <!--                      <div class="addTo" @click="modal4 = true">添加</div>-->
        <!--                    </div>-->
        <!--                    <div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;    text-align: center;">-->
        <!--                      <div class="forMtitle" v-if="listData != []">-->
        <!--                        <p>律师</p>-->
        <!--                        <p>电话</p>-->
        <!--                      </div>-->
        <!--                      &lt;!&ndash;for&ndash;&gt;-->
        <!--                      <div class="forMcontent" v-for="(value,index) in listData" :key="index">-->
        <!--                        <p>{{value.LawyerName}}</p>-->
        <!--                        <p>{{value.LawyerPhone}}</p>-->
        <!--                      </div>-->
        <!--                      <div style="text-align: left;margin: 40px;font-size: 16px">-->
        <!--                        <Page :total="count" :page-size="pageSize" show-elevator @on-change="page1 = $event;lawList(organizationIdNow)" />-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->

        <!--                </div>-->
        <!--              </div>-->
        <!--            </Panel>-->
        <!--          </Collapse>-->
        <!--          <Page :total="lawFirmCount" show-total :page-size="pageSize" show-elevator-->
        <!--                @on-change="organizationPage = $event;adminOrganizationList()" />-->
        <!--        </div>-->
        <!--      </TabPane>-->
        <!--    </Tabs>-->

        <Modal v-model="modal" title="添加" @on-ok="ok" @on-cancel="cancel">
            <div v-if="tabsType == 1">
                <Input v-model="lawyerName" placeholder="姓名" style="margin-bottom: 30px" />
                <Input v-model="lawyerPhone" placeholder="移动电话" style="margin-bottom: 30px" />
                <Select style="margin-bottom: 30px" v-model="OrganizationId" filterable clearable
                    @on-query-change="lawFirmsearch" @on-open-change="lawFirmdropDown" class="choiceLawyer"
                    placeholder="执业机构">
                    <Option v-for="item in FirmPageData" :value="item.OrganizationId" :key="item.OrganizationId">{{
                        item.AdminName
                        }}</Option>
                    <Page :total="FirmPageCount" show-total :page-size="pageSize" :current="lawFirmPage"
                        @on-change="lawFirmTurnPages"></Page>
                </Select>
                <Cascader v-if="adminLevel != 3" style="margin-bottom: 30px" :data="serachData" v-model="serachValue"
                    placeholder="服务村（社区）" @on-change="serachSelect"></Cascader>
                <Select v-if="VillageByData != ''" style="margin-bottom: 30px" v-model="VillagesId" filterable clearable
                    multiple @on-query-change="villageByQuery" @on-open-change="villageByOpen" class="choiceLawyer"
                    placeholder="请选择村（社区），可多选">
                    <Option v-for="item in VillageByData" :value="item.VillagesId" :key="item.VillagesId">{{
                        item.VillagesName
                        }}</Option>
                    <Page :total="villageByCount" show-total :page-size="pageSize" :current="villageByPage"
                        @on-change="serachSelectPage"></Page>
                </Select>
                <!--              开始时间-->
                <div style="margin-bottom: 30px">
                    <DatePicker :value="ServiceStartTime" v-model="ServiceStartTime" type="date" split-panels
                        placeholder="服务起始时间" style="width: 200px" @on-change="totalTime1"></DatePicker>
                </div>
                <!--              结束时间-->
                <div>
                    <DatePicker :value="ServiceEndTime" v-model="ServiceEndTime" type="date" split-panels
                        placeholder="服务终止时间" style="width: 200px" @on-change="totalTime2"></DatePicker>
                </div>
            </div>
            <div v-if="tabsType == 2">
                <Input v-model="GreffierName" placeholder="公证员姓名" style="margin-bottom: 30px" />
                <Input v-model="GreffierPhone" placeholder="公证员电话" style="margin-bottom: 30px" />
                <!--      <Select  style="margin-bottom: 30px" v-model="OrganizationId" filterable clearable @on-query-change="lawFirmsearch" @on-open-change="lawFirmdropDown" class="choiceLawyer" placeholder="执业机构">-->
                <!--        <Option v-for="item in FirmPageData" :value="item.OrganizationId" :key="item.OrganizationId">{{item.AdminName}}</Option>-->
                <!--        <Page-->
                <!--            :total="FirmPageCount" show-total-->
                <!--            :page-size="pageSize"-->
                <!--            :current="lawFirmPage"-->
                <!--            @on-change="lawFirmTurnPages"-->
                <!--        ></Page>-->
                <!--      </Select>-->
                <Cascader v-if="adminLevel != 3" style="margin-bottom: 30px" :data="serachData" v-model="serachValue"
                    placeholder="服务村（社区）" @on-change="serachSelect"></Cascader>
                <Select v-if="VillageByData != ''" style="margin-bottom: 30px" v-model="VillagesId" filterable clearable
                    multiple @on-query-change="villageByQuery" @on-open-change="villageByOpen" class="choiceLawyer"
                    placeholder="请选择村（社区），可多选">
                    <Option v-for="item in VillageByData" :value="item.VillagesId" :key="item.VillagesId">{{
                        item.VillagesName
                        }}</Option>
                    <Page :total="villageByCount" show-total :page-size="pageSize" :current="villageByPage"
                        @on-change="serachSelectPage"></Page>
                </Select>
            </div>
            <div v-if="tabsType == 3">
                <Input v-model="InsiderName" placeholder="明白人姓名" style="margin-bottom: 30px" />
                <Input v-model="InsiderPhone" placeholder="明白人电话" style="margin-bottom: 30px" />
                <Cascader v-if="adminLevel != 3" style="margin-bottom: 30px" :data="serachData" v-model="serachValue"
                    placeholder="服务村（社区）" @on-change="serachSelect"></Cascader>
                <Select v-if="VillageByData != ''" style="margin-bottom: 30px" v-model="VillagesId" filterable clearable
                    multiple @on-query-change="villageByQuery" @on-open-change="villageByOpen" class="choiceLawyer"
                    placeholder="请选择村（社区），可多选">
                    <Option v-for="item in VillageByData" :value="item.VillagesId" :key="item.VillagesId">{{
                        item.VillagesName
                        }}</Option>
                    <Page :total="villageByCount" show-total :page-size="pageSize" :current="villageByPage"
                        @on-change="serachSelectPage"></Page>
                </Select>
            </div>
        </Modal>
        <!--      更换顾问-->
        <Modal :draggable="true" v-model="replaceShow" title="修改顾问信息" @on-ok="modifyLawyer" @on-cancel="cancel"
            width="530">
            <Input v-model="lawyerName" placeholder="姓名" style="margin-bottom: 30px" />
            <Input v-model="lawyerPhone" placeholder="移动电话" style="margin-bottom: 30px" />
            <Input v-model="PracticeNumber" placeholder="执业证号" style="margin-bottom: 30px" />
            <Select style="margin-bottom: 30px" v-model="OrganizationId" filterable clearable
                @on-query-change="lawFirmsearch" @on-open-change="lawFirmdropDown" class="choiceLawyer"
                placeholder="执业机构">
                <Option v-for="item in FirmPageData" :value="item.OrganizationId" :key="item.OrganizationId">{{
                    item.AdminName
                    }}</Option>
                <Page :total="FirmPageCount" show-total :page-size="pageSize" :current="lawFirmPage"
                    @on-change="lawFirmTurnPages"></Page>
            </Select>
            <div style="margin-bottom: 30px">
                <DatePicker :value="ServiceStartTime" v-model="ServiceStartTime" type="date" split-panels
                    placeholder="服务起始时间" style="width: 200px" @on-change="totalTime1"></DatePicker>
            </div>
            <!--              结束时间-->
            <div>
                <DatePicker :value="ServiceEndTime" v-model="ServiceEndTime" type="date" split-panels
                    placeholder="服务终止时间" style="width: 200px" @on-change="totalTime2"></DatePicker>
            </div>
        </Modal>
        <!--    村社区管理-->
        <!-- <Modal
            v-model="modifycommunityShow"
            title="修改社区"
            @on-ok="onmodifyCommunity"
            @on-cancel="cancel"
            class="placeholderColor"
        >
            <Form :model="communitySingleData" :label-width="120">
                <FormItem label="服务社区（村）">
                    <Input v-model="communitySingleData.VillagesName" placeholder="服务社区（村）"></Input>
                </FormItem>
                <FormItem label="选择所属区域">
                    <Cascader
                        :data="addressData"
                        v-model="communitySingleData.familyaddress"
                        placeholder="请选择所属区域"
                        @on-change="addressSelect"
                    ></Cascader>
                </FormItem>
                <FormItem label="选择管理层">
                    <Cascader
                        :data="administrationData"
                        change-on-select
                        v-model="communitySingleData.AdminId"
                        :placeholder="communitySingleData._AdminName"
                        @on-change="communityAdministration"
                    ></Cascader>
                </FormItem>
            </Form>
        </Modal> -->
        <!--     更换顾问 - 新增顾问 -->
        <Modal :draggable="true" v-model="modal1" :title="title" @on-ok="determine" @on-cancel="cancel" width="530">
            <Select v-model="model1" filterable multiple @on-query-change="onSearch" @on-change="onSelect"
                @on-open-change.capture="onCapture" class="choiceLawyer" placeholder="请选择顾问">
                <Option v-for="item in lawyerList" :value="item.LawyerId" :key="item.LawyerId">{{ item.LawyerName }}{{
                    item.LawyerPhone }}{{ item.OrganizationName }}</Option>
                <Page :total="totalName" show-total :page-size="pageSize" :current="currentPageName"
                    @on-change="handlePageName"></Page>
            </Select>
        </Modal>
        <!-- <Modal
            :draggable="true"
            v-model="communityShow"
            title="添加社区"
            @on-ok="onCommunity"
            @on-cancel="cancel"
            width="530"
        >
            <div style="margin-bottom: 30px">
                <Select
                    v-model="model1"
                    filterable
                    multiple
                    @on-query-change="onSearch"
                    class="choiceLawyer"
                    placeholder="请选择顾问"
                >
                    <Option v-for="item in lawyerList" :value="item.LawyerId" :key="item.LawyerId"
                        >{{ item.LawyerName }}{{ item.LawyerPhone }}{{ item.OrganizationName }}</Option
                    >
                    <Page
                        :total="totalName"
                        show-total
                        :page-size="pageSize"
                        :current="currentPageName"
                        @on-change="handlePageName"
                    ></Page>
                </Select>
            </div>
            <Input v-model="community.VillagesName" placeholder="服务社区（村）" style="margin-bottom: 30px" />
            <Cascader
                :data="addressData"
                v-model="familyaddress"
                placeholder="请选择所属区域"
                @on-change="addressSelect"
                style="margin-bottom: 30px"
            ></Cascader>
            <Cascader
                :data="administrationData"
                v-model="community.AdminId"
                placeholder="请选择管理层"
                @on-change="onAdministration"
            ></Cascader>
        </Modal> -->
        <!--添加律所-->
        <!-- <Modal
            :draggable="true"
            v-model="lawFirmShow"
            title="添加律所"
            @on-ok="addlawFirm"
            @on-cancel="cancel"
            width="530"
        >
            <Input v-model="addlawFirmData.OrganizationName" placeholder="律所名称" style="margin-bottom: 30px" />
            <Input v-model="addlawFirmData.AdminLogin" placeholder="登录名" style="margin-bottom: 30px" />
            <Input v-model="addlawFirmData.AdminPassword" placeholder="登录密码" style="margin-bottom: 30px" />
            <Input v-model="addlawFirmData.OrganizationLinkman" placeholder="联系人" style="margin-bottom: 30px" />
            <Input v-model="addlawFirmData.OrganizationPhone" placeholder="联系人电话" style="margin-bottom: 30px" />
            <Cascader
                :data="administrationData"
                v-model="addlawFirmData.AdminId"
                placeholder="请选择管理层"
                change-on-select
                @on-change="onAdministration"
                style="margin-bottom: 30px"
            ></Cascader>
            <Cascader
                :data="addressData"
                v-model="addlawFirmData.AreaId"
                placeholder="请选择所属区域"
                @on-change="addressSelect"
                style="margin-bottom: 30px"
            ></Cascader>
        </Modal> -->

        <Modal v-model="modal4" title="添加" @on-ok="lawyerOk" @on-cancel="cancel">
            <div class="single">
                <p class="contacts">律师名称</p>
                <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName" />
            </div>
            <div class="single">
                <p class="contacts">律师电话</p>
                <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone" />
            </div>
        </Modal>
        <!--      查看合同-->
        <Modal v-model="contractData.show" title="合同列表">
            <div slot="footer">
                <Button @click="contractData.show = false">关闭</Button>
            </div>
            <List border>
                <ListItem v-for="(value, index) in contractData.data">
                    <ListItemMeta :title="'上传时间：' + value.created_at" />
                    <template slot="action">
                        <li @click="openFile(value.file)">
                            查看
                        </li>
                    </template>
                </ListItem>
            </List>
        </Modal>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'lawyerManagement',

    data() {
        return {
            title: '',
            key: '',
            lawyerList: [],
            currentPageName: 1,
            pageSize: 15,
            page: 1,
            totalName: 0,
            modal: false,
            replaceShow: false,
            replaceData: [],
            FirmPageData: [],
            FirmPageCount: 0,
            lawFirmPage: 1,
            searchFirm: '',
            lawyerPhone: '',
            PracticeNumber: '',
            lawyerName: '',
            OrganizationId: '',
            contractData: {
                show: false,
                data: []
            },
            //  村社区管理
            modifycommunityShow: false,
            communitySingleData: {},
            administrationData: [],
            searchData: '',
            CommunityData: [],
            modal2: false,
            addressData: [],
            communityShow: false,

            model1: [],
            community: {
                VillagesName: '',
                LawyerId: '',
                AdminId: [],
                ProvinceId: '',
                CityId: '',
                AreaId: '',
                CityName: ''
            },
            familyaddress: [],
            modal1: false,
            //  添加律所
            organizationSearch: '',
            lawFirmData: [],
            lawFirmCount: 0,
            lawFirmShow: false,
            value1: '',
            addData: {},
            listData: [],
            LawyerLpage1: 1,
            addlawFirmData: [],
            modal4: false,
            serachData: [],
            serachValue: [],
            VillageByData: [],
            VillagesId: [],
            villageByPage: 1,
            villageByCount: 0,
            serachAdminId: '',
            serachkey: '',
            adminLevel: '',
            tabsType: '1',
            //  公证员
            adminLawyerList: {
                data: [],
                page: 1,
                count: 0,
                pageSize: 15,
                searchLaw: ''
            },
            understandingList: {
                data: [],
                page: 1,
                count: 0,
                pageSize: 15,
                searchLaw: ''
            },
            cq: '',
            InsiderName: '',
            InsiderPhone: '',
            GreffierName: '',
            GreffierPhone: '',
            ServiceStartTime: '',
            ServiceEndTime: '',
            ServiceStartTimeData: '',
            ServiceEndTimeData: '',
        }
    },
    methods: {
        ClockSet: function (LawyerId, IsUse) {
            var _this = this
            _this.send({
                url: '/EditLawyerIsuse',
                confirm: true,
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    lawyerId: LawyerId,
                    IsUse: IsUse
                },
                success: function (res) {
                    _this.$Notice.success({
                        title: '系统通知',
                        desc: '该数据已更新！'
                    })
                    _this.serachLawyer()
                }
            })
        },
        totalTime1: function (e) {
            this.ServiceStartTimeData = e
        },
        totalTime2: function (e) {
            this.ServiceEndTimeData = e
        },
        communityAdministration() {
            console.log('这里是communityAdministration')
        },
        onSelect() {
            console.log()
        },
        serachLawyer: function (value) {
            var _this = this
            // console.log(value)
            if (value != undefined) {
                _this.currentPageName = 1
                _this.totalName = 0
            }
            _this.send({
                url: '/SerachLawyerByAdmin',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    key: _this.key,
                    page: _this.currentPageName,
                    pageSize: _this.pageSize
                },
                success: function (res) {
                    _this.lawyerList = res.data
                    _this.totalName = res.total
                }
            })
        },
        replaceLawyer: function (e) {
            this.replaceShow = true
            this.lawyerName = e.LawyerName
            this.replaceData = e
            this.lawyerPhone = e.LawyerPhone
            this.PracticeNumber = e.PracticeNumber
            this.FirmPageData = [e]
            this.OrganizationId = e.OrganizationId
            this.ServiceEndTime = e.ServiceEndTime
            this.ServiceEndTimeData = e.ServiceEndTime
            this.ServiceStartTime = e.ServiceStartTime
            this.ServiceStartTimeData = e.ServiceStartTime
        },
        //删除顾问
        removeLawyer: function (lawyerId) {
            var _this = this
            _this.send({
                url: '/RemoveLawyer',
                confirm: true,
                data: {
                    lawyerId: lawyerId
                },
                success: function (res) {
                    // console.log(res)
                    _this.$Message.success('操作成功')
                    _this.serachLawyer()
                }
            })
        },
        lawFirmsearch: function (e) {
            this.searchFirm = e
            this.lawFirmPage = 1
            this.FirmPageCount = 0
            // this.FirmPageData = []
            this.serachOrganization()
        },
        serachOrganization: function () {
            var _this = this
            _this.send({
                url: '/cityOrganizationList',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    key: _this.searchFirm,
                    page: _this.lawFirmPage,
                    pageSize: '15'
                },
                success: function (res) {
                    _this.FirmPageData = res.data
                    _this.FirmPageCount = res.total
                }
            })
        },
        lawFirmdropDown: function (e) {
            if (e) {
                this.searchFirm = ''
                this.OrganizationId = ''
                this.serachOrganization()
            }
            // console.log(this.OrganizationId)
        },
        lawFirmTurnPages: function (e) {
            // console.log(e)
            this.lawFirmPage = e
            this.serachOrganization()
        },
        modifyLawyer: function () {
            var _this = this
            _this.send({
                url: '/EditLawyerInfo',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    lawyerId: _this.replaceData.LawyerId,
                    LawyerName: _this.lawyerName,
                    LawyerPhone: _this.lawyerPhone,
                    PracticeNumber: _this.PracticeNumber,
                    ServiceStartTime: _this.ServiceStartTimeData,
                    ServiceEndTime: _this.ServiceEndTimeData,
                    OrganizationId: _this.OrganizationId
                },
                success: function (res) {
                    _this.$Message.success('操作成功')
                    _this.serachLawyer()
                }
            })
        },
        //关闭弹出
        cancel: function () {
            var _this = this
            _this.OrganizationId = ''
            _this.lawyerName = ''
            _this.lawyerPhone = ''
            // console.log(_this.OrganizationId)
        },
        ok: function () {
            var _this = this

            if (_this.tabsType == 1) {
                _this.send({
                    url: '/InsertLawyerInfo_re',
                    data: {
                        AdminId: window.sessionStorage.AdminId,
                        OrganizationId: _this.OrganizationId,
                        LawyerName: _this.lawyerName,
                        LawyerPhone: _this.lawyerPhone,
                        ServiceStartTime: _this.ServiceStartTimeData,
                        ServiceEndTime: _this.ServiceEndTimeData,
                        VillagesArr: _this.VillagesId.toString()
                    },
                    success: function (res) {
                        _this.lawyerList = []
                        _this.VillagesId = []
                        _this.serachValue = []
                        _this.VillageByData = []
                        _this.page = 1
                        _this.OrganizationId = ''
                        _this.lawyerName = ''
                        _this.lawyerPhone = ''
                        _this.serachLawyer()
                    }
                })
            }
            if (_this.tabsType == 2) {
                if (_this.GreffierName != '' && _this.GreffierPhone != '') {
                    _this.send({
                        url: '/AAddGreffierInfo',
                        data: {
                            AdminId: window.sessionStorage.AdminId,
                            GreffierName: _this.GreffierName,
                            GreffierPhone: _this.GreffierPhone,
                            VillagesArr: _this.VillagesId.toString()
                        },
                        success: function (res) {
                            _this.VillageByData = []
                            _this.serachValue = []
                            _this.adminLawyerList.page = 1
                            _this.aQuestionnaireList()
                        }
                    })
                } else {
                    _this.$Notice.warning({
                        title: '系统提示',
                        desc: '名称或电话不能为空'
                    })
                }
            }
            if (_this.tabsType == 3) {
                if (_this.InsiderName != '' && _this.InsiderPhone != '') {
                    _this.send({
                        url: '/AAddInsiderInfo',
                        data: {
                            AdminId: window.sessionStorage.AdminId,
                            InsiderName: _this.InsiderName,
                            InsiderPhone: _this.InsiderPhone,
                            VillagesArr: _this.VillagesId.toString()
                        },
                        success: function (res) {
                            _this.VillageByData = []
                            _this.serachValue = []
                            _this.adminLawyerList.page = 1
                            _this.aAdminInsiderClick()
                        }
                    })
                } else {
                    _this.$Notice.warning({
                        title: '系统提示',
                        desc: '名称或电话不能为空'
                    })
                }
            }
        },
        //  村社区管理
        addLawyer: function (e, title, type) {
            var _this = this
            if (type == 'shanchu') {
                _this.send({
                    url: '/RemoveVillages',
                    confirm: true,
                    data: {
                        VillagesId: e
                    },
                    success: function (res) {
                        // console.log(res)
                        _this.$Message.success('操作成功')
                        _this.CommunityData = []
                        _this.adminVillagesList()
                    }
                })
            } else {
                _this.VillagesId = e
                _this.title = title
                _this.modal1 = true
                _this.type = type
            }
        },
        onmodifyCommunity: function () {
            var _this = this
            if (this.communitySingleData.AdminId[this.communitySingleData.AdminId.length - 1] == undefined) {
                var AdminId = this.communityAdminId
            } else {
                var AdminId = this.communitySingleData.AdminId[this.communitySingleData.AdminId.length - 1]
            }
            _this.send({
                url: '/EditVillages',
                data: {
                    AdminId: AdminId,
                    VillagesId: _this.communitySingleData.VillagesId,
                    VillagesName: _this.communitySingleData.VillagesName,
                    CityName: _this.community.CityName,
                    ProvinceId: _this.communitySingleData.familyaddress[0],
                    CityId: _this.communitySingleData.familyaddress[1],
                    AreaId: _this.communitySingleData.familyaddress[2]
                },
                success: function (res) {
                    _this.$Message.success('操作成功')
                    _this.model1 = []
                    _this.adminVillagesList()
                }
            })
            // console.log(AdminId)
        },
        searchDara: function (type) {
            var _this = this
            if (type == 1) {
                _this.search = _this.searchData
                _this.LawyerName = ''
            } else {
                _this.search = ''
                _this.LawyerName = _this.searchData
            }
            _this.adminVillagesData = []
            _this.CommunityData = []
            _this.count = ''
            _this.page = '1'
            _this.adminVillagesList()
        },
        adminTreeList: function () {
            var _this = this
            var data = []
            _this.send({
                url: '/AdminTreeList',
                data: {
                    AdminId: window.sessionStorage.AdminId
                },
                success: function (res) {
                    if (res) {
                        for (var i in res.list) {
                            data.push(res.list[i])
                        }
                        convertTree(data)
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let { AdminId: value, AdminName: label, list: children } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    value,
                                    label,
                                    children
                                })
                            })
                            _this.administrationData = result
                            // console.log(_this.administrationData)
                            return result
                        }
                    }
                }
            })
        },
        adminVillagesList: function () {
            //   更换VillageListByPage
        },
        modifyCommunity: function (value) {
            var _this = this
            // console.log(value)
            _this.communitySingleData = value
            _this.communitySingleData.familyaddress = [value.ProvinceId, value.CityId, value.AreaId]
            _this.communityAdminId = value.AdminId
            _this.communitySingleData.AdminId = value.AdminId
            _this.modifycommunityShow = true
        },
        determine: function () {
            var _this = this
            if (_this.type == 'genghuan') {
                _this.send({
                    url: '/EditLawyerSer',
                    data: {
                        lawyerArrId: _this.model1.toString(),
                        VillagesId: _this.VillagesId
                    },
                    success: function (res) {
                        _this.$Message.success('操作成功')
                        _this.model1 = []
                        _this.serachLawyer()
                    }
                })
            } else {
                _this.send({
                    url: '/changeLawyerSer',
                    data: {
                        AdminId: window.sessionStorage.AdminId,
                        lawyerArrId: _this.model1.toString(),
                        VillagesId: _this.VillagesId,
                        type: _this.type
                    },
                    success: function (res) {
                        _this.$Message.success('操作成功')
                        _this.model1 = []
                        _this.adminVillagesList()
                    }
                })
            }
        },
        onSearch: function (e) {
            this.key = e
            this.currentPageName = 1
            this.totalName = 0
            this.lawyerList = []
            this.serachLawyer()
        },
        AreaList: function () {
            var _this = this
            var data = []
            _this.send({
                url: '/AreaList',
                success: function (res) {
                    for (var i in res) {
                        data.push(res[i])
                    }
                    convertTree(data)
                    function convertTree(tree) {
                        const result = []
                        tree.forEach((item) => {
                            // 解构赋值
                            let { AreaId: value, AreaName: label, AreaCode: AreaCode, list: children } = item
                            if (children) {
                                children = convertTree(children)
                            }
                            result.push({
                                value,
                                label,
                                AreaCode,
                                children
                            })
                        })
                        _this.addressData = result
                        return result
                    }
                }
            })
        },
        onCommunity: function () {
            var _this = this
            this.community.LawyerId = _this.model1.toString()
            this.community.AdminId = this.community.AdminId[this.community.AdminId.length - 1]
            // this.community.AdminId = window.sessionStorage.AdminId
            // console.log(this.community)
            _this.send({
                url: '/AddVillages',
                data: _this.community,
                success: function (res) {
                    _this.$Message.success('操作成功')
                    _this.model1 = []
                    _this.VillagesName = ''
                    _this.community.familyaddress = ''
                    _this.page = 1
                    _this.adminVillagesList()
                }
            })
        },
        handlePageName: function (e) {
            // console.log(e)
            this.currentPageName = e
            this.serachLawyer()
        },
        addressSelect: function (value, selectedData) {
            // console.log(value, selectedData)
            if (value[1] == 2262 || value[1] == 2237) {
                this.community.ProvinceId = value[0]
                this.community.CityId = value[1]
                this.community.AreaId = value[2] || ''
                this.community.CityName = selectedData[0].label + selectedData[2].label
            } else {
                this.community.ProvinceId = value[0]
                this.community.CityId = value[1]
                this.community.AreaId = value[2] || ''
                if (selectedData.length == 3) {
                    this.community.CityName = selectedData[0].label + selectedData[1].label + selectedData[2].label
                } else {
                    this.community.CityName = selectedData[0].label + selectedData[1].label
                }
            }
            // console.log(this.community)
        },
        onAdministration: function (value, selectedData) {
            // console.log(value[value.length - 1])
        },
        // 添加律所
        adminOrganizationList: function (value) {
            var _this = this
            if (value != undefined) {
                _this.organizationPage = 1
                _this.lawFirmCount = 0
            }
            _this.send({
                url: '/AdminOrganizationList',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    key: _this.organizationSearch,
                    page: _this.organizationPage,
                    pageSize: _this.pageSize
                },
                success: function (res) {
                    _this.lawFirmData = res.data
                    _this.lawFirmCount = res.total
                }
            })
        },
        lawList: function () {
            // console.log(this.lawFirmData[this.value1[0]].OrganizationId)
            var _this = this
            _this.send({
                consulting: true,
                url: '/LawyerListById',
                data: {
                    OrganizationId: _this.lawFirmData[this.value1[0]].OrganizationId,
                    page: _this.LawyerLpage1,
                    pageSize: 5
                },
                success: function (res) {
                    if (res) {
                        _this.listData = res.List
                        _this.count = res.count
                    } else {
                        _this.listData = []
                        _this.count = 0
                    }
                }
            })
        },
        addlawFirm: function () {
            var _this = this
            // console.log(_this.addlawFirmData.AdminId)
            _this.send({
                url: '/AddMember',
                data: {
                    AdminId: _this.addlawFirmData.AdminId[_this.addlawFirmData.AdminId.length - 1],
                    AdminLogin: _this.addlawFirmData.AdminLogin,
                    AdminPassword: _this.addlawFirmData.AdminPassword,
                    AreaId: _this.addlawFirmData.AreaId[_this.addlawFirmData.AreaId.length - 1], //（区域id）,
                    Level: 3, // （固定）,
                    OrganizationLinkman: _this.addlawFirmData.OrganizationLinkman,
                    OrganizationName: _this.addlawFirmData.OrganizationName,
                    OrganizationPhone: _this.addlawFirmData.OrganizationPhone
                },
                success: function (res) {
                    _this.addlawFirmData = []
                    _this.$Message.success('操作成功')
                    _this.adminOrganizationList()
                }
            })
        },
        lawyerOk: function () {
            var _this = this
            var op = this.lawFirmData[this.value1[0]].OrganizationId + 'p' + _this.lawyerPhone + 'p' + 1
            if (_this.lawyerName != '' && _this.lawyerPhone != '') {
                _this.send({
                    url: '/AddLawyerInfo',
                    data: {
                        OrganizationId: this.lawFirmData[this.value1[0]].OrganizationId,
                        scene: op,
                        userType: '2',
                        page: 'pages/login/login',
                        width: '430',
                        jpg: 'jpg',
                        LawyerName: _this.lawyerName,
                        LawyerPhone: _this.lawyerPhone,
                        LawyerId: _this.lawyerId
                    },
                    success: function (res) {
                        _this.listData = []
                        _this.lawyerName = ''
                        _this.lawyerPhone = ''
                        _this.LawyerLpage1 = 1
                        _this.lawyerId = ''
                        _this.lawList()
                    }
                })
            } else {
                _this.$Notice.warning({
                    title: '系统提示',
                    desc: '名称或电话不能为空'
                })
            }
        },
        CqAdminSerach: function () {
            var _this = this
            var data = []
            _this.send({
                url: '/CqAdminSerach',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    Level: window.sessionStorage.adminLevel,
                    page: 1,
                    pageSize: '999'
                },
                success: function (res) {
                    for (var i in res.list) {
                        data.push(res.list[i])
                    }
                    convertTree(data)
                    function convertTree(tree) {
                        const result = []
                        tree.forEach((item) => {
                            // 解构赋值
                            let { AdminId: value, AdminName: label, Level: Level, Pid: Pid, list: children } = item
                            if (children) {
                                children = convertTree(children)
                            }
                            result.push({
                                value,
                                label,
                                Level,
                                Pid,
                                children
                            })
                        })
                        _this.serachData = result
                        return result
                    }
                }
            })
        },
        serachSelectPage: function (value) {
            var _this = this
            _this.villageByPage = value
            _this.VillageByAdmin()
        },
        serachSelect: function (value, selectedData) {
            var _this = this
            // console.log(value, selectedData)
            if (value != '') {
                _this.serachAdminId = value[value.length - 1]
                _this.VillageByAdmin()
            } else {
                _this.VillageByData = []
            }
        },
        VillageByAdmin: function () {
            var _this = this
            _this.send({
                url: '/VillageByAdmin',
                data: {
                    AdminId: _this.serachAdminId,
                    key: _this.serachkey,
                    page: _this.villageByPage,
                    pageSize: _this.pageSize
                },
                success: function (res) {
                    _this.VillageByData = res.list
                    _this.villageByCount = res.count
                }
            })
        },
        villageByQuery: function (e) {
            // console.log(e, this.VillagesId)
            this.serachkey = e
            this.villageByPage = 1
            this.villageByCount = 0
            this.VillageByAdmin()
        },
        villageByOpen: function (e) {
            // console.log(e)
        },
        cqCommunity: function () {
            var _this = this
            if (window.sessionStorage.adminLevel == 3) {
                _this.serachAdminId = window.sessionStorage.AdminId
                _this.VillageByAdmin()
            } else {
                _this.CqAdminSerach()
            }
        },
        switchClick: function (e) {
            var _this = this
            _this.tabsType = e
            if (e == '1') {
            }
            if (e == '2') {
                _this.aQuestionnaireList()
            }
            if (e == '3') {
                _this.aAdminInsiderClick()
            }
        },
        //  公证员
        aQuestionnaireList: function () {
            var _this = this
            _this.send({
                url: '/AAdminGreffierList',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    page: _this.adminLawyerList.page,
                    pageSize: _this.adminLawyerList.pageSize,
                    key: _this.adminLawyerList.searchLaw
                },
                success: function (res) {
                    _this.adminLawyerList.data = res.data
                    _this.adminLawyerList.count = res.total
                }
            })
        },
        aAdminInsiderClick: function () {
            var _this = this
            _this.send({
                url: '/AAdminInsiderList',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    page: _this.understandingList.page,
                    pageSize: _this.understandingList.pageSize,
                    key: _this.understandingList.searchLaw
                },
                success: function (res) {
                    _this.understandingList.data = res.List
                    _this.understandingList.count = res.Count
                }
            })
        },
        getLawyerContract: function (LawyerId) {
            var _this = this
            _this.send({
                url: '/getLawyerContract',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    LawyerId: LawyerId,
                },
                success: function (res) {
                    console.log(res)
                    _this.contractData.data = res
                    _this.contractData.show = true
                }
            })
        },
        openFile: function (url) {
            console.log(url)
            var _this = this
            var fileType = url.substr(url.lastIndexOf(".") + 1, 4).toLowerCase()
            if (fileType == "txt") {
                axios({
                    method: 'get',
                    responseType: 'blob',
                    url: _this.assetsLink + url,
                    transformResponse: [function (data) {
                        var render = new FileReader()
                        render.readAsText(data, 'UTF8')
                        render.onload = function () {
                            if (render.result.indexOf('�') != -1) {
                                var renderGBK = new FileReader()
                                renderGBK.readAsText(data, 'GBK')
                                renderGBK.onload = function () {
                                }
                            }
                            var newWindow = window.open('about:blank', '', 'width=400,height=400')
                            newWindow.document.write(renderGBK.result)
                        }
                    }]
                })
            }
            else if (['pdf', 'docx', 'doc'].includes(fileType)) {
                if (fileType == 'pdf') {
                    window.open(_this.assetsLink + url)
                } else {
                    if (SYSCONFIG.location == 'chong-qing') {
                        _this.showPdf(url)
                    } else {
                        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + _this.assetsLink + url)
                    }
                }
            } else {
                window.open(_this.assetsLink + url)
            }
        },
        showPdf: function (e) {
            var _this = this
            if (SYSCONFIG.location == 'chong-qing') {
                var url = '/ShowPdf'
            } else {
                var url = '/CD_ShowPdf'
            }
            window.open(window.sessionStorage.url + 'api' + url + '?FilePath=' + e)
        },
    },

    mounted() {
        var _this = this
        _this.adminLevel = window.sessionStorage.adminLevel
        _this.serachOrganization()
        _this.serachLawyer()
        //  村社区管理
        _this.adminVillagesList()
        _this.adminTreeList()
        _this.AreaList()
        //添加律所
        _this.adminOrganizationList()
        if (SYSCONFIG.location == 'chong-qing') {
            _this.cq = 'cq'
        }
    },

    computed: {
        assetsLink: function () {
            return `${SYSCONFIG.server.default.ssl ? 'https' : 'http'}://${SYSCONFIG.server.default.host}:${SYSCONFIG.server.default.port}/`
        }
    },
}
</script>

<style scoped>
.forMcontent {
    width: calc(100% - 80px);
    height: 60px;
    color: #666666;
    font-size: 16px;
    margin: 0px 40px 0 40px;
    border-bottom: 1px solid #dddddd;
    text-align: center;
}

.forMcontent p {
    line-height: 60px;
    display: inline-block;
    width: 50%;
}

.forMtitle1 p {
    display: inline-block;
    color: #333333;
    font-size: 16px;
    line-height: 60px;
    overflow: hidden;
    white-space: nowrap;
    width: 20%;
}

.forMtitle {
    width: calc(100% - 80px);
    background-color: #f9f9f9;
    margin: 40px 40px 0 40px;
    height: 60px;
}

.forMtitle p {
    display: inline-block;
    color: #333333;
    font-size: 16px;
    width: 50%;
    line-height: 60px;
    overflow: hidden;
    white-space: nowrap;
}

.administration {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #dddddd;
    height: 80px;
    text-align: left;
    margin-bottom: 0;
}

.administration p {
    font-size: 20px;
    color: #333333;
    display: inline-block;
    line-height: 80px;
}

.label {
    width: 6px;
    height: 24px;
    vertical-align: top;
    margin: 28px 20px 0 20px;
}

.addTo {
    width: 100px;
    height: 40px;
    border-radius: 20px;
    background-color: #00b86c;
    color: #ffffff;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    float: right;
    line-height: 40px;
    margin: 20px 40px 0 0;
    cursor: pointer;
}

.inputWord {
    color: #333333;
    font-size: 16px;
    border: 0;
    text-align: left;
    height: 66px;
    margin-left: 20px;
    width: 350px;
}

.contacts {
    font-size: 16px;
    color: #999999;
    display: inline-block;
    height: 66px;
    line-height: 66px;
}

.single {
    width: 100%;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    display: inline-block;
}

.choiceLawyer :deep(.ivu-select-dropdown) {
    max-height: none;
}

.choiceLawyer :deep(.ivu-page) {
    margin-left: 30px;
}

.choiceLawyer {
    width: 380px;
}

.formtable {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}

/*表格*/
.publicWelfare table {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
    text-align: center;
}

.publicWelfare table th {
    background: #f7f7f7;
    white-space: nowrap;
    color: #5c6b77;
    font-weight: 600;
    text-align: center;
}

.publicWelfare table td,
.publicWelfare table th {
    border: 1px solid #e9e9e9;
    padding: 8px 16px;
}

.placeholderColor /deep/ input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #515a6e;
}

.placeholderColor /deep/ input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #515a6e;
}

.placeholderColor /deep/ input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #515a6e;
}

.placeholderColor /deep/ input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #515a6e;
}
</style>
