<style scoped>
	.menuNum{background-color:#008fd6;color:#FFF;width:0.4rem;height:0.4rem;border-radius:0.2rem;font-size:0.28rem;margin: auto;}
	.block-style{background-color:#f8f9fb;text-align:center;width:4em;z-index:2;position:relative;display:inline-block;}
</style>

<template>
	<div>
		<Row>
			<Col span="8" v-for="(item,index) in menu" :style="styles(index)">
				<div class="block-style">
					<div class="menuNum" :style="step<=index?'background-color:#dedede;':''">{{index+1}}</div>
					<div style="padding:0.05rem"></div>
					<div :style="step<=index?'color:#666666;':'color:#008fd6;'">{{item}}</div>
				</div>
			</Col>
		</Row>
		<div style="height:0.04rem;background-color:#dedede;position:relative;top:-0.58rem;z-index:1;"></div>
	</div>
</template>

<script>
	export default{
		name:'stepMenu',
		props:{
			step:Number,
			menu:Array,
		},
		data(){
			return{}
		},
		computed:{
			
		},
		mounted(){},
		methods:{
			styles:function(index){
				if(index==0){
					return 'text-align:left;'
				}else if(index==this.menu.length-1){
					return 'text-align:right;'
				}else{
					return 'text-align:center;'
				}
			}
		},
	}
</script>
