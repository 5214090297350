<template>
    <!--url(/img/pc/bg.png)-->
    <div style="min-width: 1200px;">
        <div style="background: linear-gradient(-90deg, #2340EE, #339AEF);position: fixed;height: 100%;width: 100%;text-align: center">
            <div style="background: url(/img/pc/bg.png);background-size: 100% 100%;height: 100%;width: 100%;">
                <div style="height: 266px;width: 100%;background: rgba(255,255,255,0.1);font-size: 48px;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;">
                    <div style="text-align: left">
                        <img src="/img/pc/mark.png" style="margin:39px 20px 0 60px;">
                        <div style="display: inline-block;line-height: 180px;vertical-align: top">重庆市渝中区人民法院—智能法律问答系统</div>
                        <!--<span style="font-size: 18px;float: right;vertical-align: top;margin-right: 60px;line-height: 180px"> 已回答 <span style="color: #FFC618">{{total_click}}</span> 个问题</span>-->
                    </div>
                </div>
                <div style="text-align: center">
                    <div style="display: inline-block;margin-top: -106px;position: fixed;margin-left: -595px;">
                        <div style="text-align: left;" @click="$router.go(-1)">
                            <img style="vertical-align: top;margin: 10px 10px 10px 0;" src="/img/pc/sy.png">
                            <div style="display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;vertical-align: top;margin: 10px 10px 10px 0">返回首页</div>
                        </div>
                        <div id="chatRecord" ref="messagesContainer" style=" animation: 10s wordsLoop linear infinite normal;width: 1200px;height: 80%;background: #F0F4F7;border-radius: 4px 4px 0px 0px;overflow-x: hidden;overflow-y: auto;position: fixed;
    left: 50%;margin-left: -600px;bottom: 0">
                            <div style="">
                                <div style="padding: 40px 66px;overflow-x: auto;overflow-y: hidden;white-space: nowrap;position: fixed;width: 1200px;background-color: rgb(240, 244, 247);">
                                    <div @click="onAdviser(value.group_r,1,index)" :style="{background:gid == index+1?'#196DFF':'#92B3EE'}" style="cursor: pointer;margin-right: 20px;display: inline-block;text-align: center;
                    width: 116px;height: 46px;border-radius: 4px;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 46px;" v-for="(value,index) in resultData" v-if="value.gid != 23">
                                        {{value.group_r}}
                                    </div>
                                </div>
                            </div>
                            <!---->
                            <div  id="demo" >
                                <div style="padding: 100px;"></div>
                                <div v-for="(value,index) in dataList">
                                    <!--对话类型1-->
                                    <div v-if="value.type == 2" style="text-align: left;margin-bottom: 30px">
                                        <div style="width: 80px;height: 80px;background: #196DFF;border-radius: 50%;text-align: center;display: inline-block;margin-left: 60px">
                                            <img style="width: 48px;height: 44px;vertical-align: top;margin-top: 18px" src="/img/pc/logo.png">
                                        </div>
                                        <div style="width: 412px;background: #FFFFFF;border-radius: 4px;display: inline-block;vertical-align: top;padding: 20px;margin-left: 20px">
                                            <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #333333;">请输入您要咨询的关键字： </div>
                                            <div @click="onAdviser(k,2,value.gid)" style="cursor:pointer;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #196DFF;line-height: 30px;display: inline-block;margin: 10px 10px 0 0" v-for="(k,i) in value.result">{{k}}</div>
                                        </div>
                                    </div>
                                    <!--对话类型2-->
                                    <div v-if="value.type == 3"  style="text-align: left;margin-bottom: 30px">
                                        <div style="width: 80px;height: 80px;background: #196DFF;border-radius: 50%;text-align: center;display: inline-block;margin-left: 60px">
                                            <img style="width: 48px;height: 44px;vertical-align: top;margin-top: 18px" src="/img/pc/logo.png">
                                        </div>
                                        <div style="width: 412px;background: #FFFFFF;border-radius: 4px;display: inline-block;vertical-align: top;padding: 20px;margin-left: 20px">
                                            <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #333333;margin-bottom: 20px;">为您匹配到以下内容，可点击查看 详细情况： </div>
                                            <div @click="onAdviser(k,3,value.gid)" style="cursor:pointer;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #196DFF;line-height: 45px;border-top: .01rem solid #e4e4e4;" v-for="(k,i) in value.result">{{k}}</div>
                                        </div>
                                    </div>
                                    <!--对话类型3-->
                                    <div v-if="value.type == 4" style="text-align: left;margin-bottom: 30px">
                                        <div style="width: 80px;height: 80px;background: #196DFF;border-radius: 50%;text-align: center;display: inline-block;margin-left: 60px">
                                            <img style="width: 48px;height: 44px;vertical-align: top;margin-top: 18px" src="/img/pc/logo.png">
                                        </div>
                                        <div style="width: 412px;background: #FFFFFF;border-radius: 4px;display: inline-block;vertical-align: top;padding: 20px;margin-left: 20px">
                                            <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #333333;" v-html="value.result">

                                            </div>
                                        </div>
                                    </div>
                                    <!--对话右-->
                                    <div v-if="value.type == 666" style="margin-bottom: 30px;text-align: right">
                                        <div style="background: #FFFFFF;border-radius: 4px;display: inline-block;vertical-align: top;padding: 20px;margin-right: 20px">
                                            <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #333333;">
                                                {{value.c_keyword}}
                                            </div>
                                        </div>
                                        <div style="width: 80px;height: 80px;background: #196DFF;border-radius: 50%;text-align: center;display: inline-block;margin-right: 60px">
                                            <img style="width: 48px;height: 44px;vertical-align: top;margin-top: 18px" src="/img/pc/mrtx.png">
                                        </div>
                                    </div>
                                </div>
                                <div style="padding: 60px;"></div>
                            </div>
                            <!---->
                            <div style="position: fixed;bottom: 0px;height: 115px;width: 1200px;background-color: rgb(240, 244, 247)">
                                <!--<input style="width: 750px;height: 52px;background: #FFFFFF;border-radius: 4px;font-size: 26px" v-model="c_keyword"/>-->
                                <div style="display: none">
                                    <input type="text" id="input" class="inputmailbox" style="width: 750px;height: 52px;background: #FFFFFF;border-radius: 4px;font-size: 26px" v-model="c_keyword" @focus="keyboardTrue" data-layout="normal" />
                                </div>
                                <!--<div @click="onSendOut" style="cursor: pointer;vertical-align: top;width: 100px;height: 52px;background: #196DFF;border-radius: 4px;display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 52px;margin-left: 10px">发送</div>-->
                                <!--<div style="width: 100px;height: 52px;background: #196DFF;border-radius: 4px;display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 52px;margin-left: 10px">重选</div>-->
                                <div style="float:right;display: inline-block;margin-right: 70px;">
                                    <div  @click="$router.go(-1)" style="cursor: pointer;vertical-align: top;width: 100px;height: 52px;background: #196DFF;border-radius: 4px;display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 52px;margin-left: 10px">返回</div>
                                    <div @click="clearScreen" style="cursor: pointer;vertical-align: top;width: 100px;height: 52px;background: #196DFF;border-radius: 4px;display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 52px;margin-left: 10px">清屏</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div>
                <!--<vue-touch-keyboard id="keyboard" v-if="visible" :layout="layout" :cancel="hide" :accept="accept" :input="input" :next="next" style="bottom: 150px"/>-->

                <!--<input id="input" type="text" name="">-->
            </div>
        </div>

    </div>
</template>

<script>
    import VueTouchKeyboard from "vue-touch-keyboard";
    import style from "vue-touch-keyboard/dist/vue-touch-keyboard.css"
    import axios from 'axios'
    export default {
        name: "chongqing",
        components: {
            "vue-touch-keyboard": VueTouchKeyboard.component,
        },
        data() {
            return {
                resultData : [],
                gid : 1,
                height : '',
                dataList : [],
                total_click : '',
                c_keyword : '',
                type : '',
                id : '',
                messagesContainerTimer : null,
            //
                visible: false,
                layout: "normal",
                input: null,
                options: {
                    useKbEvents: false}
            }
        },
        methods :{
            keyboardTrue : function(e){

                this.input = e.target;
                this.layout = e.target.dataset.layout;
                console.log(e.target)
                this.c_keyword = e.target.value
                if (!this.visible)
                    this.visible = true
            },
            accept(text) {
                console.log("Input text: " + text);
                this.hide();
            },
            hide() {
                this.visible = false;
            },
            next() {
                let inputs = document.querySelectorAll("input");
                let found = false;
                [].forEach.call(inputs, (item, i) => {
                    if (!found && item == this.input && i < inputs.length - 1) {
                        found = true;
                        this.$nextTick(() => {
                            inputs[i+1].focus();
                        });
                    }
                });
                if (!found) {
                    this.input.blur();
                    this.hide();
                }
            },
            onSendOut : function(){

                this.visible = false
                this.onAdviser(this.c_keyword,this.type,this.id)
                this.c_keyword = ''
            },
            clearScreen : function(){
                this.dataList = []
                this.onAdviser(this.$route.query.c_keyword,1,this.$route.query.id)
            },
            onAdviser : function (c_keyword,type,id) {
                console.log(c_keyword,type,id)
                var _this = this
                if(type == 1){_this.gid = id+1}
                _this.send({
                    consulting1 : true,
                    url : '/web_entrance',
                    data: {c_keyword:c_keyword,type:type,id:id},
                    success: function (res) {
                        var dataList = _this.dataList
                        _this.type = res.type
                        _this.id = res.gid
                        new Array(dataList.push({c_keyword:c_keyword,type:666}))
                        new Array(dataList.push(res))
                        _this.dataList = dataList
                        //
                        _this.messagesContainerTimer = setTimeout(()=>{
                            if(type != 3){
                                _this.$refs.messagesContainer.scrollTop = _this.$refs.messagesContainer.scrollHeight;
                            }

                            if(type == 3){
                                _this.$refs.messagesContainer.scrollTop = document.getElementById('demo').scrollHeight-300
                            }
                            // 清理定时器
                            clearTimeout(_this.messagesContainerTimer);
                        },0);

                        window.scrollTo({top:document.getElementById('demo').scrollHeight})
                        // console.log(document.getElementById('demo').scrollHeight)
                        // console.log(document.getElementById('chatRecord'))
                    }
                })
            },
        },
        mounted (){
            var _this = this
            _this.onAdviser(_this.$route.query.c_keyword,1,_this.$route.query.id)
            _this.height = document.body.offsetHeight || '100%'
            axios.post('https://vn-server.lvwa18.com/api/get_group_count',{
                params:{},
            })
                .then((res)=>{
                    console.log(res)
                    _this.resultData = res.data.Data.result
                })
           _this.total_click = this.$route.query.total_click
            $('#input').virtualkeyboard();
        }
    }
</script>

<style scoped>
    div::-webkit-scrollbar{
        width:10px;
        height:10px;
        border-radius: 6px;
        opacity: 0.1;
        /**/
    }
    div::-webkit-scrollbar-track{
        background: rgba(16, 46, 77, 0.1);
        border-radius:2px;
    }
    div::-webkit-scrollbar-thumb{
        background: rgba(136, 193, 251, 1);
        border-radius:10px;
    }
    div::-webkit-scrollbar-corner{
        background: #179a16;
    }
</style>
