<template>
  <div style="padding:20px;">
    <div style="background-color: white;">
      <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px">
        日程列表
        <div style="display: inline-block;vertical-align:middle;margin-left:10px">
          <span style="margin-left: 30px">共{{count}}条</span>
        </div>
      </div>
      <!--      <div style="margin: 15px 0">-->
      <!--        <DatePicker :value="timeData" v-model="timeData" type="daterange" split-panels placeholder="请选择时间"-->
      <!--                    style="width: 200px" @on-change="totalTime3"></DatePicker>-->

      <!--        <Select style="width:200px" v-model="model140" filterable remote clearable :remote-method="remoteMethod2"-->
      <!--                @on-change="lawyerClick3" :loading="loading2" placeholder="请输入社区">-->
      <!--          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">-->
      <!--            {{value.VillagesName}}</Option>-->
      <!--        </Select>-->
      <!--        <div style="width: 300px;display: inline-block;vertical-align: top;">-->
      <!--          <Input v-model="userKey" search enter-button placeholder="搜索姓名" @on-search="userSearch" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>姓名</th>
            <th>日期</th>
            <th>开始时间-结束时间</th>
            <th>村（社区）</th>
            <th>类型</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in aScheduleData">
            <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
            <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
            <td>{{value.InsiderName}}</td>
            <td>{{value.ScheduleDay}}</td>
            <td>{{value.ScheduleStartTime}}-{{value.ScheduleEndTime}}</td>
            <td>{{value.CityName}}{{value.VillagesName}}</td>
            <td>{{value.ScheduleTypeName}}</td>
            <td><Button type="primary"  @click="scheduleUserList(value.ScheduleId);modal = true" style="margin-left: 30px">查看</Button></td>
          </tr>
          </tbody>
        </table>
        <Page :total="count" :page-size="pageSize" show-elevator show-total
              @on-change="page = $event;aScheduleList(2)" />
      </div>
<!--      用户日程列表-->
      <Modal :draggable="true" v-model="modal" title="用户日程列表"
             cancel-text="关闭" >
        <div class="publicWelfare">
          <table class="formtable">
            <thead>
            <tr>
              <th>姓名</th>
              <th>电话</th>
              <th>日期</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(value,index) in fScheduleJoin.data">
              <td>{{value.UserName}}</td>
              <td>{{value.UserPhone}}</td>
              <td>{{value.JoinCreatTime}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <Page :total="fScheduleJoin.count" :page-size="fScheduleJoin.pageSize" show-elevator show-total
              @on-change="fScheduleJoin.page = $event;scheduleUserList(fScheduleJoin.ScheduleId)" />
      </Modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "aScheduleList",
  data() {
    return {
      aScheduleData:[],
      information:[],
      VillagesId : '',
      page : 1,
      pageSize : 15,
      startTime : '',
      endTime : '',
      timeData: '',
      model140: false,
      loading2: false,
      modal: false,
      options2: [],
      userKey : '',
      fScheduleJoin : {
        data :[],
        page : 1,
        count : 0,
        pageSize : 15,
        ScheduleId : '',
      },
      count : 0
    }
  },
  methods:{
    scheduleUserList : function (ScheduleId){
      var _this = this
      _this.fScheduleJoin.ScheduleId = ScheduleId
      _this.send({
        url: '/FScheduleJoinList',
        data: {
          AdminId: _this.information.AdminId,
          page: _this.fScheduleJoin.page,
          ScheduleId : ScheduleId,
          pageSize: _this.fScheduleJoin.pageSize,
        },
        success: function (res) {
          _this.fScheduleJoin.data = res.List
          _this.fScheduleJoin.count = res.Count
        }
      })
    },
    userSearch : function (e){
      this.userKey = e
      this.aScheduleList()
    },
    aScheduleList:function (){
      var _this = this
      _this.send({
        url: '/AScheduleList',
        data: {
          AdminId: _this.information.AdminId,
          page: _this.page,
          pageSize: _this.pageSize,
        },
        success: function (res) {
          _this.aScheduleData = res.List
          _this.count = res.Count
        }
      })
    },
  },
  mounted() {
    this.information = window.sessionStorage
    this.aScheduleList()
  },
}
</script>

<style scoped>

/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>
