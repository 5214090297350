<style scoped>
	.header{background-image: url(/img/legalAid/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<div class='header'></div>
		<div style="padding:0.3rem;">
			<stepMenu :step='3' :menu="['选择地区','基本信息','事项描述']"></stepMenu>
			<div style="padding:0.3rem"></div>
			<div class="bold">申请法律援助的案情及理由概述</div>
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
				<FormItem label="" prop="zxnr">
					<div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>案情及申请 理由概述：</div>
					<Input v-model="formValidate.zxnr" type="textarea" :autosize="{minRows:10,maxRows:15}" placeholder="" maxlength="300"></Input>
				</FormItem>
			</Form>
			<div style="padding: 0.3rem;"></div>
			<div style="text-align: center;">
				<router-link :to="{name:'legalAidStep2'}">
					<Button type="primary" style="width:3rem;">上一步</Button>
				</router-link>
				<span style="padding:0 0.3rem"></span>
				<Button type="primary" style="width:3rem;" @click="submit">提交</Button>
			</div>
		</div>
		<Spin size="large" fix v-if="spinShow"></Spin>
	</div>
</template>

<script>
	import stepMenu from '../../../components/stepMenu.vue'
	export default {
		name: 'step3',
		data() {
			return {
				spinShow : false
			}
		},
		mounted() {
			var _this = this
			_this.setPageSize()
		},
		computed: {
			ruleValidate: function() {
				return this.$store.state.legalAidData.ruleValidate
			},
			formValidate: function() {
				return this.$store.state.legalAidData.formValidate
			},
		},
		components: {
			stepMenu: stepMenu,
		},
		methods: {
			setPageSize: function() {
				var wsw = document.body.clientWidth
				document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
			},
			submit: function(name) {
				var _this = this
        if(SYSCONFIG.location == 'chong-qing'){
          var url = '/flyzApply_'
        }else{
          var url = '/CD_flyzApply_'
        }
				_this.spinShow = true
				_this.$refs.formValidate.validate((valid) => {
					if (valid) {
						_this.send({
							url: url,
							data: {
								Content: _this.formValidate,
								UserId: window.sessionStorage.userId,
								AreaId : window.sessionStorage.AreaId
							},
							success: function(res) {
								_this.spinShow = false
								if (res == true) {
									// _this.$Message.success('提交成功')
									_this.modal1 = true
									_this.formValidate.zxsxlxzx = '',
									_this.formValidate.zxr = '',
									_this.formValidate.xb = '',
									_this.formValidate.zjhm = '',
									_this.formValidate.mz = '',
									_this.formValidate.csrq = '',
									_this.formValidate.nl = '',
									_this.formValidate.dz = '',
									_this.formValidate.lxdh = '',
									_this.formValidate.bsqr = '',
									_this.formValidate.bsqrzjhm = '',
									_this.formValidate.bsqrdz = '',
									_this.formValidate.zxnr = '',
									_this.formValidate.orgCity = '',
									_this.formValidate.orgArea = '',
									_this.formValidate.sfclmc = []
									_this.$Modal.success({
										title:'系统通知',
										content:'法律援助申请成功！',
										onOk:function(){
											_this.$router.push({name:'legalAidListH'})
										}
									})
								} else {
									// const reg = /[\u4e00-\u9fa5]/g;
									// console.log(res.match(reg).join(''))
                  // _this.$Message.success(res.match(reg).join(''))
                  _this.$Message.error('数据填写有误，请核对后提交！')
								}
							}
						})
					} else {
						this.$Message.error('请输入完成后提交!');
						_this.spinShow = false
					}
				})
			},
		},
	}
</script>
