//重庆渝快政
import selectDistrict from '@/views/mobile/open/chong-qing/yu-kuai-zheng/select-district'
import home from '@/views/mobile/open/chong-qing/yu-kuai-zheng/home'
import index from '@/views/mobile/open/chong-qing/yu-kuai-zheng/index'
import dataCenter from '@/views/mobile/open/chong-qing/yu-kuai-zheng/dataCenter'

var routes = [
  {path: 'select-district',component:selectDistrict,name:'CQYKZSD',meta:{title:'重庆市村居法律顾问平台'}},
  {path: 'index',component:index,name:'CQYKZTransfer',meta:{title:'重庆村居法务平台 数据中心'}},
  {path: 'dataCenter',component:dataCenter,name:'dataCenter',meta:{title:'重庆村居法务平台 数据中心'}},
  {path: 'home',component:home,name:'CQYKZH',meta:{title:'重庆市村居法律顾问平台'}},
]

export default{
  routes:routes
}
