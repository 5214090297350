<style scoped>
    .bold{font-weight: 700}
    .legalAid :deep(.ivu-form-item){margin-bottom: 0rem;}
    .legalAid :deep(.ivu-radio-wrapper){white-space: break-spaces;}
    .legalAid :deep(.ivu-form-item-error-tip){padding: 0;margin-top: -0.03rem;}
</style>

<template>
    <div>
        <img style="width: 100%;height: 2.6rem" src="/img/assistance.png">
        <div style="padding: 0.3rem 0.5rem;" class="legalAid">
            <!--<div style="padding:15px"></div>-->
            <!--<div style="font-size:0.4rem;text-align: center">法律援助申请</div>-->
            <!--<div style="padding:15px"></div>-->
            <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
                <div class="bold">一、请选择申请法律援助事项类别<span style="color: red;padding: 0 0.1rem;">*</span></div>
                <FormItem prop="zxsxlxzx" >
                    <RadioGroup v-model="formValidate.zxsxlxzx">
                        <Radio label="请求给付赡养费、抚养费、扶养费">请求给付赡养费、抚养费、扶养费</Radio>
                        <Radio label="请求发给抚恤金、救济金">请求发给抚恤金、救济金</Radio>
                        <Radio label="依法请求国家赔偿或者行政补偿">依法请求国家赔偿或者行政补偿</Radio>
                        <Radio label="请求给予社会保险待遇或者最低生活保障待遇">请求给予社会保险待遇或者最低生活保障待遇</Radio>
                        <Radio label="因遭受家庭暴力、虐待、遗弃等侵权主张婚姻家庭民事权益">因遭受家庭暴力、虐待、遗弃等侵权主张婚姻家庭民事权益</Radio>
                        <Radio label="因交通事故、医疗损害、工伤事故、食品药品安全事故或者环境污染等侵权行为造成损害请求赔偿">因交通事故、医疗损害、工伤事故、食品药品安全事故或者环境污染等侵权行为造成损害请求赔偿</Radio>
                        <Radio label="因使用伪劣化肥、农药、种子、农机具和其他伪劣产品造成损害请求赔偿">因使用伪劣化肥、农药、种子、农机具和其他伪劣产品造成损害请求赔偿</Radio>
                        <Radio label="劳动者（雇员）与用人单位（雇主）发生争议，请求保护劳动（民事）权益">劳动者（雇员）与用人单位（雇主）发生争议，请求保护劳动（民事）权益</Radio>
                        <Radio label="因见义勇为或者为保护社会公共利益致使其合法权益受到损害，请求赔偿或者补偿">因见义勇为或者为保护社会公共利益致使其合法权益受到损害，请求赔偿或者补偿</Radio>
                        <Radio label="其他">其他</Radio>
                    </RadioGroup>
                </FormItem>
                <Divider dashed />
                <div class="bold">二、请填写基本信息内容</div>
                <!---->
                <FormItem label="" prop="zxr" >
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人：</div>
                    <Input v-model="formValidate.zxr" placeholder=""></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="xb">
                    <div><span style="color: red;padding: 0 0.1rem;">*</span>性别：</div>
                    <RadioGroup v-model="formValidate.xb">
                        <Radio label="男">男</Radio>
                        <Radio label="女">女</Radio>
                    </RadioGroup>
                </FormItem>
                <!---->
                <FormItem label="" prop="zjhm">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证号码：</div>
                    <Input v-model="formValidate.zjhm" placeholder=""></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="mz">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>民族：</div>
                    <Select v-model="formValidate.mz" placeholder="">
                        <Option :value="value.EthnicityName" v-for="(value,index) in nation">{{value.EthnicityName}}</Option>
                    </Select>
                </FormItem>
                <!---->
                <FormItem label="" prop="csrq">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>出生日期：</div>
                    <Input v-model="formValidate.csrq" placeholder="如：xxxx-xx-xx"></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="nl">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>年龄：</div>
                    <Input v-model="formValidate.nl" placeholder=""></Input>
                </FormItem>
                <!---->
                <!--<FormItem label="" prop="city">-->
                <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>住所地址：</div>-->
                <!--<Cascader :data="address" v-model="formValidate.dz"></Cascader>-->
                <!--</FormItem>-->
                <FormItem label="" prop="dz">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>请填写住所地址：</div>
                    <Input v-model="formValidate.dz" placeholder="请填写住所地址"></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="lxdh">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>联系电话：</div>
                    <Input v-model="formValidate.lxdh" placeholder=""></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="sfcl">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证：</div>
                    <div>1.身份证上的信息不能被遮挡，且清晰可见；

                        2.照片支持jpg/bmp格式，最大不超过2MB。</div>
                    <Upload action="https://vn-server.lvwa18.com/api/upload_file_H5"
                            　　:before-upload="onBefore"
                            　　:on-success="Picture"
                            :default-file-list="formValidate.sfcl"
                            :on-remove="deleteValuePicture"  accept=".jpg,.bmp"
                    >
                        <Button icon="md-add">上传居民身份证</Button>
                    </Upload>
                </FormItem>
                <!---->
                <!--<FormItem label="" prop="zmclmc">-->
                    <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>经济情况 证明材料：</div>-->
                    <!--<div>1.附件支持doc/docx/pdf/zip/rar/jpg/jpeg/bmp格式,最大不超过10MB</div>-->
                    <!--<Upload action="https://vn-server.lvwa18.com/api/upload_file_H5"-->
                            <!--　　:before-upload="onBefore"-->
                            <!--　　:on-success="material"-->
                            <!--:default-file-list="formValidate.zmclmc"-->
                            <!--:on-remove="deleteValuePicture1"-->
                    <!--&gt;-->
                        <!--<Button icon="md-add">上传经济情况材料</Button>-->
                    <!--</Upload>-->
                <!--</FormItem>-->
                <!--<Divider dashed />-->
                <!---->
                <div style="text-align:center;">被申请人信息</div>
                <FormItem label="" prop="bsqr">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>姓名：</div>
                    <Input v-model="formValidate.bsqr" placeholder="请填写被申请人姓名"></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="bsqrzjhm">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证号码：</div>
                    <Input v-model="formValidate.bsqrzjhm" placeholder="请输入15或18位的身份证"></Input>
                </FormItem>
                <!---->
                <!--<FormItem label="" prop="city">-->
                <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>住所地址：</div>-->
                <!--<Cascader :data="address" v-model="formValidate.bsqrdz"></Cascader>-->
                <!--</FormItem>-->
                <!---->
                <FormItem label="" prop="bsqrdz">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>请填写住所地址：</div>
                    <Input v-model="formValidate.bsqrdz" placeholder="请填写住所地址"></Input>
                </FormItem>
                <Checkbox  v-model="social" style="color:red">本人承诺以上所填内容和提交的证件、证明材料均为真实</Checkbox>
                <!---->
                <Divider dashed />
                <div class="bold">三、申请法律援助的案情及理由概述</div>
                <FormItem label="" prop="zxnr">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>案情及申请 理由概述：</div>
                    <Input v-model="formValidate.zxnr" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder=""></Input>
                </FormItem>
                <!---->
                <!--<FormItem label="" prop="ajclmc">-->
                    <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>案件材料：</div>-->
                    <!--<div>1.附件支持doc/docx/pdf/zip/rar/jpg/jpeg/bmp格式,最大不超过10MB</div>-->
                    <!--<Upload action="https://vn-server.lvwa18.com/api/upload_file_H5"-->
                            <!--　　:before-upload="onBefore"-->
                            <!--　　:on-success="caseData"-->
                            <!--:default-file-list="formValidate.ajclmc"-->
                            <!--:on-remove="deleteValuePicture2"-->
                    <!--&gt;-->
                        <!--<Button icon="md-add">上传与申请有关案件资料</Button>-->
                    <!--</Upload>-->
                <!--</FormItem>-->

                <!---->
                <!--<FormItem label="" prop="city">-->
                <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>机构所在：</div>-->
                <!--<Cascader :data="address" v-model="formValidate.orgCity"></Cascader>-->
                <!--&lt;!&ndash;orgCity市&ndash;&gt;-->
                <!--&lt;!&ndash;orgArea县&ndash;&gt;-->
                <!--</FormItem>-->
                <Divider dashed />
                <div class="bold">四、申请人选择法律援助机构</div>
                <FormItem label="" prop="orgArea">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>机构所在：</div>
                    <Select v-model="formValidate.orgCity">
                        <Option v-for="(value,index) in mediate.loadcity" :value="value">{{ value }}</Option>
                    </Select>
                    <div style="padding:5px;"></div>
                    <Select v-model="formValidate.orgArea">
                        <Option v-for="(value,index) in mediate.orgArea" :value="value">{{ value }}</Option>
                    </Select>
                    <!--<Input v-model="formValidate.orgCity" placeholder="如：成都市"></Input>-->
                    <!--<Input v-model="formValidate.orgArea" placeholder="区、县"></Input>-->
                </FormItem>
                <!---->
                <FormItem>
                    <div style="text-align: center;margin-top: 0.2rem">
                        <Button type="primary" @click="submit('formValidate')">提交</Button>
                    </div>
                </FormItem>
            </Form>
        </div>
        <!--弹窗-->
        <Modal
                v-model="modal1"
                title=""
                @on-ok="ok"
                @on-cancel="fanhui"
                ok-text="关闭"
                cancel-text="返回上一页">
            <p>申请成功</p>
        </Modal>
    </div>
</template>

<script>
    export default {
        name: "legalAid",
        data () {
            return {
                nation : [],
                vertical : '',
                modal1 : true,
                ruleValidate: {
                    zxsxlxzx: [{ required: true,trigger: 'change' ,message:'请选择申请法律援助事项类别'}],
                    zxr: [{ required: true,trigger: 'blur' ,message:' '}],
                    xb: [{ required: true,trigger: 'change',message:'请选择性别' }],
                    zjhm: [{ required: true,trigger: 'blur',message:' ' }],
                    mz: [{ required: true,trigger: 'change',message:' ' }],
                    csrq: [{ required: true,trigger: 'blur',message:' ' }],
                    nl: [{ required: true,trigger: 'blur',message: ' ' }],
                    dz: [{ required: true,trigger: 'blur',message:' ' }],
                    lxdh: [{ required: true,trigger: 'blur',message:' ' }],
                    bsqr: [{ required: true,trigger: 'blur',message:' ' }],
                    bsqrzjhm: [{ required: true,trigger: 'blur',message:' ' }],
                    bsqrdz: [{ required: true,trigger: 'blur',message:' ' }],
                    zxnr : [{ required: true,trigger: 'blur',message:' ' }],
                    orgCity : [{ required: true,trigger: 'change',message:' ' }],
                    orgArea : [{ required: true,trigger: 'change',message:' ' }],
                    sfcl : [{ required: true,trigger: 'change',message:'请上传身份证' }],
                    // sfcl : [{ required: true,trigger: 'blur',message:' ' }],
                    // zmclmc : [{ required: true,trigger: 'blur',message:' ' }],
                    // zmcl : [{ required: true,trigger: 'blur',message:' ' }],
                    // ajclmc : [{ required: true,trigger: 'blur',message:' ' }],
                    // ajcl : [{ required: true,trigger: 'blur',message:' ' }],
                },
                formValidate: {
                    zxsxlxzx : '',
                    zxr : '',
                    xb : '',
                    zjhm : '',
                    mz : '',
                    csrq : '',
                    nl : '',
                    dz : '',
                    lxdh : '',
                    bsqr : '',
                    bsqrzjhm : '',
                    bsqrdz : '',
                    zxnr : '',
                    orgCity : '',
                    orgArea : '',
                    sfclmc : [],
                    sfcl : '',
                    // zmclmc : [],
                    // zmcl : '',
                    // ajclmc : [],
                    // ajcl : '',
                },
                address: [{
                    value: 'beijing',
                    label: '成都',
                    children: [
                        {
                            value: 'gugong',
                            label: '锦江区'
                        }
                    ]
            }],
                mediate: {
                    loadcity : '',
                    orgArea:[],
                    orgZhen:[],
                    hui:[],
                    ren:[],
                }
            }
        },
        watch:{
            'formValidate.orgCity':function (val) {
                this.formValidate.orgArea = ''
                this.formValidate.orgZhen = ''
                this.formValidate.hui = ''
                this.formValidate.ren = ''
                val?this.mediate1():''
            },
            'formValidate.orgArea':function (val) {
                this.formValidate.orgZhen = ''
                this.formValidate.hui = ''
                this.formValidate.ren = ''
                val?this.mediate2():''
            },
            'formValidate.orgZhen':function (val) {
                this.formValidate.hui = ''
                this.formValidate.ren = ''
                val?this.mediate3():''
            },
            'formValidate.hui':function (val) {
                this.formValidate.ren = ''
                val?this.mediate4():''
            },
        },
        methods:{
            fanhui: function(){window.history.go(-1)},
            submit : function(name){
                var _this = this
              if(SYSCONFIG.location == 'chong-qing'){
                var url = '/flyzApply_'
              }else{
                var url = '/CD_flyzApply_'
              }
                if(_this.social){
                    _this.$refs[name].validate((valid) => {
                        if (valid) {
                            _this.send({
                                url : url,
                                data : {
                                    Content : _this.formValidate,
                                    UserId : _this.$route.query.id
                                },
                                success: function (res) {
                                    if(res == true){
                                        // _this.$Message.success('提交成功')
                                        _this.modal1 = true
                                        _this.formValidate.zxsxlxzx = '',
																				_this.formValidate.zxr = '',
																				_this.formValidate.xb = '',
																				_this.formValidate.zjhm = '',
																				_this.formValidate.mz = '',
																				_this.formValidate.csrq = '',
																				_this.formValidate.nl = '',
																				_this.formValidate.dz = '',
																				_this.formValidate.lxdh = '',
																				_this.formValidate.bsqr = '',
																				_this.formValidate.bsqrzjhm = '',
																				_this.formValidate.bsqrdz = '',
																				_this.formValidate.zxnr = '',
																				_this.formValidate.orgCity = '',
																				_this.formValidate.orgArea = '',
																				_this.formValidate.sfclmc = []
																				_this.formValidate.sfcl = []
                                        // window.history.go(-1);
                                    }else{
                                        const reg = /[\u4e00-\u9fa5]/g;
                                        console.log(res.match(reg).join(''))
                                        _this.$Message.success(res.match(reg).join(''))
                                    }

                                }
                            })
                        } else {
                            this.$Message.error('输入完成后提交传!');
                        }
                    })
                }else {
                    this.$Message.info('请选中本人承诺以上所填内容和提交的证件、证明材料均为真实！');
                }

                console.log(_this.formValidate,_this.address)
            },
            //删除身份证
            deleteValuePicture : function(file, fileList){
                var _this = this
                _this.formValidate.sfclmc = []
                _this.formValidate.sfcl = []
            },
            deleteValuePicture1 : function(file, fileList){
                var _this = this
                _this.formValidate.zmclmc = []
                _this.formValidate.zmcl = []
            },
            deleteValuePicture2 : function(file, fileList){
                var _this = this
                _this.formValidate.ajclmc = []
                _this.formValidate.ajcl = []
            },
            Picture : function(response, file, fileList){
                var _this = this
                _this.formValidate.sfclmc = response.Data.name
                _this.formValidate.sfcl = window.sessionStorage.url+response.Data.path
            },
            material : function(response, file, fileList){
                var _this = this
                _this.formValidate.zmclmc = window.sessionStorage.url+response.Data.path
                _this.formValidate.zmcl = window.sessionStorage.url+response.Data.path
            },
            caseData : function(response, file, fileList){
                var _this = this
                _this.formValidate.ajclmc = window.sessionStorage.url+response.Data.path
                _this.formValidate.ajcl = window.sessionStorage.url+response.Data.path
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
            ethnicityList : function(){
                var _this = this
                _this.send({
                    url : '/EthnicityList',
                    success: function (res) {
                        _this.nation = res
                    }
                })
            },
            onBefore : function () {

            },
            loadcity : function () {
                var _this = this
                _this.send({
                    url : '/loadcity',
                    success: function (res) {
                        _this.mediate.loadcity = res.data
                    }
                })
            },
            mediate1 : function(){
                var _this = this
                _this.send({
                    url : '/loadarea',
                    data:{
                        city:_this.formValidate.orgCity,
                    },
                    success: function (res) {
                        _this.mediate.orgArea = res.data
                    }
                })
            },
            mediate2 : function(){
                var _this = this
                _this.send({
                    url : '/loadzhen',
                    data:{
                        city:_this.formValidate.orgCity,
                        area:_this.formValidate.orgArea,
                    },
                    success: function (res) {
                        _this.mediate.orgZhen = res.data
                    }
                })
            },
        },
        mounted: function () {
            var _this = this
            this.setPageSize()
            _this.ethnicityList()
            _this.loadcity()
            window.onresize = function () {
                _this.setPageSize()
            }
        }
    }
</script>

