<style scoped>
  .bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
  .introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
  .introduce-video-player{width:100%;}
  .mediator-list{color:#FFF;}
  .mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
  .mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
  .introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
  .introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
  .introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <!---->
    <div class="introduce-text" v-if="jgcx != ''">
      <Tabs value="name1">
        <TabPane label="法律服务所、律师信息" name="name1">
          <div class="introduce-text-title">律师通讯录</div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川达和律师事务所 地址：安居路88号城市广场三楼 83696009</div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">兰  波	13908081319</div><div class="inline-block" style="width: 50%">米因泉	13808214918</div>
            <div class="inline-block" style="width: 50%">杨道友	13981872680</div><div class="inline-block" style="width: 50%">冯红兵	13688312688</div>
            <div class="inline-block" style="width: 50%">唐远雨	15208160217</div><div class="inline-block" style="width: 50%">陈  华	13111851424</div>
            <div class="inline-block" style="width: 50%">吴祯睿	15775973769</div><div class="inline-block" style="width: 50%">帅  芸	13438152192</div>
          </div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川西蜀律师事务所 地址：华金大道二段406号2栋7楼704    68036505</div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">陈昌杰	13438330270</div><div class="inline-block" style="width: 50%">周崇海	15902815227</div>
            <div class="inline-block" style="width: 50%">胡建萍	15002805260</div><div class="inline-block" style="width: 50%"></div>
          </div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川志宽律师事务所 地址：凤凰西六路28号瀚城国际35幢2楼7号         83665118</div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">陈志宽	13608030119</div><div class="inline-block" style="width: 50%">何世方	13438388288</div>
            <div class="inline-block" style="width: 50%">沈  明	13882133148</div><div class="inline-block" style="width: 50%">何顺明	13438251752</div>
            <div class="inline-block" style="width: 50%">康鹏程	13378111771</div><div class="inline-block" style="width: 50%">陈秋玉	18081006840</div>
            <div class="inline-block" style="width: 50%">钟贵楷	18030653315</div><div class="inline-block" style="width: 50%">刘文杰	 15982085646</div>
            <div class="inline-block" style="width: 50%">季雨虎	18982273660</div><div class="inline-block" style="width: 50%">张永锋	17628081587</div>
            <div class="inline-block" style="width: 50%">吴畏君	17381982931</div><div class="inline-block" style="width: 50%">邓  薇	13699451744</div>
          </div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川青平律师事务所 地址：华金大道二段389号       83666010</div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">韩常喜	13880130080</div><div class="inline-block" style="width: 50%">曾志均	15884489569</div>
            <div class="inline-block" style="width: 50%">朱晓兵	13980970703</div><div class="inline-block" style="width: 50%">李  琳	13881787612</div>
          </div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川蜀皓律师事务所 地址：华金大道二段465号       69886399</div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">陈玉豹	13018237286</div><div class="inline-block" style="width: 50%">李  莉	13684087268</div>
            <div class="inline-block" style="width: 50%">杨  虹	13880540567</div><div class="inline-block" style="width: 50%">凌  鹏	18048587336</div>
            <div class="inline-block" style="width: 50%">向永正	15928872763</div><div class="inline-block" style="width: 50%">陈  果	13759077150</div>
            <div class="inline-block" style="width: 50%">陈彦孛	13568957435</div><div class="inline-block" style="width: 50%"></div>
          </div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川君仁律师事务所 地址：华金大道562号巨人树服饰公司综合楼（西楼）六楼   83308918</div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">雍永志	18980019366</div><div class="inline-block" style="width: 50%">徐家语	 18281553035</div>
            <div class="inline-block" style="width: 50%">廖  丹 	13550007398</div><div class="inline-block" style="width: 50%">刘潇蔚	18215585309</div>
          </div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川智解律师事务所 地址： 四川省成都市青白江区同华大道99号斯迈特国际广场10楼1005、1006号    83659791</div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">曾红波	13982111849</div><div class="inline-block" style="width: 50%">冯顺德	15828587090</div>
            <div class="inline-block" style="width: 50%">张雯静	13981866460</div><div class="inline-block" style="width: 50%"></div>
          </div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川以清律师事务所 地址：青白江区华金大道二段406号2栋1层附2号</div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">梁志刚	13608040805</div><div class="inline-block" style="width: 50%"></div>
          </div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川锦英律师事务所  地址：青白江区青江北路69号16栋1单元401号        83661000</div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">王应辉	13882043114</div><div class="inline-block" style="width: 50%">罗  攀	18008086561</div>
            <div class="inline-block" style="width: 50%">曾维维	18140101708</div><div class="inline-block" style="width: 50%"></div>
          </div>
          <div class="introduce-text-content" style="text-indent:2em;">
            <div style="font-weight: bold;color:black;">四川蓉欧律师事务所         地址：化工北路18号1幢1单元14层1401号   63050871 </div>
          </div>
          <div style="text-align: left">
            <div class="inline-block" style="width: 50%">李胜发	13618083339</div><div class="inline-block" style="width: 50%"></div>
          </div>
        </TabPane>
        <TabPane label="机构查询" name="name2">
          <div style="font-size: 0.12rem;line-height: 20px;">
            <Table border :columns="columns1" :data="data1"></Table>
          </div>
        </TabPane>
      </Tabs>
    </div>
    <div class="introduce-text" v-else-if="gzz != ''">
      <Tabs value="name1">
        <TabPane label="公共法律服务信息" name="name1">
          <div style="font-size: 0.12rem;line-height: 20px;">
            <Table border :columns="gzzColumns" :data="gzzData"></Table>
          </div>
        </TabPane>
        <TabPane label="法律服务机构信息" name="name2">
          <div style="font-size: 0.26rem;font-weight: bold;color:black;box-shadow: 0px 4px 8px 0px rgb(0 128 255 / 12%);margin-bottom: 30px;padding: 30px 10px;">
            <div>1.基层法律服务所：</div>
            <div>名称：九龙县呷尔法律服务所</div>
            <div>负责人：杜健美</div>
            <div>联系电话：0836-3323148</div>
            <div>地址：四川省甘孜州九龙县呷尔镇入城大道24号1栋1单元12楼1号</div>
            <div>坐标：东经101°30′6″；北纬29°0′32″</div>
          </div>
          <div style="font-size: 0.26rem;font-weight: bold;color:black;box-shadow: 0px 4px 8px 0px rgb(0 128 255 / 12%);margin-bottom: 30px;padding: 30px 10px;">
            <div>2.法律援助机构：</div>
            <div>名称：九龙县法律援助中心</div>
            <div>负责人：杜健美</div>
            <div>地址：四川省甘孜州九龙县呷尔镇入城大道24号1栋1单元12楼1号</div>
            <div>联系电话：0836-3351241</div>
            <div>坐标：东经101°30′6″；北纬29°0′32″</div>
          </div>
          <div style="font-size: 0.26rem;font-weight: bold;color:black;box-shadow: 0px 4px 8px 0px rgb(0 128 255 / 12%);margin-bottom: 30px;padding: 30px 10px;">
            <div>3.律师事务所：</div>
            <div>（1）四川皓晖律师事务所</div>
            <div>地址：四川省甘孜州九龙县呷尔镇入城大道24号1栋1单元12楼1号</div>
            <div>法定代表人：杜健美</div>
            <div>联系电话：0836-3351241</div>
            <div>坐标：东经101°30′6″；北纬29°0′32″</div>
            <div>（2）四川法之识（九龙）律师事务所</div>
            <div>地址：四川省甘孜州九龙县呷尔镇绵九街13号4楼4-1号</div>
            <div>负责人：谭晓龙</div>
            <div>联系电话：028-85958968</div>
            <div>坐标：东经101°30′6″；北纬29°0′32″</div>
          </div>
          <div style="font-size: 0.26rem;font-weight: bold;color:black;box-shadow: 0px 4px 8px 0px rgb(0 128 255 / 12%);margin-bottom: 30px;padding: 30px 10px;">
            <div>4.公证机构：</div>
            <div>名称：四川省九龙县公证处</div>
            <div>地址：四川省甘孜州九龙县呷尔镇入城大道24号1栋1单元12楼1号</div>
            <div>法定代表人：杜健美</div>
            <div>联系电话：0836-3323570</div>
            <div>坐标：东经101°30′6″；北纬29°0′32″</div>
          </div>
        </TabPane>
      </Tabs>

    </div>
    <div class="introduce-text" v-else>
      <div class="introduce-text-title">中国（四川）自由贸易试验区成都青白江铁路港片区</br>法律服务中心</div>
      <div class="introduce-text-content" style="text-indent:2em;">
        立足自贸区，辐射大港区，是全国首个以“铁路港”为特色，政法跨部门业务协同的线上线下一体化法律服务平台。中心紧紧围绕企业和公众实际需求，整合政法各部门职能职责，实现诉讼服务、检察服务、警务服务、司法行政服务等功能服务一站式，同时，搭建多媒体平台，设置远程调解、“一带一路”外国法查明、知识产权纠纷快速解决等功能，为青白江区内企业和员工，提供国际化、高层次、多领域、便捷化法律服务。
      </div>
      <div style="padding:0.15rem;"></div>
      <div class="introduce-text-title">业务范围介绍</div>
      <div class="introduce-text-content">
        <span style="font-weight: bold;color:black;">诉讼服务：</span>立案审查、材料收转、网上立案、跨域立案、线上调解、诉讼辅导、释法答疑、法制宣传等。</br>
        <span style="font-weight: bold;color:black;">联系电话：028-61586933</span></br>
        <span style="font-weight: bold;color:black;">检察服务：</span>办理自贸特质案件、提出检察建议、行政检察、公益诉讼检察、控告申诉、法治宣传等。</br>
        <span style="font-weight: bold;color:black;">联系电话：028-83661678、028-83667787</span></br>
        <span style="font-weight: bold;color:black;">警务服务：</span>接处警、纠纷调解、打击犯罪和查处违法活动、户政、交管、出入境管理、维稳处突、法治宣传等。</br>
        <span style="font-weight: bold;color:black;">联系电话：028-83650286</span></br>
        <span style="font-weight: bold;color:black;">司法行政服务：</span>公证预约、法治宣传、人民调解、法律援助申请及受理、法律咨询、法律培训等。</br>
        <span style="font-weight: bold;color:black;">联系电话：028-83661869</span></br>
        <span style="font-weight: bold;color:black;">涉外法律服务</span>：国际仲裁、国际商事调解、外国法查明、一带一路法律服务咨询、专项涉外法律服务定制、涉外法律服务培训等。</br>
        <span style="font-weight: bold;color:black;">联系电话：028-83661869</span>
      </div>
      <div style="padding:0.15rem;"></div>
      <div class="introduce-text-title">服务优势</div>
      <div class="introduce-text-content" style="text-indent:2em;">
        整合全区政法各部门职能职责，为青白江区企业提供诉讼服务、检察服务、警务服务、司法行政服务等一站式、远程化、综合性法律服务。
      </div>
      <div style="padding:0.15rem;"></div>
      <div class="introduce-text-title">特色服务</div>
      <div class="introduce-text-content" style="text-indent:2em;">
        中心立足青白江自贸试验区成都国际铁路港特色，以国际标准化推进“一带一路”法律服务保障体系建设，汇集“中国国际经济贸易仲裁委员会成都国际仲裁中心”、“一带一路国际商事调解（西南）中心”和“一带一路外国法查明(西南)中心”的服务功能， 为企业提供个性化、定制化、全链条法律服务。
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'introduce',
  data() {
    return {
      mediatorList:[],
      jgcx : '',
      gzz : '',
      gzzColumns : [
        {
          title: '公共法律服务工作站',
          key: 'name'
        },
        {
          title: '地址',
          key: 'address'
        },
        {
          title: '负责人',
          key: 'fzr'
        },
        {
          title: '联系方式',
          key: 'telephone'
        }
      ],
      gzzData : [
        {name:'呷尔镇公共法律服务工作站',address:'九龙县呷尔镇政府（司法所内）',fzr:'谭柯',telephone:'18990464455',},
        {name:'汤古镇公共法律服务工作站',address:'九龙县汤古镇政府（司法所内）',fzr:'彭菊妹',telephone:'15387665888',},
        {name:'乃渠镇公共法律服务工作站',address:'九龙县乃渠镇政府（司法所内）',fzr:'谭柯',telephone:'18990464455',},
        {name:'三岩龙乡公共法律服务工作站',address:'九龙县三岩龙乡政府（司法所内）',fzr:'谭柯',telephone:'18990464455',},
        {name:'八窝龙乡公共法律服务工作站',address:'九龙县八窝龙乡政府（司法所内）',fzr:'谭柯',telephone:'18383604555',},
        {name:'上团乡公共法律服务工作站',address:'九龙县上团乡政府（司法所内）',fzr:'谭柯',telephone:'18383604555',},
        {name:'乌拉溪镇公共法律服务工作站',address:'九龙县乌拉溪镇政府（司法所内）',fzr:'尼尔的石',telephone:'15196317111',},
        {name:'雪洼龙镇公共法律服务工作站',address:'九龙县雪洼龙镇政府（司法所内）',fzr:'尼尔的石',telephone:'15196317111',},
        {name:'烟袋镇公共法律服务工作站',address:'九龙县烟袋镇政府（司法所内）',fzr:'沈晓龙',telephone:'19981714070',},
        {name:'魁多镇公共法律服务工作站',address:'九龙县魁多镇政府（司法所内）',fzr:'尼尔的石',telephone:'15196317111',},
        {name:'朵洛乡公共法律服务工作站',address:'九龙县朵洛乡政府（司法所内）',fzr:'马打铁',telephone:'15082314251',},
        {name:'小金乡公共法律服务工作站',address:'九龙县小金乡政府（司法所内）',fzr:'阿牛布哈',telephone:'15082314251',},
        {name:'三垭镇公共法律服务工作站',address:'九龙县三垭镇政府（司法所内）',fzr:'阿牛布哈',telephone:'15082314251',},
        {name:'子耳乡公共法律服务工作站',address:'九龙县子耳乡政府（司法所内）',fzr:'沈晓龙',telephone:'19981714070',},
        {name:'洪坝乡公共法律服务工作站',address:'九龙县洪坝乡政府（司法所内）',fzr:'肖俊成',telephone:'18380430682',},
        {name:'湾坝镇公共法律服务工作站',address:'九龙县湾坝镇政府（司法所内）',fzr:'陈文仲',telephone:'17345569973',},
      ],
      columns1: [
        {
          title: '网点名称',
          key: 'name'
        },
        {
          title: '地址',
          key: 'address'
        },
        {
          title: '咨询电话',
          key: 'telephone'
        }
      ],
      data1: [
        {
          name: '区法律援助中心',
          address: '青白江区凤凰西六路文体中心C区一楼（公共法律服务中心内）',
          telephone: '83696148',
        },
        {
          name: '大弯法律援助工作站',
          address: '青白江区华金大道二段广场西26~29号（大弯司法所内）',
          telephone: '83683318',
        },
        {
          name: '大同法律援助工作站',
          address: '大弯东路299号（大同司法所内）',
          telephone: '83623119',
        },
        {
          name: '城厢法律援助工作站',
          address: '城厢镇大南街70号（城厢司法所内）',
          telephone: '83632565',
        },
        {
          name: '弥牟法律援助工作站',
          address: '弥牟镇茶树街18-22号（弥牟司法所内）',
          telephone: '83679227',
        },
        {
          name: '姚渡法律援助工作站',
          address: '姚渡镇永和村3组（姚渡司法所内）',
          telephone: '83670616',
        },
        {
          name: '清泉法律援助工作站',
          address: '清泉镇平安路82号附23-24号（清泉司法所内）',
          telephone: '83655809',
        },
        {
          name: '福洪法律援助工作站',
          address: '福洪乡隆盛路32号（福洪司法所内）',
          telephone: '83648229',
        },
      ]
    };
 },
  mounted() {
    var _this = this
    if(_this.$route.query.jgcx){
      _this.jgcx = _this.$route.query.jgcx
    }
    if(_this.$route.query.gzz){
      _this.gzz = _this.$route.query.gzz
    }
    console.log(_this.$route.query.jgcx)
  },
  methods: {
  },
  computed:{
    userInfo:function(){
      return this.$store.state.userInfo
    },
  },
}
</script>
