<style scoped>
    .home{background-color: rgb(241,239,240);position: absolute;width: calc(100% - 240px);height: calc(100% - 120px);overflow: auto;}
    .screen{width: calc(100% - 80px);display: inline-block;}
    .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
    /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
    .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #00b86c;padding: 0px 16px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #00b86c;}
    .screen :deep(.ivu-tabs-ink-bar){background-color: #00b86c;}
    .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
    .house{width: 72px;height: 72px;margin-top: 29px;}
    .number{font-size: 48px;color: #7a7a7a;}
    .tipsName{font-size: 16px;color: #8d8d8d;}
    .displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
    .administration{width: 100%;background-color: #fff;margin-top: 40px;border-bottom: 1px solid #dddddd;height: 80px;text-align: left;margin-bottom: 0}
    .administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
    .label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
    .forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;margin:40px 40px 0 40px;height: 60px;text-align: center}
    .forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 25%;line-height: 60px;overflow: hidden;white-space: nowrap;}
    .forMcontent{width: calc(100% - 80px);height: 60px;color: #666666;font-size: 16px;margin:0px 40px 0 40px;border-bottom: 1px solid #dddddd;text-align: center}
    .forMcontent p {line-height: 60px;display: inline-block;width: 25%;overflow: hidden;white-space: nowrap;}
    .smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}
    .alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -33px;}
    .alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
    .qrCode{width: 100px;height: 100px;}
    .addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
    .myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
    .icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
    .contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
    .single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
    .inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}
</style>

<template>
    <div class="home">
        <!--<HelloWorld>-->

        <!--</HelloWorld>-->
        <div style="background-color: rgb(241,239,240);">
            <!--<div class="screen">-->
            <!--<div>本月 </div>-->
            <!--</div>-->

            <!--律所管理律师列表-->
            <div v-if="information.adminLevel == 4">
                <div class="font-size" style="width: calc(100% - 80px);display: inline-block;">
                    <div class="administration">
                        <img class="label" src="/img/label.png">
                        <p>管理</p>
                        <div class="addTo" @click="modal1 = true">添加</div>
                    </div>
                    <div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;">
                        <div class="forMtitle" v-if="listData != []">
                            <p>律师</p>
                            <p>电话</p>
                            <p>小程序码</p>
                            <p>评分</p>
                            <!--<p>操作</p>-->
                        </div>
                        <!--for-->
                        <div class="forMcontent" v-for="(value,index) in listData" :key="index">
                            <p>{{value.LawyerName}}</p>
                            <p>{{value.LawyerPhone}}</p>
                            <div style="color: #00b86c;cursor:pointer;width: 25%;display: inline-block;line-height: 60px;vertical-align: top;overflow: hidden;white-space: nowrap;">
                                <img class="smallProgram" @click="alertbox(value.LawyerQRcode,value.LawyerName,value.LawyerPhone,value.LawyerId)" src="/img/smallProgram1.png">
                                <div style="display: inline-block;margin: 0 8%"></div>
                                <Button type="success" @click="alertbox1(value.LawyerQRcode,value.LawyerName,value.LawyerId,value.LawyerPhone)">发送</Button>
                            </div>
                            <p v-if="value.avgGrade">{{value.avgGrade}}</p>
                            <p v-else>暂无评分</p>
                            <!--<div style="color: #00b86c;cursor:pointer;width: 20%;display: inline-block;line-height: 60px;vertical-align: top;overflow: hidden;white-space: nowrap;">-->
                                <!--<Button type="success" style="margin: 0 10px" @click="deleteLawyer(value.LawyerId)">删除</Button>-->
                                <!--<Button type="success" style="margin: 0 10px" @click="modal4 = true;lawyerId = value.LawyerId;lawyerName = value.LawyerName;lawyerPhone = value.LawyerPhone;OrganizationId = value.OrganizationId;lawyerId = value.LawyerId">更换律师</Button>-->
                            <!--</div>-->
                            <!--<p style="color: #00b86c;cursor:pointer" @click="deleteLawyer(value.LawyerId)">删除</p>-->
                        </div>
                        <div style="text-align: left;margin: 40px;font-size: 16px">
                            <!--<Page :total="count" page-size="5"  show-elevator />-->
                            <!--<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>-->
                            <Page
                                    :total="count"
                                    :page-size="pageSize"
                                    show-elevator
                                    @on-change="page = $event;lawList()"
                            />
                        </div>
                    </div>
                </div>

            </div>
            <!---->

            <!---->
        </div>
        <!--弹出-->
        <Modal
                v-model="modal1"
                title="添加"
                @on-ok="ok"
                @on-cancel="cancel">
            <div class="single">
                <p class="contacts">律师名称</p>
                <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName">
            </div>
            <div class="single">
                <p class="contacts">律师电话</p>
                <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone">
            </div>
        </Modal>
        <!--更换律师-->
        <Modal
                v-model="modal4"
                title="更换律师"
                @on-ok="replace();"
                @on-cancel="cancel">
            <div class="single">
                <p class="contacts">律师名称</p>
                <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName">
            </div>
            <div class="single">
                <p class="contacts">律师电话</p>
                <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone">
            </div>
        </Modal>
        <!---->
        <Modal
                v-model="modal2"
                title="添加"
                @on-ok="addMember"
                @on-cancel="cancel">

            <div class="single">
                <p class="contacts">村居律所名称</p>
                <input type="text" class="inputWord" placeholder="请输入村居律所名称" v-model="organizationName">
            </div>
            <div class="single">
                <p class="contacts">联系人</p>
                <input type="text" class="inputWord" placeholder="请输入联系人" v-model="organizationLinkman">
            </div>
            <div class="single">
                <p class="contacts">联系电话</p>
                <input type="text" class="inputWord" placeholder="请输入联系电话" v-model="organizationPhone">
            </div>
            <div class="single">
                <p class="contacts">登录名称</p>
                <input type="text" class="inputWord" placeholder="请输入登录名" v-model="adminLogin">
            </div>
            <div class="single">
                <p class="contacts">登录密码</p>
                <input type="text" class="inputWord" placeholder="请输入登录密码" v-model="adminPassword">
            </div>
        </Modal>
        <!--查看img-->
        <Modal
                v-model="modal3"
                :title="fileName"
                @on-ok="ok"
                @on-cancel="cancel">
            <!--<p>{{fileurl}}</p>-->
            <Carousel v-model="value1" v-if="modal3">
                <CarouselItem v-for="(value,index) in fileurl">
                    <img :src="value" style="width: 100%;">
                </CarouselItem>
            </Carousel>
        </Modal>

        <!--弹窗img-->
        <Modal
                v-model="modal5"
                :title="lawName+'的太阳码'"
                @on-ok="downloadUrl"
                @on-cancel="cancel"
                ok-text="下载"
                cancel-text="关闭"
                width="350">
            <div style="text-align: center">
                <!--<img v-if="modal5" :src="urlImg" style="width: 300px;height: 300px">-->
                <div style="width: 300px;height: 300px">
                    <img v-if="modal5 && lawImg" :src="lawImg" style="width: 300px;height: 300px">
                </div>

            </div>
        </Modal>
        <!--弹窗发送-->
        <Modal
                v-model="modal6"
                :title="lawName+'的太阳码'"
                @on-ok="sendOut"
                @on-cancel="cancel"
                ok-text="发送"
                cancel-text="关闭"
                width="350">
            <div style="text-align: center">
                <p style="font-size: 16px">是否发送律师小程序码至手机</p>
                <!--<img v-if="modal6" :src="urlImg" style="width: 300px;height: 300px">-->
                <div style="width: 300px;height: 300px">
                    <img v-if="modal6 && lawImg" :src="lawImg" style="width: 300px;height: 300px">
                </div>

            </div>
        </Modal>
    </div>
</template>

<script>
    // import HelloWorld from '../../components/HelloWorld.vue'
    export default {
        name: "home",
        // components: {HelloWorld,},
        data () {
            return {
                value1 : 0,
                fileurl : '',
                fileName : '',
                lawyerId : '',
                modal3: false,
                box : 0,
                modal1: false,
                modal2: false,
                modal4: false,
                modal5: false,
                modal6: false,
                listData : [],
                arCode : false,//二维码
                current : '',
                lawyerPhone : '',
                lawyerName : '',
                information :[],
                page : 1,
                pageSize : 5,
                count : 0,
                page1 : 1,
                pageSize1 : 5,
                count1 : 0,
                adminLogin : '',
                adminPassword : '',
                organizationPhone : '',
                organizationLinkman : '',
                organizationName : '',
                urlImg : '',
                lawImg : '',
                lawName : '',
                lawyerId1 : '',
                lLawyerPhone : '',
                statisticsData : [
                    {name : '服务社区（村）数量',value:''},
                    {name : '服务用户数量',value:''},
                    {name : '律师数量',value:''},
                    {name : '咨询消息数量',value:''}
                ],
                timelist : [
                    {name:'本月',value:0},
                    {name:'本季',value:1},
                    {name:'本年',value:2},
                    {name:'总计',value:3},
                ],
                activeListData : [],
            }
        },
        methods:{
            alertbox:function(url,name,lawyerPhone,LawyerId){
                var _this = this
                var op = _this.information.OrganizationId+'p'+lawyerPhone +'p'+1+'p'+LawyerId+''
              if(SYSCONFIG.location == 'chong-qing'){
                var url = '/getLawyerSunCode'
              }else {
                var url = '/CD_getLawyerSunCode'
              }
                _this.send({
                    url : url,
                    data: {
                        OrganizationId : _this.information.OrganizationId,
                        scene: op,
                        userType : '2',
                        page: 'pages/login/login',
                        width: '430',
                        jpg: 'jpg',
                        LawyerId : LawyerId
                    },
                    success: function (res) {
                        _this.lawImg = 'https://vn-server.lvwa18.com/'+res
                    }
                })
                console.log(op)
                _this.modal5 = true
                _this.urlImg = url
                _this.lawName = name
            },
            alertbox1:function(url,name,lawyerId1,lLawyerPhone){
                var _this = this
                var op = _this.information.OrganizationId+'p'+lLawyerPhone +'p'+1+'p'+lawyerId1+''
              if(SYSCONFIG.location == 'chong-qing'){
                var url = '/getLawyerSunCode'
              }else {
                var url = '/CD_getLawyerSunCode'
              }
                _this.send({
                    url : url,
                    data: {
                        OrganizationId : _this.information.OrganizationId,
                        scene: op,
                        userType : '2',
                        page: 'pages/login/login',
                        width: '430',
                        jpg: 'jpg',
                        LawyerId : lawyerId1
                    },
                    success: function (res) {
                        _this.lawImg = 'https://vn-server.lvwa18.com/' +res
                    }
                })
                console.log(op)
                _this.modal6 = true
                _this.urlImg = url
                _this.lawName = name
                _this.lawyerId1 = lawyerId1
                _this.lLawyerPhone = lLawyerPhone
            },
            downloadUrl : function(){
                var _this = this
                _this.modal5 = false
                // window.URL = _this.urlImg
                var downloadIamge = function(imgsrc, name) { //下载图片地址和图片名
                    let image = new Image();
                    // 解决跨域 Canvas 污染问题
                    image.setAttribute("crossOrigin", "anonymous");
                    image.onload = function() {
                        let canvas = document.createElement("canvas");
                        canvas.width = image.width;
                        canvas.height = image.height;
                        let context = canvas.getContext("2d");
                        context.drawImage(image, 0, 0, image.width, image.height);
                        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
                        let a = document.createElement("a"); // 生成一个a元素
                        let event = new MouseEvent("click"); // 创建一个单击事件
                        a.download = name || "photo"; // 设置图片名称
                        a.href = url; // 将生成的URL设置为a.href属性
                        a.dispatchEvent(event); // 触发a的单击事件
                    };
                    image.src = imgsrc;
                }
                downloadIamge(_this.urlImg, _this.lawName+".jpg");
            },
            // box: function(e){
            //     console.log(123,e)
            // },
            deleteLawyer : function(lawyerId){
                var _this = this
                _this.send({
                    url : '/DeleteLawyer',
                    confirm:true,
                    data: {
                        OrganizationId : _this.information.OrganizationId,
                        LawyerId : lawyerId,
                    },
                    success: function (res) {
                        console.log(res)
                        _this.listData = []
                        _this.page = '1'
                        _this.lawList()
                    }
                })
            },
            lawList :function(){
                var _this = this
                _this.send({
                    url : '/LawyerListById',
                    data: {
                        OrganizationId : _this.information.OrganizationId,
                        page: _this.page,
                        pageSize : _this.pageSize,
                    },
                    success: function (res) {
                        _this.listData = res.List
                        _this.count = res.count

                    }
                })
            },
            addMember :function(){
                var _this = this
                _this.send({
                    url : '/AddMember',
                    data: {
                        Level : _this.information.adminLevel,
                        AdminId : _this.information.AdminId,
                        AdminLogin : _this.adminLogin,
                        AdminPassword : _this.adminPassword,
                        OrganizationLinkman : _this.organizationLinkman,
                        OrganizationPhone : _this.organizationPhone,
                        OrganizationName : _this.organizationName,

                    },
                    success: function (res) {
                        _this.adminList()
                    }
                })
            },
            ok:function () {
                var _this = this
                var op = _this.information.OrganizationId+'p'+_this.lawyerPhone +'p'+1
                if(_this.lawyerName != '' && _this.lawyerPhone != ''){
                    _this.send({
                        url : '/getVillageSunCode',
                      consulting1:true,
                      responseType:"arraybuffer",
                        data: {
                            OrganizationId : _this.information.OrganizationId,
                            scene: op,
                            userType : '2',
                            page: 'pages/login/login',
                            width: '430',
                            jpg: 'jpg',
                            LawyerName : _this.lawyerName,
                            LawyerPhone : _this.lawyerPhone
                        },
                        success: function (res) {
                            _this.listData = []
                            _this.page = '1'
                            _this.lawList()
                            console.log(res)
                        }
                    })
                }else {
                    _this.$Notice.warning({
                        title:'系统提示',
                        desc:'名称或电话不能为空',
                    })
                }
            },
            cancel () {

            },
            enter:function(index){
                console.log(index)
                this.arCode = true
                this.current = index
            },
            leave(){
                this.arCode = false
                this.current = null
            },
            see : function (fileurl,fileName) {
                this.fileurl = fileurl.split(",")
                this.fileName = fileName
            },
            replace : function () {
                var _this = this
                var op = _this.information.OrganizationId+'p'+_this.lawyerPhone +'p'+1
                _this.send({
                    url : '/getVillageSunCode',
                  consulting1:true,
                  responseType:"arraybuffer",
                    data: {
                        OrganizationId : _this.information.OrganizationId,
                        scene: op,
                        userType : 2,
                        page: 'pages/login/login',
                        width: '430',
                        jpg: 'jpg',
                        LawyerName : _this.lawyerName,
                        LawyerPhone : _this.lawyerPhone,
                        LawyerId : _this.lawyerId
                    },
                    success: function (res) {
                        _this.listData = []
                        _this.page = '1'
                        _this.lawList()
                    }
                })
            },
            sendOut : function () {
                var _this = this
                _this.send({
                    url : '/SendMessage',
                    data: {
                        LawyerId:_this.lawyerId1,
                        LawyerPhone:_this.lLawyerPhone},
                    success: function (res) {
                        if(res.Code == 'OK'){
                            _this.$Notice.warning({
                                title:'系统提示',
                                desc:'发送成功',
                            })
                        }
                    }
                })
            }
        },
        mounted () {
            var _this = this
            if(window.sessionStorage.adminLevel == undefined){
                // this.$router.push({name:'login'})
            }else {
                // var data = JSON.parse(window.sessionStorage.information)
                // SYSCONFIG.server.information = JSON.parse(window.sessionStorage.information)
                // console.log(SYSCONFIG.server.information.AdminLogin)
                this.information = window.sessionStorage
                if(_this.information.adminLevel == 4){
                    _this.lawList()
                    console.log(this.information)
                }
            }
        },
    }
</script>

