<style scoped>
	.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
	.menu{font-size:0.24rem;padding:0.3rem 0.3rem;}
	.list{padding:0.2rem 0;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<Modal
			v-model="detialPanel.state"
			title="申请详情"
			ok-text="关闭"
			cancel-text
		>
			<template v-if="detialPanel.data.Content">
				<div>姓名：{{detialPanel.data.Content.applyName}}</div>
				<div>性别：{{detialPanel.data.Content.sex}}</div>
				<div>身份证号码：{{detialPanel.data.Content.idCard}}</div>
				<div>民族：{{detialPanel.data.Content.nation}}</div>
				<div>出生日期：{{detialPanel.data.Content.birthday}}</div>
				<div>年龄：{{detialPanel.data.Content.age}}</div>
				<div>住所地址：{{detialPanel.data.Content.address}}</div>
				<div>联系电话：{{detialPanel.data.Content.phone}}</div>
			</template>
		</Modal>
		<div class='header'></div>
		<div class='body'>
			<div class="menu">
				<Row>
					<Col span="12" style="font-size:0.3rem;font-weight: bold;"><Icon type="ios-list" style="font-size:0.5rem;"/>申请列表</Col>
					<Col span="12" style="text-align: right;">
						<router-link :to="{name:'MUCAS1'}"><span style="background-color:#008fd6;padding:0.17rem 0.45rem;border-radius:0.1rem;color: #FFF;">去申请</span></router-link>
					</Col>
				</Row>
			</div>
			<ul v-if="list.length>0">
				<li v-for="(item,index) in list" :style="index%2==0?'background-color: #fff;':''" class='list'>
					<Row type="flex" align="middle" style="font-size:0.2rem;padding:0.1rem 0.3rem;">
						<Col span="20">
							<Row>
								<Col span="24" style="font-size: 0.28rem;font-weight: bold;padding-bottom:0.1rem">{{item.Content.applyType}} | 调解员：{{item.Content.ren}} </Col>
								<Col span="24" style="color:#666666">
									进度：<span style="color: green;">{{item.Content.applyName}}</span> | 申请时间：{{item.CreateTime}}
								</Col>
							</Row>
						</Col>
						<Col span="4" style="color:#008fd6"><span @click="getDetail(item)">查看详情 ></span></Col>
					</Row>
				</li>
			</ul>
			<div v-else style="background-image:url(/img/empty.png);height:4.95rem;width:6.9rem;background-size:100% 100%;margin:1.1rem auto;"></div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'list',
		data(){
			return{
				list:[],
				detialPanel:{
					state:false,
					data:{},
					result:{},
				},
			}
		},
		mounted(){
			var _this = this
			if(window.sessionStorage.userId == undefined){
				if( _this.$route.query.id){
					window.sessionStorage.userId = _this.$route.query.id
          window.sessionStorage.AreaId = _this.$route.query.AreaId
          window.sessionStorage.pid = _this.$route.query.pid
					_this.getList()
				}else{
					_this.$Modal.error({
						title:'系统通知',
						content:'未获取到用户信息！请重新进入！',
					})
				}
			}else{
        if( _this.$route.query.id){
          window.sessionStorage.userId = _this.$route.query.id
          window.sessionStorage.AreaId = _this.$route.query.AreaId
          window.sessionStorage.pid = _this.$route.query.pid
        }
			  _this.getList()
      }
			_this.setPageSize()
		},
		methods:{
			getList:function(){
				var _this = this
				_this.send({
					url:'/mediateList',
					data:{
						UserId:window.sessionStorage.userId,
					},
					success:function(res){
						_this.list = res.List
					}
				})
			},
			getDetail:function(item){
				var _this = this
				_this.detialPanel.state = true
				_this.detialPanel.data = item
				return
				_this.send({
					url:'/mediate_resAdmin',
					data:{
						UserId :window.sessionStorage.userId,
						MediateId :item.MediateId,
					},
					success:function(res){
						_this.detialPanel.state = true
						_this.detialPanel.data = item
						_this.detialPanel.result = res.data
					}
				})
			},
			setPageSize:function() {
			    var wsw = document.body.clientWidth
			    document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
			},
		},
	}
</script>
