<template>
  <div>
    <div style="background: url(/img/dataStatistics/auditbg.png) no-repeat;width: 7.5rem;height: 2.66rem">
        <div style="font-size: 0.36rem;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;line-height: 2.66rem;text-align: center;">— {{type==4?'待审核档案':'待处理咨询'}} —</div>
<!--        <div style="width: 7.5rem;height: 0.86rem;background: rgba(255,255,255,0.2);text-align: center">-->
<!--            <div :class="value.type == type?'auditTrue':'auditFalse'" v-for="(value,index) in TabsData" @click="auditClick(value.type)">-->
<!--              {{value.name}}-->
<!--              <div v-if="value.type == type" style="position: absolute;width: 0.57rem;height: 0.06rem;background: #FFFFFF;border-radius: 0.03rem;bottom: 0.1rem;left: 50%;transform: translate(-50%, 0%);"></div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
<!--    搜索-->
    <div style="">
      <div style="width: 6.9rem;height: 0.76rem;background: #F3F4F4;border-radius: 0.38rem;position: relative;margin: 0.27rem 0.3rem;">
          <img src="/img/dataStatistics/sousuo.png" style="width: 0.36rem;height: 0.36rem;position: absolute;top: 0.2rem;left: 0.2rem">
          <input v-model="keyWord" placeholder="输入关键字搜索" style="width: 6.9rem;height: 0.76rem;background: #F3F4F4;border-radius: 0.38rem;padding: 0 2rem 0 0.72rem;"/>
          <div @click="auditevent()" style="width: 1.6rem;height: 0.76rem;background: #486FEB;border-radius: 0.38rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.76rem;text-align: center;position: absolute;top: 0;right: 0">搜 索</div>
      </div>
      <div style="width: 7.5rem;height: 0.86rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #808990;line-height: 0.86rem;text-align: center;background: #EBEFF2;">
        <div style="width: 20%;display: inline-block;">序号</div>
        <div style="width: 40%;display: inline-block;">名称</div>
        <div style="width: 40%;display: inline-block;">
          <div style="display: inline-block;width: 50%">数量</div>
          <div style="display: inline-block;width: 50%"></div>
        </div>
      </div>
      <router-link :to="{ name: 'oneClickLogin' ,query:{key:$route.query.key,secret:$route.query.secret,type:1,data:type==4?'archives':'consultingService'}}" v-for="(value,index) in auditData" :style="{background:index%2==0?'#FFF':'#F8F9FA',color:(index==0?'#cb040f':index==1?'#eb0916':index==2?'#ea2a35':index==3?'#ee414b':index==4?'#f2555d':'#808990')}">
      <div style="width: 7.5rem;height: 0.86rem;font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;line-height: 0.86rem;text-align: center">
        <div style="width: 20%;display: inline-block;">{{ index+1 }}</div>
        <div style="width: 40%;display: inline-block;white-space: nowrap;overflow: hidden;vertical-align: top;">
          <span v-if="value.CityName">{{ value.CityName.replace('重庆市', '') }}</span>
          <span>{{value.AdminName||value.VillagesName}}</span>
        </div>
        <div style="width: 40%;display: inline-block;">
          <div style="display: inline-block;width: 50%">{{value.count}}</div>
          <div style="display: inline-block;width: 50%;text-align: right;vertical-align: top;">
            <div style="width: 1.1rem;height: 0.46rem;background: #FFF;border-radius: 0.04rem;display: inline-block;text-align: center;margin: 0.2rem 0.3rem 0 0">
              <img src="/img/dataStatistics/remindFalse.png" style="width: 0.26rem;height: 0.3rem;vertical-align: top;margin: 0.08rem 0.09rem 0 0;">
              <div style="display: inline-block;font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #CACDD0;line-height: 0.46rem;vertical-align: top">提醒</div>
            </div>
<!--            <div style="width: 1.1rem;height: 0.46rem;background: #466BE2;border-radius: 0.04rem;display: inline-block;text-align: center;margin: 0.2rem 0.3rem 0 0">-->
<!--              <img src="/img/dataStatistics/remindTrue.png" style="width: 0.26rem;height: 0.3rem;vertical-align: top;margin: 0.08rem 0.09rem 0 0;">-->
<!--              <div style="display: inline-block;font-size: 0.24rem;font-family: PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 0.46rem;vertical-align: top">提醒</div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "auditList",
  data(){
    return{
      TabsData : [
        {name:'待审核档案',type:4},
        {name:'待处理咨询',type:5}
      ],
      type : 4,
      auditData : [],
      keyWord : '',
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.auditevent()
  },
  methods : {
    // 待办数据列表
    auditevent : function (){
      var _this = this
      // 咨询
      if(this.type == 5){
        _this.send({
          url: '/filesDealt',
          data: {
            id: this.$route.query.id,
            Token: '1',
            keyWord : _this.keyWord,
          },
          success: function (res) {
            res.sort((item,item1)=>{
              return item1.count-item.count
            })
              _this.auditData =res
          }
        })
      }
      // 档案
      if(this.type == 4){
        _this.send({
          url: '/advice',
          data: {
            id: this.$route.query.id,
            Token: '1',
            keyWord : _this.keyWord,
          },
          success: function (res) {
            res.sort((item,item1)=>{
              return item1.count-item.count
            })
            _this.auditData =res
          }
        })
      }
    },
    // 待办数据切换
    auditClick : function (type){
      this.type = type
      this.auditevent()
    },
  },
}
</script>

<style scoped>
.auditTrue{font-size: 0.26rem;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;line-height: 0.86rem;display: inline-block;width: 50%;position: relative;}
.auditFalse{font-size: 0.26rem;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;line-height: 0.86rem;opacity: 0.5;display: inline-block;width: 50%;position: relative;}
</style>