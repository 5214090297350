<style scoped>
</style>

<template>
  <router-view></router-view>
</template>

<script>
export default {
  name:'nullPage',
  data() {
    return {
      
    };
 },
  methods: {
    
  },
  mounted() {

  },
}
</script>