<style scoped>
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
  .details p{color: #000000}
  .details span{color: #5c6b77}
</style>

<template>
  <div>
    <div style="background-color: white;margin:20px;">
      <div style="padding:15px"></div>
    <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px;height:2em">
      社区矫正
      <Button type="error" style="float:right;margin-right:20px" :to="{name:'correctionC'}">课程发布</Button>
    </div>
    <Menu mode="horizontal" :active-name="organization.selected" @on-select="changeMenu">
      <MenuItem v-for="(item,index) in organization.list" :name="item.id">
          {{item.name}}
      </MenuItem>
    </Menu>
    <div style="padding:15px"></div>
      <div class="publicWelfare" >
          <table class="formtable">
              <thead>
                <tr>
                  <th>名称</th>
                  <th>发布单位</th>
                  <th>学习人次</th>
                  <th>考试人次</th>
                  <th>发布时间</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="(value,index) in legalAidData">
                  <td>{{value.CurriculumTitle}}</td>
                  <td>{{value.AdminName}}</td>
                  <td>{{value.StudyCount}}</td>
                  <td>{{value.ExamCount}}</td>
                  <td>{{value.CreatedTime}}</td>
                  <td>
                    <template v-if="value.AdminId==ownId">
                      <Button type="primary" :to="{name:'correctionC',query:{correctionId:value.CurriculumId}}">课程内容</Button>
                      <span style="padding:10px;"></span>
                      <Button type="warning" :to="{name:'correctionR',query:{correctionId:value.CurriculumId}}">课程详情</Button>
                      <span style="padding:10px;"></span>
                      <Button type="error" @click="deleteCorrection(value.CurriculumId)">删除</Button>
                    </template>
                    <template v-else>
                      无法操作非本账户发布的课程
                    </template>
                  </td>
              </tr>
              </tbody>
          </table>
      </div>
      <div style="text-align: left;margin-left: 40px;font-size: 16px;padding-bottom: 20px">
          <!-- <Page
            :total="count"
            show-total
            :page-size="pageSize"
            show-elevator
            @on-change="page = $event;adminflyzApplyList()"
          /> -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
      name: "mediateList",
      data () {
          return {
            information : [],
            legalAidData : [],
            count : '0',
            pageSize : 10,
            page : 1,
            modal1 : false,
            detailsData : [],
            sfclmc : '',
            organization:{
              selected:1,
              list:[
                {id:1,name:'简城司法所',},
                {id:2,name:'射洪坝司法所',},
                {id:3,name:'平泉司法所',},
                {id:4,name:'禾丰司法所',},
                {id:5,name:'三星司法所',},
                {id:6,name:'云龙司法所',},
                {id:7,name:'镇金司法所',},
                {id:0,name:'其他',},
              ],
            },
          }
      },
      computed:{
        ownId:function(){
          return window.sessionStorage.AdminId
        },
      },
      methods : {
        changeMenu:function(params){
          this.organization.selected = params
          this.adminflyzApplyList()
        },
        deleteCorrection:function(id){
          var _this = this
          _this.send({
              url:'/CurriculumDelete',
              data:{
                  AdminId:window.sessionStorage.AdminId,
                  CurriculumId:id,
              },
              confirm:true,
              success:function (res) {
                _this.$Notice.success({
                  title:'系统提示',
                  desc:'数据更新成功！'
                })
                  _this.adminflyzApplyList()
              }
          })
        },
          adminflyzApplyList : function () {
              var _this = this
              _this.legalAidData = []
              _this.send({
                  url:'/CurriculumList',
                  data:{
                    TeamTypeId:_this.organization.selected,
                      AdminId:window.sessionStorage.AdminId
                  },
                  success:function (res) {
                      _this.legalAidData = res
                  }
              })
          },
          see : function (e) {
              console.log(e)
              this.detailsData = e
          },
          sfclmcImg : function (e) {
              this.sfclmc = e
          }
      },
      mounted (){
          var _this = this
          if(window.sessionStorage.adminLevel == undefined){
              // this.$router.push({name:'login'})
          }else {
              this.information = window.sessionStorage
              _this.adminflyzApplyList()
          }
      }
  }
</script>


