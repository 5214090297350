import nullPage from '@/views/nullPage'

const gtfb={
  introduce:require('@/views/mobile/customization/qing-bai-jiang/introduce').default,
}

const routes = [
  {
    path:'qing-bai-jiang',
    component:nullPage,
    children:[
      {
        path:'gtfb',
        component:nullPage,
        children:[
          {path:'introduce',name:'CQBJGI',component:gtfb.introduce,meta:{title:'成都青白江铁路港片区法律服务中心'}},
        ]
      },
    ]
  },
]

export default {
  routes:routes
}