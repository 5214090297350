<style scoped>
	.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<div class='header'></div>
		<div style="padding:0.3rem">
			<stepMenu :step='1' :menu="['选调解员','基本信息','事项描述']"></stepMenu>
			<div style="padding:0.3rem"></div>
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
        <FormItem label prop>
          <div class="inline-block">
            <span style="color: red;padding: 0 0.1rem;">*</span>选择百米服务区调解员：
          </div>
          <br />
          <div style="text-align: center">
            <FormItem label prop="MediatorId">
              <Select v-model="formValidate.MediatorId" @on-change="$router.push({name:'CJYMUAS2'})">
                <Option v-for="(item,index) in mediatorList" :value="item.MediatorId">{{ item.MediatorName }}</Option>
              </Select>
            </FormItem>
          </div>
        </FormItem>
			</Form>
		</div>
		<div style="padding: 0.3rem;"></div>
		<div style="text-align: center;">
			<router-link :to="{name:'CJYMUAS2'}">
			  <Button type="primary" style="width:3rem;">下一步</Button>
			</router-link>
		</div>
	</div>
</template>

<script>
  import stepMenu from '@/components/stepMenu.vue'
  export default {
    name: 'step1',
    data() {
      return {
        mediatorList:[],
      }
    },
    methods: {
      getmediatorList:function(){
        var _this = this
        _this.send({
          url:'/VillagesMediatorList',
          data:{
            VillagesId:_this.$store.state.userInfo.VillagesId,
          },
          success:function(res){
            _this.mediatorList = res
          }
        })
      },
    },
    beforeRouteLeave: function (to, form, next) {
      if (to.name == 'CJYMUAS2') {
        this.$refs.formValidate.validate((valid) => {
          if (valid == false) {
            this.$Message.error('请录入完成后再进行下一步操作!');
          } else {
            next()
          }
        })
      } else {
        next()
      }
    },
    mounted() {
      var _this = this
      _this.getmediatorList()
    },
    components: {
      stepMenu: stepMenu,
    },
    computed: {
      ruleValidate: function () {
        return this.$store.state.mobile.customization.jianYang.mediate.apply.ruleValidate
      },
      formValidate: function () {
        return this.$store.state.mobile.customization.jianYang.mediate.apply.formValidate
      },
    },
  }
</script>
