<template>
    <div style="font-family: PingFang SC;">
        <div style="background: url(/img/caseInput/bjInput.png);background-size: 100% 100%;width: 7.5rem;height: 3.6rem;">
            <div style="font-size: 0.48rem;font-weight: bold;color: #000000;padding: 1rem 0 1rem 0.57rem;">录入案件信息</div>
        </div>
        <div style="margin-left: 0.57rem;font-size: 0;margin-top: -1.15rem">
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.59rem 0 0.2rem 0">案件名称</div>
            <input v-model="form.caseName" style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;" placeholder="请输入案件名称" />
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.4rem 0 0.2rem 0">案号</div>
            <input v-model="form.caseNum" style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;" placeholder="请输入案号" />
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.4rem 0 0.2rem 0">案由</div>
            <input @click="causeshow" readonly="readonly" v-model="form.caseTypeName" style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;" placeholder="请选择案由" />
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.4rem 0 0.2rem 0">案件律师</div>
            <input v-model="form.lawyerName" @click="lawShow = true" readonly="readonly" style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;padding-left: 0.2rem;font-size: 0.28rem;font-weight: 500;" placeholder="请输入案件律师" />
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.4rem 0 0.2rem 0">金额区间</div>
            <div style="width: 6.4rem;height: 0.86rem;background: #F6F6F6;border-radius: 0.04rem;font-size: 0.28rem;font-weight: 500;">
                <div style="width: calc(54% - 0.2rem);position: relative;display: inline-block;">
                    <input style="width: 100%;height: 0.86rem;padding-left: 0.2rem;" placeholder="请输入金额（大值）" v-model="form.caseCostMax"/>
                    <div style="position: absolute;right: 0;top: 0.25rem;">元 -</div>
                </div>
                <div style="width: calc(49% - 0.2rem);position: relative;display: inline-block;">
                    <input style="width: 100%;height: 0.86rem;padding-left: 0.2rem;" placeholder="请输入金额（小值）" v-model="form.caseCostMin"/>
                    <div style="position: absolute;right: 0;top: 0.25rem;">元</div>
                </div>
            </div>
            <div style="font-size: 0.28rem;font-weight: 500;color: #000000;margin: 0.4rem 0 0.2rem 0">案件详情</div>
            <textarea style="width: 6.4rem;height: 2.8rem;background: #F6F6F6;border-radius: 0.04rem;padding: 0.3rem 0 0 0.2rem;font-size: 0.28rem;font-weight: 500;" placeholder="案件详情"  v-model="form.caseDetail">
            </textarea>
            <div @click="submit" style="width: 6.4rem;height: 0.86rem;background: #1186E1;border-radius: 0.04rem;font-size: 0.28rem;font-weight: bold;color: #FFFFFF;text-align: center;
            line-height: 0.86rem;margin-top: 0.4rem;">提 交</div>
        </div>
        <div style="padding: 0.6rem;"></div>
        <!--弹出-->
        <van-popup v-model:show="show" position="bottom"  >
            <!--<div style="height: 0.6rem;line-height: 0.6rem;">-->
                <!--<div style="display: inline-block;margin-left: 0.3rem;" @click="show = false">取消</div>-->
                <!--<div style="display: inline-block;float: right;margin-right: 0.3rem;" @click="onDetermine">确定</div>-->
            <!--</div>-->
            <!--<van-picker-->
                    <!--:columns="caseTypeData"-->
                    <!--@change="onConfirm"-->

            <!--/>-->
            <van-picker show-toolbar :columns="caseTypeData" @confirm="onConfirm"/>
        </van-popup>
        <van-popup v-model:show="lawShow" position="bottom"  >
            <div style="height: 0.6rem;line-height: 0.6rem;">
                <div style="display: inline-block;float: right;margin-right: 0.3rem;" @click="lawShow = false">确定</div>
            </div>
            <CheckboxGroup v-model="checked" @on-change="onLawyer" style="padding: 0.3rem;">
                <Checkbox v-for="(value,index) in lawyerArrData" :label="value.lawyerId+','+value.lawyerName">{{value.lawyerName}}</Checkbox>
            </CheckboxGroup>
        </van-popup>
    </div>
</template>

<script>
    import 'vant/lib/index.css';
    import Vue from 'vue'
    import { Popup } from 'vant';
    import { Picker } from 'vant';
    Vue.use(Popup);
    Vue.use(Picker);
    export default {
        name: "informationInput",
        data() {
            return {
                title : '案由',
                caseTypeData : [],
                lawyerArrData : [],
                SelectcaseTypeData : [],
                form:{
                    lawOfficeId : '',
                    caseName: '',//案件名称
                    caseNum: '',//案号
                    caseType:'', //案由id
                    caseTypeName:'', //案由名称
                    lawyerIdArr : [],//律师id集
                    lawyerName : [],//律师名称
                    caseCostMin:'',//金额区间 （小值）
                    caseCostMax:'',//金额区间 （大值）
                    caseDetail:'', //案件详情
                    caseId : ''
                },
                show : false,
                lawShow : false,
                checked : [],
            }
        },
        methods :{
            submit : function(){
                var _this = this
                _this.form.lawyerIdArr = _this.form.lawyerIdArr.toString()
                console.log(_this.form.lawyerIdArr)
                if(_this.form.caseName != ''){
                    if(_this.form.caseNum != ''){
                        if(_this.form.caseType != ''){
                            if(_this.form.lawyerIdArr != ''){
                                if(_this.form.caseCostMin != ''&&_this.form.caseCostMax != ''){
                                    if(_this.form.caseDetail != ''){
                                        _this.send({
                                            url: '/LawCaseAdd',
                                            data: _this.form,
                                            success: function (res) {
                                                if(_this.$route.query.caseId){
                                                    _this.$Message.success('修改成功');
                                                }else {
                                                    _this.$Message.success('录入成功');
                                                }
                                                _this.$router.push({name:'caseList'});
                                            }
                                        })
                                    }else {this.$Message.error('请输入案件详情');}
                                }else{this.$Message.error('请输入金额');}
                            }else {this.$Message.error('请选择律师');}
                        }else {this.$Message.error('请选择案由');}
                    }else {this.$Message.error('请输入案号');}
                }else {
                    this.$Message.error('请输入案件名称');
                }
            },
            onLawyer : function(value){
                console.log(value)
                this.form.lawyerName = []
                this.form.lawyerIdArr = []
                for(var i in value){
                    this.form.lawyerName.push(value[i].split(",")[1])
                    this.form.lawyerIdArr.push(value[i].split(",")[0])
                }
            },
            causeshow : function(){
                this.show = true
                // if(this.form.caseType == ''){
                //     this.form.caseType = this.caseTypeData[0].value
                //     this.form.caseTypeName = this.caseTypeData[0].text
                // }
            },
            onDetermine : function(){
                this.show = false
            },
            onConfirm : function(value, index){
                this.show = false
                console.log(this.caseTypeData[index[0]].children[index[1]])
                this.form.caseType = this.caseTypeData[index[0]].children[index[1]].id
                this.form.caseTypeName = this.caseTypeData[index[0]].children[index[1]].text
                // this.SelectcaseTypeData = index
                // this.form.caseType = index.value
                // this.form.caseTypeName = index.text
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
            caseTypeList : function(){
                var _this = this
                var data = []
                _this.send({
                    url: '/caseTypeList',
                    data: {},
                    success: function (res) {
                        for(var i in res){
                            data.push(res[i])
                        }
                        console.log(data)
                        convertTree(data)
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let {
                                    id: id,
                                    keyword: text,
                                    list: children
                                } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    id,
                                    text,
                                    children
                                })
                            })
                            _this.caseTypeData = result
                            return result
                        }
                        console.log(_this.caseTypeData)
                        // var caseTypeData = []
                        // for(var i in res){
                        //     caseTypeData.push({text:res[i].caseTypeName,value:res[i].caseTypeId})
                        // }
                        // _this.caseTypeData = caseTypeData
                    }
                })
                _this.send({
                    url: '/lawyerArrList',
                    data: {lawOfficeId  : window.sessionStorage.lawOfficeId,},
                    success: function (res) {
                        _this.lawyerArrData = res.list
                        console.log(res.list)
                    }
                })
            },

            lawCaseById : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/LawCaseById',
                    data: {
                        caseId  : _this.$route.query.caseId,
                    },
                    success: function (res) {
                        if(res[0].lawyerArrName){
                            for(var i in res[0].lawyerArrName){
                                console.log(res[0].lawyerIdArr.split( ',' )[i])
                                console.log(res[0].lawyerArrName[i])
                                _this.checked.push(res[0].lawyerIdArr.split( ',' )[i]+','+res[0].lawyerArrName[i])
                            }
                            console.log(_this.checked)
                            var lawyerArrName = res[0].lawyerArrName.toString()
                        }else {
                            var lawyerArrName = ''
                        }
                        _this.form.caseDetail = res[0].caseDetail
                        _this.form.caseName = res[0].caseName
                        _this.form.caseNum = res[0].caseNum
                        _this.form.caseCostMax = res[0].caseCostMax
                        _this.form.caseCostMin = res[0].caseCostMin
                        _this.form.caseTypeName = res[0].keyword
                        _this.form.caseType = res[0].caseType
                        _this.form.lawyerName = lawyerArrName
                        _this.form.lawyerIdArr = res[0].lawyerIdArr
                    }
                })
            },
        },
        mounted () {
            var _this = this
            _this.setPageSize()
            _this.caseTypeList()
            _this.form.lawOfficeId = window.sessionStorage.lawOfficeId
            if(_this.$route.query.caseId){
                _this.form.caseId =_this.$route.query.caseId
                _this.lawCaseById()
            }
        }
    }
</script>

<style scoped>

</style>
