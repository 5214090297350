export default {
  menuList:[
    { name: '首页', routeName: 'managerIndex',iconType:'svg',iconSrc:'/img/icons-svg/menu/home.svg',},
    {
      name: '资源管理',
      iconType:'svg',
      iconSrc:'/img/icons-svg/menu/area.svg',
      children:[
        { name: '区域管理',routeName: 'judicialoffice',iconType:'svg',iconSrc:'/img/icons-svg/menu/area.svg',},
        // { name: '区域管理',routeName: 'communityRevise',iconType:'svg',iconSrc:'/img/icons-svg/menu/area.svg',},
        { name: '区域管理',routeName: 'MAAL',iconType:'svg',iconSrc:'/img/icons-svg/menu/area.svg',},
        { name: '顾问管理',routeName: 'lawyerManagement',iconType:'svg',iconSrc:'/img/icons-svg/menu/lawyer.svg',},
        { name: '村(居)服务管理',routeName: 'community',iconType:'svg',iconSrc:'/img/icons-svg/menu/lawfirm.svg',},
      ]
    },
    // { name: '村（社区）管理',routeName: 'villageAdmin',iconType:'svg',iconSrc:'/img/icons-svg/menu/lawyer.svg',},

    {
      name: '服务管理',
      iconType:'svg',
      iconSrc:'/img/icons-svg/menu/service.svg',
      children:[
        { name: '咨询服务',routeName: 'consultingService',iconType:'svg',iconSrc:'/img/icons-svg/menu/consulting.svg',},
        { name: '申办服务',routeName: 'handlingServices',iconType:'svg',iconSrc:'/img/icons-svg/menu/handling.svg',},
        { name: '查询服务',routeName: 'queryService',iconType:'svg',iconSrc:'/img/icons-svg/menu/query.svg',},
      ]
    },
    { name: '用户管理',routeName: 'userAdministration',iconType:'svg',iconSrc:'/img/icons-svg/menu/user.svg',},
    { name: '律师考核',routeName: 'lawyerScoreList',iconType:'svg',iconSrc:'/img/icons-svg/menu/integral.svg',},
    { name: '档案管理',routeName: 'MAL',iconType:'svg',iconSrc:'/img/icons-svg/menu/archives.svg',},
    { name: '报表管理',routeName:'report',iconType:'svg',iconSrc:'/img/icons-svg/menu/report.svg',},
    { name: '考勤打卡',routeName: 'punchIn',iconType:'svg',iconSrc:'/img/icons-svg/menu/punchIn.svg',},
    {
      name: '大数据中心',
      iconType:'svg',
      iconSrc:'/img/icons-svg/menu/big-data-md.svg',
      children:[
        { name: '单屏数据版',routeName: 'bigData',iconType:'svg',iconSrc:'/img/icons-svg/menu/single-screen.svg',target:'_blank'},
        { name: '运营中心宽屏版',routeName: 'MBDN',iconType:'svg',iconSrc:'/img/icons-svg/menu/more-screen.svg',target:'_blank'},
        { name: '实时服务监管系统',routeName: 'MDCB',iconType:'svg',iconSrc:'/img/icons-svg/menu/bigdata.svg',target:'_blank'},
      ]
    },
    {
      name: '数据推送',
      iconType:'svg',
      iconSrc:'/img/icons-svg/menu/message-push.svg',
      children:[
        { name: '热点法律 ',routeName: 'hotspotlaw',iconType:'svg',iconSrc:'/img/icons-svg/menu/pushmsg.svg',},
        { name: '普法宣传',routeName: 'publicityofSpd',iconType:'svg',iconSrc:'/img/icons-svg/menu/pfxc.svg',},
        { name: '内容推送',routeName: 'article',iconType:'svg',iconSrc:'/img/icons-svg/menu/pushArticle.svg',},
        { name: '普法视频',routeName: 'videoUpload',iconType:'svg',iconSrc:'/img/icons-svg/menu/video.svg',},
        { name: '系统数据推送 ',routeName: 'dataPush',iconType:'svg',iconSrc:'/img/icons-svg/menu/use-data-push.svg',},
        { name: '立法意见征集 ',routeName: 'reconsider',iconType:'svg',iconSrc:'/img/icons-svg/menu/collect.svg',},
      ]
    },
    { name: '运行报告',routeName: 'runReport',iconType:'svg',iconSrc:'/img/icons-svg/menu/runReport.svg',},
    { name: '服务时间设置',routeName:'timeSet',iconType:'svg',iconSrc:'/img/icons-svg/menu/sts.svg',},
    { name: '意见反馈',routeName: 'feedback',iconType:'svg',iconSrc:'/img/icons-svg/menu/feedback.svg',},
    { name: '行政执法监督',routeName: 'supervise',iconType:'svg',iconSrc:'/img/icons-svg/menu/toExamine.svg',},
    { name: '意见征集',routeName: 'opinionCollect',iconType:'svg',iconSrc:'/img/icons-svg/menu/collect.svg',},
    { name: '企业法治体检',routeName: 'enterpriseRuleOfLaw',iconType:'svg',iconSrc:'/img/icons-svg/menu/collect.svg',},
  ],
  displayRules:{
    /*
      *level-按Admin账号等级显示;
      *specific-指定AdminID显示;
      *specificAll-指定AdminID及下属全部显示;
      *specificHidden-指定AdminID隐藏;
      *specificHideAll-指定AdminID及下属全部隐藏;
      *默认全部展示
      *需要建立与routeName相同名称的对象
      *填入ID，对应规则生效
      *按层级判断，有一个判断结果为真则显示
    */
    'MAAL':{level:[0],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'judicialoffice':{level:[1,2],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    // 'communityRevise':{level:[3],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'hotspotlaw':{level:[],specific:[0],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'timeSet':{level:[],specific:[0,67],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'MDCB':{level:[],specific:[80],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'supervise':{level:[],specific:[],specificAll:[4685],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'opinionCollect':{level:[],specific:[],specificAll:[4685],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'enterpriseRuleOfLaw':{level:[],specific:[291],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'reconsider':{level:[],specific:[],specificAll:[4685],specificHidden:[],specificHideAll:[],specificLogin:[]},
    'runReport':{level:[],specific:[],specificAll:[],specificHidden:[67],specificHideAll:[],specificLogin:[]},
    'lawyerScoreList':{level:[],specific:[],specificAll:[291],specificHidden:[],specificHideAll:[],specificLogin:[]},
  }
}
