<style scoped>
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
  .details p{color: #000000}
  .details span{color: #5c6b77}
</style>

<template>
  <div style="padding:20px;">
    <Modal
      v-model="timePanel.state"
      @on-ok="saveST"
      title="设置服务时间">
      <Row type="flex" align="middle">
        <Col span="8" style="text-align:right;">选择时间类型：</Col>
        <Col span="16">
          <RadioGroup type="button" v-model="timePanel.data.type">
            <Radio label="month">按月</Radio>
            <Radio label="week">按星期</Radio>
          </RadioGroup>
        </Col>
      </Row>
      <Divider dashed style="margin:10px;"/>
      <Row v-if="timePanel.data.type=='month'" type="flex" align="middle">
        <Col span="8" style="text-align:right;">选择服务日期：</Col>
        <Col><DatePicker @on-change="pushDate" type="datetime" format="yyyy年MM月dd日" multiple placeholder="选择值班日期，可多选"></DatePicker></Col>
      </Row>
      <Row v-if="timePanel.data.type=='week'" type="flex" align="middle">
        <Col span="8" style="text-align:right;">选择服务星期：</Col>
        <Col span="16">
          <CheckboxGroup v-model="timePanel.data.weekListTemp">
            <Checkbox label="1,星期一">星期一</Checkbox>
            <Checkbox label="2,星期二">星期二</Checkbox>
            <Checkbox label="3,星期三">星期三</Checkbox>
            <Checkbox label="4,星期四">星期四</Checkbox>
            <Checkbox label="5,星期五">星期五</Checkbox>
            <Checkbox label="6,星期六">星期六</Checkbox>
            <Checkbox label="0,星期天">星期天</Checkbox>
          </CheckboxGroup>
        </Col>
      </Row>
      <Divider dashed style="margin:10px;"/>
      <Row type="flex" align="middle">
        <Col span="8" style="text-align:right;">选择服务时间范围：</Col>
        <Col span="16">
          <Row v-if="timePanel.data.dateList.length>0" v-for="(item,index) in timePanel.data.dateList" style="margin-bottom:10px;" type="flex" align="middle">
            <Col :span="timePanel.data.type=='month'?8:4">
              {{timePanel.data.type=='month'?item.date:item.date.split(',')[1]}}
            </Col>
            <Col span="14">
              <TimePicker format="HH:mm" type="timerange" placement="bottom-end" @on-change="item.time=$event" placeholder="选择时间范围"></TimePicker>
            </Col>
          </Row>
          <span v-else>请先选择日期</span>
        </Col>
      </Row>
      <Divider dashed style="margin:10px;"/>
      <Row type="flex" align="middle">
        <Col span="8" style="text-align:right;">是否重复：</Col>
        <Col span="16">
          <i-switch v-model="timePanel.data.repeat" :disabled="timePanel.data.type=='week'" true-value="T" false-value="F">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </Col>
      </Row>
    </Modal>
    <div style="background-color: white;">
    <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px">
      村(社区)服务时间管理
      <!-- <Button type="error" style="float:right;margin-right:20px" :to="{name:'correctionC'}">课程发布</Button> -->
    </div>
    <div style="padding:15px"></div>
              <div class="publicWelfare" >
                  <table class="formtable">
                      <thead>
                      <tr>
                          <th>区域</th>
                          <th>村（社区）</th>
                          <th>服务时间</th>
                          <th>是否循环</th>
                          <th>发布时间</th>
                          <th>操作</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(value,index) in legalAidData">
                          <td>{{value.CityName}}</td>
                          <td>{{value.VillagesName}}</td>
                          <td>
                            <template v-if="value.SchedulingContent">
                              <div
                                v-if="value.SchedulingContent.dateList"
                                v-for="(item2,index2) in value.SchedulingContent.dateList">
                                <template v-if="value.SchedulingContent.type=='month'">
                                  {{value.SchedulingContent.repeat=='T'?'每月'+item2.date.split('月')[1]:item2.date}},{{item2.time.toString().replace(/,/g,'-')}}
                                </template>
                                <template v-if="value.SchedulingContent.type=='week'">
                                  {{item2.date.split(',')[1]}},{{item2.time.toString().replace(/,/g,'-')}}
                                </template>
                              </div>
                            </template>
                          </td>
                          <td>{{value.SchedulingContent&&value.SchedulingContent.repeat=='T'?'是':'否'}}</td>
                          <td>{{value.CreatedTime}}</td>
                          <td>
                            <Button type="primary" @click="setTimePanel(value)">设置</Button>
                          </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <div style="text-align: left;margin-left: 40px;font-size: 16px;padding-bottom: 20px">
                  <!-- <Page
                    :total="count"
                    show-total
                    :page-size="pageSize"
                    show-elevator
                    @on-change="page = $event;adminflyzApplyList()"
                  /> -->
              </div>
            </div>
  </div>
</template>

<script>
  export default {
      name: "mediateList",
      data () {
          return {
              information : [],
              legalAidData : [],
              count : '0',
              pageSize : 10,
              page : 1,
              modal1 : false,
              detailsData : [],
              sfclmc : '',
              timePanel:{
                state:false,
                id:'',
                data:{
                  type:'month',
                  dateList:[],
                  repeat:'F',
                  weekListTemp:[],
                },
              },
          }
      },
      watch:{
        'timePanel.data.type':function(val){
          this.timePanel.data.weekListTemp = []
          if(val=='week'){
            this.timePanel.data.repeat = 'T'
          }
          if(val=='month'){
          }
        },
        'timePanel.data.weekListTemp':function(val){
          this.pushWeek(val)
        },
      },
      computed:{
        ownId:function(){
          return window.sessionStorage.AdminId
        },
      },
      methods : {
        setTimePanel:function(value){
          this.timePanel.id=value.VillagesId
          // if(value.SchedulingContent){
            // this.timePanel.data.weekListTemp = value.SchedulingContent.weekListTemp
            // this.timePanel.data.type=value.SchedulingContent.type
            // this.timePanel.data.repeat=value.SchedulingContent.repeat
            // this.timePanel.data.dateList=value.SchedulingContent.dateList
          // }else{
            this.timePanel.data = {
              type:'month',
              dateList:[],
              repeat:'F',
              weekListTemp:[],
            }
          // }
          this.timePanel.state=true
        },
        saveST:function(){
          var _this = this
          _this.send({
            url:'/UpdateScheduling',
            data:{
              AdminId:window.sessionStorage.AdminId,
              SchedulingContent:JSON.stringify(_this.timePanel.data),
              VillagesId:_this.timePanel.id,
            },
            success:function(res){
              _this.adminflyzApplyList()
            }
          })
        },
        pushWeek:function(weekList){
          this.timePanel.data.dateList = []
          for(var i=0;i<weekList.length;i++){
            this.timePanel.data.dateList.push({
              date:weekList[i],
              time:'',
            })
          }
        },
        pushDate:function(dateList){
          this.timePanel.data.dateList = []
          if(!dateList){return}
          var dateTemp =dateList.split(',')
          for(var i=0;i<dateTemp.length;i++){
            this.timePanel.data.dateList.push({
              date:dateTemp[i],
              time:'',
            })
          }
        },
        deleteCorrection:function(id){
          var _this = this
          _this.send({
              url:'/CurriculumDelete',
              data:{
                  AdminId:window.sessionStorage.AdminId,
                  CurriculumId:id,
              },
              confirm:true,
              success:function (res) {
                _this.$Notice.success({
                  title:'系统提示',
                  desc:'数据更新成功！'
                })
                  _this.adminflyzApplyList()
              }
          })
        },
          adminflyzApplyList : function () {
              var _this = this
              _this.send({
                  url:'/SchedulingList',
                  data:{
                      AdminId:window.sessionStorage.AdminId
                  },
                  success:function (res) {
                    for(var index in res){
                      if(res[index].SchedulingContent){
                        res[index].SchedulingContent = JSON.parse(res[index].SchedulingContent)
                      }
                    }
                    _this.legalAidData = res
                  }
              })
          },
          see : function (e) {
              console.log(e)
              this.detailsData = e
          },
          sfclmcImg : function (e) {
              this.sfclmc = e
          }
      },
      mounted (){
          var _this = this
          if(window.sessionStorage.adminLevel == undefined){
              // this.$router.push({name:'login'})
          }else {
              this.information = window.sessionStorage
              _this.adminflyzApplyList()
          }
      }
  }
</script>


