<template>
  <div>
      <div class="screen">
          <div style="background-color: white;margin:20px;">
              <div style="padding:15px"></div>
              <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px">
                人民调解
                <Button type="error" style="float:right;margin-right:20px" :to="{name:'mediatorN'}">调解员管理</Button>
              </div>
              <div style="padding:15px"></div>
              <div class="publicWelfare" >
                  <table class="formtable">
                      <thead>
                      <tr>
                          <th>申请人</th>
                          <th>纠纷类型</th>
                          <th>调解员</th>
                          <th>调解员电话</th>
                          <th>点位</th>
                          <th>申请时间</th>
                          <th>调解状态</th>
                          <th>操作</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(value,index) in legalAidData">
                          <td> {{value.ProposerName}}</td>
                          <td>{{value.MediationType}}</td>
                          <td>{{value.MediatorName}}</td>
                          <td>{{value.MediatorPhone}}</td>
                          <td>{{value.MediatorAddress}}</td>
                          <td>{{value.CreatedTime}}</td>
                          <td>{{value.ProcessingTitle}}</td>
                          <td> <Button type="primary" @click="getDetial(value)">查看</Button></td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <div style="text-align: left;margin-left: 40px;font-size: 16px;padding-bottom: 20px">
                  <!-- <Page
                          :total="count"
                          show-total
                          :page-size="pageSize"
                          show-elevator
                          @on-change="page = $event;adminflyzApplyList()"
                  /> -->
              </div>
          </div>
      </div>
      <Modal
              v-model="modal1"
              title="法律援助"
              ok-text="关闭"
              cancel-text=""
      >
          <div class="details">
              <div style="font-size: 16px">1、基本信息</div>
              <div>姓名：{{detailsData.ProposerName}}</div>
              <div>性别：{{detailsData.ProposerSex}}</div>
              <div>纠纷类型：{{detailsData.MediationType}}</div>
              <div>对方姓名：{{detailsData.OppositeName}}</div>
              <div>对方联系方式：{{detailsData.OppositePhone}}</div>
              <div>处理进度：{{detailsData.ProcessingTitle}}</div>
              <div>身份证号码：{{detailsData.ProposerIDCard}}</div>
              <div>民族：{{detailsData.ProposerNation}}</div>
              <div>出生日期：{{detailsData.ProposerBirthdate}}</div>
              <div>年龄：{{detailsData.ProposerAge}}</div>
              <div>住所地址：{{detailsData.ProposerAddress}}</div>
              <div>联系电话：{{detailsData.ProposerPhone}}</div>
              </div>
      </Modal>
      <!---->
      <!-- <Modal
              v-model="modal2"
              title="身份证"
              ok-text="关闭"
              cancel-text="">
          <div v-model="sfclmc" >
              <img :src="sfclmc" style="width: 100%;">
          </div>
      </Modal> -->

  </div>
</template>

<script>
  export default {
      name: "mediateList",
      data () {
          return {
              information : [],
              legalAidData : [],
              count : '0',
              pageSize : 10,
              page : 1,
              modal1 : false,
              detailsData : [],
              sfclmc : ''
          }
      },
      methods : {
          adminflyzApplyList : function () {
              var _this = this
              _this.send({
                  url:'/AdminMediationList',
                  data:{
                      // AdminId:5,
                      AdminId:window.sessionStorage.AdminId,
                  },
                  success:function (res) {
                      _this.legalAidData = res
                  }
              })
          },

          getDetial:function(item){
            var _this = this
            _this.send({
              url:'/MediationContent_',
              data:{
                MediationId :item.MediationId,
              },
              success:function(res){
                _this.detailsData = res
                _this.modal1 = true
              }
            })
          },
          see : function (e) {

              console.log(e)

              this.detailsData = e
          },
          sfclmcImg : function (e) {
              this.sfclmc = e
          }
      },
      mounted (){
          var _this = this
          if(window.sessionStorage.adminLevel == undefined){
              // this.$router.push({name:'login'})
          }else {
              this.information = window.sessionStorage
              _this.adminflyzApplyList()
          }
      }
  }
</script>

<style scoped>
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
  .details p{color: #000000}
  .details span{color: #5c6b77}
</style>
