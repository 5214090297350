<style scoped>
.dialogue{position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-color: rgb(248, 249, 251);}
.dialog-top-menu-block{background-color: #FFF;padding:0.2rem 0}
.dialog-top-menu-icon{width:0.6rem;height:0.6rem;}
.sendTime{font-size:0.24rem;color: #cccccc;text-align: center;margin-bottom:0.08rem;}
.headPortrait{width: 68rpx;height: 68rpx;border-radius: 68rpx;margin-left: 30rpx;}
.triangle {width: 0;height: 0;border-width:0.2rem;border-style: solid;border-color: white transparent transparent transparent;vertical-align: top;margin-left:0.15rem;display: inline-block}
.chatFrame{font-size: 0.28rem;color: #000000;background-color: #fff;display: inline-block;padding:0.18rem;max-width:5.1rem;border-radius:0.06rem;margin-bottom:0.6rem;text-align: justify;text-justify: newspaper;word-break: break-all;}
.chatFrameRight{font-size: 0.28rem;color: #000000;background-color: rgb(152,255,106);display: inline-block;padding:0.18rem;max-width:5.1rem;border-radius:0.06rem;text-align: left;line-height:0.45rem;text-align: justify;text-justify: newspaper;word-break: break-all;}
.input{background-color: #f6f6f6;padding:0.3rem;position: fixed;bottom:0;left:0;right:0;}
</style>

<template>
  <div calss="dialogue">
    <div class="dialog-top-menu">
      <Row style="text-align: center;padding:0.2rem" :gutter="16">
        <Col span="8">
          <div class="dialog-top-menu-block">
            <div><img class="dialog-top-menu-icon" src='https://ai-cdn.lvwa18.com/img/rusticate/chat_01.png'></img></div>
            <div>咨询结束</div>
          </div>
        </Col>
        <Col span="8">
          <div class="dialog-top-menu-block">
            <div><img class="dialog-top-menu-icon" src='https://ai-cdn.lvwa18.com/img/rusticate/chat_02.png'></img></div>
            <div>评价打分</div>
          </div>
        </Col>
        <Col span="8">
          <div class="dialog-top-menu-block">
            <div><img class="dialog-top-menu-icon" src='https://ai-cdn.lvwa18.com/img/rusticate/chat_03.png'></img></div>
            <div>电话咨询</div>
          </div>
        </Col>
      </Row>
    </div>
    <div class="dialog-content" style="padding-bottom:80px;">
      <div v-for="(item,index) in contentData">
        <div v-if="userInfo.type != contentData[index].type">
          <div class="sendTime">{{contentData[index].sendTime}}</div>
          <div style="margin-bottom: 0.2rem;">
            <img style="border-radius:0.8rem;width:0.8rem;height:0.8rem;vertical-align: middle;" :src="informationData.LawyerImg"/>
            <span class="chatFrameRight" style="vertical-align: middle;margin-right:0.2rem;" v-if="contentData[index].messageType=='text'">{{contentData[index].content}}</span>
          </div>
        </div>
        <!-- 信息（右边） -->
        <div style="text-align: right;" v-if="userInfo.type == contentData[index].type">
          <div class="sendTime">{{contentData[index].sendTime}}</div>
          <div style="margin-bottom: 0.2rem;">
            <span class="chatFrameRight" style="vertical-align: middle;margin-right:0.2rem;" v-if="contentData[index].messageType=='text'">{{contentData[index].content}}</span>
            <img style="border-radius:0.8rem;width:0.8rem;height:0.8rem;vertical-align: middle;":src="informationData.UserImg"/>
          </div>
        </div>
      </div>
    </div>
    <div class="input">
      <Row :gutter="16">
        <Col span="20">
          <input style="border-radius:0.3rem;background-color:#FFF;width: 100%;height:0.6rem;" v-model="content"></input>
        </Col>
        <Col span="4">
          <Button type="success" @click="sendout('text')">发送</Button>
        </Col>
      </Row>


    </div>
  </div>
</template>

<script>
  export default {
    name: '',
    data() {
      return {
        summaryPanelState: false,
        summaryText: '',
        scoring: false,
        value: 0,
        textareaSize: { maxHeight: 400, minHeight: 150 },
        content: [],
        userInfo: '',
        contactId: '',//对话id
        contentData: [],
        informationData: [],
        feedback: '',
        OrderEndTime: '',
        IsSolution: '',
        loadingFile: false,
        voiceTimer: {},
        voiceLength: 0,
        userType: '',
        view: {
          inputBoxBottom: '-220rpx'
        },
        fileList: [],
        RM: {},
        CIA: {},
        pressAndHold: true,
        languageIcon: false,
        showConfirmBar: false,
        bottom: '',
        detail: false,
        evaluateData: false,
        OrderId: '',
        show: false,
        JYVillagesId : '',
        CDLawyerId : '',
        webSocket:null,
      };
    },
    mounted() {
      var _this = this
      _this.userInfo = _this.$store.state.userInfo
      document.title = window.sessionStorage.Area_name + '-公共法律服务平台'
      if(this.$route.query.LawyerId){
        _this.CDLawyerId = this.$route.query.LawyerId
        _this.JYVillagesId = this.$route.query.VillagesId
      }else{
        _this.CDLawyerId = _this.$store.state.userInfo.LawyerId
        _this.CDLawyerId = _this.userInfo.LawyerId
      }
      _this.addOrder()
      _this.startSocket()
    },
    methods: {
      addOrder: function () {
        var _this = this
        _this.send({
          url:'/AddOrder',
          data:{
            Type: _this.$store.state.userInfo.type,
            LawyerId: _this.CDLawyerId,
            UserId: _this.$store.state.userInfo.UserId,
            OrderTypeId: 1,
            VillagesId : _this.JYVillagesId
          },
          success:function(res){
            _this.contactId = res.ContactId
            _this.userId = res.UserId
            _this.lawyerId = res.LawyerId
            _this.OrderId = res.OrderId
            var contentData = _this.contentData
            console.log(contentData)
            for (var i in res.Content) {
              contentData.push(JSON.parse(res.Content[i]))
            }
            _this.informationData = res
            console.log(_this.informationData)
            _this.value = res.OrderGrade
            _this.feedback = res.OrderEvaluate
            _this.contentData = contentData
            _this.pageScrollToBottom()
          }
        })
      },
      //评分
      onChange(event) {
        this.setData({
          value: event.detail
        });
        console.log(event.detail)
      },
      /**
       * 生命周期函数--监听页面加载
       */
      setSummaryState: function () {
        this.setData({
          summaryPanelState: true
        })
      },
      saveSummary: function () {
        console.log(this.data)
        var _this = this
        wx.request({
          url: app.globalData.domainName + '/UpdateOrderSummary',
          method: "post",
          data: {
            LawyerId: _this.CDLawyerId,
            UserId: _this.userInfo.UserId,
            OrderId: _this.OrderId,
            OrderSummary: _this.summaryText,
          },
          header: { 'Content-Type': 'application/x-www-form-urlencoded' },
          success: res => {
            console.log(res)
          }
        });
      },
      selectImg: function () {
        var _this = this
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
          success(res) {
            console.log(res)
            // 返回选定照片的本地文件路径列表，tempFilePath可以作为img标签的src属性显示图片
            var tempFilePath = res.tempFilePaths[0];
            _this.uploadFile('image', tempFilePath)
          }
        })
      },
      downloadFile: function (event) {
        var _this = this
        _this.setData({
          loadingFile: true
        })
        wx.downloadFile({
          url: event.currentTarget.dataset.path, //仅为示例，并非真实的资源
          success(res) {
            // 只要服务器有响应数据，就会把响应内容写入文件并进入 success 回调，业务需要自行判断是否下载到了想要的内容
            if (res.statusCode === 200) {
              const tempFilePath = res.tempFilePath
              console.log(tempFilePath)
              wx.saveFile({
                tempFilePath: tempFilePath,
                success: function (res) {
                  console.log(res)
                  const savedFilePath = res.savedFilePath
                  wx.openDocument({
                    filePath: savedFilePath,
                    success: function (res) {
                      console.log(res)
                      _this.setData({
                        loadingFile: false
                      })
                    },
                  })
                },
                fail: function (res) {
                  console.log(tempFilePath)
                  console.log(res)
                },
              })
            }
          },
          fail: function (res) {
            console.log(res)
          },
        })
      },
      selectMesFile: function () {
        var _this = this
        wx.chooseMessageFile({
          count: 1,     //能选择文件的数量
          type: 'file',   //能选择文件的类型,我这里只允许上传文件.还有视频,图片,或者都可以
          success(res) {
            _this.uploadFile('file', res.tempFiles[0].path, res.tempFiles[0].name)
          }
        })
      },
      uploadFile(type, tempFilePath, tempFileName) {
        var _this = this
        // 当设置 mutiple 为 true 时, file 为数组格式，否则为对象格式
        wx.uploadFile({
          url: app.globalData.domainName + '/upload_file',
          filePath: tempFilePath,
          name: 'upfile',
          success(res) {
            if (tempFileName) {
              _this.sendout(type, JSON.parse(res.data).Data.path, tempFileName)
            }
            else {
              _this.sendout(type, JSON.parse(res.data).Data.path)
            }
          },
        });
      },
      moreBtn: function (e) {
        if (e.currentTarget.dataset.px != undefined) {
          this.setData({
            'view.inputBoxBottom': '0rpx'
          })
        }
        if (this.view.inputBoxBottom == '-220rpx') {
          this.setData({
            'view.inputBoxBottom': '0rpx'
          })
        } else {
          this.setData({
            'view.inputBoxBottom': '-220rpx'
          })
        }
      },
      // 图片预览
      imgYu: function (e) {
        var current = e.currentTarget.dataset.url.split(",")
        console.log(e.currentTarget.dataset.url.split(","))
        wx.previewImage({
          current: current, // 当前显示图片的http链接
          urls: current// 需要预览的图片http链接列表
        })
      },
      // 再次咨询
      findOrder: function (contactId) {
        this.setData({ contentData: [] })
        wx.request({
          url: app.globalData.domainName + '/FindOrder',
          method: "post",
          data: {
            Type: this.userInfo.type,
            LawyerId: this.CDLawyerId,
            UserId: this.userInfo.UserId,
            ContactId: contactId
          },
          header: { 'Content-Type': 'application/x-www-form-urlencoded' },
          success: response => {
            var contentData = this.contentData
            for (var i in response.data.Data.Content) {
              new Array(contentData.push(JSON.parse(response.data.Data.Content[i])))
            }
            this.setData({
              informationData: response.data.Data,
              value: response.data.Data.OrderGrade,
              feedback: response.data.Data.OrderEvaluate,
              contentData: contentData,
              userId: response.data.Data.UserId,
              lawyerId: response.data.Data.LawyerId,
              OrderEndTime: response.data.Data.OrderEndTime,
              IsSolution: response.data.Data.IsSolution,
              OrderId: response.data.Data.OrderId,
              summaryText: response.data.Data.OrderSummary,
            })
            this.pageScrollToBottom()
            // for (var i in this.contentData){
            // console.log(this.contentData[i].content)
            // }
          }
        });
      },
      recordingVoice: function () {
        var _this = this
        this.setData({
          languageIcon: true
        })
        console.log('按住')
        _this.setData({ voiceLength: 0 })
        _this.setData({
          voiceTimer: setInterval(() => {
            _this.setData({
              voiceLength: _this.voiceLength + 1
            })
            console.log(_this.voiceLength)
          }, 1000)
        })
        var RM = _this.RM
        RM.start()
      },
      stopVoice: function () {
        var _this = this
        var RM = _this.RM
        this.setData({
          languageIcon: false
        })
        console.log('松开')
        RM.stop()
        RM.onStop((res) => {
          clearInterval(_this.voiceTimer)
          wx.uploadFile({
            url: app.globalData.domainName + '/upload_file',
            filePath: res.tempFilePath,
            name: 'upfile',
            success(res) {
              _this.sendout('voiced', JSON.parse(res.data).Data.path)
            },
          });
          console.log(res)
        })
      },
      playVoicd: function (event) {
        var _this = this
        var CIA = _this.CIA
        CIA.src = event.currentTarget.dataset.path
        CIA.play()
      },
      // 发送对话
      sendout: function (type, filePath, fileName) {
        var _this = this
        if (!_this.content) {
          alert('请输入内容后发送');
        } else {
          var content = {}
          content.messageType = 'text'
          content.content = _this.content
          content.nickName = _this.$store.state.userInfo.UserName
          var date = new Date
          var dateStr = date.getFullYear()+'年'+(date.getMonth()+1)+'月'+date.getDate()+'日'+date.getHours()+' :'+date.getMinutes()+':'+date.getSeconds()
          content.sendTime = dateStr
          content.type = _this.$store.state.userInfo.type
          _this.sendMessage(content)
          _this.send({
            url:'/AddContact',
            data: {
              Type: this.userInfo.type,
              LawyerId: this.CDLawyerId,
              UserId: this.userInfo.UserId,
              ContactId: this.contactId,
              OrderId: this.OrderId,
              Content: JSON.stringify(content)
            },
            success:function(res){
              _this.content = ''
              _this.contentData.push(content)
            }
          })
        }
      },
      pressAndHoldFun: function () {
        this.setData({
          pressAndHold: !this.pressAndHold
        })
        console.log(this.pressAndHold)
      },
      // 结束聊天
      stopContact: function (e) {
        console.log(e.currentTarget.dataset.orderid)
        this.setData({ show: !this.show });
        var _this = this
        console.log(_this.OrderEndTime)
        if (_this.OrderEndTime == null || _this.OrderEndTime == '') {
          wx.showModal({
            // title: '提示',
            content: '是否结束咨询',
            success: function (res) {
              if (res.confirm) {
                wx.request({
                  url: app.globalData.domainName + '/StopContact',
                  method: "post",
                  data: {
                    OrderId: e.currentTarget.dataset.orderid,
                  },
                  header: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  success: response => {
                    // _this.findOrder(_this.contactId)
                    _this.setData({
                      OrderEndTime: response.data.Data.OrderEndTime,
                      'informationData.OrderEndTime': '结束聊天'
                    })
                    Toast('结束聊天');
                    _this.showPopup(e)
                  }
                });
              } else if (res.cancel) {
                console.log('用户点击取消')
              }
            }
          })
        } else {
          Toast('聊天已结束');
        }


      },
      //监听收到对方消息
      messageInListener:function(){
      let _this = this
      _this.webSocket.addEventListener("message",message => {
        message = JSON.parse(message.data)
        if(message.type=='message'){
          if(message.content.messageType=='text'){
            _this.contentData.push(message.content)
          }
        }
      })
    },
    sendMessage:function(content){
      let _this = this
      _this.webSocket.send(JSON.stringify({
        "client":"lvwa18",
        "id":'2' + _this.userInfo.UserId,
        "receiveId":'1'+_this.CDLawyerId,
        "content":content,
        "conversationId":_this.contactId,
        "type":"message",
        "version":"1.0.0"
      }))
    },
    startSocket:function(){
      let _this = this
      return new Promise((resolve,reject)=>{
        _this.webSocket = new WebSocket("wss://node.lvwa18.com/ws")
        // let socket = new WebSocket("ws://127.0.0.1:6002")
        _this.webSocket.onopen = function () {
          _this.webSocket.send(JSON.stringify({
            id:'2'+_this.$store.state.userInfo.UserId,
            client:'lvwa18',
            type:'register'
          }))
          _this.messageInListener()
          resolve()
        }
        _this.webSocket.onerror = (event) => {
          console.log('error')
          setTimeout(_this.startSocket,10000)
        }
        // 监听Socket的关闭
        _this.webSocket.onclose = (event) => {
          setTimeout(_this.startSocket,10000)
        }
      })
    },
      solve: function (e) {
        var _this = this
        console.log(e.currentTarget.dataset.index)
        wx.request({
          url: app.globalData.domainName + '/StopContact',
          method: "post",
          data: {
            OrderId: e.currentTarget.dataset.orderid,
            IsSolution: e.currentTarget.dataset.index,
          },
          header: { 'Content-Type': 'application/x-www-form-urlencoded' },
          success: response => {
            // _this.findOrder(_this.contactId)
            _this.setData({
              OrderEndTime: response.data.Data.OrderEndTime,
              'informationData.OrderEndTime': '结束聊天'
            })
            this.setData({ show: !this.show });
            _this.findOrder(_this.contactId)
            _this.showPopup(e)
          }
        });
      },
      // 评价打分
      showPopup(e) {
        console.log(this.value, this.feedback, e)
        if (this.OrderEndTime != '' && this.OrderEndTime != null) {
          if (this.value == null || this.feedback == null) {
            this.setData({
              evaluateData: true,
            })
            console.log(this.evaluateData)
          } else {
            this.setData({
              evaluateData: false,
            })
            console.log(this.evaluateData)
          }
          // this.setData({ scoring: true });
          this.setData({
            scoring: true,
            value: e.currentTarget.dataset.ordergrade,
            feedback: e.currentTarget.dataset.orderevaluate,
            OrderId: e.currentTarget.dataset.orderid
          });
        } else {
          // Toast('结束聊天后评价');
          this.setData({ show: !this.show });
        }
      },
      onClose1() {
        this.setData({ show: !this.show });
      },
      onClose() {
        this.setData({ scoring: false });
        console.log(this.value, this.feedback)
        if (this.evaluateData == true) {
          this.setData({ value: null, feedback: null, });
        }
      },
      submission: function (e) {
        var _this = this
        console.log(this.OrderId)
        if (this.value != null) {
          wx.request({
            url: app.globalData.domainName + '/Evaluate',
            method: "post",
            data: {
              OrderId: this.OrderId,
              OrderEvaluate: this.feedback,
              OrderGrade: this.value,
            },
            header: { 'Content-Type': 'application/x-www-form-urlencoded' },
            success: response => {
              Toast('提交成功');
              this.setData({ scoring: false, evaluateData: false });
              _this.findOrder(_this.contactId)
            }
          });
        } else {
          Toast('输入完成后提交');
        }

      },
      //拨打电话
      dial: function () {
        wx.makePhoneCall({
          phoneNumber: this.userInfo.LawyerPhone
        })
      },
      // onShareAppMessage: function () {},
      // content: function (e) {
      //   // console.log(img,app.globalData.userInfo.LawyerImg)
      //   this.setData({
      //     'content.content': e.detail.value,
      //   })
      //   // console.log(this.content)
      // },//内容
      // feedback: function (e) {
      //   this.setData({
      //     feedback: e.detail.value,
      //   })
      // },
      pageScrollToBottom: function () {
        // document.getElementById('#j_page').boundingClientRect(function (rect) {
          // console.log(rect)
          // 使页面滚动到底部
          // wx.pageScrollTo({
          //   scrollTop: rect.height + 5000
          // })
        // }).exec()
      },
      //输入聚焦
      foucus: function (e) {
        var that = this;
        wx.createSelectorQuery().select('#j_page').boundingClientRect(function (rect) {
          console.log(rect.height > wx.getSystemInfoSync().windowHeight)
          if (rect.height > wx.getSystemInfoSync().windowHeight) {
            that.setData({
              bottom: 0
            })
            console.log(that.data.bottom)
          } else {
            that.setData({
              bottom: e.detail.height
            })
            console.log(that.data.bottom)
          }
        }).exec()
        if (e.detail.height == 0) {
          that.setData({
            bottom: -300
          })
        } else {

        }

      },
      //失去聚焦
      blur: function (e) {
        console.log(e)
        var that = this;
        that.setData({
          bottom: ''
        })
      },

      copy: function (e) {
        var that = this;
        var text = e.currentTarget.dataset.text;
        console.log(e);
        wx.setClipboardData({
          data: text,
          success: function (res) {
            wx.hideToast();    //打开可不显示提示框
            Toast('内容已复制');
            // wx.getClipboardData({
            // 	success (res) {
            // 		console.log(res.data) // data
            // 	}
            // })
          }
        });
      },
    },
    watch:{
      contentData:{
        handler(){
          console.log(123)
          var height = document.body.clientHeight;
          window.scroll({ top: height , left: 0, behavior: 'smooth'})
        },
        deep:true,
      }
    }
  }
</script>
