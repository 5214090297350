<style scoped>
	.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
  .detial-content{background-color:#f8f9fb;padding:0 0.3rem;}
  .content-title{text-align: center;color:#333333;font-size:0.28rem;font-weight:bold;padding:0.3rem 0;}
  .content-text{color:#888888;font-size:0.24rem;padding:0.15rem 0;}
  .content-sec-title{color:#008fd6;padding:0.3rem 0.3rem;margin:0 -0.3rem;font-size:0.28rem;background-color:#FFF;}
  .progress-btn{min-height:0.8rem;margin:0 0.1rem;padding:0 0.2rem;line-height:1em;}
</style>

<template>
  <div>
    <div class='header'></div>
    <div class="detial-content">
      <div class="content-title">
        纠纷类型：{{detial.MediationType}} | 申请人：{{detial.ProposerName}}
      </div>
      <Divider style="margin:0;"/>
      <div class="content-sec-title">
        修改调解进度
        <span style="color: gray;font-size:0.14rem;">(点击修改)</span>
      </div>
      <Row class="content-text" type="flex" align="middle">
        <div style="padding:0.075rem;"></div>
        <Col span="24" style="text-align: center;">
          <Button class="progress-btn" :ghost="detial.ProcessingId==1?false:true" type="info" @click="setProcess(1)">
            <Icon type="md-time" />待处理
          </Button>
          <Button class="progress-btn" :ghost="detial.ProcessingId==2?false:true" type="info" @click="setProcess(2)">
            <Icon type="ios-flash" />处理中
          </Button>
          <Button class="progress-btn" :ghost="detial.ProcessingId==4?false:true" type="info" @click="setProcess(4)">
            <Icon type="md-close"/><div style="display: inline-block;vertical-align: middle;">被申请人</br>拒绝调解</div>
          </Button>
          <!-- <Button class="progress-btn" :ghost="detial.ProcessingId==2?false:true" type="success" @click="setProcess(2)">
            <Icon type="md-thumbs-up" />调解成功
          </Button> -->
        </Col>
      </Row>
      <template v-if="detial.ProcessingId!=4">
        <div class="content-sec-title">录入调解信息</div>
        <Row class="content-text" type="flex" align="middle">
          <Col span="24">调解时间</Col>
          <div style="padding:0.075rem;"></div>
          <Col span="24"><Input v-model="detial.MediationTime"/></Col>
        </Row>
        <Row class="content-text" type="flex" align="middle">
          <Col span="24">调解地点</Col>
          <div style="padding:0.075rem;"></div>
          <Col span="24"><Input v-model="detial.MediationAddress"/></Col>
        </Row>
        <Button @click="updateDetial({nameArr:['MediationTime','MediationAddress'],confirm:true})">保存</Button>
        <div class="content-sec-title">资料填写</div>
        <Row class="content-text" type="flex" align="middle">
          <Col span="24">调解现场照片上传</Col>
          <div style="padding:0.075rem;"></div>
          <Col span="24">
            <Row>
              <Col span="12" style="text-align: center;">
                <Upload
                  style="width:3rem;"
                  multiple
                  type="drag"
                  action="//jsonplaceholder.typicode.com/posts/">
                  <div style="padding: 20px 0">
                      <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                      <p>点击此上传资料</p>
                  </div>
                </Upload>
              </Col>
              <Col span="12" style="text-align: center;">
                已上传的资料
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style="margin:0;"/>
        <Row class="content-text" type="flex" align="middle">
          <Col span="24">双方调解书签字照片上传</Col>
          <div style="padding:0.075rem;"></div>
          <Col span="24">
            <Row>
              <Col span="12" style="text-align: center;">
                <Upload
                  style="width:3rem;"
                  multiple
                  type="drag"
                  action="//jsonplaceholder.typicode.com/posts/">
                  <div style="padding: 20px 0">
                      <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                      <p>点击此上传资料</p>
                  </div>
                </Upload>
              </Col>
              <Col span="12" style="text-align: center;">
                已上传的资料
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style="margin:0;"/>
        <Row class="content-text" type="flex" align="middle">
          <Col span="24">纠纷概述/调解小结(选填)</Col>
          <div style="padding:0.075rem;"></div>
          <Col span="24" style="text-align: right;"><Input v-model="describe" type="textarea" :autosize="{minRows:5}"/></Col>
        </Row>
        <div style="text-align:center">
          <Button type="success" style="width:3.48rem;font-size:0.28rem;" @click="detial.ProcessingId=3,updateDetial()">
            <Icon type="md-thumbs-up" />调解成功
          </Button>
        </div>
      </template>
    </div>
    <div style="padding:0.2rem;"></div>
  </div>
</template>

<script>
export default {
  name:'revise',
  data() {
    return {
      progressId:'',
      oppositePhone:'',
      oppositeName:'',
      describe:'',
      mediateTime:'',
      mediatePosition:'',
      imageList:[],
      progress:'',
      detial:{},
    }
 },
  methods: {
    getDetial:function(){
      var _this = this
      _this.send({
        url:'/MediationContent_',
        data:{
          MediationId:_this.$route.query.mediateId,
        },
        success:function(res){
          _this.progressId = res.ProcessingId
          _this.oppositePhone = res.OppositePhone,
          _this.oppositeName = res.OppositeName,
          _this.describe = res.MediatorSummarize,
          _this.progress = res.ProcessingTitle,
          _this.detial = res
        }
      })
    },
    setProcess:function(processId){
      var _this = this
      _this.send({
        url:'/MediationUpdate',
        data:{
          MediationId:_this.$route.query.mediateId,
          ProcessingId:processId,
        },
        success:function(res){
          _this.detial.ProcessingId = processId
        },
      })
    },
    updateDetial:function(updateData){
      var _this = this
      var sendData = {}
      if(updateData){
        sendData.MediationId = _this.$route.query.mediateId
        for(var i=0;i<updateData.nameArr.length;i++){
          sendData[updateData.nameArr[i]] = _this.detial[updateData.nameArr[i]]
        }
      }else{
        var updateData = {confirm:true}
        sendData = _this.detial
      }
      _this.send({
        url:'/MediationUpdate',
        data:sendData,
        success:function(res){
          if(updateData.confirm){
            _this.$Modal.success({
              title:'系统通知',
              content:'数据更新成功！',
            })
          }
        },
      })
    },
  },
  mounted() {
    this.getDetial()
  },
}
</script>
