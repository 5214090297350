<style scoped>
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
  .details p{color: #000000}
  .details span{color: #5c6b77}
</style>

<template>
  <div>
    <div style="padding:15px"></div>
    <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px">
      录入试卷内容
      <Button type="error" style="float:right;margin-right:20px" @click="saveExam">保存试卷</Button>
    </div>
    <div style="padding:15px"></div>
    <Row style="font-size:16px;" :gutter="16">
      <Col span="18">
        <Row :gutter="16">
          <Col span="12">
            <span>编辑题目</span>
            <component
              style="background-color: #FFF;margin-bottom:20px;padding:15px;"
              v-for="(item,index) in examContent"
              :key="index+'exam'"
              :is="item.name"
              :indexQ="index"
              :contentIn="item.content"
              @change="item.content=$event"
              @del="examContent.splice($event,1)"
              state="edit">
            </component>
          </Col>
          <Col span="12">
            <div v-if="examContent.length>0">题目预览</div>
            <component
              style="background-color: #FFF;margin-bottom:20px;padding:15px;text-align: left;"
              v-for="(item,index) in examContent"
              :key="index+'examP'"
              :is="item.name"
              :indexQ="index"
              :contentIn="item.content"
              state="answer">
            </component>
          </Col>
        </Row>
      </Col>
      <Col span="4" style="text-align:center;">
        <Button style="width:100%;" @click="examContent.push({name:'examRadio',content:{}})">+单选题</Button>
        <div style="padding:5px;"></div>
        <Button style="width:100%;" @click="examContent.push({name:'examCheckbox',content:{}})">+多选题</Button>
        <div style="padding:5px;"></div>
        <Button style="width:100%;" @click="examContent.push({name:'examInput',content:{}})">+填空题</Button>
        <div style="padding:5px;"></div>
        <Button style="width:100%;" @click="examContent.push({name:'examTof',content:{}})">+判断题</Button>
        <div style="padding:5px;"></div>
        <Button style="width:100%;" @click="examContent.push({name:'examTextarea',content:{}})">+简答题</Button>
      </Col>
    </Row>
  </div>
</template>

<script>
  import examRadio from '@/components/exam/examRadio'
  import examInput from '@/components/exam/examInput'
  import examTof from '@/components/exam/examTof'
  import examTextarea from '@/components/exam/examTextarea'
  import examCheckbox from '@/components/exam/examCheckbox'
  export default {
      name: "mediateList",
      data () {
          return {
            examContent:[],
            examId:'',
          }
      },
      methods : {
        getExamCon:function(){
          var _this = this
          _this.send({
            url:'/ExamContentAdmin',
            data:{
              CurriculumId:_this.$route.query.correctionId,
            },
            success:function(res){
              _this.examId = res.ExamId
              _this.examContent = res.ExamContent
            },
          })
        },
        saveExam:function(){
          var _this = this
          _this.send({
            url:'/ExamUpdate',
            data:{
              ExamId:_this.examId,
              ExamContent:_this.examContent,
              CurriculumId:_this.$route.query.correctionId,
            },
            success:function(res){
              _this.$Notice.success({
                title:'系统提示',
                desc:'数据更新成功！'
              })
            }
          })
        },
      },
      mounted (){
        this.getExamCon()
      },
      components:{
        examRadio:examRadio,
        examInput:examInput,
        examTof:examTof,
        examTextarea:examTextarea,
        examCheckbox:examCheckbox,
      },
  }
</script>


