export default {
  menuList:[
    { name: '首页',routeName:'understandingList',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '区域分布',routeName:'regionAdmin',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '人员管理',routeName:'UnderstanderAdmin',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '配置管理',routeName:'toconfigureAdmin',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '积分管理',routeName:'integralManage',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '服务档案',routeName:'workLog',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '学习管理',routeName:'learningArea',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '学习记录',routeName:'learningRecord',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '计划管理',routeName:'aScheduleList',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '问卷管理',routeName:'questionnaireList',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '预约管理',routeName:'subscribeList',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '工作建议',routeName:'recommendation',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '通知消息',routeName:'notice',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
  ],
  displayRules:{
    /*
      *level-按Admin账号等级显示;
      *specific-指定AdminID显示;
      *specificAll-指定AdminID及下属全部显示;
      *specificHidden-指定AdminID隐藏;
      *specificHideAll-指定AdminID及下属全部隐藏;
      *默认全部展示
      *需要建立与routeName相同名称的对象
      *填入ID，对应规则生效
      *按层级判断，有一个判断结果为真则显示
    */
    'regionAdmin':{level:[0,1,2],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]}
  }
}
