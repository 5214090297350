<template>
    <div>
        <div style="font-family: PingFang SC;margin:0 0.29rem;border-bottom: 1px solid #D9D9D9;">
            <div style="font-size: 0.36rem;font-weight: bold;margin: 0.4rem 0 0.3rem 0rem;color: #333333;line-height: 0.36rem;">{{lawCaseByData.caseName}}</div>
            <div style="font-size: 0.24rem;font-weight: 500;color: #999;line-height: 0.24rem;margin-bottom: 0.18rem;">{{lawCaseByData.caseNum}}</div>
            <div style="line-height: 0.22rem;font-size: 0.22rem;font-weight: 500;color: #999;margin-bottom: 0.18rem;">案由： <span style="color: rgba(51, 51, 51, 1)">{{lawCaseByData.keyword}}</span></div>
            <div style="line-height: 0.22rem;font-size: 0.22rem;font-weight: 500;color: #999;margin-bottom: 0.18rem;">负责律师： <span style="color: rgba(51, 51, 51, 1)">{{lawCaseByData.lawyerArrName}}</span></div>
            <div style="line-height: 0.22rem;font-size: 0.22rem;font-weight: 500;color: #999;margin-bottom: 0.39rem;">金额区间： <span style="color: rgba(51, 51, 51, 1)">{{lawCaseByData.caseCostMin}}-{{lawCaseByData.caseCostMax}}</span></div>
        </div>
        <div style="margin: 0.4rem 0 0.3rem 0.39rem;">
            <img style="width: 0.26rem;height: 0.3rem;vertical-align: top;margin: 0rem 0.2rem 0 0;" src="/img/caseInput/label.png">
            <div style="font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;display: inline-block;line-height: 0.3rem;vertical-align: top;">案件详情</div>
        </div>
        <div style="width: 6.9rem;background: #F7F7F7;border-radius: 0.04rem;font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.42rem;padding: 0.30rem;margin-left: 0.29rem;">
            {{lawCaseByData.caseDetail}}
        </div>
        <div v-if="lawOfficeId == ''">
            <div style="text-align: center;margin-top: 0.34rem;">
                <div v-for="(value,index) in starData" style="display: inline-block">
                    <img style="width: 0.46rem;height: 0.46rem;vertical-align: top;margin-right: 0.07rem;" v-if="index+1 <= star" src="/img/caseInput/pingfent.png" @click="onStar(index+1)">
                    <img style="width: 0.46rem;height: 0.46rem;vertical-align: top;margin-right: 0.07rem;" v-else src="/img/caseInput/pingfenf.png" @click="onStar(index+1)">
                </div>
                <div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: bold;color: #CCCCCC;line-height: 0.24rem;margin: 0.1rem 0 0.4rem 0;">如果满意请给五星好评哦</div>
            </div>
            <div style="text-align: center;font-size: 0;margin-top: 0.5rem;">
            <textarea v-if="userId" @click="textareaEvaluate" v-model="comment" style="width: 6.9rem;height: 1.69rem;background: #fff;border-radius: 0.04rem;padding: 0.31rem 0 0 0.31rem;font-size: 0.28rem;font-weight: 500;border: 0.01rem solid rgba(223, 223, 223, 1)" placeholder="欢迎你写下你的评价" >
            </textarea>
            <textarea v-else="" @click="textareaEvaluate" readonly="readonly" v-model="comment" style="width: 6.9rem;height: 1.69rem;background: #fff;border-radius: 0.04rem;padding: 0.31rem 0 0 0.31rem;font-size: 0.28rem;font-weight: 500;border: 0.01rem solid rgba(223, 223, 223, 1)" placeholder="欢迎你写下你的评价" >
            </textarea>
                <div style="text-align: right">
                    <div @click="userComment" style="width: 1.40rem;height: 0.64rem;background: #0694E6;border-radius: 10px;font-size: 0.28rem;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;text-align: center;line-height: 0.64rem;margin: 0.2rem 0.3rem 0 0;display: inline-block">
                        发布评价
                    </div>
                </div>
            </div>
        </div>

        <div style="margin: 0.4rem 0 0.3rem 0.39rem;">
            <div style="font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;display: inline-block;line-height: 0.3rem;vertical-align: top;">评价列表</div>
        </div>
        <div style="text-align: center;font-size: 0;">
            <div v-for="(value,index) in lawCaseCommentData" style="font-family: PingFang SC;margin: 0.3rem 0rem 0 0;width: 6.9rem;padding-bottom: 0.3rem;border-bottom: 0.01rem solid #DFDFDF;text-align: left;display: inline-block;">
                <img style="width: 0.76rem;height: 0.76rem;margin: 0rem 0.19rem 0 0" src="/img/caseInput/mrtx.png" v-if="value.userImage == null">
                <img style="width: 0.76rem;height: 0.76rem;margin: 0rem 0.19rem 0 0" :src="value.userImage" v-else>
                <div style="display: inline-block;vertical-align: top;width: 5.7rem;">
                    <div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.24rem;">
                        <div style="display: inline-block;">{{value.userPhone}}</div>
                        <div style="display: inline-block;font-size: 0.2rem;float: right">{{value.commentTime}}</div>
                    </div>
                    <img style="width:1.14rem;height: 0.18rem;margin: 0.2rem 0 0.19rem 0;"  :src="'/img/caseInput/score'+value.star+'.png'">
                    <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: 500;color: #666666;line-height: 0.36rem;">
                        {{value.comment}}
                    </div>
                </div>
            </div>
        </div>
        <!--<div style="display: none">-->
            <!--<div id="qrcode" ref="qrcode" style="display: none"></div>-->
        <!--</div>-->
        <div v-if="lawOfficeId" style="padding: 0.6rem"></div>
        <div v-if="lawOfficeId" @click="show = true;showQrcode()" style="width: 7.5rem;height: 0.92rem;background: #0694E6;font-size: 0.30rem;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;line-height: 0.92rem;text-align: center;position: fixed;bottom: 0;">
            <!--<img src="/img/caseInput/xiazai.png" style="width:0.42rem;height: 0.34rem;vertical-align: top;margin-top: 0.29rem;">-->
            查看二维码
        </div>
        <div v-if="lawOfficeId == '' && userId == ''" style="padding: 0.6rem"></div>
        <div v-if="lawOfficeId == '' && userId == ''" @click="textareaEvaluate" style="width: 7.5rem;height: 0.92rem;background: #0694E6;font-size: 0.30rem;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;line-height: 0.92rem;text-align: center;position: fixed;bottom: 0;">
            需登录后评价（去登录）
        </div>
        <!--<van-dialog v-model="show" title="标题" show-cancel-button>-->
            <!--<div id="qrcode" ref="qrcode"></div>-->
        <!--</van-dialog>-->
        <Modal
                v-model="show"
                :title="lawCaseByData.caseName"
                @on-ok="downloadE" :ok-text="okText" cancel-text=" ">
            <div style="text-align: center">
                <div style="padding-bottom:0.3rem;font-size: 0.28rem">{{lawCaseByData.caseNum}}</div>
                <div id="qrcode" ref="qrcode" style="display: inline-block;"></div>
                <!--<div v-if="okText == '关闭'" style="font-size: 0.26rem;color: #333333">长按保存</div>-->
            </div>
        </Modal>
        <van-dialog v-model="show1" title="" show-cancel-button :message="'该操作需登录后使用('+messageTimer.count+'s)'" :showConfirmButton="false" :showCancelButton="false">

        </van-dialog>
    </div>
</template>

<script>
    import QRCode from "qrcode2";
    export default {
        name: "caseDetails",
        data() {
            return {
                lawCaseByData : [],
                lawCaseCommentData : [],
                lawOfficeId : '',
                qrcode : '',
                userId : '',
                qr : '',
                starData : [1,2,3,4,5],
                star : 5,
                comment : '',
                page : 1,
                loadData : false,
                show : false ,
                windowWidth : '',
                okText : '',
                messageTimer: {
                    fun: null,
                    count: 3,
                },
                show1: false,
            }
        },
        methods :{
            determine : function () {
                var _this = this
                _this.$router.push({name:'userLogin'});
            },
            textareaEvaluate : function(){
                var _this = this
                if(_this.userId == ''){
                    _this.show1 = true
                    _this.messageTimer.fun = setInterval(function () {
                        if (_this.messageTimer.count > 1) {
                            _this.messageTimer.count--
                        } else {
                            _this.$router.push({name: 'userLogin'})
                            clearInterval(_this.messageTimer.fun)
                            // _this.messageTimer.fun = null;
                        }
                    }, 1000)
                }
            },
            userComment : function(){
                var _this = this
                if(_this.userId == ''){
                    _this.textareaEvaluate()
                }else{
                    if(_this.comment != ''){
                        _this.send({
                            consulting : true,
                            url: '/userComment',
                            data: {
                                userId  : _this.userId,
                                caseId   : _this.$route.query.caseId,
                                star : _this.star,
                                comment : _this.comment,
                            },
                            success: function (res) {
                                _this.comment = ''
                                _this.lawCaseCommentData = []
                                _this.page = 1
                                _this.lawCaseCommentList()
                            }
                        })
                    }else {
                        this.$Message.error('评价内容不能为空');
                    }
                }

            },
            onStar : function(index){
                this.star = index
            },
            login : function(){
                var _this = this
                _this.$router.push({name:'userLogin'});
            },
            downloadE(name) {
                this.show = false
                if(this.okText == '下载'){
                    var canvasData = this.$refs.qrcode.getElementsByTagName('canvas')
                    var a = document.createElement("a");
                    var event = new MouseEvent("click"); // 创建一个单击事件
                    a.href = canvasData[0].toDataURL("image/png");
                    a.download = this.lawCaseByData.caseName;
                    a.dispatchEvent(event); // 触发a的单击事件
                    var _this = this
                    _this.send({
                        consulting : true,
                        url: '/GreateCode',
                        data: {
                            caseId  : _this.$route.query.caseId,
                            caseCode : 'https://cj.lvwa18.com/caseDetails?caseId='+_this.$route.query.caseId+'&qr=1'
                        },
                        success: function (res) {

                        }
                    })
                }

            },
            showQrcode() {
                var _this = this
                if(_this.show){
                    document.getElementById("qrcode").innerHTML = "";
                    var qrcode = new QRCode('qrcode', { // 第一个入参是组件id
                        text: 'https://cj.lvwa18.com/caseDetails?caseId='+_this.$route.query.caseId+'&qr=1', // 生成二维码的文本
                        width: _this.windowWidth,
                        height: _this.windowWidth,
                        colorDark: '#000000', // 二维码色
                        colorLight: '#ffffff', // 背景色
                        correctLevel: QRCode.CorrectLevel.H // 容错等级，H是heigh，最高，所以二维码看起来很密
                    })
                }

            },
            lawCaseById : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/LawCaseById',
                    data: {
                        caseId  : _this.$route.query.caseId,
                    },
                    success: function (res) {
                        if(res[0].lawyerArrName){
                            res[0].lawyerArrName = res[0].lawyerArrName.toString()
                        }
                        _this.lawCaseByData = res[0]
                    }
                })
            },
            lawCaseCommentList : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/LawCaseCommentList',
                    data: {
                        caseId  : _this.$route.query.caseId,
                        page : _this.page
                    },
                    success: function (res) {
                        if(res){
                            for(var i in res.list){
                                res.list[i].star = parseInt(res.list[i].star)
                                _this.lawCaseCommentData.push(res.list[i])
                            }
                            _this.loadData = false
                        }
                    }
                })
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
        mounted () {
            var _this = this
            _this.setPageSize()
            _this.lawCaseById()
            _this.lawCaseCommentList()
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'userId') {
                        _this.userId = arr2[1]
                    }
                    if (arr2[0] == 'lawOfficeId') {
                        _this.lawOfficeId = arr2[1]
                    }
                }
            }
            // _this.lawOfficeId = window.sessionStorage.lawOfficeId || ''
            window.sessionStorage.qr = _this.$route.query.qr || ''
            _this.qr = _this.$route.query.qr || ''
            // this.$nextTick (function () {
            //     this.showQrcode();
            // })
            _this.windowWidth = window.screen.width-100
            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            if(isiOS){
                _this.okText = '关闭'
            }else if(isAndroid){
                _this.okText = '下载'
            }
        },
        created() {
            let that = this;
            window.onscroll = function () {
                // scrollTop 滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                // windowHeight 可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // scrollHeight 滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                // 滚动条到底部的条件
                if (scrollTop + windowHeight+5 >= scrollHeight &&!that.loadData) {
                    that.loadData = true
                    // 加载数据
                    that.page++
                    that.lawCaseCommentList();
                }

            }
        },
    }
</script>

<style scoped>

</style>
