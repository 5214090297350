<style scoped>
    .sifa{width: 1.02rem;height: 1.10rem;margin: 0.79rem 0 0.10rem 0;}
    .title{font-size: 0.6rem;font-weight: 900;color: #FFFFFF;margin-bottom: 0.60rem;}
    .regionFalse{height: 0.90rem;display: inline-block;line-height: 0.90rem;font-size: 0.26rem;border: 0.01rem solid #ffffff;position: relative;margin: 0.30rem 0rem 0 0.30rem;width: 1.90rem;background: #ffffff;text-align: center;}
    .street{width: 100%;height: 0.80rem;line-height: 0.80rem;text-align: center;border-bottom: 0.01rem solid #F6F5F8;}
</style>

<template>
  <div style="background:url(https://ai-cdn.lvwa18.com/img/rusticate/newbg.png) no-repeat;background-size:100% auto;background-position: top;">
    <div style="text-align: center;">
      <img src="https://ai-cdn.lvwa18.com/img/rusticate/sifa.png" class="sifa" />
      <div class="title">两江新区<br>村居法务视频服务平台</div>
    </div>
    <!---->
    <div style="text-align: center;">
      <div style="background: #F6F5F8;width: 6.90rem;display: inline-block;    text-align: left;">
        <div v-for="(item, index) in regionList" style="display: inline-block;">
          <div class="regionFalse" @click="regionClick(item.AdminId)">
            {{ item.AdminName }}
          </div>
        </div>
        <div style="padding:0.10rem;"></div>
      </div>
    </div>
    <!---->
    <Modal v-model="streetPanel">
      <div>
        <div class="street">选择所属区域</div>
        <div class="street" v-for="(item, index) in streetList" @click="street(item.AdminId)">{{ item.AdminName }}</div>
        <div class="street" v-for="(item, index) in villagesList" @click="findLawyers(item)">{{ item.VillagesName }}
        </div>
        <div v-if="streetList == '' && villagesList == ''" style="text-align: center">暂无数据</div>
      </div>
      <div slot="footer"></div>
    </Modal>
    <Modal v-model="lawyerPanel">
      <div>
        <div class="street">选择要联系的律师</div>
        <div class="street" v-for="(item, index) in lawyerList" @click="callVideo('1'+item.LawyerId)">{{ item.LawyerName }}</div>
        <div class="street" v-for="(item, index) in serviceCenterList" @click="callVideo('VS'+item.ServiceCentreId)">{{ item.ServiceCentreName }} [{{ item.state=='online'?'在线':'离线' }}]</div>
        <div v-if="lawyerList.length<1&&serviceCenterList.length<1" style="text-align: center">暂无数据</div>
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      secret: '',
      regionList: [],
      streetList: [],
      villagesList: [],
      lawyerList:[],
      streetPanel: false,
      lawyerPanel: false,
      userId:'',
      serviceCenterList:[],
    }
  },
  mounted() {
    if(!this.$route.query.userId||!this.$route.query.secret){
      alert('缺少参数！')
      window.close()
      return
    }
    this.userId = 'ljxq'+this.$route.query.userId
    this.secret = this.$route.query.secret
    this.initArea()
  },
  methods: {
    initArea: function (adminId) {
      var _this = this
      _this.send({
        url: '/findVillages',
        data: {
          secret: _this.secret,
        },
        success: function (res) {
          _this.regionList = []
          for (var index in res) {
            _this.regionList.push({ AdminId: res[index].id, AdminName: res[index].name })
          }
          _this.getVideoCenterList(_this.regionList[0].AdminId)
        }
      })
    },
    regionClick: function (adminId) {
      var _this = this
      _this.send({
        url: '/FindBottomVillagesAndAdmin_',
        data: {
          AdminId: adminId,
        },
        success: function (res) {
          _this.streetList = res.Admin
          _this.villagesList = res.Villages
          _this.streetPanel = true
        }
      })
    },
    findLawyers:function(item){
      let _this = this
      _this.villagesName = item.VillagesName
      _this.send({
        url:'/findLawyers',
        data:{
          id:item.VillagesId,
          secret:_this.secret,
        },
        success:function(res){
          _this.lawyerList = res
          _this.streetPanel = false
          _this.lawyerPanel = true
        }
      })
    },
    getVideoCenterList:function(adminId){
      let _this = this
      _this.send({
      url: "/GetServiceCentre",
        data: {
          AdminId:adminId,
          //VillagesId: villagesId,
        },
        success:function(res){
          _this.serviceCenterList = res
          console.log(_this.serviceCenterList)
          for (let index in _this.serviceCenterList) {
            axios({
              url:"https://node.lvwa18.com/socket/checkOnline",
              method:"post",
              data:{
                id: 'VS' + _this.serviceCenterList[index].ServiceCentreId,
                client:'lvwa18',
              },
            })
            .then(res1=>{
              if (res1.data.data.status == 'online') {
              _this.serviceCenterList[index].state = 'online'
              } else {
                _this.serviceCenterList[index].state = 'offline'
              }
            })
          }
        }
      })
    },
    generateToken:function () {
      let _this = this
      return new Promise((resolve,reject)=>{
        _this.send({
          url:"/video-chat-token",
          method:'get',
          data:{
            userId:this.userId,
            secret:_this.secret,
          },
          success:function(res){
            resolve(res)
          }
        })
      })
    },
    callVideo:async function(lawyerId){
      let roomName = parseInt(Math.random()*100000)+this.userId+lawyerId
      this.$router.push({path:'/mobile/public/video-chat/single',query:{
        roomName:roomName,
        localUserId:this.userId,
        remoteUserId:lawyerId,
        locaUserLocation:'公共法律服务大厅',
        locaUserName:'一体机用户',
        source:'法律服务一体机',
        roomToken:await this.generateToken()}
      })
    },
  },
}
</script>


