<template>
    <div style="padding: 20px">
        <div style="background-color: white">
            <div style="font-size: 16px; text-align: left; border-left: solid #de4f0b 3px; padding-left: 5px">
                用户管理
                <div style="display: inline-block; vertical-align: middle; margin-left: 10px">
                    <span style="margin-left: 30px">共{{ count }}条</span>
                </div>
            </div>
            <div style="margin: 15px 0">
                <DatePicker
                    :value="timeData"
                    v-model="timeData"
                    type="daterange"
                    split-panels
                    placeholder="请选择时间"
                    style="width: 200px"
                    @on-change="totalTime3"
                ></DatePicker>

                <Select
                    style="width: 200px"
                    v-model="model140"
                    filterable
                    remote
                    clearable
                    :remote-method="remoteMethod2"
                    @on-change="lawyerClick3"
                    :loading="loading2"
                    placeholder="请输入村（社区）"
                >
                    <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">
                        {{ value.VillagesName }}</Option
                    >
                </Select>
                <div style="width: 300px; display: inline-block; vertical-align: top">
                    <Input v-model="userKey" search enter-button placeholder="请输入姓名" @on-search="userSearch" />
                </div>
            </div>
            <div class="publicWelfare">
                <table class="formtable">
                    <thead>
                        <tr>
                            <th>姓名</th>
                            <th>手机号码</th>
                            <th>所在村（社区）</th>
                            <th>关注时间</th>
                            <th>咨询次数</th>
                            <th>申办次数</th>
                            <th>查询次数</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(value, index) in adminUserData">
                            <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
                            <td>{{ value.UserPhone }}</td>
                            <td>{{ value.village.CityName.replace('重庆市', '') }}{{ value.village._AdminName }}{{ value.village.VillagesName }}</td>
                            <td>{{ value.CreatTime }}</td>
                            <td>{{ value.OrderCount }}</td>
                            <td>{{ value.ApplyCount }}</td>
                            <td>{{ value.QueryCount }}</td>
                        </tr>
                    </tbody>
                </table>
                <Page
                    :total="count"
                    :page-size="pageSize"
                    show-elevator
                    show-total
                    @on-change="
                        page = $event
                        adminUserList(2)
                    "
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'userAdministration',
    data() {
        return {
            count: 0,
            adminUserData: [],
            information: [],
            VillagesId: '',
            page: 1,
            pageSize: 15,
            startTime: '',
            endTime: '',
            timeData: '',
            model140: '',
            loading2: false,
            options2: [],
            userKey: ''
        }
    },
    methods: {
        userSearch: function (e) {
            this.userKey = e
            this.adminUserList()
        },
        adminUserList: function () {
            var _this = this
            _this.send({
                url: '/AdminUserList',
                data: {
                    AdminId: _this.information.AdminId,
                    typeId: 4,
                    page: _this.page,
                    pageSize: _this.pageSize,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime,
                    VillagesId: _this.VillagesId,
                    key: _this.userKey
                },
                success: function (res) {
                    for (var i in res.data) {
                      if(res.data[i].UserPhone){
                        var mobile = res.data[i].UserPhone
                        var tempStr = ''
                        for (var j = 0; j < mobile.length; j++) {
                          if (j < 3 || j > mobile.length - 3) {
                            tempStr += mobile[j]
                          } else {
                            tempStr += '*'
                          }
                        }
                        res.data[i].UserPhone = tempStr
                      }
                    }
                    _this.adminUserData = res.data
                    _this.count = res.total
                }
            })
        },
        totalTime3: function (e) {
            var _this = this
            ;(_this.startTime = e[0]), //开始时间
                (_this.endTime = e[1]) //结束时间
            _this.page = 1
            _this.adminUserList(2)
        },
        lawyerClick3: function (e) {
            var _this = this
            if (e == undefined) {
                _this.VillagesId = ''
                _this.options2 = []
            } else {
                _this.VillagesId = e
            }
            _this.page = 1
            _this.adminUserList(2)
            console.log(e)
        },
        remoteMethod2(query) {
            console.log(query)
            var _this = this
            _this.send({
                url: '/villagesList',
                data: {
                    AdminId: _this.information.AdminId,
                    Key: query
                },
                success: function (res) {
                    console.log(res)
                    _this.options2 = res.list
                }
            })
        }
    },
    mounted() {
        this.information = window.sessionStorage
        this.adminUserList()
    }
}
</script>

<style scoped>
/*表格*/
.publicWelfare table {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}

.formtable {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}

.publicWelfare table th {
    background: #f7f7f7;
    white-space: nowrap;
    color: #5c6b77;
    font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
    border: 1px solid #e9e9e9;
    padding: 8px 16px;
    text-align: left;
}
</style>
