<template>
  <div>


    <div class="publicWelfare">
      <Row>
        <Col span="12">
          <Input v-model="searchLaw" search enter-button placeholder="搜索公证员" @on-search="aQuestionnaireList" />
        </Col>
        <!--<Col span="12" style="text-align: center">-->
        <!--<Button type="primary" @click="sendOutinformation()">一键发送</Button>-->
        <!--</Col>-->
        <Col span="12">
          <Button type="primary"  @click="cqCommunity();modal = true" style="margin-left: 30px">添加</Button>
        </Col>
      </Row>

      <table class="formtable">
        <thead>
        <tr>
          <th>序号</th>
          <th>姓名</th>
          <th>移动电话</th>
          <th>执业机构</th>
          <th>服务起始时间</th>
          <th>服务终止时间</th>
<!--          <th>操作</th>-->
<!--          <th>小程序码</th>-->
<!--          <th>是否绑定</th>-->
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value,index) in adminLawyerList">
          <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
          <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
          <td>
            <Avatar :src="value.GreffierImg" /> {{value.GreffierName}}</td>
          <td>{{value.GreffierPhone}}</td>
          <td>{{value.GreffierFirm}}</td>

<!--          <td style="width: 190px;">-->
<!--            <img class="smallProgram"-->
<!--                 @click="alertbox(value.LawyerQRcode,value.GreffierName,value.GreffierPhone,value.GreffierId,value.OrganizationId)"-->
<!--                 src="/img/smallProgram1.png">-->
<!--          </td>-->
<!--          <td v-if="value.isBinding == 1" style="color: red">已绑定</td>-->
<!--          <td v-else>未绑定</td>-->
          <td>{{ value.ServiceStartTime || '--'}}</td>
          <td>{{ value.ServiceEndTime || '--' }}</td>
          <td style="text-align: center">
            <Button type="primary" @click="replaceLawyer(value)">修改</Button>

<!--                          <div style="margin-left: 20px;display: inline-block">-->
<!--                            <Button-->
<!--                                v-if="value.IsUse == 0"-->
<!--                                type="error"-->
<!--                                @click="ClockSet(value.GreffierId, 1)"-->
<!--                            >禁用</Button>-->
<!--                            <Button-->
<!--                                v-if="value.IsUse == 1"-->
<!--                                type="success"-->
<!--                                @click="ClockSet(value.GreffierId, 0)"-->
<!--                            >启用</Button>-->
<!--                          </div>-->
          </td>
        </tr>
        </tbody>
      </table>
      <Page :total="count" :page-size="pageSize" show-elevator show-total
            @on-change="page = $event;aQuestionnaireList()" />
    </div>
    <!--    添加公证员-->
    <Modal
        v-model="modal"
        title="添加"
        @on-ok="ok"
        @on-cancel="cancel">
      <Input v-model="GreffierName" placeholder="公证员姓名" style="margin-bottom: 30px" />
      <Input v-model="GreffierPhone" placeholder="公证员电话" style="margin-bottom: 30px" />
      <!--      <Select  style="margin-bottom: 30px" v-model="OrganizationId" filterable clearable @on-query-change="lawFirmsearch" @on-open-change="lawFirmdropDown" class="choiceLawyer" placeholder="服务机构">-->
      <!--        <Option v-for="item in FirmPageData" :value="item.OrganizationId" :key="item.OrganizationId">{{item.AdminName}}</Option>-->
      <!--        <Page-->
      <!--            :total="FirmPageCount" show-total-->
      <!--            :page-size="pageSize"-->
      <!--            :current="lawFirmPage"-->
      <!--            @on-change="lawFirmTurnPages"-->
      <!--        ></Page>-->
      <!--      </Select>-->
      <Cascader v-if="adminLevel != 3" style="margin-bottom: 30px" :data="serachData" v-model="serachValue" placeholder="区域" @on-change="serachSelect"  ></Cascader>
      <Select v-if="VillageByData != ''" style="margin-bottom: 30px" v-model="VillagesId" filterable clearable multiple @on-query-change="villageByQuery" @on-open-change="villageByOpen" class="choiceLawyer" placeholder="请选择村（社区），可多选">
        <Option v-for="item in VillageByData" :value="item.VillagesId" :key="item.VillagesId">{{item.VillagesName}}</Option>
        <Page
            :total="villageByCount" show-total
            :page-size="pageSize"
            :current="villageByPage"
            @on-change="serachSelectPage"
        ></Page>
      </Select>
      <div style="margin-bottom: 30px" >
        <DatePicker
            :value="ServiceStartTime"
            v-model="ServiceStartTime"
            type="date"
            split-panels
            placeholder="服务起始时间"
            style="width: 200px"
            @on-change="totalTime1"
        ></DatePicker>
      </div>
      <!--              结束时间-->
      <div>
        <DatePicker
            :value="ServiceEndTime"
            v-model="ServiceEndTime"
            type="date"
            split-panels
            placeholder="服务终止时间"
            style="width: 200px"
            @on-change="totalTime2"
        ></DatePicker>
      </div>
    </Modal>
    <Modal
        :draggable="true"
        v-model="replaceShow"
        title="修改公证员信息"
        @on-ok="modifyInsider"
        @on-cancel="cancel"
        width="530"
    >
      <Input v-model="GreffierName" placeholder="姓名" style="margin-bottom: 30px" />
      <Input v-model="GreffierPhone" placeholder="移动电话" style="margin-bottom: 30px" />

      <div style="margin-bottom: 30px" >
        <DatePicker
            :value="ServiceStartTime"
            v-model="ServiceStartTime"
            type="date"
            split-panels
            placeholder="服务起始时间"
            style="width: 200px"
            @on-change="totalTime1"
        ></DatePicker>
      </div>
      <!--              结束时间-->
      <div>
        <DatePicker
            :value="ServiceEndTime"
            v-model="ServiceEndTime"
            type="date"
            split-panels
            placeholder="服务终止时间"
            style="width: 200px"
            @on-change="totalTime2"
        ></DatePicker>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
name: "notaryAdd",
  data(){
    return{
      searchLaw : '',
      adminLawyerList:[],
      serachValue : [],
      page : 1,
      pageSize : 15,
      serachData : [],
      information:[],
      VillageByData : [],
      GreffierName : '',
      GreffierPhone : '',
      GreffierId : '',
      ServiceStartTime: '',
      ServiceEndTime: '',
      ServiceStartTimeData : '',
      ServiceEndTimeData : '',
      count : 0,
      replaceShow : false,
      modal : false,
      adminLevel : '',
    }
  },
  mounted() {
    this.adminLevel = window.sessionStorage.adminLevel
    this.information = window.sessionStorage
    this.aQuestionnaireList()
  },
  methods:{
    ClockSet: function (GreffierId, IsUse) {
      var _this = this
      _this.send({
        url: '/EditGreffierIsuse',
        confirm: true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          GreffierId: GreffierId,
          IsUse: IsUse
        },
        success: function (res) {
          _this.$Notice.success({
            title: '系统通知',
            desc: '该数据已更新！'
          })
          _this.aQuestionnaireList()
        }
      })
    },
    totalTime1: function (e) {
      this.ServiceStartTimeData = e
    },
    totalTime2: function (e) {
      this.ServiceEndTimeData = e
    },
    ok: function() {
      var _this = this
      if (_this.GreffierName != '' && _this.GreffierPhone != '') {
        _this.send({
          url: '/AAddGreffierInfo',
          data: {

            AdminId: _this.information.AdminId,
            GreffierName : _this.GreffierName,
            GreffierPhone : _this.GreffierPhone,
            VillagesArr : _this.VillagesId.toString(),
          },
          success: function(res) {

            _this.VillageByData = []
            _this.serachValue = []
            _this.page = 1
            _this.aQuestionnaireList()
          }
        })
      } else {
        _this.$Notice.warning({
          title: '系统提示',
          desc: '名称或电话不能为空',
        })
      }
    },
    modifyInsider : function (){
      var _this = this
      _this.send({
        url: '/editGreffierInfo',
        data: {
          AdminId: _this.information.AdminId,
          GreffierId: _this.GreffierId,
          GreffierName: _this.GreffierName,
          GreffierPhone: _this.GreffierPhone,
          ServiceStartTime: _this.ServiceStartTimeData,
          ServiceEndTime: _this.ServiceEndTimeData,
        },
        success: function (res) {
          _this.$Message.success('操作成功')
          _this.aQuestionnaireList()
        }
      })
    },
    cancel : function (){
      var _this = this
      _this.GreffierName = ''
      _this.GreffierPhone = ''
      _this.GreffierId = ''
      _this.ServiceStartTime = ''
      _this.ServiceEndTime = ''
      _this.ServiceStartTimeData  = ''
      _this.ServiceEndTimeData  = ''
    },
    replaceLawyer : function (e){
      this.replaceShow = true
      this.GreffierName = e.GreffierName
      this.GreffierPhone = e.GreffierPhone
      this.GreffierId = e.GreffierId

      this.ServiceEndTime = e.ServiceEndTime
      this.ServiceEndTimeData = e.ServiceEndTime
      this.ServiceStartTime = e.ServiceStartTime
      this.ServiceStartTimeData = e.ServiceStartTime
    },
    serachSelectPage : function (value){
      var _this = this
      _this.villageByPage = value
      _this.VillageByAdmin()
    },
    serachSelect : function (value, selectedData){
      var _this = this
      console.log(value, selectedData)
      if(value != ''){
        _this.serachAdminId = value[value.length-1]
        _this.VillageByAdmin()
      }else {
        _this.VillageByData = []
      }
    },

    VillageByAdmin : function (){
      var _this = this
      _this.send({
        url: '/VillageByAdmin',
        data: {
          AdminId: _this.serachAdminId,
          key : _this.serachkey,
          page : _this.villageByPage,pageSize : _this.pageSize,
        },
        success: function(res) {
          _this.VillageByData = res.list
          _this.villageByCount = res.count
        }
      })
    },
    villageByQuery : function (e){
      console.log(e,this.VillagesId)
      this.serachkey = e
      this.villageByPage = 1
      this.villageByCount = 0
      this.VillageByAdmin()
    },
    cqCommunity : function (){
      var _this = this
      if(window.sessionStorage.adminLevel == 3){
        _this.serachAdminId = window.sessionStorage.AdminId
        _this.VillageByAdmin()
      }else{
        _this.CqAdminSerach()
      }
    },
    CqAdminSerach : function (){
      var _this = this
      var data = []
      _this.send({
        url: '/CqAdminSerach',
        data: {
          AdminId: window.sessionStorage.AdminId,
          Level: window.sessionStorage.adminLevel,
          page : 1,
          pageSize : '999',
        },
        success: function(res) {
          for(var i in res.list){
            data.push(res.list[i])
          }
          convertTree(data)
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let {
                AdminId: value,
                AdminName: label,
                Level : Level,
                Pid : Pid,
                list: children
              } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                Level,
                Pid,
                children
              })
            })
            _this.serachData = result
            return result
          }
        }
      })
    },
    aQuestionnaireList:function (e){
      var _this = this
      _this.send({
        url: '/AAdminGreffierList',
        data: {
          AdminId: _this.information.AdminId,
          page: _this.page,
          pageSize: _this.pageSize,
          key: _this.searchLaw || e
        },
        success: function (res) {
          _this.adminLawyerList = res.data
          _this.count = res.total
          for(var i in _this.statisticsData){
            if(_this.statisticsData[i].type == 2){if(_this.statisticsData[i].value == ''){_this.statisticsData[i].value = res.Count}}
          }
        }
      })
      _this.send({
        url: '/AGWorkFileList',
        data: {
          AdminId: _this.information.AdminId,
          typeId: 4,
          page: _this.page,
          pageSize: _this.pageSize,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime,
          VillagesId: _this.VillagesId,
          key : _this.userKey,
          ServiceStartTime: _this.ServiceStartTimeData,
          ServiceEndTime: _this.ServiceEndTimeData,
        },
        success: function (res) {
          for(var i in _this.statisticsData){
            if(_this.statisticsData[i].type == 5){_this.statisticsData[i].value = res.Count}
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
<style scoped>
.tipsName {
  font-size: 16px;
  color: #8d8d8d;
}
.number {
  font-size: 36px;
  color: #7a7a7a;
}
.house {
  width: 72px;
  height: 72px;
  margin-top: 29px;
}
.displayData {
  width: 33.3%;
  height: 220px;
  background-color: #fff;
  font-size: 0;
  display: inline-block;
  text-align: center;
  /*margin-left: 2%;*/
}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
.smallProgram {
  width: 32px;
  height: 32px;
  vertical-align: top;
}
.addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}

.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

</style>
