<template>
  <div>
    <div style="padding:20px;position: relative;">
      <div style="display: flex;justify-content: space-between;align-items:center;">
        <div>
          <div class="archive-title-icon"></div>
          <span class="archive-title">事件报送（治理中心）</span>
<!--          <div style="display:inline-block;">-->
<!--            事件总数<span class="statistics-num">{{pageBar.total}}</span>个-->
<!--          </div>-->
        </div>
        <div style="float: right;">
          <Button @click="submitted()" type="primary" v-if="submittedType == 1">报送</Button>
          <span style="margin-left:5px;"></span>
          <Button type="success" @click="$router.back(0)" style="background-color:#16c1a3">返回</Button>
        </div>
      </div>
      <Divider style="margin:15px 0 20px 0;"/>
<!--      事件日期列表-->
      <div>
        <Row :gutter="16">
<!--          <Col span="9">-->
<!--            <Row style="text-align: center;line-height: 50px;border: rgb(233,233,233) 1px solid;font-weight: bold">-->
<!--              <Col span="8" style="background-color: #f7f7f7;color: #566c77;height: 50px;font-size: 18px;">-->
<!--                <div style="display: inline-block;vertical-align: top">事件日期</div>-->
<!--                <div style="background-color: rgb(233,233,233);width: 1px;height: 100%;display: inline-block;float: right"></div>-->
<!--              </Col>-->
<!--              <Col span="16" style="background-color: #f7f7f7;color: #566c77;height: 50px;font-size: 18px;">事件概述</Col>-->
<!--            </Row>-->
<!--            <Row v-for="(value, index) in warnData" @click.native="getArchiveDetail(value.fileId,value)" style="cursor:pointer;border: 1px solid rgb(233,233,233);border-top:transparent;height: 110px" :class="value.beforeOneClick?'selectedTrue':'selectedFalse'">-->
<!--              <Col span="8" style="font-size: 13px;text-align: center;position: relative">-->
<!--                <div style="display: inline-block;vertical-align: top;position: absolute;transform:translate(-50%,-50%);left: 50%;top: 50%;white-space: nowrap;">{{value.create_time}}</div>-->
<!--                <div style="background-color: rgb(233,233,233);width: 1px;height: 100%;display: inline-block;float: right"></div>-->
<!--              </Col>-->
<!--              <Col span="16" style="font-size: 13px;position: relative">-->
<!--                <div :title="value.content" class="box" style="overflow: hidden;-webkit-line-clamp: 4;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;margin: 0 11px;position: absolute;top: 50%;transform: translate(0%, -50%);">-->
<!--                  {{value.content}}-->
<!--                </div>-->
<!--              </Col>-->
<!--            </Row>-->
<!--          </Col>-->
          <Col span="24">
            <detail :archiveDetail="archiveDetail"></detail>
          </Col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import detail from '../manager/archives/components/detail'
export default {
  name: "earlyWarningLookover",
  data () {
    return{
      pageBar:{//分页
        total:0,
        size:15,
        sizeList:[10,15,30],
        current:1,
      },
      warnData : [],
      archiveDetail:{//档案详情
        state:false,
        data:{}
      },
      submittedType : ''
    }
  },
  components:{
    detail
  },
  mounted() {
    // this.warnList()
    this.getArchiveDetail(this.$route.query.fileId)
    // 是否可以报送
    this.submittedType = window.sessionStorage.submittedType
  },
  methods: {
    // 列表接口
    warnList : function (){
      var _this = this
      _this.send({
        url: '/warnDetail',
        data:{
          AdminId: window.sessionStorage.AdminId,
          id: _this.$route.query.id,
          page : _this.pageBar.current,
          pageSize : _this.pageBar.size,
        },
        success: function (res) {
          _this.pageBar.total = res.count||0
          if(res.data){
            for(var i in res.data){
              if(i == 0){
                res.data[i].beforeOneClick = true
              }else {
                res.data[i].beforeOneClick = false
              }
            }
            _this.warnData = res.data
            _this.getArchiveDetail(res.data[0].fileId)
          }
        }
      })
    },
    // 档案详情
    getArchiveDetail:function(archiveId,value){
      var _this = this
      if(value){
        this.warnData.forEach(value => value.beforeOneClick = false);
        value.beforeOneClick = true;
      }
      _this.send({
        url: '/eleFileView',
        data: {
          FileStatsId: archiveId,
        },
        success: function (res) {
          _this.archiveDetail.data = {}
          if (res.FileUrl) {
            res.FileUrl = res.FileUrl.split(",")
          }
          if(res.DocumentUrl){
            try {
              res.DocumentUrl = JSON.parse(res.DocumentUrl)
            } catch (e) {
              //旧格式兼容，旧格式仅为文件路径，需截从路径取出文件名
              res.DocumentUrl = [{ name: getFileName(res.DocumentUrl), url: res.DocumentUrl }]
            }
          }
          _this.archiveDetail.data = res
          _this.archiveDetail.state = true
        }
      })
      function getFileName(url) {
        return url.substring(url.lastIndexOf("/"))
      }
    },
  //   报送
    submitted : function (){
      var _this = this
      _this.send({
        url: '/submitted',
        data: {
          id: _this.$route.query.id,
        },
        success: function (res) {
          if(res.data){
            _this.submittedType = 2
            window.sessionStorage.submittedType = 2
            _this.$Message.success('报送成功')
          }
        }
      })
    },
  }
}
</script>

<style scoped>

.archive-title-icon{display: inline-block;width:8px;border-radius:4px;background-color: #2d8cf0;height:22px;vertical-align:middle;}
.archive-title{vertical-align:middle;font-size:16px;margin-left:10px;color:#626775;font-weight:bold;}
.statistics-num{color:#fc2c2d;margin:0 5px;}
.publicWelfare thead tr th{
  background-color: rgb(127,127,127);color: #FFF;height: 50px;font-size: 22px;
}
.publicWelfare tbody tr td{
  background-color: rgb(242,242,242);color: #000;font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectedFalse{background-color: #FFF;color: #000}
.selectedTrue{background-color: #999;color: #FFF}
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
  table-layout: fixed;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>