<style scoped>
    .frame{
        background: linear-gradient(80deg, #006FD6, #4C97FF);
        box-shadow: 0rem 2rem 4rem 0rem rgba(255, 255, 255, 0.1);opacity: 0.8;border-radius: 0.04rem;width: 6.90rem;height: 4.80rem;
        display: inline-block;}
    .sifa{width: 1.02rem;height: 1.10rem;margin: 0.79rem 0 0.10rem 0;}
    .title{font-size: 0.84rem;font-family: SingYiEG-Ultra-GB;font-weight: 900;color: #FFFFFF;margin-bottom: 0.60rem;}
    .frameTwo{
        border-radius: 4px;margin: 0.30rem;width:5.98rem;height:3.90rem;
        border: 0.16rem solid rgba(255, 255, 255, 0.5);}



    .tick{width: 0.5rem;height: 0.50rem;position: absolute;right: 0;bottom: 0;}
    .regionTrue{height: 0.90rem;display: inline-block;line-height: 0.90rem;font-size: 0.26rem;
        border: 0.01rem solid #EBF8FF;position: relative;margin: 0.30rem 0rem 0 0.30rem;width: 1.90rem;background: #EBF8FF;text-align: center;}
    .regionFalse{height: 0.90rem;display: inline-block;line-height: 0.90rem;font-size: 0.26rem;
        border: 0.01rem solid #ffffff;position: relative;margin: 0.30rem 0rem 0 0.30rem;width: 1.90rem;background: #ffffff;text-align: center;}


    /*模态框*/
    .modals{position:fixed; z-index: 999; top:0; left: 0; right:0; bottom: 0;}
    .modals-cancel{position:absolute; z-index:1000; top:0; left: 0; right:0; bottom: 0; background-color: rgba(0,0,0,.5);}
    .bottom-dialog-body{position:absolute; z-index:10001; bottom:0; left:0; right:0;max-height:70%; background-color: #fff;overflow:auto;}
    /*动画前初始位置*/
    .bottom-pos{-webkit-transform:translateY(100%);transform:translateY(100%);}
    .street{width: 100%;height: 0.80rem;line-height: 0.80rem;text-align: center;border-bottom: 0.01rem solid #F6F5F8;}
    .close{position: absolute;right: 0.20rem;top: -0.12rem;}
    .scrollBar{overflow: hidden;position: fixed;width: 100%;}

</style>

<template>
    <div class="">
        <div style="background:url(https://ai-cdn.lvwa18.com/img/rusticate/newbg.png) no-repeat;background-size:100% auto;background-position: top;height:7.21rem;text-align: center;">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/sifa.png" class="sifa" />
            <div class="title">成都司法通-PLS</div>
            <div class="frame">
                <video autoplay src="https://ai-cdn.lvwa18.com/img/rusticate/sft.mp4" controls="controls"
                       class="frameTwo">
                    your browser does not support the video tag
                </video>
            </div>
        </div>
        <!---->
        <div style="text-align: center;">
            <div style="margin:1.60rem 0 0 0;background: #F6F5F8;width: 6.90rem;display: inline-block;    text-align: left;">
                <div  v-for="(item,index) in regionList" style="display: inline-block;">
                    <div class="regionTrue" v-if="item.AdminId == AdminId && item.nothing != false" @click="regionClick(item.AdminId)" >
                        {{item.AdminName}}
                        <img class="tick" src="https://ai-cdn.lvwa18.com/img/rusticate/tick.png"/>
                    </div>
                    <div class="regionFalse" v-if="item.AdminId != AdminId && item.nothing != false" @click="regionClick(item.AdminId)" >
                        {{item.AdminName}}
                    </div>
                    <div class="regionFalse" v-if="item.nothing == false" style="background: #EBEBEB;">
                        {{item.AdminName}}
                    </div>
                </div>
                <div style="padding:0.10rem;"></div>
            </div>
        </div>
        <!---->
        <div class="cdsftPls">
            <Modal
                    v-model="hideModal"
                    @on-cancel="cancel">
                <div>
                    <div class="street">选择所属区域</div>
                    <div class="street" v-for="(item,index) in streetList" @click="street(item.AdminId)">{{item.AdminName}}</div>
                    <div class="street" v-for="(item,index) in villagesList" @click="jump(item.VillagesId)">{{item.VillagesName}}</div>
                    <div v-if="streetList && villagesList" style="text-align: center">暂无数据</div>
                </div>
                <div slot="footer"></div>
            </Modal>
        </div>
    </div>
</template>

<script>
    export default {
        name: "cdsftPls",
        data(){
            return{
                AdminId : '',
                regionList : [
                    { AdminName: '天府新区', AdminId: '638' },
                    { AdminName: '高新区', AdminId: '378' },
                    { AdminName: '武侯区', AdminId: '3' },
                    { AdminName: '成华区', AdminId: '375' },
                    { AdminName: '龙泉驿', AdminId: '486' },
                    { AdminName: '青白江区', AdminId: '374' },
                    { AdminName: '新都区', AdminId: '210' },
                    { AdminName: '温江区', AdminId: '291' },
                    { AdminName: '双流区', AdminId: '627' },
                    { AdminName: '郫都区', AdminId: '640' },
                    { AdminName: '新津区', AdminId: '475' },
                    { AdminName: '简阳市', AdminId: '309' },
                    { AdminName: '都江堰', AdminId: '373' },
                    { AdminName: '彭州市', AdminId: '376' },
                    { AdminName: '邛崃市', AdminId: '678' },
                    { AdminName: '崇州市', AdminId: '343' },
                    { AdminName: '金堂县', AdminId: '639' },
                    { AdminName: '大邑县', AdminId: '351' },
                    { AdminName: '蒲江县', AdminId: '377' },
                    { AdminName: '锦江区', AdminId: '0', nothing: false },
                    { AdminName: '青羊区', AdminId: '0', nothing: false },
                    { AdminName: '金牛区', AdminId: '0', nothing: false },
                    { AdminName: '东部新区', AdminId: '0', nothing:false},
                ],
                streetList : [],
                villagesList : [],
                //
                hideModal:false, //模态框的状态  true-隐藏  false-显示
                animationData:{},//
            }
        },
        mounted(){
            var _this = this
            _this.setPageSize()
        },
        methods : {
            jump:function (res) {
                console.log(res+'-SFT')
            },
            cancel : function(){
                this.streetList = []
                this.villagesList = []
            },
            showModal : function(){
                this.hideModal = !this.hideModal
            },
            regionClick : function (adminId) {
                var _this = this
                _this.AdminId = adminId
                _this.send({
                    url:'/FindBottomVillagesAndAdmin_',
                    data:{
                        AdminId:adminId,
                    },
                    success:function(res){
                        _this.streetList = res.Admin
                        console.log(_this.streetList)
                        _this.showModal()
                    }
                })
            },
            street : function (adminId) {
                var _this =this
                _this.send({
                    url:'/FindBottomVillagesAndAdmin_',
                    data:{
                        AdminId:adminId,
                    },
                    success:function(res){
                        _this.villagesList = res.Villages
                        _this.streetList = []
                        console.log(_this.streetList)
                    }
                })
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
    }
</script>


